import classNames from 'classnames';

const Label = ({
  label,
  dontFocus,
  isOptional = false,
  children,
  className,
  textClassName,
  ...props
}) => {
  const textClasses = classNames(
    'text-gray-500 text-sm leading-5 font-medium mb-2',
    textClassName
  );
  if (dontFocus) {
    return (
      <div className={className} {...props}>
        <div className={textClasses}>
          {label} {isOptional && <span className="text-gray-400">{`(Opsiyonel)`}</span>}
        </div>
        {children}
      </div>
    );
  }
  return (
    <label className={className} {...props}>
      <div className={textClasses}>
        {label} {isOptional && <span className="text-gray-400">{`(Opsiyonel)`}</span>}
      </div>
      {children}
    </label>
  );
};

export default Label;
