import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import routes from './router';

import { getUserData } from 'store/actions/auth';
import { getCompany } from 'store/actions/company';

import LayoutRouter from 'components/layoutRouter';
import LoadingOverlay from 'components/LoadingOverlay';
import { useRouter } from 'utils/hooks/useRouter';
import useGTM from 'utils/hooks/useGTM';
import Seo from 'components/seo';
import { initAdjust, initializeMarketing } from 'utils/initializeMarketing';

function App() {
  const dispatch = useDispatch();
  const { initGTM } = useGTM();

  const { token, user } = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.globalLoading);

  const { location } = useRouter();

  useEffect(() => {
    initGTM();
    initAdjust();
  }, []);

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      dispatch({
        type: 'SET_TOKEN',
        data: localToken
      });

      fetchData();
    }

    async function fetchData() {
      await dispatch(getUserData());
      await dispatch(getCompany());
    }

    const localRememberMeToken = localStorage.getItem('rememberMeToken');
    if (localRememberMeToken) {
      dispatch({
        type: 'SET_REMEMBER_ME_TOKEN',
        data: localRememberMeToken
      });
    }

    const localUser = localStorage.getItem('user');
    if (localUser) {
      dispatch({
        type: 'SET_USER_DATA',
        data: JSON.parse(localUser)
      });
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (user?.userId) initializeMarketing(user);
  }, [user]);

  return (
    <>
      <Seo />
      <Switch>
        <LayoutRouter
          exact={routes.welcome.exact}
          path={routes.welcome.path}
          component={routes.welcome.component}
          layout={routes.welcome.layout}
          isPrivate={routes.welcome.isPrivate}
        />
        <LayoutRouter
          exact={routes.home.exact}
          path={routes.home.path}
          component={routes.home.component}
          layout={routes.home.layout}
          isPrivate={routes.home.isPrivate}
        />
        <LayoutRouter
          exact={routes.accounts.exact}
          path={routes.accounts.path}
          component={routes.accounts.component}
          layout={routes.accounts.layout}
          isPrivate={routes.accounts.isPrivate}
          provider={routes.accounts.contextProvider}
        />
        <LayoutRouter
          exact={routes.transactions.exact}
          path={routes.transactions.path}
          component={routes.transactions.component}
          layout={routes.transactions.layout}
          isPrivate={routes.transactions.isPrivate}
          provider={routes.transactions.contextProvider}
        />
        <LayoutRouter
          exact={routes.invoicing.exact}
          path={routes.invoicing.path}
          component={routes.invoicing.component}
          layout={routes.invoicing.layout}
          isPrivate={routes.invoicing.isPrivate}
          provider={routes.invoicing.contextProvider}
        />
        <LayoutRouter
          exact={routes.createExpenseRecord.exact}
          path={routes.createExpenseRecord.path}
          component={routes.createExpenseRecord.component}
          layout={routes.createExpenseRecord.layout}
          isPrivate={routes.createExpenseRecord.isPrivate}
          provider={routes.createExpenseRecord.contextProvider}
        />
        <LayoutRouter
          exact={routes.expenseDetail.exact}
          path={routes.expenseDetail.path}
          component={routes.expenseDetail.component}
          layout={routes.expenseDetail.layout}
          isPrivate={routes.expenseDetail.isPrivate}
        />
        <LayoutRouter
          exact={routes.other.exact}
          path={routes.other.path}
          component={routes.other.component}
          layout={routes.other.layout}
          isPrivate={routes.other.isPrivate}
        />
        <LayoutRouter
          exact={routes.contactDetail.exact}
          path={routes.contactDetail.path}
          component={routes.contactDetail.component}
          layout={routes.contactDetail.layout}
          provider={routes.contactDetail.contextProvider}
          isPrivate={routes.contactDetail.isPrivate}
        />
        <LayoutRouter
          exact={routes.itemDetail.exact}
          path={routes.itemDetail.path}
          component={routes.itemDetail.component}
          layout={routes.itemDetail.layout}
          isPrivate={routes.itemDetail.isPrivate}
          provider={routes.itemDetail.contextProvider}
        />
        <LayoutRouter
          exact={routes.profile.exact}
          path={routes.profile.path}
          component={routes.profile.component}
          layout={routes.profile.layout}
          isPrivate={routes.profile.isPrivate}
        />
        <LayoutRouter
          exact={routes.documents.exact}
          path={routes.documents.path}
          component={routes.documents.component}
          layout={routes.documents.layout}
          isPrivate={routes.documents.isPrivate}
        />
        <LayoutRouter
          exact={routes.login.exact}
          path={routes.login.path}
          component={routes.login.component}
          layout={routes.login.layout}
          isPrivate={routes.login.isPrivate}
        />
        <LayoutRouter
          exact={routes.signup.exact}
          path={routes.signup.path}
          component={routes.signup.component}
          layout={routes.signup.layout}
          isPrivate={routes.signup.isPrivate}
        />
        <LayoutRouter
          exact={routes.forgotPassword.exact}
          path={routes.forgotPassword.path}
          component={routes.forgotPassword.component}
          layout={routes.forgotPassword.layout}
          isPrivate={routes.forgotPassword.isPrivate}
        />
        <LayoutRouter
          exact={routes.companyOptions.exact}
          path={routes.companyOptions.path}
          component={routes.companyOptions.component}
          layout={routes.companyOptions.layout}
          isPrivate={routes.companyOptions.isPrivate}
        />
        <LayoutRouter
          exact={routes.addCompany.exact}
          path={routes.addCompany.path}
          component={routes.addCompany.component}
          layout={routes.addCompany.layout}
          isPrivate={routes.addCompany.isPrivate}
        />
        <LayoutRouter
          exact={routes.startCompany.exact}
          path={routes.startCompany.path}
          component={routes.startCompany.component}
          layout={routes.startCompany.layout}
          isPrivate={routes.startCompany.isPrivate}
        />
        {/* <LayoutRouter
          exact={routes.kyc.exact}
          path={routes.kyc.path}
          component={routes.kyc.component}
          layout={routes.kyc.layout}
          isPrivate={routes.kyc.isPrivate}
        /> */}
        <LayoutRouter
          exact={routes.agreementAddress.exact}
          path={routes.agreementAddress.path}
          component={routes.agreementAddress.component}
          layout={routes.agreementAddress.layout}
          isPrivate={routes.agreementAddress.isPrivate}
        />
        <LayoutRouter
          exact={routes.onboardingCompleteWelcome.exact}
          path={routes.onboardingCompleteWelcome.path}
          component={routes.onboardingCompleteWelcome.component}
          layout={routes.onboardingCompleteWelcome.layout}
          isPrivate={routes.onboardingCompleteWelcome.isPrivate}
        />
        <LayoutRouter
          exact={routes.onboardingMarketplace.exact}
          path={routes.onboardingMarketplace.path}
          component={routes.onboardingMarketplace.component}
          layout={routes.onboardingMarketplace.layout}
          isPrivate={routes.onboardingMarketplace.isPrivate}
        />
        <LayoutRouter
          exact={routes.accessRouter.exact}
          path={routes.accessRouter.path}
          component={routes.accessRouter.component}
          layout={routes.accessRouter.layout}
          isPrivate={routes.accessRouter.isPrivate}
        />
        <LayoutRouter
          exact={routes.NotFound.exact}
          path={routes.NotFound.path}
          component={routes.NotFound.component}
          layout={routes.NotFound.layout}
          isPrivate={routes.NotFound.isPrivate}
        />
      </Switch>
      {isLoading && <LoadingOverlay />}
    </>
  );
}

export default App;
