import React, { useState } from 'react';
import { ReactComponent as IconCopy } from 'assets/icons/copy.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import Button from 'components/button';

/**
 *
 * @param textToCopy
 * @param beforeCopyMessage
 * @param copiedTextMessage
 * @param activeTimeout
 *
 * activateTimeout should be in seconds, as an example if you want to set a timeout of 5 seconds, you should pass 5 as a parameter.
 * If you want to disable the timeout, pass 0 as a parameter or dont pass anyting
 */
const CopyToClipboardButton = ({
  textToCopy,
  beforeCopyMessage,
  copiedTextMessage,
  activateTimeout = 3,
  className,
  ...props
}) => {
  const [copied, setCopied] = useState(false);

  const onCopyText = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      if (activateTimeout && activateTimeout > 0) {
        setTimeout(() => {
          setCopied(false);
        }, activateTimeout * 1000);
      }
    });
  };

  return (
    <Button
      id={document.id}
      variant="ghost"
      onClick={onCopyText}
      leftIcon={copied ? IconCheck : IconCopy}
      className={className}
      {...props}
    >
      {copied ? copiedTextMessage : beforeCopyMessage}
    </Button>
  );
};
export default CopyToClipboardButton;
