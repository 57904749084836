import { Card, Popup } from 'components';
import React from 'react';
import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import { ETRANSFORMATION_SELECTION_TYPES } from 'utils/contants';

export default function EtransformationSelectionPopup({ onSelect, data, ...props }) {
  return (
    <Popup childrenClassName="!w-full max-w-[480px] !h-full !max-h-[420px] !p-10" {...props}>
      <div className="text-2xl text-gray-900 font-semibold mb-6">e-İmzan Var Mı</div>
      <div className="flex flex-col gap-y-4 w-full">
        <ListItem
          title="e-İmzam var"
          description="e-Dönüşüm'e başvur"
          onClick={() => onSelect(ETRANSFORMATION_SELECTION_TYPES.APPLY_ETRANSFORMATION, data)}
        />
        <ListItem
          title="e-İmzam yok"
          description="Sizden temin edeceğim"
          onClick={() => onSelect(ETRANSFORMATION_SELECTION_TYPES.WITH_YOUR_PROVIDER, data)}
        />
        <ListItem
          title="e-İmzam yok"
          description="Başka yerden temin edeceğim"
          onClick={() => onSelect(ETRANSFORMATION_SELECTION_TYPES.WITH_ANOTHER_PROVIDER, data)}
        />
      </div>
    </Popup>
  );
}

const ListItem = ({ title, description, ...props }) => {
  return (
    <Card className={'!cursor-pointer !shadow-norma h-[84px]'} {...props}>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-base leading-6 font-semibold text-gray-900">{title}</p>
          <p className="text-sm font-medium leading-5 text-gray-700">{description}</p>
        </div>
        <div>
          <IconArrowRight
            width={24}
            height={24}
            className="stroke-current !w-6 !h-6 text-gray-500"
          />
        </div>
      </div>
    </Card>
  );
};
