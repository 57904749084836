import React, { createContext, useState } from 'react';
import { BottomModal /*Button */ } from 'components';
import {
  CardActivationSuccess,
  ConfirmPassword,
  ConfirmSmsToken,
  EnterCardNumber,
  EnterPassword
} from './components';

// import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import classNames from 'classnames';

const steps = [
  <EnterCardNumber key="EnterCardNumber" />,
  <EnterPassword key="EnterPassword" />,
  <ConfirmPassword key="ConfirmPassword" />,
  <ConfirmSmsToken key="ConfirmSmsToken" />,
  <CardActivationSuccess key="CardActivationSuccess" />
];

export const ActivatePhysicalCardContext = createContext({
  step: 0,
  formValues: {
    cardNumber: '',
    password: '',
    confirmPassword: ''
  },
  wallet: null,
  setStep: () => {},
  nextStep: () => {},
  previousStep: () => {},
  setFormValues: () => {},
  onClose: () => {}
});

export default function ActivatePhysicalCardModal({ wallet, onClose }) {
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  // const [showBackButton, setShowBackButton] = useState(true);
  const nextStep = () => setStep((currentStep) => currentStep + 1);
  const previousStep = () => setStep((currentStep) => currentStep - 1);

  const modalClasses = classNames({ 'bg-green-400': step === 4 });

  return (
    <ActivatePhysicalCardContext.Provider
      value={{
        setStep,
        step,
        nextStep,
        previousStep,
        formValues,
        setFormValues,
        onClose,
        wallet
        // setShowBackButton,
      }}
    >
      <BottomModal className={modalClasses} isActive handleClose={onClose}>
        {/* <div className="p-6">
          {showBackButton && step > 0 && (
            <Button variant="circle" color="gray" icon={IconArrowLeft} onClick={previousStep} />
          )}
        </div> */}
        <div className="overflow-y-auto flex justify-center items-center w-full h-full">
          <div className="w-full mx-auto max-w-[400px]">{steps[step]}</div>
        </div>
      </BottomModal>
    </ActivatePhysicalCardContext.Provider>
  );
}
