import React from 'react';

import useModal from 'utils/hooks/useModal';

import { Button, Popup } from 'components';

import business_loan_1 from 'assets/images/business_loan_1.png';
import business_loan_2 from 'assets/images/business_loan_2.png';
import business_loan_3 from 'assets/images/business_loan_3.png';
import business_loan_4 from 'assets/images/business_loan_4.png';

import { ReactComponent as IconBank } from 'assets/icons/bank-green.svg';

export default function BusinessLoan() {
  const [isRedirectModalActive, , toggleRedirectModalActive] = useModal({
    shouldBeBlockScroll: true
  });

  return (
    <div className="flex flex-col gap-y-3 w-1/2">
      <h1 className="text-[22px] font-semibold text-gray-900 leading-7 mb-5">İşletme Kredisi</h1>
      <span className="font-semibold text-lg text-gray-900">
        İşletmenizin ihtiyaçları için anında krediniz hazır!
      </span>
      <div className="h-auto bg-gray-100 shadow-norma rounded-xl p-6 flex flex-col gap-y-8">
        <div className="flex gap-x-4">
          <label className="w-3/5">
            <img
              src={business_loan_1}
              alt="business_loan_1"
              style={{ borderRadius: '0 3rem 3rem 0' }}
            />
          </label>
          <label className="w-2/5">
            <img
              src={business_loan_2}
              alt="business_loan_2"
              style={{ borderRadius: '3rem 0 0 3rem' }}
            />
          </label>
        </div>
        <div className="flex gap-x-4">
          <label className="w-4/5">
            <img
              src={business_loan_3}
              alt="business_loan_3"
              style={{ borderRadius: '0 3rem 3rem 0' }}
            />
          </label>
          <label className="w-1/5">
            <img className="rounded-full" src={business_loan_4} alt="business_loan_circle" />
          </label>
        </div>
        <span className="w-4/5 font-semibold text-md text-green-500">
          İşletmenizin ihtiyaçları için anında krediniz hazır!
        </span>
        <label className="text-sm text-gray-900 ">
          Fibabanka ile gerçekleştirdiğimiz işbirliği sayesinde size özel avantajlarla işletme
          kredisi imkanı sunuyoruz! İşinizi büyütmek, yeni projelere başlamak veya nakit akışınızı
          güçlendirmek için hızlı ve kolay bir çözüm arıyorsanız, doğru yerdesiniz.
        </label>
        <label className="text-sm text-gray-900">
          Hemen başvurun, işletmenizi daha da ileri taşıyın!
        </label>

        <Button
          className="w-full bg-green-300 text-green-900 hover:bg-green-400  rounded-lg px-8 py-3 font-medium text-center min-w-max transition"
          onClick={toggleRedirectModalActive}
        >
          Krediye Başvur
        </Button>
        <RedirectModal isOpen={isRedirectModalActive} onClose={toggleRedirectModalActive} />
      </div>
    </div>
  );
}

const RedirectModal = ({ isOpen, onClose }) => {
  return (
    <Popup active={isOpen} onClose={onClose} childrenClassName="!h-auto">
      <div className="flex flex-col justify-between items-center w-full h-full">
        <div className="w-20 h-20 flex items-center justify-center rounded-full bg-green-100">
          <IconBank className="fill-current w-10 h-10 text-green-500" />
        </div>

        <span className="mt-9 text-gray-900 text-center font-semibold">
          Başvurunuzun devamı için sizi Fibabanka’nın güvenli kredi başvuru sayfasına
          yönlendiriyoruz. Burada kredi başvuru sürecinizi tamamlayabilirsiniz{' '}
        </span>
        <div className="w-full">
          <Button color="red" className="mt-4 w-full" onClick={onClose}>
            Vazgeç
          </Button>
          <Button
            className="mt-4 w-full"
            onClick={() => {
              onClose();
              window.open(
                'https://www.fibabanka.com.tr/kucuk-isletme-ve-tarim/isletme-ve-tarim-kredi-basvuru-formu?code=278',
                '_blank'
              );
            }}
          >
            Devam
          </Button>
        </div>
      </div>
    </Popup>
  );
};
