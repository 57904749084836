import React from 'react';
import classNames from 'classnames';

export default function IconContainer({ className, children, ...props }) {
  const classes = classNames('rounded-full p-4 bg-green-100', className);

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
}
