import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Popover } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import useModal from 'utils/hooks/useModal';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { getContacts } from 'store/actions/contacts';
import { getItems } from 'store/actions/items';
import { calculateSaleExpenseItems, createSaleExpense } from 'store/actions/invoice';
import { getRates } from 'store/actions/exchange';
import { getCategories } from 'store/actions/categories';
import useDengage from 'utils/hooks/useDengage';

import {
  COMPANY_SUB_TYPES,
  COMPANY_TYPES,
  currencyFormat,
  getFormattedDateForPayload,
  PAYMENT_STATUS
} from 'utils';
import { CURRENCY_SIGNS } from 'views/other/items/enums';
import { PAGE_NAMES } from './enums';
import { CURRENCY_NAMES, PAYMENT_METHODS } from 'views/invoicing/enums';

import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as TransactionListIcon } from 'assets/illustrations/accounts/transaction-list.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';

import { AddNewItemModal as AddItemModal } from 'views/other/items/components';
import { useItemContext } from 'contexts/itemContext';

import SaleRecordStatus from 'views/invoicing/components/saleRecordStatus';
import AmountInput from 'components/AmountInput';
import {
  Checkbox,
  CustomButton,
  CustomInput,
  CategoryBadge,
  AddCategoryModal,
  Button,
  OptionalArea,
  InfoMessage
} from 'components';

import { useRouter } from 'utils/hooks/useRouter';
import CreateContactModal from 'views/other/contacts/components/createContactModal';

const CreateForm = ({ setPage, setRecordId }) => {
  const dispatch = useDispatch();

  const [searchContact, setSearchContact] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [paymentStatus, setPaymentStatus] = useState();
  const [currency, setCurrency] = useState();
  const { publishEvent, eventTypes } = useDengage();
  const { user, etransformationType } = useSelector((state) => state.auth);

  const [addContactModalActive, , toggleAddContactModal] = useModal({
    handleClose: getContactsBySearch
  });

  const { isAddNewItemModalActive, toggleAddNewItemModal } = useItemContext();

  const date = new Date();
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;

  const schema = z.object({
    contactId: z.string({
      invalid_type_error: 'Müşteri/Tedarikçi seçilmesi zorunludur.',
      required_error: 'Müşteri/Tedarikçi seçilmesi zorunludur.'
    }),
    currency: z.string(),
    currencyRate:
      currency === 'TRY'
        ? z.optional(z.number().nullable())
        : z
            .number({
              required_error: 'Kur girilmesi zorunludur.',
              invalid_type_error: 'Kur girilmesi zorunludur.'
            })
            .positive('Kur girilmesi zorunludur.'),
    dueDate:
      paymentStatus === PAYMENT_STATUS.NOT_PAID
        ? z.date({
            required_error: 'Vade Tarihi girilmesi zorunludur.',
            invalid_type_error: 'Vade Tarihi girilmesi zorunludur.'
          })
        : z.optional(z.date().or(z.null())),
    items: z
      .object({
        discountRate: z.number(),
        id: z.number(),
        quantity: z.number(),
        price: z.number(),
        currency: z.string()
      })
      .array()
      .nonempty('Ürün seçilmesi zorunludur.'),
    note: z.string(),
    paymentDate: z.optional(z.date().or(z.null())),
    transferIds: z.any(),
    paymentStatus: z
      .string({ required_error: 'Durum seçilmesi zorunludur.' })
      .nonempty('Durum seçilmesi zorunludur'),
    recordType: z.string(),
    title: z.string().nonempty('Kayıt İsmi girilmesi zorunludur.'),
    totalTaxAmount: z.optional(z.number()),
    totalAmount: z.optional(z.number()),
    paymentMethod: z.string(),
    categoryId: z.optional(z.number().nullish())
  });

  const defaultValues = {
    contactId: null,
    currency: null,
    currencyRate: null,
    items: [],
    note: '',
    dueDate: null,
    paymentDate: null,
    paymentStatus: PAYMENT_STATUS.PAID,
    recordType: 'SALES',
    title: '',
    totalTaxAmount: 0,
    totalAmount: 0,
    paymentMethod: 'NORMA',
    categoryId: null,
    transferIds: []
  };

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm({ resolver: zodResolver(schema), defaultValues });

  async function onSubmit(data) {
    if (!user.companyId) return;
    Object.keys(data).map((key) => {
      if (!data[key] === null) delete data[key];
    });

    const payload = {
      ...data,
      companyId: user.companyId
    };

    if (data.dueDate) payload.dueDate = getFormattedDateForPayload(payload.dueDate);

    if (data.paymentDate) payload.paymentDate = getFormattedDateForPayload(payload.paymentDate);

    try {
      const createResponse = await createSaleExpense(payload);

      const recordId = createResponse.data?.data?.id;
      if (!recordId) return;

      // await completeSaleExpense({ companyId: user.companyId, recordId });

      setRecordId(recordId);
      setPage(PAGE_NAMES.SUMMARY);
    } catch (err) {
      console.log(err);
    }
  }

  function getContactsBySearch() {
    dispatch(
      getContacts({
        search: searchContact,
        orderBy: 'firstName',
        orderDirection: 'ASC',
        size: 999
      })
    );
  }

  function getItemsBySearch() {
    dispatch(
      getItems({
        size: 9999,
        name: searchItem,
        orderBy: 'createDate',
        orderDirection: 'DESC',
        situation: 'SELLING'
      })
    );
  }

  function handleCreatedContact(contact) {
    setValue('contactId', contact?.id);
  }

  function handleAddItem(isChecked, item) {
    if (!isChecked) {
      return setValue(
        'items',
        watch('items').filter((i) => i.id !== item.id)
      );
    }

    const payload = {
      discountRate: 0,
      id: item.id,
      quantity: 1,
      price: item?.price,
      currency: item?.currency
    };

    if (user?.company?.subType === COMPANY_SUB_TYPES.FREE_LANCER) {
      setValue('items', []);
    }

    setValue('items', [...watch('items'), payload]);
  }

  useEffect(() => {
    getContactsBySearch();
  }, [searchContact]);

  useEffect(() => {
    getItemsBySearch();
  }, [searchItem]);

  useEffect(() => {
    setPaymentStatus(watch('paymentStatus'));
    clearErrors('dueDate');
    clearErrors('paymentDate');
  }, [watch('paymentStatus'), watch('dueDate'), watch('paymentDate')]);

  useEffect(() => {
    if (watch('paymentStatus') === PAYMENT_STATUS.PAID) setValue('dueDate', null);
    if (watch('paymentStatus') === PAYMENT_STATUS.NOT_PAID) setValue('paymentDate', null);
  }, [watch('paymentStatus')]);

  useEffect(() => {
    setCurrency(watch('currency'));
  }, [watch('currency')]);

  useEffect(() => {
    if (watch('paymentMethod') !== PAYMENT_METHODS.NORMA) setValue('transferIds', []);
  }, [watch('paymentMethod')]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_create_sales' });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
      {addContactModalActive && (
        <CreateContactModal onClose={toggleAddContactModal} onSucceded={handleCreatedContact} />
      )}
      {isAddNewItemModalActive && (
        <AddItemModal
          onClose={(item) => {
            if (watch('items').length === 0) {
              handleAddItem(true, item);
            } else if (item.currency === currency) {
              handleAddItem(true, item);
            }
            toggleAddNewItemModal();
          }}
        />
      )}
      <div className="flex justify-center h-full bg-gray-50 overflow-y-scroll">
        <div className="w-[640px] h-full flex flex-col py-10">
          <div className="text-sm font-semibold leading-5 text-gray-400">Satış Kaydı Oluştur</div>
          <div className="flex justify-between items-center border-b border-solid pb-12 border-gray-200">
            <span className="font-semibold text-[22px] text-gray-900">Gelir Ekle</span>
            <span className="text-sm font-medium leading-5">{formattedDate}</span>
          </div>
          <SaleRecordDescription
            watch={watch}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
          />
          <SaleRecordContact
            watch={watch}
            setValue={setValue}
            toggleAddContactModal={toggleAddContactModal}
            search={searchContact}
            setSearch={setSearchContact}
            errors={errors}
            clearErrors={clearErrors}
          />
          <SaleRecordCategory setValue={setValue} watch={watch} />
          <SaleRecordItem
            watch={watch}
            setValue={setValue}
            toggleAddItemModal={toggleAddNewItemModal}
            search={searchItem}
            setSearch={setSearchItem}
            errors={errors}
            clearErrors={clearErrors}
            handleAddItem={handleAddItem}
          />
          <SaleRecordStatus setValue={setValue} watch={watch} errors={errors} />
          <SaleRecordNote setValue={setValue} watch={watch} />
          <div className="w-full">
            <CustomButton
              color="success"
              className="w-full mb-20"
              type="submit"
              disabled={
                !watch('title') ||
                !watch('contactId') ||
                !watch('items').length > 0 ||
                !(watch('paymentStatus') === PAYMENT_STATUS.PAID
                  ? watch('paymentDate')
                  : watch('dueDate'))
              }
            >
              Kaydet
            </CustomButton>
          </div>
        </div>
      </div>
    </form>
  );
};

const SearchInput = ({ onChange, className, value, onClick, error }) => (
  <>
    <div
      className={`${className} bg-gray-100 py-3 px-3 flex items-center text-gray-400 rounded-lg focus-within:bg-white focus-within:shadow-lg`}
    >
      <IconSearch className="fill-current w-4 mr-4" />
      <input
        onChange={onChange}
        placeholder="Arama"
        className="w-full appearance-none focus:outline-none text-black bg-transparent"
        value={value}
        onClick={onClick}
      />
    </div>
    {error && <span className="text-xs text-red-500">{error}</span>}
  </>
);

const SaleRecordDescription = ({ setValue, watch, errors, clearErrors }) => (
  <div className="border-b border-solid border-gray-100 py-12">
    <span className="font-semibold text-lg text-gray-900">Fatura Açıklaması Ekle</span>
    <CustomInput
      label="Kayıt İsmi"
      onChange={(e) => {
        setValue('title', e.target.value);
        clearErrors('title');
      }}
      className="mt-4"
      count={watch('title')?.length || 0}
      maxLength={140}
      error={errors?.title?.message}
    />
  </div>
);

const NoContact = () => (
  <div className="flex flex-col items-center justify-center bg-white py-10 border border-solid border-gray-100 rounded-lg mt-4 gap-y-5">
    <TransactionListIcon className="w-20 h-auto" />
    <div className="py-2 px-4 rounded-full flex gap-x-2 items-center bg-gray-100 border border-solid border-gray-200 w-auto">
      <InfoCircleIcon className="fill-current w-4 text-gray-500" />
      <span className="text-sm text-gray-500 font-medium">
        Henüz Müşteri/Tedarikçi eklenmemiş
      </span>
    </div>
  </div>
);

const SaleRecordContact = ({
  search,
  setSearch,
  watch,
  setValue,
  toggleAddContactModal,
  errors,
  clearErrors
}) => {
  const { contacts, filteredContacts } = useSelector((state) => state.contacts);
  const { query } = useRouter();
  function handleSelectContact(c, close) {
    setValue('contactId', c.id);
    close();
  }

  const selectedContact = contacts.find((c) => c.id === watch('contactId'));
  const nameLetters =
    (selectedContact?.firstName ? selectedContact?.firstName[0] : '') +
    (selectedContact?.lastName ? selectedContact?.lastName[0] : 0);

  const fullName =
    selectedContact?.companyType === COMPANY_TYPES.BUSINESS
      ? selectedContact?.companyName
        ? selectedContact?.companyName
        : '-'
      : selectedContact?.firstName && selectedContact?.lastName
      ? selectedContact?.firstName + ' ' + selectedContact?.lastName
      : '-';

  useEffect(() => {
    setValue('contactId', query?.contactId);
  }, [query?.contactId]);

  useEffect(() => {
    clearErrors('contactId');
  }, [watch('contactId')]);

  return (
    <div className="border-b border-solid border-gray-100 py-12">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg text-gray-900">Müşteri/Tedarikçi</span>
        <Button
          variant="ghost"
          leftIcon={<IconPlus className="stroke-current" />}
          onClick={toggleAddContactModal}
          type="button"
        >
          Yeni Ekle
        </Button>
      </div>
      {!contacts.length ? (
        <NoContact />
      ) : (
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                as="div"
                className="bg-white rounded-xl border border-solid border-gray-200 px-4 py-6 mt-4 cursor-pointer"
              >
                {watch('contactId') ? (
                  <div className="flex items-center">
                    <div className="w-10 h-10 mr-4 bg-purple-700 text-white rounded-full flex items-center justify-center">
                      {nameLetters}
                    </div>
                    <span className="text-gray-900">{fullName}</span>
                    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span className="font-semibold text-gray-900">Müşteri/Tedarikçi Seç</span>
                    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
                  </div>
                )}
              </Popover.Button>
              {errors?.contactId && (
                <span className="text-red-500 text-xs">{errors?.contactId?.message}</span>
              )}
              <Popover.Panel
                as="div"
                className="bg-white rounded-xl border border-solid border-gray-200 p-4 mt-4 h-80 overflow-y-scroll absolute w-full z-50"
              >
                <SearchInput onChange={(e) => setSearch(e.target.value)} value={search} />
                <div className="mt-2 flex flex-col gap-y-2">
                  {filteredContacts.map((contact, i) => {
                    return (
                      <div
                        key={i}
                        className="flex items-center cursor-pointer"
                        onClick={() => handleSelectContact(contact, close)}
                      >
                        <div className="flex items-center justify-center h-11 w-11 rounded-full bg-purple-700 text-white">
                          <span>{`${contact?.firstName ? contact?.firstName[0] : ''}${
                            contact?.lastName ? contact?.lastName[0] : 0
                          }`}</span>
                        </div>
                        <span className="text-gray-900 ml-4">
                          {contact?.companyType === COMPANY_TYPES.BUSINESS
                            ? contact?.companyName
                            : `${contact?.firstName} ${contact?.lastName}`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      )}
    </div>
  );
};

const SaleRecordItem = ({
  search,
  setSearch,
  watch,
  setValue,
  toggleAddItemModal,
  errors,
  clearErrors,
  handleAddItem
}) => {
  const dispatch = useDispatch();

  const [fixedCurrencyRate, setFixedCurrencyRate] = useState(true);
  const [prices, setPrices] = useState();
  const [originalPrices, setOriginalPrices] = useState();

  const { user, etransformationType } = useSelector((state) => state.auth);
  const { items: allItems, filteredItems: allFilteredItems } = useSelector(
    (state) => state.items
  );

  const { TRY } = useSelector((state) => state.exchange);

  const items = allItems.filter(
    (i) => watch('currency') === null || i.currency === watch('currency')
  );
  const filteredItems = allFilteredItems.filter(
    (i) => watch('currency') === null || i.currency === watch('currency')
  );

  function calculateItems() {
    if (
      !user?.companyId ||
      !watch('currency') ||
      (!watch('currencyRate') && watch('currency') !== 'TRY')
    ) {
      setPrices(null);
      setOriginalPrices(null);
      return;
    }

    const payload = {
      currency: watch('currency') || 'TRY',
      currencyRate: watch('currencyRate'),
      items: watch('items')
    };
    calculateSaleExpenseItems({ companyId: user.companyId, data: payload })
      .then((res) => {
        let calculatedPrices = { ...res.data?.data };
        setOriginalPrices(res.data?.data);
        if (watch('currency') !== 'TRY') {
          const currentCurrencyVal = watch('currencyRate');
          Object.entries(calculatedPrices).forEach(([key, value]) => {
            if (key === 'taxes') {
              calculatedPrices[key] = calculatedPrices.taxes.map((tax) => {
                tax.amount = tax.amount * currentCurrencyVal;
                return tax;
              });
              return;
            }
            calculatedPrices[key] = value * currentCurrencyVal;
          });
        }

        setPrices(calculatedPrices);
        if (res.data?.data?.totalAmount) setValue('totalAmount', res.data?.data?.totalAmount);
        if (res.data?.data?.totalTaxAmount)
          setValue('totalTaxAmount', res.data?.data?.totalTaxAmount);
      })
      .catch((err) => {
        setPrices(null);
        setOriginalPrices(null);
        console.log(err);
      });
  }

  function handleItemChange(value, id, key) {
    const payload = watch('items').map((i) => {
      if (i.id === id) {
        value = Number(value);
        return {
          ...i,
          [key]: key === 'discountRate' && Number(value) > 100 ? 100 : Number(value)
        };
      }
      return i;
    });

    setValue('items', payload);
  }

  const selectedItemIds = watch('items').map((i) => i.id);

  useEffect(() => {
    if (watch('items').length !== 0) calculateItems();
  }, [watch('items'), watch('currency'), watch('currencyRate')]);

  useEffect(() => {
    dispatch(getRates({ currency: 'TRY' }));
  }, []);

  useEffect(() => {
    if (watch('currency') === 'TRY') {
      setValue('currencyRate', null);
    }

    if (!fixedCurrencyRate) return;

    const rates = TRY.find((c) => c.name === watch('currency'));

    if (!rates) return;

    setValue('currencyRate', rates.sellRate);
  }, [fixedCurrencyRate, watch('items'), watch('currency')]);

  useEffect(() => {
    setValue('currency', watch('items')[0]?.currency || null);
    clearErrors('items');
  }, [watch('items')]);

  return (
    <form noValidate className="py-12 border-b border-solid border-gray-100 flex flex-col">
      <div className="flex justify-between">
        <span className="font-semibold text-lg text-gray-900">Ürün Ekle</span>

        <Button
          variant="ghost"
          leftIcon={<IconPlus className="stroke-current" />}
          onClick={toggleAddItemModal}
          type="button"
          disabled={
            user?.company?.subType === COMPANY_SUB_TYPES.FREE_LANCER && watch('items').length > 0
          }
        >
          Yeni Ekle
        </Button>
      </div>
      <Popover className="relative">
        {({ open }) => (
          <>
            {!items.length ? (
              <NoItem />
            ) : (
              <Popover.Button
                as={SearchInput}
                className="mt-5"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                error={errors?.items?.message}
              />
            )}
            <Popover.Panel>
              <div className="flex flex-col items-center justify-start bg-white border border-solid border-gray-100 rounded-lg px-10 mt-4 gap-y-5 h-96 overflow-y-scroll absolute w-full z-50">
                {filteredItems.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="w-full border-b border-solid border-gray-200 last:border-0 py-6 flex justify-between"
                  >
                    <div className="flex gap-x-2 justify-start items-start">
                      <Checkbox
                        checked={selectedItemIds.includes(item.id)}
                        onChange={(e) => handleAddItem(e.target.checked, item)}
                      />
                      <div className="flex flex-col justify-center items-start gap-y-2">
                        <span className="font-semibold text-gray-700">{item.name}</span>
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-end">
                      <span className="text-lg font-semibold text-gray-900 text-right">
                        {item?.price && item?.currency
                          ? `${item?.price} ${CURRENCY_NAMES[item?.currency] || '-'}`
                          : '-'}
                      </span>
                      {item?.vatRate && (
                        <span className="text-sm font-medium text-gray-500 text-right">{`+%${
                          item?.vatRate || '-'
                        } KDV`}</span>
                      )}
                      {item?.vatWithholdingRate && (
                        <span className="text-sm font-medium text-gray-500 text-right">{`-%${
                          item?.vatWithholdingRate || '-'
                        } Tevkifat`}</span>
                      )}
                      {!!item?.additionalTaxes?.length &&
                        item?.additionalTaxes.map((tax, i) => (
                          <span
                            key={i}
                            className="text-sm font-medium text-gray-500 text-right"
                          >{`+%${tax.percent || '-'} ${tax.name || '-'}`}</span>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      {!!watch('items').length && (
        <ItemsSummary
          watch={watch}
          handleItemChange={handleItemChange}
          items={items}
          selectedItemIds={selectedItemIds}
          totalPrice={originalPrices?.taxExcludedAmount || 0}
          handleAddItem={handleAddItem}
        />
      )}
      {user?.company?.subType === COMPANY_SUB_TYPES.FREE_LANCER && (
        <InfoMessage className="my-6 !bg-gray-100">
          E-makbuz keserken sadece bir tane ürün seçebilirsiniz. Seçtiğiniz ürünün adet ve indirim
          oranını değiştiremezsiniz.
        </InfoMessage>
      )}
      <div className="bg-gray-100 border border-solid border-gray-200 p-10 rounded-lg mt-6 flex flex-col gap-y-2">
        <div className="flex justify-between">
          <span className="text-sm font-semibold text-gray-900">Toplam Tutar</span>
          <span className="text-sm font-semibold text-gray-900">
            {prices?.taxExcludedAmount
              ? `${currencyFormat(prices?.taxExcludedAmount)}`
              : currencyFormat(0)}{' '}
            TL
          </span>
        </div>

        <div className="flex justify-between">
          <span className="text-sm font-semibold text-gray-900">Vergiler</span>
          {/* <span className="text-sm font-semibold text-gray-900">
            {!!prices?.taxes.length
              ? `${currencyFormat(prices?.totalTaxAmount)}`
              : currencyFormat(0)}{' '}
            TL
          </span> */}
        </div>

        {prices?.taxes.map((tax, i) => (
          <div key={i} className="flex justify-between ml-6">
            <span className="text-xs font-semibold text-gray-500">
              {tax?.name} (%{tax?.percent})
            </span>
            <span className="text-xs font-semibold text-gray-500">
              {currencyFormat(tax?.amount)} TL
            </span>
          </div>
        ))}
        <div className="flex justify-between">
          <span className="text-sm font-semibold text-gray-900">Genel Toplam</span>
          <span className="text-sm font-semibold text-gray-900">
            {prices?.totalAmount ? `${currencyFormat(prices?.totalAmount)}` : currencyFormat(0)}
            TL
          </span>
        </div>
      </div>
      {watch('currency') && watch('currency') !== 'TRY' && (
        <div className="mt-6 flex flex-col">
          <Checkbox
            checked={fixedCurrencyRate}
            onChange={(e) => {
              setFixedCurrencyRate(e.target.checked);
            }}
          >
            {`1 ${
              CURRENCY_SIGNS[watch('currency')]
            } fiyatı Merkez Bankası Efektif satış tarafından hesaplanmıştır. 1 ${
              CURRENCY_SIGNS[watch('currency')]
            } = ${currencyFormat(TRY.find((c) => c.name === watch('currency'))?.sellRate)} ₺`}
          </Checkbox>
          {!fixedCurrencyRate && (
            <CustomInput
              label="Kur"
              type="number"
              className="mt-4"
              defaultValue={watch('currencyRate')}
              onChange={(e) => {
                setValue('currencyRate', Number(e.target.value));
              }}
              error={errors?.currencyRate?.message}
            />
          )}
        </div>
      )}
    </form>
  );
};

const NoItem = () => (
  <div className="flex flex-col items-center justify-center bg-white py-10 border border-solid border-gray-100 rounded-lg mt-4 gap-y-5 w-full">
    <TransactionListIcon className="w-20 h-auto" />
    <div className="py-2 px-4 rounded-full flex gap-x-2 items-center bg-gray-100 border border-solid border-gray-200 w-auto">
      <InfoCircleIcon className="fill-current w-4 text-gray-500" />
      <span className="text-sm text-gray-500 font-medium">Henüz ürün eklenmemiş</span>
    </div>
  </div>
);

const ItemsSummary = ({
  watch,
  items,
  selectedItemIds,
  handleItemChange,
  totalPrice,
  handleAddItem
}) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="flex flex-col items-center justify-start bg-white border border-solid border-gray-100 rounded-lg p-9 mt-4 gap-y-5">
      {watch('items').map((i, itemIndex) => {
        const item = items.find((it) => it.id === i.id);
        return (
          <div
            key={itemIndex}
            className="flex flex-col w-full border-b border-solid border-gray-200 py-6"
          >
            <div className="w-full flex justify-between">
              <div className="flex gap-x-2 justify-start items-start">
                <Checkbox
                  checked={selectedItemIds.includes(i.id)}
                  onChange={(e) => handleAddItem(e.target.checked, i)}
                />
                <div className="flex flex-col justify-center items-start gap-y-2">
                  <span className="font-semibold text-gray-700">{item?.name}</span>
                </div>
              </div>
              <div className="flex flex-col justify-start items-end">
                <span className="text-lg font-semibold text-gray-900 text-right">
                  {!!i?.price && !!item?.currency
                    ? `${currencyFormat(i.price)} ${CURRENCY_NAMES[item.currency] || '-'}`
                    : '-'}
                </span>
                {item?.vatRate && (
                  <span className="text-sm font-medium text-gray-500 text-right">{`+%${
                    item?.vatRate || '-'
                  } KDV`}</span>
                )}
                {item?.vatWithholdingRate && (
                  <span className="text-sm font-medium text-gray-500 text-right">{`-%${
                    item?.vatWithholdingRate || '-'
                  } Tevkifat`}</span>
                )}
                {!!item?.additionalTaxes?.length &&
                  item?.additionalTaxes.map((tax, i) => (
                    <span key={i} className="text-sm font-medium text-gray-500 text-right">{`+%${
                      tax?.percent || '-'
                    } ${tax?.name || '-'}`}</span>
                  ))}
              </div>
            </div>
            <div className="flex gap-x-1 mt-5 w-full">
              <CustomInput
                label={'Birim (Adet)'}
                className="w-1/3"
                value={i.quantity}
                onChange={(e) => handleItemChange(e.target.value, i.id, 'quantity')}
                only="number"
                disabled={user?.company?.subType === COMPANY_SUB_TYPES.FREE_LANCER}
              />
              <AmountInput
                label={`${
                  user?.company?.subType === COMPANY_SUB_TYPES.SOLE_TRADER ? 'Birim' : 'Brüt'
                } Fiyat`}
                className="w-1/3"
                value={i.price}
                onValueChange={(value) => handleItemChange(value, i.id, 'price')}
                suffix={` ${CURRENCY_SIGNS[watch('currency')]}`}
              />
              <AmountInput
                label="İndirim Oranı"
                className="w-1/3"
                value={Number(i.discountRate)}
                onValueChange={(value) => handleItemChange(value, i.id, 'discountRate')}
                max={100}
                prefix="%"
                disabled={user?.company?.subType === COMPANY_SUB_TYPES.FREE_LANCER}
              />
            </div>
          </div>
        );
      })}
      <div className="w-full py-1 flex flex-col gap-y-2">
        <span className="text-lg font-semibold text-gray-900">Toplam Ücret</span>
        <div className="flex justify-between items-end">
          <span className="text-4xl text-green-500">
            {currencyFormat(totalPrice)} {watch('currency')}
          </span>
          <span>+ KDV</span>
        </div>
      </div>
    </div>
  );
};

const SaleRecordNote = ({ setValue, watch }) => (
  <div className="py-12 border-b border-solid border-gray-100 flex flex-col">
    <span className="text-sm text-gray-500">
      Not Ekle <OptionalArea />
    </span>
    <div className="w-full h-20 mt-1">
      <textarea
        className="appearance-none bg-gray-100 border border-solid border-gray-200 focus:outline-none resize-none rounded-lg px-4 py-2 w-full h-full mt-1"
        onChange={(e) => setValue('note', e.target.value)}
        maxLength={140}
      />
      <div className="text-xs text-gray-500 ml-auto text-right w-full">
        {watch('note').length}/140
      </div>
    </div>
  </div>
);

const SaleRecordCategory = ({ setValue, watch }) => {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categories);

  const [addUserModalActive, , toggleAddUserModalActive] = useModal();

  useEffect(() => {
    dispatch(getCategories());
  }, [addUserModalActive]);

  return (
    <div className="py-12 border-b border-solid border-gray-200">
      {addUserModalActive && <AddCategoryModal handleClose={toggleAddUserModalActive} />}
      <div className="flex justify-between">
        <span className="text-lg font-semibold text-gray-900">
          Kategori <OptionalArea />{' '}
        </span>
        <span
          className="text-green-500 text-sm flex items-center font-medium cursor-pointer"
          onClick={() => toggleAddUserModalActive()}
        >
          <IconPlus className="stroke-current w-4 mr-2" />
          <span>Oluştur</span>
        </span>
      </div>
      <div className="flex flex-wrap gap-4 mt-5">
        {categories.map((c, i) => (
          <CategoryBadge
            key={i}
            name={c?.name}
            colorHexCode={c?.colorHexCode}
            textColorHexCode={c?.textColorHexCode}
            iconUrl={c?.iconUrl}
            selected={c?.id === watch('categoryId')}
            onClick={() => setValue('categoryId', c?.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default CreateForm;
