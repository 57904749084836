import React from 'react';
import { Checkbox } from 'components';
import { useContactContext } from 'contexts/contactContext';

export default function QuickContactCheckbox() {
  const { formValues, setFormValues } = useContactContext();

  const onChangeQuickContact = (e) => {
    const { checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      express: checked
    }));
  };

  return (
    <div className="w-full py-5 px-6 border border-solid border-gray-200 rounded bg-gray-50">
      <Checkbox
        checked={formValues.express}
        onChange={onChangeQuickContact}
        name="express"
        data-testid="checkbox_make_quick_contact"
      >
        Hızlı Müşteri/Tedarikçi olarak tanımla
      </Checkbox>
    </div>
  );
}
