const Tooltip = ({ children, text }) => (
  <div className="tooltip">
    <span className="tooltip-text invisible absolute rounded-xl py-2 px-4 border border-solid border-gray-200 bg-white text-gray-900 text-sm shadow-lg -mt-11 min-w-max">
      {text}
    </span>
    {children}
  </div>
);

export default Tooltip;
