import React, { useState, useEffect } from 'react';
import { useItemContext } from 'contexts/itemContext';
import AddTaxButton from '../addTaxButton';
import { Button, Select } from 'components';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';
import { getTaxGroups } from 'store/actions/eTransformation';
import AmountInput from 'components/AmountInput';

export default function AdditionalTax() {
  const [taxes, setTaxes] = useState([]);
  const { setFormValues, formValues, isFreelancer } = useItemContext();
  const selectedCodes = formValues?.additionalTaxes?.map((at) => at.code);

  useEffect(() => {
    getTaxGroups({ exclude: 'KDV' })
      .then((res) => {
        setTaxes(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addTax = () => {
    setFormValues((prevValues) => {
      const tax = {
        name: isFreelancer ? taxes[0].name : null,
        code: isFreelancer ? taxes[0].code : null,
        percent: isFreelancer ? 20 : null
      };
      const prevAdditionalTaxes = [...prevValues.additionalTaxes, tax];
      return {
        ...prevValues,
        additionalTaxes: prevAdditionalTaxes
      };
    });
  };

  const getAvailableTaxes = (additionalTax) => {
    const availableTaxes = taxes.filter(
      (tax) =>
        additionalTax.code === tax.code ||
        (tax.hasRatio && tax.ratios.map((r) => r.code).includes(additionalTax.code)) ||
        !(
          selectedCodes.includes(tax.code) ||
          (tax.hasRatio && selectedCodes.find((c) => tax.ratios.map((r) => r.code).includes(c)))
        )
    );
    return availableTaxes;
  };

  return (
    <div className="flex flex-col space-y-4">
      {formValues?.additionalTaxes?.map((additionalTax, index) => {
        const availableTaxes = getAvailableTaxes(additionalTax);
        return <TaxInput taxes={availableTaxes} tax={additionalTax} key={index} index={index} />;
      })}
      {formValues?.additionalTaxes?.length < taxes.length && (
        <AddTaxButton onClick={addTax}>Vergi Ekle</AddTaxButton>
      )}
    </div>
  );
}

const TaxInput = ({ taxes = [], tax, index, ...props }) => {
  const [group, setGroup] = useState([]);

  const { setFormValues, formValues } = useItemContext();

  useEffect(() => {
    const groupTax = taxes.find(
      (t) => t.code === tax.code || (t?.ratios && t.ratios.map((r) => r.code).includes(tax.code))
    );

    if (groupTax) setGroup(groupTax);
  }, [taxes?.length]);

  const onChangeAdditionalTax = (groupName, index) => {
    let selectedTax = taxes.find(
      (tax) => tax.groupName.toLocaleLowerCase() === groupName.toLocaleLowerCase()
    );
    setGroup(selectedTax);
    const newTax = {
      name: selectedTax?.groupName,
      groupName: selectedTax?.groupName,
      code: selectedTax?.code,
      percent: 0
    };
    setFormValues((prevValues) => {
      prevValues.additionalTaxes[index] = newTax;
      return {
        ...prevValues
      };
    });
  };

  const onChangeAdditionalTaxRatio = (ratio, index, tax) => {
    setFormValues((prevValues) => {
      const current = prevValues.additionalTaxes[index];
      if (tax?.hasRatio) {
        const selected = tax?.ratios?.find((t) => t.ratio.toString() === ratio.toString());
        prevValues.additionalTaxes[index] = {
          ...current,
          code: selected?.code || null,
          name: tax?.groupName || null,
          percent: selected?.ratio || null
        };
      } else {
        prevValues.additionalTaxes[index] = {
          ...current,
          code: tax.code,
          name: tax?.groupName,
          percent: ratio
        };
      }
      return {
        ...prevValues
      };
    });
  };

  const onDeleteAdditionalTax = (index) => {
    setFormValues((prevValues) => {
      const filteredTaxes = prevValues.additionalTaxes.filter(
        (_, currentIndex) => currentIndex !== index
      );

      return {
        ...prevValues,
        additionalTaxes: [...filteredTaxes]
      };
    });
  };

  return (
    <div className="flex items-center space-x-4" {...props}>
      <Select
        className="w-full max-w-[172px]"
        options={taxes}
        valueLabel="name"
        value={'groupName'}
        label="Vergi Adı"
        onChange={(name) => onChangeAdditionalTax(name, index)}
        defaultValue={group?.groupName}
      />
      {group?.hasRatio ? (
        <Select
          className="w-full max-w-[118px]"
          key={group.name}
          options={group.ratios}
          value={'ratio'}
          valueLabel="listName"
          label="Vergi Oranı"
          onChange={(ratio) => onChangeAdditionalTaxRatio(ratio, index, group)}
          defaultValue={formValues.additionalTaxes[index]?.percent}
        />
      ) : (
        <AmountInput
          label="Vergi Oranı"
          value={tax.percent}
          onValueChange={(ratio) => onChangeAdditionalTaxRatio(ratio, index, group)}
          prefix="%"
          placeholder="%"
        />
      )}
      <div>
        <Button
          className="mt-5"
          size="sm"
          variant="circle"
          color="gray"
          icon={<IconClose className="stroke-current w-5" />}
          onClick={() => onDeleteAdditionalTax(index)}
        />
      </div>
    </div>
  );
};
