import { NavLink } from 'react-router-dom';
import '../../index.css';

const DropdownMenuItem = ({ icon: Icon, title, to, className, hasDivider, ...props }) => {
  const customClass = className ? className : '';

  return (
    <div className="flex flex-col items-start">
      {to ? (
        <NavLink
          exact={true}
          to={to}
          className={`navlink ${customClass}`}
          activeClassName="!text-green-500"
          isActive={(_, location) => to === location.pathname}
          {...props}
        >
          {Icon && <Icon className="fill-current w-4 h-4" />}
          {title}
        </NavLink>
      ) : (
        <p className={`navlink ${customClass}`}>
          {Icon && <Icon className="fill-current w-4 h-4" />}
          {title}
        </p>
      )}
      {hasDivider && <hr className="divider" />}
    </div>
  );
};

export default DropdownMenuItem;
