import { useHistory } from 'react-router-dom';

import { Button } from 'components';

import Logo from 'assets/icons/logo.svg';

const Welcome = () => {
  const history = useHistory();

  return (
    <div className="w-full h-full flex items-center justify-center" data-testid="section_welcome">
      <div className="h-full max-w-[400px] flex flex-col items-center justify-between md:justify-center space-y-12">
        <div className="flex flex-col space-y-6">
          <img src={Logo} alt="logo" width="48" />
          <span className="text-gray-900 text-4xl md:text-[44px] font-semibold">
            Norma’ya
            <br /> hoş geldiniz!
          </span>
          <p className="text-gray-700 leading-6 font-medium mb-12 text-base md:text-lg">
            Kendi işini yönetenlere özel finans platformu Norma’da, finansal işlemler, ön-muhasebe
            ve finansal danışmanlık hizmetleri elinizin altında!
          </p>
        </div>
        <div className="flex flex-col space-y-4 w-full">
          <Button
            variant="primary"
            onClick={() => history.push('/login')}
            data-testid="btn_link_login"
          >
            Giriş Yap
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              localStorage.removeItem('signUpToken');
              history.push('/signup');
            }}
            data-testid="btn_link_signup"
          >
            Hesap Oluştur
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
