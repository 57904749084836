import axios from 'axios';

import { E_TRANSFORMATION_TYPES } from 'utils';

export const getWithHolding = () => {
  return (dispatch) => {
    return axios.get('/api/etransformation/invoice/codes/withholding').then((res) => {
      dispatch({
        type: 'SET_E_TRANSFORMATION_WITH_HOLDING',
        data: res.data?.data
      });
    });
  };
};

export const getUnits = () => {
  return (dispatch) => {
    return axios.get('/api/etransformation/invoice/codes/unit').then((res) => {
      dispatch({
        type: 'SET_E_TRANSFORMATION_UNIT',
        data: res.data?.data
      });
    });
  };
};

export const getTaxCodes = () => {
  return (dispatch) => {
    return axios.get('/api/etransformation/invoice/codes/tax').then((res) => {
      dispatch({
        type: 'SET_E_TRANSFORMATION_TAX_CODES',
        data: res.data?.data
      });
    });
  };
};

export const getItemUnits = () => {
  return (dispatch) => {
    return axios.get('/api/item-units').then((res) => {
      dispatch({
        type: 'SET_E_TRANSFORMATION_ITEM_UNITS',
        data: res.data?.data
      });
    });
  };
};

export const getInvoices = (data) => {
  return (dispatch, getState) => {
    const { user, etransformationType } = getState().auth;

    const controller =
      etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT ? 'receipt' : 'invoice';

    if (!user.companyId) return Promise.reject();
    return axios
      .get(`/api/etransformation/${controller}/${user.companyId}/`, { params: data })
      .then((res) => {
        if (data?.['app-response-needed'] === true) {
          dispatch({
            type: 'SET_RESPONSE_NEEDED_INVOICES',
            data: res.data?.data
          });
        } else if (data?.['app-response-needed'] === false) {
          dispatch({
            type: 'SET_NO_RESPONSE_NEEDED_INVOICES',
            data: res.data?.data
          });
        } else {
          dispatch({
            type: 'SET_INVOICES',
            data: res.data?.data?.content
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const downloadInvoice = ({ companyId, documentId, ...data }) => {
  return (dispatch, getState) => {
    const { etransformationType } = getState().auth;

    const controller =
      etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT ? 'receipt' : 'invoice';

    return axios
      .get(`/api/etransformation/${controller}/${companyId}/${documentId}/download`, {
        params: data
      })
      .then((res) => {
        dispatch({
          type: 'SET_E_DOCUMENT',
          data: 'data:application/pdf;base64,' + res.data?.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const sendInvoiceResponse = ({ companyId, documentId }, data) => {
  return (dispatch, getState) => {
    const { etransformationType } = getState().auth;

    const controller =
      etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT ? 'receipt' : 'invoice';

    return axios.post(
      `/api/etransformation/${controller}/${companyId}/${documentId}/response`,
      data
    );
  };
};

export const getManagementCredential = ({ companyId }) => {
  return axios.get(`/api/etransformation/management/${companyId}/credential`);
};

export const getManagementCredentialCheck = () => {
  return (_, getState) => {
    const { user } = getState().auth;
    return axios.get(`/api/etransformation/management/${user.companyId}/credential/check`);
  };
};

export const getCredential = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios
      .get(`/api/etransformation/management/${user.companyId}/credential`)
      .then((res) => {
        dispatch({
          type: 'SET_E_TRANSFORMATION_CREDENTIAL',
          data: res.data?.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createCredential = (data) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios
      .post(`/api/etransformation/management/${user.companyId}/credential`, {
        active: true,
        transformationType: data.etransformationType,
        ...data
      })
      .then((res) => {
        dispatch({
          type: 'SET_E_TRANSFORMATION_CREDENTIAL',
          data: res.data?.data
        });
      });
  };
};

export const updateCredential = (data) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios
      .put(`/api/etransformation/management/${user.companyId}/credential`, {
        active: true,
        transformationType: data.etransformationType,
        ...data
      })
      .then((res) => {
        dispatch({
          type: 'SET_E_TRANSFORMATION_CREDENTIAL',
          data: res.data?.data
        });
      });
  };
};

export const getDocumentById = ({ companyId, documentId }) => {
  return axios.get(`/api/etransformation/invoice/${companyId}/${documentId}`);
};

export const getDocumentSummaryById = ({ companyId, documentId }) => {
  return (dispatch, getState) => {
    const { etransformationType } = getState().auth;

    const controller =
      etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT ? 'receipt' : 'invoice';

    return axios.get(`/api/etransformation/${controller}/${companyId}/${documentId}/summary`);
  };
};

export const getTaxGroups = (params) => {
  return axios.get(`/api/etransformation/invoice/codes/tax/groups`, { params });
};
