import React, { useState } from 'react';

import cn from 'classnames';

import { ReactComponent as DotsVertical } from 'assets/icons/dots-vertical.svg';
import { ReactComponent as EyeOff } from 'assets/icons/eye-off.svg';
import { ReactComponent as EyeOn } from 'assets/icons/eye-on.svg';
import { ReactComponent as CardNormaWatermark } from 'assets/icons/card-norma-watermark.svg';
import { ReactComponent as CardNormaGrayWatermark } from 'assets/icons/card-norma-gray-watermark.svg';
import { ReactComponent as Visa } from 'assets/icons/visa.svg';
import { ReactComponent as CaretRightIcon } from 'assets/icons/caret-right.svg';

import NormaText from 'assets/images/norma-text.png';

import { Button, PhysicalCard, Card } from 'components';
import CopyToClipboardButton from 'components/copyToClipboardButton';

import { useDispatch } from 'react-redux';
import { getPrepaidCard } from 'store/actions/card';

import { PREPAID_CARD_TYPES, CARD_ACTIVATE_STATUS } from 'utils';

export default function CardCarousel({
  cards,
  walletId,
  closeCardCallback,
  onUpdateCardPin,
  toggleActivatePhysicalCardModal,
  toggleMultiCardModal,
  isInModal,
  index = 0,
  setIndex,
  toggleAddCardPopup
}) {
  const [currentStep, setCurrentStep] = useState(index);

  const handleStepChange = (i) => {
    setIndex(i);
    setCurrentStep(i);
  };

  let CarouselArray = cards.map((card) => ({
    ...card,
    component:
      card?.type === PREPAID_CARD_TYPES.VIRTUAL ? (
        <NormaVirtualCard
          card={card}
          walletId={walletId}
          onUpdateCardPin={onUpdateCardPin}
          toggleMultiCardModal={toggleMultiCardModal}
          isInModal={isInModal}
          closeCardCallback={closeCardCallback}
        />
      ) : (
        <PhysicalCard
          card={card}
          status={card?.status === CARD_ACTIVATE_STATUS.ACTIVE}
          toggleActivatePhysicalCardModal={toggleActivatePhysicalCardModal}
          toggleMultiCardModal={toggleMultiCardModal}
          isInModal={isInModal}
        />
      )
  }));

  if (!isInModal && cards?.length < 2) {
    CarouselArray = [
      ...CarouselArray,
      {
        type: 'AddCard',
        component: <AddCard toggleAddCardPopup={toggleAddCardPopup} />
      }
    ];
  }

  return (
    <div className="w-[391px] h-[292px] bg-gray-100 flex rounded-[10px] pt-8 pb-14 relative overflow-hidden">
      <Carousel>
        {CarouselArray.map((prepaidCard, index) => (
          <Item currentStep={currentStep} show={index} key={`card-${index}`}>
            {prepaidCard.component}
          </Item>
        ))}
      </Carousel>
      <div className="w-[327px] absolute mx-auto bottom-4 right-0 left-0 text-center ">
        <CarouselControllers
          length={CarouselArray?.length}
          currentStep={currentStep}
          handleStepChange={handleStepChange}
        />
      </div>
    </div>
  );
}

const CarouselControllers = ({ length, currentStep, handleStepChange }) => {
  return (
    <nav className="flex items-center justify-between " aria-label="Progress">
      <button
        type="button"
        disabled={currentStep === 0}
        onClick={() => handleStepChange(currentStep - 1)}
        className="text-gray-500  disabled:text-gray-300 "
      >
        <CaretRightIcon className="stroke-current w-7 rotate-180" />
      </button>
      <Dots length={length} currentStep={currentStep} />
      <button
        type="button"
        disabled={currentStep === length - 1}
        onClick={() => handleStepChange(currentStep + 1)}
        className="text-gray-500  disabled:text-gray-300 "
      >
        <CaretRightIcon className="stroke-current w-7 rotate" />
      </button>
    </nav>
  );
};

const Dots = ({ length, currentStep }) => {
  return (
    <>
      <ol className="mx-8 flex items-center space-x-2">
        {length > 0 &&
          [0, 1].slice(0, length).map((step, i) => (
            <li key={`step_${i}`}>
              <Dot green={step == currentStep} />
            </li>
          ))}
      </ol>
    </>
  );
};

const Dot = ({ green = false }) => {
  const classnames = cn('block w-2 h-2 rounded-full ', {
    ['bg-green-500']: green,
    ['bg-gray-300']: !green
  });

  return (
    <a className={classnames}>
      <span className="sr-only"></span>
    </a>
  );
};

const Carousel = ({ children }) => (
  <div className="flex flex-nowrap items-start w-86 sm:w-full absolute left-[26px]">
    {children}
  </div>
);

const Item = ({ children, currentStep, show }) => (
  <div
    className={cn({
      'transition duration-500 ease': true,
      'mx-[6px]': true,
      'scale-y-[80%] contrast-0': !(currentStep === show),
      'translate-x-[calc(-100%-12px)]': currentStep == 1
    })}
  >
    {children}
  </div>
);

export function AddCard({ toggleAddCardPopup }) {
  const cardClassNames = cn(
    'w-[327px] h-[204px] bg-gray-200 text-white rounded-xl p-4 pl-6 flex flex-col justify-between relative z-1 '
  );

  return (
    <Card className={cardClassNames}>
      <CardNormaGrayWatermark className="fill-current text-gray-300 rounded-xl absolute bottom-0 right-0 rotate" />
      <div className="h-full flex flex-col justify-between relative">
        <div className="flex items-start justify-between">
          <div className="mt-2">
            <img src={NormaText} width={99} height={18} alt="norma-text-logo" />
          </div>
        </div>
        <div>
          <div className="w-full flex justify-center space-x-2">
            <Button className="w-[180px] h-[44px]" onClick={toggleAddCardPopup}>
              <span>Kart Ekle</span>
            </Button>
          </div>
        </div>
        <div className="flex justify-between flex-row-reverse">
          <Visa />
        </div>
      </div>
    </Card>
  );
}

const NormaVirtualCard = ({ card, walletId, toggleMultiCardModal, isInModal }) => {
  const [displayCardInfo, setDisplayCardInfo] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const dispatch = useDispatch();

  const toggleDisplayCardInfo = () => {
    if (cardInfo) setDisplayCardInfo(!displayCardInfo);

    if (!displayCardInfo && !cardInfo)
      dispatch(getPrepaidCard({ walletId: walletId, cardId: card?.id, masked: false }))
        .then((res) => {
          setDisplayCardInfo(true);
          setCardInfo(res.data?.data);
        })
        .catch((err) => {
          console.log(err);
          setDisplayCardInfo(false);
        });
  };

  return (
    <div className="w-[327px] h-[204px] bg-gray-900 text-white rounded-xl overflow-hidden p-4 pl-6 flex flex-col justify-between relative z-1">
      <CardNormaWatermark className="fill-current text-gray-800 rounded-xl absolute bottom-0 right-0 rotate z--4  " />
      <div className="h-full flex flex-col justify-between relative z-10">
        <div className="z-20 relative inline-block">
          <div className="flex justify-between item-start">
            <img
              src={NormaText}
              alt="norma text"
              className="mt-2 select-none w-[99px] h-[18px]"
            />
            <div className="w-35 flex">
              <span
                className="w-8 h-8 ml-2 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
                onClick={toggleDisplayCardInfo}
              >
                {displayCardInfo ? (
                  <EyeOn className="fill-current text-gray-500 w-4 h-16" />
                ) : (
                  <EyeOff className="text-gray-500 w-4 h-16" />
                )}
              </span>
              {!isInModal && (
                <span
                  className="w-8 h-8 ml-2 rounded-full bg-gray-500 flex items-center justify-center cursor-pointer"
                  onClick={toggleMultiCardModal}
                >
                  <DotsVertical className="fill-current text-white" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="mt-6 z-10 text-xs text-gray-500 font-medium leading-4">Kart Numarası</div>
        <div className="leading-6 mt-1 flex items-center gap-x-4 z-10">
          <div>
            <span className="text-white text-[22px] leading-7">
              {displayCardInfo && cardInfo ? cardInfo?.cardNo : card?.cardNo}
            </span>
          </div>

          <div className="flex items-center gap-x-1">
            <CopyToClipboardButton
              className="!p-0"
              textToCopy={cardInfo?.cardNo}
              activateTimeout={3}
              disabled={!displayCardInfo}
            />
          </div>
        </div>
        <div className="flex justify-between ">
          <div className="mr-auto font-medium text-xs">
            <span className="text-gray-500 leading-4">SKT</span>
            <div className="text-xl">
              {displayCardInfo && cardInfo?.expiryMonth
                ? `${cardInfo?.expiryMonth}/${cardInfo?.expiryYear}`
                : `${card?.expiryMonth}/${card?.expiryYear}`}
            </div>
          </div>
          <div className="mx-auto font-medium text-xs">
            <span className="text-gray-500 leading-4">CVV</span>
            <div className="text-xl tracking-tight">
              {displayCardInfo && cardInfo?.cvv ? <>{cardInfo?.cvv}</> : <>***</>}
            </div>
          </div>
          <div className="ml-auto mt-6">
            <Visa className="fill-current tracking-tight" />
          </div>
        </div>
      </div>
    </div>
  );
};
