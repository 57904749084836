const initialState = {
  isNotificationModalActive: false,
  notifications: []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_NOTIFICATION_MODAL':
      return {
        ...state,
        isNotificationModalActive: !state.isNotificationModalActive
      };
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.data
      };
    default:
      return { ...state };
  }
};

export default authReducer;
