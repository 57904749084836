import { Button, SideModal, Tab } from 'components';
import React, { useEffect } from 'react';
import { COMPANY_TYPES, DEBT_STATUS, filterKeys } from 'utils';
import {
  CurrentAccountInformation,
  PersonalInformation,
  ContactInformation,
  AccountInformation,
  InvoiceInformation,
  QuickContactCheckbox,
  CompanyInformation,
  CompanyContactInformation
} from './components';
import { useDispatch } from 'react-redux';
import { createContact } from 'store/actions/contacts';
import { getCities } from 'store/actions/country';
import { initialContactFormValues, useContactContext } from 'contexts/contactContext';

export default function CreateContactModal({ onClose = () => {}, onSucceded }) {
  const { formValues, setFormValues, refetchContacts } = useContactContext();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCities());
    return () => {
      setFormValues(initialContactFormValues);
    };
  }, []);
  const onChangeCompanyType = (typeIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      companyType: typeIndex === 0 ? COMPANY_TYPES.INDIVIDUAL : COMPANY_TYPES.BUSINESS
    }));
  };

  const onSubmit = () => {
    let payload = { ...formValues };

    if (!formValues.hasOpeningBalance) {
      payload = filterKeys(payload, ['debtStatus', 'initialBalance', 'initialBalanceDate']);
    }

    payload = filterKeys(
      payload,
      ['hasOpeningBalance', 'isTaxIdentityNumberValid', 'isIbanValid'],
      {
        filterEmpty: true,
        filterNull: true
      }
    );

    dispatch(createContact(payload))
      .then((res) => {
        if (res.status !== 201) return;

        onClose();
        onSucceded(res.data.data);
        refetchContacts();
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  const tabs = [
    { title: 'Bireysel', component: <Individual onSubmit={onSubmit} /> },
    { title: 'Şirket', component: <Business onSubmit={onSubmit} /> }
  ];

  return (
    <SideModal handleClose={onClose}>
      <div
        className="flex flex-col gap-y-10 w-full overflow-y-auto px-4"
        data-testid="section_add_contact_modal"
      >
        <h5 className="font-semibold text-[28px] text-gray-900">Müşteri/Tedarikçi Oluştur</h5>
        <Tab
          tabs={tabs}
          onChange={onChangeCompanyType}
          defaultTabIndex={0}
          tabsClassName="mb-10"
        />
      </div>
    </SideModal>
  );
}

const Individual = ({ onSubmit }) => {
  const { formValues } = useContactContext();

  const isDisabled =
    !formValues?.businessType ||
    !formValues?.firstName ||
    !formValues?.lastName ||
    (formValues.iban && !formValues.isIbanValid) ||
    (formValues?.hasOpeningBalance &&
      ((formValues.initialBalance > 0 && formValues?.debtStatus === DEBT_STATUS.NONE.value) ||
        !formValues?.initialBalanceDate));
  return (
    <div className="flex flex-col gap-y-10 mb-10">
      <CurrentAccountInformation />
      <PersonalInformation />
      <ContactInformation />
      <AccountInformation />
      <InvoiceInformation />
      <QuickContactCheckbox />
      <Button type="button" onClick={onSubmit} disabled={isDisabled}>
        Oluştur
      </Button>
    </div>
  );
};

const Business = ({ onSubmit }) => {
  const { formValues } = useContactContext();

  const isDisabled =
    !formValues?.businessType ||
    !formValues?.companyName ||
    (formValues.iban && !formValues.isIbanValid) ||
    (formValues.taxIdentityNumber && !formValues.isTaxIdentityNumberValid) ||
    (formValues?.hasOpeningBalance &&
      ((formValues.initialBalance > 0 && formValues?.debtStatus === DEBT_STATUS.NONE.value) ||
        !formValues?.initialBalanceDate)) ||
    (formValues.initialBalance > 0 && !formValues.debtStatus);

  return (
    <div className="flex flex-col gap-y-10 mb-10">
      <CurrentAccountInformation />
      <CompanyInformation />
      <AccountInformation />
      <CompanyContactInformation />
      <QuickContactCheckbox />
      <Button type="button" onClick={onSubmit} disabled={isDisabled}>
        Oluştur
      </Button>
    </div>
  );
};
