import React from 'react';
import { ReactComponent as IconPlusSquare } from 'assets/icons/plus-square.svg';
import { ReactComponent as IconCheckCircle } from 'assets/icons/check-circle.svg';
import styles from './index.module.css';
export default function CategoryLabel({
  data,
  selected,
  icon: Icon,
  iconClass,
  onChange,
  size,
  ...props
}) {
  const { id, name, colorHexCode, textColorHexCode } = data;

  return (
    <div
      key={id}
      className={`
      ${styles.base} 
      ${styles[size]} 
      `}
      style={{
        backgroundColor: colorHexCode,
        color: textColorHexCode
      }}
      onClick={() => onChange(id)}
      {...props}
    >
      <span className={`mr-1 ${iconClass}`}>
        {/* {renderIcon()} */}
        {Icon || <IconPlusSquare className=" w-5 fill-current stroke-current text-white" />}
      </span>
      <span className={textColorHexCode ? 'text-inherit' : 'text-black'}>{name}</span>
      {selected === id && (
        <span className="absolute -top-4 -right-1">
          <IconCheckCircle className="w-5" height={40} width={40} />
        </span>
      )}
    </div>
  );
}

CategoryLabel.defaultProps = {
  icon: null,
  size: 'md'
};
