const initialState = {
  withHoldingTaxes: [],
  units: [],
  taxCodes: [],
  itemUnits: [],
  invoices: [],
  noResponseNeededInvoices: {
    content: [],
    last: false,
    first: false,
    empty: false,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 0,
    number: 0,
    pageable: {
      sort: { sorted: false, unsorted: false, empty: false },
      pageNumber: 0,
      pageSize: 0,
      offset: 0,
      paged: false,
      unpaged: false
    },
    sort: { sorted: false, unsorted: false, empty: false }
  },
  responseNeededInvoices: {
    content: [],
    last: false,
    first: false,
    empty: false,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 0,
    number: 0,
    pageable: {
      sort: { sorted: false, unsorted: false, empty: false },
      pageNumber: 0,
      pageSize: 0,
      offset: 0,
      paged: false,
      unpaged: false
    },
    sort: { sorted: false, unsorted: false, empty: false }
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_E_TRANSFORMATION_WITH_HOLDING':
      return {
        ...state,
        withHoldingTaxes: action.data
      };
    case 'SET_E_TRANSFORMATION_UNIT':
      return {
        ...state,
        units: action.data
      };
    case 'SET_E_TRANSFORMATION_TAX_CODES':
      return {
        ...state,
        taxCodes: action.data
      };
    case 'SET_E_TRANSFORMATION_ITEM_UNITS':
      return {
        ...state,
        itemUnits: action.data
      };
    case 'SET_E_TRANSFORMATION_CREDENTIAL':
      return {
        ...state,
        credentials: action.data
      };
    case 'SET_INVOICES':
      return {
        ...state,
        invoices: action.data
      };
    case 'SET_NO_RESPONSE_NEEDED_INVOICES':
      return {
        ...state,
        noResponseNeededInvoices: action.data
      };
    case 'SET_RESPONSE_NEEDED_INVOICES':
      return {
        ...state,
        responseNeededInvoices: action.data
      };
    default:
      return { ...state };
  }
};

export default authReducer;
