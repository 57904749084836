export const ITEM_TYPES = {
  SERVICE: 'SERVICE',
  PRODUCT: 'PRODUCT'
};

export const SITUATIONS = {
  BUYING: 'BUYING',
  SELLING: 'SELLING',
  BUYING_SELLING: 'BUYING_SELLING'
};

export const CURRENCIES = {
  TRY: 'TRY',
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP'
};

export const ITEM_TYPE_NAMES = {
  SERVICE: 'Hizmet',
  PRODUCT: 'Ürün'
};

export const CURRENCY_SIGNS = {
  TRY: '₺',
  EUR: '€',
  USD: '$',
  GBP: '£'
};
