import React, { useContext, useState } from 'react';
import { Button, CodeInput, Popup } from 'components';
import StepHeader from 'components/stepHeader';
import { ActivatePhysicalCardContext } from '../..';
import { useSelector } from 'react-redux';

import { useCountdownTimer } from 'utils/hooks';
import { OTP_TIMEOUT } from 'utils';

import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import useModal from 'utils/hooks/useModal';
import { useDispatch } from 'react-redux';
import { activatePrepaidCard, checkPrepaidCard } from 'store/actions/card';

export default function ConfirmSmsToken() {
  const { nextStep, wallet, formValues } = useContext(ActivatePhysicalCardContext);
  const [smsToken, setSmsToken] = useState('');
  const { user } = useSelector((state) => state.auth);
  const { isCounting, timeByMinute, restart } = useCountdownTimer(OTP_TIMEOUT, true, () => {});

  const [isNotActivatedPopupActive, setIsNotActivatedPopupActive, toggleNotActivatedPopup] =
    useModal({ defaultMode: false });

  const dispatch = useDispatch();

  const { checkCardData } = useSelector((state) => state.card);

  const onApproveSmsToken = () => {
    dispatch(
      activatePrepaidCard({
        walletId: wallet?.id,
        cardId: wallet?.prepaidCards[0]?.id,
        cardNumber: formValues.cardNumber,
        mfaToken: checkCardData?.mfaToken,
        otpToken: smsToken,
        pin: formValues.confirmPassword
      })
    )
      .then(() => {
        nextStep();
      })
      .catch(() => setIsNotActivatedPopupActive(true));
  };

  const onRenewSMSToken = () => {
    dispatch(
      checkPrepaidCard({
        walletId: wallet?.id,
        cardId: wallet?.prepaidCards[0]?.id
      })
    ).then(() => {
      restart();
    });
  };

  return (
    <div className="flex flex-col gap-y-10">
      {isNotActivatedPopupActive && <NotActivatedPopup onClose={toggleNotActivatedPopup} />}
      <div>
        <StepHeader
          title="Kart Aktifleştirme"
          subtitle={`${user?.fullPhoneNumber} numaranıza gelen 4 haneli kodu girin`}
          className="!mb-10"
        />
        <div>
          <CodeInput
            length={4}
            invisible
            onChange={setSmsToken}
            className="mt-4"
            only="number"
            value={smsToken}
            blockPaste
            autofocus
          />
        </div>
      </div>
      <div className="flex flex-col items-start gap-x-4">
        <p className="text-gray-700 font-medium leading-6">SMS ulaşmadı mı?</p>
        {isCounting && <p className="text-green-500 leading-6 font-semibold">{timeByMinute} </p>}
        {!isCounting && (
          <Button variant="ghost" className="!p-0" onClick={onRenewSMSToken}>
            Tekrar Gönder
          </Button>
        )}
      </div>
      <Button
        className="w-full"
        disabled={!isCounting || smsToken?.length !== 4}
        onClick={onApproveSmsToken}
      >
        Onayla
      </Button>
    </div>
  );
}

const NotActivatedPopup = ({ onClose }) => (
  <Popup
    active
    childrenClassName="w-[327px] h-[320px] flex flex-col items-center justify-between text-center rounded-xl"
  >
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-100">
      <IconInfoCircle className="fill-current w-10 text-red-600" />
    </div>
    <div className="flex flex-col gap-y-3">
      <span className="text-lg font-semibold text-gray-900">Kartınız Aktifleştirilemedi </span>
      <span className="text-base font-medium text-gray-500">
        Lütfen daha sonra tekrar deneyin
      </span>
    </div>
    <Button className="w-full" onClick={onClose}>
      Geri Dön
    </Button>
  </Popup>
);
