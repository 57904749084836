import React from 'react';
import classNames from 'classnames';

export default function LineInfo({
  title,
  description,
  className,
  titleClassName,
  descriptionClassName
}) {
  const lineInfoClassNames = classNames('flex flex-col gap-y-2 w-full min-w-max', className);
  const titleClassNames = classNames(
    'font-medium text-xs text-gray-500 leading-4',
    titleClassName
  );
  const descriptionClassNames = classNames(
    'text-xs font-semibold leading-4 text-gray-700',
    descriptionClassName
  );
  return (
    <div className={lineInfoClassNames}>
      <p className={titleClassNames}>{title}</p>
      <p className={descriptionClassNames}>{description}</p>
    </div>
  );
}
