import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { BottomModal, CustomButton, CustomInput, Select } from 'components';
import { getCities, getCountries, getDistrict } from 'store/actions/country';

// import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import CardCorrect from 'assets/illustrations/card/card-correct.png';
import { PREPAID_CARD_TYPES } from 'utils';
import { createPrepaidCard } from 'store/actions/card';
import { getWallets } from 'store/actions/accounts';
import { WALLET_TYPES } from 'utils/contants';

const PhysicalCardConfirmationModal = ({ walletId, onClose }) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  function nextStep() {
    setStep(step + 1);
  }

  const defaultValues = {
    cityId: null,
    districtId: null,
    address: null
    // countryId: 1,
    // companyId: null
  };

  const { watch, setValue, handleSubmit } = useForm({ defaultValues });

  function onSubmit(data) {
    const payload = {
      type: PREPAID_CARD_TYPES.PHYSICAL,
      deliveryAddress: {
        ...data,
        cityId: Number(data.cityId),
        districtId: Number(data.districtId)
      }
    };

    dispatch(createPrepaidCard({ walletId, payload }))
      .then((res) => {
        if (![200, 201, 204].includes(res.status)) return;
        dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const TABS = {
    0: <Step0 watch={watch} setValue={setValue} />,
    1: <Step1 handleClose={onClose} />
  };

  return (
    <BottomModal handleClose={onClose} className={step === 1 && 'bg-green-500'}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        <div className="w-full h-full flex items-center justify-center">{TABS[step]}</div>
      </form>
    </BottomModal>
  );
};

const StepHeader = ({ title, subtitle }) => (
  <div className="flex flex-col">
    <span className="text-gray-400 text-sm font-semibold">{title}</span>
    <span className="text-gray-900 text-xl font-semibold">{subtitle}</span>
  </div>
);

const Step0 = ({ watch, setValue }) => {
  const dispatch = useDispatch();

  const { cities, districts, countries } = useSelector((state) => state.country);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCities({ country: 'TR' }));
  }, []);

  function _getDistrict(cId) {
    dispatch(getDistrict({ cityId: cId }));
  }

  function handleCity(cId) {
    setValue('cityId', cId);
    setValue('districtId', null);
    if (!Object.keys(districts).includes(cId)) _getDistrict(cId);
  }
  const filteredDistricts =
    watch('cityId') && districts[watch('cityId')] ? districts[watch('cityId')] : [];

  return (
    <div className="flex flex-col">
      <StepHeader title="Kart Sipariş Et" subtitle="Kart teslimatı için adresinizi giriniz" />
      <div className="h-[600px] overflow-y-scroll">
        <div className="w-96 flex flex-col gap-y-4 mt-8">
          <Select
            options={countries}
            value="id"
            valueLabel="name"
            label="Ülke"
            defaultValue={1}
            disabled
          />
          <Select
            options={cities}
            value="id"
            valueLabel="name"
            label="İl"
            onChange={handleCity}
          />
          <Select
            options={filteredDistricts}
            value="id"
            valueLabel="name"
            label="İlçe"
            onChange={(dId) => setValue('districtId', dId)}
          />
          <CustomInput label="Adres" onChange={(e) => setValue('address', e.target.value)} />
          <CustomButton
            color="success"
            type="submit"
            disabled={!watch('cityId') || !watch('districtId') || !watch('address')}
          >
            Devam
          </CustomButton>
        </div>
        {/* <div className="w-96 bg-gray-100 border border-solid border-gray-200 rounded-lg mt-6 p-3 flex items-start justify-center gap-x-3">
          <IconInfoCircle className="fill-current w-6 text-gray-400 mt-4" />
          <span className="text-gray-500 break-words w-4/5">
            Yeni Norma Kart isteğiniz belirteceğiniz adrese ücretsiz olarak gönderilecektir.
            Lütfen adresinizi eksiksiz girdiğinizden emin olun.
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Step1 = ({ handleClose }) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-96 text-center flex flex-col items-center">
        <img src={CardCorrect} alt="card-correct" width={200} />
        <span className="text-2xl text-white mt-8">Siparişiniz Alındı</span>
        <span className="text-white mt-2">
          Norma Card elinize ulaştığında kartınızı Norma uygulaması üzerinden
          aktifleştirebilirsiniz
        </span>
        <CustomButton color="success" onClick={handleClose} className="w-full mt-6">
          Kapat
        </CustomButton>
      </div>
    </div>
  );
};

export default PhysicalCardConfirmationModal;
