import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';

const ItemsTable = ({ items, currencyCode }) => {
  return (
    <table className="w-full mb-10">
      <thead>
        <tr className="text-left text-xs font-semibold text-purple-700 border-b border-solid border-gray-100">
          <th className="py-4">Mal Hizmet</th>
          <th className="py-4 text-center">Miktar</th>
          <th className="py-4 text-right">Birim Fiyat</th>
          <th className="py-4 text-right">Mal Hizmet Tut.</th>
        </tr>
      </thead>
      <tbody>
        {items.map((c, i) => (
          <tr
            key={i}
            className="border-b border-solid border-gray-100 hover:bg-gray-50 group transition cursor-pointer"
          >
            <td className="flex items-center gap-x-4 py-6 text-sm font-semibold text-gray-900 text-right">
              {c?.itemName || ''}
            </td>
            <td className="py-6 text-xs font-semibold text-gray-500 text-center">
              {c?.quantity || ''}
            </td>
            <td className="py-6 text-xs font-semibold text-gray-500 text-right">
              {c?.unitAmount && currencyCode
                ? `${currencyFormat(c?.unitAmount)} ${CURRENCY_NAMES[currencyCode]}`
                : ''}
            </td>
            <td className="py-6 text-sm font-semibold text-gray-900 text-right">
              {c?.baseAmount && currencyCode
                ? `${currencyFormat(c?.baseAmount)} ${CURRENCY_NAMES[currencyCode]}`
                : ''}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ItemsTable;
