import { Popover } from '@headlessui/react';

import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { Checkbox } from 'components';
import { currencyFormat, dateFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';

const SelectTransferDropdown = ({ transfers, search, setSearch, onSelect, selectedItems }) => (
  <Popover className="relative w-full">
    <Popover.Button
      as="div"
      className="w-full rounded-lg bg-gray-100 border border-solid border-gray-200 p-6 mt-3 flex items-center justify-between cursor-pointer"
      data-testid="button_select_transfer_dropdown"
    >
      <span>İşlem Seç</span>
      <IconCaretDown className="stroke-current w-6 text-gray-400" />
    </Popover.Button>
    <Popover.Panel className="absolute z-10 h-[348px] w-full p-6 mt-3 rounded-xl bg-white border border-solid border-gray-200">
      <div className="flex h-auto items-center space-x-3 w-full px-2 py-2 bg-gray-100 border-black border-1 border-opacity-10 rounded-lg focus:outline-none focus:bg-white focus:shadow-md">
        <IconSearch className="fill-current text-gray-400" />
        <input
          placeholder="Arama"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="appearance-none bg-transparent outline-none text-black w-full"
          data-testid="input_select_transfer_dropdown"
        />
      </div>
      <div className="flex flex-col w-full divide-y divide-solid divide-gray-100 overflow-y-scroll h-64 mt-6">
        {transfers.map((transfer) => (
          <div key={transfer?.id} className="w-full flex justify-between py-4">
            <div className="flex gap-x-2 items-center justify-center">
              <Checkbox
                checked={selectedItems.includes(transfer.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    onSelect([...selectedItems, transfer?.id], transfer.id);
                  } else {
                    onSelect(
                      selectedItems.filter((id) => id !== transfer?.id),
                      transfer.id
                    );
                  }
                }}
                data-testid="checkbox_select_transfer_dropdown"
              />
              <span className="w-10 h-10 rounded-full bg-purple-700 text-purple-300 font-semibold flex items-center justify-center">
                {transfer?.receiverAccount?.companyName
                  ? transfer?.receiverAccount?.companyName
                      .split(' ')
                      .map((s) => s[0])
                      .slice(0, 2)
                      .join('')
                  : `${transfer?.receiverAccount?.firstName[0]} ${transfer?.receiverAccount?.lastName[0]}`}
              </span>
              <div className="flex flex-col">
                <span className="font-semibold text-gray-900">
                  {transfer?.receiverAccount?.companyName ||
                    `${transfer?.receiverAccount?.firstName} ${transfer?.receiverAccount?.lastName}`}
                </span>
                <span className="text-xs font-medium text-gray-500">
                  {dateFormat(new Date(transfer?.transferDate))}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="font-semibold text-gray-900">
                {`${currencyFormat(transfer?.amount)} ${CURRENCY_NAMES[transfer?.currency]}`}
              </span>
              <span className="text-xs font-medium text-gray-500">{transfer?.partyName}</span>
            </div>
          </div>
        ))}
      </div>
    </Popover.Panel>
  </Popover>
);

export default SelectTransferDropdown;
