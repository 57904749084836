import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Filter } from 'assets/icons/filter-2.svg';

import { ReactComponent as IconCloseSquare } from 'assets/icons/close-square.svg';
import { ReactComponent as CloseSquareFilledIcon } from 'assets/icons/close-square-filled.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as PlusSquareIcon } from 'assets/icons/plus-square.svg';
import { ReactComponent as IconColoredWindow } from 'assets/icons/colored-window.svg';
import { ReactComponent as IconColoredBuilding } from 'assets/icons/colored-building.svg';

import { ReactComponent as IconFileDocument } from 'assets/icons/file-document.svg';
import Refresh from 'assets/icons/refresh.png';
import { ReactComponent as TransactionList } from 'assets/illustrations/accounts/transaction-list.svg';
import DoubleCheckIllustration from 'assets/illustrations/card/double-check.png';

import { ReactComponent as IconArchive } from 'assets/icons/icon-archive.svg';
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';

import {
  CustomButton,
  BottomModal,
  AddCategoryModal,
  Button,
  Card as Box,
  Popup,
  SearchInput,
  ExportButton,
  CardCarousel,
  EmptyView
} from 'components';
import toast from 'react-hot-toast';

import { useEffect, useRef, useState } from 'react';
import ChangeCardPasswordModal from './changeCardPasswordModal';
import RemoveCardModal from './RemoveCardModal';
import FiltersModal from './FiltersModal';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import OpenAnAccountModal from './OpenAnAccountModal';
import GetCardModal from './GetCardModal';
import TransactionDetailModal from './TransactionDetailModal';
import {
  currencyFormat,
  PREPAID_CARD_TYPES,
  filterKeys,
  getFormattedDateWithSeperator,
  TRANSACTION_TYPES,
  downloadFileByUrl
} from 'utils';
import CopyToClipboardButton from 'components/copyToClipboardButton';
import { useDispatch, useSelector } from 'react-redux';
import { getWallets } from 'store/actions/accounts';
// import { getQRCodes } from 'store/actions/qrCodes';
import { getCategories } from 'store/actions/categories';
import AddCardModal from './AddCardModal';

import {
  getTransaction,
  getTransactionGroupByType,
  getTransactions,
  updateTransactionCategory
} from 'store/actions/transactions';
import { Popover } from '@headlessui/react';
import { createPrepaidCard } from 'store/actions/card';

import { useForm } from 'react-hook-form';
import useModal from 'utils/hooks/useModal';
import { useRouter } from 'utils/hooks/useRouter';
import {
  MONTHS,
  TRANSACTION_DIRECTION,
  EXPENSE_TYPE_TEXTS,
  TYPE_TEXTS,
  WALLET_TYPES
} from 'utils/contants';
import ReceiptModal from './ReceiptModal';
import QRCodeModal from './QRCodeModal';
import { ReactComponent as IconQRCode } from 'assets/icons/qr-code.svg';

import ReactDom from 'react-dom';
import cn from 'classnames';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import useDebounce from 'utils/hooks/useDebounce';
import { closeWallet, exportWalletTransactions } from 'store/actions/wallet';
import PhysicalCardConfirmationModal from './physicalCardConfirmationModal';
import CreateWalletModal from './createWalletModal';
import WalletTypeSelectionModal from './walletTypeSelectionModal';
import AccountSettingsMenu from './accountSettingsMenu';
import EditWallet from './editWalletSideModal';
import AddTransactionSideModal from './addTransactionSideModal';
import SuccessModal from 'components/successModal';
import ArchiveAccountPopup from './archiveAccountPopup';
import { archiveWallet } from 'store/actions/archive';
import ClosePhysicalCardPopup from './closePhysicalCardPopup';
import { CURRENCIES } from 'views/other/items/enums';
import classNames from 'classnames';
import ActivatePhysicalCardModal from './activatePhysicalCardModal';
import MulticardModal from './multicardModal';
import WalletSelectionModal from './walletSelectionModal';
import { useWalletContext } from 'contexts/walletContext';
import WalletTypeSelectionButton from './walletTypeSelectionButton';
import OpenBankingCreateModal from './openBankingCreateModal';
import { deleteOpenBankingBankAccount } from 'store/actions/openBanking';

const defaultFiltersValues = {
  direction: null,
  'expense-type': null,
  'category-id': '',
  type: null
};

const Accounts = () => {
  const dispatch = useDispatch();
  const { query, location, history } = useRouter();

  const [selectedTransactionId, setSelectedTransactionId] = useState();
  const [wallets, setWallets] = useState();
  const accounts = useSelector((state) => state.accounts);
  const accountsLoading = useSelector((state) => state.accountsLoading);
  const { user } = useSelector((state) => state.auth);
  const [closedAccount, setClosedAccount] = useState(null);

  const {
    toggleWalletSelectionModal,
    isWalletSelectionModalActive,
    selectedWalletType,
    setSelectedWalletType,
    isOpenBankingCreateModalActive,
    toggleOpenBankingCreateModal,
    tab,
    setTab,
    isCreateWalletModalActive,
    toggleCreateWalletModal,
    selectedWallet,
    setSelectedAccount,
    selectedAccount
  } = useWalletContext();
  // Filters Modal
  const [isFiltersModalActive, , toggleFiltersModal] = useModal({ shouldBeBlockScroll: true });

  const filtersForm = useForm({ defaultValues: { ...defaultFiltersValues } });

  // Change Card Password Modal
  const [isChangeCardPasswordModalActive, , toggleChangeCardPasswordModal] = useModal({
    shouldBeBlockScroll: true
  });

  // Remove Card Modal
  const [isRemoveCardModalActive, , toggleRemoveCardModal] = useModal({
    shouldBeBlockScroll: true
  });

  // Remove Card Comfirmation Popup
  const [isRemoveCardConfirmationPopupActive, , toggleRemoveCardConfirmationPopup] = useModal({
    shouldBeBlockScroll: true
  });

  // Open An Account Modal
  const [isOpenAnAccountModalActive, , toggleOpenAnAccountModal] = useModal({
    defaultMode: false,
    handleClose: handleCloseOpenAccountModal,
    shouldBeBlockScroll: true
  });
  // Get Card
  const [isGetCardModalActive, , toggleGetCardModal] = useModal({ shouldBeBlockScroll: true });

  // Add Card
  const [isAddCardModalActive, , toggleAddCardModal] = useModal({ shouldBeBlockScroll: true });

  // Transaction Detail
  const [isTransactionDetailModalActive, , toggleTransactionDetailModal] = useModal({
    shouldBeBlockScroll: true
  });

  // Close Account
  const [isCloseAccountTransactionPopupActive, setIsCloseAccountTransactionPopupActive] =
    useModal({
      shouldBeBlockScroll: true
    });

  const [isCloseAccountPopupActive, setIsCloseAccountPopupActive] = useModal({
    shouldBeBlockScroll: true
  });

  const [isAddCardPopupActive, , toggleAddCardPopup] = useModal({
    shouldBeBlockScroll: true
  });

  const [
    isPrepaidCardConfirmationActive,
    setIsPrepaidCardConfirmationActive,
    togglePrepaidCardConfirmationActive
  ] = useModal({
    shouldBeBlockScroll: true
  });

  const [
    isCloseAccountModalActive,
    setIsCloseAccountModalActive,
    toggleCloseAccountTransactionModal
  ] = useModal({
    shouldBeBlockScroll: true
  });

  const [isWallletCloseModalActive, setIsWallletCloseModalActive] = useModal({
    shouldBeBlockScroll: true
  });

  const [isPhysicalCardModalActive, setIsPhysicalCardModalActive, togglePhysicalCardModal] =
    useModal({
      shouldBeBlockScroll: true
    });

  const [
    isWalletTypeSelectionModalActive,
    setIsWalletTypeSelectionModalActive,
    toggleWalletTypeSelectionModal
  ] = useModal({
    shouldBeBlockScroll: true
  });

  const [isWalletEditSideModalActive, , toggleWalletEditSideModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [
    isArchiveAccountSuccessModalActive,
    setIsArchiveAccountSuccessModalActive,
    toggleArchiveAccountSuccessModal
  ] = useModal({
    shouldBeBlockScroll: true
  });

  const [isArchiveAccountPopupActive, setIsArchiveAccountPopupActive, toggleArchiveAccountPopup] =
    useModal({
      shouldBeBlockScroll: true
    });

  const [isCloseOpenBankingAccountPopupActive, , toggleCloseOpenBankingAccountPopup] = useModal({
    shouldBeBlockScroll: true
  });

  const primaryAccount = accounts.find((a) => a.isPrimary);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getTransactionGroupByType());

    if (query?.redirectUrl && query?.currencyCode) {
      toggleOpenAnAccountModal();
    }
  }, []);

  useEffect(() => {
    filtersForm.reset();
  }, [tab]);

  useEffect(() => {
    if (user.companyId) _getWallets();
  }, [user, isOpenAnAccountModalActive, selectedWalletType]);

  useEffect(() => {
    const defaultAccountIndex = accounts.findIndex((a) => a.id === Number(query.accountId));
    if (location.state?.accountIndex) {
      setTab(location.state?.accountIndex);
      history.replace(location.pathname, {});
    } else if (defaultAccountIndex !== -1) setTab(defaultAccountIndex);

    if (accounts?.length > 0) setSelectedAccount(accounts[tab || 0]);
  }, [accounts]);

  useEffect(() => {
    setWallets(accounts || []);
  }, [accounts]);

  function handleTab(i) {
    setSelectedAccount(wallets[i]);
    filtersForm.reset();
    setTab(i);
  }

  function handleFilters(filter) {
    filtersForm.setValue('category-id', filter['category-id'] || null);
    filtersForm.setValue('direction', filter.direction || null);
    filtersForm.setValue('expense-type', filter['expense-type'] ?? null);
    filtersForm.setValue('type', filter.type || null);
    filtersForm.setValue('date', filter?.date || null);
  }

  function _getWallets(type) {
    if (selectedWalletType !== WALLET_TYPES.OPEN_BANKING.value) {
      dispatch(
        getWallets({
          status: 'ACTIVE',
          type: type || selectedWalletType
        })
      );
    }
  }

  function handleCloseOpenAccountModal() {
    _getWallets();
  }

  function handleCloseAccountConfirmation(accountIndex) {
    const _account = wallets[accountIndex];
    setSelectedAccount(_account);

    if (_account?.type === WALLET_TYPES.EMONEY.value) {
      if (
        _account?.autoBillPayment ||
        _account?.balance?.balance > 0 ||
        _account?.prepaidCards?.length ||
        _account?.autoTransaction
      ) {
        return setIsCloseAccountTransactionPopupActive(true);
      }
    }
    // for closing account popup
    return setIsCloseAccountPopupActive(true);
  }

  function handleCloseAccount() {
    setClosedAccount(selectedAccount);
    dispatch(closeWallet({ walletId: selectedAccount.id }))
      .then(() => {
        _getWallets();
        handleClose();
        setIsWallletCloseModalActive(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleCreatePrepaidCard = () => {
    const payload = {
      type: PREPAID_CARD_TYPES.VIRTUAL
    };
    if (selectedAccount?.id) {
      dispatch(createPrepaidCard({ walletId: selectedAccount.id, payload }))
        .then((res) => {
          if (![200, 201, 204].includes(res.status)) return;
          toast.success('Sanal kartınız oluşturulmuştur');
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function handleClose() {
    setIsCloseAccountTransactionPopupActive(false);
    setIsCloseAccountPopupActive(false);
    setIsCloseAccountModalActive(false);
    setIsPrepaidCardConfirmationActive(false);
    setIsWallletCloseModalActive(false);
    _getWallets();
  }

  function handleTransactionDetail(transaction) {
    if (selectedWalletType === WALLET_TYPES.EMONEY.value) {
      setSelectedTransactionId(transaction.id);
      dispatch(
        getTransaction({ walletId: selectedAccount.id, transactionId: transaction.id })
      ).then((data) => {
        setSelectedTransactionId(data.id);
        toggleTransactionDetailModal();
      });
    }
  }

  const tabs = wallets
    ?.filter((wallet) => wallet.type === selectedWalletType)
    ?.map((acc, index) => ({
      title: acc.name,
      page: (
        <Account
          key={acc.id || acc.length}
          accountIndex={index}
          account={acc}
          hasAccount={wallets?.filter((wallet) => wallet.type === selectedWalletType)?.length > 0}
          //cards={cards}
          toggleFiltersModal={toggleFiltersModal}
          toggleGetCardModal={toggleGetCardModal}
          // toggleAddCardModal={toggleAddCardModal}
          toggleAddCardPopup={toggleAddCardPopup}
          togglePrepaidCardConfirmationActive={togglePrepaidCardConfirmationActive}
          filtersForm={filtersForm}
          closeCardCallback={toggleRemoveCardConfirmationPopup}
          handleChangeCardPassword={toggleChangeCardPasswordModal}
          handleTransactionDetail={handleTransactionDetail}
          shouldUpdateFilters={isFiltersModalActive}
          //getCards={_getCards}
          selectedWalletType={selectedWalletType}
        />
      )
    }));

  const CardConfirmationPopup = ({ active, closePopup, handleCreatePrepaidCard }) => {
    return ReactDom.createPortal(
      <CustomPopup active={active} childrenClassName="!w-full !max-w-md" onClose={closePopup}>
        <div className="flex flex-col items-center text-center">
          <span className="rounded-full w-20 h-20 bg-yellow-100 flex items-center justify-center">
            <InfoCircleIcon className="fill-current text-yellow-500 w-8" />
          </span>
          <div className="mt-6 mb-2 text-xl font-semibold leading-4">Sanal Kart Oluşturma</div>
          <span className="text-gray-500 mt-3">
            Sanal kartınız oluşturulacaktır. <br />
            Onaylıyor musunuz?
          </span>
          <CustomButton color="green" className="w-full my-4" onClick={handleCreatePrepaidCard}>
            <span>Evet</span>
          </CustomButton>
          <CustomButton color="danger" className="w-full" onClick={closePopup}>
            <span>Hayır</span>
          </CustomButton>
        </div>
      </CustomPopup>,
      document.body
    );
  };

  const AddCardPopup = ({
    active,
    closePopup,
    togglePrepaidCardConfirmationActive,
    setIsPhysicalCardModalActive,
    activeCards
  }) => {
    const DebitCard = ({
      cardName,
      description,
      icon: Icon = null,
      activeCard = true,
      onClick
    }) => {
      const classnames = cn('!border', {
        ['!bg-white border-solid !border-white cursor-pointer']: activeCard,
        ['!bg-gray-100 border-solid !border-gray-200 !shadow-none']: !activeCard
      });

      const cardNameClassName = cn('text-base text-gray-900 leading-6 font-semibold', {
        '!text-gray-500': !activeCard
      });
      const descriptionClassName = cn('text-sm text-gray-600 leading-5 font-medium', {
        '!text-gray-500': !activeCard
      });
      const iconClassName = cn('!w-9 !h-9 ', { grayscale: !activeCard });
      return (
        <Box className={classnames} onClick={activeCard && onClick}>
          <div>
            <div className="flex gap-x-5 w-full">
              {Icon && <Icon className={iconClassName} />}
              <div>
                <div className="flex justify-between w-full mb-2">
                  <span className={cardNameClassName}>{cardName}</span>
                  {/* {!activeCard && (
                    <span className="px-3 py-1 rounded-full bg-gray-200 flex items-center justify-center select-none text-gray-900">
                      Yakında
                    </span>
                  )} */}
                </div>
                <div className={descriptionClassName}>{description}</div>
              </div>
            </div>
          </div>
        </Box>
      );
    };

    return ReactDom.createPortal(
      <Popup active={active} childrenClassName="!w-full !max-w-md" onClose={closePopup}>
        <div className="mb-4 text-2xl font-semibold leading-7">Kart Ekle</div>
        <div className="flex flex-col gap-y-4">
          <DebitCard
            icon={IconColoredBuilding}
            cardName="Sanal Kart"
            description="Online ödemelerimde kullanmak için bir sanal kart"
            activeCard={!activeCards?.some((card) => card.type === PREPAID_CARD_TYPES.VIRTUAL)}
            onClick={() => {
              closePopup();
              togglePrepaidCardConfirmationActive();
            }}
          />
          <DebitCard
            icon={IconColoredWindow}
            cardName="Fiziksel Kart Sipariş Et"
            description="Norma Card’ı tüm atm’lerde, online ve pos ödemelerinde kullan."
            activeCard={!activeCards?.some((card) => card.type === PREPAID_CARD_TYPES.PHYSICAL)}
            onClick={() => {
              closePopup();
              setIsPhysicalCardModalActive(true);
            }}
          />
        </div>
      </Popup>,
      document.body
    );
  };

  const onWalletTypeSelectedFromModal = (type) => {
    setSelectedWalletType(type);
    setIsWalletTypeSelectionModalActive(false);
    setTab(0);
    if (type === WALLET_TYPES.OPEN_BANKING.value) return toggleOpenBankingCreateModal();
    if (type === WALLET_TYPES.EMONEY.value) return toggleOpenAnAccountModal();

    toggleCreateWalletModal();
  };

  const onCloseCreateWalletModal = () => {
    const hasAccount = accounts.some((acc) => acc.type === selectedWalletType);
    if (accounts.length !== 0) setTab(accounts.length - 1);
    if (!hasAccount) {
      setSelectedWalletType(selectedAccount.type);
    }
    toggleCreateWalletModal();
  };

  const onCreateWallet = () => {
    setIsWalletTypeSelectionModalActive(true);
  };

  const onArchiveAccountApproved = () => {
    dispatch(archiveWallet(selectedAccount.id)).then(() => {
      setIsArchiveAccountPopupActive(false);
      setIsArchiveAccountSuccessModalActive(true);
    });
  };

  const onCloseArchiveAccountSuccessModal = () => {
    dispatch(getWallets({ status: 'ACTIVE', type: selectedAccount.type })).then((data) => {
      if (data.length === 0) {
        setSelectedWalletType(WALLET_TYPES.EMONEY.value);
        dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
      }
    });
    setTab(0);
    toggleArchiveAccountSuccessModal();
  };

  const onDeleteOpenBankingAccount = (tabIndex) => {
    const account = wallets[tabIndex];
    setSelectedAccount(account);
    toggleCloseOpenBankingAccountPopup();
  };

  const onApproveDeleteCloseOpenBankingAccount = () => {
    dispatch(deleteOpenBankingBankAccount(selectedWallet.openBankingId, selectedAccount.id)).then(
      () => {
        toggleCloseOpenBankingAccountPopup();
        _getWallets(selectedWalletType);
        setTab((tab) => (tab > 0 ? tab - 1 : 0));
        dispatch(
          getWallets({
            status: 'ACTIVE',
            type: selectedWalletType,
            openBankingId: selectedWallet.openBankingId
          })
        );
      }
    );
  };

  return (
    <div
      className="relative bg-gray-200 flex flex-col justify-start items-center py-12 min-h-screen"
      data-testid="section_accounts"
    >
      {isRemoveCardConfirmationPopupActive && (
        <ClosePhysicalCardPopup
          onClose={toggleRemoveCardConfirmationPopup}
          onReject={toggleRemoveCardConfirmationPopup}
          onApproved={() => {
            toggleRemoveCardModal();
            toggleRemoveCardConfirmationPopup();
          }}
        />
      )}
      {isArchiveAccountSuccessModalActive && (
        <SuccessModal
          actionButtonText="Kapat"
          successMessage={`${selectedAccount?.name} adlı hesap başarıyla arşivlendi.`}
          onClose={onCloseArchiveAccountSuccessModal}
          onActionButtonClick={onCloseArchiveAccountSuccessModal}
        />
      )}
      {isArchiveAccountPopupActive && (
        <ArchiveAccountPopup
          selectedAccount={selectedAccount}
          onClose={toggleArchiveAccountPopup}
          onReject={toggleArchiveAccountPopup}
          onApproved={onArchiveAccountApproved}
        />
      )}
      {isWalletEditSideModalActive && (
        <EditWallet onClose={toggleWalletEditSideModal} selectedAccount={selectedAccount} />
      )}
      {isWalletTypeSelectionModalActive && (
        <WalletTypeSelectionModal
          onTypeSelected={onWalletTypeSelectedFromModal}
          onClose={toggleWalletTypeSelectionModal}
        />
      )}
      {isOpenBankingCreateModalActive && (
        <OpenBankingCreateModal onClose={toggleOpenBankingCreateModal} />
      )}
      {isCreateWalletModalActive && (
        <CreateWalletModal walletType={selectedWalletType} onClose={onCloseCreateWalletModal} />
      )}
      {isPhysicalCardModalActive && (
        <PhysicalCardConfirmationModal
          walletId={selectedAccount.id}
          onClose={togglePhysicalCardModal}
        />
      )}
      <CloseAccountTransactionPopup
        isCloseAccountTransactionPopupActive={isCloseAccountTransactionPopupActive}
        setIsCloseAccountTransactionPopupActive={setIsCloseAccountTransactionPopupActive}
        selectedAccount={selectedAccount}
      />

      <CloseAccountPopup
        isCloseAccountPopupActive={isCloseAccountPopupActive}
        setIsCloseAccountPopupActive={setIsCloseAccountPopupActive}
        selectedAccount={selectedAccount}
        handleCloseAccount={handleCloseAccount}
      />
      {isTransactionDetailModalActive && (
        <TransactionDetailModal
          accountId={selectedAccount?.id}
          transactionId={selectedTransactionId}
          toggleTransactionDetailModal={toggleTransactionDetailModal}
        />
      )}
      {isAddCardModalActive && (
        <AddCardModal
          toggleAddCardModal={toggleAddCardModal}
          primaryAccountId={primaryAccount.id}
        />
      )}
      <AddCardPopup
        active={isAddCardPopupActive}
        closePopup={toggleAddCardPopup}
        togglePrepaidCardConfirmationActive={togglePrepaidCardConfirmationActive}
        setIsPhysicalCardModalActive={setIsPhysicalCardModalActive}
        activeCards={selectedAccount?.prepaidCards}
      />
      <CardConfirmationPopup
        active={isPrepaidCardConfirmationActive}
        closePopup={togglePrepaidCardConfirmationActive}
        handleCreatePrepaidCard={handleCreatePrepaidCard}
      />

      {isGetCardModalActive && <GetCardModal toggleGetCardModal={toggleGetCardModal} />}
      {isCloseAccountModalActive && (
        <CloseAccountModal
          toggleCloseAccountTransactionModal={toggleCloseAccountTransactionModal}
          selectedAccount={selectedAccount}
          handleClose={handleClose}
        />
      )}
      {isFiltersModalActive && (
        <FiltersModal
          filters={filtersForm.getValues()}
          toggleFiltersModal={toggleFiltersModal}
          handleFilters={handleFilters}
          form={filtersForm}
          selectedWalletType={selectedWalletType}
        />
      )}
      {isChangeCardPasswordModalActive && (
        <ChangeCardPasswordModal
          toggleChangeCardPasswordModal={toggleChangeCardPasswordModal}
          cardId={selectedAccount?.prepaidCards[0]?.id}
          walletId={selectedAccount?.id}
        />
      )}
      {isRemoveCardModalActive && (
        <RemoveCardModal
          toggleRemoveCardModal={toggleRemoveCardModal}
          cardId={selectedAccount?.prepaidCards[0]?.id}
          accountId={selectedAccount?.id}
          getCards={_getWallets}
        />
      )}
      {isOpenAnAccountModalActive && (
        <OpenAnAccountModal toggleOpenAnAccountModal={toggleOpenAnAccountModal} />
      )}
      {isWallletCloseModalActive && (
        <CloseAccountSuccessModal handleClose={handleClose} selectedAccount={closedAccount} />
      )}
      {isCloseOpenBankingAccountPopupActive && (
        <CloseOpenBankingAccountPopup
          selectedAccount={selectedAccount}
          onOk={onApproveDeleteCloseOpenBankingAccount}
          onCancel={toggleCloseOpenBankingAccountPopup}
        />
      )}
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative flex flex-col justify-start items-start w-11/12 xl:w-3/4">
        <div className="flex items-center justify-between ml-4 w-full relative">
          {/* <WalletSelectionMenu
            onSelect={onWalletTypeSelected}
            defaultWalletType={selectedWalletType}
          /> */}
          {isWalletSelectionModalActive && (
            <WalletSelectionModal onClose={toggleWalletSelectionModal} />
          )}
          <div>
            <WalletTypeSelectionButton />
          </div>
          <div className="flex items-center mr-4 gap-x-4">
            <CustomButton
              color="success"
              className="flex items-center gap-x-2 py-2 text-sm"
              onClick={onCreateWallet}
              data-testid="button_open_account_modal"
            >
              <Plus className="stroke-current w-5" />
              <span data-testid="label_open_account_modal">Yeni Hesap Ekle</span>
            </CustomButton>
            {!selectedAccount?.isPrimary && (
              <AccountSettingsMenu
                tab={tab}
                menus={[
                  {
                    label: 'Hesabı Düzenle',
                    isActive:
                      (selectedWallet?.type === WALLET_TYPES.EMONEY.value &&
                        selectedAccount?.currencyCode !== CURRENCIES.TRY) ||
                      [WALLET_TYPES.CASH.value, WALLET_TYPES.BANK.value].includes(
                        selectedAccount?.type
                      ),
                    icon: <IconEdit className="w-6 mr-4" />,
                    onClick: toggleWalletEditSideModal
                  },
                  {
                    label: 'Hesabı Arşivle',
                    isActive:
                      selectedWalletType !==
                      (WALLET_TYPES.EMONEY.value && WALLET_TYPES.OPEN_BANKING.value),
                    icon: <IconArchive className="w-6 mr-4" />,
                    onClick: toggleArchiveAccountPopup
                  },
                  {
                    label: 'Hesabı Kapat',
                    isActive: selectedWalletType !== WALLET_TYPES.OPEN_BANKING.value,
                    icon: <IconCloseSquare className="fill-current text-red-500 w-6 mr-4" />,
                    onClick: () => handleCloseAccountConfirmation(tab)
                  },
                  {
                    label: 'Hesabı Kaldır',
                    isActive: selectedWalletType === WALLET_TYPES.OPEN_BANKING.value,
                    icon: <IconCloseSquare className="fill-current text-red-500 w-6 mr-4" />,
                    onClick: () => onDeleteOpenBankingAccount(tab)
                  }
                ]}
              />
            )}
          </div>
        </div>
        <TabsCard
          primaryAccount={primaryAccount}
          className="mt-4 w-full"
          tabs={tabs}
          accountsLoading={accountsLoading}
          handleCloseAccountConfirmation={handleCloseAccountConfirmation}
          tab={tab}
          setTab={handleTab}
          toggleAddCardModal={toggleAddCardModal}
          toggleGetCardModal={toggleGetCardModal}
          // cards={cards}
          toggleFiltersModal={toggleFiltersModal}
          filtersForm={filtersForm}
          handleCloseCard={toggleRemoveCardModal}
          handleChangeCardPassword={toggleChangeCardPasswordModal}
          handleTransactionDetail={handleTransactionDetail}
          shouldUpdateFilters={isFiltersModalActive}
        />
      </div>
    </div>
  );
};

const CustomPopup = ({ children, active, style, className }) => {
  const openClasses = active ? 'fixed' : 'hidden';

  const classNames = `${openClasses} ${className} top-0 left-0 w-full h-screen bg-black bg-opacity-50 flex items-center justify-center`;
  return (
    <div className={classNames}>
      <div
        className="bg-white w-80 h-auto px-6 py-8 rounded-lg w-min-max overflow-y-auto"
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

const TabsCard = ({ tabs = [], className, tab, setTab, accountsLoading }) => {
  const { selectedWalletType, selectedWallet } = useWalletContext();
  return (
    <div className={`${className} h-auto bg-white shadow-md rounded-xl`}>
      {tabs.length === 0 &&
        selectedWalletType === WALLET_TYPES.OPEN_BANKING.value &&
        !selectedWallet?.name && (
          <div className="p-4 flex justify-end">
            <img
              src={selectedWallet?.logoUrl}
              alt={selectedWallet?.openBankingId}
              className="w-[175px]"
            />
          </div>
        )}
      <div className="flex flex-col items-center justify-center w-full">
        {tabs?.length > 0 && (
          <div className="w-full flex items-center justify-between px-12 border-b border-solid border-gray-200 text-black text-sm">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center overflow-x-auto">
                {tabs?.map((t, i) => {
                  const classNames =
                    tab === i
                      ? 'border-b border-solid border-green-500 text-green-500'
                      : 'text-gray-500';
                  return (
                    <div
                      className={`${classNames} w-min min-w-max font-semibold pb-[17px] pt-[28px] mr-12 h-auto flex items-center justify-center cursor-pointer select-none`}
                      onClick={() => !t.disabled && setTab(i)}
                      key={i}
                    >
                      {t.title}
                    </div>
                  );
                })}
              </div>
              {selectedWalletType === WALLET_TYPES.OPEN_BANKING.value && (
                <img
                  src={selectedWallet?.logoUrl}
                  alt={selectedWallet?.openBankingId}
                  className="w-[175px]"
                />
              )}
            </div>
          </div>
        )}
        {tabs?.length > 0 && <div className="w-full mt-4 p-6 relative">{tabs[tab]?.page}</div>}
      </div>
      {tabs?.length === 0 &&
        (accountsLoading ? (
          <div className="flex flex-col items-center justify-center min-h-[620px]">
            <div className="flex justify-center items-center h-full">
              <Popup active childrenClassName="hidden " />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[620px]">
            <div className="flex justify-center items-center h-full">
              <EmptyView InfoMessageContainerClassname="!rounded-3xl w-[290px] !items-start">
                <div className="flex flex-col gap-y-2">
                  <p className="text-sm leading-5 font-medium text-gray-500">
                    Henüz eklenmiş bir hesabınız yok
                  </p>
                  <p className="text-xs leading-[18px]">
                    Sağ üstte yer alan artı ikonuna tıklayıp{' '}
                    {WALLET_TYPES[selectedWalletType]?.label}
                    ’nı seçerek hesap ekleyebilirsiniz.
                  </p>
                </div>
              </EmptyView>
            </div>
          </div>
        ))}
    </div>
  );
};

const CategoryModal = ({ category, handleChangeCategory }) => {
  const ref = useRef();

  const { categories } = useSelector((state) => state.categories);

  const [isAddCategoryModalActive, , toggleAddCategoryModal] = useModal({
    shouldBeBlockScroll: true
  });

  return (
    <div
      className="relative inline-block w-auto max-w-full"
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      {isAddCategoryModalActive && (
        <AddCategoryModal
          handleClose={toggleAddCategoryModal}
          handleChangeCategory={handleChangeCategory}
        />
      )}
      <Popover as="div">
        <Popover.Button as="div">
          {category ? (
            <div
              className="py-1 px-2 rounded-full w-auto text-black flex items-center justify-start gap-x-1 text-ellipsis"
              style={{ background: category?.colorHexCode }}
            >
              {category?.iconUrl && (
                <img src={category?.iconUrl} alt="category icon" className="w-3" />
              )}
              <div className="text-xs font-semibold text-white break-normal whitespace-nowrap truncate overflow-hidden">
                {category?.name}
              </div>
            </div>
          ) : (
            <div className="py-1 px-2 rounded-full w-auto text-white flex items-center justify-center gap-x-1 min-w-max bg-gray-500">
              <PlusSquareIcon className="fill-current w-3 text-white" />
              <span className="text-xs font-semibold min-w-max">Kategori Ekle</span>
            </div>
          )}
        </Popover.Button>
        <Popover.Panel
          as="div"
          className="absolute left-0 ring-1 ring-opacity-5 ring-black divide-y divide-gray-100 focus:outline-none mt-2 w-80 h-auto bg-white rounded-lg flex flex-col z-10"
        >
          {({ close }) => (
            <>
              <div className=" max-h-60 overflow-auto flex flex-wrap gap-4 p-6 border-b border-solid border-gray-200">
                {categories.map((c, i) => (
                  <div
                    className="flex gap-x-2 px-3 py-1 text-xs font-semibold rounded-full text-black"
                    style={{ background: c.colorHexCode }}
                    key={i}
                    onClick={() => {
                      handleChangeCategory(c);
                      close();
                    }}
                  >
                    {c.iconUrl && <img src={c.iconUrl} alt="icon" className="w-4" />}
                    <span>{c.name}</span>
                  </div>
                ))}
              </div>
              <div className="p-6" onClick={toggleAddCategoryModal}>
                <div className="w-8 py-1 px-2 rounded-full text-white flex items-center justify-center gap-x-1 min-w-max bg-gray-500">
                  <PlusSquareIcon className="fill-current w-3 text-white" />
                  <span className="text-xs font-semibold min-w-max">Kategori Oluştur</span>
                </div>
              </div>
            </>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
};

const ListItem = ({
  transaction,
  selectedWalletType,
  hourDisabled,
  onClick,
  categoryUpdated,
  account
}) => {
  const dispatch = useDispatch();
  const [isReceiptModalActive, , toggleReceiptModal] = useModal();
  const [receiptUrl, setReceiptUrl] = useState(null);
  const { transactionGroupTypes } = useSelector((state) => state.transactions);
  async function handleChangeCategory(c) {
    if (!c) return;

    await dispatch(
      updateTransactionCategory({
        walletId: account.id,
        transactionId: transaction.id,
        categoryId: c?.id
      })
    );

    categoryUpdated();
  }

  async function _getTransferDetail() {
    dispatch(getTransaction({ walletId: account.id, transactionId: transaction?.id })).then(
      (data) => {
        setReceiptUrl(data?.receiptUrl);
        toggleReceiptModal();
      }
    );
  }

  const repeatTransaction = () => {
    //TODO: should be repeat same transaction when a user click the button
  };

  if (!transaction) return null;

  const renderTitle = () => {
    // // eslint-disable-next-line no-unused-vars
    // const transactionGroupType = transactionGroupTypes?.find(
    //   (type) => type.key === transaction?.type
    // );

    return (
      <p className="text-gray-900 font-medium text-base leading-6">{transaction?.title || '-'}</p>
    );
  };

  const renderDetail = () => {
    const detail = transactionGroupTypes?.find((type) => type.key === transaction?.type)?.detail;

    if (!detail) return null;

    return (
      <span className="text-xs font-semibold h-6 px-2 flex items-center justify-center rounded-sm border border-solid border-gray-200 text-gray-500 min-w-max">
        {detail}
      </span>
    );
  };
  const {
    amount,
    category,
    currencyCode,
    direction,
    // title,
    transactionDate: date,
    type,
    description
  } = transaction;

  const listItemClassNames = classNames(
    'w-full h-auto my-4 flex justify-between border border-gray-200 bg-gray-50 hover:bg-white py-2 px-4 rounded-xl border-solid cursor-pointer group gap-y-4',
    {
      'cursor-default': selectedWalletType !== WALLET_TYPES.EMONEY.value
    }
  );
  return (
    <>
      <div className={listItemClassNames}>
        <div onClick={onClick} className="flex justify-start w-full">
          <div className="flex w-auto">
            {date && (
              <div className="flex flex-col items-center justify-center pl-4 pr-6 mr-6 border-r border-solid border-gray-200 w-28">
                <span className="text-2xl leading-6 text-gray-600 font-medium">
                  {new Date(date).getDate().toString().padStart(2, '0')}
                </span>
                <span className="font-normal text-xs">
                  {MONTHS[new Date(date).getMonth()] + ' ' + new Date(date).getFullYear()}
                </span>
                {!hourDisabled && (
                  <span className="text-xs font-light">
                    {new Date(date).getHours().toString().padStart(2, '0') +
                      ':' +
                      new Date(date).getMinutes().toString().padStart(2, '0')}
                  </span>
                )}
              </div>
            )}
            <div className="flex flex-col pr-3 justify-center items-start w-[220px]">
              <div className="flex flex-col gap-y-1">
                {renderTitle()}
                {selectedWalletType !== WALLET_TYPES.EMONEY.value && description && (
                  <p className="text-xs text-gray-500 font-medium leading-4">{description}</p>
                )}
              </div>
              {selectedWalletType === WALLET_TYPES.EMONEY.value &&
                ![
                  TRANSACTION_TYPES.EXCHANGE_BUY.key,
                  TRANSACTION_TYPES.EXCHANGE_SELL.key,
                  TRANSACTION_TYPES.EXCHANGE_TRANSFER.key
                ].includes(transaction.type) && (
                  <CategoryModal
                    category={category}
                    handleChangeCategory={handleChangeCategory}
                  />
                )}
            </div>
          </div>
          <div className="flex items-center justify-start w-auto gap-x-14 px-6">
            {renderDetail()}
          </div>
        </div>
        <div className=" min-w-max flex items-center gap-x-2">
          <span
            className={`${
              direction === TRANSACTION_DIRECTION.IN.key
                ? 'font-semibold text-black group-hover:text-green-500 transition'
                : 'font-semibold text-black group-hover:text-red-500 transition'
            } ml-auto`}
          >
            {direction === TRANSACTION_DIRECTION.IN.key ? '+' : '-'}
            {currencyFormat(amount).replace('-', '')} {CURRENCY_NAMES[currencyCode]}
          </span>
          {selectedWalletType === WALLET_TYPES.EMONEY.value && (
            <Button
              variant="circle"
              color="gray"
              icon={<IconFileDocument className="fill-current" />}
              onClick={() => {
                _getTransferDetail();
              }}
            />
          )}
          {TRANSACTION_TYPES.TRANSFER_ACCOUNT.key[type] === TRANSACTION_TYPES.TRANSFER_ACCOUNT &&
            direction === TRANSACTION_DIRECTION.OUT.key && (
              <div
                onClick={repeatTransaction}
                className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 cursor-pointer"
              >
                <img src={Refresh} width="24" alt="refresh" />
              </div>
            )}
        </div>
      </div>
      {isReceiptModalActive && (
        <ReceiptModal receiptUrl={receiptUrl} onClose={toggleReceiptModal} />
      )}
    </>
  );
};

const Account = ({
  account,
  hasAccount,
  toggleFiltersModal,
  toggleAddCardPopup,
  filtersForm,
  closeCardCallback,
  handleChangeCardPassword,
  handleTransactionDetail,
  shouldUpdateFilters,
  selectedWalletType,
  accountIndex,
  ...props
}) => {
  const dispatch = useDispatch();

  const [totalSize, setTotalSize] = useState(0);
  const [size, setSize] = useState(6);
  const [search, setSearch] = useState('');
  const [walletType, setWalletType] = useState(WALLET_TYPES.EMONEY.value);

  const { categories } = useSelector((state) => state.categories);

  const { transactions } = useSelector((state) => state.transactions);

  const { publishEvent, eventTypes } = useDengage();

  const { push } = useRouter();

  const [isQRCodeModalActive, , toggleQRCodeModal] = useModal({
    shouldBeBlockScroll: true
  });

  const filterCount =
    Object.values(filtersForm.getValues()).filter((el) => el && el?.toString()).length || 0;

  const searchInputRef = useRef(null);

  useDebounce(search, 500, searchInputRef, () => {
    _getTransfers();
  });

  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'accounts' }), []);

  useEffect(() => {
    _getTransfers();
  }, [size, filtersForm, shouldUpdateFilters, account]);

  useEffect(() => {
    setTotalSize(transactions.totalElements);
  }, [transactions]);

  useEffect(() => {
    if (account.type !== walletType && account?.length === 0) {
      console.log('account is empty', account);
      return;
    }

    setWalletType(account.type);
  }, [hasAccount]);

  function handleFilter(f) {
    filtersForm.setValue(f, null);
    _getTransfers();
  }

  function handleSize() {
    setSize(size + 6);
  }

  function _getTransfers() {
    let searchParams,
      referenceNo = null;

    const filterPayload = { ...filtersForm.getValues() };

    searchParams = {
      size,
      ...filterPayload
    };

    referenceNo = parseInt(search);

    if (filterPayload?.date) {
      searchParams['start-date'] = filterPayload.date.startDate;
      searchParams['end-date'] = filterPayload.date.endDate;
    }

    if (!isNaN(referenceNo)) {
      searchParams.referenceNo = referenceNo;
    } else {
      searchParams.title = search;
    }

    searchParams = filterKeys(searchParams, ['date'], { filterEmpty: true, filterNull: true });

    dispatch(
      getTransactions({
        walletId: account?.id,
        searchParams
      })
    );
  }

  const [isAddTransactionSideModalActive, , toggleAddTransactionSideModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isActivatePhysicalCardActive, , toggleActivatePhysicalCardModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [stateOfCarousel, setStateOfCarousel] = useState(0);
  const [isMultiCardModalActivate, , toggleMultiCardModal] = useModal({
    shouldBeBlockScroll: true
  });

  const onExportFile = (fileType) => {
    if (!account?.id) return;

    const filterPayload = { ...filtersForm.getValues(), search };

    dispatch(exportWalletTransactions(account?.id, filterPayload, fileType))
      .then((file) => {
        downloadFileByUrl(file?.exportUrl);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Dosya indirilirken bir hata oluştu.');
      });
  };

  return (
    <div className="px-7" {...props}>
      {isMultiCardModalActivate && (
        <MulticardModal
          cards={account?.prepaidCards}
          walletId={account?.id}
          onCloseModal={toggleMultiCardModal}
          closeCardCallback={closeCardCallback}
          toggleActivatePhysicalCardModal={toggleActivatePhysicalCardModal}
          //
          toggleAddCardPopup={toggleAddCardPopup}
          changeCardPassword={handleChangeCardPassword}
          onUpdateCardPin={handleChangeCardPassword}
          stateOfCarousel={stateOfCarousel}
          setStateOfCarousel={setStateOfCarousel}
        />
      )}
      {isActivatePhysicalCardActive && (
        <ActivatePhysicalCardModal wallet={account} onClose={toggleActivatePhysicalCardModal} />
      )}
      {isAddTransactionSideModalActive && (
        <AddTransactionSideModal
          onClose={toggleAddTransactionSideModal}
          selectedAccount={account}
          onSuccess={() => {
            _getTransfers();
            dispatch(getWallets({ status: 'ACTIVE', type: selectedWalletType }));
          }}
        />
      )}
      <div className="w-full flex justify-between border-b border-solid border-gray-200 pb-10">
        <div className="flex flex-col">
          <div className="flex flex-col mb-8">
            <span className="text-base text-gray-600">Bakiye</span>
            <span
              className={`text-4xl font-semibold ${
                account?.balance?.balance === 0 && 'text-gray-500'
              }`}
            >
              {currencyFormat(account?.balance?.balance)}
              <span className="text-xl ml-2 font-normal">
                {CURRENCY_NAMES[account?.currencyCode]}
              </span>
            </span>
          </div>
          <div className="flex gap-4 flex-wrap w-full min-w-max">
            {account?.type !== WALLET_TYPES.EMONEY.value && account?.balanceStartDate && (
              <div className="flex flex-col w-40">
                <span className="text-sm text-gray-400">Bakiye Başlangıç Tarihi</span>
                <span className="text-sm text-gray-700 font-semibold flex items-center cursor-pointer">
                  <span className="mr-2">
                    {getFormattedDateWithSeperator(account?.balanceStartDate, 'tr', '/')}
                  </span>
                </span>
              </div>
            )}
            {[WALLET_TYPES.EMONEY.value, WALLET_TYPES.BANK.value].includes(account?.type) &&
              account.currencyCode === CURRENCIES.TRY &&
              account?.iban && (
                <div className="flex flex-col mr-8">
                  <span className="text-sm text-gray-400">IBAN</span>
                  <span className="text-sm text-gray-700 font-semibold flex items-center cursor-pointer">
                    <span className="mr-2">{account?.iban}</span>
                    <CopyToClipboardButton
                      className="!p-0"
                      textToCopy={account?.iban}
                      activateTimeout={3}
                    />
                  </span>
                </div>
              )}
            {account?.type === WALLET_TYPES.EMONEY.value &&
              account?.number &&
              account.currencyCode === CURRENCIES.TRY && (
                <div className="flex flex-col">
                  <span className="text-sm text-gray-400">Norma no</span>
                  <span className="text-sm text-gray-700 font-semibold flex items-center cursor-pointer">
                    <span className="mr-2">{account?.number}</span>
                    <CopyToClipboardButton
                      className="!p-0"
                      textToCopy={account?.number}
                      activateTimeout={3}
                    />
                  </span>
                </div>
              )}
            {WALLET_TYPES.EMONEY.value === account?.type &&
              account?.currencyCode === 'TRY' &&
              account?.qrCode && (
                <div className="h-10 w-10 flex justify-center items-center">
                  <Button
                    icon={<IconQRCode className="fill-current text-gray-500" />}
                    variant="circle"
                    color="gray"
                    onClick={toggleQRCodeModal}
                  />
                  <QRCodeModal
                    isOpen={isQRCodeModalActive}
                    onClose={toggleQRCodeModal}
                    qrCode={account?.qrCode}
                    number={account?.number}
                  />
                </div>
              )}
          </div>
        </div>
        {WALLET_TYPES.EMONEY.value === account.type && (
          <div className="xl:w-1/2 w-full flex items-end justify-end xl:mt-0 mt-8">
            {account.currencyCode === CURRENCIES.TRY && (
              <CardCarousel
                cards={account?.prepaidCards}
                walletId={account?.id}
                isInModal={false}
                index={stateOfCarousel}
                setIndex={setStateOfCarousel}
                toggleAddCardPopup={toggleAddCardPopup}
                //
                closeCardCallback={closeCardCallback}
                changeCardPassword={handleChangeCardPassword}
                onUpdateCardPin={handleChangeCardPassword}
                toggleActivatePhysicalCardModal={toggleActivatePhysicalCardModal}
                toggleMultiCardModal={toggleMultiCardModal}
              />
            )}
          </div>
        )}
        {[WALLET_TYPES.CASH.value, WALLET_TYPES.BANK.value].includes(account.type) && (
          <div className="w-full flex flex-col items-end justify-end">
            <Button className="w-40" onClick={toggleAddTransactionSideModal}>
              İşlem Ekle
            </Button>
          </div>
        )}

        {WALLET_TYPES.EMONEY.value === account.type && account.currencyCode !== CURRENCIES.TRY && (
          <div className="w-full flex flex-col items-end justify-end">
            <Button
              className="w-40"
              onClick={() => push('/transactions', { exchangeModalActive: true, accountIndex })}
            >
              Döviz Al/Sat
            </Button>
          </div>
        )}
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center flex-wrap gap-y-4">
          <span className="font-semibold text-lg">İşlem Geçmişi</span>
          <div className="flex items-center gap-x-6 flex-wrap gap-y-4">
            {selectedWalletType !== WALLET_TYPES.OPEN_BANKING.value && (
              <SearchInput ref={searchInputRef} onChange={(e) => setSearch(e.target.value)} />
            )}
            {selectedWalletType !== WALLET_TYPES.OPEN_BANKING.value && (
              <Button
                variant="secondary"
                onClick={toggleFiltersModal}
                leftIcon={<Filter className="fill-current w-4" />}
              >
                <span className="min-w-max">
                  Filtrele {filterCount > 0 && `(${filterCount})`}
                </span>
              </Button>
            )}
            {selectedWalletType === WALLET_TYPES.EMONEY.value && (
              <ExportButton
                disabled={transactions?.content?.length === 0}
                onChange={onExportFile}
              >
                Dışa Aktar
              </ExportButton>
            )}
          </div>
        </div>
        <div className="mt-2 flex gap-3 flex-wrap">
          {WALLET_TYPES.EMONEY.value !== selectedWalletType &&
            filtersForm.getValues()?.direction && (
              <div className="px-3 py-2 rounded-full bg-gray-100 w-auto flex gap-x-3">
                <span>
                  {filtersForm.getValues()?.direction === TRANSACTION_DIRECTION.IN.key
                    ? 'Para Girişi'
                    : 'Para Çıkışı'}
                </span>
                <CloseSquareFilledIcon
                  className="fill-current w-5 text-gray-400 cursor-pointer"
                  onClick={() => handleFilter('direction')}
                />
              </div>
            )}
          {WALLET_TYPES.EMONEY.value === selectedWalletType &&
            filtersForm.getValues()?.direction && (
              <div className="px-3 py-2 rounded-full bg-gray-100 w-auto flex gap-x-3">
                <span>{TRANSACTION_DIRECTION[filtersForm.getValues()['direction']].value}</span>
                <CloseSquareFilledIcon
                  className="fill-current w-5 text-gray-400 cursor-pointer"
                  onClick={() => handleFilter('direction')}
                />
              </div>
            )}
          {filtersForm.getValues()['expense-type'] && (
            <div className="px-3 py-2 rounded-full bg-gray-100 w-auto flex gap-x-3">
              <span>{EXPENSE_TYPE_TEXTS[filtersForm.getValues()['expense-type']]}</span>
              <CloseSquareFilledIcon
                className="fill-current w-5 text-gray-400 cursor-pointer"
                onClick={() => handleFilter('expense-type')}
              />
            </div>
          )}
          {filtersForm.getValues()?.type && (
            <div className="px-3 py-2 rounded-full bg-gray-100 w-auto flex gap-x-3">
              <span>{TYPE_TEXTS[filtersForm.getValues().type]}</span>
              <CloseSquareFilledIcon
                className="fill-current w-5 text-gray-400 cursor-pointer"
                onClick={() => handleFilter('type')}
              />
            </div>
          )}
          {filtersForm.getValues()['category-id'] && (
            <div className="px-3 py-2 rounded-full bg-gray-100 w-auto flex gap-x-3">
              <span>
                {
                  categories.find(
                    (category) => category.id === filtersForm.getValues()['category-id']
                  ).name
                }
              </span>
              <CloseSquareFilledIcon
                className="fill-current w-5 text-gray-400 cursor-pointer"
                onClick={() => handleFilter('category-id')}
              />
            </div>
          )}
          {filtersForm.getValues()?.date && (
            <div className="px-3 py-2 rounded-full bg-gray-100 w-auto flex gap-x-3">
              <span>
                {getFormattedDateWithSeperator(
                  filtersForm.getValues()?.date.startDate,
                  'tr',
                  '.'
                )}
                {' - '}
                {getFormattedDateWithSeperator(filtersForm.getValues()?.date.endDate, 'tr', '.')}
              </span>
              <CloseSquareFilledIcon
                className="fill-current w-5 text-gray-400 cursor-pointer"
                onClick={() => handleFilter('date')}
              />
            </div>
          )}
        </div>
        {transactions?.content?.length > 0 ? (
          <div>
            {transactions?.content.map((transaction, i) => (
              <ListItem
                transaction={transaction}
                title={transaction?.partyName}
                amount={transaction?.amount}
                category={transaction?.category}
                referenceNo={transaction?.receiverAccount?.walletReferenceId}
                date={new Date(transaction?.createDate)}
                type={transaction?.type}
                direction={transaction?.direction}
                currencyCode={transaction?.currencyCode}
                key={i}
                hourDisabled={true}
                onClick={() => handleTransactionDetail(transaction)}
                categoryUpdated={_getTransfers}
                account={account}
                selectedWalletType={selectedWalletType}
              />
            ))}
            {size < totalSize && (
              <div className="flex items-center justify-center my-4">
                <CustomButton onClick={handleSize}>Daha Fazla Gör</CustomButton>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-y-8 items-center justify-center mt-20">
            <TransactionList />
            <span className="text-gray-500 flex items-center justify-center gap-x-2 px-4 py-2 rounded-2xl bg-gray-100 border border-solid border-gray-200">
              <InfoCircleIcon className="fill-current w-3" />
              <span className="text-sm">Henüz işlem yapılmamış</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const CloseAccountTransactionPopup = ({
  isCloseAccountTransactionPopupActive,
  setIsCloseAccountTransactionPopupActive,
  selectedAccount
}) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_account_money_transfer' });
  }, []);

  const getMessage = () => {
    if (selectedAccount?.autoBillPayment) {
      return 'Hesabınızda otomatik fatura ödemeniz bulunmaktadır. Devam etmek için lütfen otomatik ödemenizi iptal edin.';
    }
    if (selectedAccount?.balance?.balance > 0) {
      const balance = selectedAccount?.balance?.balance;
      return `${selectedAccount?.name} hesabınızda ${currencyFormat(balance)} ${
        CURRENCY_NAMES[selectedAccount?.currencyCode]
      } kalan bakiyeniz bulunmaktadır. Hesabınızı kapatmak için kalan bakiyenizi transfer etmeniz gerekmektedir.`;
    }
    if (selectedAccount?.prepaidCards?.length) {
      return 'Hesabınızı kapatmak için hesabınıza bağlı kartı/kartları kapatmanız gerekmektedir.';
    }
    if (selectedAccount?.autoTransaction) {
      return 'Hesabınızda otomatik para transferi talimatınız bulunmaktadır. Devam etmek için lütfen otomatik ödemenizi iptal edin.';
    }
    return null;
  };

  const getTitle = () => {
    if (selectedAccount?.autoBillPayment) {
      return 'Kalan İşlem İptali';
    }
    if (selectedAccount?.balance?.balance > 0) {
      return 'Kalan Bakiye Transferi';
    }
    if (selectedAccount?.prepaidCards?.length) {
      return 'Hesabınıza bağlı kartınız bulunmaktadır';
    }
    if (selectedAccount?.autoTransaction) {
      return 'Kalan İşlem İptali';
    }

    return null;
  };

  return (
    <CustomPopup active={isCloseAccountTransactionPopupActive} className="z-50">
      <div className="w-full h-full flex flex-col items-center text-center">
        <span className="w-20 h-20 rounded-full bg-yellow-100 flex items-center justify-center">
          <InfoCircleIcon className="fill-current text-yellow-500 w-10" />
        </span>
        <span className="font-semibold text-gray-900 text-2xl mt-6">{getTitle()}</span>
        <span className="text-gray-500 mt-3">{getMessage()}</span>
        <CustomButton
          color="success"
          className="w-full mt-6"
          onClick={() => setIsCloseAccountTransactionPopupActive(false)}
        >
          Tamam
        </CustomButton>
      </div>
    </CustomPopup>
  );
};

const CloseAccountPopup = ({
  isCloseAccountPopupActive,
  setIsCloseAccountPopupActive,
  selectedAccount,
  handleCloseAccount
}) => {
  return (
    <CustomPopup active={isCloseAccountPopupActive} className="z-50">
      <div className="w-full h-full flex flex-col items-center text-center">
        <span className="w-20 h-20 rounded-full bg-red-100 flex items-center justify-center">
          <TrashIcon className="fill-current text-red-500 w-10" />
        </span>
        <span className="font-semibold text-gray-900 text-2xl mt-6">
          “{selectedAccount?.name}” isimli hesabınızı silmek istiyor musunuz?
        </span>
        <span className="text-gray-500 mt-3">Bu işlem geri alınamaz.</span>
        <CustomButton color="danger" className="w-full mt-6" onClick={handleCloseAccount}>
          Sil
        </CustomButton>
        <CustomButton
          color="success"
          className="w-full mt-4"
          onClick={() => setIsCloseAccountPopupActive(false)}
        >
          İptal Et
        </CustomButton>
      </div>
    </CustomPopup>
  );
};

const CloseOpenBankingAccountPopup = ({
  selectedAccount,
  onOk = () => {},
  onCancel = () => {}
}) => {
  return (
    <CustomPopup active={true} className="z-50">
      <div className="w-full h-full flex flex-col items-center text-center">
        <span className="w-20 h-20 rounded-full bg-red-100 flex items-center justify-center">
          <TrashIcon className="fill-current text-red-500 w-10" />
        </span>
        <span className="font-semibold text-gray-900 text-2xl mt-6">Hesap Silme</span>
        <span className="text-gray-500 mt-3">
          “{selectedAccount?.name}” isimli hesabınızı kaldırmak istiyor musunuz?
        </span>
        <CustomButton color="danger" className="w-full mt-6" onClick={onOk}>
          Evet
        </CustomButton>
        <CustomButton color="success" className="w-full mt-4" onClick={onCancel}>
          Geri Dön
        </CustomButton>
      </div>
    </CustomPopup>
  );
};

const CAStep0 = ({
  filteredAccounts,
  selectedTransferAccount,
  setSelectedTransferAccount,
  handleCloseAccount
}) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_account_money_transfer' });
  }, []);
  return (
    <div className="flex flex-col gap-y-6 justify-center items-center overflow-y-scroll">
      <div className="w-1/2 h-full pt-20">
        <div className="mb-6">
          <p className="text-sm font-semibold text-gray-400">Kalan Bakiye Transferi</p>
          <p className="text-3xl font-semibold text-gray-900">Hesap Seçin</p>
        </div>
        <div className="flex flex-col gap-y-2">
          {filteredAccounts.map((a, i) => (
            <div
              key={i}
              className={`${
                selectedTransferAccount === a.id
                  ? 'border-2 border-green-500'
                  : 'border-1 border-gray-200'
              } border-solid cursor-pointer w-full bg-gray-100 p-6 rounded-xl flex flex-col`}
              onClick={() => setSelectedTransferAccount(a.id)}
            >
              <span className="text-gray-900">{a?.name}</span>
              <span className="text-gray-900">{a?.balance?.balance + ' ' + a?.currencyCode}</span>
            </div>
          ))}
        </div>
        <Button
          className="my-6 w-full"
          type="button"
          disabled={!selectedTransferAccount}
          onClick={handleCloseAccount}
        >
          Devam
        </Button>
      </div>
    </div>
  );
};

const CAStep1 = ({ selectedAccount, handleClose }) => {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_close_account_success' });
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'close_account'
    });
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-96 flex flex-col items-center justify-center text-center">
        <img src={DoubleCheckIllustration} alt="double-check" width={100} />
        <span className="text-white text-2xl mt-6">
          “{selectedAccount?.name}” hesabınız başarıyla kapatıldı
        </span>
        <CustomButton color="success" className="w-full mt-10" onClick={handleClose}>
          Tamam
        </CustomButton>
      </div>
    </div>
  );
};

const CloseAccountModal = ({
  toggleCloseAccountTransactionModal,
  selectedAccount,
  handleClose
}) => {
  const dispatch = useDispatch();

  const accounts = useSelector((state) => state.accounts);
  const filteredAccounts = accounts.filter(
    (a) => a.id !== selectedAccount.id && a.currencyCode === 'TRY'
  );

  const [step, setStep] = useState(0);
  function nextStep() {
    setStep(step + 1);
  }

  const [selectedTransferAccount, setSelectedTransferAccount] = useState(null);

  function handleCloseAccount() {
    dispatch(
      closeWallet({ walletId: selectedAccount.id, transferWalletId: selectedTransferAccount })
    )
      .then(() => {
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const STEPS = {
    0: (
      <CAStep0
        filteredAccounts={filteredAccounts}
        selectedTransferAccount={selectedTransferAccount}
        setSelectedTransferAccount={setSelectedTransferAccount}
        handleCloseAccount={handleCloseAccount}
      />
    ),
    1: <CAStep1 selectedAccount={selectedAccount} handleClose={handleClose} />
  };

  return (
    <BottomModal
      handleClose={toggleCloseAccountTransactionModal}
      className={step === 1 && 'bg-green-500'}
    >
      {STEPS[step]}
    </BottomModal>
  );
};

const CloseAccountSuccessModal = ({ handleClose, selectedAccount }) => {
  return (
    <BottomModal handleClose={handleClose} className={'bg-green-500'}>
      <CAStep1 selectedAccount={selectedAccount} handleClose={handleClose} />
    </BottomModal>
  );
};

export default Accounts;
