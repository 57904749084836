import { binQueryTypes } from 'store/types';
import axios from 'axios';

const { GET_BIN_QUERY, GET_BIN_QUERY_FAILURE, GET_BIN_QUERY_SUCCESS } = binQueryTypes;

const initialBINQueryParams = {
  binNumber: 0,
  amount: 0
};

export const getBinQuery = (
  { binNumber, amount } = initialBINQueryParams,
  threeD = true,
  language = 'tr-TR'
) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId) return;

      dispatch({ type: GET_BIN_QUERY });

      const response = await axios.get(`/api/payments/bin/query`, {
        params: {
          'bin-number': binNumber,
          amount: amount,
          'three-d': threeD,
          language
        }
      });

      if (response.status === 200) {
        dispatch({
          type: GET_BIN_QUERY_SUCCESS,
          data: response.data.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_BIN_QUERY_FAILURE,
        data: error
      });
    }
  };
};
