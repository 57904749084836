import { useRef } from 'react';
import { useEffect, useState } from 'react';

export default function useCountdownTimer(initialValue = 60, isActive = true, onDone = () => {}) {
  const [seconds, setSeconds] = useState(initialValue);
  const [isCounting, setIsCounting] = useState(isActive);
  const [timeByMinute, setTimeByMinute] = useState('');
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      isCounting && setSeconds((second) => second - 1);
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [isCounting]);

  useEffect(() => {
    if (seconds !== 0) calculateTimeAsMinute();
    if (seconds === 0) {
      clearInterval(intervalRef.current);
      onDone();
      setIsCounting(false);
    }
  }, [seconds]);

  const calculateTimeAsMinute = () => {
    let minute = Math.floor(seconds / 60);
    let remaningSecond = seconds % 60;

    setTimeByMinute(
      `${minute.toString().padStart(2, '0')}:${remaningSecond.toString().padStart(2, '0')}`
    );
  };

  const restart = () => {
    setSeconds(initialValue);
    setIsCounting(true);
  };

  const pause = () => setIsCounting(false);
  const start = () => setIsCounting(true);

  return { time: seconds, timeByMinute, restart, pause, start, isCounting };
}
