import { useEffect } from 'react';
import StepHeader from 'views/company-options/StepHeader';

import { ReactComponent as IconBuilding } from 'assets/icons/building.svg';
import { ReactComponent as IconClipboard } from 'assets/icons/clipboard.svg';


import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { COMPANY_SUB_TYPES } from 'utils';
import { Card } from 'components';

export default function CompanyType({ nextStep, setValue }) {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'company_type' });
  }, []);

  function handleCompanySubType(type) {
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'start_company_type',
      company_type: type
    });
    setValue('companySubType', type);
    nextStep();
  }

  return (
    <div>
      <StepHeader
        title="Şirket Kur"
        subtitle="Kurmak istediğiniz şirket türünü seçin"
        data-testid="section_start_company"
      />
      <div className="flex flex-col space-y-4 max-w-md">
        <CompanyTypeCard
          title="A.Ş. veya LTD. Şirket"
          description="Kolay ve hızlı bir şekilde şirketinizi kurun, kendi işinizin sahibi olun."
          icon={<IconBuilding className="fill-current text-green-500 w-12" />}
          data-testid="section_start_company_sole_trader"
          onClick={() => handleCompanySubType(COMPANY_SUB_TYPES.SOLE_TRADER)}
        />
        <CompanyTypeCard
          title="Serbest Meslek Erbabı"
          description="Serbest meslek erbabı olarak hemen şirketinizi kurun."
          icon={<IconClipboard className="fill-curren w-12" />}
          onClick={() => handleCompanySubType(COMPANY_SUB_TYPES.FREE_LANCER)}
        />
      </div>
    </div>
  );
}

const CompanyTypeCard = ({ title, description, icon, onClick, ...props }) => {
  return (
    <Card
      className="flex cursor-pointer hover:translate-y-1 space-x-4"
      onClick={onClick}
      {...props}
    >
      {icon}
      <div className="flex flex-col space-y-2">
        <span className="text-gray-900 font-semibold leading-6">{title}</span>
        <span className="text-gray-700 text-sm font-medium">{description}</span>
      </div>
    </Card>
  );
};
