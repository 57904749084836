import React, { createContext, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { createContact, getContacts } from 'store/actions/contacts';
import { BUSINESS_TYPES, COMPANY_TYPES, DEBT_STATUS, filterKeys } from 'utils';

export const initialContactFormValues = {
  companyType: COMPANY_TYPES.INDIVIDUAL,
  firstName: '',
  lastName: '',
  email: '',
  fullPhoneNumber: '',
  walletNumber: '',
  iban: '',
  companyName: '',
  taxIdentityNumber: '',
  taxOfficeId: '',
  cityId: '',
  districtId: '',
  address: '',
  express: false,
  initialBalance: 0,
  initialBalanceDate: new Date().toISOString(),
  debtStatus: DEBT_STATUS.NONE.value,
  businessType: BUSINESS_TYPES.NONE.value,
  hasOpeningBalance: false,
  isTaxIdentityNumberValid: true,
  isIbanValid: true
};

const ContactContext = createContext({
  formValues: {
    companyType: initialContactFormValues.companyType,
    profilePhoto: null,
    firstName: '',
    lastName: '',
    email: '',
    fullPhoneNumber: '',
    walletNumber: '',
    iban: '',
    companyName: '',
    taxIdentityNumber: '',
    taxOfficeId: '',
    cityId: '',
    districtId: '',
    address: '',
    express: false,
    initialBalance: 0,
    initialBalanceDate: initialContactFormValues.initialBalanceDate,
    debtStatus: initialContactFormValues.debtStatus,
    businessType: initialContactFormValues.businessType,
    hasOpeningBalance: false,
    isTaxIdentityNumberValid: true,
    isIbanValid: true
  },
  selectedContact: null,
  setSelectedContact: () => {},
  setFormValues: () => {},
  refetchContacts: () => {},
  createNewContact: async () => {}
});

export const useContactContext = () => useContext(ContactContext);

export default function ContactContextProvider({ children }) {
  const [formValues, setFormValues] = useState(initialContactFormValues);
  const [selectedContact, setSelectedContact] = useState(null);
  const dispatch = useDispatch();

  const refetchContacts = (params = null) => {
    dispatch(
      getContacts({
        orderBy: 'firstName',
        orderDirection: 'ASC',
        page: 0,
        size: 10,
        ...params
      })
    );
  };

  const filterContactPayload = () => {
    let payload = { ...formValues, debtStatus: formValues?.debtStatus?.value };

    if (!formValues.hasOpeningBalance) {
      payload = filterKeys(payload, ['debtStatus', 'initialBalance', 'initialBalanceDate']);
    }

    payload = filterKeys(payload, ['hasOpeningBalance', 'isTaxIdentityNumberValid'], {
      filterEmpty: true,
      filterNull: true
    });

    return payload;
  };

  const createNewContact = async () => {
    const payload = filterContactPayload();

    return dispatch(createContact(payload));
    // .then((res) => {
    //   if (res.status !== 201) return;
    //   onClose();
    //   refetchContacts();
    // })
    // .catch((err) => {
    //   console.log('err: ', err);
    // });
  };

  return (
    <ContactContext.Provider
      value={{
        formValues,
        setFormValues,
        setSelectedContact,
        selectedContact,
        refetchContacts,
        createNewContact
      }}
    >
      {children}
    </ContactContext.Provider>
  );
}
