import React, { useEffect } from 'react';
import StepHeader from 'components/stepHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useSmartOperationContext } from '../..';
import { Button, Card, Divider, SelectedAccountView } from 'components';

import useTextShortener from 'utils/hooks/useTextShortener';

import { LineInfo } from 'views/transactions/components';

import {
  AUTOMATIC_TRANSACTION_RECEIVER_TYPES,
  AUTOMATIC_TRANSACTION_REPEAT_TYPES,
  currencyFormat,
  filterKeys,
  getFormattedDateWithSeperator,
  getMaskedIban
} from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { getWallets } from 'store/actions/accounts';
import { resetAutomaticTransaction } from 'store/actions/automatic';

export default function AutomaticTransactionDetail() {
  const { toggleAutomaticTransactionEditModal } = useSmartOperationContext();
  const { automaticTransaction } = useSelector((state) => state.automaticTransaction);
  const wallet = useSelector((state) => state.accounts).find(
    (wallet) => wallet?.id == automaticTransaction?.senderWalletId
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWallets({ status: 'ACTIVE' }));
    return () => {
      dispatch(resetAutomaticTransaction());
    };
  }, []);

  return (
    <div className="flex flex-col gap-y-6 w-full">
      <div className="flex items-center justify-between">
        <StepHeader title="Akıllı İşlemler" subtitle="Otomatik Ödeme" />
        <Button
          color="secondary"
          onClick={toggleAutomaticTransactionEditModal}
          disabled={!automaticTransaction}
        >
          Düzenle
        </Button>
      </div>
      {automaticTransaction && <TransactionInformationCard />}

      <SelectedAccountView
        name={wallet?.name}
        amount={wallet?.balance?.balance}
        verticalView
        disabled
      />

      <AutomaticTransaferInformations />
    </div>
  );
}

const TransactionInformationCard = () => {
  const { automaticTransaction } = useSelector((state) => state.automaticTransaction);
  const wallets = useSelector((state) => state.accounts);

  const recordName = useTextShortener(automaticTransaction.recordName, 10);
  const fullName = useTextShortener(automaticTransaction?.receiverFullName, 15);
  const iban =
    automaticTransaction?.receiverIban && getMaskedIban(automaticTransaction?.receiverIban);
  //useTextShortener(automaticTransaction?.receiverIban, 100);
  const normaNo = useTextShortener(automaticTransaction?.receiverWalletNumber, 15);

  const ibanOrNormaNoText = (iban && normaNo) || normaNo ? 'Norma No' : 'IBAN';
  const ibanOrNormaNoValue = (iban && normaNo) || normaNo ? normaNo : iban;

  const receiverNameOrReceiverAccountNameTitle =
    AUTOMATIC_TRANSACTION_RECEIVER_TYPES.OWN_ACCOUNT === automaticTransaction?.receiverType
      ? 'Alıcı Hesap'
      : 'Alıcı Adı Soyadı';

  const receiverNameOrReceiverAccountNameText = [
    AUTOMATIC_TRANSACTION_RECEIVER_TYPES.NORMA_NO,
    AUTOMATIC_TRANSACTION_RECEIVER_TYPES.CONTACT,
    AUTOMATIC_TRANSACTION_RECEIVER_TYPES.IBAN
  ].includes(automaticTransaction?.receiverType)
    ? fullName
    : wallets?.find(
        (wallet) =>
          wallet?.iban === automaticTransaction?.receiverIban ||
          wallet?.number === automaticTransaction?.receiverWalletNumber
      )?.name;

  return (
    <Card className="bg-gray-50 !shadow-none flex items-center gap-x-14 px-6">
      <p className="text-sm font-semibold leading-5">{recordName}</p>
      <div className="flex gap-x-6">
        <LineInfo
          title={receiverNameOrReceiverAccountNameTitle}
          description={receiverNameOrReceiverAccountNameText}
        />
        <Divider isRight />
        <LineInfo
          title="Tutar"
          description={`${currencyFormat(automaticTransaction?.amount)} ${CURRENCY_NAMES.TRY}`}
        />
        <Divider isRight />

        {(iban || normaNo) && (
          <LineInfo title={ibanOrNormaNoText} description={ibanOrNormaNoValue} />
        )}
      </div>
    </Card>
  );
};

const AutomaticTransaferInformations = () => {
  const { automaticTransaction } = useSelector((state) => state.automaticTransaction);
  const { daysOfWeek, daysOfMonth } = useSelector((state) => state.enums);
  const transactionDetail = filterKeys(
    automaticTransaction,
    ['repeatCount', 'remainingRepeatCount', 'repeatType', 'paymentStartDate', 'transferDay'],
    { returnSelectedKeys: true }
  );

  const TransactionDetail = ({ name, value }) => (
    <div className="flex justify-between w-full border-solid border-gray-200 py-[18px]">
      <span className="text-xs font-medium text-gray-500">{name}</span>
      <span className="text-sm font-semibold text-gray-700">{value}</span>
    </div>
  );

  const getTransferDayText = (value) => {
    const isWeekly =
      AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value === transactionDetail?.repeatType;

    return isWeekly
      ? daysOfWeek?.find((week) => week.value === value)?.detail
      : daysOfMonth?.find((month) => month.value === value)?.detail;
  };

  return (
    <div className="flex flex-col divide-y">
      <TransactionDetail name="Transfer Sayısı" value={transactionDetail.repeatCount} />
      <TransactionDetail
        name="Kalan Transfer Sayısı"
        value={transactionDetail.remainingRepeatCount}
      />
      <TransactionDetail
        name="Transfer Aralığı"
        value={AUTOMATIC_TRANSACTION_REPEAT_TYPES[transactionDetail?.repeatType]?.label}
      />
      <TransactionDetail
        name="Transfer Başlangıç Tarihi"
        value={getFormattedDateWithSeperator(transactionDetail.paymentStartDate)}
      />
      {[
        AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value,
        AUTOMATIC_TRANSACTION_REPEAT_TYPES.MONTHLY.value
      ].includes(transactionDetail.repeatType) && (
        <TransactionDetail
          name="Transfer Günü"
          value={getTransferDayText(transactionDetail.transferDay)}
        />
      )}
    </div>
  );
};
