import { contactTypes } from 'store/types';

const {
  GET_CONTACT,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  GET_CONTACT_BALANCE,
  GET_CONTACT_BALANCE_SUCCESS,
  GET_CONTACT_BALANCE_FAILURE,
  GET_CONTACT_TRANSACTION_DEBT_STATUS,
  GET_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS,
  GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE,
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS,
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS,
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE
} = contactTypes;
const initialState = {
  contacts: [],
  quickContacts: [],
  lastContacts: [],
  filteredContacts: [],
  contact: null,
  balance: null,
  transactionDebtStatus: null,
  allContactTransactionDebtStatus: null,
  totalElements: {
    contacts: 0,
    quickContacts: 0,
    lastContacts: 0
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT:
      return {
        ...state,
        loading: true,
        contact: null
      };
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contact: action.payload
      };
    case GET_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_CONTACT_BALANCE:
      return {
        ...state,
        loading: true,
        balance: null
      };
    case GET_CONTACT_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        balance: action.payload
      };
    case GET_CONTACT_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_CONTACT_TRANSACTION_DEBT_STATUS:
      return {
        ...state,
        loading: true,
        transactionDebtStatus: null
      };
    case GET_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionDebtStatus: action.payload
      };
    case GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS:
      return {
        ...state,
        loading: true,
        allContactTransactionDebtStatus: null
      };
    case GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        allContactTransactionDebtStatus: action.payload
      };
    case GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'SET_CONTACTS':
      return {
        ...state,
        contacts: action.data,
        totalElements: {
          ...state.totalElements,
          contacts: action.totalElements
        },
        totalPages: action.totalPages
      };
    case 'SET_FILTERED_CONTACTS':
      return {
        ...state,
        filteredContacts: action.data,
        totalElements: {
          ...state.totalElements,
          filteredContacts: action.totalElements
        },
        totalPages: action.totalPages
      };
    case 'SET_QUICK_CONTACTS':
      return {
        ...state,
        quickContacts: action.data,
        totalElements: {
          ...state.totalElements,
          quickContacts: action.totalElements
        },
        totalPages: action.totalPages
      };
    case 'SET_LAST_CONTACTS':
      return {
        ...state,
        lastContacts: action.data
      };

    default:
      return { ...state };
  }
};

export default authReducer;
