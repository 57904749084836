import { ReactComponent as Close } from '../assets/icons/close.svg';
import cn from 'classnames';
import { useDeviceDetection } from 'utils/hooks';

const SideModal = ({ title, children, className, handleClose, fullWidth = false, header }) => {
  const { isMobile } = useDeviceDetection();
  const modalClass = cn('fixed top-0 left-0 w-screen h-screen z-50');
  const modalContainerClass = cn(
    'fixed top-0 right-0 w-[455px] h-full bg-white flex flex-col z-50 max-h-screen overflow-y-auto',
    {
      [className]: className,
      ['!w-full']: isMobile
    }
  );

  const sideModalContents = cn('h-full', {
    'px-8': !fullWidth && !isMobile,
    'px-6': isMobile
  });

  return (
    <div className={modalClass} id="sideModal">
      <div
        className="fixed cursor-pointer top-0 left-0 w-full h-full bg-gray-900 bg-opacity-60 flex items-center justify-center z-40"
        onClick={handleClose}
      />
      <div className={modalContainerClass}>
        {header || (
          <div className="flex justify-between items-center p-8">
            <span className="font-semibold text-2xl">{title}</span>
            <div
              onClick={handleClose}
              className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
            >
              <Close className="stroke-current text-black" />
            </div>
          </div>
        )}
        <div className={sideModalContents}>{children}</div>
      </div>
    </div>
  );
};

export default SideModal;
