import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Lightning } from 'assets/icons/lightning.svg';
import { ReactComponent as LightningFilled } from 'assets/icons/lightning-filled.svg';
import { ReactComponent as Qrcode } from 'assets/icons/qrcode.svg';
import { ReactComponent as ProfilePhoto } from 'assets/icons/default-profile-ıcon-40px.svg';
import { ReactComponent as CompanyPhoto } from 'assets/icons/default-company-Icon.svg';
import { COMPANY_TYPES } from 'utils';

export default function Transaction({ handleContactClick }) {
  const [contactType, setContactType] = useState('quickContacts');
  const { quickContacts, lastContacts } = useSelector((state) => state.contacts);

  const contactActiveClasses =
    'flex items-center space-x-2 py-2 px-4 rounded-full text-white bg-purple-600 cursor-default';
  const contactPassiveClasses =
    'flex items-center space-x-2 py-2 px-4 rounded-full text-gray-600 bg-gray-300 cursor-pointer';

  const contacts = {
    quickContacts,
    lastContacts
  };

  function handleContactType(type) {
    setContactType(type);
  }
  return (
    <div>
      <div className="flex space-x-3">
        <div
          className={
            contactType === 'quickContacts' ? contactActiveClasses : contactPassiveClasses
          }
          onClick={() => handleContactType('quickContacts')}
        >
          <Lightning className="fill-current w-4" />
          <span>Hızlı Müşteri/Tedarikçiler</span>
        </div>
        <div
          className={
            contactType === 'lastContacts' ? contactActiveClasses : contactPassiveClasses
          }
          onClick={() => handleContactType('lastContacts')}
        >
          <Clock className="fill-current w-4" />
          <span>Son Müşteri/Tedarikçiler</span>
        </div>
      </div>
      <div className="flex w-full mt-6 max-w-min overflow-x-auto py-3">
        <div className="flex space-x-4">
          <ContactQrCard />
          {contacts[contactType].map((c, i) => (
            <ContactCard
              key={i}
              firstName={c.firstName}
              lastName={c.lastName}
              isStarred={c.express}
              companyType={c.companyType}
              onClick={() => {
                handleContactClick(c);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const ContactCard = ({ firstName, lastName, isStarred, companyType, onClick }) => (
  <div
    className="w-52 h-36 relative border hover:border-gray-100 hover:bg-white cursor-pointer transition border-gray-200 bg-gray-100 rounded-xl border-solid flex flex-col items-center justify-center"
    onClick={onClick}
  >
    <LightningFilled
      className={
        isStarred ? 'fill-current text-yellow-400 absolute top-2 right-3 w-5 h-auto' : 'hidden'
      }
    />
    <>
      {companyType === COMPANY_TYPES.INDIVIDUAL && (
        <ProfilePhoto className="w-14 h-14 rounded-full bg-purple-700 flex items-center justify-center"></ProfilePhoto>
      )}
      {companyType === COMPANY_TYPES.BUSINESS && (
        <CompanyPhoto className="w-14 h-14 rounded-full bg-purple-700 flex items-center justify-center"></CompanyPhoto>
      )}
    </>
    <span className="font-normal mt-4">{`${firstName} ${lastName}`}</span>
  </div>
);

const ContactQrCard = () => (
  <div className="w-28 h-36 relative border hover:bg-white cursor-pointer transition border-gray-100 bg-gray-50 rounded-xl border-solid flex flex-col items-center justify-center">
    <div className="w-14 h-14 rounded-full bg-green-300 flex items-center justify-center text-white">
      <Qrcode className="stroke-current text-green-700" />
    </div>
    <span className="font-normal mt-4">QR</span>
  </div>
);
