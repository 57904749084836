import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/lightning.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter-2.svg';
import { ReactComponent as LightningFilledIcon } from 'assets/icons/lightning-filled.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/dots-horizontal.svg';
import { ReactComponent as IconAddBalance } from 'assets/icons/add-balance.svg';
import { ReactComponent as FileDocumentIcon } from 'assets/icons/file-document.svg';
import { ReactComponent as TransactionListIcon } from 'assets/illustrations/accounts/transaction-list.svg';
import { ReactComponent as ProfilePhoto } from 'assets/icons/default-profile-ıcon-40px.svg';
import { ReactComponent as CompanyPhoto } from 'assets/icons/default-company-Icon.svg';

import { CustomButton, Popup, Tooltip } from 'components';
import { getWallets } from 'store/actions/accounts';
import useDengage from 'utils/hooks/useDengage';

import { updateContact, deleteContact, getContacts } from 'store/actions/contacts';

import { Menu } from '@headlessui/react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';

import MoneyTransferModal from 'views/transactions/MoneyTransferModal';
import useModal from 'utils/hooks/useModal';
import useDebounce from 'utils/hooks/useDebounce';
import CopyToClipboardButton from 'components/copyToClipboardButton';
import CreateContactModal from './components/createContactModal';
import UpdateContactModal from './components/updateContactModal';
import { useContactContext } from 'contexts/contactContext';
import { useRef } from 'react';
import { COMPANY_TYPES } from 'utils';

const Contacts = () => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { user } = useSelector((state) => state.auth);
  const {
    filteredContacts: contacts,
    quickContacts,
    totalElements,
    totalPages
  } = useSelector((state) => state.contacts);

  const { setSelectedContact } = useContactContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [isExpress, setIsExpress] = useState(false);
  const [isDeleteContactPopupActive, setIsDeleteContactPopupActive] = useState(false);
  const [selectedRemoveContact, setSelectedRemoveContact] = useState();
  const [size] = useState(10);
  const [page, setPage] = useState(0);

  const [selectedContactId, setSelectedContactId] = useState(null);
  const [editContactModalActive, setEditContactModalActive] = useModal({
    shouldBeBlockScroll: true
  });
  const [paginatedContacts, setpaginatedContacts] = useState([]);

  const [isMoneyTransferModalActive, , toggleMoneyTransferModal] = useModal({
    shouldBeBlockScroll: true
  });

  const selectedContacts = isExpress ? quickContacts : contacts;
  const totalSize = isExpress ? totalElements.quickContacts : totalElements.contacts;
  const [createContactModalActive, , toggleCreateContactModal] = useModal({
    shouldBeBlockScroll: true
  });

  const searchInputRef = useRef(null);

  useDebounce(searchTerm, 500, searchInputRef, () => {
    setPage(0);
    setpaginatedContacts([]);
    _getContacts();
  });

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_contact' });
  }, []);

  useEffect(() => {
    dispatch(getWallets({ status: '' }));
  }, [user]);

  useEffect(() => {
    _getContacts();
  }, [user, isExpress, page]);

  useEffect(() => {
    setpaginatedContacts((prevContacts) => [
      ...new Map(
        [...(page === 0 ? [] : prevContacts), ...contacts].map((contact) => [
          contact['id'],
          contact
        ])
      ).values()
    ]);
  }, [contacts]);

  function toggleEditContactModal(id = null) {
    if (!editContactModalActive) {
      const currentContact = contacts.find((contact) => contact.id === id);
      setSelectedContact(currentContact);
      if (currentContact.status !== 'ACTIVE') return;
    }
    setSelectedContactId(id);
    setEditContactModalActive(!editContactModalActive);
    if (editContactModalActive) _getContacts();
  }

  async function updateQuickContact(contact, status) {
    await dispatch(updateContact({ ...contact, contactId: contact.id, express: status }));
    _getContacts();
  }

  function toggleExpress() {
    setIsExpress(!isExpress);
  }

  function _getContacts() {
    dispatch(
      getContacts({
        isExpress,
        size,
        page,
        search: searchTerm,
        orderBy: 'firstName',
        orderDirection: 'ASC'
      })
    );
  }

  function handleDeleteContact(contact) {
    setIsDeleteContactPopupActive(!isDeleteContactPopupActive);
    setSelectedRemoveContact(contact);
  }

  async function _deleteContact() {
    if (selectedRemoveContact.status === 'ACTIVE') {
      await dispatch(deleteContact({ contactId: selectedRemoveContact.id }));
      _getContacts();
    } else if (selectedRemoveContact.status === 'LOCKED') {
      toast.error('Bir belgeye bağlı Müşteri/Tedarikçiler silinemez');
    } else {
      toast.error('Aktif durumunda olmayan Müşteri/Tedarikçiler silinemez');
    }
    setIsDeleteContactPopupActive(false);
    setSelectedRemoveContact();
  }

  function handleMoneyTransfer(contactId) {
    setSelectedContactId(contactId);
    toggleMoneyTransferModal();
  }

  return (
    <div>
      {createContactModalActive && <CreateContactModal onClose={toggleCreateContactModal} />}
      {editContactModalActive && (
        <UpdateContactModal
          contactId={selectedContactId}
          onClose={() => setEditContactModalActive(false)}
        />
      )}
      {isMoneyTransferModalActive && (
        <MoneyTransferModal
          handleClose={toggleMoneyTransferModal}
          defaultContactId={selectedContactId}
        />
      )}
      <Popup active={isDeleteContactPopupActive}>
        <div className="w-full h-full flex flex-col justify-between items-center text-center">
          <span className="w-20 h-20 rounded-full bg-red-100 flex items-center justify-center">
            <TrashIcon className="fill-current text-red-500 w-10" />
          </span>
          <span className="text-xl font-semibold text-gray-900 mt-6">Müşteri/Tedarikçi Sil</span>
          <span className="font-medium text-gray-500 mt-3">
            {`${selectedRemoveContact?.firstName} ${selectedRemoveContact?.lastName}`} silmek
            istiyor musun?
          </span>
          <CustomButton
            className="w-full mt-auto"
            color="danger"
            onClick={_deleteContact}
            data-testid="delete-contact-button"
          >
            Sil
          </CustomButton>
          <CustomButton
            className="w-full mt-2"
            color="success"
            onClick={handleDeleteContact}
            data-testid="button_cancel_delete_contact"
          >
            İptal Et
          </CustomButton>
        </div>
      </Popup>
      <div className="flex flex-col justify-center items-between">
        <div className="flex w-full">
          <span className="font-semibold text-xl text-gray-900">
            Müşteri/Tedarikçiler ({totalSize})
          </span>
        </div>
        <div className="flex items-center w-full mt-6 flex-wrap gap-y-4">
          <div className="flex items-center gap-x-2 bg-gray-100 text-gray-400 px-3 py-2 rounded-lg flex-grow sm:flex-grow-0">
            <IconSearch className="fill-current w-4" />
            <input
              className="appearance-none focus:outline-none bg-transparent text-black"
              placeholder="Arama"
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={searchInputRef}
            />
          </div>
          <Tooltip text="Hızlı Müşteri/Tedarikçiler">
            <span
              className="p-2 rounded-full ml-4 bg-gray-100 flex items-center justify-center cursor-pointer"
              onClick={toggleExpress}
            >
              {isExpress ? (
                <LightningFilledIcon className="fill-current w-4 text-yellow-500" />
              ) : (
                <LightningIcon className="fill-current w-4 text-gray-500" />
              )}
            </span>
          </Tooltip>
          <CustomButton
            color="success"
            className="flex items-center gap-x-2 ml-auto"
            onClick={toggleCreateContactModal}
            data-testid="button_add_contact"
          >
            <IconPlus className="stroke-current w-4" />
            <span>Müşteri/Tedarikçi Ekle</span>
          </CustomButton>
        </div>
      </div>
      <div className="w-full flex flex-col items-center gap-y-6 mt-6">
        {selectedContacts.length ? (
          <ContactsTable
            selectedContacts={paginatedContacts}
            updateQuickContact={updateQuickContact}
            handleDeleteContact={handleDeleteContact}
            toggleEditContactModal={toggleEditContactModal}
            handleMoneyTransfer={handleMoneyTransfer}
          />
        ) : (
          <div className="w-full flex flex-col items-center justify-center gap-y-8 py-28">
            <TransactionListIcon className="fill-current w-40" />
            <span className="py-2 px-4 rounded-full bg-gray-100 border border-solid border-gray-200 flex gap-x-2 items-center">
              <InfoCircleIcon className="fill-current w-4 text-gray-500" />
              <span className="text-sm text-gray-500 font-medium">
                Henüz Müşteri/Tedarikçi eklenmemiş
              </span>
            </span>
          </div>
        )}
        {page + 1 < totalPages && (
          <CustomButton
            onClick={() => setPage((prevPage) => prevPage + 1)}
            data-testid="button_next_page"
          >
            Daha Fazla Göster
          </CustomButton>
        )}
      </div>
    </div>
  );
};

const ActionsDropdown = ({
  contact,
  updateQuickContact,
  handleDeleteContact,
  toggleEditContactModal
}) => (
  <Menu as="div" className="relative">
    <Menu.Button
      as="div"
      className="w-min p-2 bg-gray-100 rounded-full flex-nowrap cursor-pointer"
      data-testid="section_contact_actions_dropdown"
    >
      <DotsHorizontalIcon className="fill-current w-4 text-gray-500" />
    </Menu.Button>
    <Menu.Items className="origin-top-right absolute right-0 mt-2 bg-white rounded-xl p-5 border border-solid border-gray-200 flex flex-col min-w-max z-50 focus:outline-none gap-y-4">
      {contact?.express ? (
        <Menu.Item
          as="span"
          className="flex gap-x-2 text-gray-800 items-center cursor-pointer"
          onClick={() => updateQuickContact(false)}
          data-testid="button_contact_actions_remove_quick_contact"
        >
          <LightningFilledIcon className="fill-current w-4 text-yellow-500" />
          <span>Hızlı Müşteri/Tedarikçi Çıkar</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          as="span"
          className="flex gap-x-2 text-gray-800 items-center cursor-pointer"
          onClick={() => updateQuickContact(true)}
          data-testid="button_contact_actions_add_quick_contact"
        >
          <LightningIcon className="fill-current w-4" />
          <span>Hızlı Müşteri/Tedarikçi Yap</span>
        </Menu.Item>
      )}
      <Menu.Item
        as="span"
        className="flex gap-x-2 text-gray-800 items-center cursor-pointer"
        onClick={toggleEditContactModal}
        data-testid="button_contact_actions_edit_contact"
      >
        <IconFilter className="fill-current w-4" />
        <span>Düzenle</span>
      </Menu.Item>
      <Menu.Item
        as="span"
        className="flex gap-x-2 text-red-500 items-center cursor-pointer"
        onClick={handleDeleteContact}
        data-testid="button_contact_actions_delete_contact"
      >
        <TrashIcon className="fill-current w-4" />
        <span>Kontağı Sil</span>
      </Menu.Item>
    </Menu.Items>
  </Menu>
);

const ContactsTable = ({
  selectedContacts,
  updateQuickContact,
  handleDeleteContact,
  toggleEditContactModal,
  handleMoneyTransfer
}) => {
  const history = useHistory();

  function routeToDetail(id) {
    history.push(`/contact/${id}`);
  }

  const ContactName = ({ contact }) => {
    if (contact?.firstName && contact.lastName)
      return (
        <div className="flex flex-col justify-center">
          <span className="font-semibold text-gray-700">{`${contact?.firstName} ${contact?.lastName}`}</span>
          <span className="text-xs font-medium text-gray-500">{contact?.companyName || '-'}</span>
        </div>
      );

    return (
      <div className="flex flex-col justify-center">
        <span className="font-semibold text-gray-700">{contact?.companyName}</span>
      </div>
    );
  };

  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="text-left text-sm font-semibold text-gray-700 border-b border-solid border-gray-100">
          <th className="py-4">Ad Soyad - Şirket</th>
          <th className="py-4">Norma No</th>
          <th className="py-4">IBAN</th>
          <th className="py-4">Gelecek İşlem</th>
          <th className="py-4 text-right">Aksiyonlar</th>
        </tr>
      </thead>
      <tbody>
        {selectedContacts.map((contact, i) => (
          <tr
            key={i}
            className="border-b border-solid border-gray-100 hover:bg-gray-50 group transition"
          >
            <td
              className="flex items-center gap-x-4 py-6 cursor-pointer"
              onClick={() => routeToDetail(contact.id)}
            >
              <div className="relative w-10 h-10 flex items-center">
                {contact?.profilePhoto && contact.profilePhoto.includes('https') ? (
                  <div className="w-10 h-10 rounded-full bg-purple-700 overflow-hidden">
                    <img
                      src={contact.profilePhoto}
                      alt="profile"
                      className="w-10 h-10 object-cover object-center"
                    />
                  </div>
                ) : (
                  <>
                    {contact.companyType === COMPANY_TYPES.INDIVIDUAL && (
                      <ProfilePhoto className="w-10 h-10 rounded-full bg-purple-700 flex items-center justify-center"></ProfilePhoto>
                    )}
                    {contact.companyType === COMPANY_TYPES.BUSINESS && (
                      <CompanyPhoto className="w-10 h-10 rounded-full bg-purple-700 flex items-center justify-center"></CompanyPhoto>
                    )}
                  </>
                )}
                {contact?.express && (
                  <div className="absolute -bottom-1 -right-2 z-40">
                    <LightningFilledIcon className="fill-current w-5 text-yellow-500" />
                  </div>
                )}
              </div>
              <ContactName contact={contact} />
            </td>
            <td className="py-6 text-sm font-semibold text-gray-700">
              {contact?.walletNumber ? (
                <div className="flex items-center gap-x-2">
                  <span>{`
                  ${contact?.walletNumber?.trim().slice(0, 3)} 
                  ${contact?.walletNumber?.trim().slice(4, 7)}
                  ${contact?.walletNumber?.trim().slice(7, 10)}
                  ${contact?.walletNumber?.trim().slice(10, 13)}
                  `}</span>
                  <CopyToClipboardButton
                    className="invisible group-hover:visible"
                    textToCopy={contact?.walletNumber?.replaceAll(' ', '')}
                  />
                </div>
              ) : (
                <div>-</div>
              )}
            </td>
            <td className="py-6 text-sm font-semibold text-gray-700">
              {contact?.iban ? (
                <div className="flex items-center gap-x-2">
                  <span>
                    {contact?.iban.slice(0, 4) + ' **** **** ' + contact?.iban.slice(-2)}
                  </span>
                  <CopyToClipboardButton
                    className="invisible group-hover:visible"
                    textToCopy={contact?.iban.replaceAll(' ', '')}
                  />
                </div>
              ) : (
                <span>-</span>
              )}
            </td>
            <td className="py-6 text-sm font-semibold text-gray-400 flex justify-center">-</td>
            <td className="py-6">
              <div className="flex gap-x-2 justify-end">
                <Tooltip text="Para Transferi">
                  <div
                    className="w-min p-2 bg-gray-100 rounded-full flex-nowrap cursor-pointer"
                    onClick={() => handleMoneyTransfer(contact?.id)}
                  >
                    <IconAddBalance className="fill-current w-4 text-gray-500" />
                  </div>
                </Tooltip>
                <div className="w-min p-2 bg-gray-100 rounded-full flex-nowrap">
                  <FileDocumentIcon className="fill-current w-4 text-gray-500" />
                </div>
                <ActionsDropdown
                  contact={contact}
                  updateQuickContact={(status) => updateQuickContact(contact, status)}
                  handleDeleteContact={() => handleDeleteContact(contact)}
                  toggleEditContactModal={() => toggleEditContactModal(contact.id)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Contacts;
