import React from 'react';
import { SideModal, CardCarousel, Button } from 'components';

import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as IconCloseSquare } from 'assets/icons/close-square.svg';
import { ReactComponent as CaretRightIcon } from 'assets/icons/caret-right.svg';

import { PREPAID_CARD_TYPES, CARD_ACTIVATE_STATUS } from 'utils';

export default function MulticardModal({
  cards,
  walletId,
  onCloseModal,
  stateOfCarousel,
  setStateOfCarousel,
  onUpdateCardPin,
  closeCardCallback,
  toggleActivatePhysicalCardModal,
  toggleAddCardPopup
}) {
  function handleCloseCard() {
    if (closeCardCallback) {
      closeCardCallback(cards[stateOfCarousel]?.id);
    }
    onCloseModal();
  }

  function handleChangeCardPassword() {
    onUpdateCardPin(cards[stateOfCarousel]?.id);
    onCloseModal();
  }

  function handleActivateCard() {
    toggleActivatePhysicalCardModal();
    onCloseModal();
  }

  function handleAddCard() {
    toggleAddCardPopup();
    onCloseModal();
  }

  return (
    <SideModal handleClose={onCloseModal}>
      <div className="flex flex-col w-full h-full justify-between pb-10">
        <div>
          <h2 className="mb-8 font-semibold text-3xl text-gray-900 ">
            {cards[stateOfCarousel].type === PREPAID_CARD_TYPES.PHYSICAL
              ? 'Norma Card'
              : 'Sanal Kart'}
          </h2>
          <CardCarousel
            cards={cards}
            walletId={walletId}
            isInModal={true}
            index={stateOfCarousel}
            setIndex={setStateOfCarousel}
            toggleAddCardPopup={handleAddCard}
          />

          <CardActions
            card={cards[stateOfCarousel]}
            onCloseModal={onCloseModal}
            handleCloseCard={handleCloseCard}
            handleChangeCardPassword={handleChangeCardPassword}
            handleActivateCard={handleActivateCard}
          />
        </div>
      </div>
    </SideModal>
  );
}

const CardActions = ({
  card,
  handleCloseCard,
  handleChangeCardPassword,
  handleActivateCard,
  ...props
}) => {
  return (
    <div className="flex flex-col justify-between mt-8 " {...props}>
      {card?.type === PREPAID_CARD_TYPES.PHYSICAL &&
        (card?.status === CARD_ACTIVATE_STATUS.ACTIVE ? (
          <ModalButton onClick={handleChangeCardPassword}>
            <LockIcon className="fill-current w-5 h-5 mr-4" />
            <span className="select-none">Şifre Değiştir</span>
          </ModalButton>
        ) : (
          <Button className="mx-8 h-[44px]" onClick={handleActivateCard}>
            <span>Kartı Aktifleştir</span>
          </Button>
        ))}

      {card?.type && card?.status === CARD_ACTIVATE_STATUS.ACTIVE && (
        <ModalButton onClick={handleCloseCard}>
          <IconCloseSquare className="fill-current w-5 h-5 mr-4 block" />
          <span className="select-none">Kartı Kapat</span>
        </ModalButton>
      )}
    </div>
  );
};

const ModalButton = ({ children, ...props }) => {
  return (
    <div
      className="w-full p-4 pl-0 ml-6 flex items-center justify-between cursor-pointer border-b border-solid border-gray-200 "
      {...props}
    >
      <div className="text-gray-800 flex items-center ">{children}</div>
      <CaretRightIcon className="stroke-current w-5 h-5 text-gray-500 mr-6" />
    </div>
  );
};
