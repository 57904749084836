import React, { useEffect } from 'react';

import { Button, Popup } from 'components';
import { useRouter } from 'utils/hooks/useRouter';
import cn from 'classnames';

export function Prompt({
  isActive,
  onOK,
  onCancel,
  title,
  description,
  okText = 'Onayla',
  cancelText = 'Vazgeç',
  isOkButtonDisabled = false,
  isCancelButtonDisabled = false,
  okButtonColor = 'primary',
  cancelButtonColor = 'red',
  className = ''
}) {
  const { history } = useRouter();
  const classNames = cn({
    ['!w-auto']: className,
    [className]: className
  });

  useEffect(() => {
    if (isActive) {
      history.block(() => {
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, isActive]);

  return isActive ? (
    <Popup
      active={isActive}
      className={'!p-6'}
      childrenClassName={classNames}
      style={{
        height: 'auto'
      }}
    >
      {title && (
        <div className="mb-6 text-gray-900 text-base leading-6 font-medium text-center w-full">
          {title}
        </div>
      )}
      {description && (
        <div className="mb-6 text-gray-900 text-base leading-6 font-medium text-center w-full">
          {description}
        </div>
      )}
      <div className="flex flex-col items-center gap-y-3 justify-between w-full">
        <Button
          color={okButtonColor}
          className="w-full"
          onClick={onOK}
          disabled={isOkButtonDisabled}
        >
          {okText}
        </Button>
        <Button
          className="w-full"
          color={cancelButtonColor}
          onClick={onCancel}
          disabled={isCancelButtonDisabled}
        >
          {cancelText}
        </Button>
      </div>
    </Popup>
  ) : null;
}
