const initialState = [];

const virtualOfficesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VIRTUAL_OFFICES':
      return action.data;
    default:
      return state;
  }
};

export default virtualOfficesReducer;
