import { BottomModal } from 'components';
import { useState } from 'react';
import { InvoiceList, InvoicePreview } from './components';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoices } from 'store/actions/eTransformation';
import { INVOICE_PROFILE_IDS } from 'views/invoicing/enums';
import { E_TRANSFORMATION_TYPES } from 'utils';

const BackButton = ({ handleBack }) => (
  <div className="flex justify-between items-center mb-10 absolute left-6 top-6">
    <div
      onClick={handleBack}
      className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
    >
      <ArrowLeftIcon className="stroke-current text-gray-500 w-6" />
    </div>
  </div>
);

const AcceptRejectInvoiceModal = ({ handleClose, defaultInvoice, handleCreateWithInvoice }) => {
  const dispatch = useDispatch();

  const [selectedInvoice, setSelectedInvoice] = useState(defaultInvoice);
  const { etransformationType } = useSelector((state) => state.auth);

  useEffect(() => {
    let params = {
      direction: 'INCOMING',
      'app-response-needed': true,
      'profile-id': INVOICE_PROFILE_IDS.TICARIFATURA,
      archived: false
    };

    if (etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT) {
      params = {
        direction: 'INCOMING',
        archived: false
      };
    }
    dispatch(getInvoices(params));
  }, [selectedInvoice]);

  function handleSuccess(invoice) {
    setSelectedInvoice(null);
    handleClose();
    handleCreateWithInvoice(invoice);
  }

  return (
    <BottomModal handleClose={handleClose}>
      {!!selectedInvoice && <BackButton handleBack={() => setSelectedInvoice(null)} />}
      <div className="flex w-full h-full justify-center items-start overflow-y-auto py-28 bg-gray-50">
        {!selectedInvoice ? (
          <InvoiceList setSelectedInvoice={setSelectedInvoice} />
        ) : (
          <InvoicePreview
            invoice={selectedInvoice}
            handlePopupClose={handleClose}
            handleSuccess={handleSuccess}
          />
        )}
      </div>
    </BottomModal>
  );
};

export default AcceptRejectInvoiceModal;
