import { useEffect } from 'react';
import { CustomButton, Popup } from 'components';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const DeleteSaleConfirmPopup = ({ active, onClose, handleDelete }) => {
  const { publishEvent, eventTypes } = useDengage();

  function confirm() {
    handleDelete();
    onClose();
  }

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_record_deleted' });
  }, []);

  return (
    <Popup active={active} onClose={onClose}>
      <div className="flex flex-col items-center w-full h-full text-center justify-around">
        <span className="rounded-full w-20 h-20 bg-red-100 flex items-center justify-center">
          <TrashIcon className="fill-current text-red-500 w-8" />
        </span>
        <span className="text-xl font-semibold text-gray-900 mt-6">Kaydı Sil</span>
        <span className="text-base font-medium text-gray-500 mt-3">
          Kaydı silmek istediğine emin misin?
        </span>
        <CustomButton color="danger" className="w-full mt-auto" onClick={confirm}>
          Sil
        </CustomButton>
        <CustomButton color="success" className="w-full mt-2" onClick={onClose}>
          İptal Et
        </CustomButton>
      </div>
    </Popup>
  );
};

export default DeleteSaleConfirmPopup;
