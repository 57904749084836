import React, { useContext, createContext, useState, version, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorContentResources } from 'store/actions/contentResource';
import { LOCAL_STORAGE_PARAMS } from 'utils';

const initialState = {
  language: 'tr'
};
const TranslationContext = createContext({
  language: initialState.language,
  currentVersion: '',
  translate: () => {},
  changeLanguage: () => {}
});

export const useTranslation = () => useContext(TranslationContext);

export default function TranslationContextProvider({
  children,
  options = { fallbackLanguage: 'tr' }
}) {
  const [language, setLanguage] = useState('');
  const [currentVersion, setCurrentVersion] = useState('');

  const dispatch = useDispatch();

  const { errorContent } = useSelector((state) => state.contentResource);

  useEffect(() => {
    initializeTranslation();
  }, []);

  useEffect(() => {
    if (language)
      dispatch(getErrorContentResources(language.toLocaleUpperCase())).then(({ resources }) => {
        if (resources?.length === 0) {
          changeLanguage(options.fallbackLanguage);
        }
      });
  }, [language]);

  useEffect(() => {
    if (currentVersion !== errorContent?.version) setCurrentVersion(errorContent?.version);
  }, [errorContent?.version]);

  const initializeTranslation = () => {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_PARAMS.NORMA_LANG);
    const browserLanguge = navigator.language.split('-')[0];

    if (userLanguage) {
      setLanguage(userLanguage);
    } else if (browserLanguge) {
      setLanguage(browserLanguge);
      localStorage.setItem(LOCAL_STORAGE_PARAMS.NORMA_LANG, browserLanguge);
    } else {
      const lang = options?.fallbackLanguage;
      setLanguage(lang);
      localStorage.setItem(LOCAL_STORAGE_PARAMS.NORMA_LANG, lang);
    }
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem(LOCAL_STORAGE_PARAMS.NORMA_LANG, lang);
  };

  const translateBy = (key = '', params = []) => {
    if (params.length > 0) {
      let replaced = '';

      params.forEach((value, index) => {
        replaced = errorContent?.resources[language][key].replace(`{${index}}`, value);
      });

      return replaced;
    }
    return errorContent?.resources[language][key];
  };

  const values = useMemo(() => {
    return {
      language,
      currentVersion,
      translateBy,
      changeLanguage
    };
  }, [language, version]);

  return <TranslationContext.Provider value={values}>{children}</TranslationContext.Provider>;
}
