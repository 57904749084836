import { Popup } from 'components';
import CustomButton from 'components/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { logout, toggleSessionEndModal } from 'store/actions/auth';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import ReactDom from 'react-dom';

const SessionEndModal = () => {
  const dispatch = useDispatch();

  const { isSessionEndModalActive } = useSelector((state) => state.auth);

  function handleLogout() {
    dispatch(toggleSessionEndModal(false));
    dispatch(logout());
  }

  return ReactDom.createPortal(
    <Popup
      active={isSessionEndModalActive}
      childrenClassName="w-[327px] h-[320px] flex flex-col items-center justify-between text-center"
    >
      <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100">
        <InfoCircleIcon className="fill-current w-10 text-yellow-600" />
      </div>
      <div className="flex flex-col gap-y-3">
        <span className="text-lg font-semibold text-gray-900">Tekrar Giriş Yapın</span>
        <span className="text-base font-medium text-gray-500">
          Hesabınıza başka bir cihazdan giriş yapılmıştır.
        </span>
      </div>
      <CustomButton color="success" className="w-full" onClick={handleLogout}>
        Giriş Yap
      </CustomButton>
    </Popup>,
    document.body
  );
};

export default SessionEndModal;
