const useDengage = () => {
  const eventTypes = {
    initialize: 'initialize',
    pageView: 'pageView',
    click: 'click'
  };
  const publishEvent = (name, params = {}, prefix = 'ec:') => {
    if (window?.dengage && name && process.env?.REACT_APP_DENGAGE_DISABLED_DEV !== 'true') {
      window.dengage(`${prefix}${name}`, params);
    }
  };
  return {
    publishEvent: publishEvent,
    eventTypes: eventTypes
  };
};

export default useDengage;
