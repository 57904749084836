import { RadioGroup } from '@headlessui/react';
import { PAYMENT_STATUS } from 'utils';

const PaymentStatusRadioButtons = ({ watch, setValue }) => (
  <RadioGroup
    value={watch('paymentStatus')}
    onChange={(e) => setValue('paymentStatus', e)}
    className="flex gap-x-8 mt-6"
  >
    <RadioGroup.Option value={PAYMENT_STATUS.PAID}>
      {({ checked }) => (
        <div className={`${!checked && 'cursor-pointer'} flex gap-x-2 items-center`}>
          {checked ? (
            <span className="w-5 h-5 rounded-full bg-white border-4 border-solid border-green-500" />
          ) : (
            <span className="w-5 h-5 rounded-full bg-white border border-solid border-gray-200" />
          )}
          <span className="text-sm font-medium text-gray-700">Ödendi</span>
        </div>
      )}
    </RadioGroup.Option>
    <RadioGroup.Option value={PAYMENT_STATUS.NOT_PAID}>
      {({ checked }) => (
        <div className={`${!checked && 'cursor-pointer'} flex gap-x-2 items-center`}>
          {checked ? (
            <span className="w-5 h-5 rounded-full bg-white border-4 border-solid border-green-500" />
          ) : (
            <span className="w-5 h-5 rounded-full bg-white border border-solid border-gray-200" />
          )}
          <span className="text-sm font-medium text-gray-700">Ödenecek</span>
        </div>
      )}
    </RadioGroup.Option>
  </RadioGroup>
);

export default PaymentStatusRadioButtons;
