import Loading from 'components/loading';
import reactDom from 'react-dom';

export default function LoadingOverlay() {
  return reactDom.createPortal(
    <div className="w-full h-full flex items-center justify-center fixed top-0 z-[999] bg-gray-500 bg-opacity-20">
      <Loading />
    </div>,
    document.body
  );
}
