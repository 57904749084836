import React, { useEffect, useContext } from 'react';
import DoubleCheck from 'assets/illustrations/card/double-check.png';
import { Button, Popup, ReceiptModal } from 'components';
import { BillContext } from '../..';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { useSelector } from 'react-redux';
import useModal from 'utils/hooks/useModal';

import { useDispatch } from 'react-redux';
import { getBillSubscriptions } from 'store/actions/bill';

export default function BillPaymentSuccess({ onClose }) {
  const {
    setShowBackButton,
    setSelectedPaymentAccount,
    setStep,

    selectedBill,
    isBillCreateAndPayModalActive,
    isBillPaymentModalActive
  } = useContext(BillContext);
  const { payBill, bills } = useSelector((state) => state.bill);
  const dispatch = useDispatch();
  const [isReceiptModalActive, , toggleReceiptModal] = useModal();
  const [isMorePayableBillPopupActive, setIsMorePayableBillPopupActive] = useModal();

  useEffect(() => {
    setShowBackButton(false);
    setSelectedPaymentAccount(null);
    if (bills?.bills.filter((bill) => bill.invoiceNo !== selectedBill?.invoiceNo).length > 0) {
      setIsMorePayableBillPopupActive(true);
    }
    return () => {
      setShowBackButton(true);
      dispatch(getBillSubscriptions());
    };
  }, []);

  const onShowReceipt = () => {
    toggleReceiptModal();
  };

  const onShowOtherBills = () => {
    if (isBillCreateAndPayModalActive) return setStep(1);
    if (isBillPaymentModalActive) return setStep(0);
  };

  const onCloseMorePayableBillPopup = () => {
    setIsMorePayableBillPopupActive(false);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-8">
      {isMorePayableBillPopupActive && (
        <MorePayableBillPopup
          onApprove={onShowOtherBills}
          onCancel={onCloseMorePayableBillPopup}
        />
      )}
      {isReceiptModalActive && (
        <ReceiptModal onClose={toggleReceiptModal} receiptUrl={payBill?.receiptUrl} />
      )}
      <img src={DoubleCheck} alt="double-check" width="100" />
      <p className="text-3xl text-white max-w-md text-center">Fatura başarıyla ödendi</p>
      <div className="w-full flex flex-col items-center gap-y-4">
        <Button color="secondary" className="w-[400px]" onClick={onShowReceipt}>
          Dekont Göster
        </Button>
        <Button className="w-[400px]" onClick={onClose}>
          Kapat
        </Button>
      </div>
    </div>
  );
}

const MorePayableBillPopup = ({ onCancel, onApprove }) => (
  <Popup
    active
    childrenClassName="w-[327px] h-[320px] flex flex-col items-center !h-auto gap-y-6"
    onClose={onCancel}
  >
    <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100">
      <InfoCircleIcon className="fill-current w-10 h-10 text-yellow-600" />
    </div>
    <p className="text-center font-medium leading-6">
      5333729937 nolu aboneye ait kalan faturaları ödemek ister misin?
    </p>
    <div className="flex flex-col gap-y-4 w-full">
      <Button className="w-full" onClick={onApprove}>
        Fatura Öde
      </Button>
      <Button className="w-full" color="red" onClick={onCancel}>
        Kapat
      </Button>
    </div>
  </Popup>
);
