import React, { useEffect } from 'react';
import { BottomModal, Button } from 'components';
import ReceiptPdf from 'views/invoicing/components/eDocumentPreview/ReceiptPdf';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as IconShare } from 'assets/icons/share.svg';
import { downloadFileByUrl } from 'utils';

export default function ViewDocumentModal({
  selectedFile,
  selectedDocument,
  ref,
  isActive = false,
  handleClose
}) {
  const { publishEvent, eventTypes } = useDengage();

  const downloadReceiptByFileUrl = () => {
    if (selectedFile?.url) downloadFileByUrl(selectedFile.url, selectedFile.name);
  };

  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_and_settings_tax_declaration_preview'
    });
  }, []);

  return (
    <BottomModal ref={ref} isActive={isActive} handleClose={handleClose}>
      <div
        className="mx-auto w-auto h-full flex flex-col items-center justify-center"
        data-testid="section_documents_file_modal_info"
      >
        <div className="w-full flex justify-between items-center gap-x-3">
          <h4 data-testid="label_documents_file_modal_receipt">
            {selectedDocument?.taxDocumentType.name}
          </h4>
          <div className="flex items-center gap-x-3">
            <Button
              className="w-full"
              variant="circle"
              color="gray"
              icon={<IconShare className="fill-current stroke-0" />}
              data-testid="button_documents_file_modal_receipt_download"
              onClick={downloadReceiptByFileUrl}
            />
          </div>
        </div>
        <div className="h-auto w-auto overflow-y-scroll rounded-xl overflow-hidden my-5">
          {selectedFile?.url.split('.').pop() === 'pdf' ? (
            <ReceiptPdf pdfUrl={selectedFile?.url} />
          ) : (
            <img className="h-full w-full" src={selectedFile?.url} alt={selectedFile?.name} />
          )}
        </div>
        <div className="flex gap-x-3 items-center w-full">
          <Button
            className="w-full"
            color="primary"
            onClick={downloadReceiptByFileUrl}
            data-testid="button_documents_file_modal_info_download"
          >
            İndir
          </Button>
        </div>
      </div>
    </BottomModal>
  );
}
