import { BottomModal } from 'components';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSaleExpenseById } from 'store/actions/invoice';

import DetailSale from './DetailSale';
import EditSale from './EditSale';
import EDocument from './EDocument';
import SuccessPage from './SuccessPage';

import { PAGE_NAMES } from './enums';
import { useDispatch } from 'react-redux';

import ItemContextProvider from 'contexts/itemContext';

const SalesRecordDetailModal = ({ handleClose, salesRecordId, defaultPage }) => {
  const { user } = useSelector((state) => state.auth);

  const [page, setPage] = useState(defaultPage || PAGE_NAMES.DETAIL);
  const [salesExpense, setSaleExpense] = useState();
  const dispatch = useDispatch();

  function getSaleExpense() {
    if (!user.companyId) return;
    dispatch(
      getSaleExpenseById({ companyId: user.companyId, salesExpenseRecordId: salesRecordId })
    )
      .then((res) => {
        setSaleExpense(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getSaleExpense();
  }, [page]);

  const PAGES = {
    [PAGE_NAMES.DETAIL]: (
      <DetailSale
        transaction={salesExpense}
        salesExpenseRecordId={salesRecordId}
        setPage={setPage}
        handleClose={handleClose}
      />
    ),
    [PAGE_NAMES.EDIT]: (
      <EditSale
        transaction={salesExpense}
        handleEdited={() => setPage(PAGE_NAMES.DETAIL)}
        handleClose={handleClose}
      />
    ),
    [PAGE_NAMES.EDOCUMENT]: (
      <EDocument transaction={salesExpense} sended={getSaleExpense} setPage={setPage} />
    ),
    [PAGE_NAMES.SUCCESS]: <SuccessPage handleClose={handleClose} setPage={setPage} />
  };

  return (
    <BottomModal handleClose={handleClose}>
      <ItemContextProvider>{PAGES[page]}</ItemContextProvider>
    </BottomModal>
  );
};

export default SalesRecordDetailModal;
