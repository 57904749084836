import React from 'react';
import cn from 'classnames';
export default function Divider({ className, isRight, isLeft, isTop }) {
  const classNames = cn(
    'border-b-2 border-solid border-gray-200',
    isRight && 'border-r-[1px] border-b-0',
    isLeft && 'border-l-[1px] border-b-0',
    isTop && 'border-r-[1px] border-b-0',
    className
  );
  return <div className={classNames} />;
}
