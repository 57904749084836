import { CustomButton } from 'components';
import { useEffect } from 'react';
import StepHeader from 'views/company-options/StepHeader';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as CaretRightIcon } from 'assets/icons/caret-right.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getVirtualOffices } from 'store/actions/virtualOffices';

export default function VirtualOffice({ setValue, toggleInfoSidebar, nextStep, watch }) {
  const dispatch = useDispatch();

  const virtualOffices = useSelector((state) => state.virtualOffices);

  useEffect(() => {
    dispatch(getVirtualOffices());
  }, []);

  function handleSelectVirtualOffice(id) {
    setValue('virtualOfficeId', id);
  }

  return (
    <div>
      <StepHeader
        title="Şirket Kur"
        subtitle={
          <div className="flex gap-x-2 cursor-pointer" onClick={toggleInfoSidebar}>
            <span>Sanal ofis seçimi</span>
            <InfoCircleIcon className="fill-current w-6 text-gray-300" />
          </div>
        }
      />
      <div className="flex flex-col gap-y-4 mt-6">
        {virtualOffices.map((v, i) => (
          <VirtualOfficeCard
            key={i}
            name={v.name}
            onClick={() => handleSelectVirtualOffice(v.id)}
            isSelected={watch('virtualOfficeId') === v.id}
          />
        ))}
      </div>
      <CustomButton
        color="success"
        className="w-full mt-6"
        onClick={() => watch('virtualOfficeId') && nextStep(4)}
        disabled={!watch('virtualOfficeId')}
        data-testid="section_button_start_company_virtual_address_next"
      >
        Devam
      </CustomButton>
    </div>
  );
}

function VirtualOfficeCard({ name, onClick, isSelected }) {
  return (
    <div
      className={`${
        isSelected ? 'bg-green-200 border-green-300' : 'bg-white border-gray-200'
      } w-full p-4 border border-solid rounded-lg flex cursor-pointer`}
      onClick={onClick}
      data-testid="section_card_start_company_virtual_office"
    >
      <span className="text-gray-900">{name}</span>
      {isSelected ? (
        <IconCheck className="stroke-current w-5 text-green-900 ml-auto" />
      ) : (
        <CaretRightIcon className="stroke-current w-5 text-gray-900 ml-auto" />
      )}
    </div>
  );
}
