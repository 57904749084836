import { enumTypes } from 'store/types';
import axios from 'axios';
import httpStatus from 'utils/httpStatus';

const {
  GET_DAYS_OF_WEEK,
  GET_DAYS_OF_WEEK_SUCCESS,
  GET_DAYS_OF_WEEK_FAILURE,
  GET_DAYS_OF_MONTH,
  GET_DAYS_OF_MONTH_SUCCESS,
  GET_DAYS_OF_MONTH_FAILURE,
  GET_ENUMS,
  GET_ENUMS_SUCCESS,
  GET_ENUMS_FAILURE
} = enumTypes;

const BASE_URL = 'enums';

export const getDaysOfWeek = () => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    if (!user.companyId)
      return dispatch({
        type: GET_DAYS_OF_WEEK_FAILURE,
        payload: 'company not found'
      });

    dispatch({
      type: GET_DAYS_OF_WEEK
    });

    const response = await axios.get(`${BASE_URL}/DayOfWeek`);

    if (response.status === httpStatus.OK) {
      dispatch({
        type: GET_DAYS_OF_WEEK_SUCCESS,
        payload: response.data.data
      });
    }
  } catch (error) {
    dispatch({
      type: GET_DAYS_OF_WEEK_FAILURE,
      payload: error
    });
  }
};

export const getDaysOfMoth = () => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    if (!user.companyId)
      return dispatch({
        type: GET_DAYS_OF_MONTH_FAILURE,
        payload: 'company not found'
      });

    dispatch({
      type: GET_DAYS_OF_MONTH
    });

    const response = await axios.get(`${BASE_URL}/DayOfMonth`);

    if (response.status === httpStatus.OK) {
      dispatch({
        type: GET_DAYS_OF_MONTH_SUCCESS,
        payload: response.data.data
      });
    }
  } catch (error) {
    dispatch({
      type: GET_DAYS_OF_MONTH_FAILURE,
      payload: error
    });
  }
};

export const getEnums = () => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    if (!user.companyId)
      return dispatch({
        type: GET_ENUMS_FAILURE,
        payload: 'company not found'
      });

    dispatch({
      type: GET_ENUMS
    });

    const response = await axios.get(BASE_URL);

    if (response.status === httpStatus.OK) {
      dispatch({
        type: GET_ENUMS_SUCCESS,
        payload: response.data.data
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ENUMS_FAILURE,
      payload: error
    });
  }
};
