import { Button, CustomInput, Select } from 'components';

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getCities, getCountries, getDistrict } from 'store/actions/country';
import { getTaxOfficesByCityId } from 'store/actions/taxOffice';

import StepHeader from 'views/company-options/StepHeader';

import useKeyPress from 'utils/hooks/useKeyPress';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { isValidVKN } from 'utils';

export default function CompanyInformation({
  setValue,
  watch,
  nextStep,
  errors,
  setError,
  clearErrors
}) {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'add_company_info' });
  }, []);

  const { countries, cities, districts } = useSelector((state) => state.country);

  const [taxOffices, setTaxOffices] = useState([]);

  const isCorrect =
    watch('companyName') &&
    watch('countryId') &&
    watch('cityId') &&
    watch('address') &&
    watch('taxOfficeId') &&
    watch('taxIdentityNumber') &&
    !Object.keys(errors).length;

  const filteredDistricts =
    watch('cityId') && districts[watch('cityId')] ? districts[watch('cityId')] : [];

  function _getCities() {
    dispatch(
      getCities({ country: countries.find((c) => c.id === parseInt(watch('countryId')))?.code })
    );
  }

  function _getTaxOffices() {
    dispatch(getTaxOfficesByCityId({ cityId: watch('cityId') })).then((res) => {
      setTaxOffices(res.data?.data);
    });
  }

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (watch('countryId')) _getCities();
  }, [watch('countryId')]);

  useEffect(() => {
    if (watch('cityId')) _getTaxOffices();
  }, [watch('cityId')]);

  function handleCountryChange(e) {
    setValue('cityId', 0);
    setValue('taxOfficeId', 0);
    setValue('countryId', e);
  }

  function handleCityChange(cityId) {
    setValue('taxOfficeId', 0);
    setValue('cityId', cityId);
    dispatch(getDistrict({ cityId }));
  }

  function handleDistrict(districtId) {
    setValue('districtId', districtId);
  }

  function handleNextStep() {
    if (watch('taxIdentityNumber').length !== 10) {
      setError('taxIdentityNumber', {
        type: 'invalid',
        message: 'Vergi numarası 10 haneli olmalıdır!'
      });
      return;
    }
    if (!isValidVKN(watch('taxIdentityNumber'))) {
      setError('taxIdentityNumber', {
        type: 'invalid',
        message: 'Lütfen vergi numaranızı kontrol ediniz.'
      });
      return;
    }
    nextStep();

    sendGTMEvent({
      event: 'catEvent',
      eventName: 'add_a_company_info'
    });
  }

  // useEffect(() => {
  //   clearErrors('taxIdentityNumber');
  // }, [watch('taxIdentityNumber')]);

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    handleNextStep();
  });

  const onVKNChange = (e) => {
    setValue('taxIdentityNumber', e.target.value);
    if (watch('taxIdentityNumber').length !== 10) {
      setError('taxIdentityNumber', {
        type: 'invalid',
        message: 'Vergi numarası 10 haneli olmalıdır!'
      });
      return;
    }
    if (watch('taxIdentityNumber')?.length === 10 && !isValidVKN(watch('taxIdentityNumber'))) {
      setError('taxIdentityNumber', {
        type: 'invalid',
        message: 'Lütfen vergi numaranızı kontrol ediniz.'
      });
    } else {
      clearErrors('taxIdentityNumber');
    }
  };

  return (
    <div className="w-full">
      <StepHeader title="Şirket Ekle" subtitle="Şirket Bilgilerinizi Girin" />
      <div className="flex flex-col space-y-4 w-full">
        <div>
          <CustomInput
            label="Şirket Adı"
            onChange={(e) => setValue('companyName', e.target.value)}
            value={watch('companyName')}
            error={errors?.companyName?.message}
            data-testid="input_company_name"
            autoFocus
          />
          <span className="font-medium text-gray-400 text-sm leading-4">
            Farklı bir şirket adı yazabilirsiniz.
          </span>
        </div>
        <Select
          options={countries}
          value="id"
          valueLabel="name"
          label="Ülke"
          defaultValue="1"
          disabled
          onChange={handleCountryChange}
          data-testid="select_company_country"
        />
        <Select
          options={cities}
          value="id"
          valueLabel="name"
          label="İl"
          defaultValue={watch('cityId')}
          onChange={handleCityChange}
          data-testid="select_company_city"
        />
        <Select
          options={filteredDistricts}
          value="id"
          valueLabel="name"
          label="İlçe"
          className="mt-4"
          onChange={handleDistrict}
          defaultValue={watch('districtId')}
          data-testid="select_company_district"
        />
        <CustomInput
          label="Şirket Adresi"
          value={watch('address')}
          onChange={(e) => setValue('address', e.target.value)}
          data-testid="input_company_address"
        />
        <Select
          options={taxOffices}
          value="id"
          valueLabel="name"
          label="Vergi Dairesi"
          defaultValue={watch('taxOfficeId')}
          onChange={(e) => setValue('taxOfficeId', e)}
          data-testid="select_company_tax_office"
        />
        <CustomInput
          label="Vergi No"
          maxLength={10}
          value={watch('taxIdentityNumber')}
          onChange={onVKNChange}
          only="number"
          error={errors?.taxIdentityNumber?.message}
          data-testid="input_company_tax_identity_number"
        />
      </div>
      <Button
        className="mt-6 w-full"
        disabled={!isCorrect}
        onClick={handleNextStep}
        data-testid="button_company_options_next"
      >
        Devam
      </Button>
    </div>
  );
}
