import classNames from 'classnames';
import React from 'react';

const Checkbox = React.forwardRef(
  ({ className, children, onChange, checked, register, name, defaultChecked, disabled }, ref) => {
    const checkboxClassNames = classNames(
      'h-full cursor-pointer flex items-center space-x-2',
      className
    );

    return (
      <label className={checkboxClassNames}>
        {register ? (
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={checked}
            onChange={onChange}
            {...register(name)}
            disabled={disabled}
          />
        ) : (
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={checked}
            onChange={onChange}
            defaultChecked={defaultChecked}
            disabled={disabled}
            ref={ref}
          />
        )}
        <span className="select-none">{children}</span>
      </label>
    );
  }
);
Checkbox.displayName = 'Checkbox';

export default Checkbox;
