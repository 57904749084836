import axios from 'axios';

export const getNotifications = () => {
  return (dispatch) => {
    return axios.get(`/api/notifications`).then((res) => {
      dispatch({
        type: 'SET_NOTIFICATIONS',
        data: res.data?.data?.content
      });
    });
  };
};

export const deleteNotification = (notificationId) => {
  return axios.delete(`/api/notifications/${notificationId}`);
};
