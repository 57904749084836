import axios from 'axios';

const initialState = {
  token: null,
  signUpToken: null,
  rememberMeToken: null,
  companyStatus: null,
  kycCompleted: false,
  etransformationType: null,
  isSessionEndModalActive: false,
  isIdleTimePopupActive: false,
  user: {
    birthDate: null,
    companyId: null,
    email: null,
    firstName: null,
    fullPhoneNumber: null,
    identityNumber: null,
    lastName: null,
    userId: null,
    profilePhotoUrl: null
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      axios.defaults.headers.Authorization = `Bearer ${action.data}`;
      return {
        ...state,
        token: action.data
      };
    case 'SET_REMEMBER_ME_TOKEN':
      return {
        ...state,
        rememberMeToken: action.data
      };
    case 'SET_SIGN_UP_TOKEN':
      return {
        ...state,
        signUpToken: action.data
      };
    case 'SET_CLIENT_CREDENTIALS_TOKEN':
      axios.defaults.headers.Authorization = `Bearer ${action.data}`;
      return { ...state, signUpToken: action.data };
    case 'SET_USER_DATA':
      return {
        ...state,
        user: action.data
      };
    case 'SET_COMPANY_STATUS':
      return {
        ...state,
        companyStatus: action.data.companyStatus,
        kycCompleted: action.data.kycCompleted,
        etransformationType: action.data.etransformationType,
        user: {
          ...state.user,
          company: action.data.company
        }
      };
    case 'TOGGLE_SESSION_END_MODAL':
      return {
        ...state,
        isSessionEndModalActive: action.data
      };
    case 'TOGGLE_IDLE_TIME_POPUP':
      return {
        ...state,
        isIdleTimePopupActive: action.data
      };
    default:
      return { ...state };
  }
};

export default authReducer;
