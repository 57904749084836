import React, { createContext, useState, useEffect } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { COMPANY_SUB_TYPES } from 'utils';
import useModal from 'utils/hooks/useModal';

const initialFormValues = {
  additionalTaxes: [],
  code: '',
  currency: '',
  name: '',
  price: null,
  priceType: '',
  unitTypeId: null,
  vatRate: null,
  vatWithholdingCode: '',
  vatWithholdingRate: null
};

const ItemContext = createContext({
  formValues: initialFormValues,
  setFormValues: () => {},
  setSelectedItem: () => {},
  selectedItem: null,
  toggleAddNewItemModal: () => {},
  isAddNewItemModalActive: false,
  toggleEditItemModal: () => {},
  isEditItemModalActive: false,
  isFreelancer: false
});

export const useItemContext = () => useContext(ItemContext);

export default function ItemContextProvider({ children }) {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isFreelancer, setIsFreelancer] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [isAddNewItemModalActive, , toggleAddNewItemModal] = useModal({
    shouldBeBlockScroll: true
  });
  const [isEditItemModalActive, , toggleEditItemModal] = useModal({
    shouldBeBlockScroll: true
  });

  const resetFormValues = () => setFormValues(initialFormValues);

  useEffect(() => {
    setIsFreelancer(user?.company?.subType === COMPANY_SUB_TYPES.FREE_LANCER);
  }, [user]);

  return (
    <ItemContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues,
        selectedItem,
        setSelectedItem,
        isAddNewItemModalActive,
        toggleAddNewItemModal,
        isEditItemModalActive,
        toggleEditItemModal,
        isFreelancer
      }}
    >
      {children}
    </ItemContext.Provider>
  );
}
