import { useEffect } from 'react';

import { SideModal } from 'components';
import useDengage from 'utils/hooks/useDengage';

const ContactUsModal = ({ handleClose }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'support' }), []);

  return (
    <SideModal handleClose={handleClose}>
      <div className="flex flex-col">
        <span className="text-3xl font-semibold text-gray-900">Bize UIaşın</span>
        <div className="mt-10 flex flex-col gap-y-2 pb-4 border-b border-solid border-gray-200">
          <span className="text-xs font-medium text-gray-500">İletişim Numaramız</span>
          <span className="text-sm font-medium text-gray-900">0 (850) 211 73 73</span>
        </div>
        <div className="flex flex-col gap-y-2 py-4 border-b border-solid border-gray-200">
          <span className="text-xs font-medium text-gray-500">E-Posta Adresimiz</span>
          <span className="text-sm font-medium text-gray-900">destek@norma.co</span>
        </div>
        <div className="flex flex-col gap-y-2 py-4 border-b border-solid border-gray-200">
          <span className="text-xs font-medium text-gray-500">Websitemiz</span>
          <span className="text-sm font-medium text-gray-900">norma.co</span>
        </div>
        <div className="flex flex-col gap-y-2 py-4 border-b border-solid border-gray-200">
          <span className="text-xs font-medium text-gray-500">Adresimiz</span>
          <span className="text-sm font-medium text-gray-900">
            Fenerbahçe mah. Fener Kalamış Cad. Seda Ap. Blok No: 63 A Kadıköy
          </span>
        </div>
      </div>
    </SideModal>
  );
};

export default ContactUsModal;
