const initialState = {
  countries: [],
  cities: [],
  districts: {},
  regions: [],
  streets: []
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CITIES':
      return {
        ...state,
        cities: action.data
      };
    case 'SET_DISTRICT':
      return {
        ...state,
        districts: { ...state.districts, [action.data.cityId]: action.data.data }
      };
    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: action.data
      };
    case 'SET_REGIONS':
      return {
        ...state,
        regions: action.data
      };
    case 'SET_STREETS':
      return {
        ...state,
        streets: action.data
      };
    default:
      return { ...state };
  }
};

export default countryReducer;
