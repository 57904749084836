import TagManager from 'react-gtm-module';

const useGTM = () => {
  window.dataLayer = window.dataLayer || [];

  const initGTM = () => {
    TagManager.initialize({
      gtmId: process.env?.REACT_APP_GTM_TAG_ID
    });
  };

  const sendGTMEvent = (params = {}) => {
    window?.dataLayer?.push(params);
  };

  return {
    initGTM,
    sendGTMEvent
  };
};

export default useGTM;
