import axios from 'axios';
import { store } from 'store/storeConfig/store';
import { toggleSessionEndModal } from 'store/actions/auth';

import { toast } from 'react-hot-toast';
import { LOCAL_STORAGE_PARAMS } from 'utils';
import errorMessages from 'utils/error-messages';

axios.defaults.baseURL =
  process.env?.REACT_APP_API_URL_STAGING ||
  process.env?.REACT_APP_API_URL_DEV ||
  process.env?.REACT_APP_API_URL_PROD;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    //TODO: the url check condition should be removed after the web socket is implemented
    if (!config.url.includes('/api/payments/transaction/'))
      store.dispatch({ type: 'SET_IS_LOADING' });

    //get current language of user
    const currentLanguage = localStorage.getItem(LOCAL_STORAGE_PARAMS.NORMA_LANG);
    config.headers['Accept-Language'] = currentLanguage; //TODO: it will remove after i18n is ready

    const token = store.getState().auth.token || localStorage.getItem('token');
    if (typeof config.headers['Authorization'] !== 'string' && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    store.dispatch({ type: 'SET_IS_LOADING_END' });
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    store.dispatch({ type: 'SET_IS_LOADING_END' });
    if (['invalid_token', 'Unauthorized'].includes(error?.response?.data.error)) {
      store.dispatch(toggleSessionEndModal(true));
      return Promise.reject(error.response);
    }

    const errors = error.response?.data?.errorMessages || [];
    //get localized errors
    const errorCodes = store.getState()?.contentResource?.errorContents?.resources;
    //get current language of user
    const currentLanguage = localStorage.getItem(LOCAL_STORAGE_PARAMS.NORMA_LANG);
    errors.forEach(({ code }) => {
      toast.error(
        errorCodes[currentLanguage][code]
          ? `${code}: ${errorCodes[currentLanguage][code]}`
          : errorMessages[code]
          ? errorMessages[code]
          : 'Bir Hata Oluştu!'
      );
    });

    return Promise.reject(errors);
  }
);
