import React, { useState, useEffect } from 'react';
import { BottomModal, Card } from 'components';
import { useSelector } from 'react-redux';
import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import CopyToClipboardButton from 'components/copyToClipboardButton';
import useDengage from 'utils/hooks/useDengage';

export default function CollectionModal({ isActive, onClose }) {
  const { bankInformationList, receiver, accountNumber } = useSelector(
    (state) => state.bankInformations
  );
  const [selectedBank, setSelectedBank] = useState(null);

  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'transactions_7&24collection_banklist' });
  }, []);

  return (
    <BottomModal
      isActive={isActive}
      handleClose={onClose}
      className="flex justify-center items-center"
    >
      <div className="my-20 w-full max-w-[480px] overflow-scroll py-5">
        {!selectedBank && (
          <BankList data={bankInformationList} setSelectedBank={setSelectedBank} />
        )}
        {selectedBank && (
          <BankAccountInformations
            receiver={receiver}
            accountNumber={accountNumber}
            data={selectedBank}
          />
        )}
      </div>
    </BottomModal>
  );
}

const BankList = ({ data = [], setSelectedBank }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'transactions_7&24collection_transfer_info' });
  }, []);

  return (
    <div className="max-w-[418px] w-full m-auto">
      <div className="mb-6">
        <p className="text-gray-400 text-sm">7/24 Tahsilat</p>
        <h5>Para transferinin yapılacağı bankayı seçiniz.</h5>
      </div>
      <Card>
        <div className="flex flex-col gap-y-4">
          {data?.map((bankInformation, i) => (
            <BankListItem
              key={i}
              data={bankInformation}
              onSelected={() => setSelectedBank(bankInformation)}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

const BankListItem = ({ data, onSelected }) => {
  return (
    <div
      className="border-solid border-b border-gray-100 py-2 text-gray-500 hover:bg-gray-50 cursor-pointer px-3 rounded-md"
      onClick={onSelected}
    >
      <div className="flex justify-between items-center">
        <div>
          <img src={data.bankLogoUrl} alt={data.bankName} />
        </div>
        <IconArrowRight className="stroke-current" />
      </div>
    </div>
  );
};

const BankAccountInformations = ({ data, receiver, accountNumber }) => {
  return (
    <div className="flex flex-col gap-y-2 items-center">
      <BankAccountInformationItem text="Alıcı" data={receiver} />
      <BankAccountInformationItem text="IBAN" data={data.iban} />
      <BankAccountInformationItem text="Açıklama" data={accountNumber} />
    </div>
  );
};

const BankAccountInformationItem = ({ data, text }) => {
  return (
    <Card className="flex justify-between items-center !shadow-norma max-w-[418px] w-full">
      <div>
        <p className="font-medium text-xs leading-4 text-gray-500">{text}</p>
        <p className="text-gray-900 text-sm leading-5 font-semibold">{data}</p>
      </div>

      <CopyToClipboardButton
        copiedTextMessage=""
        textToCopy={data}
        activateTimeout={3}
        // beforeCopyMessage='Kopyala'
      />
    </Card>
  );
};
