import { binQueryTypes } from 'store/types';

const { GET_BIN_QUERY, GET_BIN_QUERY_FAILURE, GET_BIN_QUERY_SUCCESS } = binQueryTypes;

const initialState = {
  data: null
};

export default function binQueryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BIN_QUERY:
    case GET_BIN_QUERY_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case GET_BIN_QUERY_FAILURE:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}
