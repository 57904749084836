import React, { useEffect } from 'react';
import StepHeader from 'components/stepHeader';
import { WALLET_TYPES } from 'utils/contants';
import { BottomModal, Button, Card, Divider } from 'components';
import classNames from 'classnames';
import useModal from 'utils/hooks/useModal';
import { Prompt } from 'components/promptModal';

import { useWalletContext } from 'contexts/walletContext';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getWallets } from 'store/actions/accounts';
import CreateWalletWarningPopup from '../createWalletPopup';

//icons
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { getOpenBankingBanks } from 'store/actions/openBanking';

export default function WalletSelectionModal({ onTypeSelected = () => {}, onClose = () => {} }) {
  const [isOpenBankingWarningPopupActive, , toggleOpenBankingWarningPopup] = useModal();
  const {
    setSelectedWalletType,
    selectedWalletType,
    toggleOpenBankingCreateModal,
    toggleCreateWalletModal,
    setSelectedWallet
  } = useWalletContext();

  const dispatch = useDispatch();

  const { walletTypes } = useSelector((state) => state.wallet);

  const [isCreateWalletWarningPopupActive, setIsCreateWalletWarningPopupActive] = useModal({
    shouldBeBlockScroll: true
  });

  useEffect(() => {
    dispatch(getOpenBankingBanks());
  }, []);
  const onSelected = (type, wallet) => {
    dispatch(getWallets({ status: 'ACTIVE', type, openBankingId: wallet?.openBankingId })).then(
      (data) => {
        setSelectedWalletType(type);
        setSelectedWallet(wallet);
        if (data.length === 0 && !wallet?.openBankingId) {
          if (type === WALLET_TYPES.OPEN_BANKING.value) {
            return toggleOpenBankingWarningPopup();
          } else {
            return setIsCreateWalletWarningPopupActive(true);
          }
        }

        onTypeSelected(type);
        onClose();
      }
    );
  };

  const onApprovedCreateOpenBanking = () => {
    onClose();
    toggleOpenBankingCreateModal();
    onTypeSelected(WALLET_TYPES.OPEN_BANKING.value);
    setSelectedWalletType(WALLET_TYPES.OPEN_BANKING.value);
  };

  const onApprovedNewWalletCreate = () => {
    onClose();
    setIsCreateWalletWarningPopupActive(false);
    toggleCreateWalletModal();
  };

  const onRejectedNewWalletCreate = () => {
    setIsCreateWalletWarningPopupActive(false);
  };

  return (
    <BottomModal isActive={true} handleClose={onClose}>
      {isOpenBankingWarningPopupActive && (
        <OpenBankingWarningPopup
          onOk={onApprovedCreateOpenBanking}
          onCancel={toggleOpenBankingWarningPopup}
        />
      )}
      {isCreateWalletWarningPopupActive && (
        <CreateWalletWarningPopup
          isActive={isCreateWalletWarningPopupActive}
          walletType={selectedWalletType}
          onClose={onRejectedNewWalletCreate}
          onReject={onRejectedNewWalletCreate}
          onApproved={onApprovedNewWalletCreate}
        />
      )}
      <div className="w-full h-full flex items-center justify-center max-w-[416px] mx-auto">
        <div className="overflow-y-auto h-5/6">
          <StepHeader title="Hesaplarım" subtitle="İşlem yapmak istediğiniz hesabınızı seçin" />
          <div className="flex flex-col gap-y-4 h-full">
            <div className="flex flex-col gap-y-4">
              <div className="text-sm text-gray-400 font-medium">Banka Hesapları</div>
              {walletTypes?.bankAccountList?.map((wallet, index) => (
                <WalletTypeCard
                  key={index + wallet?.name}
                  id={index}
                  data={wallet}
                  logoUrl={wallet.logoUrl}
                  name={wallet.name}
                  type={wallet.type}
                  onSelected={onSelected}
                />
              ))}
            </div>
            <Divider className="border-gray-100" />
            <div className="flex flex-col gap-y-4">
              <div className="text-sm text-gray-400 font-medium">Kasa Hesapları</div>
              {walletTypes?.impersonalAccountList?.map((wallet, index) => (
                <WalletTypeCard
                  key={index + wallet?.name}
                  id={index}
                  data={wallet}
                  logoUrl={wallet.logoUrl}
                  name={wallet.name}
                  type={wallet.type}
                  onSelected={onSelected}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </BottomModal>
  );
}

function WalletTypeCard({ logoUrl, name, onSelected, disabled, type, data, ...props }) {
  const onClick = () => {
    onSelected(type, data);
  };
  const classes = classNames(
    'w-full flex items-center gap-x-4 cursor-pointer relative select-none bg-white',
    {
      ['!bg-gray-100 cursor-not-allowed border']: disabled
    }
  );

  if (name) {
    return (
      <Card className={classes} onClick={onClick} {...props}>
        <div>
          <img src={logoUrl} alt={name} width={32} height={32} />
        </div>
        <div className="flex flex-col gap-y-2 cur">
          <p className="text-base font-semibold text-gray-900">{name}</p>
        </div>
        {disabled && (
          <span className="absolute top-5 right-5 bg-gray-200 rounded-full px-3 py-1 text-xs text-gray-600">
            Yakında
          </span>
        )}
      </Card>
    );
  }

  return (
    <Card className={classes} onClick={onClick} {...props}>
      <img src={logoUrl} alt={logoUrl} className="h-6 w-28" />
    </Card>
  );
}

function OpenBankingWarningPopup({ onOk, onCancel }) {
  return (
    <Prompt
      isActive={true}
      className="max-w-xs mx-auto"
      title={
        <div className="flex flex-col items-center justify-between gap-y-6">
          <Button
            variant="circle"
            color="yellow"
            className="pointer-events-none scale-150"
            icon={<IconInfoCircle className="fill-current text-yellow-600" />}
          />
          <div className="text-lg font-semibold">
            Entegre Banka hesabınız bulunmamakta, açmak ister misiniz?
          </div>
        </div>
      }
      okText="Evet"
      cancelText="Vazgeç"
      onOK={onOk}
      onCancel={onCancel}
    />
  );
}
