import { CodeInput, CustomButton } from 'components';
import toast from 'react-hot-toast';
import useKeyPress from 'utils/hooks/useKeyPress';
import { useRouter } from 'utils/hooks/useRouter';
import { logout, validate_password } from 'store/actions/auth';
import IconDoubleCheck from 'assets/illustrations/card/double-check.png';
import { useDispatch } from 'react-redux';
import ToastThemeWithTitle from 'components/toastThemeWithTitle';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { useEffect, useState } from 'react';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';

const CustomHeader = ({ title, subtitle }) => (
  <div className="flex flex-col">
    <span className="font-semibold text-sm text-gray-400">{title}</span>
    <span className="text-gray-900 text-3xl font-semibold">{subtitle}</span>
  </div>
);

const CurrentPassword = ({ nextStep, setActualPassword, actualPassword }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_password'
    });
  }, []);
  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;
    handleNextStep(actualPassword);
  });

  const handleNextStep = (actualPassword) => {
    validate_password(actualPassword).then((res) => {
      if (res.data.data) {
        nextStep();
      } else {
        toast.error('Mevcut şifreniz doğru değil!');
      }
    });
  };

  const isCorrect = actualPassword.length === 6;

  return (
    <div className="w-full flex flex-col">
      <CustomHeader title="Şifre Değiştirme" subtitle="Mevcut şifrenizi girin" />
      <CodeInput
        invisible={true}
        onChange={(e) => setActualPassword(e)}
        className="mt-16"
        length={6}
        autofocus
      />
      <CustomButton
        className="mt-10"
        disabled={!isCorrect}
        color="success"
        onClick={() => handleNextStep(actualPassword)}
      >
        Devam
      </CustomButton>
    </div>
  );
};
const NewPassword = ({ nextStep, setPassword, password }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_new_password'
    });
  }, []);

  const [error, setError] = useState(null);
  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    nextStep();
  });

  function approve() {
    if (error) return;
    nextStep();
  }

  useEffect(() => {
    if (PASSWORD_REPEATING_REGEX.test(password)) {
      setError('Şifreniz tekrarlayan rakamlardan oluşamaz');
    } else if (PASSWORD_SEQUENTIAL_REGEX.test(password)) {
      setError('Şifreniz ardışık rakamlardan oluşamaz');
    } else {
      setError(null);
    }
  }, [password]);

  const isCorrect = password.length === 6;

  return (
    <div className="w-full flex flex-col">
      <CustomHeader title="Şifre Değiştirme" subtitle="Yeni giriş şifrenizi belirleyin" />
      <CodeInput
        invisible={true}
        onChange={(e) => setPassword(e)}
        className="mt-16"
        length={6}
        autofocus
        error={!!error}
      />
      {!!error && <span className="text-sm text-red-500">{error}</span>}
      <CustomButton
        onClick={approve}
        className="mt-10"
        disabled={!isCorrect || !!error}
        color="success"
      >
        Devam
      </CustomButton>
    </div>
  );
};
const ConfirmNewPassword = ({ handleChangePassword, setRePassword, rePassword }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_new_password_2'
    });
  }, []);
  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    handleChangePassword();
  });

  const isCorrect = rePassword.length === 6;

  return (
    <div className="w-full flex flex-col">
      <CustomHeader
        title="Şifre Değiştirme"
        subtitle="Yeni belirlediğiniz şifrenizi tekrar girin"
      />
      <CodeInput
        invisible={true}
        onChange={(e) => setRePassword(e)}
        className="mt-16"
        length={6}
        autofocus
      />
      <CustomButton
        onClick={handleChangePassword}
        className="mt-10"
        disabled={!isCorrect}
        color="success"
      >
        Devam
      </CustomButton>
    </div>
  );
};

const SuccessfullyChanged = () => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_new_password_success'
    });
  }, []);

  const { push } = useRouter();
  const dispatch = useDispatch();
  useKeyPress((e) => {
    if (e.key !== 'Enter') return;

    onClose();
  });

  const onClose = () => {
    dispatch(logout());
    toast.custom(
      <ToastThemeWithTitle
        title="Şifre güncellendi!"
        message="Lütfen yeni şifreniz ile giriş yapınız."
        icon={IconInfoCircle}
      />
    );
    push('/login');
  };

  return (
    <div className="flex flex-col w-full items-center">
      <img src={IconDoubleCheck} alt="double-check" width="100" />
      <span className="text-white text-3xl font-medium text-center mt-6">
        Şifreniz başarıyla değiştirildi
      </span>
      <CustomButton color="success" className="mt-8 w-full" onClick={onClose}>
        Kapat
      </CustomButton>
    </div>
  );
};

export { CurrentPassword, NewPassword, ConfirmNewPassword, SuccessfullyChanged };
