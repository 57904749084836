import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { BottomModal, Button } from 'components';
import { CodeInput, CustomButton } from '../../components';
import useGTM from 'utils/hooks/useGTM';

import DoubleCheck from 'assets/illustrations/card/double-check.png';
// import { closeCard } from 'store/actions/card';
import { closePrepaidCard } from 'store/actions/card';
import errorCodes from 'utils/errorCodes';

// import { ReactComponent as IconDeliveryTruck } from 'assets/icons/delivery-truck.svg';
import StepHeader from 'components/stepHeader';

export default function RemoveCardModal({ toggleRemoveCardModal, cardId, accountId, getCards }) {
  const [step, setStep] = useState(0);

  const dispatch = useDispatch();

  const {
    setValue,
    handleSubmit,
    setError,
    watch,
    formState: { errors }
  } = useForm({ defaultValues: { password: '', cardId: '', accountId: '' } });

  function onSubmit(data) {
    const payload = { ...data, cardId, accountId };

    dispatch(closePrepaidCard(payload))
      .then(() => {
        setStep(1);
        getCards();
      })
      .catch((err) => {
        if (err[errorCodes.passwordsDoNotMatch]) {
          setError('password', {
            type: 'invalid',
            message: err[errorCodes.passwordsDoNotMatch]
          });
        }
      });
  }

  const STEPS = {
    0: <Step0 setValue={setValue} errors={errors} watch={watch} />,
    1: <Step1 completed={toggleRemoveCardModal} />
  };

  return (
    <BottomModal handleClose={toggleRemoveCardModal} className={step === 1 && 'bg-green-400'}>
      <div
        className="w-full h-full flex items-center justify-center"
        data-testid="section_remove_card_modal"
      >
        <div className="w-[400px]">
          <form onSubmit={handleSubmit(onSubmit)}>{STEPS[step]}</form>
        </div>
      </div>
    </BottomModal>
  );
}

const Step0 = ({ setValue, errors, watch }) => {
  return (
    <div className="flex flex-col items-start justify-center space-y-10">
      <StepHeader
        title="Kartı Kapat"
        subtitle="Kartınızı kapatmak için 6 haneli Norma şifrenizi girin"
      />
      <CodeInput
        length={6}
        invisible
        onChange={(val) => setValue('password', val)}
        error={errors?.password}
        blockPaste
        only="number"
        data-testid="input_remove_card_password"
        autofocus
      />
      <CustomButton
        color="success"
        className="w-96"
        type="submit"
        disabled={watch('password').length !== 6}
        data-testid="button_remove_card_submit"
      >
        Devam
      </CustomButton>
    </div>
  );
};

const Step1 = ({ completed }) => {
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'remove_card'
    });
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center space-y-10"
      data-testid="section_remove_card_step_1"
    >
      <img
        src={DoubleCheck}
        width="100"
        alt="double-check"
        data-testid="image_remove_card_double_check"
      />
      <span className="text-white text-xl" data-testid="label_remove_card_success">
        Norma Kart başarıyla kapatıldı
      </span>
      <div className="flex flex-col gap-y-4 w-full">
        {/* <Button
          color="secondary"
          leftIcon={<IconDeliveryTruck className="fill-current" />}
          className="w-full"
          type="button"
          onClick={completed}
        >
          Yeni Kart Sipariş Et
        </Button> */}
        <Button className="w-full" onClick={completed} data-testid="button_remove_card_success">
          Kapat
        </Button>
      </div>
    </div>
  );
};
