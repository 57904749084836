import axios from 'axios';
import { bankInformationsTypes } from '../../types';

const { GET_BANK_INFORMATIONS } = bankInformationsTypes;

export const getBanks = (params) => {
  return (dispatch) => {
    return axios.get(`/api/wallets/bank-information`, { params }).then((res) => {
      dispatch({
        type: GET_BANK_INFORMATIONS,
        data: res.data?.data
      });
    });
  };
};
