import { Button, ComboInput } from 'components';
import StepHeader from 'components/stepHeader';
import React from 'react';
import { useContext } from 'react';
import { ActivatePhysicalCardContext } from '../..';

export default function EnterCardNumber() {
  const { setFormValues, formValues, nextStep } = useContext(ActivatePhysicalCardContext);
  const CARD_NUMBER_LENGTH = 16;

  const onChangeCardNumber = (cardNumber) => {
    setFormValues((values) => ({
      ...values,
      cardNumber
    }));
  };
  const onApproveCardNumber = () => {
    nextStep();
  };

  return (
    <div className="flex flex-col gap-y-[136px]">
      <div>
        <StepHeader
          title="Kart Aktifleştirme"
          subtitle="16 haneli kart numaranızı girin"
          className="!mb-10"
        />
        <div>
          <ComboInput count={4} maxLength={4} onChange={onChangeCardNumber} />
        </div>
      </div>
      <Button
        className="w-full"
        onClick={onApproveCardNumber}
        disabled={formValues?.cardNumber?.length !== CARD_NUMBER_LENGTH}
      >
        Onayla
      </Button>
    </div>
  );
}
