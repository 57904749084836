import React, { useEffect } from 'react';
import { EmptyView, Card, IconContainer } from 'components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getBillSubscriptions } from 'store/actions/bill';
import useTextShortener from 'utils/hooks/useTextShortener';
import { BILL_STATUSES } from 'utils';

import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import { ReactComponent as IconInvoice } from 'assets/icons/invoice.svg';

import { useSmartOperationContext } from '../..';

export default function AutoBillPaymentList() {
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state) => state.bill);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getBillSubscriptions(user?.companyId));
  }, []);

  if (subscriptions?.filter((subscription) => subscription?.autoPayment)?.length === 0)
    return <EmptyView>Akıllı İşleminiz Bulunmamaktadır.</EmptyView>;

  return (
    <div>
      <Bills />
    </div>
  );
}

const Bills = () => {
  const { subscriptions } = useSelector((state) => state.bill);
  const { setSelectedBill, toggleAutoBillDetailPayment } = useSmartOperationContext();
  const onSelected = (item) => {
    setSelectedBill(item);
    toggleAutoBillDetailPayment();
  };

  return (
    <div>
      <div className="flex flex-col gap-y-6">
        {subscriptions
          .filter((subscription) => subscription?.autoPayment)
          ?.map((data, i) => (
            <Bill item={data} key={i} onClick={onSelected} />
          ))}
        {subscriptions?.length === 0 && <EmptyView>Kayıtlı Faturanız Bulunmamaktadır</EmptyView>}
      </div>
    </div>
  );
};

const Bill = ({ onClick, item, ...props }) => {
  const corporationName = useTextShortener(item?.corpName, 15);
  return (
    <Card
      className="flex items-center justify-between bg-gray-50 border border-solid border-gray-100 !shadow-none cursor-pointer hover:bg-gray-100 h-[98px]"
      onClick={() => onClick(item)}
      {...props}
    >
      <div className="flex items-center gap-x-16">
        <span className="font-medium leading-6 min-w-[300px]">{item?.recordName}</span>
        <span className="text-sm text-gray-500 font-medium min-w-[145px]">{corporationName}</span>
        <span className="text-sm text-gray-500 font-medium">Abone No: {item?.subscriberNo}</span>
      </div>
      <div className="flex items-center gap-x-16">
        <div className="flex items-center gap-x-4">
          {[BILL_STATUSES.LATE, BILL_STATUSES.NOT_PAID].includes(item?.status) && (
            <IconContainer className="!bg-yellow-100">
              <IconInvoice className="fill-current text-yellow-500 w-4" />
            </IconContainer>
          )}
        </div>
        <IconArrowRight className="stroke-current text-gray-700 w-5 h-5" />
      </div>
    </Card>
  );
};
