const initialState = {
  items: [],
  filteredItems: [],
  totalElements: 0,
  calculation: {
    additionalTaxes: [],
    sellingCurrency: null,
    sellingUnitPrice: 0,
    sellingUnitPriceWithTax: 0,
    sellingVatAmount: 0,
    sellingVatRate: 0,
    situation: null
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEMS':
      return {
        ...state,
        items: action.data,
        totalElements: action.totalElements,
        totalPages: action.totalPages
      };
    case 'SET_ITEM':
      return {
        ...state,
        item: action.data
      };
    case 'SET_FILTERED_ITEMS':
      return {
        ...state,
        filteredItems: action.data,
        totalElements: action.totalElements,
        totalPages: action.totalPages
      };
    case 'SET_CALCULATION':
      return {
        ...state,
        calculation: action.data
      };
    case 'CLEAR_CALCULATION':
      return {
        ...state,
        calculation: initialState.calculation
      };
    default:
      return state;
  }
};

export default authReducer;
