import Logo from 'assets/icons/logo.svg';

const StepHeader = ({ title, subtitle, ...props }) => (
  <div className="flex flex-col mb-6" data-testid="section_header_company_options" {...props}>
    <img src={Logo} alt="logo" width="35" data-testid="section_header_logo_company_options" />
    <span
      className="text-gray-400 mt-6 text-sm font-semibold"
      data-testid="label_step_header_title"
    >
      {title}
    </span>
    <span
      className="text-gray-900 text-xl font-semibold mt-3"
      data-testid="label_step_header_subtitle"
    >
      {subtitle}
    </span>
  </div>
);

export default StepHeader;
