const errorCodes = {
  emailAlreadyInUse: 13101,
  phoneAlreadySaved: 13201,
  invalidOtpToken: 15102,
  invalidCredential: 10001,
  invalidPhoneNumber: 11001,
  invalidEmail: 4000,
  otpNotFound: 4004,
  passwordNotSame: 13401,
  invalidIdentityNumber: 4000,
  somethingWentWrong: 5000,
  userIDAlreadyRegistered: 13303,
  thereIsCompanyWithSameName: 19002,
  passwordsDoNotMatch: 13401
};

export default errorCodes;
