import { useState, useEffect } from 'react';

import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';

// Flags
import TurkeyFlag from 'assets/flags/turkey.png';

const countries = {
  TR: {
    name: 'Türkiye',
    flag: TurkeyFlag,
    code: '+90',
    length: 10
  }
};

const numberRegex = /[^0-9]+/gi;

const PhoneInput = ({
  onChange,
  defaultValue,
  disabled,
  error,
  defaultCountryCode = 'TR',
  disableCountrySelect = true,
  autofocus,
  onKeyPress,
  justCellPhone = true,
  isOptional = false,
  showCountryCode = true,
  ...props
}) => {
  const [phone, setPhone] = useState(defaultValue);
  useEffect(() => {
    setPhone(defaultValue);
  }, [defaultValue]);

  if (!Object.keys(countries).includes(defaultCountryCode)) defaultCountryCode = 'TR';

  const inputClassNames =
    'bg-gray-100 focus:outline-none w-full h-full focus:bg-white max-w-[160px]';

  const [selectedCountryCode, setSelectedCountryCode] = useState(defaultCountryCode);
  const selectedCountry = countries[selectedCountryCode];

  const [isSelectCountryOpen, setIsSelectCountryOpen] = useState(false);
  function toggleSelectCountry() {
    if (disableCountrySelect) return;
    setIsSelectCountryOpen(!isSelectCountryOpen);
  }

  if (defaultValue && defaultValue.startsWith(selectedCountry.code)) {
    defaultValue = defaultValue.slice(selectedCountry.code.length);
  }

  function handleChange(e) {
    if (e.target.value.startsWith(selectedCountry.code)) {
      e.target.value = e.target.value.slice(selectedCountry.code.length);
    }
    if (numberRegex.test(e.target.value)) {
      e.target.value = e.target.value.replace(numberRegex, '');
    }
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10);
    }
    if (isNaN(e.target.value)) {
      e.target.value = e.target.value
        .split('')
        .reduce((cur, pre) => (isNaN(pre) ? cur : cur + pre), '');
      return e.target.value;
    }
    if (e.target.value[0] === '0') {
      e.target.value = '';
    }
    if (justCellPhone && e.target.value[0] !== '5') {
      e.target.value = '';
    }
    if (e.target.value.length > selectedCountry.length) {
      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    }
    const formattedInput = `${selectedCountry.code}${e.target.value}`;
    const phoneInput = e.target.value;
    setPhone(phoneInput);

    if (onChange) onChange(formattedInput, phoneInput);
  }

  return (
    <div className="flex flex-col min-w-max">
      <div className="relative flex">
        <div className="flex items-start w-full rounded-lg">
          {showCountryCode && (
            <div className="flex flex-col mr-3">
              <span className="text-sm text-gray-500">Ülke Kodu</span>
              <div
                className="px-3 py-2 bg-gray-100 rounded-lg flex items-center justify-center cursor-default h-12 mt-1"
                onClick={toggleSelectCountry}
              >
                <img src={selectedCountry.flag} alt="Flag" width="22" />
                <span className="ml-2 text-sm font-semibold text-gray-900">
                  {selectedCountry.code}
                </span>
                <IconCaretDown className="stroke-current text-gray-500 w-11 ml-2" />
              </div>
            </div>
          )}
          <div className="flex flex-col w-full">
            <span className="text-sm text-gray-500">
              Telefon Numarası{' '}
              {isOptional && (
                <span className="text-sm leading-5 font-medium text-gray-400 italic">
                  {'(Opsiyonel)'}
                </span>
              )}
            </span>
            <div
              className={`${
                error ? 'border-red-500' : 'border-transparent'
              } flex items-center bg-gray-100 px-3 py-2 rounded-lg border border-solid focus-within:bg-white focus-within:shadow-md h-12 mt-1`}
            >
              <input
                onChange={handleChange}
                defaultValue={defaultValue}
                value={phone}
                autoFocus={autofocus}
                disabled={disabled}
                onKeyPress={onKeyPress}
                className={inputClassNames}
                {...props}
              />
            </div>
            {error && (
              <label className="text-xs text-red-500 flex items-center mt-2">{error}</label>
            )}
          </div>
        </div>
        {isSelectCountryOpen && (
          <div className="w-full h-64 bg-white rounded-lg shadow-lg mt-3 border-gray-100 border border-solid absolute block z-50 px-4 pt-4">
            <div className="text-gray-400 bg-gray-100 w-full rounded-lg p-3 flex items-center">
              <IconSearch className="fill-current w-4" />
              <input
                className="ml-2 bg-transparent focus:outline-none w-full"
                placeholder="Arama"
              />
            </div>
            <div className="flex flex-col overflow-y-scroll h-40">
              {Object.keys(countries).map((c, i) => (
                <div
                  className="w-full px-2 py-3 mt-3 hover:bg-gray-100 rounded-lg transition flex cursor-pointer"
                  key={i}
                  onClick={() => setSelectedCountryCode(c)}
                >
                  <img src={countries[c].flag} alt="flag" width="20" className="mr-1" />
                  <span>
                    {countries[c].name} ({countries[c].code})
                  </span>
                  {selectedCountryCode === c && (
                    <IconCheck className="ml-auto stroke-current text-green-500 w-5" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneInput;
