import React, { useState } from 'react';
import { Button, CustomInput, Select, SideModal } from 'components';
import { updateWallet } from 'store/actions/wallet';
import { getFormattedDateWithSeperator } from 'utils';
import { useDispatch } from 'react-redux';
import { getWallets } from 'store/actions/accounts';
import IbanInput from 'views/transactions/components/IbanInput';
import { WALLET_TYPES } from 'utils/contants';
import AmountInput from 'components/AmountInput';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { CURRENCIES } from 'views/other/items/enums';

export default function EditWalletSideModal({ onClose, selectedAccount }) {
  const [name, setName] = useState(selectedAccount?.name);
  const [iban, setIban] = useState(selectedAccount?.iban || 'TR');
  const dispatch = useDispatch();

  const onSave = () => {
    if (name) {
      const isValidIban = iban?.replace(/\s/g, '')?.substr(2, 24)?.length === 24;
      const payload = { ...selectedAccount, name, iban: isValidIban ? iban : null };
      dispatch(updateWallet(payload)).then(() => {
        onClose();
        dispatch(
          getWallets({
            type: selectedAccount.type,
            status: 'ACTIVE'
          })
        );
      });
    }
  };

  const isNotValidForm =
    !name || (iban?.replace('TR', '').length > 0 && iban?.replace('TR', '')?.length < 24);

  return (
    <SideModal handleClose={onClose}>
      <div className="flex flex-col w-full h-full justify-between overflow-y-scroll pb-10">
        <div>
          <span className="font-semibold text-2xl text-gray-900">Hesap Düzenle</span>
          <div className="flex flex-col gap-y-2 justify-between mt-5">
            <CustomInput
              label="Hesap İsmi"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Select
              options={[
                {
                  value: selectedAccount?.currencyCode,
                  label: CURRENCY_NAMES[selectedAccount?.currencyCode]
                }
              ]}
              value="value"
              valueLabel="label"
              label="Para Birimi"
              defaultValue={selectedAccount?.currencyCode}
              disabled
            />
            {selectedAccount?.type !== WALLET_TYPES.EMONEY.value &&
              selectedAccount?.currencyCode !== CURRENCIES.TRY && (
                <AmountInput
                  label="Bakiye"
                  suffix={` ${CURRENCY_NAMES[selectedAccount.currencyCode]}`}
                  value={selectedAccount?.balance?.balance}
                  defaultValue={selectedAccount?.balance?.balance}
                  disabled={true}
                />
              )}
            {selectedAccount?.type !== WALLET_TYPES.EMONEY.value &&
              selectedAccount?.currencyCode !== CURRENCIES.TRY && (
                <CustomInput
                  label="Bakiye Başlangıç Tarihi"
                  disabled={true}
                  value={getFormattedDateWithSeperator(
                    selectedAccount?.balanceStartDate,
                    'tr',
                    '/'
                  )}
                />
              )}

            {selectedAccount?.type === WALLET_TYPES.BANK.value && (
              <div>
                <h5 className="my-6">Banka Bilgileri</h5>
                <div>
                  <div className="text-gray-500 text-sm leading-5 font-medium">
                    IBAN <span className="text-gray-400">{`(Opsiyonel)`}</span>
                  </div>
                  <IbanInput label="" className="!p-0 !m-0" iban={iban} setIban={setIban} />
                </div>
              </div>
            )}
          </div>
        </div>
        <Button className="self-end w-full" onClick={onSave} disabled={isNotValidForm}>
          Kaydet
        </Button>
      </div>
    </SideModal>
  );
}
