import React from 'react';
import { InfoMessage } from 'components';

export default function TimeoutMessage({ onClose }) {
  return (
    <InfoMessage
      className="bg-yellow-400 gap-x-3 max-w-[398px] border-none"
      textClassName="text-yellow-50 select-none"
      iconClassName="text-yellow-50"
      closeIconClassName="text-yellow-50"
      onClose={onClose}
    >
      30 saniye içerisinde işleminizi tamamlayamadığınız için kur değişti.
    </InfoMessage>
  );
}
