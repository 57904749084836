import { Button, SideModal } from 'components';
import React from 'react';

const AgreementModal = ({ onClose, onAgreementAccepted }) => {
  return (
    <SideModal handleClose={onClose} data-testid="section_modal_side_info">
      <span className="text-gray-900 text-3xl font-semibold">NORMA HİZMET ÖZEL ŞARTLARI</span>
      <div className="mt-6 overflow-y-auto agreement-text-container">
        <div className="text-sm">
          <p>
            1. İşbu Hizmet Özel Şartları (“Şartlar”) Norma Finansal Teknoloji A.Ş. (“Norma”)ile
            Norma Üye Sözleşmesini (“Sözleşme”) onaylayan Üye arasında Sözleşme’nin eki olarak
            düzenlenmiştir. İşbu Şartlar kapsamında Norma ve Üye birlikte “Taraflar”, tek
            başlarına “Taraf” olarak anılacaktır. Şartların yorumlanmasında Sözleşme’de yer alan
            terimler esas alınacak olup, Hizmetleri sağlayan üçüncü kişiler işbu Şartlar’da “İş
            Ortağı” olarak anılacaktır.
          </p>
          <p>
            2. Üye, Norma’nın Hizmetleri gruplar halinde ve/veya ayrı ayrı sunma hakkına sahip
            olduğunu, Hizmetleri Platform’da Norma tarafından belirlenen şekilde ve çerçevede
            temin edebileceğini kabul ve beyan eder.
          </p>{' '}
          <p>
            3. Üye, tercih ettiği Hizmet süresinin sona ermesi halinde aksi yönde bir bildirim
            yapmadığı takdirde Hizmet süresinin aynı sürelerle yenileneceğini kabul ve beyan eder.
          </p>{' '}
          <p>
            4. Üye, aracılık edilen Hizmetlere ilişkin şartların İş Ortakları ve Üye arasında
            düzenlenecek sözleşme ile belirleneceğini kabul ve beyan eder.
          </p>{' '}
          <p>
            5. Norma, platformda sağlanan Hizmetlerin alanını genişletmek yahut Hizmetleri başka
            bir İş Ortağına devretmek konusunda münhasıran yetkilidir.
          </p>{' '}
          <p>
            6. Norma’da sağlanan Hizmetlerin başka bir İş Ortağına devredilmesi halinde Üye
            Hizmetin devredildiği İş Ortağı ile sözleşme kurmayı kabul, beyan ve taahhüt eder.
          </p>
          <p>
            7. Üye, Hizmet kapsamına ilişkin değişiklik ile diğer talep ve önerilerini
            <a href="mailto:destek@norma.co" className="px-2">
              destek@norma.co
            </a>{' '}
            adresi üzerinden Norma’ya iletecektir. Üye, Hizmetlere ilişkin değişiklik talebinin
            kabulünün Norma’nın takdirinde olduğunu kabul ve beyan eder.
          </p>{' '}
          <p>
            8. Üye, İş Ortakları ile arasında düzenlenen sözleşmelere ilişkin Norma’nın bir
            sorumluluğu bulunmadığını kabul ve beyan eder. Norma ile Üye arasındaki ilişki ve
            sorumluluklar Sözleşme ve işbu Şartlar kapsamında düzenlenmiştir.
          </p>{' '}
          <p>
            {' '}
            9. Hizmetlere ilişkin olarak yaşanabilecek kesinti, aksaklık hallerinde ve/veya
            Hizmetin uygun olarak ifa edilmemesi sebebiyle uğrayacağı zararlar nedeniyle Norma’nın
            herhangi bir sorumluluğu bulunmadığını kabul ve beyan eder.
          </p>
          <p>
            10. Üye, İş Ortağı tarafından hizmet ilişkisinin sona erdirilmesi halinde ve Norma’ya
            bildirilmesi halinde Norma’nın, kendi takdirine bağlı olarak yeni bir İş Ortağı’na
            aynı şartlarda sağlama hakkı olduğunu kabul ve beyan eder. Üye, böyle bir durumda
            Norma ve yeni İş Ortağı yönlendirmelerine uygun olarak yeni İş Ortağı ile iş
            ilişkisine girebilecektir.
          </p>
          <p>
            {' '}
            11. Üye, almak istediği Hizmetlere ilişkin ödemeyi, ödeme sayfasında karşılıklı
            belirlenen yöntem ve şekilde süresinde ve eksiksiz olarak gerçekleştirmesi halinde
            Hizmetlerin sunulacağını kabul ve beyan eder.
          </p>
          <p>
            {' '}
            12. Üye, Hizmetin herhangi bir nedenle aylık dönem tamamlanmadan sona erdirilmesi
            halinde ilgili döneme ilişkin ücretin tamamını ödemekle yükümlü olduğunu kabul ve
            beyan eder.{' '}
          </p>
          <p>
            {' '}
            13. Üye, Hizmetlere ilişkin ödemelerin aksi Norma tarafından belirtilmediği sürece
            Norma’ya gerçekleştirileceğini kabul ve beyan eder.
          </p>
          <p>
            14. Üye, Hizmet bedelinin ödeme sayfasında karşılıklı belirlenen yöntem ve şekilde
            süresinde ve eksiksiz olarak ödenmemesi halinde Norma’nın Hizmetleri durdurma, sona
            erdirme, erişim engelleme, Hizmet ilişkisini sona erdirme, ilgili kurum ve kuruluşlara
            bildirim yapma ve Hizmet bedelini ödeme hizmeti sağlayan iş ortağı nezdindeki hak ve
            alacaklarından mahsup etme hakkı bulunmaktadır.
          </p>{' '}
          <p>
            15. Üye, bir aydan uzun süreli Hizmet alım taahhüdünde, taahhüt ettiği süreden önce
            Hizmeti sona erdirmek istemesi halinde, Hizmetin sunulduğu süre için Norma tarafından
            taahhüde bağlı olarak gerçekleştirilen indirim bedelini iade etmekle yükümlü olduğunu
            kabul ve beyan eder.
          </p>
          <p>
            16. Hizmet bedellerinde sonradan değişim olması ya da Hizmetin ücretsiz hale gelmesi
            durumunda, Üye, Hizmet bedeline ilişkin indirim ya da iade talep etmeyeceğini kabul ve
            beyan eder.
          </p>
          <p>
            17. Hizmetlere ilişkin ücretlere vergiler dahil değildir. Sözleşme’den ve Hizmetlerden
            doğabilecek her türlü vergiden Üye sorumlu olacaktır.
          </p>
          <p>
            18. Üye, Norma’nın herhangi bir gerekçe göstermeden Hizmetleri sona erdirme, İş
            Ortağı’nı değiştirme, Şartları sona erdirme hakkına sahiptir. Üye, sağlanmış hizmete
            ilişkin hiçbir iade ve hak talebinde bulunmayacağını kabul ve beyan eder.
          </p>
          <p>
            19. İşbu Şartlara uygun olarak sona erdirilmeyen Hizmete ilişkin, Üye’nin Hizmet
            Bedeli Borcu devam etmektedir.
          </p>
          <p>
            20. Sözleşme ile işbu Şartlar arasında çelişki olması halinde Şartlardaki hükümler,
            işbu Şartlarda yer almayan hükümler yönünden Sözleşme hükümleri uygulanır.
          </p>
        </div>
      </div>
      <div>
        <Button className="w-full my-6" onClick={() => onAgreementAccepted(true)}>
          Sözleşmeyi Onayla
        </Button>
      </div>
    </SideModal>
  );
};
export default AgreementModal;
