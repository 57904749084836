import React, { useState } from 'react';
import { BottomModal, Button, SearchInput } from 'components';
import useDebounce from 'utils/hooks/useDebounce';
import { useSelector } from 'react-redux';
import { MONTHS, TRANSACTION_DIRECTION } from 'utils/contants';
import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
import { useDispatch } from 'react-redux';
import { getTransactions } from 'store/actions/transactions';
import { useRef } from 'react';

export default function TransactionsModal({
  selectedWallet,
  icon,
  onClose,
  onUnarchive,
  ...props
}) {
  const [search, setSearch] = useState('');
  const { transactions } = useSelector((state) => state.transactions);
  const dispatch = useDispatch();

  const searhInputRef = useRef(null);

  useDebounce(search, 500, searhInputRef, (debouncedSearch) => {
    searchTransaction(debouncedSearch);
  });

  function searchTransaction(title) {
    let searchParams = { title };

    searchParams = {
      ...searchParams
    };

    dispatch(
      getTransactions({
        walletId: selectedWallet?.id,
        searchParams
      })
    );
  }

  return (
    <BottomModal isActive handleClose={onClose} {...props}>
      <div className="p-20">
        <div className="flex justify-between border-b border-solid border-gray-100 pb-10">
          <h4 className="flex items-center gap-x-2 leading-7">
            {icon} {selectedWallet?.name}
          </h4>
          <div className="flex gap-x-4">
            <SearchInput
              placeholder="test"
              ref={searhInputRef}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button color="secondary" onClick={(e) => onUnarchive(selectedWallet, e)}>
              Filtrele
            </Button>
            <Button onClick={(e) => onUnarchive(selectedWallet, e)}>Arşivden Çıkar</Button>
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          {transactions?.content?.length > 0 &&
            transactions?.content?.map((transaction, i) => (
              <ListItem key={i} transaction={transaction} />
            ))}
        </div>
      </div>
    </BottomModal>
  );
}

const ListItem = ({ transaction }) => {
  const {
    transactionDate: date,
    title,
    description,
    direction,
    amount,
    currencyCode
  } = transaction;
  return (
    <div className="w-full h-auto flex justify-between border border-solid border-gray-100 bg-gray-50 hover:bg-white p-5 rounded-xl">
      <div className="flex">
        {date && (
          <div className="flex flex-col items-center justify-center pl-4 pr-6 mr-6 border-r border-solid border-gray-200 w-28">
            <span className="text-2xl leading-6 text-gray-600 font-medium">
              {new Date(date).getDate().toString().padStart(2, '0')}
            </span>
            <span className="font-normal text-xs">
              {MONTHS[new Date(date).getMonth()] + ' ' + new Date(date).getFullYear()}
            </span>
          </div>
        )}
        <div className="flex flex-col justify-center w-[220px]">
          <p className="text-base font-medium leading-6 text-gray-900">{title || '-'}</p>
          {description && (
            <p className="text-xs font-medium leading-4 text-gray-500">{description}</p>
          )}
        </div>
      </div>
      <div className=" min-w-max flex items-center gap-x-2">
        <span
          className={`${
            direction === TRANSACTION_DIRECTION.IN.key
              ? 'font-semibold text-black group-hover:text-green-500 transition'
              : 'font-semibold text-black group-hover:text-red-500 transition'
          } ml-auto`}
        >
          {direction === TRANSACTION_DIRECTION.IN.key ? '+' : '-'}
          {currencyFormat(amount).replace('-', '')} {CURRENCY_NAMES[currencyCode]}
        </span>
      </div>
    </div>
  );
};
