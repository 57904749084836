import axios from 'axios';

export const getRecurringPaymentSummary = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    axios.get(`/api/companies/${user.companyId}/recurring-payments/summary`).then((res) => {
      if (res.status !== 200) return;
      dispatch({
        type: 'SET_RECURRING_SUMMARY',
        data: res.data?.data
      });
    });
  };
};

export const getRecurringPayments = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    axios.get(`/api/companies/${user.companyId}/recurring-payments`).then((res) => {
      if (res.status !== 200) return;
      dispatch({
        type: 'SET_RECURRING_PAYMENTS',
        data: res.data?.data?.content
      });
    });
  };
};
