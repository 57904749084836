import { useEffect, useState } from 'react';

export default function useDebounce(value, delay, elementRef, callback) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (elementRef?.current === document.activeElement) {
        setDebouncedValue(value);
        if (typeof callback === 'function') callback(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
