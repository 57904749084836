import IllustrationDoubleCheck from 'assets/illustrations/card/double-check.png';
import { BottomModal, Button } from 'components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addCard } from 'store/actions/creditCards';
import { getMarketplaceItems, getSubscriptions } from 'store/actions/marketplace';
import useGTM from 'utils/hooks/useGTM';

const PaymentSuccessModal = ({ selectedService, message, onClose, cardInformations }) => {
  const { sendGTMEvent } = useGTM();
  const dispatch = useDispatch();

  const { name, variant } = selectedService;

  useEffect(() => {
    if (cardInformations) dispatch(addCard(cardInformations));
    dispatch(getMarketplaceItems());
    dispatch(getSubscriptions());
    sendGTMEvent({
      event: 'catEvent',
      item_name: name,
      variant_id: variant?.id,
      eventName: 'marketplace_payment_success'
    });
  }, []);

  return (
    <BottomModal handleClose={onClose} className="bg-green-400">
      <div className="w-full h-full flex flex-col items-center justify-center gap-y-11">
        <img src={IllustrationDoubleCheck} alt="double-check" width="100" />
        <span className="text-3xl text-white" data-testid="label_success_add_company">
          {message}
        </span>
        <Button className="w-96" color="primary" onClick={onClose}>
          Kapat
        </Button>
      </div>
    </BottomModal>
  );
};

export default PaymentSuccessModal;
