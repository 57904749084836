import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'utils/hooks/useRouter';

import { CustomButton, CategoryBadge } from 'components';
import { deleteSaleExpense, getSaleExpenseById } from 'store/actions/invoice';

import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
import useModal from 'utils/hooks/useModal';
import EditExpenseModal from 'views/invoicing/components/editExpenseModal';
import { currencyAmountCalculator } from 'utils/contants';
import PreviewDocument from './PreviewDocument';
import { currencyFormat, PAYMENT_STATUS } from 'utils';
import { getDocumentSummaryById } from 'store/actions/eTransformation';
import useDengage from 'utils/hooks/useDengage';

const PAYMENT_STATUS_TITLES = {
  PAID: 'Ödendi',
  NOT_PAID: 'Ödenecek'
};

const ExpenseInformation = ({ title, fileUrl, category }) => (
  <div className="flex gap-x-4 border-b border-solid border-gray-200 py-16 px-6">
    {fileUrl ? (
      <img src={fileUrl} className="w-14 h-14 rounded-full" alt="Voucher Image" />
    ) : (
      <div className="w-14 h-14 rounded-full bg-green-500" />
    )}
    <div className="flex flex-col justify-center items-start gap-y-1">
      <span className="text-lg font-semibold text-gray-700">{title}</span>
      {category && (
        <CategoryBadge
          iconUrl={category?.iconUrl}
          colorHexCode={category?.colorHexCode}
          textColorHexCode={category?.textColorHexCode}
          name={category?.name}
          className="py-1 text-xs w-auto"
        />
      )}
    </div>
  </div>
);

const Payment = ({ paymentStatus, invoiceDate }) => {
  const date = invoiceDate ? new Date(invoiceDate) : invoiceDate;
  const formattedDate = invoiceDate
    ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`
    : '-';
  return (
    <div className="py-16 border-b border-solid border-gray-200 px-6">
      <span className="text-lg font-semibold text-gray-900">Ödeme</span>
      <div className="flex gap-x-32 mt-6">
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-500">Durum</span>
          <span className="text-lg font-semibold text-gray-900 mt-2">
            {PAYMENT_STATUS_TITLES[paymentStatus] || '-'}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-500">
            {paymentStatus === PAYMENT_STATUS.PAID ? 'Ödeme Tarihi' : 'Vade Tarihi'}
          </span>
          <span className="text-lg font-semibold text-gray-900 mt-2">{formattedDate}</span>
        </div>
      </div>
    </div>
  );
};

const InvoiceInformation = ({ invoiceDate, invoiceNumber }) => {
  invoiceDate = invoiceDate ? new Date(invoiceDate) : invoiceDate;
  const formattedDate = invoiceDate
    ? `${invoiceDate.getDate().toString().padStart(2, '0')}/${(invoiceDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${invoiceDate.getFullYear()}`
    : '-';
  return (
    <div className="py-16 border-b border-solid border-gray-200 px-6">
      <span className="text-lg font-semibold text-gray-900">Fatura Bilgileri</span>
      <div className="flex gap-x-32 mt-6">
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-500">Fatura Tarihi</span>
          <span className="text-lg font-semibold text-gray-900 mt-2">{formattedDate || '-'}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-500">Fatura Numarası</span>
          <span className="text-lg font-semibold text-gray-900 mt-2">{invoiceNumber || '-'}</span>
        </div>
      </div>
    </div>
  );
};

const Financial = ({
  currency,
  taxExcludedAmount,
  totalAmount,
  taxes,
  document,
  totalTaxAmount,
  documentId
}) => {
  // TODO: NOR_3287 WITHOLDING TAXES OBJECT STRUCTURE WILL CHANGE
  const witholdingTaxes =
    document?.financialSummary?.withHoldingTaxSummary?.taxSubtotalSummaries ||
    document?.financialSummary?.withholdingTaxes?.taxSubtotalSummaries ||
    [];

  //totalTaxAmount
  const FinancialField = ({ name, value }) => {
    return (
      <div key={`${name}-${value}`} className="flex items-center justify-between">
        <span className="text-sm font-semibold text-gray-900">{name}</span>
        <span className="text-sm font-semibold text-gray-900 text-right">
          {value && <>{`${currencyFormat(value)} ${CURRENCY_NAMES[currency]}`}</>}
        </span>
      </div>
    );
  };

  return (
    <div className="py-16 border-b border-solid border-gray-200 px-6">
      <span className="text-lg font-semibold text-gray-900">Finansal</span>
      <div className="w-[548px] p-6 bg-gray-50 border border-solid border-gray-200 rounded-xl mt-6 flex flex-col gap-y-4">
        <FinancialField name="Vergisiz Tutar" value={taxExcludedAmount} />
        <FinancialField name="Vergiler" value={!documentId && totalTaxAmount} />
        {witholdingTaxes?.map((tax) => (
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold text-gray-900">{`${tax?.description} (%${tax?.percent})`}</span>
            <span className="text-sm font-semibold text-gray-900 text-right">
              {`${currencyFormat(tax.taxAmount)} ${CURRENCY_NAMES[currency]}`}
            </span>
          </div>
        ))}
        {taxes?.map((tax) => (
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold text-gray-900">{`${tax.name} (%${
              tax.percent || '-'
            })`}</span>
            <span className="text-sm font-semibold text-gray-900 text-right">
              {`${currencyFormat(tax.amount)} ${CURRENCY_NAMES[currency]}`}
            </span>
          </div>
        ))}
        <FinancialField name="Genel Toplam" value={totalAmount} />
      </div>
      {currency !== 'TRY' && document?.pricingExchangeRate && (
        <div className="flex items-center gap-x-2 mt-4">
          <InfoCircleIcon className="fill-current text-gray-500 w-6" />
          <span className="text-sm font-medium text-gray-500">
            Bu işlem için döviz kuru {document?.pricingExchangeRate?.calculationRate}
            TL olarak kullanılmıştır.
          </span>
        </div>
      )}
    </div>
  );
};

const Note = ({ note }) => (
  <div className="py-16 px-6 flex flex-col gap-y-4">
    <span className="text-lg font-semibold text-gray-900">Not</span>
    <span className="text-lg font-semibold text-gray-900">{note}</span>
  </div>
);

const ExpenseDetail = () => {
  const dispatch = useDispatch();
  const { query, history } = useRouter();
  const { publishEvent, eventTypes } = useDengage();

  const [data, setData] = useState();
  const [document, setDocument] = useState();

  const [isEditExpenseModalActive, setEditExpenseModal, toggleEditExpenseModal] = useModal({
    shouldBeBlockScroll: true
  });
  const [documentPreviewPopupActive, , toggleDocumentPreviewPopup] = useModal();

  const { user } = useSelector((state) => state.auth);

  const defaultValues = {
    salesExpenseRecordId: data?.id,
    categoryId: data?.category?.id || null,
    contactId: data?.contact?.id || null,
    currency: data?.currency,
    currencyRate: data?.currencyRate,
    documentId: data?.documentId || data?.salesExpenseInvoiceDto?.documentId,
    dueDate: data?.dueDate ? new Date(data?.dueDate) : null,
    invoiceDate: data?.salesExpenseInvoiceDto?.invoiceDate
      ? new Date(data?.salesExpenseInvoiceDto?.invoiceDate)
      : null,
    invoiceFileIds: !!data?.files.length ? data.files.map((f) => f.file.id) : [],
    invoiceNumber: data?.salesExpenseInvoiceDto?.invoiceNumber,
    items: data?.salesExpenseItems || [],
    note: data?.note,
    paymentDate: data?.paymentDate ? new Date(data?.paymentDate) : null,
    paymentMethod: data?.paymentMethod,
    paymentStatus: data?.paymentStatus,
    recordType: data?.recordType,
    title: data?.title,
    totalTaxAmount: data?.totalTaxAmount,
    totalAmount: data?.totalAmount,
    voucherFileIds: data?.files.length ? data?.files.map((f) => f.id) : [],
    transferIds: data?.transfers || []
  };

  function handleDelete() {
    if (!user.companyId || !data.id) return;

    setEditExpenseModal(false);
    deleteSaleExpense({ companyId: user.companyId, id: data.id })
      .then((res) => {
        history.push('/invoicing');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!user?.companyId) return;

    dispatch(getSaleExpenseById({ companyId: user.companyId, salesExpenseRecordId: query.id }))
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, isEditExpenseModalActive]);

  useEffect(() => {
    if (!data?.salesExpenseInvoiceDto?.documentId || !user.companyId) return;

    dispatch(
      getDocumentSummaryById({
        companyId: user.companyId,
        documentId: data?.salesExpenseInvoiceDto?.documentId
      })
    ).then((res) => {
      setDocument(res.data?.data);
    });
  }, [data, user]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_detail_expense' });
  }, []);

  return (
    <div className="relative bg-gray-200 flex justify-center py-12 w-full h-auto min-h-screen">
      {isEditExpenseModalActive && (
        <EditExpenseModal
          handleClose={toggleEditExpenseModal}
          defaultValues={defaultValues}
          deleteExpense={handleDelete}
          defaultFile={data?.files[0]?.file}
          data={data}
          defaultInvoice={data?.salesExpenseInvoiceDto}
        />
      )}
      {documentPreviewPopupActive && (
        <PreviewDocument
          handleClose={toggleDocumentPreviewPopup}
          documentId={data?.salesExpenseInvoiceDto?.documentId}
        />
      )}
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative w-11/12 xl:w-3/4">
        <div className="flex items-center justify-between flex-wrap gap-y-4">
          <span className="text-xl font-semibold text-white">Masraf Detayı</span>
          <div className="flex gap-4">
            {!!data?.salesExpenseInvoiceDto?.documentId && (
              <CustomButton color="success" onClick={toggleDocumentPreviewPopup}>
                Belge Görüntüle
              </CustomButton>
            )}
            <CustomButton onClick={toggleEditExpenseModal}>Düzenle</CustomButton>
          </div>
        </div>
        <div className="w-full bg-white px-6 rounded-xl mt-4">
          <ExpenseInformation
            title={data?.title}
            category={data?.category}
            fileUrl={data?.files[0]?.file?.url}
          />
          <Payment
            paymentStatus={data?.paymentStatus}
            invoiceDate={data?.dueDate || data?.paymentDate}
          />
          <InvoiceInformation
            invoiceDate={data?.salesExpenseInvoiceDto?.invoiceDate}
            invoiceNumber={data?.salesExpenseInvoiceDto?.invoiceNumber}
          />
          <Financial
            currency={data?.currency}
            taxExcludedAmount={data?.taxExcludedAmount}
            totalAmount={data?.totalAmount}
            taxes={data?.taxes || []}
            totalTaxAmount={data?.totalTaxAmount}
            document={document}
            documentId={data?.salesExpenseInvoiceDto?.documentId}
          />
          <Note note={data?.note} />
        </div>
      </div>
    </div>
  );
};

export default ExpenseDetail;
