import { enumTypes } from 'store/types';

const {
  GET_ENUMS,
  GET_ENUMS_SUCCESS,
  GET_ENUMS_FAILURE,
  GET_DAYS_OF_WEEK,
  GET_DAYS_OF_WEEK_SUCCESS,
  GET_DAYS_OF_WEEK_FAILURE,
  GET_DAYS_OF_MONTH,
  GET_DAYS_OF_MONTH_SUCCESS,
  GET_DAYS_OF_MONTH_FAILURE
} = enumTypes;

const initialState = {
  days: [],
  months: [],
  isLoading: false,
  error: null
};

export default function WalletReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ENUMS:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case GET_ENUMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allEnums: action.payload,
        error: null
      };
    case GET_ENUMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GET_DAYS_OF_WEEK:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case GET_DAYS_OF_WEEK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        daysOfWeek: action.payload,
        error: null
      };
    case GET_DAYS_OF_WEEK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GET_DAYS_OF_MONTH:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case GET_DAYS_OF_MONTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        daysOfMonth: action.payload,
        error: null
      };
    case GET_DAYS_OF_MONTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
