import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { useRouter } from 'utils/hooks/useRouter';

const TabsCard = ({ tabs, defaultTab = 0, className }) => {
  const [, setTab] = useState(defaultTab);
  const { match } = useRouter();

  const handleTabs = (i) => {
    if (tabs[i]?.disabled) return;

    setTab(i);
  };

  return (
    <div className={`${className} h-auto bg-white shadow-md rounded-xl`}>
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full flex px-8 items-center border-b border-solid border-gray-100 text-black text-sm space-x-8 overflow-x-auto pr-6">
          {tabs.map((t, i) => {
            return (
              <NavLink
                exact={i === 0}
                to={t.route}
                key={i}
                className={`text-gray-400 fill-gray-400 solid-gray-400 w-min min-w-max font-semibold leading-6 pb-3 pt-5 h-auto flex items-center justify-center gap-x-3 cursor-pointer select-none`}
                activeClassName="border-b border-solid border-green-500 text-green-500"
                onClick={() => handleTabs(i)}
              >
                {t.icon}
                {t.title}
              </NavLink>
            );
          })}
        </div>
        <div className="w-full mt-4 p-6">
          <Switch>
            <Route exact path={`${match.path}`}>
              {tabs[0].page}
            </Route>
            <Route exact path={`${tabs[1].route}`}>
              {tabs[1].page}
            </Route>
            <Route exact path={`${tabs[2].route}`}>
              {tabs[2].page}
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default TabsCard;
