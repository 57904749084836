import React from 'react';
import logo from 'assets/images/logo.png';
// import { ReactComponent as UnionIcon } from 'assets/icons/union.svg';
import { Link } from 'react-router-dom';

import { ProfileMenu, Navbar } from './subcomponents';

const Header = () => (
  <div className="h-auto w-full text-white lg:px-12 px-4 py-4 gap-y-4 flex flex-wrap md:flex-nowrap items-center justify-between bg-gray-900">
    <Link to="/">
      <img src={logo} alt="logo" width="133" />
    </Link>
    <Navbar />
    <div className="flex items-center gap-x-6">
      {/* <Link to="/?notifications">
        <UnionIcon className="fill-current text-gray-500 w-5 cursor-pointer" />
      </Link> */}
      <ProfileMenu />
    </div>
  </div>
);

export default Header;
