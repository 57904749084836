import { useEffect, useState } from 'react';

export default function useTextShortener(text, maxLength = 10) {
  const [shortText, setShortText] = useState(text);

  useEffect(() => {
    if (text?.length > maxLength) {
      setShortText(`${text?.substring(0, maxLength)}...`);
    } else {
      setShortText(text);
    }
  }, [text]);

  return shortText;
}
