import { archiveTypes } from 'store/types';
import axios from 'axios';

const {
  GET_ARCHIVED_WALLET,
  GET_ARCHIVED_WALLET_SUCCESS,
  GET_ARCHIVED_WALLET_FAILURE,
  ARCHIVE_WALLET,
  ARCHIVE_WALLET_SUCCESS,
  ARCHIVE_WALLET_FAILURE,
  UNARCHIVE_WALLET,
  UNARCHIVE_WALLET_SUCCESS,
  UNARCHIVE_WALLET_FAILURE
} = archiveTypes;

export const getArchivedWallets = (params) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_ARCHIVED_WALLET_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: GET_ARCHIVED_WALLET
      });

      const response = await axios.get(
        `/api/companies/${user?.companyId}/wallets?status=ARCHIVE`,
        {
          params
        }
      );

      if (response.status === 200) {
        dispatch({
          type: GET_ARCHIVED_WALLET_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_ARCHIVED_WALLET_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const archiveWallet = (walletId = null) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!walletId) {
      return dispatch({
        type: ARCHIVE_WALLET_FAILURE,
        payload: 'walletId is not defined'
      });
    }
    if (!user?.companyId)
      return dispatch({
        type: ARCHIVE_WALLET_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: ARCHIVE_WALLET
      });

      const response = await axios.post(
        `/api/companies/${user?.companyId}/wallets/${walletId}/archive`
      );

      if (response.status === 200) {
        dispatch({
          type: ARCHIVE_WALLET_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: ARCHIVE_WALLET_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const unarchiveWallet = (walletId = null) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!walletId) {
      return dispatch({
        type: UNARCHIVE_WALLET_FAILURE,
        payload: 'walletId is not defined'
      });
    }
    if (!user?.companyId)
      return dispatch({
        type: UNARCHIVE_WALLET_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: UNARCHIVE_WALLET
      });

      const response = await axios.post(
        `/api/companies/${user?.companyId}/wallets/${walletId}/unarchive`
      );

      if (response.status === 200) {
        dispatch({
          type: UNARCHIVE_WALLET_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UNARCHIVE_WALLET_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
