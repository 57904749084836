import IconContainer from 'components/iconContainer';
import React from 'react';
import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { ReactComponent as IconBank } from 'assets/icons/bank.svg';
import classNames from 'classnames';

export default function SelectedAccountView({
  name,
  amount,
  customIcon,
  verticalView,
  disabled
}) {
  const className = classNames(
    'p-6 flex items-center justify-between w-full bg-gray-100 border-solid border border-gray-200 rounded-lg'
  );
  const iconContainerClass = classNames(disabled ? '!bg-gray-300' : '!bg-green-300');
  const iconClass = classNames('fill-current', disabled ? '!text-gray-600' : '!text-green-700');
  const nameClasses = classNames('font-medium', disabled && 'text-gray-400');
  const amountClasses = classNames('text-2xl leading-7 font-medium', disabled && 'text-gray-400');
  const amountVerticalClass = classNames('text-sm', disabled && 'text-gray-400');

  if (verticalView) {
    return (
      <div className={className}>
        <div className="flex gap-x-4 items-center">
          <IconContainer className={iconContainerClass}>
            {customIcon ? (
              React.cloneElement(customIcon, { className: iconClass })
            ) : (
              <IconBank className={iconClass} />
            )}
          </IconContainer>
          <div className="flex flex-col">
            <span className={nameClasses}>{name}</span>
            <span className={amountVerticalClass}>
              {currencyFormat(amount)} {CURRENCY_NAMES.TRY}
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={className}>
      <div className="flex gap-x-4 items-center">
        <IconContainer className={iconContainerClass}>
          <IconBank className={iconClass} />
        </IconContainer>
        <span className={nameClasses}>{name}</span>
      </div>
      <span className={amountClasses}>
        {currencyFormat(amount)} {CURRENCY_NAMES.TRY}
      </span>
    </div>
  );
}
