import { useState } from 'react';
import { BottomModal } from 'components';
import CreateForm from './CreateForm';
import Success from './Success';
import { useEffect } from 'react';
import { useRouter } from 'utils/hooks/useRouter';

const PAGES = {
  CREATE: 'CREATE',
  SUCCESS: 'SUCCESS'
};

const CreateExpenseModal = ({ handleClose, defaultInvoice }) => {
  const [page, setPage] = useState(PAGES.CREATE);
  const [createdExpenseId, setCreatedExpenseId] = useState();
  const { query, push, history } = useRouter();
  useEffect(() => {
    return () => {
      if (query?.contactId) {
        history.replace({
          search: ''
        });
        push(`/contact/${query?.contactId}`);
      }
    };
  }, []);

  function success(id) {
    setCreatedExpenseId(id);
    setPage(PAGES.SUCCESS);
  }

  return (
    <BottomModal handleClose={handleClose} className="w-full">
      {page === PAGES.SUCCESS ? (
        <Success handleClose={handleClose} createdExpenseId={createdExpenseId} />
      ) : (
        <CreateForm success={success} defaultInvoice={defaultInvoice} handleClose={handleClose} />
      )}
    </BottomModal>
  );
};

export default CreateExpenseModal;
