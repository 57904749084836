import React from 'react';
import { EmptyView } from 'components';

export default function MoneyTrasferOperationList() {
  return (
    <div>
      <EmptyView>Akıllı İşleminiz Bulunmamaktadır.</EmptyView>
    </div>
  );
}
