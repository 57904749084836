export default {
  GET_CONTACT: 'GET_CONTACT',
  GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
  GET_CONTACT_FAILURE: 'GET_CONTACT_FAILURE',
  GET_CONTACTS: 'GET_CONTACTS',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_FAILURE: 'GET_CONTACTS_FAILURE',
  GET_CONTACT_BALANCE: 'GET_CONTACT_BALANCE',
  GET_CONTACT_BALANCE_SUCCESS: 'GET_CONTACT_BALANCE_SUCCESS',
  GET_CONTACT_BALANCE_FAILURE: 'GET_CONTACT_BALANCE_FAILURE',
  GET_CONTACT_TRANSACTION_DEBT_STATUS: 'GET_CONTACT_TRANSACTION_DEBT_STATUS',
  GET_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS: 'GET_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS',
  GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE: 'GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE',
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS: 'GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS',
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS:
    'GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS',
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE:
    'GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE',
  CREATE_CONTACT: 'CREATE_CONTACT',
  CREATE_CONTACT_SUCCESS: 'CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_FAILURE: 'CREATE_CONTACT_FAILURE',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',
  DELETE_CONTACT: 'DELETE_CONTACT',
  DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
  DELETE_CONTACT_FAILURE: 'DELETE_CONTACT_FAILURE'
};
