import { CustomButton, BottomModal } from 'components';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import ReceiptPdf from 'views/accounts/ReceiptPdf';

const ReceiptModal = ({ onClose, receiptUrl }) => {
  const onDownload = () => {
    const a = document.createElement('a');
    a.href = receiptUrl;
    a.download = receiptUrl.split('/').pop();
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  if (!receiptUrl) return null;
  return (
    <BottomModal handleClose={onClose} className="bg-green-500">
      <div
        className="mx-auto w-96 h-full flex flex-col items-center justify-center"
        data-testid="modal_receipt_account"
      >
        <div className="w-full flex justify-between items-center">
          <span className="text-2xl font-semibold text-white" data-testid="label_receipt_title">
            Dekont
          </span>
          <div
            className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
            onClick={onDownload}
            data-testid="button_download_receipt"
          >
            <ShareIcon className="fill-current text-gray-500 w-4 " />
          </div>
        </div>
        <div
          className="h-auto w-auto overflow-y-scroll rounded-xl overflow-hidden mt-5"
          data-testid="section_receipt_content"
        >
          {receiptUrl?.includes('pdf') ? (
            <ReceiptPdf pdfUrl={receiptUrl} />
          ) : (
            <iframe
              title={receiptUrl}
              src={receiptUrl}
              className="w-full h-full bg-white min-h-[450px] min-w-[450px]"
            />
          )}
        </div>
        <CustomButton
          className="mt-6 w-full"
          color="success"
          onClick={onClose}
          data-testid="button_receipt_close"
        >
          Kapat
        </CustomButton>
      </div>
    </BottomModal>
  );
};

export default ReceiptModal;
