import { useState } from 'react';

const DEFUALT_STEP_INDEX = 0;
export default function useStep(options = { defaultStep: 0 }) {
  const DEFAULT_HISTORY = [options?.defaultStep];
  const [step, setStep] = useState(options?.defaultStep);
  const [historyStepIndex, setHistoryStepIndex] = useState(DEFUALT_STEP_INDEX);
  const [stepHistory, setStepHistory] = useState(DEFAULT_HISTORY);

  const nextStep = () => {
    setStepHistory((currentStepHistory) => [...currentStepHistory, step]);
    setStep((currentStep) => currentStep + 1);
  };
  const previousStep = () => {
    setHistoryStepIndex((index) => index - 1);

    const previousHistoryStep = stepHistory[historyStepIndex - 1];

    if (previousHistoryStep > -1) {
      setStepHistory((prev) => prev.slice(0, -1));
      setStep(previousHistoryStep);
    }
  };

  const addStep = (value) => {
    setHistoryStepIndex((index) => index + 1);
    setStepHistory((currentStepHistory) => [...currentStepHistory, value]);
    setStep(value);
  };

  return {
    step,
    nextStep,
    previousStep,
    setStep: addStep,
    stepHistory,
    DEFUALT_STEP_INDEX
  };
}
