export default {
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',
  GET_TRANSACTION: 'GET_TRANSACTION',
  GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
  GET_TRANSACTION_FAILURE: 'GET_TRANSACTION_FAILURE',
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_FAILURE: 'CREATE_TRANSACTION_FAILURE',
  CONFIRM_TRANSACTION: 'CONFIRM_TRANSACTION',
  CONFIRM_TRANSACTION_SUCCESS: 'CONFIRM_TRANSACTION_SUCCESS',
  CONFIRM_TRANSACTION_FAILURE: 'CONFIRM_TRANSACTION_FAILURE',
  RESET_TRANSACTION_STATE: 'RESET_TRANSACTION_STATE',
  UPDATE_TRANSACTION_CATEGORY: 'UPDATE_TRANSACTION_CATEGORY',
  UPDATE_TRANSACTION_CATEGORY_SUCCESS: 'UPDATE_TRANSACTION_CATEGORY_SUCCESS',
  UPDATE_TRANSACTION_CATEGORY_FAILURE: 'UPDATE_TRANSACTION_CATEGORY_FAILURE',
  UPDATE_TRANSACTION_EXPENSE_TYPE: 'UPDATE_TRANSACTION_EXPENSE_TYPE',
  UPDATE_TRANSACTION_EXPENSE_TYPE_SUCCESS: 'UPDATE_TRANSACTION_EXPENSE_TYPE_SUCCESS',
  UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE: 'UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE',
  UPDATE_TRANSACTION_NOTE: 'UPDATE_TRANSACTION_NOTE',
  UPDATE_TRANSACTION_NOTE_SUCCESS: 'UPDATE_TRANSACTION_NOTE_SUCCESS',
  UPDATE_TRANSACTION_NOTE_FAILURE: 'UPDATE_TRANSACTION_NOTE_FAILURE',
  RESET_CONFIRMED_TRANSACTION: 'RESET_CONFIRMED_TRANSACTION',
  ADD_WALLET_TRANSACTION: 'ADD_WALLET_TRANSACTION',
  ADD_WALLET_TRANSACTION_SUCCESS: 'ADD_WALLET_TRANSACTION_SUCCESS',
  ADD_WALLET_TRANSACTION_FAILURE: 'ADD_WALLET_TRANSACTION_FAILURE',
  GET_TRANSACTION_GROUP_TYPES: 'GET_TRANSACTION_GROUP_TYPES',
  GET_TRANSACTION_GROUP_TYPES_SUCCESS: 'GET_TRANSACTION_GROUP_TYPES_SUCCESS',
  GET_TRANSACTION_GROUP_TYPES_FAILURE: 'GET_TRANSACTION_GROUP_TYPES_FAILURE'
};
