import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTransfersOfCompany } from 'store/actions/transfer';
import { currencyFormat, dateFormat } from 'utils';
import { CURRENCY_NAMES } from '../../enums';
import SelectTransferDropdown from '../selectTransferDropdown';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const PaymentStatusPaidTransaction = ({ errors, watch, setValue }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState(null);
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);

  useEffect(() => {
    dispatch(getTransfersOfCompany({ search }))
      .then((res) => {
        if (search === null) {
          setTransfers(res.data?.data?.content);
        }

        setFilteredTransfers(res.data?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search]);

  return (
    <div className="flex flex-col w-full">
      <span className="text-gray-900 font-semibold mt-7">İşlemler</span>
      <SelectTransferDropdown
        transfers={filteredTransfers}
        selectedItems={watch('transferIds')}
        setSearch={setSearch}
        search={search}
        onSelect={(selectedItems) => setValue('transferIds', selectedItems)}
      />
      <div className="flex flex-col">
        {watch('transferIds').map((transferId) => {
          const transfer = transfers.find((t) => t.id === transferId);
          return (
            <div key={transfer?.id} className="w-full flex justify-between py-4">
              <div className="flex gap-x-2 items-center justify-center">
                <span className="w-10 h-10 rounded-full bg-purple-700 text-purple-300 font-semibold flex items-center justify-center">
                  {transfer?.receiverAccount?.companyName
                    ? transfer?.receiverAccount?.companyName
                        .split(' ')
                        .map((s) => s[0])
                        .slice(0, 2)
                        .join('')
                    : `${transfer?.receiverAccount?.firstName[0]} ${transfer?.receiverAccount?.lastName[0]}`}
                </span>
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-900">
                    {transfer?.receiverAccount?.companyName ||
                      `${transfer?.receiverAccount?.firstName} ${transfer?.receiverAccount?.lastName}`}
                  </span>
                  <span className="text-xs font-medium text-gray-500">
                    {dateFormat(new Date(transfer?.transferDate))}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <span className="font-semibold text-gray-900">
                  {`${currencyFormat(transfer?.amount)} ${CURRENCY_NAMES[transfer?.currency]}`}
                </span>
                <span
                  className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setValue(
                      'transferIds',
                      watch('transferIds').filter((id) => id !== transferId)
                    );
                  }}
                >
                  <TrashIcon className="fill-current w-5 text-gray-500" />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentStatusPaidTransaction;
