import { useState, useEffect } from 'react';

const useCountdown = (m, onDone) => {
  const [minutes, setMinutes] = useState(m);
  const [myTimeout, setMyTimeout] = useState();
  const [isCounting, setIsCounting] = useState();

  useEffect(() => {
    start();
  }, [minutes]);

  function start() {
    setIsCounting(true);
    if (minutes === 0) return stop();

    const t = setTimeout(() => {
      setMinutes(minutes - 1);
    }, 1000);

    setMyTimeout(t);
  }

  function stop() {
    setIsCounting(false);
    clearTimeout(myTimeout);
    return onDone ? onDone() : null;
  }

  function restart() {
    stop();
    setMinutes(m);
  }

  return [minutes, restart, isCounting];
};

export default useCountdown;
