import classNames from 'classnames';
import React from 'react';

export default function StepHeader({
  title,
  subtitle,
  className,
  subtitleClassName,
  titleClassName,
  ...props
}) {
  const classes = classNames('flex flex-col mb-6', className);
  const titleClasses = classNames('text-gray-400 text-sm font-semibold mb-2', titleClassName);
  const subtitleClasses = classNames('text-gray-900 text-xl font-semibold', subtitleClassName);
  return (
    <div className={classes} {...props}>
      <span className={titleClasses}>{title}</span>
      <span className={subtitleClasses}>{subtitle}</span>
    </div>
  );
}
