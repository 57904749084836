import { Button, Card } from 'components';
import React from 'react';
import { SERVICE_PACKAGE_TYPES } from 'utils/contants';

export default function ServiceCard({ data, onBuyOrApplyService, onSeeMore, ...props }) {
  const { description, iconUrl, name, note, purchased } = data;

  return (
    <Card
      className="w-full max-w-md !bg-gray-100 !shadow-none !border !border-gray-200"
      {...props}
    >
      <div>
        <div className="flex justify-center items-center w-12 h-12 bg-gray-200 rounded-xl mb-4">
          <img src={iconUrl} alt={name} className="text-gray-500 stroke-current w-6 h-6" />
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-1">{SERVICE_PACKAGE_TYPES[name]?.value}</h3>
          {note && <p className="text-gray-600 text-sm font-medium leading-5">{note}</p>}
        </div>
        {description && (
          <div>
            <p className="text-gray-600 text-sm font-medium leading-5">{description}</p>
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-4 mt-11">
        <Button color="secondary" className="w-full" onClick={() => onSeeMore(data)}>
          Özellikleri Gör
        </Button>
        <Button className="w-full" onClick={() => onBuyOrApplyService(data)} disabled={purchased}>
          Satın Al
        </Button>
      </div>
    </Card>
  );
}
