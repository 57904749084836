import React from 'react';
import { Button } from 'components';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';

export default function AddTaxButton({ children, onClick }) {
  return (
    <div className="flex items-center space-x-[12px] cursor-pointer" onClick={onClick}>
      <Button
        variant="circle"
        size="sm"
        color="gray"
        icon={<IconPlus className="stroke-current w-4" />}
      />
      <p className="text-sm leading-5 font-medium">{children}</p>
    </div>
  );
}
