import React from 'react';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';

import cn from 'classnames';
import { useDeviceDetection } from 'utils/hooks';

export default function InfoMessage({
  children,
  className,
  iconClassName,
  textClassName,
  closeIconClassName,
  onClose = null,
  ...props
}) {
  const { isIOS, isMobile } = useDeviceDetection();
  const classNames = cn(
    'flex items-center p-4 bg-gray-50 border border-solid border-gray-200 rounded-xl text-left relative',
    {
      'gap-x-3': !isMobile,
      'space-x-3': isIOS
    },
    className
  );

  const iconClassNames = cn('fill-current stroke-0 text-gray-400 w-6 h-6', iconClassName);

  const textClassNames = cn('text-gray-500 text-base font-medium leading-6', textClassName);
  const closeIconClassNames = cn(
    ' stroke-current stroke-0 text-gray-400 w-6 h-6 cursor-pointer absolute right-3 top-2',
    closeIconClassName
  );
  return (
    <div className={classNames} {...props}>
      <span>
        <IconInfoCircle className={iconClassNames} />
      </span>
      <span className={textClassNames}>{children}</span>
      {onClose && <IconClose className={closeIconClassNames} />}
    </div>
  );
}
