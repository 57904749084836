import React, { useState } from 'react';
import { Button, CustomInput, Select, SideModal } from 'components';

//icons
import { useItemContext } from 'contexts/itemContext';
import AmountInput from 'components/AmountInput';
import { CURRENCIES } from '../../enums';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
import { useEffect } from 'react';
import { getItemUnits, getTaxCodes, getWithHolding } from 'store/actions/eTransformation';
import { useDispatch, useSelector } from 'react-redux';
import { filterKeys, PRICE_TYPES, VATS } from 'utils';

import { WithholdingTax, AdditionalTax, Title } from 'views/other/items/components';
import { createItem, getItems } from 'store/actions/items';

export default function AddNewItemModal({ onClose }) {
  const { formValues, setFormValues, resetFormValues, toggleAddNewItemModal, isFreelancer } =
    useItemContext();
  const { itemUnits } = useSelector((state) => state.eTransformation);
  const [isNotValid, setIsNotValid] = useState(true);

  const dispatch = useDispatch();

  const currencies = Object.entries(CURRENCIES).map(([key, value]) => ({
    id: value,
    name: CURRENCY_NAMES[key]
  }));

  const {
    additionalTaxes,
    currency,
    name,
    price,
    priceType,
    unitTypeId,
    vatRate,
    code,
    vatWithholdingCode,
    vatWithholdingRate
  } = formValues;

  useEffect(() => {
    dispatch(getWithHolding());
    dispatch(getItemUnits());
    dispatch(getTaxCodes());
    setFormValues((prevValues) => ({
      ...prevValues,
      vatRate: 18
    }));
    return () => resetFormValues();
  }, []);

  useEffect(() => {
    if (isFreelancer) {
      setIsNotValid(!currency || !name || !price || !priceType || !vatRate);
    } else {
      setIsNotValid(!currency || !name || !price || !unitTypeId || !vatRate);
    }
  }, [additionalTaxes, currency, name, price, priceType, unitTypeId, vatRate]);

  const onChangeItemName = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      name: e.target.value
    }));
  };
  const onChangeVatRate = (vatRate) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      vatRate: Number(vatRate)
    }));
  };
  const onChangeCurrency = (currency) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      currency
    }));
  };
  const onChangePriceTypeChange = (priceType) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      priceType
    }));
  };
  const onChangeUnitType = (unitTypeId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      unitTypeId: Number(unitTypeId)
    }));
  };

  const onChangePrice = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      price: value
    }));
  };

  const onSubmit = () => {
    if (isNotValid) return;
    let payload = {
      additionalTaxes: additionalTaxes.filter((tax) => tax.name && tax.code && tax.percent),
      code,
      currency,
      name,
      price,
      vatRate
    };
    if (vatWithholdingCode && vatWithholdingRate) {
      payload.vatWithholdingCode = vatWithholdingCode;
      payload.vatWithholdingRate = vatWithholdingRate;
    }
    if (isFreelancer) {
      payload.priceType = priceType;
    } else {
      payload.unitTypeId = unitTypeId;
    }
    payload = filterKeys(payload, null, { filterEmpty: true, filterNull: true });
    dispatch(createItem(payload))
      .then((res) => {
        onClose(res?.data?.data);
        dispatch(getItems());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SideModal handleClose={toggleAddNewItemModal}>
      <div className="flex flex-col gap-y-12 pb-4 overflow-y-auto px-4 w-full">
        <p className="text-2xl font-semibold">Ürün Oluştur</p>
        <div className="h-full flex flex-col gap-y-4">
          <div className="flex flex-col space-y-4">
            <Title>Bilgiler</Title>
            <CustomInput label="Ürün İsmi" onChange={onChangeItemName} value={formValues?.name} />
            {isFreelancer ? (
              <Select
                options={PRICE_TYPES}
                value={'id'}
                label="Tutar Türü"
                valueLabel="name"
                onChange={onChangePriceTypeChange}
              />
            ) : (
              <Select
                options={itemUnits}
                value="id"
                valueLabel="name"
                label="Birim Tipi"
                onChange={onChangeUnitType}
              />
            )}
            <div className="flex space-x-4">
              <AmountInput
                className="w-full max-w-[172px]"
                label="Tutar"
                onValueChange={onChangePrice}
                value={formValues.price}
              />
              <Select
                className="w-full"
                options={currencies}
                value={'id'}
                valueLabel="name"
                label="Para Birimi"
                onChange={onChangeCurrency}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <Title>Vergiler</Title>
            <div className="flex space-x-4">
              <CustomInput
                className="w-full max-w-[172px]"
                label="Vergi Adı"
                value="KDV"
                disabled
              />
              <Select
                className="w-full max-w-[172px]"
                options={VATS}
                value={'id'}
                valueLabel="name"
                label="Vergi Oranı"
                onChange={onChangeVatRate}
                defaultValue={formValues.vatRate}
              />
            </div>
            <AdditionalTax />
            <WithholdingTax />
          </div>
        </div>
        <div className="flex flex-col w-full gap-y-4 mb-4">
          <Button type="button" className="w-full" onClick={onSubmit} disabled={isNotValid}>
            Oluştur
          </Button>
        </div>
      </div>
    </SideModal>
  );
}
