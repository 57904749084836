import { CustomButton, Popup } from 'components';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const DeleteConfirmPopup = ({ active, toggle, handleDelete }) => (
  <Popup active={active} style={{ height: 'auto' }}>
    <div className="w-full h-full flex flex-col items-center text-center">
      <span className="w-20 h-20 rounded-full bg-red-100 flex items-center justify-center">
        <TrashIcon className="fill-current text-red-500 w-10" />
      </span>
      <span className="font-semibold text-gray-900 text-2xl mt-6">Kategori Sil</span>
      <CustomButton
        color="danger"
        className="w-full mt-6"
        onClick={handleDelete}
        data-testid="button_delete_category"
      >
        Sil
      </CustomButton>
      <CustomButton
        color="success"
        className="w-full mt-4"
        onClick={toggle}
        data-testid="button_close_delete_confirmation_popup"
      >
        İptal Et
      </CustomButton>
    </div>
  </Popup>
);

export default DeleteConfirmPopup;
