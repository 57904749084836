export default {
  GET_AUTOMATIC_TRANSACTIONS: ' GET_AUTOMATIC_TRANSACTIONS',
  GET_AUTOMATIC_TRANSACTIONS_SUCCESS: ' GET_AUTOMATIC_TRANSACTIONS_SUCCESS',
  GET_AUTOMATIC_TRANSACTIONS_FAILURE: ' GET_AUTOMATIC_TRANSACTIONS_FAILURE',
  GET_AUTOMATIC_TRANSACTION: ' GET_AUTOMATIC_TRANSACTION',
  GET_AUTOMATIC_TRANSACTION_SUCCESS: ' GET_AUTOMATIC_TRANSACTION_SUCCESS',
  GET_AUTOMATIC_TRANSACTION_FAILURE: ' GET_AUTOMATIC_TRANSACTION_FAILURE',
  CREATE_AUTOMATIC_TRANSACTION: ' CREATE_AUTOMATIC_TRANSACTION',
  CREATE_AUTOMATIC_TRANSACTION_SUCCESS: ' CREATE_AUTOMATIC_TRANSACTION_SUCCESS',
  CREATE_AUTOMATIC_TRANSACTION_FAILURE: ' CREATE_AUTOMATIC_TRANSACTION_FAILURE',
  UPDATE_AUTOMATIC_TRANSACTION: ' UPDATE_AUTOMATIC_TRANSACTION',
  UPDATE_AUTOMATIC_TRANSACTION_SUCCESS: ' UPDATE_AUTOMATIC_TRANSACTION_SUCCESS',
  UPDATE_AUTOMATIC_TRANSACTION_FAILURE: ' UPDATE_AUTOMATIC_TRANSACTION_FAILURE',
  DELETE_AUTOMATIC_TRANSACTION: ' DELETE_AUTOMATIC_TRANSACTION',
  DELETE_AUTOMATIC_TRANSACTION_SUCCESS: ' DELETE_AUTOMATIC_TRANSACTION_SUCCESS',
  DELETE_AUTOMATIC_TRANSACTION_FAILURE: ' DELETE_AUTOMATIC_TRANSACTION_FAILURE',
  RESET_AUTOMATIC_TRANSACTION_DETAIL: 'RESET_AUTOMATIC_TRANSACTION_DETAIL',
  RESET_CREATED_AUTOMATIC_TRANSACTION: 'RESET_CREATED_AUTOMATIC_TRANSACTION'
};
