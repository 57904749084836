import React from 'react';
import Lottie from 'react-lottie';
import animationData from 'assets/norma-loading.json';
export default function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      height={400}
      width={400}
      isClickToPauseDisabled={true}
      style={{
        pointerEvents: 'none',
        cursor: 'none'
      }}
    />
  );
}
