import { BottomModal, CodeInput, CustomButton, CustomInput, PhoneInput } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserData,
  updateUserProfilePhoto,
  update_email,
  update_email_otp_token
} from 'store/actions/auth';
import { emailRegex, OTP_TIMEOUT, timeFormat } from 'utils';
import errorCodes from 'utils/errorCodes';
import useCountdown from 'utils/hooks/useCountdown';
import useKeyPress from 'utils/hooks/useKeyPress';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';

import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg';

import DoubleIconCheck from 'assets/illustrations/card/double-check.png';
import toast from 'react-hot-toast';
import useModal from 'utils/hooks/useModal';
import TabSubTitle from '../tabSubTitle';

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const fileRef = useRef(null);
  const { publishEvent, eventTypes } = useDengage();

  const [isChangeMailModalActive, , toggleChangeMailModal] = useModal({
    shouldBeBlockScroll: true
  });

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_and_settings'
    });
  }, []);

  function dateFormat(_date) {
    if (!_date) return '';
    const date = new Date(_date);
    return `${date.getDate().toString().padStart(2, '0')}.${date
      .getMonth()
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`;
  }

  const onClickSelectPhoto = () => fileRef.current.click();

  const onImageSelected = (e) => {
    const file = e.target.files[0];
    if (!file) return toast.error('Resim seçilirken bir hata oluştu!');
    const formData = new FormData();
    formData.append('file', file);
    updateUserProfilePhoto({ userId: user.userId, file: formData })
      .then(() => dispatch(getUserData()))
      .catch(() => toast.error('Resim yüklenirken bir hata oluştu!'));
  };

  return (
    <div className="flex flex-col">
      {isChangeMailModalActive && <ChangeMailModal handleClose={toggleChangeMailModal} />}
      {user?.profilePhotoUrl ? (
        <div className="relative w-24 mb-12">
          <img
            src={user?.profilePhotoUrl}
            alt="profile"
            className="w-24 h-24 bg-gray-500 rounded-full border border-gray-200 object-cover"
          />
          <div
            onClick={onClickSelectPhoto}
            className=" w-10 h-10 rounded-full bg-white absolute right-0 bottom-0 flex items-center justify-center shadow-norma cursor-pointer"
          >
            <input
              ref={fileRef}
              type="file"
              className="hidden"
              accept="image/png, image/jpeg"
              onChange={onImageSelected}
            />
            <IconCamera width={24} height={24} className="fill-current text-gray-500" />
          </div>
        </div>
      ) : (
        <div
          onClick={onClickSelectPhoto}
          className="w-24 h-24 bg-gray-300 rounded-full mb-12 flex items-center justify-center cursor-pointer"
        >
          <input
            ref={fileRef}
            type="file"
            className="hidden"
            accept="image/png, image/jpeg"
            onChange={onImageSelected}
          />
          <IconCamera width={24} height={24} className="fill-current text-gray-500" />
        </div>
      )}
      <div className="flex space-x-8">
        <div className="w-1/2 flex flex-col gap-y-4">
          <TabSubTitle className="!m-0">Kişisel Bilgileriniz</TabSubTitle>
          <CustomInput label="İsim" disabled value={user?.firstName} />
          <CustomInput label="Soyisim" disabled value={user?.lastName} />
          <CustomInput label="TC Kimlik Numarası" disabled value={user?.identityNumber} />
          <CustomInput
            label="Doğum Tarihi (GG/AA/YYYY)"
            disabled
            value={dateFormat(user?.birthDate)}
          />
        </div>
        <div className="w-1/2 flex flex-col gap-y-4">
          <TabSubTitle className="!m-0">İletişim Bilgileriniz</TabSubTitle>
          <div className="flex flex-col justify-center gap-y-1">
            <PhoneInput defaultValue={user?.fullPhoneNumber} disabled />
            <div className="flex font-medium text-xs leading-4 text-gray-500 mt-4">
              <IconInfoCircle width={20} height={20} className="fill-current mr-2" />
              <p>
                Telefon numaranı değiştirmek istiyorsan destek ekibimize{' '}
                <span className="text-green-500">0 (850) 211 73 73</span> numarası üzerinden
                ulaşabilirsin.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-y-1">
            <label className="text-sm text-gray-500">E-Posta</label>
            <div className="flex justify-between bg-gray-100 rounded-lg py-3 px-2">
              <span>{user?.email}</span>
              <IconEdit
                className="fill-current w-5 text-gray-500 cursor-pointer"
                onClick={toggleChangeMailModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChangeMailModal = ({ handleClose }) => {
  const defaultValues = {
    mail: '',
    otpToken: '',
    mfaToken: ''
  };

  const [index, setIndex] = useState(0);
  function nextStep() {
    setIndex(index + 1);
  }

  const {
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues });

  function handleMail() {
    return update_email({ email: watch('mail') }).then((res) => {
      setValue('mfaToken', res?.data?.data?.mfa_token);
    });
  }

  function handleOtp() {
    return update_email_otp_token({
      otpToken: watch('otpToken'),
      mfaToken: watch('mfaToken'),
      email: watch('mail')
    });
  }

  const STEPS = [
    <CMStep0
      key={'CMStep0'}
      watch={watch}
      setValue={setValue}
      handleMail={handleMail}
      nextStep={nextStep}
      errors={errors}
    />,
    <CMStep1
      key={'CMStep1'}
      watch={watch}
      handleOtp={handleOtp}
      handleMail={handleMail}
      setValue={setValue}
      nextStep={nextStep}
      setError={setError}
      errors={errors}
      clearErrors={clearErrors}
    />,
    <CMStep2 key={'CMStep2'} handleClose={handleClose} />
  ];

  return (
    <BottomModal handleClose={handleClose} className={`${index === 2 && 'bg-green-500'}`}>
      <div className="flex w-full h-full justify-center items-center">
        <div className="flex w-[400px] h-auto justify-center items-center">{STEPS[index]}</div>
      </div>
    </BottomModal>
  );
};
const CMStep0 = ({ handleMail, watch, setValue, nextStep }) => {
  const [hasError, setHasError] = useState(null);

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    _handleMail();
  });

  async function _handleMail() {
    await handleMail()
      .then(() => {
        setHasError(null);
        nextStep();
      })
      .catch((err) => {
        setHasError(err);
      });
  }

  const isCorrect = emailRegex.test(watch('mail'));

  return (
    <div className="flex flex-col w-full">
      <span className="font-semibold text-sm text-gray-400">E-posta Adresi Değiştir</span>
      <span className="text-gray-900 text-2xl font-semibold">Yeni e-posta adresinizi girin</span>
      <CustomInput
        label="Email"
        className="mt-10"
        onChange={(e) => setValue('mail', e.target.value)}
        autoFocus
        error={
          hasError &&
          'Bu e-posta adresi sistemde kayıtlı. Lütfen farklı bir e-posta adresi yazın.'
        }
      />
      <CustomButton color="success" className="mt-6" onClick={_handleMail} disabled={!isCorrect}>
        Devam
      </CustomButton>
    </div>
  );
};

const CMStep1 = ({
  watch,
  handleOtp,
  handleMail,
  setValue,
  nextStep,
  setError,
  errors,
  clearErrors
}) => {
  const dispatch = useDispatch();
  const { sendGTMEvent } = useGTM();
  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    _handleOtp();
  });

  const [timeLeft, restartCounting, isCounting] = useCountdown(OTP_TIMEOUT);

  async function handleResendMail() {
    await handleMail();
    restartCounting();
  }

  function _handleOtp() {
    handleOtp()
      .then(() => {
        nextStep();
        dispatch(getUserData());
      })
      .catch((err) => {
        if (Object.keys(err).includes(errorCodes.invalidOtpToken.toString())) {
          setError('otpToken', {
            type: 'invalid',
            message: err[errorCodes.invalidOtpToken]
          });
        }
      });

    sendGTMEvent({
      event: 'catEvent',
      eventName: 'email_verification'
    });
  }

  useEffect(() => {
    clearErrors('otpToken');
  }, [watch('otpToken')]);

  const isCorrect = !!watch('otpToken');

  return (
    <div className="flex flex-col w-full">
      <span className="font-semibold text-sm text-gray-400">E-Posta Adresi Değiştir</span>
      <span className="text-gray-900 text-2xl font-semibold">
        {watch('mail')} adresinize gelen 4 haneli kodu girin
      </span>
      <CodeInput
        length={4}
        className="mt-16"
        onChange={(e) => setValue('otpToken', e)}
        autofocus
        error={errors?.otpToken}
      />
      <div className="flex flex-col mt-6">
        <span>Kodunuz ulaşmadı mı?</span>
        {isCounting ? (
          <span className="text-green-500 select-none cursor-default">
            {timeFormat(timeLeft)}
          </span>
        ) : (
          <span className="text-green-500 select-none cursor-pointer" onClick={handleResendMail}>
            Tekrar Gönder.
          </span>
        )}
      </div>
      <CustomButton color="success" onClick={_handleOtp} disabled={!isCorrect} className="mt-6">
        Devam
      </CustomButton>
    </div>
  );
};

const CMStep2 = ({ handleClose }) => {
  useKeyPress((e) => {
    if (e.key !== 'Enter') return;

    handleClose();
  });

  return (
    <div className="flex flex-col w-full items-center">
      <img src={DoubleIconCheck} alt="double-check" width="100" />
      <span className="text-white text-3xl font-medium text-center mt-6">
        E-Posta adresiniz başarıyla değiştirildi
      </span>
      <CustomButton color="success" className="mt-8 w-full" onClick={handleClose}>
        Tamam
      </CustomButton>
    </div>
  );
};

export default PersonalInfo;
