import React from 'react';
import { Button, Card } from 'components';
import { Popover } from '@headlessui/react';

import { ReactComponent as IconArrowDown } from 'assets/icons/caret-down.svg';
import classNames from 'classnames';

export default function ExportButton({
  size = 'md',
  color = 'primary',
  variant = 'default',
  leftIcon = null,
  rightIcon = null,
  icon = null,
  isLoading = false,
  disabled = false,
  onChange = () => {},

  ...props
}) {
  const exportOptions = [
    {
      label: 'Excel',
      value: 'XLSX'
    },
    {
      label: 'CSV',
      value: 'CSV'
    }
  ];

  const panelClassnames = classNames('absolute z-50 w-full mt-2');

  const onSelected = (_, selectedItem) => {
    onChange(selectedItem?.value);
  };

  return (
    <div>
      <Popover className="relative">
        <Popover.Button>
          {({ open }) => (
            <Button
              size={size}
              color={color}
              variant={variant}
              leftIcon={leftIcon}
              rightIcon={
                rightIcon || (
                  <IconArrowDown
                    className={classNames({
                      ['rotate-180']: open
                    })}
                  />
                )
              }
              icon={icon}
              isLoading={isLoading}
              disabled={disabled}
              {...props}
            >
              Dışarı Aktar
            </Button>
          )}
        </Popover.Button>
        <Popover.Panel as="div" className={panelClassnames}>
          {({ close }) => (
            <Card className="!p-3">
              {exportOptions.map((item, index) => (
                <Item
                  key={index + item.value}
                  isLast={exportOptions.length - 1 === index}
                  item={item}
                  onClick={(e, item) => {
                    onSelected(e, item);
                    close();
                  }}
                />
              ))}
            </Card>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
}

const Item = ({ item, onClick, isLast, ...props }) => {
  const itemClassName = classNames('cursor-pointer py-4 px-3 text-sm font-medium leading-5', {
    'border-solid border-b border-gray-100': !isLast
  });

  return (
    <div className={itemClassName} onClick={(e) => onClick(e, item)} {...props}>
      {item.label}
    </div>
  );
};
