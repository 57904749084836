export const MONTHS = [
  'Oca',
  'Şub',
  'Mar',
  'Nis',
  'May',
  'Haz',
  'Tem',
  'Ağu',
  'Eyl',
  'Eki',
  'Kas',
  'Ara'
];

export const TRANSACTION_DIRECTION = {
  IN: {
    key: 'IN',
    value: 'Gelen Ödeme'
  },
  OUT: {
    key: 'OUT',
    value: 'Giden Ödeme'
  }
};

export const EXPENSE_TYPE_TEXTS = {
  PERSONAL: 'Bireysel',
  CORPORATE: 'Şirket'
};

export const TYPE_TEXTS = {
  TRANSFER_FEE: 'Kendi Hesabına',
  PEER_TRANSFER: 'Norma Numarası',
  ACCOUNT_TRANSFER: 'EFT',
  DEPOSIT: 'Deposit'
};

export const SERVICE_PACKAGE_TYPES = {
  E_TRANSFORMATION: { key: 'E_TRANSFORMATION', value: 'e-Dönüşüm' },
  E_SIGNATURE: { key: 'E_SIGNATURE', value: 'e-İmza' },
  VIRTUAL_OFFICE: { key: 'VIRTUAL_OFFICE', value: 'Sanal Ofis' },
  FINANCIAL_CONSULTANCY: { key: 'FINANCIAL_CONSULTANCY', value: 'Finans Danışmanlığı' },
  NORMA_POS: { key: 'NORMA_POS', value: 'NORMA POS' },
  PRIVATE_HEALTH_INSURANCE: { key: 'PRIVATE_HEALTH_INSURANCE', value: 'Özel Sağlık Sigortası' },
  COMPLEMENTARY_HEALTH_INSURANCE: {
    key: 'COMPLEMENTARY_HEALTH_INSURANCE',
    value: 'Tamamlayıcı Sağlık Sigortası'
  },
  TRAVEL_HEALTH_INSURANCE: {
    key: 'TRAVEL_HEALTH_INSURANCE',
    value: 'Seyahat Sağlık Sigortası'
  },
  COMPULSORY_CAR_INSURANCE: {
    key: 'COMPULSORY_CAR_INSURANCE',
    value: 'Zorunlu Trafik Sigortası'
  },
  CAR_INSURANCE: { key: 'CAR_INSURANCE', value: 'KASKO' },
  DISASTER_INSURANCE: { key: 'DISASTER_INSURANCE', value: 'DASK' },
  MEAL_CHECK: { key: 'MEAL_CHECK', value: 'Yemek Kartı' }
};

export const currencyAmountCalculator = (
  amount,
  options = { currencyRate: 0, currency: 0, revertCurrencyRate: false }
) => {
  const { currencyRate, currency, revertCurrencyRate } = options;

  let calculatedAmount = amount;

  if (revertCurrencyRate)
    calculatedAmount = currency === 'TRY' ? amount : Math.round(amount / currencyRate);
  else {
    calculatedAmount = Math.round(amount * currencyRate * 100) / 100;
  }

  return calculatedAmount;
};

export const COLLECTION_WARNING_POPUP_ID = 'isCollectionModalPopupVisibilityAvailable';

export const ETRANSFORMATION_SELECTION_TYPES = {
  APPLY_ETRANSFORMATION: 0,
  WITH_YOUR_PROVIDER: 1,
  WITH_ANOTHER_PROVIDER: 2
};

export const SUBSCRIPTION_TYPES = {
  YEARLY: 'Yıllık',
  MONTHLY: 'Aylık'
};

export const PAYMENT_TRANSACTION_STATUS = {
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAIL',
  REFUND: 'REFUND'
};

export const WALLET_TYPES = {
  BANK: { label: 'Banka Hesabı', value: 'BANK' },
  CASH: { label: 'Nakit Hesabı', value: 'CASH' },
  EMONEY: { label: 'Norma Hesabı', value: 'EMONEY' },
  OPEN_BANKING: { label: 'Entegre Banka Hesabı', value: 'OPEN_BANKING' }
};
