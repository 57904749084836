import React, { useEffect } from 'react';
import { useOpenBakingContext } from '../..';
import DoubleCheck from 'assets/illustrations/card/double-check.png';
import { Button } from 'components';
import { useDispatch } from 'react-redux';
import { getWallets } from 'store/actions/accounts';
import { useWalletContext } from 'contexts/walletContext';
import { useSelector } from 'react-redux';
import { getWalletTypes } from 'store/actions/wallet';
import { getOpenBankingBanks } from 'store/actions/openBanking';

export default function Succeded() {
  const { onClose, formValues } = useOpenBakingContext();
  const { selectedWallet, selectedWalletType } = useWalletContext();
  const { addBank } = useSelector((state) => state.openBanking);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getWallets({
        status: 'ACTIVE',
        selectedWalletType,
        openBankingId:
          selectedWallet?.openBankingId ||
          formValues?.bank?.openBankingId ||
          addBank?.openBankingId
      })
    );
    dispatch(getWalletTypes());
    dispatch(getOpenBankingBanks());
  }, {});

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-6">
      <img src={DoubleCheck} alt="double-check" width="100" />
      <p className=" text-3xl text-white text-center font-medium leading-8">
        İşleminiz başarıyla gerçekleşmiştir
      </p>
      <Button className="w-[400px] mt-8" onClick={onClose}>
        Kapat
      </Button>
    </div>
  );
}
