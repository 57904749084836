import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useModal from 'utils/hooks/useModal';
import { Popover, RadioGroup } from '@headlessui/react';

import {
  AddCategoryModal,
  BottomModal,
  CategoryBadge,
  Checkbox,
  CustomButton,
  CustomInput,
  DatePicker,
  DragAndDropFileUploader,
  OptionalArea
} from 'components';
import AmountInput from 'components/AmountInput';
import AddContactModal from 'views/other/contacts/components/addContactModal';
import ConfirmDeletePopup from './ConfirmDeletePopup';

import { getCategories } from 'store/actions/categories';
import { getRates } from 'store/actions/exchange';
import { getDocumentById } from 'store/actions/eTransformation';
import { getContacts } from 'store/actions/contacts';
import { getDocumentSummaryById } from 'store/actions/eTransformation';

import {
  COMPANY_TYPES,
  currencyFormat,
  dateFormat,
  getFormattedDateForPayload,
  MONTHS,
  PAYMENT_STATUS
} from 'utils';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as TransactionListIcon } from 'assets/illustrations/accounts/transaction-list.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { uploadFile } from 'store/actions/fileUpload';
import FILE_TYPES from 'utils/fileTypes';
import { updateSaleExpense } from 'store/actions/invoice';
import SaleCanNotDeletePopup from '../SaleCanNotDeletePopup';
import { PAYMENT_METHODS } from 'views/invoicing/enums';
import SelectTransferDropdown from '../selectTransferDropdown';
import { getTransfersOfCompany } from 'store/actions/transfer';
import { CURRENCY_NAMES } from '../salesRecordDetailModal/enums';
import ItemsTable from './ItemsTable';
import ExpenseRecordTaxes from './ExpenseRecordTaxes';
import CreateContactModal from 'views/other/contacts/components/createContactModal';

const CURRENCIES = {
  TRY: {
    show: 'TL',
    symbol: '₺'
  },
  EUR: {
    show: 'Euro',
    symbol: '€'
  },
  USD: {
    show: 'USD',
    symbol: '$'
  }
};

const ExpenseRecordDescription = ({ setValue, watch, errors, clearErrors, document }) => {
  const date = document?.issueDate ? new Date(document?.issueDate) : null;
  return (
    <div className="py-12 border-b border-solid border-gray-200">
      <span className="text-lg font-semibold text-gray-900">Fatura Açıklaması Ekle</span>
      <CustomInput
        label="Kayıt İsmi"
        className="mt-5"
        onChange={(e) => {
          setValue('title', e.target.value);
          clearErrors('title');
        }}
        maxLength={140}
        count={watch('title') ? watch('title').length : 0}
        error={errors?.title?.message}
        value={watch('title')}
      />
      {document && (
        <div className="w-full flex justify-between mt-12">
          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-500">Unvan</span>
            <span className="text-lg font-semibold text-gray-900">{document?.senderTitle}</span>
            <span className="text-sm font-medium text-gray-500">{document?.senderTaxId}</span>
          </div>
          <div className="flex flex-col text-right">
            <span className="text-sm font-medium text-gray-500">{`${date?.getDate()} ${
              MONTHS[date?.getMonth()]
            } ${date?.getFullYear()}`}</span>
            <span className="text-lg font-semibold text-gray-700">
              <span>Fatura No: </span>
              <span>{document?.invoiceId}</span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const UploadInvoiceInput = ({ setFile }) => {
  const fileInput = useRef();

  function handleChange(e) {
    const file = e.target.files[0];

    setFile(file);
  }

  return (
    <>
      <div
        className="w-full border border-dashed border-gray-300 bg-gray-100 flex flex-col items-center py-8 rounded-xl cursor-pointer mt-4"
        onClick={() => fileInput.current.click()}
      >
        <FileUploadIcon className="fill-current w-8 text-gray-400" />
        <span className="text-lg font-semibold text-gray-500 mt-3">
          Fiş / Pos Slibi eklemek için tıklayın
        </span>
        <span className="text-sm font-semibold text-gray-400 mt-2">
          veya sürükleyip buraya bırakın
        </span>
        <input type="file" ref={fileInput} className="hidden" onChange={handleChange} />
      </div>
      <div className="flex gap-x-2 items-center mt-4">
        <InfoCircleIcon className="fill-current w-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-500">
          Dosya tipi olarak .jpg, .jpeg, .png ve .pdf desteklenir. Maksimum boyut 15 mb
        </span>
      </div>
    </>
  );
};

const PreviewFile = ({ file, setFile, setValue }) => {
  function handleResetInput() {
    setFile(null);
    setValue('invoiceFileIds', []);
  }

  if (file instanceof File) {
    return (
      <div className="mb-4 w-full border-2 border-dashed border-gray-300 rounded-lg py-4 px-5 flex items-center gap-x-6 bg-white">
        <img src={URL.createObjectURL(file)} alt="" className="h-32 rounded-lg" />
        <div className="flex flex-col gap-y-2 justify-center items-start">
          <span className="text-lg font-semibold text-gray-600">{file.name}</span>
          <CustomButton color="danger" onClick={handleResetInput}>
            Sil
          </CustomButton>
        </div>
      </div>
    );
  }

  if (file?.url) {
    return (
      <div className="mb-4 w-full border-2 border-dashed border-gray-300 rounded-lg py-4 px-5 flex items-center gap-x-6 bg-white">
        <img src={file?.url} alt="" className="h-32 rounded-lg" />
        <div className="flex flex-col gap-y-2 justify-center items-start">
          <span className="text-lg font-semibold text-gray-600">{file.name}</span>
          <CustomButton color="danger" onClick={handleResetInput}>
            Sil
          </CustomButton>
        </div>
      </div>
    );
  }

  return null;
};

const ExpenseInvoice = ({
  watch,
  setValue,
  clearErrors,
  errors,
  setFile,
  file,
  isCreatedFromInvoice = false
}) => (
  <div className="py-12 border-b border-solid border-gray-200">
    {!isCreatedFromInvoice && (
      <div>
        <span className="text-lg font-semibold text-gray-900">
          Fatura/Fiş fotoğrafı <OptionalArea />
        </span>
        {!!file ? (
          <PreviewFile setFile={setFile} file={file} setValue={setValue} />
        ) : (
          <div>
            <DragAndDropFileUploader
              onSelected={(selectedFile) => selectedFile && setFile(selectedFile[0])}
            />
          </div>
        )}
      </div>
    )}

    <div className="mt-6">
      <span className="text-sm font-medium text-gray-500">Fatura Tarihi</span>
      <div className="w-full bg-gray-100 px-3 rounded-lg flex items-center">
        <DatePicker
          className="bg-transparent py-3 w-full focus:outline-none text-black"
          selected={watch('invoiceDate')}
          onChange={(e) => {
            setValue('invoiceDate', e);
            clearErrors('invoiceDate');
          }}
          disabled={isCreatedFromInvoice}
        />
      </div>
      {errors?.paymentStatus && (
        <span className="text-red-500 text-xs mt-1">{errors?.paymentStatus?.message}</span>
      )}
    </div>
    <div className="mt-4">
      <span className="text-sm font-medium text-gray-500">
        Fatura Numarası <OptionalArea />
      </span>
      <CustomInput
        maxLength={40}
        onChange={(e) => setValue('invoiceNumber', e.target.value)}
        value={watch('invoiceNumber')}
        disabled={isCreatedFromInvoice}
      />
    </div>
  </div>
);

const SearchInput = ({ onChange, className, value, onClick, error }) => (
  <>
    <div
      className={`${className} bg-gray-100 py-3 px-3 flex items-center text-gray-400 rounded-lg focus-within:bg-white focus-within:shadow-lg`}
    >
      <IconSearch className="fill-current w-4 mr-4" />
      <input
        onChange={onChange}
        placeholder="Arama"
        className="w-full appearance-none focus:outline-none text-black bg-transparent"
        value={value}
        onClick={onClick}
      />
    </div>
    {error && <span className="text-xs text-red-500">{error}</span>}
  </>
);

const NoContact = () => (
  <div className="flex flex-col items-center justify-center bg-white py-10 border border-solid border-gray-100 rounded-lg mt-4 gap-y-5">
    <TransactionListIcon className="w-20 h-auto" />
    <div className="py-2 px-4 rounded-full flex gap-x-2 items-center bg-gray-100 border border-solid border-gray-200 w-auto">
      <InfoCircleIcon className="fill-current w-4 text-gray-500" />
      <span className="text-sm text-gray-500 font-medium">
        Henüz Müşteri/Tedarikçi eklenmemiş
      </span>
    </div>
  </div>
);

const ExpenseContact = ({
  search,
  setSearch,
  watch,
  setValue,
  toggleAddContactModal,
  errors,
  clearErrors
}) => {
  const { contacts, filteredContacts } = useSelector((state) => state.contacts);

  function handleSelectContact(c, close) {
    setValue('contactId', c.id);
    close();
  }

  const selectedContact = contacts.find((c) => c.id === watch('contactId'));
  const nameLetters =
    selectedContact?.companyType === COMPANY_TYPES.BUSINESS
      ? selectedContact?.companyName[0]
      : selectedContact?.firstName && selectedContact?.lastName
      ? selectedContact?.firstName[0] + selectedContact?.lastName[0]
      : '-';
  const fullName =
    selectedContact?.companyType === COMPANY_TYPES.BUSINESS
      ? selectedContact?.companyName
      : selectedContact?.firstName && selectedContact?.lastName
      ? selectedContact?.firstName + ' ' + selectedContact?.lastName
      : '-';

  useEffect(() => {
    clearErrors('contactId');
  }, [watch('contactId')]);

  return (
    <div className="border-b border-solid border-gray-100 py-12">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg text-gray-900">
          Müşteri/Tedarikçi <OptionalArea />{' '}
        </span>
        <span
          className="text-green-500 text-sm flex items-center font-medium cursor-pointer"
          onClick={toggleAddContactModal}
        >
          <IconPlus className="stroke-current w-4 mr-2" />
          <span>Yeni Ekle</span>
        </span>
      </div>
      {!contacts.length ? (
        <NoContact />
      ) : (
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                as="div"
                className="bg-white rounded-xl border border-solid border-gray-200 px-4 py-6 mt-4 cursor-pointer"
              >
                {watch('contactId') ? (
                  <div className="flex items-center">
                    <div className="w-10 h-10 mr-4 bg-purple-700 text-white rounded-full flex items-center justify-center">
                      {nameLetters.toLocaleUpperCase()}
                    </div>
                    <span className="text-gray-900">{fullName}</span>
                    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <span className="font-semibold text-gray-900">Müşteri/Tedarikçi Seç</span>
                    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
                  </div>
                )}
              </Popover.Button>
              {errors?.contactId && (
                <span className="text-red-500 text-xs">{errors?.contactId?.message}</span>
              )}
              <Popover.Panel
                as="div"
                className="bg-white rounded-xl border border-solid border-gray-200 p-4 mt-4 h-80 overflow-y-scroll absolute w-full z-50"
              >
                <SearchInput onChange={(e) => setSearch(e.target.value)} value={search} />
                <div className="mt-2 flex flex-col gap-y-2">
                  {filteredContacts.map((contact, i) => (
                    <div
                      key={i}
                      className="flex items-center cursor-pointer"
                      onClick={() => handleSelectContact(contact, close)}
                    >
                      <div className="flex items-center justify-center h-11 w-11 rounded-full bg-purple-700 text-white">
                        <span>
                          {contact?.companyType === COMPANY_TYPES.BUSINESS
                            ? contact?.companyName[0]?.toLocaleUpperCase()
                            : `${
                                contact?.firstName
                                  ? contact?.firstName[0]?.toLocaleUpperCase()
                                  : ''
                              }${
                                contact?.lastName ? contact?.lastName[0]?.toLocaleUpperCase() : ''
                              }`}
                        </span>
                      </div>
                      <span className="text-gray-900 ml-4">
                        {contact?.companyType === COMPANY_TYPES.BUSINESS
                          ? contact?.companyName
                          : `${contact.firstName} ${contact.lastName}`}
                      </span>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </>
          )}
        </Popover>
      )}
    </div>
  );
};

const ExpenseRecordCategory = ({ setValue, watch, toggleAddCategoryModal }) => {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categories);
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <div className="py-12 border-b border-solid border-gray-200">
      <div className="flex justify-between">
        <span className="text-lg font-semibold text-gray-900">
          Kategori <OptionalArea />
        </span>
        <span
          className="text-sm font-medium text-green-500 cursor-pointer flex items-center gap-x-2"
          onClick={toggleAddCategoryModal}
        >
          <IconPlus className="stroke-current w-4" />
          Yeni Ekle
        </span>
      </div>
      <div className="flex flex-wrap gap-4 mt-5">
        {categories.map((c, i) => (
          <CategoryBadge
            key={i}
            name={c?.name}
            colorHexCode={c?.colorHexCode}
            textColorHexCode={c?.textColorHexCode}
            iconUrl={c?.iconUrl}
            selected={c?.id === watch('categoryId')}
            onClick={() => setValue('categoryId', c?.id)}
          />
        ))}
      </div>
    </div>
  );
};

const ExpenseRecordCurrency = ({
  setValue,
  watch,
  errors,
  clearErrors,
  isCreatedFromInvoice = false
}) => {
  const dispatch = useDispatch();

  const { TRY } = useSelector((state) => state.exchange);

  const currencyRate = TRY.find((e) => e.name === watch('currency'));
  const [autoCurrencyRate, setAutoCurrencyRate] = useState(
    watch('currencyRate') === currencyRate?.averageRate || false
  );

  useEffect(() => {
    dispatch(getRates({ currency: 'TRY' }));
  }, [dispatch]);

  function handleCurrency(currency) {
    clearErrors('currency');
    clearErrors('currencyRate');
    setValue('currency', currency);
  }

  useEffect(() => {
    if (watch('currency') === 'TRY') return setValue('currencyRate', 1);

    if (autoCurrencyRate) {
      setValue('currencyRate', currencyRate.averageRate);
    }
  }, [autoCurrencyRate, watch('currency')]);

  return (
    <div className="py-12 border-b border-solid border-gray-200">
      <span
        className={`${
          errors?.currency || errors?.currencyRate ? 'text-red-500' : 'text-gray-700'
        } text-lg font-semibold`}
      >
        Para Birimi
      </span>
      <div className="flex gap-x-2 w-full mt-4">
        {Object.keys(CURRENCIES).map((currency, i) => (
          <span
            key={i}
            className={`${
              watch('currency') === currency
                ? 'bg-green-500 text-green-50'
                : 'bg-gray-200 text-gray-500'
            } w-1/3 flex items-center justify-center py-2 rounded-3xl cursor-pointer select-none ${
              isCreatedFromInvoice ? 'pointer-events-none' : ''
            }`}
            onClick={() => {
              if (isCreatedFromInvoice) return;
              setAutoCurrencyRate(true);
              handleCurrency(currency);
            }}
          >
            {`${CURRENCIES[currency].symbol} ${CURRENCIES[currency].show}`}
          </span>
        ))}
      </div>
      {!!currencyRate && (
        <div>
          <Checkbox
            className={`mt-6 ${isCreatedFromInvoice ? 'pointer-events-none' : ''}`}
            onChange={(e) => setAutoCurrencyRate(e.target.checked)}
            checked={autoCurrencyRate}
            disabled={isCreatedFromInvoice}
          >
            <span className="text-sm text-gray-700">
              1 {CURRENCIES[currencyRate.name].symbol} fiyatı Merkez Bankası Efektif satış
              tarafından hesaplanmıştır.
              <span className="text-green-500">
                1 {CURRENCIES[currencyRate.name].symbol} = {currencyRate.averageRate} ₺
              </span>
            </span>
          </Checkbox>
          {!autoCurrencyRate && (
            <AmountInput
              label="Kur"
              className="mt-6"
              // disabled={autoCurrencyRate}
              onValueChange={(val) => {
                setValue('currencyRate', Number(val));
                clearErrors('currencyRate');
              }}
              value={watch('currencyRate')}
              suffix=" TL"
              disabled={isCreatedFromInvoice}
              error={errors?.currencyRate?.message}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ExpenseRecordPayment = ({
  setValue,
  watch,
  errors,
  clearErrors,
  isCreatedFromInvoice = false
}) => {
  function handleTotalAmountChange(value) {
    setValue('totalAmount', Number(value));
    clearErrors('totalAmount');
  }

  function handleTaxAmountChange(value) {
    setValue('totalTaxAmount', Number(value));
    clearErrors('totalTaxAmount');
  }

  return (
    <div className="py-12 border-b border-solid border-gray-200">
      <div className="flex flex-col w-full">
        <AmountInput
          label="Toplam Vergi"
          className="w-full mb-12"
          onValueChange={(val) => handleTaxAmountChange(val)}
          disabled={!watch('currency') || isCreatedFromInvoice}
          error={errors?.totalTaxAmount?.message}
          value={watch('totalTaxAmount')}
          suffix={` ${CURRENCIES[watch('currency')].show}`}
        />
        <AmountInput
          label="Toplam Tutar"
          className="w-full"
          onValueChange={(val) => handleTotalAmountChange(val)}
          error={errors?.totalAmount?.message}
          disabled={!watch('currency') || isCreatedFromInvoice}
          value={watch('totalAmount')}
          suffix={` ${CURRENCIES[watch('currency')].show}`}
        />
        {['EUR', 'USD'].includes(watch('currency')) && (
          <span className="text-xs">
            <span className="font-medium text-gray-500">Toplam TL Tutar:</span>
            <span className="font-semibold text-gray-900">
              {' '}
              {currencyFormat((watch('currencyRate') || 0) * (watch('totalAmount') || 0))} TL
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

const PaymentStatusRadioButtons = ({ watch, setValue }) => (
  <RadioGroup
    value={watch('paymentStatus')}
    onChange={(e) => setValue('paymentStatus', e)}
    className="flex gap-x-8 mt-6"
  >
    <RadioGroup.Option value={PAYMENT_STATUS.PAID}>
      {({ checked }) => (
        <div className={`${!checked && 'cursor-pointer'} flex gap-x-2 items-center`}>
          {checked ? (
            <span className="w-5 h-5 rounded-full bg-white border-4 border-solid border-green-500" />
          ) : (
            <span className="w-5 h-5 rounded-full bg-white border border-solid border-gray-200" />
          )}
          <span className="text-sm font-medium text-gray-700">Ödendi</span>
        </div>
      )}
    </RadioGroup.Option>
    <RadioGroup.Option value={PAYMENT_STATUS.NOT_PAID}>
      {({ checked }) => (
        <div className={`${!checked && 'cursor-pointer'} flex gap-x-2 items-center`}>
          {checked ? (
            <span className="w-5 h-5 rounded-full bg-white border-4 border-solid border-green-500" />
          ) : (
            <span className="w-5 h-5 rounded-full bg-white border border-solid border-gray-200" />
          )}
          <span className="text-sm font-medium text-gray-700">Ödenecek</span>
        </div>
      )}
    </RadioGroup.Option>
  </RadioGroup>
);

const PaymentStatusPaidDatePicker = ({ setValue, watch, errors, clearErrors }) => (
  <div className="flex flex-col">
    <span className="text-sm font-medium text-gray-500">Ödeme Tarihi</span>
    <div className="w-full bg-gray-100 px-3 rounded-lg flex items-center mt-1">
      <DatePicker
        className="bg-transparent py-4 w-full focus:outline-none text-black"
        selected={watch('paymentDate')}
        onChange={(e) => {
          setValue('paymentDate', e);
          clearErrors('paymentDate');
        }}
        maxDate={new Date()}
      />
      <CalendarIcon className="fill-current w-5 text-gray-400" />
    </div>
    {errors?.paymentDate && (
      <span className="text-red-500 text-xs mt-1">{errors?.paymentDate?.message}</span>
    )}
  </div>
);

const PaymentStatusPaidTransaction = ({ errors, watch, setValue }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState(null);
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);

  useEffect(() => {
    dispatch(getTransfersOfCompany({ search }))
      .then((res) => {
        if (search === null) {
          setTransfers(res.data?.data?.content);
        }

        setFilteredTransfers(res.data?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search]);

  useEffect(() => {
    setValue('transferIds', []);
  }, [watch('paymentMethod'), watch('paymentStatus')]);

  return (
    <div className="flex flex-col w-full">
      <span className="text-gray-900 font-semibold">İşlemler</span>
      <SelectTransferDropdown
        transfers={filteredTransfers}
        selectedItems={watch('transferIds')}
        setSearch={setSearch}
        search={search}
        onSelect={(selectedItems) => setValue('transferIds', selectedItems)}
      />
      <div className="flex flex-col">
        {watch('transferIds').map((transferId) => {
          const transfer = transfers.find((t) => t.id === transferId);
          return (
            <div key={transfer?.id} className="w-full flex justify-between py-4">
              <div className="flex gap-x-2 items-center justify-center">
                <span className="w-10 h-10 rounded-full bg-purple-700 text-purple-300 font-semibold flex items-center justify-center">
                  {transfer?.receiverAccount?.companyName
                    ? transfer?.receiverAccount?.companyName
                        .split(' ')
                        .map((s) => s[0])
                        .slice(0, 2)
                        .join('')
                    : `${transfer?.receiverAccount?.firstName[0]} ${transfer?.receiverAccount?.lastName[0]}`}
                </span>
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-900">
                    {transfer?.receiverAccount?.companyName ||
                      `${transfer?.receiverAccount?.firstName} ${transfer?.receiverAccount?.lastName}`}
                  </span>
                  <span className="text-xs font-medium text-gray-500">
                    {dateFormat(new Date(transfer?.transferDate))}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-x-4">
                <span className="font-semibold text-gray-900">
                  {`${currencyFormat(transfer?.amount)} ${CURRENCY_NAMES[transfer?.currency]}`}
                </span>
                <span
                  className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setValue(
                      'transferIds',
                      watch('transferIds').filter((id) => id !== transferId)
                    );
                  }}
                >
                  <TrashIcon className="fill-current w-5 text-gray-500" />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ExpenseRecordStatus = ({ setValue, watch, errors, clearErrors }) => {
  const paymentMethods = {
    NORMA: 'Norma Hesabı',
    BANK: 'Banka Hesabı',
    CARD: 'Kredi / Banka Kartı',
    CASH: 'Nakit',
    CHECK: 'Çek'
  };

  useEffect(() => {
    if (watch('paymentStatus') === PAYMENT_STATUS.PAID) {
      setValue('dueDate', null);
      clearErrors('paymentStatus');
    } else if (watch('paymentStatus') === PAYMENT_STATUS.NOT_PAID) {
      setValue('paymentDate', null);
      clearErrors('dueDate');
      setValue('paymentMethod', null);
    }
  }, [watch('paymentStatus')]);

  return (
    <div className="py-12 border-b border-solid border-gray-200 flex flex-col">
      <span className="text-lg font-semibold text-gray-900">Durum</span>
      <PaymentStatusRadioButtons setValue={setValue} watch={watch} />
      {watch('paymentStatus') === PAYMENT_STATUS.PAID && (
        <div className="mt-8">
          <div className="mb-8 flex gap-2 flex-wrap">
            {Object.keys(paymentMethods).map((p, i) => (
              <span
                key={i}
                className={`${
                  watch('paymentMethod') === p
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 text-gray-500 cursor-pointer'
                } px-4 py-2 rounded-full text-sm font-medium`}
                onClick={() => {
                  setValue('paymentMethod', p);
                  setValue('paymentDate', null);
                  setValue('dueDate', null);
                  setValue('transferIds', []);
                }}
              >
                {paymentMethods[p]}
              </span>
            ))}
          </div>
          <PaymentStatusPaidDatePicker
            setValue={setValue}
            errors={errors}
            watch={watch}
            clearErrors={clearErrors}
          />
          {/* {watch('paymentMethod') === PAYMENT_METHODS.NORMA ? (
            <PaymentStatusPaidTransaction errors={errors} setValue={setValue} watch={watch} />
          ) : (
            <PaymentStatusPaidDatePicker
              setValue={setValue}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
            />
          )} */}
        </div>
      )}
      {watch('paymentStatus') === PAYMENT_STATUS.NOT_PAID && (
        <div className="mt-8">
          <span className="text-sm font-medium text-gray-500">Vade Tarihi</span>
          <div className="w-full bg-gray-100 px-3 rounded-lg flex items-center">
            <DatePicker
              className="bg-transparent py-4 w-full focus:outline-none text-black"
              selected={watch('dueDate')}
              onChange={(e) => {
                setValue('dueDate', e);
                clearErrors('dueDate');
              }}
              minDate={new Date()}
            />
            <CalendarIcon className="fill-current w-5 text-gray-400" />
          </div>
          {errors?.dueDate && (
            <span className="text-red-500 text-xs mt-1">{errors?.dueDate?.message}</span>
          )}
        </div>
      )}
    </div>
  );
};

const ExpenseRecordNote = ({ setValue, watch }) => {
  const maxLength = 140;
  return (
    <div className="py-12 border-b border-solid border-gray-200 flex flex-col">
      <span className="text-sm font-medium text-gray-500">
        Not Ekle <OptionalArea />
      </span>
      <div className="w-full flex flex-col">
        <textarea
          className="appearance-none bg-gray-100 rounded-lg h-20 resize-none focus:outline-none mt-1 p-2"
          maxLength={maxLength}
          onChange={(e) => setValue('note', e.target.value)}
          value={watch('note')}
        />
        <span className="mt-2 ml-auto text-xs font-medium text-gray-400">{`${
          watch('note') ? watch('note').length : 0
        } / ${maxLength}`}</span>
      </div>
    </div>
  );
};

const EditExpenseModal = ({
  handleClose,
  defaultInvoice,
  defaultValues,
  deleteExpense,
  defaultFile,
  data
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [document, setDocument] = useState();
  const [summary, setSummary] = useState();
  const [searchContact, setSearchContact] = useState('');
  const [file, setFile] = useState(defaultFile);
  const isCreatedFromInvoice = !!data.salesExpenseInvoiceDto?.documentId;

  const [addContactModalActive, , toggleAddContactModal] = useModal();
  const [confirmDeletePopupActive, , toggleConfirmDeletePopup] = useModal();
  const [isSaleCanNotDeletePopupActive, , toggleSaleCanNotDeletePopup] = useModal();

  const schema = z
    .object({
      salesExpenseRecordId: z.number(),
      categoryId: z.optional(z.number().nullish()),
      contactId: z.optional(z.string().nullish()),
      currency: z.string({
        invalid_type_error: 'Para Birimi seçilmesi zorunludur.',
        required_error: 'Para Birimi seçilmesi zorunludur.'
      }),
      currencyRate: z.number({
        invalid_type_error: 'Kur girilmesi zorunludur.',
        required_error: 'Kur girilmesi zorunludur.'
      }),
      documentId: z.optional(z.string().nullish()),
      dueDate: z.date().nullish(),
      invoiceDate: z.date().nullish(),
      invoiceFileIds: z.any(),
      invoiceNumber: z.any().nullish(),
      items: z.any(),
      note: z.optional(z.string().nullish()),
      paymentDate: z.date().nullish(),
      paymentMethod: z.optional(z.string().nullable()),
      paymentStatus: z.enum(Object.keys(PAYMENT_STATUS)),
      transferIds: z.any(),
      totalAmount: z.number({
        invalid_type_error: 'Toplam Tutar girilmesi zorunludur.',
        required_error: 'Toplam Tutar girilmesi zorunludur.'
      }),
      recordType: z.string(),
      title: z
        .string({
          required_error: 'Kayıt İsmi girilmesi zorunludur.',
          invalid_type_error: 'Kayıt İsmi girilmesi zorunludur.'
        })
        .nonempty('Kayıt İsmi girilmesi zorunludur.'),
      totalTaxAmount: z
        .number({
          required_error: 'Toplam Vergi girilmesi zorunludur.',
          invalid_type_error: 'Toplam Vergi girilmesi zorunludur.'
        })
        .positive('Toplam Vergi girilmesi zorunludur.'),
      voucherFileIds: z.array(z.number()).nullish(),
      salesExpenseInvoiceDto: z.optional(
        z.object({ id: z.number(), invoiceDate: z.string(), invoiceNumber: z.string() })
      )
    })
    .refine(
      (data) =>
        data.paymentStatus === PAYMENT_STATUS.PAID && data.paymentMethod !== PAYMENT_METHODS.NORMA
          ? data.paymentDate
          : true,
      {
        path: ['paymentDate'],
        message: 'Ödeme Tarihi girilmesi zorunludur.'
      }
    )
    .refine((data) => (data.paymentStatus === PAYMENT_STATUS.NOT_PAID ? data.dueDate : true), {
      path: ['dueDate'],
      message: 'Vade Tarihi girilmesi zorunludur.'
    })
    .refine((data) => (data.paymentStatus === PAYMENT_STATUS.PAID ? data.paymentMethod : true), {
      path: ['paymentMethod'],
      message: 'Ödeme Metodu girilmesi zorunludur.'
    })
    .refine(
      (data) => (data.currency !== 'TRY' && data.currencyRate <= 0 ? data.currencyRate : true),
      {
        path: ['currencyRate'],
        message: 'Kur girilmesi zorunludur.'
      }
    );

  const [isAddCategoryModalActive, , toggleAddCategoryModal] = useModal();

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues, resolver: zodResolver(schema) });
  const isCorrect =
    watch('title') &&
    watch('invoiceDate') &&
    watch('totalAmount') &&
    watch('totalTaxAmount') &&
    watch('currencyRate') &&
    watch('paymentStatus') &&
    (watch('paymentDate') || watch('dueDate') || watch('transferIds').length);

  useEffect(() => {
    if (!defaultInvoice?.documentId || !user.companyId) return;

    // setValue('documentId', defaultInvoice?.documentId);
    getDocumentById({ companyId: user.companyId, documentId: defaultInvoice?.documentId }).then(
      (res) => {
        setDocument(res.data?.data);
      }
    );
  }, []);

  useEffect(() => {
    getContactsBySearch();
  }, [searchContact, addContactModalActive]);

  async function onSubmit(data) {
    if (!user?.companyId) return;

    if (file instanceof File) {
      const res = await uploadFile({ file, fileType: FILE_TYPES.EXPENSE_INVOICE });

      data = {
        ...data,
        invoiceFileIds: res.data?.data?.id ? [res.data?.data?.id] : []
      };
    }
    if (data.dueDate) data.dueDate = getFormattedDateForPayload(data.dueDate);
    if (data.paymentDate) data.paymentDate = getFormattedDateForPayload(data.paymentDate);
    if (data.invoiceDate) data.invoiceDate = getFormattedDateForPayload(data.invoiceDate);

    try {
      // if (data.currency !== 'TRY') {
      //   data.totalAmount = Math.round(data.totalAmount * data.currencyRate * 100) / 100;
      //   data.totalTaxAmount = Math.round(data.totalTaxAmount * data.currencyRate * 100) / 100;
      // }
      await updateSaleExpense({ companyId: user.companyId, ...data });

      handleClose();
    } catch (err) {
      console.log(err);
    }
  }

  function getContactsBySearch() {
    dispatch(
      getContacts({
        search: searchContact,
        size: 9999,
        orderBy: 'firstName',
        orderDirection: 'ASC'
      })
    );
  }

  function handleCreatedContact(contact) {
    setValue('contactId', contact.id);
  }

  function handleDelete() {
    if (data?.salesExpenseInvoiceDto?.documentId) {
      toggleSaleCanNotDeletePopup();
    } else {
      toggleConfirmDeletePopup();
    }
  }

  useEffect(() => {
    if (!defaultInvoice?.documentId || !user.companyId) return;

    dispatch(
      getDocumentSummaryById({
        companyId: user.companyId,
        documentId: defaultInvoice?.documentId
      })
    ).then((res) => {
      setSummary(res.data?.data);
    });
  }, []);

  return (
    <BottomModal handleClose={handleClose} className="flex flex-col items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        {addContactModalActive && (
          <CreateContactModal onClose={toggleAddContactModal} onSucceded={handleCreatedContact} />
        )}
        {isAddCategoryModalActive && <AddCategoryModal handleClose={toggleAddCategoryModal} />}
        <ConfirmDeletePopup
          active={confirmDeletePopupActive}
          onClose={toggleConfirmDeletePopup}
          handleDelete={deleteExpense}
        />
        <SaleCanNotDeletePopup
          active={isSaleCanNotDeletePopupActive}
          onClose={toggleSaleCanNotDeletePopup}
        />
        <div className="flex justify-center h-full overflow-y-scroll">
          <div className="w-[640px] h-full flex flex-col py-10">
            <div className="flex flex-col py-12 border-b border-solid border-gray-200">
              <span className="text-sm font-semibold text-gray-400">Gider Kaydı Düzenle</span>
              <span className="text-xl font-semibold text-gray-900">Masraf Düzenle</span>
            </div>
            <ExpenseRecordDescription
              setValue={setValue}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              document={document}
            />
            <ExpenseInvoice
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
              setFile={setFile}
              file={file}
              isCreatedFromInvoice={isCreatedFromInvoice}
            />
            <ExpenseContact
              watch={watch}
              setValue={setValue}
              toggleAddContactModal={toggleAddContactModal}
              search={searchContact}
              setSearch={setSearchContact}
              errors={errors}
              clearErrors={clearErrors}
            />
            <ExpenseRecordCategory
              setValue={setValue}
              watch={watch}
              errors={errors}
              toggleAddCategoryModal={toggleAddCategoryModal}
            />
            <ExpenseRecordCurrency
              setValue={setValue}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              isCreatedFromInvoice={isCreatedFromInvoice}
            />
            {!defaultInvoice?.documentId && (
              <ExpenseRecordPayment
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
                isCreatedFromInvoice={isCreatedFromInvoice}
              />
            )}
            <ExpenseRecordStatus
              setValue={setValue}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
              isCreatedFromInvoice={isCreatedFromInvoice}
            />
            <ExpenseRecordNote setValue={setValue} watch={watch} errors={errors} />
            {!!defaultInvoice?.documentId && (
              <>
                <ItemsTable
                  items={summary?.financialSummary?.invoiceLineSummaries || []}
                  currencyCode={summary?.currencyCode}
                />
                <ExpenseRecordTaxes
                  document={summary}
                  hasDocumentId={!!defaultInvoice?.documentId}
                />
              </>
            )}
            <div className="py-12 flex gap-x-6">
              <CustomButton color="danger" className="w-1/2" onClick={handleDelete}>
                Sil
              </CustomButton>
              <CustomButton
                color="success"
                className="w-1/2"
                type="submit"
                onClick={() => {
                  console.log(errors);
                }}
                disabled={!isCorrect}
              >
                Kaydet
              </CustomButton>
            </div>
          </div>
        </div>
      </form>
    </BottomModal>
  );
};

export default EditExpenseModal;
