import { SideModal } from 'components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import { getCompany, onboardingCompany } from 'store/actions/company';

import useModal from 'utils/hooks/useModal';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { useHistory } from 'react-router-dom';
import { useRouter } from 'utils/hooks/useRouter';
import { useRouteStep } from 'utils/hooks/useRouteStep';

import { COMPANY_SUB_TYPES, filterKeys, ONBOARDING_COMPANY_FLOW_TYPE } from 'utils';
import { SERVICE_PACKAGE_TYPES } from 'utils/contants';
import {
  CompanyType,
  CompanyBusinessAddress,
  VirtualOfficeSelection,
  AddCurrentBusinessAddress,
  CompanyActivityInformation,
  StartCompanySuccessModal
} from './steps';

const defaultValues = {
  address: null,
  businessInfo: null,
  cityId: 0,
  companyAddressType: 'REAL',
  companySubType: COMPANY_SUB_TYPES.SOLE_TRADER,
  countryId: 0,
  districtId: 0,
  name: null,
  profession: null,
  sector: null,
  taxIdentityNumber: null,
  taxOfficeId: 0,
  virtualOfficeId: 0
};

const StartCompany = () => {
  const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sendGTMEvent } = useGTM();
  const { subscriptions } = useSelector((state) => state.marketplace);
  const { user } = useSelector((state) => state.auth);
  const { publishEvent, eventTypes } = useDengage();

  const hasVirtualOfficeSubscription =
    subscriptions?.filter(
      (item) =>
        item.status === 'ACTIVE' &&
        item.itemVariant.itemName === SERVICE_PACKAGE_TYPES.VIRTUAL_OFFICE.key
    ).length > 0;

  const [step, nextStep] = useRouteStep(
    {
      router,
      max: 5,
      fallbackPath: '/company-options'
    },
    (step) => {
      if (hasVirtualOfficeSubscription) {
        return nextStep(4);
      }
      if ([2].includes(step) && watch('companyAddressType') === 'REAL') {
        return nextStep(1);
      }
      if ([3].includes(step) && watch('companyAddressType') === 'VIRTUAL') {
        return nextStep(2);
      }
      if ([2, 3].includes(step) && watch('companyAddressType') === 'VEHICLE') {
        return nextStep(1);
      }

      nextStep(step);
    }
  );

  const [successModalActive, , toggleSuccessModal] = useModal({
    handleClose: async () => {
      const company = await dispatch(getCompany());
      sendGTMEvent({
        event: 'catEvent',
        eventName: 'start_company_activity_completed',
        location: `${company?.location?.countryTitle}, ${company?.location?.cityTitle}`
      });
      history.push('/');
    }
  });

  const { watch, handleSubmit, setValue } = useForm({ defaultValues });

  function onSubmit(data) {
    let payload = data;

    if (hasVirtualOfficeSubscription) {
      payload = {
        ...filterKeys(data, ['cityId', 'countryId', 'districtId', 'taxOfficeId'], {
          filterEmpty: true,
          filterNull: true
        }),
        companySubType: COMPANY_SUB_TYPES.SOLE_TRADER,
        companyAddressType: 'VIRTUAL',
        name: `${user?.firstName} ${user?.lastName}`,
        taxIdentityNumber: user.identityNumber
      };
    }
    dispatch(onboardingCompany({ ...payload, flowType: ONBOARDING_COMPANY_FLOW_TYPE.START }))
      .then(() => {
        sendGTMEvent({ event: 'catEvent', eventName: 'start_a_company_sector_success' });
        sendGTMEvent({
          event: 'catEvent',
          eventName: 'start_a_company',
          start_date: new Date().toJSON().slice(0, 10).replace(/-/g, '-')
        });
        publishEvent(eventTypes.pageView, { page_type: 'start_company_activity_completed' });
        dispatch(getCompany()).then((company) => {
          sendGTMEvent({
            event: 'catEvent',
            eventName: 'start_company_activity_completed',
            location: `${company?.location?.countryTitle}, ${company?.location?.cityTitle}`
          });
          history.push('/');
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [isInfoSideModalActive, setIsInfoSideModalActive] = useState(false);
  function toggleInfoSidebar() {
    setIsInfoSideModalActive(!isInfoSideModalActive);
  }

  const STEPS = [
    <CompanyType key={'Step0'} nextStep={nextStep} setValue={setValue} />,
    <CompanyBusinessAddress
      key={'Step1'}
      nextStep={nextStep}
      setValue={setValue}
      hasVirtualOfficeSubscription={hasVirtualOfficeSubscription}
    />,
    <VirtualOfficeSelection
      key={'Step2'}
      nextStep={nextStep}
      setValue={setValue}
      toggleInfoSidebar={toggleInfoSidebar}
      watch={watch}
    />,
    <AddCurrentBusinessAddress
      key={'Step3'}
      nextStep={nextStep}
      setValue={setValue}
      watch={watch}
    />,
    <CompanyActivityInformation key={'Step4'} watch={watch} setValue={setValue} />
  ];

  useEffect(() => {
    if (hasVirtualOfficeSubscription) {
      nextStep(4);
    }
  }, [hasVirtualOfficeSubscription]);

  useEffect(() => {
    if (watch('companyAddressType') === 'REAL') {
      setValue('virtualOfficeId', null);
    }
    if (watch('companyAddressType') === 'VIRTUAL') {
      setValue('cityId', null);
      setValue('districtId', null);
      setValue('address', null);
    }
    if (watch('companyAddressType') === 'VEHICLE') {
      setValue('virtualOfficeId', null);
      setValue('cityId', null);
      setValue('districtId', null);
      setValue('address', null);
    }
  }, [watch('companyAddressType')]);

  return (
    <>
      {isInfoSideModalActive && <InfoSideModal handleClose={toggleInfoSidebar} />}
      {successModalActive && <StartCompanySuccessModal handleClose={toggleSuccessModal} />}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex items-center md:justify-center max-w-[400px] md:flex-1"
        data-testid="section_start_company_form"
      >
        {STEPS[step]}
      </form>
    </>
  );
};

const InfoSideModal = ({ handleClose }) => (
  <SideModal handleClose={handleClose} data-testid="section_modal_side_info">
    <span className="text-gray-900 text-3xl font-semibold">Sanal ofis nedir</span>
    <div className="w-full h-36 bg-gray-300 rounded-lg mt-6">
      <img
        src={'https://norma-assets.s3.eu-west-1.amazonaws.com/marketplace/virtual_office.png'}
        alt="info side"
      />
    </div>
    <div className="mt-6 overflow-y-scroll">
      <span className="text-sm">
        Esnek ve entegre çalışma anlayışının bir ürünü olarak ortaya çıkan sanal ofis; tüm şirket
        yönetimleri arasında kullanımı yaygın olan, fiziksel ofis ihtiyaçlarının tamamının
        karşılanabildiği bir alandır. Sanal ofis, her çalışma endüstrisinin ihtiyaç duyduğu ofis
        ortamını onlara sunabilen aynı zamanda konsept çalışma düzeni imkanları barındıran
        alanların genel adıdır. Yani şirketiniz için baştan sona bir ofis var etmek yerine;
        herhangi bir sermaye yatırımı yapmadan, ofis masraflarını minimum düzeyde tutarak ve
        fiziki ofis imkanlarının tamamına sahip olarak kullanmanız için sunulan alanlara sanal
        ofis adı verilir. Türk Ticaret Kanunu’nda belirtilen iş yeri ve ofis tanımlamasında,
        şirketiniz için bulunması koşul sayılan gerekliliklere uygun şekilde hizmetinize sunulan
        sanal ofisler tamamıyla yasaldır. Bu bakımdan hem ofis imkanlarından faydalanabileceğiniz
        hem de şirketinize ait fatura ve çalışma adresi olarak gösterebileceğiniz sanal ofisler
        sayesinde, fiziki ofis koşulları oluşturmak için fazladan zaman ve para harcamazsınız.
        Norma desteği ile hem sanal ofis ihtiyaçlarınızı en güvenli ve hızlı şekilde karşılar hem
        de şirketinizin tüm mali işlemleri ve prosedür sorumluluklarını tek kanal üzerinden
        yürütebilirsiniz.
      </span>
    </div>
  </SideModal>
);
export default StartCompany;
