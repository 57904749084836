import { useContext, createContext, useState } from 'react';
import { BottomModal, Button } from 'components';

import {
  SelectBank,
  AddBankAccountForm,
  BankList,
  DownloadForm,
  SelectBankAccount,
  Succeded,
  AddManuallyBankAccountForm
} from './components';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import classNames from 'classnames';
import { useStep } from 'utils/hooks';

const initialFormValues = {
  bank: null,
  corporate: {},
  selectedBankAccounts: []
};
const steps = {
  bankList: 0,
  selectBank: 1,
  downloadForm: 2,
  addBankAccountForm: 3,
  addManuallyBankAccountForm: 4,
  selectBankAccount: 5,
  succeded: 6
};
const OpenBankingContext = createContext({
  step: 0,
  setStep: () => {},
  nextStep: () => {},
  previouseStep: () => {},
  formValues: initialFormValues,
  setFormValues: () => {},
  steps,
  onClose: () => {}
});
export const useOpenBakingContext = () => useContext(OpenBankingContext);

export default function OpenBankingCreateModal({ onClose = () => {} }) {
  const { step, setStep, nextStep, previousStep, DEFUALT_STEP_INDEX } = useStep({
    defaultStep: 0
  });
  const [formValues, setFormValues] = useState(initialFormValues);

  const STEPS = [
    <BankList key={'BankList'} />,
    <SelectBank key="SelectBank" />,
    <DownloadForm key="DownloadForm" />,
    <AddBankAccountForm key="AddBankAccountForm" />,
    <AddManuallyBankAccountForm key="AddManuallyBankAccountForm" />,
    <SelectBankAccount key="SelectBankAccount" />,
    <Succeded key="Succeded" />
  ];

  const modalClassnames = classNames(
    'bg-gray-50 overflow-y-auto pb-6',
    step === steps.succeded && '!bg-green-400 !text-white'
  );
  return (
    <OpenBankingContext.Provider
      value={{
        step,
        setStep,
        nextStep,
        previousStep,
        formValues,
        setFormValues,
        steps,
        onClose
      }}
    >
      <BottomModal isActive handleClose={onClose} className={modalClassnames}>
        <div className="h-12 m-6">
          {step > DEFUALT_STEP_INDEX && step !== steps.succeded && (
            <Button variant="circle" color="gray" icon={IconArrowLeft} onClick={previousStep} />
          )}
        </div>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full h-full max-w-[460px] mx-auto">{STEPS[step]}</div>
        </div>
      </BottomModal>
    </OpenBankingContext.Provider>
  );
}
