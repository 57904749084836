import React from 'react';
import ReactSelect from 'react-select';

const style = {
  control: (base, state) => ({
    ...base,
    border: state.isSelected && 0,

    boxShadow: state.isSelected && 'none',
    '&:hover': {
      border: state.isSelected && 'none'
    },
    '&:focus': {
      border: state.isSelected && 0,
      boxShadow: state.isSelected && 'none',
      backgroundColor: state.isSelected && '#bdbdbd'
    },
    '&.norma__control--is-disabled': {
      boxShadow: 'none'
    }
  })
};

function DropdownSelect({
  options = [],
  defaultValue,
  isSearchable = false,
  isDisabled = false,
  name = '',
  isLoading,
  value = null,
  isMulti = false,
  customOptionComponent = null,
  noOptionsMessage = 'Veri Yok',
  ...props
}) {
  return (
    <ReactSelect
      styles={style}
      classNamePrefix="norma"
      options={options}
      defaultValue={defaultValue}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      name={name}
      isLoading={isLoading}
      value={value}
      isMulti={isMulti}
      components={customOptionComponent}
      noOptionsMessage={() => noOptionsMessage}
      {...props}
    />
  );
}

export default DropdownSelect;
