import { useWalletContext } from 'contexts/walletContext';
import React from 'react';
import { ReactComponent as IconArrowDown } from 'assets/icons/caret-down.svg';

import { ReactComponent as IconNorma } from 'assets/icons/norma-icon.svg';
import { ReactComponent as IconBankColored } from 'assets/icons/bank-colored.svg';
import { ReactComponent as IconWalletColored } from 'assets/icons/wallet-colored.svg';
import { ReactComponent as IconIntegratedBankColored } from 'assets/icons/integrated-bank-colored.svg';

import { WALLET_TYPES } from 'utils/contants';

const WALLETS = [
  {
    title: 'Banka Hesapları'
  },
  {
    label: WALLET_TYPES.EMONEY.label,
    icon: <IconNorma className="w-8 h-8" />,
    type: WALLET_TYPES.EMONEY.value,
    description: 'Tüm ticari ödeme ve tahsilatlarınız için ücretsiz kurumsal hesap.',
    isDisabled: false
  },
  {
    label: WALLET_TYPES.OPEN_BANKING.label,
    icon: <IconIntegratedBankColored className="w-8 h-8" />,
    type: WALLET_TYPES.OPEN_BANKING.value,
    description: 'Anlaşmalı banka hesaplarınızı Norma üzerinden yönetebileceğiniz hesap.',
    isDisabled: false
  },
  { isDivider: true },
  {
    title: 'Kasa Hesapları'
  },
  {
    label: WALLET_TYPES.CASH.label,
    icon: <IconWalletColored className="w-8 h-8" />,
    type: WALLET_TYPES.CASH.value,
    description: 'Şirketinizin nakit giriş-çıkışlarını takip edebileceğiniz kasa hesabı.',
    isDisabled: false
  },
  {
    label: WALLET_TYPES.BANK.label,
    icon: <IconBankColored className="w-8 h-8" />,
    type: WALLET_TYPES.BANK.value,
    description: 'Bankalardaki hesap hareketlerinizi takip edebileceğiniz hesap.',
    isDisabled: false
  }
];
export default function WalletTypeSelectionButton() {
  const { selectedWalletType, toggleWalletSelectionModal } = useWalletContext();

  if (!selectedWalletType) return null;

  const { label, icon } = WALLETS.find((wallet) => wallet.type === selectedWalletType);

  return (
    <div
      className="flex gap-x-3 items-center text-white text-2xl hover:bg-gray-800 cursor-pointer rounded-[20px] py-[10px] px-4"
      onClick={toggleWalletSelectionModal}
    >
      {icon}
      <span className="text-[22px] font-semibold">{label}</span>
      <IconArrowDown className="stroke-current w-6 h-6 font-semibold" />
    </div>
  );
}
