import { DatePicker } from 'components';

const PaymentStatusPaidDatePicker = ({ watch, setValue, errors }) => (
  <div className="flex flex-col mt-10">
    <span className="text-gray-500 text-sm font-medium">Ödeme Tarihi</span>
    <DatePicker
      className="bg-gray-100 w-full rounded-lg p-2 focus:outline-none"
      selected={watch('paymentDate')}
      onChange={(date) => setValue('paymentDate', date)}
      maxDate={new Date()}
    />
    {errors?.paymentDate && (
      <span className="text-red-500 text-xs mt-2">{errors?.paymentDate.message}</span>
    )}
  </div>
);

export default PaymentStatusPaidDatePicker;
