import { archiveTypes } from 'store/types';

const {
  ARCHIVE_WALLET,
  ARCHIVE_WALLET_SUCCESS,
  ARCHIVE_WALLET_FAILURE,
  UNARCHIVE_WALLET,
  UNARCHIVE_WALLET_SUCCESS,
  UNARCHIVE_WALLET_FAILURE,
  GET_ARCHIVED_WALLET,
  GET_ARCHIVED_WALLET_FAILURE,
  GET_ARCHIVED_WALLET_SUCCESS
} = archiveTypes;

const initialState = {
  loading: false,
  data: null,
  error: null
};

export default function WalletReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARCHIVED_WALLET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ARCHIVED_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case GET_ARCHIVED_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ARCHIVE_WALLET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ARCHIVE_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        archiveWallet: action.payload,
        error: null
      };
    case ARCHIVE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UNARCHIVE_WALLET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UNARCHIVE_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        unarchivedWallet: action.payload,
        error: null
      };
    case UNARCHIVE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
