import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { getCompany } from 'store/actions/company';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';

import { ReactComponent as IconClipboard } from 'assets/icons/clipboard.svg';

import { getSubscriptions } from 'store/actions/marketplace';
import { SERVICE_PACKAGE_TYPES } from 'utils/contants';
import { useRouter } from 'utils/hooks/useRouter';
import StepHeader from './StepHeader';
import { Card } from 'components';
import { useDeviceDetection } from 'utils/hooks';
import classNames from 'classnames';

const CompanyOptions = () => {
  const history = useHistory();
  const { push } = useRouter();
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();
  const { companyStatus } = useSelector((state) => state.auth);
  const { subscriptions } = useSelector((state) => state.marketplace);

  useEffect(() => {
    shouldBeRedirected();
    publishEvent(eventTypes.pageView, { page_type: 'company_selection_type' });
    dispatch(getSubscriptions());
  }, []);

  useEffect(() => {
    if (
      subscriptions?.filter(
        (item) =>
          item.status === 'ACTIVE' &&
          item.itemVariant.itemName === SERVICE_PACKAGE_TYPES.VIRTUAL_OFFICE.key
      ).length > 0
    ) {
      push('/company-options/start-company?step=4');
    }
  }, [subscriptions]);

  const shouldBeRedirected = async () => {
    if (companyStatus !== 'PASSIVE') {
      await dispatch(getCompany());
      history.push('/');
    }
  };
  const redirectSetupNewCompany = () => {
    history.push('/company-options/start-company');

    sendGTMEvent({
      event: 'catEvent',
      eventName: 'no_company'
    });
  };

  return (
    <div className="w-full h-screen flex md:p-0" data-testid="section_company_options">
      <div className="w-full h-full flex md:items-center justify-center">
        <div className="flex flex-col space-y-10 w-full max-w-[400px]">
          <StepHeader title="Şirketini Yönet" subtitle="Mevcut şirket durumunuzu belirtin" />
          <div className="flex flex-col space-y-4 mx-[2px]">
            <CompanyTypeCard
              title="Şirketim zaten var"
              subtitle="Şahıs şirketim var veya serbest meslek erbabıyım"
              icon={<BuildingIcon className="h-8 w-8" />}
              onClick={() => {
                sendGTMEvent({
                  event: 'catEvent',
                  eventName: 'have_company'
                });
                history.push('/company-options/add-company');
              }}
              data-testid="button_company_options_add_company"
            />
            <CompanyTypeCard
              title="Yeni şirket kurmak istiyorum"
              subtitle="Şahıs şirketi kurmak veya serbest meslek erbabı olmak istiyorum"
              icon={<IconClipboard className="h-8 w-8" />}
              onClick={() => redirectSetupNewCompany()}
              data-testid="button_company_options_setup_new_company"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyTypeCard = ({ title, subtitle, onClick, icon, className }) => {
  const { isMobile, isIOS } = useDeviceDetection();
  const cardContentClassnames = classNames(
    'flex',
    {
      'gap-x-4': !isMobile,
      'space-x-4': isIOS
    },
    className
  );

  return (
    <Card className="!cursor-pointer hover:translate-y-1" onClick={onClick}>
      <div className={cardContentClassnames}>
        <div>{icon}</div>
        <div className="m-0">
          <h6 data-testid="label_modal_title">{title}</h6>
          <p className="mt-2" data-testid="label_modal_subtitle">
            {subtitle}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default CompanyOptions;
