import { ReactComponent as UserIcon } from 'assets/icons/user.svg';

import { CustomButton, Popup } from 'components';

const EditIbanInfoPopup = ({ active, onClose, toggleEditContactModal }) => (
  <Popup
    active={active}
    onClose={onClose}
    className="z-50"
    style={{ width: '320px', height: 'auto' }}
  >
    <div className="flex flex-col items-center justify-center gap-y-6 h-auto">
      <span className="w-20 h-20 rounded-full bg-yellow-100 flex items-center justify-center">
        <UserIcon className="fill-current text-yellow-500 w-10 h-10" />
      </span>
      <span className="font-semibold text-gray-900 text-center">
        Lütfen seçtiğiniz kontağın IBAN ya da Norma Numarası bilgilerini giriniz.
      </span>
      <CustomButton
        color="success"
        className="w-full"
        onClick={() => {
          onClose();
          toggleEditContactModal();
        }}
      >
        Müşteri/Tedarikçi Düzenle
      </CustomButton>
    </div>
  </Popup>
);

export default EditIbanInfoPopup;
