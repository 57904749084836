import axios from 'axios';

export const createTransfer = ({
  accountId,
  amount,
  description,
  receiverIdentifier,
  receiverFullName,
  transactionType,
  transferMethod,
  walletTransferReferenceId
}) => {
  return () => {
    return axios.post(`/api/accounts/${accountId}/transfers`, {
      amount,
      description,
      receiverFullName,
      receiverIdentifier,
      transactionType,
      transferMethod,
      walletTransferReferenceId
    });
  };
};

export const getTransfersOfCompany = ({ size = 10, search, ...filters }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId) return Promise.reject();

    const payload = {
      orderBy: 'createDate',
      orderDirection: 'DESC',
      status: 'COMPLETED',
      'party-name': search,
      size,
      'contact-list': false,
      ...filters
    };

    if (filters['start-date'] || filters['end-date']) {
      if (new Date(filters['start-date']).getTime() === new Date(filters['end-date']).getTime()) {
        payload['end-date'] = new Date(
          new Date(filters['end-date']).setUTCHours(23, 59, 59, 999)
        );
      }
    }
    return axios.get(`/api/companies/${user.companyId}/transfers`, {
      params: payload
    });
  };
};

export const changeStatusTransfer = ({ accountId, statusUpdateToken, status, description }) => {
  return () => {
    return axios.put(`/api/accounts/${accountId}/transfers/status`, {
      statusUpdateToken,
      status,
      description
    });
  };
};

export const findTransferById = ({ id }) => {
  return () => {
    return axios.get(`/api/transfers/${id}`);
  };
};

export const getTransferDetail = ({ accountId, id }) => {
  return () => {
    return axios.get(`/api/accounts/${accountId}/transfers/${id}`);
  };
};

export const updateTransferCategory = ({ accountId, transferId, categoryId }) => {
  return () => {
    return axios.put(`/api/accounts/${accountId}/transfers/${transferId}/categories`, {
      categoryId
    });
  };
};

export const exportTransfers = ({ accountId, fileType }) => {
  return () => {
    return axios.get('/api/transfers/export', {
      params: { 'account-id': accountId, 'file-type': fileType }
    });
  };
};
