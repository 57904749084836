// Layouts
import { FullScreenLayout, DefaultLayout, MarketplaceLayout } from 'layouts';

//Pages
import Home from 'views/home';
import Accounts from 'views/accounts';
import NotFound from 'views/misc/NotFound';
import Welcome from 'views/welcome';
import Login from 'views/login';
import Signup from 'views/signup';
import ForgotPassword from 'views/forgot-password';
import Transactions from 'views/transactions';
import Invoicing from 'views/invoicing';
import CreateExpenseRecordProductService from 'views/invoicing/sub-pages/createExpense';
import Other from 'views/other';
import ContactDetail from 'views/other/contacts/contactDetail';
import ItemDetail from 'views/other/items/sub-pages/ItemDetail';
import Profile from 'views/profile';
import ExpenseDetail from 'views/invoicing/sub-pages/expenseDetail';
import CompanyOptions from 'views/company-options';
import AddCompany from 'views/company-options/addCompany';
import StartCompany from 'views/company-options/startCompany';
import KYC from 'views/kyc';
import AgreementAddress from 'views/agreementAddress';
import OnboardingCompleteWelcome from 'views/onboardingCompleteWelcome';
import OnboardingMarketplace from 'views/onboardingMarketplace';
import Documents from 'views/documents';
import AccessRouter from 'components/accessRouter';
import ContactContextProvider from 'contexts/contactContext';
import WalletContextProvider from 'contexts/walletContext';
import ItemContextProvider from 'contexts/itemContext';

const routes = {
  home: {
    path: '/',
    name: 'Home',
    exact: true,
    isPrivate: true,
    component: Home,
    layout: DefaultLayout
  },
  accounts: {
    path: '/accounts',
    name: 'Accounts',
    exact: true,
    isPrivate: true,
    component: Accounts,
    layout: DefaultLayout,
    contextProvider: WalletContextProvider
  },
  transactions: {
    path: '/transactions',
    name: 'Transactions',
    exact: false,
    isPrivate: true,
    component: Transactions,
    layout: DefaultLayout,
    contextProvider: ContactContextProvider
  },
  invoicing: {
    path: '/invoicing',
    name: 'Invoicing',
    exact: true,
    isPrivate: true,
    component: Invoicing,
    layout: DefaultLayout,
    contextProvider: ContactContextProvider
  },
  createExpenseRecord: {
    path: '/invoicing/create-expense-record',
    name: 'CreateExpenseRecord',
    exact: true,
    isPrivate: true,
    component: CreateExpenseRecordProductService,
    layout: DefaultLayout,
    contextProvider: ContactContextProvider
  },
  expenseDetail: {
    path: '/invoicing/expense-detail/:id',
    name: 'ExpenseDetail',
    exact: true,
    isPrivate: true,
    component: ExpenseDetail,
    layout: DefaultLayout
  },
  other: {
    path: '/other',
    name: 'Other',
    exact: false,
    isPrivate: true,
    component: Other,
    layout: DefaultLayout
  },
  contactDetail: {
    path: '/contact/:id',
    name: 'ContactDetail',
    exact: true,
    isPrivate: true,
    component: ContactDetail,
    layout: DefaultLayout,
    contextProvider: ContactContextProvider
  },
  itemDetail: {
    path: '/items/:id',
    name: 'ItemDetail',
    exact: true,
    isPrivate: true,
    component: ItemDetail,
    layout: DefaultLayout,
    contextProvider: ItemContextProvider
  },
  profile: {
    path: '/profile',
    name: 'Profile',
    exact: false,
    isPrivate: true,
    component: Profile,
    layout: DefaultLayout
  },
  documents: {
    path: '/documents',
    name: 'Documents',
    exact: false,
    isPrivate: true,
    component: Documents,
    layout: DefaultLayout
  },

  welcome: {
    path: '/welcome',
    name: 'Welcome',
    exact: true,
    component: Welcome,
    isPrivate: false,
    layout: FullScreenLayout
  },
  login: {
    path: '/login',
    name: 'Login',
    exact: true,
    component: Login,
    isPrivate: false,
    layout: FullScreenLayout
  },
  signup: {
    path: '/signup',
    name: 'Signup',
    exact: true,
    component: Signup,
    isPrivate: false,
    layout: FullScreenLayout
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'ForgotPassword',
    exact: true,
    component: ForgotPassword,
    isPrivate: false,
    layout: FullScreenLayout
  },
  companyOptions: {
    path: '/company-options',
    name: 'CompanyOptions',
    exact: true,
    isPrivate: true,
    component: CompanyOptions,
    layout: FullScreenLayout,
    isOnboarding: true
  },
  addCompany: {
    path: '/company-options/add-company',
    name: 'AddCompany',
    // exact: true,
    isPrivate: true,

    component: AddCompany,
    layout: FullScreenLayout,
    isOnboarding: true
  },
  startCompany: {
    path: '/company-options/start-company',
    name: 'AddCompany',
    // exact: true,
    isPrivate: true,

    component: StartCompany,
    layout: FullScreenLayout,
    isOnboarding: true
  },
  kyc: {
    path: '/kyc',
    name: 'KYC',
    exact: true,
    isPrivate: true,

    component: KYC,
    layout: FullScreenLayout,
    isOnboarding: true
  },
  agreementAddress: {
    path: '/agreement-address',
    name: 'AgreementAddress',
    exact: true,
    isPrivate: true,

    component: AgreementAddress,
    layout: FullScreenLayout,
    isOnboarding: true
  },
  onboardingCompleteWelcome: {
    path: '/onboarding-complete-welcome',
    name: 'OnboardingCompleteWelcome',
    exact: true,
    isPrivate: true,
    component: OnboardingCompleteWelcome,
    layout: FullScreenLayout,
    isOnboarding: true
  },
  onboardingMarketplace: {
    path: '/onboarding-marketplace',
    name: 'OnboardingMarketplace',
    exact: true,
    isPrivate: true,
    component: OnboardingMarketplace,
    layout: MarketplaceLayout,
    isOnboarding: true
  },
  accessRouter: {
    path: '/access-router',
    name: 'AccessRouter',
    exact: true,
    isPrivate: true,
    component: AccessRouter,
    layout: FullScreenLayout
  },
  NotFound: {
    path: '*',
    name: 'NotFound',
    exact: true,
    component: NotFound,
    isPrivate: false,
    layout: FullScreenLayout
  }
};

export default routes;
