export default {
  // GET_BILL: 'GET_BILL',
  // GET_BILL_SUCCESS: 'GET_BILL_SUCCESS',
  // GET_BILL_FAILURE: 'GET_BILL_FAILURE',
  GET_BILLS: 'GET_BILLS',
  GET_BILLS_SUCCESS: 'GET_BILLS_SUCCESS',
  GET_BILLS_FAILURE: 'GET_BILLS_FAILURE',
  GET_BILL_SUBSCRIPTIONS: 'GET_BILL_SUBSCRIPTIONS,',
  GET_BILL_SUBSCRIPTIONS_SUCCESS: 'GET_BILL_SUBSCRIPTIONS_SUCCESS,',
  GET_BILL_SUBSCRIPTIONS_FAILURE: 'GET_BILL_SUBSCRIPTIONS_FAILURE,',
  SAVE_BILL_SUBSCRIPTIONS: 'SAVE_BILL_SUBSCRIPTIONS,',
  SAVE_BILL_SUBSCRIPTIONS_SUCCESS: 'SAVE_BILL_SUBSCRIPTIONS_SUCCESS,',
  SAVE_BILL_SUBSCRIPTIONS_FAILURE: 'SAVE_BILL_SUBSCRIPTIONS_FAILURE,',
  UPDATE_BILL_SUBSCRIPTIONS: 'UPDATE_BILL_SUBSCRIPTIONS,',
  UPDATE_BILL_SUBSCRIPTIONS_SUCCESS: 'UPDATE_BILL_SUBSCRIPTIONS_SUCCESS,',
  UPDATE_BILL_SUBSCRIPTIONS_FAILURE: 'UPDATE_BILL_SUBSCRIPTIONS_FAILURE,',
  DELETE_BILL_SUBSCRIPTIONS: 'DELETE_BILL_SUBSCRIPTIONS,',
  DELETE_BILL_SUBSCRIPTIONS_SUCCESS: 'DELETE_BILL_SUBSCRIPTIONS_SUCCESS,',
  DELETE_BILL_SUBSCRIPTIONS_FAILURE: 'DELETE_BILL_SUBSCRIPTIONS_FAILURE,',
  GET_BILL_CATEGORIES: 'GET_BILL_CATEGORIES',
  GET_BILL_CATEGORIES_SUCCESS: 'GET_BILL_CATEGORIES_SUCCESS',
  GET_BILL_CATEGORIES_FAILURE: 'GET_BILLS_CATEGORIES_FAILURE',
  GET_BILL_CORPORATIONS: 'GET_BILL_CORPORATIONS',
  GET_BILL_CORPORATIONS_SUCCESS: 'GET_BILL_CORPORATIONS_SUCCESS',
  GET_BILL_CORPORATIONS_FAILURE: 'GET_BILL_CORPORATIONS_FAILURE',
  CHECK_HAS_BILL: 'CHECK_HAS_BILL,',
  CHECK_HAS_BILL_SUCCESS: 'CHECK_HAS_BILL_SUCCESS,',
  CHECK_HAS_BILL_FAILURE: 'CHECK_HAS_BILL_FAILURE,',
  PAY_BILL: 'PAY_BILL',
  PAY_BILL_SUCCESS: 'PAY_BILL_SUCCESS',
  PAY_BILL_FAILURE: 'PAY_BILL_FAILURE',
  RESET_ALL_BILL: 'RESET_ALL_BILL',
  RESET_GET_BILL: 'RESET_ALL_BILL'
};
