import React, { useEffect, useState } from 'react';
import { BottomModal, Button, Card, Divider, Tag } from 'components';
import { SERVICE_PACKAGE_TYPES, SUBSCRIPTION_TYPES } from 'utils/contants';

import PaymentMethodSelectionTabs from '../paymetMethodSelectionTabs';
import AddNewCard from '../addNewCard';
import SelectCard from '../selectCard';
import SubTitle from '../subTitle';
import { CURRENCIES } from 'views/other/items/enums';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import useGTM from 'utils/hooks/useGTM';
import classNames from 'classnames';
import { useDeviceDetection } from 'utils/hooks';

export default function PaymentModal({ data, onClose, setCardInformations, ...props }) {
  const { name, variantTitle, variants, variantDescription } = data;
  const [selectedVariant, setSelectedVariant] = useState(
    variants?.length === 1 ? variants[0] : null
  );

  const { isMobile } = useDeviceDetection();

  useEffect(() => {
    setSelectedVariant(variants?.length === 1 ? variants[0] : null);
  }, [data]);

  const tabs = [
    {
      title: 'Hesaplar',
      content: <Accounts />,
      disabled: true
    },
    {
      title: 'Kartlar',
      content: (
        <Cards selectedVariant={selectedVariant} setCardInformations={setCardInformations} />
      ),
      disabled: false
    }
  ];

  return (
    <BottomModal handleClose={onClose} {...props} className="bg-gray-50">
      {variants?.length > 1 && selectedVariant && (
        <div className="absolute top-6 left-6">
          <Button
            variant="circle"
            color="gray"
            icon={IconArrowLeft}
            onClick={() => setSelectedVariant(null)}
          />
        </div>
      )}
      <div className="w-full h-full  overflow-y-auto py-20">
        <div className={classNames('mx-auto max-w-[640px]', { 'px-6': isMobile })}>
          {(variants?.length === 1 || selectedVariant) && (
            <Payment name={name} tabs={tabs} variant={selectedVariant} />
          )}
          {variants?.length > 1 && !selectedVariant && (
            <VariantSelection
              data={variants}
              title={variantTitle}
              description={variantDescription}
              onSelected={setSelectedVariant}
            />
          )}
        </div>
      </div>
    </BottomModal>
  );
}

const Payment = ({ name, tabs, variant }) => {
  const { sendGTMEvent } = useGTM();
  useEffect(() => {
    sendGTMEvent({
      event: 'catEvent',
      item_name: name,
      variant_id: variant.id,
      eventName: 'marketplace_virtual_variant'
    });
  }, []);
  return (
    <>
      <h3 className="mb-6 text-2xl">Ödeme Sayfası</h3>
      <Card className="!w-full">
        <ServiceInformation name={name} variant={variant} />
        <Divider className="my-10" />
        <PaymentMethodSelectionTabs tabs={tabs} defaultTab={1} />
      </Card>
    </>
  );
};

const VariantSelection = ({ title, description, data, onSelected }) => {
  return (
    <div className="px-4">
      <h3 className="mb-6 text-2xl">{title}</h3>
      <div className="flex flex-col space-y-4">
        {data.map(({ packageName, tag, price, subscriptionType, currency }, i) => (
          <Card key={i} className="!w-full !cursor-pointer" onClick={() => onSelected(data[i])}>
            <div className="flex justify-between items-baseline">
              <div className="flex-1 flex flex-col gap-y-1">
                <p className="text-xs leading-4 font-semibold text-gray-600">{packageName}</p>
                <p className="text-[22px] font-semibold leading-7">
                  {price} {CURRENCIES[currency]} / {SUBSCRIPTION_TYPES[subscriptionType]}
                </p>
              </div>
              {tag && (
                <Tag size="sm" color="yellow-light">
                  {tag}
                </Tag>
              )}
            </div>
          </Card>
        ))}
        <div className="text-gray-500 text-xs font-medium leading-4">{description}</div>
      </div>
    </div>
  );
};

const ServiceInformation = ({ name, variant }) => {
  const { price, subscriptionType, currency } = variant;
  return (
    <div>
      <SubTitle>Seçilen Ürün/Hizmet</SubTitle>
      <div className="bg-gray-100 border border-gray-200 border-solid w-full rounded-lg px-6 py-3">
        <div className="font-semibold text-xs leading-4 text-gray-600">
          {SERVICE_PACKAGE_TYPES[name].value}
        </div>
        <div className="text-gray-900 text-[22px] leading-7 font-semibold">
          {price} {CURRENCIES[currency]} / {SUBSCRIPTION_TYPES[subscriptionType]}
        </div>
      </div>
    </div>
  );
};

const Accounts = () => {
  return <div>Hesaplar</div>;
};

const Cards = ({ selectedVariant, setCardInformations }) => {
  const [isAddNewCardActive, setIsAddNewCardActive] = useState(false);

  return (
    <div>
      {!isAddNewCardActive && (
        <SelectCard variant={selectedVariant} onClickCreateCard={setIsAddNewCardActive} />
      )}
      {isAddNewCardActive && (
        <AddNewCard
          variant={selectedVariant}
          onGoBack={setIsAddNewCardActive}
          setCardInformations={setCardInformations}
        />
      )}
    </div>
  );
};
