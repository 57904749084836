const initialState = {
  summary: {},
  recurringPayments: []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RECURRING_SUMMARY':
      return {
        ...state,
        summary: action.data
      };
    case 'SET_RECURRING_PAYMENTS':
      return {
        ...state,
        recurringPayments: action.data
      };
    default:
      return state;
  }
};

export default authReducer;
