import React from 'react';
import StepHeader from 'components/stepHeader';
import { useWalletContext } from 'contexts/walletContext';
import { WALLET_TYPES } from 'utils/contants';
import { Button, Card, EmptyView } from 'components';
import { useOpenBakingContext } from '../..';
import { useSelector } from 'react-redux';

//icons
import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';

export default function BankList() {
  const { selectedWalletType } = useWalletContext();
  const { setStep, steps, setFormValues } = useOpenBakingContext();
  const { banks } = useSelector((state) => state.openBanking);

  const onBankSelected = (bank) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      bank
    }));
    setStep(steps.selectBankAccount);
  };

  return (
    <div className="h-full flex-col ">
      <StepHeader
        title="Yeni Hesap Ekle"
        subtitle={`${WALLET_TYPES[selectedWalletType]?.label}`}
      />
      <div className="flex flex-col gap-y-4 max-h-[420px] overflow-y-auto">
        {banks.map((bank) => (
          <Card
            className="flex flex-col gap-y-5 shadow-norma divide-y divide-gray-200 h-full w-full !shadow-none !border !border-gray-100 border-solid p-4"
            key={bank?.id}
          >
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => onBankSelected(bank)}
            >
              <img src={bank?.logoUrl} alt={bank?.name} className="h-full w-32" />
              <IconArrowRight className="stroke-current text-gray-700 w-5 h-5" />
            </div>
          </Card>
        ))}
      </div>
      {banks.length === 0 && (
        <div className="my-20">
          <EmptyView>Banka Entegrasyonunuz bulunmamaktadır</EmptyView>
        </div>
      )}
      <Button className="w-full mt-6" onClick={() => setStep(steps.selectBank)}>
        Yeni Banka Ekle
      </Button>
    </div>
  );
}
