import React, { useContext, useEffect } from 'react';
import { Button, Label, Card } from 'components';
import { ExchangeModalContext } from 'views/transactions/components/exchangeModal';
import PopoverSelect from 'components/popoverSelect';

import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as IconSafe } from 'assets/icons/safe.svg';
import { ReactComponent as IconExchange } from 'assets/icons/exchange.svg';

import { useSelector } from 'react-redux';
import { CURRENCIES } from 'views/other/items/enums';
import { currencyFormat, EXCHANGE_TYPES } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import AmountInput from 'components/AmountInput';
import cn from 'classnames';

export default function ExchangeAccountSelection() {
  const { setSubTitle, nextStep, formValues } = useContext(ExchangeModalContext);

  useEffect(() => {
    setSubTitle('İşlem Yapın');
  }, []);

  return (
    <div className="flex flex-col gap-y-12">
      <AccountSelection key={'AccountSelection'} />
      <ExchangeCalculation key={'ExchangeCalculation'} />
      <Button
        className="w-full mt-4"
        onClick={nextStep}
        tabIndex={0}
        disabled={
          !formValues.wallet ||
          !formValues.targetWallet ||
          !formValues.buyAmount ||
          !formValues.sellAmount
        }
      >
        Devam
      </Button>
    </div>
  );
}

const CustomItemElement = ({ item, onClick, isLast, ...props }) => {
  return (
    <div
      className="text-black cursor-pointer flex gap-x-4 pb-4"
      style={{ borderBottom: isLast ? 'none' : '1px solid #E6E6E6' }}
      onClick={(e) => onClick(e, item)}
      {...props}
    >
      <div className="bg-green-300 rounded-full w-11 h-11 flex items-center justify-center flex-initial">
        <IconSafe className="fill-current stroke-1 text-green-700" />
      </div>
      <div className="flex-1">
        <p className="text-base text-gray-900 font-medium leading-6">{item?.label}</p>
        <p className="text-sm text-gray-900 font-medium leading-5">
          {currencyFormat(item?.balance)} {CURRENCY_NAMES[item?.currencyCode]}{' '}
        </p>
      </div>
    </div>
  );
};
const CustomSelectedItemElement = ({ item, children, ...props }) => {
  return (
    <Card className="flex items-center justify-between w-full gap-x-4" {...props}>
      <div className="bg-green-300 rounded-full w-11 h-11 flex items-center justify-center flex-initial">
        <IconSafe className="fill-current stroke-1 text-green-700" />
      </div>
      <div className="flex-1">
        <p className="text-base text-gray-900 font-medium leading-6">{item?.label}</p>
        <p className="text-sm text-gray-900 font-medium leading-5">
          {currencyFormat(item?.balance)} {CURRENCY_NAMES[item?.currencyCode]}
        </p>
      </div>
      {children}
    </Card>
  );
};

const AccountSelection = () => {
  const accounts = useSelector((state) => state.accounts);
  const { formValues, setFormValues } = useContext(ExchangeModalContext);

  let TurkishLiraAccounts = accounts
    .filter((account) => account.currencyCode === CURRENCIES.TRY)
    .map((account) => ({
      label: account.name,
      value: account.id,
      balance: account?.balance?.balance,
      currencyCode: account.currencyCode
    }));
  let ForeignCurrencyAccounts = accounts
    .filter((account) => account.currencyCode === formValues.currencyCode)
    .map((account) => ({
      label: account.name,
      value: account.id,
      balance: account?.balance?.balance,
      currencyCode: account.currencyCode
    }));

  return (
    <div className="flex items-center w-full gap-x-7">
      {formValues.exchangeType === EXCHANGE_TYPES.SELL && (
        <>
          <Label
            label="Döviz Hesabı Seç"
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <PopoverSelect
              options={ForeignCurrencyAccounts}
              defaultValue={ForeignCurrencyAccounts[0]}
              customItemElement={CustomItemElement}
              customSelectedItem={CustomSelectedItemElement}
              className="w-full"
              panelClassName={'!w-full'}
              onChange={(wallet) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  targetWallet: { ...wallet, id: wallet?.value, name: wallet?.label }
                }))
              }
            />
          </Label>
          <IconArrowRight className="stroke-current text-gray-400 w-4 h-4 mt-7" />
          <Label
            label="TL Hesabı Seç"
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <PopoverSelect
              options={TurkishLiraAccounts}
              defaultValue={TurkishLiraAccounts[0]}
              className="w-full"
              panelClassName={'!w-full'}
              customItemElement={CustomItemElement}
              customSelectedItem={CustomSelectedItemElement}
              onChange={(wallet) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  wallet: {
                    ...wallet,
                    id: wallet?.value,
                    name: wallet?.label
                  }
                }))
              }
            />
          </Label>
        </>
      )}
      {formValues.exchangeType === EXCHANGE_TYPES.BUY && (
        <>
          <Label
            label="TL Hesabı Seç"
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <PopoverSelect
              options={TurkishLiraAccounts}
              defaultValue={TurkishLiraAccounts[0]}
              className="w-full"
              panelClassName={'!w-full'}
              customItemElement={CustomItemElement}
              customSelectedItem={CustomSelectedItemElement}
              onChange={(wallet) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  wallet: {
                    ...wallet,
                    id: wallet?.value,
                    name: wallet?.label
                  }
                }))
              }
            />
          </Label>
          <IconArrowRight className="stroke-current text-gray-400 w-4 h-4 mt-7" />
          <Label
            label="Döviz Hesabı Seç"
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <PopoverSelect
              options={ForeignCurrencyAccounts}
              defaultValue={ForeignCurrencyAccounts[0]}
              customItemElement={CustomItemElement}
              customSelectedItem={CustomSelectedItemElement}
              className="w-full"
              panelClassName={'!w-full'}
              onChange={(wallet) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  targetWallet: { ...wallet, id: wallet?.value, name: wallet?.label }
                }))
              }
            />
          </Label>
        </>
      )}
    </div>
  );
};

const ExchangeCalculationInput = ({ className, onChange, currencyCode, value = 0, ...props }) => {
  const classNames = cn(
    'text-[28px] font-medium px-6 pl-7 pr-14 border border-solid border-gray-200 rounded-xl focus:!bg-white',
    className
  );
  return (
    <div className="relative flex items-center">
      <AmountInput
        inputClassName={classNames}
        decimalSeparator=","
        groupSeparator="."
        value={value}
        onValueChange={onChange}
        {...props}
      />
      {currencyCode && (
        <span className="absolute right-0 px-6 py-7 text-2xl leading-7 font-normal text-gray-600">
          {currencyCode}
        </span>
      )}
    </div>
  );
};

const ExchangeCalculation = () => {
  const { formValues, calculateBuyAmount, calculateSellAmount } =
    useContext(ExchangeModalContext);

  return (
    <div className="flex items-center w-full gap-x-7">
      {formValues.exchangeType === EXCHANGE_TYPES.SELL && (
        <>
          <Label
            label={`${CURRENCY_NAMES[formValues.currencyCode]} Miktar`}
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <ExchangeCalculationInput
              currencyCode={CURRENCY_NAMES[formValues.currencyCode]}
              value={formValues?.buyAmount || 0}
              onChange={calculateSellAmount}
              tabIndex={-1}
              defaultValue={formValues?.buyAmount || 0}
            />
          </Label>
          <IconExchange className="fill-current text-green-500 w-4 h-4 mt-9" />
          <Label
            label="TL Miktar"
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <ExchangeCalculationInput
              currencyCode={CURRENCY_NAMES.TRY}
              value={formValues?.sellAmount || 0}
              onChange={calculateBuyAmount}
              tabIndex={0}
              defaultValue={formValues?.sellAmount || 0}
            />
          </Label>
        </>
      )}
      {formValues.exchangeType === EXCHANGE_TYPES.BUY && (
        <>
          <Label
            label="TL Miktar"
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <ExchangeCalculationInput
              currencyCode={CURRENCY_NAMES.TRY}
              value={formValues?.sellAmount || 0}
              onChange={calculateBuyAmount}
              tabIndex={0}
              defaultValue={formValues?.sellAmount || 0}
            />
          </Label>
          <IconExchange className="fill-current text-green-500 w-4 h-4 mt-9" />
          <Label
            label={`${CURRENCY_NAMES[formValues.currencyCode]} Miktar`}
            className="flex-1"
            textClassName="text-lg !font-semibold leading-6 text-gray-900"
          >
            <ExchangeCalculationInput
              currencyCode={CURRENCY_NAMES[formValues.currencyCode]}
              value={formValues?.buyAmount || 0}
              onChange={calculateSellAmount}
              tabIndex={-1}
              defaultValue={formValues?.buyAmount || 0}
            />
          </Label>
        </>
      )}
    </div>
  );
};
