import React from 'react';
import { Card, ContentContainer } from 'components';
import { currencyFormat } from 'utils';
import PopoverSelect from 'components/popoverSelect';
import { CURRENCY_NAMES } from 'views/invoicing/enums';

export default function Resources({ balance, currency, setCurrency }) {
  const currencies = Object.entries(CURRENCY_NAMES).map(([key, value]) => ({
    label: value,
    value: key
  }));

  return (
    <ContentContainer
      title="Varlıklarım"
      className="mt-14"
      actions={
        <>
          <PopoverSelect
            options={currencies}
            defaultValue={currencies[0]}
            onChange={(c) => setCurrency(c.value)}
          />
        </>
      }
    >
      <Card>
        <div className="flex flex-col gap-y-6">
          <div className="text-base leading-6 font-semibold">Toplam Bakiye</div>
          <div className="text-[40px] font-medium">
            {currencyFormat(balance?.totalBalance)} {currency}
          </div>
        </div>
      </Card>
    </ContentContainer>
  );
}
