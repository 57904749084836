import { CURRENCY_NAMES } from '../salesRecordDetailModal/enums';

const { currencyFormat } = require('utils');

const ExpenseRecordTaxes = ({ document, hasDocumentId }) => {
  const WitholdingTaxes = ({ witholdingTaxes, currencyCode }) => {
    return witholdingTaxes.map((tax) => (
      <div className="flex justify-between items-center mt-4 pl-6">
        <span className="text-xs font-medium text-gray-500">
          {tax?.description} (%{tax?.percent})
        </span>
        <span className="text-xs font-medium text-gray-500">
          {currencyFormat(tax?.taxAmount)} {CURRENCY_NAMES[currencyCode] || ''}
        </span>
      </div>
    ));
  };

  const Taxes = ({ taxes, currencyCode }) => {
    return taxes.map((tax) => (
      <div className="flex justify-between items-center mt-4 pl-6">
        <span className="text-xs font-medium text-gray-500">
          {tax?.name} (%{tax?.percent})
        </span>
        <span className="text-xs font-medium text-gray-500">
          {currencyFormat(tax?.taxAmount)} {CURRENCY_NAMES[currencyCode] || ''}
        </span>
      </div>
    ));
  };

  return (
    <div className="w-full rounded-lg shadow-md bg-gray-50 px-8 py-7 border-b border-r border-l border-solid border-gray-200">
      <div className="flex justify-between items-center">
        <span className="text-sm font-semibold text-gray-900">Toplam Tutar</span>
        <span className="text-sm font-semibold text-gray-900">
          {`
              ${
                document?.financialSummary?.taxExclusiveAmount
                  ? currencyFormat(document?.financialSummary?.taxExclusiveAmount)
                  : ''
              } ${CURRENCY_NAMES[document?.currencyCode] || ''}
              `}
        </span>
      </div>
      <div className="flex justify-between items-center mt-4">
        <span className="text-sm font-semibold text-gray-900">Vergiler (TL)</span>
        {!hasDocumentId && (
          <span className="text-sm font-semibold text-gray-900">
            {`
              ${
                document?.financialSummary?.taxSummary?.taxAmount
                  ? currencyFormat(document?.financialSummary?.taxSummary?.taxAmount)
                  : '-'
              } ${CURRENCY_NAMES[document?.currencyCode] || ''}
              `}
          </span>
        )}
      </div>
      {/* TODO: NOR_3287 WITHOLDING TAXES OBJECT STRUCTURE WILL CHANGE */}
      <WitholdingTaxes
        witholdingTaxes={
          document?.financialSummary?.withHoldingTaxSummary?.taxSubtotalSummaries ||
          document?.financialSummary?.withholdingTaxes?.taxSubtotalSummaries ||
          []
        }
        currencyCode={document?.currencyCode}
      />
      <Taxes
        taxes={document?.financialSummary?.taxSummary?.taxSubtotalSummaries || []}
        currencyCode={document?.currencyCode}
      />
      <div className="flex justify-between items-center mt-5">
        <span className="text-sm font-semibold text-gray-900">Genel Toplam</span>
        <span className="text-sm font-semibold text-gray-900">
          {`
              ${
                document?.financialSummary?.payableAmount
                  ? currencyFormat(document?.financialSummary?.payableAmount)
                  : '-'
              } ${CURRENCY_NAMES[document?.currencyCode] || ''}
              `}
        </span>
      </div>
    </div>
  );
};

export default ExpenseRecordTaxes;
