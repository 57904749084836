import axios from 'axios';
import { cardTypes } from 'store/types';

const {
  CHECK_PREPAID_CARD,
  CHECK_PREPAID_CARD_SUCCESS,
  CHECK_PREPAID_CARD_FAILURE,
  CHECK_PREPAID_CARD_PIN,
  CHECK_PREPAID_CARD_PIN_SUCCESS,
  CHECK_PREPAID_CARD_PIN_FAILURE,
  UPDATE_PREPAID_CARD_PIN,
  UPDATE_PREPAID_CARD_PIN_SUCCESS,
  UPDATE_PREPAID_CARD_PIN_FAILURE,
  ACTIVATE_PREPAID_CARD,
  ACTIVATE_PREPAID_CARD_SUCCESS,
  ACTIVATE_PREPAID_CARD_FAILURE
} = cardTypes;

export const createCardOrder = ({ address, cityId, countryId, districtId }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.post(`/api/companies/${user.companyId}/cards/order`, {
      address,
      cityId,
      countryId,
      districtId
    });
  };
};

export const onboardingDocuments = (payload) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.post(`/api/companies/${user.companyId}/onboarding/documents`, payload);
  };
};

export const getCards = ({ accountId }) => {
  return () => {
    if (!accountId) return Promise.reject();
    return axios.get(`/api/accounts/${accountId}/cards`);
  };
};

export const cardAssociate = ({ accountId, barcodeCode, lastDigitsOfCard, password }) => {
  return () => {
    return axios.post(`/api/accounts/${accountId}/cards/associate`, {
      barcodeCode,
      lastDigitsOfCard,
      password
    });
  };
};

export const closeCard = ({ accountId, cardId, password }) => {
  return () => {
    return axios.post(`/api/accounts/${accountId}/cards/${cardId}/close`, { password });
  };
};

export const changeCardPassword = ({ accountId, cardId, password }) => {
  return () => {
    return axios.post(`/api/accounts/${accountId}/cards/${cardId}/password`, { password });
  };
};

export const createPrepaidCard = ({ walletId, payload }) => {
  return () => {
    if (!walletId) return Promise.reject();
    return axios.post(`/api/wallets/${walletId}/prepaid-cards`, payload);
  };
};

export const getPrepaidCard = ({ walletId, cardId, masked = false }) => {
  return () => {
    if (!walletId || !cardId) return Promise.reject();
    return axios.get(`/api/wallets/${walletId}/prepaid-cards/${cardId}?masked=${masked}`);
  };
};

export const closePrepaidCard = ({ accountId, cardId, password }) => {
  return () => {
    return axios.put(`/api/wallets/${accountId}/prepaid-cards/${cardId}/close`, { password });
  };
};

export const updatePrepaidCardPin = ({ walletId, cardId, cardPin, mfaToken, otpCode }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: UPDATE_PREPAID_CARD_PIN_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: UPDATE_PREPAID_CARD_PIN
      });

      const response = await axios.put(`/api/wallets/${walletId}/prepaid-cards/${cardId}/pin`, {
        cardPin,
        mfaToken,
        otpCode
      });

      if (response.status === 200) {
        dispatch({
          type: UPDATE_PREPAID_CARD_PIN_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PREPAID_CARD_PIN_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const checkPrepaidCard = ({ walletId, cardId }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: CHECK_PREPAID_CARD_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: CHECK_PREPAID_CARD
      });

      const response = await axios.post(`api/wallets/${walletId}/prepaid-cards/${cardId}/check`);

      if (response.status === 200) {
        dispatch({
          type: CHECK_PREPAID_CARD_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CHECK_PREPAID_CARD_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const checkPrepaidCardPin = ({ walletId, cardId, pin }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: CHECK_PREPAID_CARD_PIN_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: CHECK_PREPAID_CARD_PIN
      });

      const response = await axios.post(
        `api/wallets/${walletId}/prepaid-cards/${cardId}/pin/check`,
        {
          pin
        }
      );

      if (response.status === 200) {
        dispatch({
          type: CHECK_PREPAID_CARD_PIN_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CHECK_PREPAID_CARD_PIN_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const activatePrepaidCard = ({
  walletId,
  cardId,
  pin,
  mfaToken,
  otpToken,
  cardNumber
}) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: ACTIVATE_PREPAID_CARD_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: ACTIVATE_PREPAID_CARD
      });

      const response = await axios.post(
        `/api/wallets/${walletId}/prepaid-cards/${cardId}/activate`,
        {
          pin,
          mfaToken,
          otpToken,
          cardNumber
        }
      );

      if (response.status === 200) {
        dispatch({
          type: ACTIVATE_PREPAID_CARD_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: ACTIVATE_PREPAID_CARD_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
