import React from 'react';
import { CustomInput, DropdownSelect, Label, DatePicker } from 'components';
import { useSmartOperationContext } from 'views/transactions/subPages/smartOperations';
import { AUTOMATIC_TRANSACTION_REPEAT_TYPES } from 'utils';
import moment from 'moment';
import { useSelector } from 'react-redux';

function RecurringDateSelection({
  onChangeRepeatCount = () => {},
  onChangeRepeatType = () => {},
  onChangePaymentStartDate = () => {},
  onChangeTransferDay = () => {},
  repeatTypes = []
}) {
  const { automaticTransactionFormValues } = useSmartOperationContext();

  return (
    <>
      <div className="flex gap-x-4 items-center w-full">
        <Label label="Tekrarla" dontFocus>
          <CustomInput
            only="number"
            defaultValue={1}
            maxLength={2}
            className="w-[65px]"
            onChange={onChangeRepeatCount}
            value={automaticTransactionFormValues?.repeatCount}
            disabled={
              automaticTransactionFormValues.repeatType?.value ===
              AUTOMATIC_TRANSACTION_REPEAT_TYPES.ONCE.value
            }
          />
        </Label>
        <DropdownSelect
          className="flex-1 max-w-[200px] mt-7"
          options={repeatTypes}
          defaultValue={automaticTransactionFormValues.repeatType?.value}
          value={automaticTransactionFormValues.repeatType}
          onChange={onChangeRepeatType}
        />
        <Label label="Transfer Başlangıç Tarihi" className="flex-1" dontFocus>
          <DatePicker
            className="font-medium h-[52px]"
            selected={automaticTransactionFormValues?.paymentStartDate}
            minDate={new Date(moment().add(1, 'days'))}
            onChange={onChangePaymentStartDate}
            isFormInput
          />
        </Label>

        <TransferDay onChangeTransferDay={onChangeTransferDay} />
      </div>
    </>
  );
}

const TransferDay = ({ onChangeTransferDay = () => {} }) => {
  const { automaticTransactionFormValues } = useSmartOperationContext();
  const { daysOfWeek, daysOfMonth } = useSelector((state) => state.enums);

  const DAYS_OF_WEEK_MAP =
    daysOfWeek?.map((item) => ({
      label: item.detail,
      value: item.value
    })) || [];

  const DAYS_OF_MONTH_MAP =
    daysOfMonth?.map((item) => ({
      label: item.detail,
      value: item.value
    })) || [];

  const isWeekly =
    AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value ===
    automaticTransactionFormValues.repeatType?.value;

  if (
    [
      AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value,
      AUTOMATIC_TRANSACTION_REPEAT_TYPES.MONTHLY.value
    ].includes(automaticTransactionFormValues.repeatType?.value)
  ) {
    return (
      <Label label="Transfer Günü" dontFocus className="flex-1">
        <DropdownSelect
          options={isWeekly ? DAYS_OF_WEEK_MAP : DAYS_OF_MONTH_MAP}
          placeholder=""
          defaultValue={automaticTransactionFormValues.transferDay}
          value={automaticTransactionFormValues.transferDay}
          onChange={onChangeTransferDay}
        />
      </Label>
    );
  }

  return <></>;
};

export default React.memo(RecurringDateSelection);
