import { CustomButton, Popup } from 'components';

import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';

const CanNotDeletePopup = ({ active, toggle }) => (
  <Popup active={active} style={{ height: 'auto' }}>
    <div className="w-full h-full flex flex-col items-center text-center">
      <span className="w-20 h-20 rounded-full bg-yellow-100 flex items-center justify-center">
        <InfoCircleIcon className="fill-current text-yellow-600 w-10" />
      </span>
      <span className="font-semibold text-gray-900 text-lg mt-6">Bu Kategori Silinemez</span>
      <span className="font-medium text-gray-500 text-base mt-3">
        Bir fatura ile ilişkilendirilmiş kategori silinemez
      </span>
      <CustomButton
        color="success"
        className="w-full mt-4"
        onClick={toggle}
        data-testid="button_close_can_not_delete_popup"
      >
        Tamam
      </CustomButton>
    </div>
  </Popup>
);

export default CanNotDeletePopup;
