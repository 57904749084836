import { useState } from 'react';

import { ReactComponent as CaretRightIcon } from 'assets/icons/caret-right.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as TransactionList } from 'assets/illustrations/accounts/transaction-list.svg';

import useModal from 'utils/hooks/useModal';
import useDengage from 'utils/hooks/useDengage';

import CreateExpenseModal from '../../components/createExpenseModal';

import { currencyFormat, MONTHS, E_TRANSFORMATION_TYPES } from 'utils';

import { useEffect } from 'react';
import { getInvoices } from 'store/actions/eTransformation';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CURRENCY_NAMES } from '../../components/salesRecordDetailModal/enums';
import { CustomButton } from 'components';
import AcceptRejectInvoiceModal from 'views/invoicing/components/acceptRejectInvoiceModal';
import { useRouter } from 'utils/hooks/useRouter';

const InvoiceColumn = ({ invoice, onSelect }) => {
  const date = invoice ? new Date(invoice?.issueDate) : null;
  return (
    <div
      className="w-full rounded-xl border border-solid border-gray-100 bg-gray-50 py-4 px-5 flex items-center justify-between hover:bg-white hover:shadow-lg transition cursor-pointer group"
      onClick={() => !!onSelect && onSelect(invoice)}
      data-testid="section_invoice_column"
    >
      <div
        className="flex flex-col items-center justify-center border-r border-solid border-gray-200 pr-6"
        data-testid="section_invoice_column_date"
      >
        <span
          className="text-xl font-medium text-gray-600"
          data-testid="label_invoice_column_full_date"
        >
          {date?.getDate()}
        </span>
        <span
          className="text-xs font-medium text-gray-500"
          data-testid="label_invoice_column_month_year"
        >
          {MONTHS[date?.getMonth()].toString().slice(0, 3)} {date?.getFullYear()}
        </span>
      </div>
      <span
        className="px-6 text-gray-700 font-semibold w-72"
        data-testid="section_invoice_column_sender_title"
      >
        {invoice?.senderTitle || '-'}
      </span>
      <div className="flex flex-col">
        <span
          className="text-sm font-normal text-gray-400"
          data-testid="section_invoice_column_id_number"
        >
          VKN / TCKN
        </span>
        <span
          className="text-sm font-semibold text-gray-700"
          data-testid="section_invoice_column_tax"
        >
          {invoice?.receiverTaxId || '-'}
        </span>
      </div>
      <span
        className="group-hover:text-red-400 ml-auto text-lg font-semibold text-gray-900"
        data-testid="section_invoice_column_amount"
      >
        - {currencyFormat(invoice?.payableAmount)} {CURRENCY_NAMES[invoice?.currencyCode]}
      </span>
      <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center ml-10">
        <CaretRightIcon className="stroke-current w-5 text-gray-500" />
      </div>
    </div>
  );
};

const NoInvoice = () => (
  <div className="flex flex-col items-center my-14" data-testid="section_no_invoice">
    <TransactionList className="w-20" />
    <div className="flex items-center justify-center gap-x-2 rounded-full px-4 py-2 bg-gray-100">
      <InfoCircleIcon className="fill-current w-4 text-gray-500" />
      <span className="text-sm font-medium text-gray-500">Henüz fatura eklenmemiş</span>
    </div>
  </div>
);

const CreateExpenseRecordProductService = () => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { query } = useRouter();
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [responseNeededInvoicesSize, setResponseNeededInvoicesSize] = useState(10);
  const [noResponseNeededInvoicesSize, setNoResponseNeededInvoicesSize] = useState(10);

  const { noResponseNeededInvoices, responseNeededInvoices } = useSelector(
    (state) => state.eTransformation
  );

  const { user, etransformationType } = useSelector((state) => state.auth);

  const [isCreateExpenseModalActive, , toggleCreateExpenseModal] = useModal({
    handleClose: () => setSelectedInvoice(null),
    shouldBeBlockScroll: true
  });
  const [isAcceptRejectInvoiceModalActive, , toggleAcceptRejectInvoiceModal] = useModal({
    handleClose: () => setSelectedInvoice(null)
  });

  function handleAcceptReject(invoice) {
    setSelectedInvoice(invoice);
    toggleAcceptRejectInvoiceModal();
  }

  function handleCreateWithInvoice(invoice) {
    setSelectedInvoice(invoice);
    toggleCreateExpenseModal();
  }

  useEffect(() => {
    if (query.contactId) {
      toggleCreateExpenseModal();
    }
  }, [query?.contactId]);

  useEffect(() => {
    let requestParams = {
      direction: 'INCOMING',
      archived: false,
      page: 0,
      size: noResponseNeededInvoicesSize,
      orderBy: 'issueDate',
      orderDirection: 'DESC'
    };

    if (etransformationType !== E_TRANSFORMATION_TYPES.E_RECEIPT) {
      requestParams = {
        ...requestParams,
        'app-response-needed': false
      };
    }

    dispatch(getInvoices(requestParams));
  }, [
    user,
    isCreateExpenseModalActive,
    isAcceptRejectInvoiceModalActive,
    noResponseNeededInvoicesSize
  ]);

  useEffect(() => {
    if (etransformationType !== E_TRANSFORMATION_TYPES.E_RECEIPT) {
      dispatch(
        getInvoices({
          direction: 'INCOMING',
          'app-response-needed': true,
          page: 0,
          size: responseNeededInvoicesSize,
          orderBy: 'issueDate',
          orderDirection: 'DESC'
        })
      );
    }
  }, [
    user,
    isCreateExpenseModalActive,
    isAcceptRejectInvoiceModalActive,
    responseNeededInvoicesSize
  ]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_e_invoices' });
  }, []);

  return (
    <div className="relative bg-gray-200 flex justify-center py-12 w-full h-auto min-h-screen">
      {isCreateExpenseModalActive && (
        <CreateExpenseModal
          handleClose={toggleCreateExpenseModal}
          defaultInvoice={selectedInvoice}
        />
      )}
      {isAcceptRejectInvoiceModalActive && (
        <AcceptRejectInvoiceModal
          handleClose={toggleAcceptRejectInvoiceModal}
          invoices={responseNeededInvoices.content}
          defaultInvoice={selectedInvoice}
          handleCreateWithInvoice={handleCreateWithInvoice}
        />
      )}
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative w-11/12 xl:w-3/4">
        <div className="flex flex-col mt-8">
          <div className="flex justify-between items-center">
            <span className="font-semibold text-xl text-white">
              Ürün/Hizmet Alım Kaydı Oluştur
            </span>
            <CustomButton
              color="success"
              className="flex gap-x-2 items-center"
              onClick={toggleCreateExpenseModal}
              button="button_add_expense_modal"
            >
              <IconPlus className="stroke-current w-5 text-green-900" />
              <span>Gider Kaydı Ekle</span>
            </CustomButton>
          </div>
          <div className="w-full rounded-xl bg-white shadow mt-6 px-12 py-6">
            <div className="mt-12" data-testid="section_waiting_expenses">
              <span className="text-lg font-semibold text-gray-600">
                Onay Bekleyen Faturalardan Seç
              </span>
              <div className="mt-6 flex flex-col gap-y-4">
                {responseNeededInvoices.content.length ? (
                  <>
                    {responseNeededInvoices.content.map((i) => (
                      <InvoiceColumn invoice={i} onSelect={handleAcceptReject} />
                    ))}
                    {responseNeededInvoices.totalElements > responseNeededInvoices.size && (
                      <CustomButton
                        color="primary"
                        className="mx-auto"
                        onClick={() => setResponseNeededInvoicesSize((size) => size + 10)}
                        data-testid="button_load_more_response_needed_invoices"
                      >
                        Daha Fazla Göster
                      </CustomButton>
                    )}
                  </>
                ) : (
                  <NoInvoice />
                )}
              </div>
            </div>
            <span className="text-lg font-semibold text-gray-600">
              Onaylanmış Faturalarından Seç
            </span>
            <div className="mt-6 flex flex-col gap-y-4">
              {noResponseNeededInvoices.content.length ? (
                <>
                  {noResponseNeededInvoices.content.map((i) => (
                    <InvoiceColumn
                      key={i?.documentId}
                      invoice={i}
                      onSelect={handleCreateWithInvoice}
                    />
                  ))}
                  {noResponseNeededInvoices.totalElements > noResponseNeededInvoices.size && (
                    <CustomButton
                      color="primary"
                      className="mx-auto"
                      onClick={() => setNoResponseNeededInvoicesSize((size) => size + 10)}
                      data-testid="button_load_more_invoices"
                    >
                      Daha Fazla Göster
                    </CustomButton>
                  )}
                </>
              ) : (
                <NoInvoice />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateExpenseRecordProductService;
