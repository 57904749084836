import React, { useState } from 'react';
import {
  SideModal,
  Button,
  CustomInput,
  Label,
  SelectedAccountView,
  DatePicker
} from 'components';
import { Prompt } from 'components/promptModal';
import useModal from 'utils/hooks/useModal';
import { ReactComponent as IconDelete } from 'assets/icons/trash.svg';
import { useDispatch } from 'react-redux';
import { useSmartOperationContext } from '../..';
import { useSelector } from 'react-redux';
import {
  deleteAutomaticTransaction,
  getAutomaticTransaction,
  getAutomaticTransactions,
  updateAutomaticTransaction
} from 'store/actions/automatic';
import AmountInput from 'components/AmountInput';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import {
  AUTOMATIC_TRANSACTION_RECEIVER_TYPES,
  AUTOMATIC_TRANSACTION_REPEAT_TYPES,
  getMaskedIban,
  getMaskedNormaNo
} from 'utils';
import { ReactComponent as IconSafe } from 'assets/icons/safe.svg';

export default function AutomaticTransactionEditSideModal({ onClose }) {
  const { setStep, toggleAutomaticTransactionEditModal } = useSmartOperationContext();

  const { automaticTransaction } = useSelector((state) => state.automaticTransaction);
  const accounts = useSelector((state) => state.accounts);

  const [recordName, setRecordName] = useState(automaticTransaction?.recordName);
  const [amount, setAmount] = useState(automaticTransaction?.amount);
  const { daysOfWeek, daysOfMonth } = useSelector((state) => state.enums);
  const wallet = useSelector((state) => state.accounts).find(
    (wallet) => wallet?.id == automaticTransaction?.senderWalletId
  );

  const [isDeleteAutomaticTransactionPromptActive, , toggleDeleteAutomaticTransactionPrompt] =
    useModal();

  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(
      updateAutomaticTransaction(automaticTransaction?.automaticTransactionId, {
        amount,
        recordName
      })
    ).then(() => {
      dispatch(getAutomaticTransaction(automaticTransaction?.automaticTransactionId));
      dispatch(getAutomaticTransactions());
      onClose();
    });
  };

  const onDeleteApproved = () => {
    dispatch(deleteAutomaticTransaction(automaticTransaction?.automaticTransactionId)).then(
      () => {
        toggleDeleteAutomaticTransactionPrompt();
        toggleAutomaticTransactionEditModal();
        setStep(1);
      }
    );
  };

  const onChangeRecordName = (e) => {
    setRecordName(e.target.value);
  };

  const getTransferDayText = (value) => {
    const isWeekly =
      AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value === automaticTransaction?.repeatType;

    return isWeekly
      ? daysOfWeek?.find((week) => week.value === value)?.detail
      : daysOfMonth?.find((month) => month.value === value)?.detail;
  };

  const isFormValid =
    recordName && recordName.length > 2 && recordName.length <= 32 && amount && amount > 0;
  return (
    <SideModal handleClose={onClose}>
      <Prompt
        isActive={isDeleteAutomaticTransactionPromptActive}
        className="max-w-xs mx-auto"
        title={
          <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-100 mx-auto">
            <IconDelete className="fill-current w-10 h-10 text-red-600" />
          </div>
        }
        description={
          <div className="flex flex-col gap-y-4">
            <p className="!text-base !leading-6 !text-gray-900 font-semibold">
              “{automaticTransaction.recordName}” isimli kaydınızı silmek istiyor musunuz?
            </p>
            <p className="text-gray-500">Bu İşlem geri alınamaz.</p>
          </div>
        }
        okText="Sil"
        cancelText="Vazgeç"
        okButtonColor="red"
        cancelButtonColor="primary"
        onOK={onDeleteApproved}
        onCancel={toggleDeleteAutomaticTransactionPrompt}
      />
      <div className="flex flex-col justify-between h-full pb-10">
        <div>
          <p className="font-semibold text-[28px] leading-8 mb-8">Düzenle</p>
          <div className="flex flex-col gap-y-4">
            <CustomInput
              label="Kayıt İsmi"
              defaultValue={recordName}
              value={recordName}
              maxLength={32}
              onChange={onChangeRecordName}
            />
            <AmountInput
              label="Tutar"
              value={amount}
              suffix={` ${CURRENCY_NAMES[automaticTransaction?.currencyCode]}`}
              onValueChange={setAmount}
            />

            {automaticTransaction.receiverType !==
              AUTOMATIC_TRANSACTION_RECEIVER_TYPES.OWN_ACCOUNT && (
              <CustomInput
                label="Alıcı İsim Soyisim / Unvan"
                value={automaticTransaction?.receiverFullName}
                disabled
              />
            )}
            {automaticTransaction.receiverType ===
            AUTOMATIC_TRANSACTION_RECEIVER_TYPES.OWN_ACCOUNT ? (
              <>
                <Label label="Alıcı Hesap">
                  <SelectedAccountView
                    name={
                      accounts.find(
                        (account) =>
                          account.number === automaticTransaction?.receiverWalletNumber ||
                          account?.iban === automaticTransaction?.receiverIban
                      ).name
                    }
                    amount={automaticTransaction?.amount}
                    customIcon={<IconSafe />}
                    verticalView
                    disabled
                  />
                </Label>
                <CustomInput
                  label="Norma No"
                  defaultValue={getMaskedNormaNo(automaticTransaction?.receiverWalletNumber)}
                  disabled
                />
              </>
            ) : (
              <CustomInput
                label="Alıcı IBAN"
                defaultValue={getMaskedIban(automaticTransaction?.receiverIban)}
                disabled
              />
            )}

            <div className="flex items-center gap-x-4">
              <CustomInput
                className="w-14"
                label="Tekrarla"
                value={automaticTransaction?.repeatCount}
                disabled
              />
              <CustomInput
                className="w-full mt-6"
                value={
                  AUTOMATIC_TRANSACTION_REPEAT_TYPES[automaticTransaction?.repeatType]?.label
                }
                disabled
              />
            </div>
            <DatePicker
              label="Transfer Başlangıç Tarihi"
              className="font-semibold"
              isFormInput
              disabled={true}
              selected={new Date(automaticTransaction?.paymentStartDate)}
              data-testid="input_filters_modal_start_date"
            />
            {[
              AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value,
              AUTOMATIC_TRANSACTION_REPEAT_TYPES.MONTHLY.value
            ].includes(automaticTransaction?.repeatType) && (
              <CustomInput
                label="Transfer Günü"
                defaultValue={getTransferDayText(automaticTransaction?.transferDay)}
                disabled
              />
            )}
            <Label
              label={
                automaticTransaction.receiverType ===
                AUTOMATIC_TRANSACTION_RECEIVER_TYPES.OWN_ACCOUNT
                  ? 'Gönderen Hesap'
                  : 'Otomatik Ödeme Yapılacak Hesap'
              }
            >
              <SelectedAccountView
                name={wallet?.name}
                amount={wallet?.balance?.balance}
                customIcon={<IconSafe />}
                verticalView
                disabled
              />
            </Label>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 mt-12">
          <Button onClick={onSave} disabled={!isFormValid}>
            Kaydet
          </Button>
          <Button color="red" onClick={toggleDeleteAutomaticTransactionPrompt}>
            Sil
          </Button>
        </div>
      </div>
    </SideModal>
  );
}
