import classNames from 'classnames';

export default function BusinessTypeItem({ item, onClick, isLast, ...props }) {
  const itemClassName = classNames({
    'cursor-pointer px-2 pt-4 pb-4 text-sm font-normal text-base flex gap-x-2 items-center': true,
    'pb-6 mb-[7px] border-solid border-b border-gray-100': !isLast
  });
  const iconClassName = classNames('w-[20px] h-[20px]');
  const labelClassName = classNames('w-full');

  return (
    <div className={itemClassName} onClick={(e) => onClick(e, item)} {...props}>
      {item?.icon && (
        <span className={iconClassName}>
          {typeof item?.icon === 'string' ? (
            <img src={item?.icon} alt={item?.label} width={20} height={20} />
          ) : (
            item?.icon
          )}
        </span>
      )}
      <span className={labelClassName}>{item.label}</span>
    </div>
  );
}
