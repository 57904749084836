import { Tab } from 'components';
import React from 'react';
import AutoBillPaymentList from '../autoBillPaymentList';
import AutomaticTransactionList from '../automaticTransactionList';

export default function Operationtabs({ onChange }) {
  const tabs = [
    { title: 'Para Transferleri', component: <AutomaticTransactionList /> },
    { title: 'Faturalar', component: <AutoBillPaymentList /> }
  ];

  return (
    <div>
      <Tab tabs={tabs} onChange={onChange} defaultIndex={0} tabsClassName="mb-10" />
    </div>
  );
}
