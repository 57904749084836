import { useEffect } from 'react';
import { Button } from 'components';
import DoubleCheck from 'assets/illustrations/card/double-check.png';
import useKeyPress from 'utils/hooks/useKeyPress';
import FullModal from 'views/company-options/FullModal';
import { useRouter } from 'utils/hooks/useRouter';
import useDengage from 'utils/hooks/useDengage';

export default function SuccessBottomModal({ handleClose, success }) {
  const { history, push } = useRouter();
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'add_company_activity_completed' });
  }, []);

  useKeyPress((e) => {
    if (e.key !== 'Enter') return;

    success();
  });

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        push(location.pathname + location.search);
      }
    });
  }, []);
  return (
    <FullModal
      handleClose={handleClose}
      className="bg-green-500"
      data-testid="modal_success_add_company"
    >
      <div className="w-full h-full flex flex-col items-center justify-center gap-y-6">
        <img src={DoubleCheck} alt="double-check" width="100" />
        <span className="text-3xl text-white" data-testid="label_success_add_company">
          Şirket Bilgilerinizi Kaydettik
        </span>
        <Button
          className="max-w-[400px] w-full"
          onClick={success}
          data-testid="button_success_add_company"
        >
          Devam
        </Button>
      </div>
    </FullModal>
  );
}
