import { TabsCard } from 'components';

import Contacts from './contacts';
import Items from './items';
import Categories from './categories';
import ContactContextProvider from 'contexts/contactContext';
import ItemContextProvider from 'contexts/itemContext';
import { ReactComponent as IconOther } from 'assets/icons/other.svg';

const tabs = [
  {
    title: 'Müşteri/Tedarikçiler',
    page: (
      <ContactContextProvider>
        <Contacts />
      </ContactContextProvider>
    ),
    route: '/other'
  },
  {
    title: 'Ürünler',
    page: (
      <ItemContextProvider>
        <Items />
      </ItemContextProvider>
    ),
    route: '/other/items'
  },
  {
    title: 'Kategoriler',
    page: <Categories />,
    route: '/other/categories'
  }
];

const Other = () => (
  <div className="relative bg-gray-200 flex justify-center py-12 w-full h-auto min-h-screen">
    <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
    <div className="relative w-11/12 xl:w-3/4 pb-6">
      <div className="flex items-center  w-full relative gap-x-2">
        <IconOther className="fill-current w-8 h-auto ml-4 text-green-500" />
        <span className="font-semibold text-[22px] text-white">Diğer</span>
      </div>
      <TabsCard tabs={tabs} className="mt-6" />
    </div>
  </div>
);

export default Other;
