import { useEffect, useState, useContext } from 'react';
import useDengage from 'utils/hooks/useDengage';
import { Button, CodeInput, CustomButton } from 'components';

import StepHeader from 'components/stepHeader';
import { ChangeCardPasswordContext } from '../..';
import { useSelector, useDispatch } from 'react-redux';
import { checkPrepaidCardPin, updatePrepaidCardPin } from 'store/actions/card';
import { useCountdownTimer } from 'utils/hooks';
import { OTP_TIMEOUT } from 'utils';

export default function ConfirmSmsToken() {
  const { publishEvent, eventTypes } = useDengage();
  const [otpCode, setOtpCode] = useState('');

  const { user } = useSelector((state) => state.auth);
  const { checkCardPinData } = useSelector((state) => state.card);

  const { isCounting, timeByMinute, restart } = useCountdownTimer(OTP_TIMEOUT, true, () => {});

  const dispatch = useDispatch();

  const { walletId, cardId, cardPin, nextStep } = useContext(ChangeCardPasswordContext);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_card_password_2' });
  }, []);

  const onSubmitOtpToken = () => {
    dispatch(
      updatePrepaidCardPin({
        walletId,
        cardId,
        cardPin,
        otpCode,
        mfaToken: checkCardPinData?.mfaToken
      })
    ).then(() => {
      nextStep();
    });
  };

  const onRenewSMSToken = () => {
    dispatch(
      checkPrepaidCardPin({
        walletId,
        cardId,
        pin: cardPin
      })
    ).then(() => {
      restart();
    });
  };

  return (
    <div className="flex flex-col">
      <StepHeader
        title="Şifresi Değiştir"
        subtitle={`${user?.fullPhoneNumber} numaranıza gelen 4 haneli kodu girin`}
        data-testid="label_modal_change_password_again"
      />
      <CodeInput
        length={4}
        invisible
        onChange={setOtpCode}
        className="mt-4"
        only="number"
        data-testid="input_modal_change_password_again"
        blockPaste
        autofocus
      />
      <div className="flex flex-col items-start gap-x-4">
        <p className="text-gray-700 font-medium leading-6">SMS ulaşmadı mı?</p>
        {isCounting && <p className="text-green-500 leading-6 font-semibold">{timeByMinute} </p>}
        {!isCounting && (
          <Button variant="ghost" className="!p-0" onClick={onRenewSMSToken}>
            Tekrar Gönder
          </Button>
        )}
      </div>
      <CustomButton
        className="mt-6 w-96"
        color="success"
        disabled={otpCode?.length !== 4}
        data-testid="button_modal_change_password_again"
        onClick={onSubmitOtpToken}
      >
        Onayla
      </CustomButton>
    </div>
  );
}
