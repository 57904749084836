import { CategoryBadge } from 'components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

const CategoriesTable = ({ categories, toggleEditCategoryModal }) => {
  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="text-left text-sm font-semibold text-gray-700 border-b border-solid border-gray-100">
          <th className="py-4">Kategori</th>
          <th className="py-4 text-right">Toplam İşlem Sayısı</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((category, i) => (
          <tr
            key={i}
            className={`border-b border-solid border-gray-100 hover:bg-gray-50 group transition group ${
              category.saveType !== 'DEFAULT_SYSTEM' ? 'cursor-pointer' : ''
            }`}
            onClick={() =>
              category.saveType !== 'DEFAULT_SYSTEM' && toggleEditCategoryModal(category.id)
            }
          >
            <td className="flex items-center gap-x-4 py-6">
              <CategoryBadge
                textColorHexCode={category?.textColorHexCode}
                colorHexCode={category?.colorHexCode}
                iconUrl={category?.iconUrl}
                name={category?.name}
              />
            </td>
            <td className="py-6 text-sm font-semibold text-gray-700">
              {!!category.count && (
                <div className="flex gap-x-1 items-center w-min ml-auto">
                  {/* <span>{category.count}</span> */}
                  <ArrowRightIcon className="stroke-current w-4 text-gray-300 group-hover:text-green-500 -rotate-45" />
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CategoriesTable;
