import React, { useEffect } from 'react';
import { Card } from 'components';

import { getContacts, getLastContacts } from 'store/actions/contacts';
import { useDispatch, useSelector } from 'react-redux';
import MoneyTransferModal from './MoneyTransferModal';

import CollectionModal from './components/collectionModal';
import useDengage from 'utils/hooks/useDengage';

import {
  getRecurringPayments,
  getRecurringPaymentSummary
} from 'store/actions/recurringPayments';
import useModal from 'utils/hooks/useModal';

// Illustrations
import AddBalance from 'assets/illustrations/add-balance.png';
import { ReactComponent as IconExchange } from 'assets/icons/exchange-colored.svg';
import Insert from 'assets/icons/insert-colored.svg';
import { ReactComponent as IconRefresh } from 'assets/icons/refresh-colored.svg';
import { ReactComponent as IconTransactions } from 'assets/icons/transactions.svg';
import IconShowAll from 'assets/icons/show-all.svg';

import IconBankNote from 'assets/icons/bank-note.svg';
import CollectionWarningPopup from './components/collectionWarningPopup';
import { COLLECTION_WARNING_POPUP_ID } from 'utils/contants';
import { getBanks } from 'store/actions/bankInformations';
import { CardButton, ExchangeModal } from './components';
import { useRouter } from 'utils/hooks/useRouter';
import { Route, Switch } from 'react-router-dom';
import { Bills, SmartOperations, Transaction, BusinessLoan } from './subPages';
import NotFound from 'views/misc/NotFound';
import routes from 'router';
import { useContactContext } from 'contexts/contactContext';

const Transactions = () => {
  const dispatch = useDispatch();

  const { match, push } = useRouter();
  const { user } = useSelector((state) => state.auth);
  const { query, location } = useRouter();

  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'transactions' }), []);

  useEffect(() => {
    if (!localStorage.getItem(COLLECTION_WARNING_POPUP_ID))
      localStorage.setItem(COLLECTION_WARNING_POPUP_ID, true);

    if (query?.currencyCode) {
      toggleExchangeModal();
    }
    if (location?.state?.exchangeModalActive) {
      toggleExchangeModal();
    }
  }, []);

  useEffect(() => {
    dispatch(getContacts({ isExpress: true }));
    dispatch(getLastContacts());
    dispatch(getRecurringPaymentSummary());
    dispatch(getRecurringPayments());
  }, [user]);

  const { selectedContact, setSelectedContact } = useContactContext();
  const [isMoneyTransferModalActive, , toggleMoneyTransferModal] = useModal({
    shouldBeBlockScroll: true,
    handleClose: () => {
      setSelectedContact(null);
    }
  });

  const [isCollectionModalActive, , toggleCollectionModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isExchangeModalActive, , toggleExchangeModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [
    isCollectionWarningPopupActive,
    setIsCollectionWarningPopupActive,
    toggleCollectionWarningPopup
  ] = useModal({
    shouldBeBlockScroll: true
  });

  const onToggleCollectionModal = () => {
    dispatch(getBanks());

    const isCollectionModalPopupVisibilityAvailable = JSON.parse(
      localStorage.getItem(COLLECTION_WARNING_POPUP_ID)
    );

    if (isCollectionModalPopupVisibilityAvailable) return setIsCollectionWarningPopupActive(true);

    toggleCollectionModal();
  };

  const onCollectionWarningPopupClose = () => {
    toggleCollectionWarningPopup();
    toggleCollectionModal();
  };

  const onExchangeModalClose = () => {
    toggleExchangeModal();
    if (location?.state?.accountIndex)
      push(routes.accounts.path, {
        accountIndex: location?.state?.accountIndex
      });
  };

  const handleModalDefaultContact = (contact) => {
    setSelectedContact(contact);
    toggleMoneyTransferModal();
  };

  return (
    <div className="relative bg-gray-200 flex justify-center py-12 w-full min-h-screen">
      {isMoneyTransferModalActive && (
        <MoneyTransferModal
          handleClose={toggleMoneyTransferModal}
          defaultContactId={selectedContact?.id}
        />
      )}
      {isExchangeModalActive && <ExchangeModal onClose={onExchangeModalClose} />}

      {isCollectionModalActive && (
        <CollectionModal isActive={isCollectionModalActive} onClose={toggleCollectionModal} />
      )}
      {isCollectionWarningPopupActive && (
        <CollectionWarningPopup
          isActive={isCollectionWarningPopupActive}
          onClose={onCollectionWarningPopupClose}
        />
      )}
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative w-11/12 xl:w-3/4">
        <div className="w-full flex flex-col items-start ">
          <div className="flex items-center  w-full relative gap-x-2">
            <IconTransactions className="fill-current w-8 h-auto ml-4 text-green-500" />
            <span className="font-semibold text-[22px] text-white">İşlemler</span>
          </div>
          <div className="flex mt-6 w-full gap-x-4">
            <CardButton
              text="Para Transferi"
              icon={AddBalance}
              onClick={toggleMoneyTransferModal}
            />
            <CardButton
              text="Döviz Al / Sat"
              buttonText="Yakında"
              icon={<IconExchange className="w-8 h-8" />}
              onClick={toggleExchangeModal}
            />
            <CardButton
              isDisabled={false}
              text="Para Yükle"
              icon={IconBankNote}
              onClick={onToggleCollectionModal}
            />
            <CardButton
              text="Fatura İşlemleri"
              icon={Insert}
              onClick={() => push(`${match.path}/bills`)}
            />
            <CardButton
              text="Talimatlar"
              icon={<IconRefresh />}
              onClick={() => push(`${match.path}/smart-operations`)}
            />
            <CardButton
              text="Tümünü Göster"
              icon={IconShowAll}
              dropDowns={[
                {
                  label: 'Sigorta',
                  onClick: () => {
                    window.open('https://norma.sigortayeri.com/', '_blank');
                  }
                },
                {
                  label: 'İşletme Kredisi',
                  onClick: () => {
                    push(`${match.path}/business-loan`);
                  }
                }
              ]}
            />
          </div>
        </div>
        <Card className="w-full mt-10 p-12">
          <Switch>
            <Route exact path={`${match.path}`}>
              <Transaction handleContactClick={handleModalDefaultContact} />
            </Route>
            <Route path={`${match.path}/bills`}>
              <Bills />
            </Route>
            <Route path={`${match.path}/smart-operations`}>
              <SmartOperations />
            </Route>
            <Route path={`${match.path}/business-loan`}>
              <BusinessLoan />
            </Route>
            <Route exact path={`*`}>
              <NotFound />
            </Route>
          </Switch>
        </Card>
      </div>
    </div>
  );
};

export default Transactions;
