import React, { useEffect, useState, useCallback } from 'react';

export default function ProgressBar({ value = 0, total = 0 }) {
  const [progress, setProgress] = useState(0);

  const calculatePercentage = useCallback((value, total) => {
    return (value / total) * 100;
  }, []);

  useEffect(() => {
    setProgress(calculatePercentage(value, total));
  }, [value, total]);

  return (
    <div className="relative w-full bg-gray-300 rounded-xl">
      <div className="abosolute flex w-full">
        <div
          className={`bg-green-500 w-full rounded-l-xl`}
          style={{
            maxWidth: `${progress}%`
          }}
        ></div>
        <div className="bg-white w-1 h-2 shadow-norma border border-red-900"></div>
      </div>
    </div>
  );
}
