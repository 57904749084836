import React from 'react';
import { DropdownMenuItem } from './subComponents';
import './index.css';

const DropdownMenu = React.forwardRef(
  ({ isActive, children, items, className = '', withArrow = true, ...props }, ref) => {
    const activeClass = isActive ? 'block' : 'hidden';

    return (
      <div className="dropdown-menu" ref={ref}>
        <div className="dropdown-menu-child">{children}</div>
        <div
          className={`dropdown-menu-card min-w-[215px] ${activeClass} ${
            withArrow ? 'dropdown-menu-card-arrow' : 'false'
          } ${className}`}
          ref={ref}
          {...props}
        >
          {items?.map(({ path, icon, title, hasDivider, customClass, onClick }, i) => {
            return (
              <DropdownMenuItem
                key={i}
                hasDivider={hasDivider}
                icon={icon}
                title={title}
                to={path}
                onClick={onClick}
                className={customClass}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
