import React, { useEffect } from 'react';
import { BottomModal, Button } from 'components';
import ReceiptPdf from 'views/invoicing/components/eDocumentPreview/ReceiptPdf';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as IconShare } from 'assets/icons/share.svg';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { DOCUMENT_STATUS, downloadFileByUrl } from 'utils';

export default function ViewDocumentModal({
  selectedDocument,
  ref,
  isActive = false,
  handleClose,
  onApprove,
  onSendBack,
  onAddPaymentInfo
}) {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_and_settings_tax_declaration_preview'
    });
  }, []);

  const downloadReceiptByFileUrl = () => {
    if (selectedDocument?.file?.url)
      downloadFileByUrl(selectedDocument.file.url, selectedDocument.file.name);
  };

  const renderButtons = () => {
    if (selectedDocument?.status === DOCUMENT_STATUS.WAITING_APPROVAL.key)
      return (
        <>
          <Button className="w-full" color="secondary" onClick={onSendBack}>
            Geri Gönder
          </Button>
          <Button className="w-full" onClick={onApprove}>
            Onayla
          </Button>
        </>
      );
    if (selectedDocument?.status === DOCUMENT_STATUS.WAITING_FOR_PAID.key)
      return (
        <Button className="w-full" color="primary" onClick={onAddPaymentInfo}>
          Ödeme Bilgisi Ekle
        </Button>
      );
    if (selectedDocument?.status === DOCUMENT_STATUS.PAID.key)
      return (
        <Button className="w-full" color="primary" onClick={downloadReceiptByFileUrl}>
          İndir
        </Button>
      );

    return null;
  };

  return (
    <BottomModal ref={ref} isActive={isActive} handleClose={handleClose}>
      <div
        className="mx-auto w-auto h-full flex flex-col items-center justify-center"
        data-testid="section_documents_company_file_preview"
      >
        <div className="w-full flex justify-between items-center gap-x-3">
          <h4 data-testid="label_documents_file_document_receipt">
            {selectedDocument?.taxDocumentType.name}
          </h4>
          <div className="flex items-center gap-x-3">
            <Button
              className="w-full"
              variant="circle"
              color="gray"
              icon={<IconShare className="fill-current stroke-0" />}
              data-testid="button_documents_file_document_receipt_share"
              onClick={downloadReceiptByFileUrl}
            />
            <Button
              className="w-full"
              variant="circle"
              color="gray"
              icon={<IconDownload className="fill-current stroke-0" />}
              data-testid="button_documents_file_document_receipt_download"
              onClick={downloadReceiptByFileUrl}
            />
          </div>
        </div>
        <div className="h-auto w-auto overflow-y-scroll rounded-xl overflow-hidden my-5">
          <ReceiptPdf pdfUrl={selectedDocument?.file?.url} />
        </div>
        <div className="flex gap-x-3 items-center w-full">{renderButtons()}</div>
      </div>
    </BottomModal>
  );
}
