import axios from 'axios';

export const getSectors = () => {
  return (dispatch) => {
    return axios.get('/api/activity-scopes/sector').then((res) => {
      dispatch({
        type: 'SET_SECTORS',
        data: res.data?.data
      });
    });
  };
};

export const getProfessions = () => {
  return (dispatch) => {
    return axios.get('/api/activity-scopes/profession').then((res) => {
      dispatch({
        type: 'SET_PROFESSIONS',
        data: res.data?.data
      });
    });
  };
};

export const getProfessionsByRefId = ({ refId }) => {
  return () => {
    return axios.get(`/api/activity-scopes/${refId}/profession`);
  };
};
