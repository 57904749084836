import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { Card } from 'components';
import { ReactComponent as IconArrowDown } from 'assets/icons/caret-down.svg';
import classNames from 'classnames';
import { useEffect } from 'react';
import SearchInput from 'components/searchInput';

export default function PopoverSelect({
  onChange = () => {},
  options,
  defaultValue,
  customItemElement: CustomItemElement,
  customSelectedItem: CustomSelectedItem,
  panelClassName,
  labelClassName,
  hasBaseFormClass,
  isSearchable,
  searchParam,
  searchPlaceholder,
  className,
  selectedOption,
  disabled,
  placeholder,
  onChangeSearch = () => {}
}) {
  const [selected, setSelected] = useState(defaultValue);

  const panelClassNames = classNames(
    panelClassName,
    { ['!w-full']: hasBaseFormClass },
    'absolute right-0 mt-2 w-56 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none p-6 text-gray-800 bg-white flex flex-col gap-y-4 rounded-xl shadow max-h-40 overflow-y-auto z-50'
  );

  const labelClassNames = classNames(
    'select-none text-base',
    {
      ['w-full']: hasBaseFormClass,
      'font-semibold text-gray-700': placeholder && !selected && !selectedOption?.label
    },
    labelClassName
  );

  const baseClassNames = classNames(
    '!p-4 flex gap-x-2 items-center text-sm font-medium leading-5',
    { ['w-full !bg-gray-100 !shadow-none rounded-lg']: hasBaseFormClass },
    className
  );

  const containerClassNames = classNames(
    'relative',
    { ['w-full !bg-gray-100 !shadow-none rounded-lg']: hasBaseFormClass },
    className
  );

  const popoverButtonClassNames = classNames('flex cursor-pointer max-h', {
    'cursor-not-allowed text-gray-400': disabled
  });

  useEffect(() => {
    if (typeof onChange === 'function') onChange(defaultValue);
  }, []);

  const onItemSelected = (item) => {
    setSelected(item);
    if (typeof onChange === 'function') onChange(item);
  };

  return (
    <Popover className={containerClassNames}>
      <Popover.Button as="span" className={popoverButtonClassNames} aria-disabled={disabled}>
        {({ open }) =>
          CustomSelectedItem ? (
            <CustomSelectedItem item={selectedOption || selected}>
              <IconArrowDown
                className={classNames('stroke-current text-gray-400 w-5', {
                  ['rotate-180']: !disabled && open
                })}
              />
            </CustomSelectedItem>
          ) : (
            <Card className={baseClassNames}>
              {selectedOption?.icon ||
                (selected?.icon && (
                  <span>
                    {typeof selectedOption?.icon || selected?.icon === 'string' ? (
                      <img
                        src={selectedOption?.icon || selected?.icon}
                        alt={selectedOption?.label || selected?.label}
                        width={20}
                        height={20}
                      />
                    ) : (
                      selectedOption?.icon || selected?.icon
                    )}
                  </span>
                ))}
              <span className={labelClassNames}>
                {selectedOption?.label || selected?.label || placeholder}
              </span>
              <IconArrowDown
                className={classNames('stroke-current text-gray-400 w-5', {
                  ['rotate-180']: !disabled && open
                })}
              />
            </Card>
          )
        }
      </Popover.Button>
      {!disabled && (
        <Popover.Panel as="div" className={panelClassNames}>
          {({ close }) => (
            <Items
              customItemElement={CustomItemElement}
              data={options}
              onClick={(e, item) => {
                onItemSelected(item);
                close();
              }}
              isSearchable={isSearchable}
              searchParam={searchParam}
              searchPlaceholder={searchPlaceholder}
              onChangeSearch={onChangeSearch}
            />
          )}
        </Popover.Panel>
      )}
    </Popover>
  );
}

const Items = ({
  data,
  isSearchable,
  searchPlaceholder,
  customItemElement: CustomItemElement,
  onChangeSearch = () => {},
  ...props
}) => {
  const [items, setItems] = useState(data);

  const onChangeSearchParam = (e) => {
    onChangeSearch(e);
    setItems(
      data?.filter((item) =>
        item?.label.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      ) || data
    );
  };
  return (
    <div>
      {isSearchable && (
        <div className="mb-2">
          <SearchInput
            onChange={onChangeSearchParam}
            className="!w-full"
            placeholder={searchPlaceholder}
            containerClassName="!w-full"
          />
        </div>
      )}
      {items?.map((item, i) =>
        CustomItemElement ? (
          <CustomItemElement key={i} item={item} isLast={i === data?.length - 1} {...props} />
        ) : (
          <Item key={i} item={item} isLast={i === data?.length - 1} {...props} />
        )
      )}
    </div>
  );
};

const Item = ({ item, onClick, isLast, ...props }) => {
  const itemClassName = classNames(
    'cursor-pointer p-2 text-sm font-medium leading-5 flex gap-x-2'
  );
  const iconClassName = classNames('w-[20px] h-[20px]');
  const labelClassName = classNames('w-full pb-4', {
    'border-solid border-b border-gray-100': !isLast
  });
  return (
    <div className={itemClassName} onClick={(e) => onClick(e, item)} {...props}>
      <span className={iconClassName}>
        {typeof item?.icon === 'string' ? (
          <img src={item?.icon} alt={item?.label} width={20} height={20} />
        ) : (
          item?.icon
        )}
      </span>
      <span className={labelClassName}>{item.label}</span>
    </div>
  );
};
