export default {
  GET_ENUMS: 'GET_ENUMS',
  GET_ENUMS_SUCCESS: 'GET_ENUMS_SUCCESS',
  GET_ENUMS_FAILURE: 'GET_ENUMS_FAILURE',
  GET_DAYS_OF_WEEK: 'GET_DAYS_OF_WEEK',
  GET_DAYS_OF_WEEK_SUCCESS: 'GET_DAYS_OF_WEEK_SUCCESS',
  GET_DAYS_OF_WEEK_FAILURE: 'GET_DAYS_OF_WEEK_FAILURE',
  GET_DAYS_OF_MONTH: 'GET_DAYS_OF_MONTH',
  GET_DAYS_OF_MONTH_SUCCESS: 'GET_DAYS_OF_MONTH_SUCCESS',
  GET_DAYS_OF_MONTH_FAILURE: 'GET_DAYS_OF_WEEK_FAILURE'
};
