import { DatePicker } from 'components';

const PaymentStatusNotPaidDatePicker = ({ errors, watch, setValue }) => (
  <div className="flex flex-col">
    <span className="text-gray-500 text-sm font-medium">Vade Tarihi</span>
    <DatePicker
      className="bg-gray-100 w-full rounded-lg p-2 focus:outline-none"
      selected={watch('dueDate')}
      onChange={(date) => setValue('dueDate', date)}
      minDate={new Date()}
    />
    {errors?.dueDate && (
      <span className="text-red-500 text-xs mt-2">{errors?.dueDate?.message}</span>
    )}
  </div>
);

export default PaymentStatusNotPaidDatePicker;
