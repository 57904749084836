import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

export default function Tab({
  tabs = [],
  defaultIndex = 0,
  tabsClassName,
  onChange = () => {},
  ...props
}) {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  useEffect(() => {
    setSelectedIndex(defaultIndex);
  }, [defaultIndex]);

  const onChangeTab = (index, tab) => {
    if (tabs[index]?.disabled) return;
    setSelectedIndex(index);
    onChange(index, tab);
  };

  return (
    <TabContainer {...props}>
      <Tabs className={tabsClassName}>
        {tabs?.map((tab, index) => (
          <Tabs.Tab
            key={index + tab.title}
            isSelected={selectedIndex === index}
            disabled={tab?.disabled}
            onClick={() => onChangeTab(index, tab)}
            className={
              tab?.tabClassNameFunc ? tab?.tabClassNameFunc(selectedIndex === index) : null
            }
          >
            {tab?.title}
          </Tabs.Tab>
        ))}
      </Tabs>
      <Panels>
        {tabs?.map(
          (tab, index) =>
            selectedIndex === index && <Panels.Panel key={index}>{tab?.component}</Panels.Panel>
        )}
      </Panels>
    </TabContainer>
  );
}
const TabContainer = ({ children }) => <div id="tab-container">{children}</div>;

const Tabs = ({ children, className, ...props }) => {
  return (
    <div
      id="tabs"
      className={classNames(
        'flex gap-x-12 border-solid border-b border-gray-200 px-6',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

Tabs.Tab = ({ children, isSelected = false, disabled = false, className, ...props }) => {
  return (
    <div
      id="tab"
      className={classNames(
        'flex-1 max-w-max text-center text-gray-500 font-semibold leading-6 pb-3 cursor-pointer select-none',
        {
          'border-solid border-b-2 !border-green-500 text-green-500': isSelected,
          '!cursor-not-allowed': disabled
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const Panels = ({ children }) => {
  return <div id="panels">{children}</div>;
};

Panels.Panel = ({ children }) => {
  return <div id="panel">{children}</div>;
};

Panels.Panel.displayName = 'Panel';

Tabs.Tab.displayName = 'Tab';
