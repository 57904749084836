import React from 'react';
import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import { Card } from 'components';
import classNames from 'classnames';

export default function SmartOperationCardButton({
  icon,
  title = '',
  description = '',
  disabled,
  className = '',
  onClick,
  ...props
}) {
  const classes = classNames(
    'flex justify-between items-center gap-x-6 cursor-pointer !shadow-none border border-solid border-gray-200 flex-1',
    className
  );
  if (disabled)
    return (
      <Card className="w-1/5 bg-gray-700 flex-1" {...props}>
        <div className="flex space-x-2 items-center justify-between text-white mt-4">
          <div>
            <span className="font-semibold select-none text-gray-300">{title}</span>
            <p className="text-sm font-medium text-gray-500">{description}</p>
          </div>
          <span className="w-24 h-10 px-4 rounded-full bg-gray-300 flex items-center justify-center select-none text-gray-900">
            Yakında
          </span>
        </div>
      </Card>
    );
  return (
    <Card className={classes} onClick={onClick} {...props}>
      <div className="flex items-center gap-x-6">
        <div>{icon}</div>
        <div className="flex flex-col gap-x-4">
          <span className="text-base font-semibold">{title}</span>
          <p className="text-sm font-medium text-gray-500">{description}</p>
        </div>
      </div>
      <IconArrowRight className="stroke-current text-gray-700 w-5 h-5" />
    </Card>
  );
}
