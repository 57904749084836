import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useModal from 'utils/hooks/useModal';
import { Document, Page } from 'react-pdf';

import { CustomButton } from 'components';
import RejectConfirmationPopup from 'views/invoicing/components/acceptRejectInvoiceModal/RejectConfirmationPopup';
import useDengage from 'utils/hooks/useDengage';

import { downloadInvoice, sendInvoiceResponse } from 'store/actions/eTransformation';

import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';

const ReceiptPdf = ({ pdfUrl }) => {
  const [pageCount, setPageCount] = useState(1);

  const PageList = ({ pageCount }) => {
    return [...Array(pageCount).keys()].map((pageIndex) => {
      return <Page pageNumber={pageIndex + 1} className="w-96" width={384} />;
    });
  };

  return (
    <Document
      file={pdfUrl}
      onLoadError={console.log}
      className="rounded-xl py-4"
      noData={pdfUrl ? null : 'Fatura yükleniyor...'}
      loading={pdfUrl ? null : 'Fatura yükleniyor...'}
      onLoadSuccess={({ numPages }) => setPageCount(numPages)}
      renderMode="svg"
    >
      <PageList pageCount={pageCount} />
    </Document>
  );
};

const InvoicePreview = ({ invoice, handlePopupClose, handleSuccess }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const [rejectConfirmationPopupActive, , toggleRejectConfirmationPopup] = useModal();

  const { user } = useSelector((state) => state.auth);
  const { eDocument } = useSelector((state) => state.invoice);

  function acceptInvoice() {
    dispatch(
      sendInvoiceResponse(
        { companyId: user.companyId, documentId: invoice.documentId },
        { accepted: true }
      )
    )
      .then(() => {
        handleSuccess(invoice);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onDownload = () => {
    if (!eDocument) return;

    const a = document.createElement('a');
    a.href = eDocument;
    a.download = eDocument.split('/').pop();
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (!user.companyId) return;

    dispatch({
      type: 'SET_E_DOCUMENT',
      data: null
    });
    dispatch(
      downloadInvoice({
        companyId: user.companyId,
        documentId: invoice?.documentId,
        'content-type': 'PDF'
      })
    );
  }, [user]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_e_invoice_info' });
  }, []);

  return (
    <div className="flex flex-col w-[582px] h-auto">
      <RejectConfirmationPopup
        active={rejectConfirmationPopupActive}
        onClose={toggleRejectConfirmationPopup}
        invoice={invoice}
        handlePopupClose={handlePopupClose}
      />
      <div className="flex items-center justify-between">
        <span className="text-3xl font-semibold text-gray-900">Onay Bekleyen e-Fatura</span>
        <div
          className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
          onClick={onDownload}
        >
          <ShareIcon className="fill-current w-6 text-gray-700" />
        </div>
      </div>
      <div className="w-full bg-white flex justify-center rounded-lg mt-6 overflow-y-auto h-[768px]">
        <ReceiptPdf pdfUrl={eDocument} />
      </div>
      <span className="mt-6 text-xs text-gray-500 font-medium">
        e-Fatura itirazı için yasal süre 8 gündür. Bu süre içinde itiraz edilmeyen faturalar
        otomatik olarak kabul edilecektir.
      </span>
      <div className="w-full flex gap-x-4 mt-8">
        <CustomButton color="danger" className="w-1/2" onClick={toggleRejectConfirmationPopup}>
          Reddet
        </CustomButton>
        <CustomButton color="success" className="w-1/2" onClick={acceptInvoice}>
          Onayla
        </CustomButton>
      </div>
    </div>
  );
};

export default InvoicePreview;
