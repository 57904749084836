export default {
  CLOSE_WALLET: 'CLOSE_WALLET',
  CLOSE_WALLET_SUCCESS: 'CLOSE_WALLET_SUCCESS',
  CLOSE_WALLET_FAILURE: 'CLOSE_WALLET_FAILURE',
  UPDATE_WALLET: 'UPDATE_WALLET',
  UPDATE_WALLET_SUCCESS: 'UPDATE_WALLET_SUCCESS',
  UPDATE_WALLET_FAILURE: 'UPDATE_WALLET_FAILURE',
  EXPORT_WALLET_TRANSACTIONS: 'EXPORT_WALLET_TRANSACTIONS',
  EXPORT_WALLET_TRANSACTIONS_SUCCESS: 'EXPORT_WALLET_TRANSACTIONS_SUCCESS',
  EXPORT_WALLET_TRANSACTIONS_FAILURE: 'EXPORT_WALLET_TRANSACTIONS_FAILURE',
  GET_WALLET_TYPES: 'GET_WALLET_TYPES',
  GET_WALLET_TYPES_FAILURE: 'GET_WALLET_TYPES_FAILURE',
  GET_WALLET_TYPES_SUCCESS: 'GET_WALLET_TYPES_SUCCESS'
};
