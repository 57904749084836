import { ReactComponent as IconRefresh } from 'assets/icons/refresh-colored.svg';

const Chip = ({ colorHexCode }) => {
  return (
    <div
      className="mt-1 flex items-center justify-center h-5 w-[38px] rounded-full"
      style={{ background: colorHexCode }}
    >
      <IconRefresh className="w-4 h-4" />
    </div>
  );
};

export default Chip;
