import React, { useContext, useState, useEffect } from 'react';
import { Button, CodeInput, InfoMessage } from 'components';
import StepHeader from 'components/stepHeader';
import { ActivatePhysicalCardContext } from '../..';
import toast from 'react-hot-toast';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';
import { useDispatch } from 'react-redux';
import { checkPrepaidCard } from 'store/actions/card';
import { PIN_PASSWORD_LENGTH } from 'utils';

export default function ConfirmPassword() {
  const { setFormValues, formValues, wallet, nextStep } = useContext(ActivatePhysicalCardContext);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formValues?.confirmPassword?.length === PIN_PASSWORD_LENGTH) {
      if (PASSWORD_SEQUENTIAL_REGEX.test(formValues?.confirmPassword)) {
        return toast.error('Şifreniz ardışık rakamlardan oluşamaz.');
      }
      if (PASSWORD_REPEATING_REGEX.test(formValues?.confirmPassword)) {
        return toast.error('Şifreniz tekrarlayan rakamlardan oluşamaz.');
      }
      if (formValues?.password !== formValues?.confirmPassword) {
        return toast.error('Girdiğiniz pinler uyuşmuyor');
      } else {
        return setIsConfirmPasswordValid(true);
      }
    }
    setIsConfirmPasswordValid(false);
  }, [formValues?.confirmPassword]);

  const onChangePassword = (confirmPassword) => {
    setFormValues((values) => ({
      ...values,
      confirmPassword
    }));
  };

  const onApprovePassword = () => {
    dispatch(
      checkPrepaidCard({
        walletId: wallet?.id,
        cardId: wallet?.prepaidCards[0]?.id
      })
    ).then(() => {
      nextStep();
    });
  };

  return (
    <div className="flex flex-col gap-y-10">
      <div>
        <StepHeader
          title="Şifre Belirle"
          subtitle="4 haneli kart şifrenizi tekrar girin"
          className="!mb-10"
        />
        <div>
          <CodeInput
            length={4}
            invisible
            onChange={onChangePassword}
            className="mt-4"
            only="number"
            value={formValues?.confirmPassword}
            blockPaste
            autofocus
          />
        </div>
      </div>
      <InfoMessage>
        Şifreniz, tekrarlayan ya da ardışık rakamlardan oluşamaz. (111,123 vb)
      </InfoMessage>
      <Button className="w-full" disabled={!isConfirmPasswordValid} onClick={onApprovePassword}>
        Onayla
      </Button>
    </div>
  );
}
