import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { useDeviceDetection } from 'utils/hooks';

const { Toaster, resolveValue, default: toast } = require('react-hot-toast');

const TYPE_COLORS = {
  error: 'bg-red-toaster text-white',
  success: 'bg-green-500 text-white',
  default: 'bg-white text-black'
};

const CustomToaster = () => {
  const { isMobile } = useDeviceDetection();
  return (
    <Toaster
      position={isMobile ? 'top-center' : 'bottom-left'}
      containerClassName={!isMobile && '!mt-11 mr-8'}
    >
      {(t) => (
        <div
          className={`${
            TYPE_COLORS[t.type] || TYPE_COLORS.default
          } w-96 p-4 rounded-xl flex items-center shadow-lg relative`}
        >
          {t.icon && <div className="mr-2">{t.icon}</div>}
          <InfoCircleIcon className="fill-current w-6 mr-2" />
          <div>{resolveValue(t.message, t)}</div>
          <div className="absolute top-2 right-3 mt-">
            <CloseIcon
              className="stroke-current w-5 cursor-pointer"
              onClick={() => toast.dismiss(t.id)}
            />
          </div>
        </div>
      )}
    </Toaster>
  );
};

export default CustomToaster;
