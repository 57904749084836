const { useEffect } = require('react');

const useKeyPress = (handle) => {
  function handleKeyPress(e) {
    if (e.key !== 'Enter') return handle(e);
    e.preventDefault();
    return handle(e);
  }

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  });
};

export default useKeyPress;
