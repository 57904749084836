import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomInput, Select } from 'components';

import { getDistrict } from 'store/actions/country';
import { getTaxOfficesByCityId } from 'store/actions/taxOffice';
import { useContactContext } from 'contexts/contactContext';
import { isValidVKN } from 'utils';

const CompanyInformation = ({ errors, onlyRequired = false }) => {
  const { formValues, setFormValues } = useContactContext();
  const [tcknVknErrorMessage, setTcknVknErrorMessage] = useState('');

  const dispatch = useDispatch();
  const districtRef = useRef();
  const taxOfficeRef = useRef();

  const { cities, districts } = useSelector((state) => state.country);
  const filteredDistricts = districts[formValues?.cityId] || [];
  const [taxOffices, setTaxOffices] = useState([]);

  useEffect(() => {
    tcknVknCheck(formValues?.taxIdentityNumber);
  }, [formValues?.taxIdentityNumber]);

  useEffect(() => {
    const cityId = formValues?.cityId;
    if (!cityId) return;

    dispatch(getDistrict({ cityId }));
  }, [formValues?.cityId]);

  useEffect(() => {
    const cityId = formValues?.cityId;
    if (!cityId) return;

    dispatch(getTaxOfficesByCityId({ cityId })).then((res) => {
      setTaxOffices(res.data?.data);
    });
  }, [formValues?.cityId]);

  const onChangeCompanyName = (e) => {
    const { value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      companyName: value
    }));
  };

  const onChangeCity = (cityId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      cityId: Number(cityId),
      districtId: null,
      taxOfficeId: null
    }));
    districtRef.current.value = '';
    taxOfficeRef.current.value = '';
  };

  const onChangeDistrict = (districtId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      districtId: Number(districtId),
      taxOfficeId: null
    }));
  };

  const onChangeAddress = (e) => {
    const { value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      address: value
    }));
  };

  const onChangeTaxOffice = (taxOfficeId) => {
    setFormValues((prevValues) => ({
      ...prevValues,

      taxOfficeId: Number(taxOfficeId)
    }));
  };

  const tcknVknCheck = (value) => {
    if (value?.length > 0 && value?.length < 10) {
      setTcknVknErrorMessage('Vergi / T.C. Kimlik Numarası 10 ya da 11 haneli olmalıdır!');
      setFormValues((prevValues) => ({
        ...prevValues,
        isTaxIdentityNumberValid: false
      }));
    } else if (value?.length === 10 && !isValidVKN(value)) {
      setTcknVknErrorMessage('Vergi Numarası 10 haneli ve uygun olmalıdır!');
      setFormValues((prevValues) => ({
        ...prevValues,
        isTaxIdentityNumberValid: false
      }));
    } else {
      setTcknVknErrorMessage('');
      setFormValues((prevValues) => ({
        ...prevValues,
        isTaxIdentityNumberValid: true
      }));
    }

    return value;
  };

  const onChangeTaxIdentityNumber = (e) => {
    const { value } = e.target;
    console.log('tax');
    tcknVknCheck(value);
    setFormValues((prevValues) => ({
      ...prevValues,

      taxIdentityNumber: value
    }));
  };

  return (
    <div className="flex flex-col gap-y-4" data-testid="section_company_information">
      <span className="text-[18px] text-gray-600 font-semibold">Şirket Bilgileri</span>
      <CustomInput
        key="business_companyName"
        className="w-full"
        label="Şirket Adı"
        name="companyName"
        onChange={onChangeCompanyName}
        maxLength={140}
        value={formValues?.companyName}
        error={errors?.companyName?.message}
        data-testid="input_company_name"
      />
      <Select
        className="w-full"
        options={cities}
        value="id"
        valueLabel="name"
        label="İl"
        isOptional={!onlyRequired}
        defaultValue={formValues?.cityId}
        onChange={onChangeCity}
        error={errors?.cityId?.message}
        data-testid="select_city"
      />
      <Select
        className="w-full"
        selectRef={districtRef}
        options={filteredDistricts}
        value="id"
        valueLabel="name"
        label="İlçe"
        isOptional={!onlyRequired}
        defaultValue={formValues?.districtId}
        onChange={onChangeDistrict}
        error={errors?.districtId?.message}
        data-testid="select_district"
      />
      <div className="flex flex-col w-full">
        <span className="text-sm text-gray-500 font-medium" data-testid="label_tax_office">
          Adres{' '}
          {!onlyRequired && (
            <span className="text-sm leading-5 font-medium text-gray-400 italic">
              {'(Opsiyonel)'}
            </span>
          )}
        </span>
        <textarea
          onChange={onChangeAddress}
          value={formValues?.address}
          className="bg-gray-100 resize-none rounded-lg focus:outline-none p-2 h-20 w-full"
          maxLength={140}
          data-testid="input_address"
        />
      </div>
      <Select
        className="w-full"
        selectRef={taxOfficeRef}
        key="business_taxOfficeId"
        options={taxOffices}
        isOptional={!onlyRequired}
        value="id"
        valueLabel="name"
        label="Vergi Dairesi"
        defaultValue={formValues?.taxOfficeId}
        onChange={onChangeTaxOffice}
        error={errors?.taxOfficeId?.message}
        data-testid="select_tax_office"
      />
      <CustomInput
        key="business_taxIdentityNumber"
        className="w-full"
        label="Vergi / T.C. Kimlik Numarası"
        name="taxIdentityNumber"
        isOptional={!onlyRequired}
        maxLength={11}
        onChange={onChangeTaxIdentityNumber}
        value={formValues?.taxIdentityNumber}
        only="number"
        error={tcknVknErrorMessage}
        data-testid="input_tax_identity_number"
      />
    </div>
  );
};

export default CompanyInformation;
