import axios from 'axios';
import { filterKeys, getFormattedDateForPayload } from 'utils';
import { invoicingTypes } from 'store/types';

const {
  GET_SALES_DETAIL,
  GET_SALES_DETAIL_SUCCESS,
  GET_SALES_DETAIL_FAIL,
  GET_E_DOCUMENT,
  GET_E_DOCUMENT_FAIL,
  GET_E_DOCUMENT_SUCCESS
} = invoicingTypes;
export const getExpenseInvoice = ({ companyId }) => {
  return axios.get(`/api/companies/${companyId}/expense-invoices`);
};

export const getExpenseInvoiceSummarySeries = ({ companyId, intervalType }) => {
  return axios
    .get(`/api/companies/${companyId}/expense-invoices/summary-series`, {
      params: { intervalType }
    })
    .then((res) => res.data.data);
};

export const getSalesExpense = (companyId, params) => {
  return (dispatch) => {
    const filters = {
      'category-id': params?.selectedCategory?.value,
      'contact-id': params?.selectedContact?.value,
      currency: params?.currency,
      'end-date': params?.endDate ? getFormattedDateForPayload(params?.endDate) : null,
      'start-due-date': params?.startDueDate
        ? getFormattedDateForPayload(params?.startDueDate)
        : null,
      'end-due-date': params?.endDueDate ? getFormattedDateForPayload(params?.endDueDate) : null,
      'start-invoice-date': params?.startInvoiceDate
        ? getFormattedDateForPayload(params?.startInvoiceDate)
        : null,
      'end-invoice-date': params?.endInvoiceDate
        ? getFormattedDateForPayload(params?.endInvoiceDate)
        : null,
      'start-payment-date': params?.startPaymentDate
        ? getFormattedDateForPayload(params?.startPaymentDate)
        : null,
      'end-payment-date': params?.endPaymentDate
        ? getFormattedDateForPayload(params?.endPaymentDate)
        : null,
      'first-name': params?.firstName,
      'full-name': params?.fullName,
      'has-invoice': params?.hasInvoice,
      'last-name': params?.lastName,
      'payment-status': params?.paymentStatus,
      orderBy: params?.orderBy ? params?.orderBy : 'createDate',
      orderDirection: 'DESC',
      page: params?.page,
      'payment-method': params?.paymentMethod?.value,
      title: params?.search,
      'record-type': params?.recordType,
      size: params?.size,
      status: 'COMPLETED'
    };

    const payloadParams = filterKeys(filters, null, {
      filterEmpty: true,
      filterNull: true
    });
    return axios
      .get(`/api/companies/${companyId}/sales-expense`, { params: payloadParams })
      .then((res) => {
        dispatch({
          type: 'SET_SALES_EXPENSES',
          data: res.data?.data
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_SALES_EXPENSE_ERROR'
        });
      });
  };
};

export const getSalesInvoice = ({ companyId }) => {
  return axios.get(`/api/companies/${companyId}/sales-invoices`);
};

export const getSalesInvoiceSummarySeries = ({ companyId, intervalType }) => {
  return axios
    .get(`/api/companies/${companyId}/sales-invoices/summary-series`, {
      params: { intervalType }
    })
    .then((res) => res.data.data);
};

export const createSaleInvoice = ({
  companyId,
  categoryId,
  collectionDate,
  contactId,
  currency,
  description,
  productOrServices
}) => {
  const payload = {
    categoryId,
    collectionDate,
    contactId,
    currency,
    description,
    productOrServices
  };
  return axios.post(`/api/companies/${companyId}/sales-invoices`, payload);
};

export const createSaleExpense = ({ companyId, ...data }) => {
  return axios.post(`/api/companies/${companyId}/sales-expense/product-service`, data);
};

export const completeSaleExpense = ({ companyId, recordId }) => {
  return axios.put(
    `/api/companies/${companyId}/sales-expense/complete`,
    {},
    { params: { recordId } }
  );
};

export const getSaleExpenseById = ({ salesExpenseRecordId }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      dispatch({ type: GET_SALES_DETAIL });

      const response = await axios.get(
        `/api/companies/${user?.companyId}/sales-expense/${salesExpenseRecordId}`
      );

      if (response.status === 200) {
        dispatch({ type: GET_SALES_DETAIL_SUCCESS, payload: response?.data?.data });

        return Promise.resolve(response);
      }
    } catch (error) {
      dispatch({ type: GET_SALES_DETAIL_FAIL, payload: error });

      return Promise.reject(error);
    }
  };
};

export const updateSaleExpense = ({ companyId, salesExpenseRecordId, ...data }) => {
  return axios.put(
    `/api/companies/${companyId}/sales-expense/${salesExpenseRecordId}/product-service`,
    data
  );
};

export const calculateSaleExpenseItems = ({ companyId, data }) => {
  return axios.post(
    `/api/companies/${companyId}/sales-expense/product-service/calculation`,
    data
  );
};

export const deleteSaleExpense = ({ companyId, id }) => {
  return axios.delete(`/api/companies/${companyId}/sales-expense/${id}`);
};

export const createEDocument = ({ companyId, salesExpenseRecordId }) => {
  return axios.put(
    `api/companies/${companyId}/sales-expense/${salesExpenseRecordId}/product-service/e-document`
  );
};

export const previewEDocument = ({ companyId, salesExpenseRecordId }) => {
  return (dispatch) => {
    return axios
      .get(
        `/api/companies/${companyId}/sales-expense/${salesExpenseRecordId}/product-service/e-document/preview`
      )
      .then((res) => {
        dispatch({
          type: 'SET_E_DOCUMENT',
          data: 'data:application/pdf;base64,' + res.data?.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getEDocument = ({ companyId, salesExpenseRecordId }) => {
  return (dispatch) => {
    dispatch({
      type: GET_E_DOCUMENT
    });
    return axios
      .get(
        `/api/companies/${companyId}/sales-expense/${salesExpenseRecordId}/product-service/e-document/preview`
      )
      .then((res) => {
        dispatch({
          type: GET_E_DOCUMENT_SUCCESS,
          data: 'data:application/pdf;base64,' + res.data?.data
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_E_DOCUMENT_FAIL,
          data: err
        });
        console.log(err);
      });
  };
};

export const getSummarySeries = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const { intervalType } = getState().invoice;
    if (!user.companyId) return Promise.reject();
    return axios
      .get(`/api/companies/${user.companyId}/sales-expense/summary-series`, {
        params: { 'interval-type': intervalType }
      })
      .then((res) => {
        dispatch({
          type: 'SET_SUMMARY_SERIES',
          data: res.data?.data
        });
      });
  };
};

export const createSaleExpenseExpress = (
  { categoryId, currency, currencyRate, note, paymentDate, title, totalPaidAmount, vatRate },
  salesExpenseExpressFileCreateDtoList
) => {
  const payload = {
    categoryId,
    currency,
    currencyRate,
    note,
    paymentDate,
    salesExpenseExpressFileCreateDtoList,
    title,
    totalPaidAmount,
    vatRate
  };
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.post(`/api/companies/${user.companyId}/sales-expense/express`, payload);
  };
};

export const createSaleExpenseGeneral = ({
  categoryId,
  currency,
  currencyRate,
  dueDate,
  note,
  paymentAmount,
  paymentDate,
  paymentMethod,
  title
}) => {
  const payload = {
    categoryId,
    currency,
    currencyRate,
    dueDate,
    note,
    paymentAmount,
    paymentDate,
    paymentMethod,
    title
  };
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.post(`/api/companies/${user.companyId}/sales-expense/general`, payload);
  };
};

export const exportSaleExpenseCSV = ({ companyId, payload }) => {
  return axios.get(`/api/companies/${companyId}/sales-expense/export`, { params: payload });
};

export const exportSaleExpense = ({ companyId, payload, fileType = 'XLSX' }) => {
  const filters = {
    'category-id': payload?.selectedCategory?.value,
    'contact-id': payload?.selectedContact?.value,
    currency: payload?.currency,
    'end-date': payload?.endDate ? getFormattedDateForPayload(payload?.endDate) : null,
    'start-due-date': payload?.startDueDate
      ? getFormattedDateForPayload(payload?.startDueDate)
      : null,
    'end-due-date': payload?.endDueDate ? getFormattedDateForPayload(payload?.endDueDate) : null,
    'start-invoice-date': payload?.startInvoiceDate
      ? getFormattedDateForPayload(payload?.startInvoiceDate)
      : null,
    'end-invoice-date': payload?.endInvoiceDate
      ? getFormattedDateForPayload(payload?.endInvoiceDate)
      : null,
    'start-payment-date': payload?.startPaymentDate
      ? getFormattedDateForPayload(payload?.startPaymentDate)
      : null,
    'end-payment-date': payload?.endPaymentDate
      ? getFormattedDateForPayload(payload?.endPaymentDate)
      : null,
    'first-name': payload?.firstName,
    'full-name': payload?.fullName,
    'has-invoice': payload?.hasInvoice,
    'last-name': payload?.lastName,
    'payment-status': payload?.paymentStatus,
    orderBy: 'createDate',
    orderDirection: 'DESC',
    page: payload?.page,
    'payment-method': payload?.paymentMethod?.value,
    title: payload?.search,
    'record-type': payload?.recordType,
    size: payload?.size,
    'file-type': fileType
  };
  const payloadParams = filterKeys(filters, null, {
    filterEmpty: true,
    filterNull: true
  });

  return axios.get(`/api/companies/${companyId}/sales-expense/export`, { params: payloadParams });
};

export const updateExpenseSale = ({ companyId, id, payload }) => {
  return axios.put(`/api/companies/$${companyId}/sales-expense/${id}/express`, payload);
};

export const getSalesExpenseByCompanyId = (companyId, data) => {
  return axios.get(`/api/companies/${companyId}/sales-expense`, { params: data }).catch((err) => {
    console.log(err);
  });
};

export const getSummarySeriesByCompanyId = (companyId, params) => {
  return axios
    .get(`/api/companies/${companyId}/sales-expense/summary-series`, {
      params
    })
    .catch((err) => {
      console.log(err);
    });
};
