import React from 'react';
import classNames from 'classnames';
import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';

export default function IncomeExpenseItem({
  title,
  value,
  icon,
  isIncome = false,
  className
  // currency
}) {
  const classes = classNames('flex flex-col gap-y-4 p-2', className);
  const valueClasses = classNames('text-[22px] font-semibold leading-7', className, {
    ['text-green-500']: isIncome,
    ['text-gray-900']: !isIncome
  });
  return (
    <div className={classes}>
      <div className="flex gap-x-2 items-center">
        <span>{icon}</span>
        <span className="text-base leading-6 font-semibold text-gray-700">{title}</span>
      </div>

      <div className={valueClasses}>
        {isIncome ? '+' : '-'}
        {currencyFormat(value)} {CURRENCY_NAMES.TRY}
      </div>
    </div>
  );
}
