import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemById } from 'store/actions/items';
import { useRouter } from 'utils/hooks/useRouter';
import useDengage from 'utils/hooks/useDengage';
import { Card, Button, Divider } from 'components';

//icons
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { currencyFormat, PRICE_TYPES } from 'utils';
import { useItemContext } from 'contexts/itemContext';
import { EditItemModal } from 'views/other/items/components';

const ItemDetail = () => {
  const dispatch = useDispatch();
  const { query } = useRouter();
  const { toggleEditItemModal, isEditItemModalActive, isFreelancer } = useItemContext();
  const { publishEvent, eventTypes } = useDengage();
  const { item } = useSelector((state) => state.items);
  const { user } = useSelector((state) => state.auth);

  function getItem() {
    dispatch(getItemById({ itemId: query.id }));
  }

  function handleCloseEdited() {
    getItem();
  }

  useEffect(() => {
    getItem();
  }, [user]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_item_detail' });
  }, []);

  const calcualteWithholding = (tax) => {
    if (tax < 10) return `${tax * 10}/10`;

    return `${tax}/10`;
  };

  return (
    <div className="relative bg-gray-200 flex justify-center py-12 w-full h-auto min-h-screen px-">
      {/* {isEditModalActive && <EditItemModal handleClose={handleCloseEdited} item={{ ...item }} />} */}
      {isEditItemModalActive && <EditItemModal onClose={handleCloseEdited} />}
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative flex flex-col space-y-4 w-11/12 xl:w-3/4 pb-6">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-xl text-white">Ürün Detayı</span>
          <Button
            type="button"
            className="flex items-center gap-x-2"
            onClick={toggleEditItemModal}
            data-testid="button_edit_item"
            leftIcon={<EditIcon className="fill-current w-5" />}
          >
            <span>Düzenle</span>
          </Button>
        </div>
        <Card className="px-12 py-14">
          <div className="flex flex-col space-y-8">
            <Title>{item?.name}</Title>
            <div className="flex space-x-[104px]">
              {isFreelancer ? (
                <ItemInformation
                  title="Tutar Türü"
                  description={PRICE_TYPES.find((pt) => pt.id === item?.priceType)?.name}
                />
              ) : (
                <ItemInformation title="Birim Tipi" description={item?.unitType?.name} />
              )}
              <ItemInformation title="Tutar" description={currencyFormat(item?.price)} />
              <ItemInformation title="Para Birimi" description={CURRENCY_NAMES[item?.currency]} />
            </div>
            <Divider />
            <Title>Vergiler</Title>
            <div className="flex space-x-[104px]">
              {item?.vatRate > 0 && (
                <ItemInformation title={'KDV'} description={`%${item?.vatRate}`} />
              )}
              {isFreelancer && item?.vatWithholdingRate && (
                <ItemInformation
                  title={'KDV Tevkifatı'}
                  description={calcualteWithholding(item?.vatWithholdingRate)}
                />
              )}
              {!isFreelancer && item?.vatWithholdingCode && item?.vatWithholdingRate && (
                <>
                  <ItemInformation
                    title={'Tevkifat Kodu'}
                    description={item?.vatWithholdingCode}
                  />
                  <ItemInformation
                    title={'Tevkifat Oranı'}
                    description={calcualteWithholding(item?.vatWithholdingRate)}
                  />
                </>
              )}
              {item?.additionalTaxes?.map((tax) => (
                <ItemInformation
                  key={tax.name}
                  title={tax.name}
                  description={`%${tax.percent}`}
                />
              ))}
            </div>

            <Card className="!bg-gray-100 w-full !shadow-none !border !border-gray-200 !rounded-md !p-6">
              <div className="flex flex-col space-y-2">
                <VatInformation
                  title={isFreelancer ? 'Brüt Tutar' : 'Birim Tutar'}
                  amount={item?.grossPrice}
                  currency={item?.currency}
                />
                {isFreelancer && (
                  <VatInformation
                    title="Net Tutar"
                    amount={item?.netPrice}
                    currency={item?.currency}
                  />
                )}

                {(item?.additionalTaxes?.length > 0 ||
                  item?.vatRate > 0 ||
                  item?.vatWithholdingCode) && (
                  <VatInformation title="Vergiler" currency={item?.currency} amount={null} />
                )}
                {item?.vatRate > 0 && (
                  <VatInformation
                    title="KDV Tutarı"
                    amount={item?.vatAmount}
                    currency={item?.currency}
                    isSubVat
                  />
                )}
                {(item?.vatWithholdingCode || item?.vatWithholdingRate) && (
                  <VatInformation
                    title="Tevkifat Tutarı"
                    amount={item?.vatWithholdingAmount}
                    currency={item?.currency}
                    isSubVat
                  />
                )}
                {item?.additionalTaxes?.map((tax) => (
                  <VatInformation
                    key={tax.name}
                    title={tax.name}
                    amount={tax?.amount}
                    currency={item?.currency}
                    isSubVat
                  />
                ))}
              </div>
              <div className="text-lg font-semibold leading-6 flex justify-between items-center w-full mt-4">
                <p>{`${isFreelancer ? 'KDV Dahil Toplam Tutar' : 'Genel Toplam'}`}</p>
                <p>
                  {currencyFormat(item?.totalPrice)} {CURRENCY_NAMES[item?.currency]}
                </p>
              </div>
            </Card>
          </div>
        </Card>
      </div>
    </div>
  );
};

const Title = ({ children }) => <p className="text-2xl leading-6 font-semibold">{children}</p>;
const ItemInformation = ({ title, description }) => (
  <div className="flex flex-col space-y-2">
    <p className="text-sm font-medium text-gray-500">{title}</p>
    <p className="text-sm font-semibold text-gray-700">{description}</p>
  </div>
);
const VatInformation = ({ title, amount = 0, currency = 'TRY', isSubVat = false }) => {
  if (isSubVat) {
    return (
      <div className="ml-2">
        <div className="flex justify-between items-center">
          <p className="text-sm font-medium leading-5 text-gray-500">{title}</p>
          {amount && (
            <p className="text-sm leading-5 font-semibold text-gray-600">
              {currencyFormat(amount)} {CURRENCY_NAMES[currency]}
            </p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-sm font-semibold leading-5">{title}</p>
        {amount && (
          <p className="text-sm leading-5 font-semibold text-gray-600">
            {currencyFormat(amount)} {CURRENCY_NAMES[currency]}
          </p>
        )}
      </div>
    </div>
  );
};

export default ItemDetail;
