import { CustomButton, SideModal } from 'components';
import { useState, useEffect } from 'react';

import { ITEM_TYPES } from '../enums';
import useDengage from 'utils/hooks/useDengage';

const FilterModal = ({ handleClose, handleFilters, filters }) => {
  const [itemType, setItemType] = useState(filters?.type);
  const { publishEvent, eventTypes } = useDengage();

  function handleItemType(type) {
    if (itemType === type) return setItemType(null);
    setItemType(type);
  }

  function handleSuccess() {
    handleFilters(itemType);
    handleClose();
  }

  function handleClear() {
    setItemType(null);
  }

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_item_filter' });
  }, []);

  return (
    <SideModal handleClose={handleClose}>
      <div className="flex flex-col overflow-y-scroll h-full">
        <span className="text-2xl text-gray-900 font-semibold">Filtrele</span>
        <span className="mt-10 text-gray-600 font-semibold">Ürün Tipi</span>
        <div className="flex gap-x-4 mt-4">
          <span
            className={`${
              itemType === ITEM_TYPES.PRODUCT
                ? 'bg-green-500 text-green-50'
                : 'bg-gray-200 text-gray-500'
            } w-1/2 rounded-full text-sm py-2 flex items-center justify-center cursor-pointer`}
            onClick={() => handleItemType(ITEM_TYPES.PRODUCT)}
          >
            Ürün
          </span>
          <span
            className={`${
              itemType === ITEM_TYPES.SERVICE
                ? 'bg-green-500 text-green-50'
                : 'bg-gray-200 text-gray-500'
            } w-1/2 rounded-full text-sm py-2 flex items-center justify-center cursor-pointer`}
            onClick={() => handleItemType(ITEM_TYPES.SERVICE)}
            data-testid="button_filter_service"
          >
            Hizmet
          </span>
        </div>
        <div className="mt-auto flex flex-col gap-y-4">
          <CustomButton color="success" onClick={handleSuccess} data-testid="button_filter_apply">
            Uygula
          </CustomButton>
          <CustomButton onClick={handleClear} data-testid="button_filter_clear">
            Temizle
          </CustomButton>
        </div>
      </div>
    </SideModal>
  );
};

export default FilterModal;
