import React from 'react';
import cls from 'classnames';

export default function ContentContainer({ children, title, actions, className }) {
  const classnames = cls('w-full', className);
  return (
    <div className={classnames}>
      <div className="w-full flex justify-between items-center mb-6">
        <span className="font-semibold leading-7 text-gray-900 text-xl">{title}</span>
        <div>{actions}</div>
      </div>
      {children}
    </div>
  );
}
