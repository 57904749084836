import { contentResourceTypes } from 'store/types';

const {
  GET_ALL_CONTENT_RESOURCES,
  GET_ALL_CONTENT_RESOURCES_FAILED,
  GET_ALL_CONTENT_RESOURCES_SUCCEDED,
  GET_ERROR_CONTENT_RESOURCES,
  GET_ERROR_CONTENT_RESOURCES_FAILED,
  GET_ERROR_CONTENT_RESOURCES_SUCCEDED
} = contentResourceTypes;

const initialState = {
  loading: false,
  errorContents: {
    resources: null,
    version: ''
  },
  allContents: {
    resources: null,
    version: ''
  },
  error: null
};

export default function ContentResourceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTENT_RESOURCES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_CONTENT_RESOURCES_SUCCEDED:
      return {
        ...state,
        loading: false,
        allContents: action.payload,
        error: null
      };
    case GET_ALL_CONTENT_RESOURCES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_ERROR_CONTENT_RESOURCES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ERROR_CONTENT_RESOURCES_SUCCEDED:
      return {
        ...state,
        loading: false,
        errorContents: action.payload,
        error: null
      };
    case GET_ERROR_CONTENT_RESOURCES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
