const initialState = false;

const accountLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_LOAD':
      return true;
    case 'SET_ACCOUNT_LOADED':
      return false;
    default:
      return state;
  }
};

export default accountLoadingReducer;
