import { CustomInput, PhoneInput } from 'components';

const CompanyContactInformation = ({ watch, setValue, clearErrors, errors }) => (
  <div className="flex flex-col gap-y-4">
    <span className="text-[18px] text-gray-600 font-semibold">Yetkili Kişi Bilgileri</span>
    <CustomInput
      label="İsim"
      onChange={(e) => {
        setValue('firstName', e.target.value);
        clearErrors('firstName');
      }}
      isOptional
      only="text"
      maxLength={140}
      value={watch('firstName')}
      error={errors?.firstName?.message}
      data-testid="input_first_name"
    />
    <CustomInput
      label="Soyisim"
      onChange={(e) => {
        setValue('lastName', e.target.value);
        clearErrors('lastName');
      }}
      isOptional
      value={watch('lastName')}
      only="text"
      maxLength={140}
      error={errors?.lastName?.message}
      data-testid="input_last_name"
    />
    <CustomInput
      label="E-Posta"
      name="email"
      onChange={(e) => {
        setValue('email', e.target.value);
        clearErrors('email');
      }}
      isOptional
      maxLength={140}
      value={watch('email')}
      error={errors?.email?.message}
      data-testid="input_email"
    />
    <PhoneInput
      onChange={(phone) => {
        setValue('fullPhoneNumber', phone);
        clearErrors('fullPhoneNumber');
      }}
      isOptional
      showCountryCode={false}
      defaultValue={watch('fullPhoneNumber')}
      error={errors?.fullPhoneNumber?.message}
      justCellPhone={false}
      data-testid="input_phone"
    />
  </div>
);

export default CompanyContactInformation;
