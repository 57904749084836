import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { CustomButton, Button, CodeInput } from 'components';
import { login, loginWRememberMeToken } from 'store/actions/auth';
import PhoneInput from 'components/PhoneInput';
import errorCodes from 'utils/errorCodes';
import useKeyPress from 'utils/hooks/useKeyPress';
import useCountdown from 'utils/hooks/useCountdown';
import { OTP_TIMEOUT, timeFormat } from 'utils';
import useDengage from 'utils/hooks/useDengage';

import Logo from 'assets/icons/logo.svg';

const StepHeader = ({ title, subtitle }) => (
  <div className="flex flex-col my-4">
    <a href="/" title="Norma">
      <img src={Logo} alt="logo" width="35" />
    </a>
    <span className="text-gray-400 mt-6 font-semibold">{title}</span>
    <span className="text-xl font-semibold text-gray-900">{subtitle}</span>
  </div>
);

const Step0 = ({ watch, nextStep, setValue }) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'login_enter_phone' });
  }, []);

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    nextStep();
  });

  const isCorrect = watch('phone').length === 10;

  return (
    <div className="flex flex-col justify-between space-y-6 md:justify-center w-full h-full">
      <div>
        <StepHeader
          title="Giriş Yap"
          subtitle="Telefon numaranızı girin"
          data-testid="label_login_phone"
        />

        <PhoneInput
          onChange={(_, phone) => setValue('phone', phone)}
          autofocus
          data-testid="input_login_phone"
        />
      </div>

      <Button
        type="button"
        className="w-full"
        onClick={() => nextStep()}
        data-testid="button_login"
        disabled={!isCorrect}
      >
        Devam
      </Button>
    </div>
  );
};

const Step1 = ({ watch, nextStep, setValue, setError, errors }) => {
  const { publishEvent, eventTypes } = useDengage();
  const dispatch = useDispatch();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'login_enter_password' });
  }, []);

  function _login() {
    const payload = {
      phone: watch('phone'),
      password: watch('password')
    };
    dispatch(login(payload))
      .then((res) => {
        setValue('mfaToken', res.data.data.mfa_token);
        nextStep();
      })
      .catch((err) => {
        if (err[errorCodes.invalidCredential]) {
          setError('password', {
            type: 'warning',
            message: err[errorCodes.invalidCredential]
          });
        }
      });
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    _login();
  });

  const isCorrect = watch('password').length === 6;

  return (
    <div className="flex flex-col justify-between space-y-6 md:justify-center w-full h-full">
      <div>
        <StepHeader
          title="Giriş Yap"
          subtitle="Şifrenizi girin"
          data-testid="label_login_password"
        />
        <CodeInput
          label="Şifre"
          only="number"
          className="mt-2"
          onChange={(pw) => setValue('password', pw)}
          length={6}
          invisible={true}
          error={errors?.password}
          data-testid="input_login_password"
          autofocus
          blockPaste
        />
        <Link
          className="text-green-500 font-medium mt-4"
          to="forgot-password"
          data-testid="input_login_link_forgot"
        >
          Şifremi Unuttum
        </Link>
      </div>
      <CustomButton
        color="success"
        className="mt-12"
        disabled={!isCorrect}
        onClick={_login}
        data-testid="button_login"
      >
        Devam
      </CustomButton>
    </div>
  );
};

const Step2 = ({ setValue, isCorrect, watch, errors, length }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'login_enter_phone_otp' });
  }, []);

  const dispatch = useDispatch();
  const [timeLeft, restartCounting, isCounting] = useCountdown(OTP_TIMEOUT);

  function sendCodeAgain() {
    restartCounting();
    const payload = {
      phone: watch('phone'),
      password: watch('password')
    };
    dispatch(login(payload)).then((res) => {
      setValue('mfaToken', res.data.data.mfa_token);
    });
  }

  const phone = `+90 ${watch('phone').slice(0, 3)} ${watch('phone').slice(3, 6)} ${watch(
    'phone'
  ).slice(6, 8)} ${watch('phone').slice(8, 10)}`;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Giriş Yap"
        subtitle={`${phone} numaranıza gelen ${length} haneli kodu girin`}
        data-testid="label_login_otp"
      />
      <CodeInput
        length={length}
        onChange={(val) => setValue('otpToken', val)}
        error={errors?.otpToken}
        autofocus
        only="number"
        data-testid="input_login_otp"
      />
      <span className="text-sm mt-4">SMS ulaşmadı mı?</span>
      {isCounting ? (
        <span className="text-sm text-green-400 select-none cursor-default">
          {timeFormat(timeLeft)}
        </span>
      ) : (
        <span
          className="text-sm text-green-400 select-none cursor-pointer"
          onClick={sendCodeAgain}
          data-testid="input_login_otp_resend"
        >
          Tekrar Gönder.
        </span>
      )}
      <CustomButton
        color="success"
        className="mt-8"
        type="submit"
        disabled={!isCorrect}
        data-testid="button_login"
      >
        Devam
      </CustomButton>
    </div>
  );
};

const RMStep0 = ({ user, forgotMe }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);

  function handleLogin() {
    dispatch(loginWRememberMeToken({ password }))
      .then((res) => {
        dispatch({
          type: 'SET_TOKEN',
          data: res.data?.access_token
        });
        localStorage.setItem('token', res.data.access_token);
        history.push('/');
      })
      .catch((err) => {
        if (err[errorCodes.invalidCredential]) {
          setHasError(true);
        }
      });
  }

  function handleForgotPassword() {
    history.push('/forgot-password');
  }

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Giriş Yap"
        subtitle={`Hoşgeldiniz, ${user.firstName} ${user.lastName}`}
        data-testid="label_login_remember_me"
      />
      <CodeInput
        label="Şifre"
        className="mt-2"
        onChange={(pw) => setPassword(pw)}
        length={6}
        invisible={true}
        error={hasError}
        data-testid="input_login_remember_me"
      />
      <span
        className="text-sm text-green-500 my-4 cursor-pointer"
        onClick={handleForgotPassword}
        data-testid="input_login_remember_me_forgot"
      >
        Şifremi Unuttum
      </span>
      <CustomButton
        color="success"
        className="mt-8"
        onClick={handleLogin}
        disabled={!password}
        data-testid="button_login_remember_me"
      >
        Devam
      </CustomButton>
      <CustomButton
        color="danger"
        className="mt-6"
        onClick={forgotMe}
        data-testid="button_login_remember_me_forgot"
      >
        Çıkış
      </CustomButton>
    </div>
  );
};

export { Step0, Step1, Step2, RMStep0 };
