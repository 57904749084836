import axios from 'axios';
import { exchangeTypes } from 'store/types';

const {
  GET_EXCHANGE_RATE,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_FAILURE,
  BUY_FOREIGN_CURRENCY,
  BUY_FOREIGN_CURRENCY_SUCCESS,
  BUY_FOREIGN_CURRENCY_FAILURE,
  CALCULATE_BUY_FOREIGN_CURRENCY,
  CALCULATE_BUY_FOREIGN_CURRENCY_SUCCESS,
  CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE,
  SELL_FOREIGN_CURRENCY,
  SELL_FOREIGN_CURRENCY_SUCCESS,
  SELL_FOREIGN_CURRENCY_FAILURE,
  CALCULATE_SELL_FOREIGN_CURRENCY,
  CALCULATE_SELL_FOREIGN_CURRENCY_SUCCESS,
  CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE
} = exchangeTypes;

export const getRates = (params) => {
  return (dispatch) => {
    axios.get('/api/exchanges/rates', { params }).then((res) => {
      dispatch({
        type: 'SET_RATES',
        data: {
          currency: params?.currency,
          data: res.data?.data
        }
      });
    });
  };
};

export const getExchangeRate = (currencyCode = null) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_EXCHANGE_RATE_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: GET_EXCHANGE_RATE
      });

      const response = await axios.post(`/api/wallets/exchange/rate`, {
        currencyCode
      });

      if (response.status === 200) {
        dispatch({
          type: GET_EXCHANGE_RATE_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_EXCHANGE_RATE_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const buyForeignCurrency = ({
  walletId = '',
  exchangeToken = '',
  targetWalletId = ''
}) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: BUY_FOREIGN_CURRENCY_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: BUY_FOREIGN_CURRENCY
      });

      const response = await axios.post(`/api/wallets/${walletId}/exchange/buy`, {
        exchangeToken,
        targetWalletId
      });

      if (response.status === 200) {
        dispatch({
          type: BUY_FOREIGN_CURRENCY_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: BUY_FOREIGN_CURRENCY_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const sellForeignCurrency = ({
  walletId = '',
  exchangeToken = '',
  targetWalletId = ''
}) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: SELL_FOREIGN_CURRENCY_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: SELL_FOREIGN_CURRENCY
      });

      const response = await axios.post(`/api/wallets/${walletId}/exchange/sell`, {
        exchangeToken,
        targetWalletId
      });

      if (response.status === 200) {
        dispatch({
          type: SELL_FOREIGN_CURRENCY_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: SELL_FOREIGN_CURRENCY_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const calculateBuyForeignCurrency = (
  {
    exchangeAmount = 0,
    exchangeRateToken = '',
    sourceCurrency = 'TRY',
    targetCurrency = 'EUR',
    targetWalletId
  },
  walletId
) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: CALCULATE_BUY_FOREIGN_CURRENCY
      });

      const response = await axios.post(`/api/wallets/${walletId}/exchange/buy/calculation`, {
        exchangeAmount,
        exchangeRateToken,
        sourceCurrency,
        targetCurrency,
        targetWalletId
      });

      if (response.status === 200) {
        dispatch({
          type: CALCULATE_BUY_FOREIGN_CURRENCY_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const calculateSellForeignCurrency = (
  {
    exchangeAmount = 0,
    exchangeRateToken = '',
    sourceCurrency = 'TRY',
    targetCurrency = 'EUR',
    targetWalletId
  },
  walletId
) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: CALCULATE_SELL_FOREIGN_CURRENCY
      });

      const response = await axios.post(`/api/wallets/${walletId}/exchange/sell/calculation`, {
        exchangeAmount,
        exchangeRateToken,
        sourceCurrency,
        targetCurrency,
        targetWalletId
      });

      if (response.status === 200) {
        dispatch({
          type: CALCULATE_SELL_FOREIGN_CURRENCY_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
