import { useRouter } from 'utils/hooks/useRouter';

import { Header, NotificationsModal, CustomToaster, Footer } from 'components';
import ContactUsModal from 'components/contactUsModal';
import NotificationSettingsModal from 'components/notificationSettingsModal';
import SessionEndModal from 'components/sessionEndModal';
import { useDispatch } from 'react-redux';
import IdleTimer from 'components/idleTimer';
import { useEffect } from 'react';
import { toggleSessionEndModal } from 'store/actions/auth';

const DefaultLayout = ({ children }) => {
  const dispatch = useDispatch();

  const { query, location, push } = useRouter();

  const isNotificationModalActive = Object.keys(query).includes('notifications');
  const isContactUsModalActive = Object.keys(query).includes('contact-us');
  const isNotificationSettingsModalActive = Object.keys(query).includes('notification-settings');

  function closeModal() {
    push(location.pathname);
  }

  useEffect(() => {
    dispatch(toggleSessionEndModal(false));
  }, []);

  return (
    <div>
      {isNotificationModalActive && <NotificationsModal handleClose={closeModal} />}
      {isContactUsModalActive && <ContactUsModal handleClose={closeModal} />}
      {isNotificationSettingsModalActive && (
        <NotificationSettingsModal handleClose={closeModal} />
      )}
      <SessionEndModal />
      <IdleTimer />
      <CustomToaster />
      <Header />
      {children}
      <Footer className="bg-gray-200 border-gray-300" />
    </div>
  );
};

export default DefaultLayout;
