import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import { store } from 'store/storeConfig/store';
import { BrowserRouter as Router } from 'react-router-dom';

import 'plugins/axios';
import TranslationContextProvider from 'contexts/translationContext';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <TranslationContextProvider
        options={{
          fallbackLanguage: 'tr'
        }}
      >
        <App />
      </TranslationContextProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);
