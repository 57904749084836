import { useState, useEffect } from 'react';

const useModal = (
  { defaultMode = false, handleToggle, handleOpen, handleClose, shouldBeBlockScroll = false } = {
    defaultMode: false
  }
) => {
  const [_isModalActive, _setIsModalActive] = useState(defaultMode);

  useEffect(() => {
    if (shouldBeBlockScroll)
      document.body.style = _isModalActive ? 'overflow:hidden' : 'overflow:auto';
  }, [_isModalActive]);

  function _toggleModal() {
    _handleSet(!_isModalActive);
  }

  function _handleSet(e = false) {
    if (handleToggle) handleToggle(!_isModalActive);
    if (!_isModalActive && handleOpen) {
      if (handleOpen() === false) return;
    }
    if (!!_isModalActive && handleClose) {
      if (handleClose() === false) return;
    }
    _setIsModalActive(e);
  }

  return [_isModalActive, _handleSet, _toggleModal];
};

export default useModal;
