import React, { useState, useEffect } from 'react';
import CustomInput from 'components/CustomInput';
import classNames from 'classnames';
import { useRef } from 'react';

export default function ComboInput({
  count = 4,
  name = 'combo-input',
  id = 'combo-input',
  className = '',
  onChange,
  inputClassName = '',
  maxLength = 4,
  type = 'number',
  ...props
}) {
  const containerClassNames = classNames('flex gap-x-3', className);
  const inputClassNames = classNames('flex-1 max-w-[72px]', inputClassName);
  const [inputs, setInputs] = useState([]);
  const [inputValues, setInputValues] = useState([]);

  const comboInputRef = useRef([]);

  useEffect(() => {
    if (inputs?.length < count) {
      for (let index = 0; index < count; index++) {
        setInputs((current) => [
          ...current,
          {
            id: `${id}${index}`,
            name: `${name}${index}`,
            key: `${id}${name}${index}`
          }
        ]);
      }
    }
  }, []);

  useEffect(() => {
    onChange(inputValues.join(''));
  }, [inputValues]);

  function onComboInputChange(e, index) {
    const inputValue = e.target.value;
    inputValues[index] = inputValue;
    setInputValues([...inputValues]);

    if (inputValue?.length === maxLength && index <= maxLength) {
      comboInputRef.current[index + 1]?.focus();
    } else if ((!inputValue || inputValue?.length === 0) && index > 0) {
      comboInputRef.current[index - 1]?.focus();
    }
  }

  return (
    <div>
      <div className={containerClassNames}>
        {inputs.map((input, index) => (
          <CustomInput
            id={input.id}
            key={input.key}
            only={type}
            name={input.name}
            className={inputClassNames}
            maxLength={maxLength}
            refProp={(ref) => (comboInputRef.current[index] = ref)}
            onChange={(e) => onComboInputChange(e, index)}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}
