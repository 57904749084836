import React, { useEffect } from 'react';
import DoubleCheck from 'assets/illustrations/card/double-check.png';
import { Button } from 'components';
import { useSmartOperationContext } from '../..';

import { useDispatch } from 'react-redux';
import {
  getAutomaticTransaction,
  getAutomaticTransactions,
  resetCreatedAutomaticTransaction
} from 'store/actions/automatic';
import { useSelector } from 'react-redux';

export default function AutoTransactionSuccess({
  message = '',
  actionButtonText = 'Kapat',
  isDeleteSuccess
}) {
  const {
    setShowBackButton,
    toggleCreateAutomaticTransactionModal,
    toggleAutomaticTransactionDetailModal
  } = useSmartOperationContext();
  const { createdAutomaticTransaction, automaticTransaction } = useSelector(
    (state) => state.automaticTransaction
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setShowBackButton(false);
    dispatch(getAutomaticTransactions());
    return () => {
      setShowBackButton(true);
    };
  }, []);

  useEffect(() => {
    if (createdAutomaticTransaction?.automaticTransactionId && !isDeleteSuccess)
      dispatch(getAutomaticTransaction(createdAutomaticTransaction?.automaticTransactionId)).then(
        () => {
          dispatch(resetCreatedAutomaticTransaction());
        }
      );
  }, [createdAutomaticTransaction]);

  const onClose = () => {
    if (automaticTransaction?.automaticTransactionId) {
      toggleCreateAutomaticTransactionModal();
    }
    toggleAutomaticTransactionDetailModal();
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-6">
      <img src={DoubleCheck} alt="double-check" width="100" />
      <p className="text-3xl text-white max-w-md text-center">{message}</p>
      <Button
        onClick={onClose}
        className="w-[400px]"
        disabled={
          isDeleteSuccess
            ? false
            : !automaticTransaction?.automaticTransactionId && !isDeleteSuccess
        }
      >
        {actionButtonText}
      </Button>
    </div>
  );
}
