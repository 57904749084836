import React, { useEffect } from 'react';
import { BottomModal, Card } from 'components';
import { currencyFormat, getFormattedDateWithSeperator } from 'utils';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as IconFile } from 'assets/icons/file-document.svg';

export default function ViewDocumentModal({
  selectedDocument,
  ref,
  isActive = false,
  handleClose,
  onSelectFile
}) {
  const { publishEvent, eventTypes } = useDengage();

  const onClickDocument = (file) => {
    if (typeof onSelectFile === 'function') onSelectFile(file);
  };

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'profile_and_settings_tax_declaration_info' });
  }, []);

  return (
    <BottomModal ref={ref} isActive={isActive} handleClose={handleClose}>
      <div
        className="p-4 w-full max-w-[640px] mx-auto overflow-y-scroll"
        data-testid="section_documents_tax_modal"
      >
        <div className="w-full flex flex-col gap-y-8 justify-between items-center">
          <FileCard
            title="Belge"
            fileName={selectedDocument?.file?.name}
            onClick={() => onClickDocument(selectedDocument.file)}
          />
          <FileCard
            title="Ödeme Makbuzu/Fişi"
            fileName={selectedDocument?.receiptPaymentFile?.name}
            onClick={() => onClickDocument(selectedDocument?.receiptPaymentFile)}
          />
        </div>
        <div
          className="flex flex-col gap-y-6 w-full border border-gray-500 mt-8"
          data-testid="section_documents_company_file_info"
        >
          <FileInfo
            title="Dosya Adı"
            description={selectedDocument?.file?.name?.split('.')[0]}
            data-testid="info_documents_file_document_name"
          />
          <FileInfo
            title="Vergi Kategorisi"
            description={selectedDocument?.taxDocumentType?.name}
            data-testid="info_documents_file_document_type"
          />
          <FileInfo title="Tahakkuk Fiş Numarası" description={selectedDocument?.receiptNumber} />
          <FileInfo
            title="Son Ödeme Tarihi"
            description={getFormattedDateWithSeperator(selectedDocument?.dueDate)}
            data-testid="info_documents_file_document_due_date"
          />
          <FileInfo
            title="Ödeme Tarihi"
            description={getFormattedDateWithSeperator(selectedDocument?.createDate)}
            data-testid="info_documents_file_document_create_date"
          />
          <FileInfo
            title="Tutar"
            description={`${currencyFormat(selectedDocument?.amount)} TL`}
            data-testid="info_documents_file_document_amount"
          />
        </div>
      </div>
    </BottomModal>
  );
}

const FileCard = ({ title, fileName, ...props }) => {
  return (
    <div className="w-full" {...props}>
      <div
        className="text-base font-medium text-gray-500 leading-6 mb-4"
        data-testid="label_documents_file_document_card_title"
      >
        {title}
      </div>
      <Card className="cursor-pointer hover:translate-y-1">
        <div className="flex items-center gap-x-3">
          <div className="w-14 h-14 bg-gray-50 flex justify-center items-center rounded-lg">
            <IconFile className="fill-current w-6 h-6 text-gray-400" />
          </div>
          <p
            className="font-semibold text-sm leading-5 text-gray-900"
            data-testid="label_documents_file_document_card_file_name"
          >
            {fileName}
          </p>
        </div>
      </Card>
    </div>
  );
};

const FileInfo = ({ title, description }) => {
  return (
    <div className="text-left">
      <div
        className="text-base font-medium text-gray-500 leading-6 mb-4"
        data-testid="label_documents_file_document_title"
      >
        {title}
      </div>
      <div
        className=" text-lg font-medium text-gray-900 leading-6"
        data-testid="label_documents_file_document_description"
      >
        {description}
      </div>
    </div>
  );
};
