const CustomButton = ({
  ref,
  children,
  onClick,
  color,
  style,
  disabled,
  className,
  type,
  ...props
}) => {
  type = type || 'button';

  const colors = {
    danger: ['bg-red-100', 'text-red-800', 'hover:bg-red-200'],
    success: ['bg-green-300', 'text-green-900', 'hover:bg-green-400'],
    primary: ['bg-purple-100', 'text-purple-700', 'hover:bg-purple-200'],
    black: ['bg-black', 'text-white'],
    'success-darker': ['bg-green-500', 'text-green-900', 'hover:bg-green-600'],
    disabled: ['bg-gray-200', 'text-gray-400', 'cursor-default'],
    green: ['bg-green-300', 'text-green-900', 'hover:bg-green-400'],
    default: ['bg-gray-50', 'text-gray-900', 'hover:bg-gray-100'],
    transparent: ['bg-transparent', 'text-gray-900']
  };

  const colorClasses = disabled
    ? colors.disabled.join(' ')
    : colors[color]
    ? colors[color].join(' ')
    : colors.primary.join(' ');

  const classNames = `${className} ${colorClasses} ${
    disabled ? 'pointer-events-none' : ''
  } rounded-lg px-8 py-3 font-medium text-center min-w-max transition`;

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={classNames}
      style={style}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;
