import React from 'react';
import { Button, Card, CustomToaster, Footer } from 'components';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import logo from 'assets/images/logo.png';
import { useRouter } from 'utils/hooks/useRouter';

export default function MarketplaceLayout({ children }) {
  const { history } = useRouter();
  return (
    <div className="bg-gray-50">
      <div className="w-full h-[400px] bg-gray-900 p-6">
        <img src={logo} alt="logo" width="133" />
      </div>
      <div className="w-full max-w-6xl mx-auto relative -top-64 px-6">
        <div className="flex space-x-6 items-center mb-4">
          <Button
            variant="circle"
            color="gray"
            icon={IconArrowLeft}
            onClick={() => history.goBack()}
          />

          <h4 className="text-white">Hizmetler ve Ürünler</h4>
        </div>
        <Card>{children}</Card>
      </div>
      <Footer />
      <CustomToaster />
    </div>
  );
}
