import React, { useState, useEffect } from 'react';
import { ActionCards, CashFlow, Resources, SalesExpenseRecords, DebtCredit } from './components';
import { ReactComponent as PhoneSignIcon } from 'assets/illustrations/action-card/phone-sign.svg';
import { useDispatch } from 'react-redux';
import { getBalance } from 'store/actions/company';
import { CURRENCIES } from 'views/other/items/enums';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
import { useRouter } from 'utils/hooks/useRouter';
import { getMarketplaceItems } from 'store/actions/marketplace';
import { useSelector } from 'react-redux';
import { Divider } from 'components';

export default function Home() {
  const dispatch = useDispatch();

  const [balance, setBalance] = useState(null);
  const [currency, setCurrency] = useState(CURRENCIES.TRY);
  const [hasAllItemsPurchased, setHasAllItemsPurchased] = useState(false);

  const { push } = useRouter();
  const { items } = useSelector((state) => state.marketplace);

  useEffect(() => {
    dispatch(
      getBalance({
        'currency-code': currency,
        'interval-type': 'MONTHLY'
      })
    ).then(({ data: { data } }) => setBalance(data));
  }, [currency]);

  const actionCards = [
    {
      name: 'Limit',
      icon: <PhoneSignIcon />,
      doneIcon: <PhoneSignIcon />,
      title: 'e-Dönüşüme Başvur',
      subtitle: 'Tüm faturalarını dijitale taşı',
      actionTitle: 'e-Dönüşüme Başvur',
      doneActionTitle: 'Tamamlandı',
      done: false,
      onClick: () => push(`/profile/services?shouldBeSubscribeEInvoice=true`)
    }
  ];

  useEffect(() => {
    dispatch(getMarketplaceItems());
  }, []);

  useEffect(() => {
    setHasAllItemsPurchased(items.filter((item) => item.purchased).length === actionCards.length);
  }, [items]);

  return (
    <div className="relative bg-gray-200 h-auto lg:px-24 px-4 py-12 w-full flex flex-col justify-start items-center pb-16">
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="w-full relative flex">
        <ActionCards actionCards={actionCards} hasAllItemsPurchased={hasAllItemsPurchased} />
      </div>
      <div className="w-full lg:w-10/12">
        <Resources
          balance={balance}
          setCurrency={setCurrency}
          currency={CURRENCY_NAMES[currency]}
        />
        <Divider className="border-gray-300 !border-b my-16" />
        <DebtCredit />
        <Divider className="border-gray-300 !border-b my-16" />
        <CashFlow balance={balance} currency={CURRENCY_NAMES[currency]} />
        <Divider className="border-gray-300 !border-b my-16" />
        <SalesExpenseRecords />
      </div>
    </div>
  );
}
