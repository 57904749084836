import axios from 'axios';
import { getFormattedDateForPayload } from 'utils';
import { contactTypes } from 'store/types';

const {
  GET_CONTACT,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  GET_CONTACT_BALANCE,
  GET_CONTACT_BALANCE_SUCCESS,
  GET_CONTACT_BALANCE_FAILURE,
  GET_CONTACT_TRANSACTION_DEBT_STATUS,
  GET_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS,
  GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE,
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS,
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS,
  GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE
} = contactTypes;
export const getContacts = (
  { isExpress = false, size = 10, search, orderBy, orderDirection, page } = {
    isExpress: false,
    size: 10,
    page: 0,
    search: '',
    orderBy: 'firstName',
    orderDirection: 'ASC'
  }
) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;

    const params = {
      express: isExpress || null,
      page,
      size,
      orderBy,
      orderDirection
    };

    if (search) {
      params['full-name'] = search;
    }
    return axios
      .get(`/api/companies/${user.companyId}/contacts`, {
        params
      })
      .then((res) => {
        if (!isExpress && !search) {
          dispatch({
            type: 'SET_CONTACTS',
            data: res.data?.data?.content,
            totalElements: res.data?.data?.totalElements,
            totalPages: res.data?.data.totalPages
          });
        } else if (isExpress) {
          dispatch({
            type: 'SET_QUICK_CONTACTS',
            data: res.data?.data?.content,
            totalElements: res.data?.data?.totalElements,
            totalPages: res.data?.data.totalPages
          });
        }
        dispatch({
          type: 'SET_FILTERED_CONTACTS',
          data: res.data?.data?.content,
          totalPages: res.data?.data.totalPages
        });
      });
  };
};

export const getLastContacts = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.get(`/api/companies/${user.companyId}/contacts/last`).then((res) => {
      dispatch({
        type: 'SET_LAST_CONTACTS',
        data: res.data?.data
      });
    });
  };
};

export const createContact = ({
  walletNumber,
  address,
  cityId,
  companyName,
  companyType,
  districtId,
  email,
  express,
  firstName,
  fullPhoneNumber,
  iban,
  lastName,
  profilePhoto,
  taxIdentityNumber,
  taxOfficeId,
  businessType,
  debtStatus,
  initialBalance,
  initialBalanceDate
}) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    const payload = {
      walletNumber,
      address,
      cityId,
      companyName,
      companyType,
      districtId,
      email,
      express,
      firstName,
      fullPhoneNumber,
      iban,
      lastName,
      profilePhoto,
      taxIdentityNumber,
      taxOfficeId,
      businessType,
      debtStatus,
      initialBalance,
      initialBalanceDate
    };
    if (initialBalanceDate) {
      payload.initialBalanceDate = getFormattedDateForPayload(initialBalanceDate);
    }
    if (iban?.length <= 2) {
      payload.iban = '';
    }
    return axios.post(`/api/companies/${user.companyId}/contacts`, payload);
  };
};

export const getContactById = (contactId) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) {
      const message = 'user companyId is not defined';
      dispatch({
        type: GET_CONTACT_FAILURE,
        payload: message
      });
      return Promise.reject(message);
    }
    try {
      dispatch({
        type: GET_CONTACT
      });
      const response = await axios.get(`/api/companies/${user.companyId}/contacts/${contactId}`);

      if (response.status === 200) {
        const data = response.data.data;

        dispatch({
          type: GET_CONTACT_SUCCESS,
          payload: data
        });

        return Promise.resolve(data);
      }
    } catch (error) {
      dispatch({
        type: GET_CONTACT_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const updateContact = ({ contactId, iban, ...data }) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    const payload = {
      ...data,
      iban
    };
    if (data?.initialBalanceDate) {
      payload.initialBalanceDate = getFormattedDateForPayload(data?.initialBalanceDate);
    }
    if (iban?.length <= 2) {
      payload.iban = '';
    }
    return axios.put(`/api/companies/${user.companyId}/contacts/${contactId}`, payload);
  };
};

export const deleteContact = ({ contactId }) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.delete(`/api/companies/${user.companyId}/contacts/${contactId}`);
  };
};

export const getContactBalance = (contactId) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) {
      const message = 'user companyId is not defined';
      dispatch({
        type: GET_CONTACT_BALANCE_FAILURE,
        payload: message
      });
      return Promise.reject(message);
    }
    try {
      dispatch({
        type: GET_CONTACT_BALANCE
      });
      const response = await axios.get(
        `/api/companies/${user.companyId}/contacts/${contactId}/transactions/balance`
      );

      if (response.status === 200) {
        const data = response.data.data;

        dispatch({
          type: GET_CONTACT_BALANCE_SUCCESS,
          payload: data
        });

        return Promise.resolve(data);
      }
    } catch (error) {
      dispatch({
        type: GET_CONTACT_BALANCE_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const getContactTransactionDebtStatus = (
  contactId,
  params = {
    queryType: 'TODAY',
    companyType: ''
  }
) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) {
      const message = 'user companyId is not defined';
      dispatch({
        type: GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE,
        payload: message
      });
      return Promise.reject(message);
    }
    try {
      if (params) {
        params = {
          'query-type': params?.queryType,
          'company-type': params?.companyType
        };
      }
      dispatch({
        type: GET_CONTACT_TRANSACTION_DEBT_STATUS
      });
      const response = await axios.get(
        `/api/companies/${user.companyId}/contacts/${contactId}/transactions/debt-status`,
        { params }
      );

      if (response.status === 200) {
        const data = response.data.data;

        dispatch({
          type: GET_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS,
          payload: data
        });

        return Promise.resolve(data);
      }
    } catch (error) {
      dispatch({
        type: GET_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const getAllContactTransactionDebtStatus = (
  params = {
    queryType: 'TODAY',
    companyType: ''
  }
) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) {
      const message = 'user companyId is not defined';
      dispatch({
        type: GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE,
        payload: message
      });
      return Promise.reject(message);
    }
    try {
      if (params) {
        params = {
          'query-type': params?.queryType,
          'company-type': params?.companyType
        };
      }
      dispatch({
        type: GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS
      });
      const response = await axios.get(
        `/api/companies/${user.companyId}/contacts/transactions/debt-status`,
        { params }
      );

      if (response.status === 200) {
        const data = response.data.data;

        dispatch({
          type: GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_SUCCESS,
          payload: data
        });

        return Promise.resolve(data);
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_CONTACT_TRANSACTION_DEBT_STATUS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
