import classNames from 'classnames';
import React from 'react';

export default function Footer({ className, ...props }) {
  const footerClass = classNames('p-6 border-t border-solid border-gray-200', className);
  return (
    <footer className={footerClass} {...props}>
      <div className="flex justify-between w-full max-w-6xl mx-auto px-6 text-gray-500 text-sm font-medium leading-5">
        <div>© {new Date().getFullYear()} Norma</div>
        <div className="flex gap-x-8">
          <span className="">Kullanım Koşulları</span>
          <span className="">Sıkça Sorulan Sorular</span>
          <span className="">İletişim</span>
        </div>
      </div>
    </footer>
  );
}
