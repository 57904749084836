import React from 'react';
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2';
import { CHART_FILTER_OPTIONS, CHART_TYPES, currencyFormat } from 'utils';

const DEFAULT_OPTIONS = {
  responsive: true,

  plugins: {
    tooltips: {
      mode: 'label',
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[0].data[tooltipItem.index];
          return label + ': ' + currencyFormat(value);
        }
      }
    },
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: ''
    }
  }
};

export default function Chart({
  type = CHART_TYPES.BAR,
  data = [],
  filterBy,
  locale = 'tr-TR',
  options = DEFAULT_OPTIONS
}) {
  let dataByFilter = {
    ...data,
    labels:
      filterBy.value === CHART_FILTER_OPTIONS.WEEK
        ? data?.labels?.map((label) =>
            new Date(label).toLocaleDateString(locale, { weekday: 'long' })
          )
        : data?.labels?.map((label) => {
            return new Date(label).toLocaleDateString(locale, { month: 'short', day: 'numeric' });
          })
  };
  switch (type) {
    case CHART_TYPES.BAR:
      return <Bar data={dataByFilter} options={options} />;
    case CHART_TYPES.LINE:
      return <Line data={dataByFilter} options={options} />;
    case CHART_TYPES.DOUGHNUT:
      return <Doughnut data={dataByFilter} options={options} />;
    case CHART_TYPES.PIE:
      return <Pie data={dataByFilter} options={options} />;
    default:
      return <>Data Not Found</>;
  }
}
