import { CustomInput } from 'components';
import { useContactContext } from 'contexts/contactContext';

const PersonalInformation = ({ errors }) => {
  const { formValues, setFormValues } = useContactContext();

  const onChange = (e) => {
    const { value, name } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-[18px] text-gray-600 font-semibold">Kişisel Bilgiler</span>
      <CustomInput
        label="İsim"
        onChange={onChange}
        name="firstName"
        only="text"
        maxLength={140}
        value={formValues?.firstName}
        error={errors?.firstName?.message}
        data-testid="input_first_name"
      />
      <CustomInput
        label="Soyisim"
        onChange={onChange}
        name="lastName"
        value={formValues?.lastName}
        only="text"
        maxLength={140}
        error={errors?.lastName?.message}
        data-testid="input_last_name"
      />
    </div>
  );
};

export default PersonalInformation;
