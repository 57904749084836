import { creditCardsTypes } from 'store/types';
import axios from 'axios';

const {
  GET_CREDIT_CARDS,
  GET_CREDIT_CARDS_FAILURE,
  GET_CREDIT_CARDS_SUCCESS,
  ADD_CARD,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE
} = creditCardsTypes;

export const getCreditCards = () => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId) return;

      dispatch({ type: GET_CREDIT_CARDS });

      const response = await axios.get(`/api/payments/card/stored?user-id=${user.userId}`);

      if (response.status === 200) {
        dispatch({
          type: GET_CREDIT_CARDS_SUCCESS,
          data: response.data.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CREDIT_CARDS_FAILURE,
        data: error
      });
    }
  };
};

const initialCardParams = {
  cardAlias: '',
  cardCvc: '000',
  cardExpireMonth: '00',
  cardExpireYear: '00',
  cardNumber: '0',
  cardOwnerName: '',
  clientIp: '',
  transactionId: ''
};

export const addCard = (cardParams = initialCardParams) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId) return;

      dispatch({ type: ADD_CARD });

      const response = await axios.post(
        `/api/payments/card/stored?user-id=${user.userId}`,
        cardParams
      );

      if (response.status === 200) {
        dispatch({
          type: ADD_CARD_SUCCESS,
          data: response.data.data
        });
      }

      return response.data.data;
    } catch (error) {
      dispatch({
        type: ADD_CARD_FAILURE,
        data: error
      });
    }
  };
};
