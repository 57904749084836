import { CustomButton, SideModal } from 'components';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { PAYMENT_STATUS } from 'utils';

import PaymentStatusRadioButtons from './PaymentStatusRadioButtons';
import PaymentStatusPaidTransaction from './PaymentStatusPaidTransaction';
import PaymentStatusNotPaidDatePicker from './PaymentStatusNotPaidDatePicker';
import { updateSaleExpense } from 'store/actions/invoice';
import { useSelector } from 'react-redux';
import { PAYMENT_METHODS, PAYMENT_METHOD_TITLES } from 'views/invoicing/enums';
import PaymentStatusPaidDatePicker from './PaymentStatusPaidDatePicker';

const AddPaymentInformationModal = ({ handleClose, record }) => {
  const { user } = useSelector((state) => state.auth);

  const [paymentStatus, setPaymentStatus] = useState();

  const schema = z.object({
    contactId: z.any(),
    currency: z.any(),
    currencyRate: z.any(),
    dueDate:
      paymentStatus === PAYMENT_STATUS.NOT_PAID
        ? z.date({
            required_error: 'Vade Tarihi girilmesi zorunludur.',
            invalid_type_error: 'Vade Tarihi girilmesi zorunludur.'
          })
        : z.optional(z.date().or(z.null())),
    items: z.any(),
    note: z.any(),
    paymentDate: z.optional(z.date().or(z.null())),
    transferIds: z.any(),
    paymentStatus: z
      .string({ required_error: 'Durum seçilmesi zorunludur.' })
      .nonempty('Durum seçilmesi zorunludur'),
    recordType: z.any(),
    title: z.any(),
    totalTaxAmount: z.any(),
    totalAmount: z.any(),
    paymentMethod: z.string(),
    categoryId: z.any()
  });

  const defaultValues = {
    contactId: record?.contact?.id,
    currency: record?.currency,
    currencyRate: record?.currencyRate,
    items: record?.salesExpenseItems || [],
    note: record?.note || '',
    dueDate: record?.dueDate ? new Date(record?.dueDate) : null,
    paymentDate: record?.paymentDate ? new Date(record?.paymentDate) : null,
    paymentStatus: record?.paymentStatus || PAYMENT_STATUS.PAID,
    recordType: record?.recordType,
    title: record?.title,
    totalTaxAmount: record?.totalTaxAmount,
    totalAmount: record?.totalAmount,
    paymentMethod: record?.paymentMethod,
    categoryId: record?.category?.id,
    transferIds: record?.transfers || []
  };

  const {
    watch,
    setValue,
    formState: { errors },
    clearErrors,
    handleSubmit
  } = useForm({ resolver: zodResolver(schema), defaultValues });

  function onSubmit(data) {
    if (!user?.companyId) return;

    updateSaleExpense({ companyId: user.companyId, salesExpenseRecordId: record?.id, ...data })
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setPaymentStatus(watch('paymentStatus'));
    clearErrors('dueDate');
    clearErrors('paymentDate');
  }, [watch('paymentStatus'), watch('dueDate'), watch('paymentDate')]);

  useEffect(() => {
    if (watch('paymentStatus') === PAYMENT_STATUS.PAID) setValue('dueDate', null);
    if (watch('paymentStatus') === PAYMENT_STATUS.NOT_PAID) setValue('paymentDate', null);
  }, [watch('paymentStatus')]);

  useEffect(() => {
    if (watch('paymentMethod') === PAYMENT_METHODS.NORMA) setValue('paymentDate', null);
    else setValue('transferIds', []);
  }, [watch('paymentMethod')]);

  return (
    <SideModal handleClose={handleClose}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-between min-h-full"
      >
        <div className="flex flex-col">
          <span className="text-3xl font-semibold text-gray-900">Ödeme Bilgisi Ekle</span>
          <span className="font-semibold text-sm text-gray-900 mt-10">Durum</span>
          <PaymentStatusRadioButtons watch={watch} setValue={setValue} />
          <div className="w-full mt-4">
            {watch('paymentStatus') === PAYMENT_STATUS.PAID && (
              <>
                <div className="flex flex-wrap gap-2 my-4">
                  {Object.keys(PAYMENT_METHOD_TITLES).map((paymentMethod) => (
                    <span
                      className={`${
                        watch('paymentMethod') === paymentMethod
                          ? 'bg-green-500 text-green-50'
                          : 'bg-gray-200 text-gray-500'
                      } px-4 py-2 rounded-full min-w-max cursor-pointer`}
                      onClick={() => setValue('paymentMethod', paymentMethod)}
                    >
                      {PAYMENT_METHOD_TITLES[paymentMethod]}
                    </span>
                  ))}
                </div>
                {watch('paymentMethod') === PAYMENT_METHODS.NORMA ? (
                  <PaymentStatusPaidTransaction
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                  />
                ) : (
                  <PaymentStatusPaidDatePicker
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                  />
                )}
              </>
            )}
            {watch('paymentStatus') === PAYMENT_STATUS.NOT_PAID && (
              <PaymentStatusNotPaidDatePicker errors={errors} watch={watch} setValue={setValue} />
            )}
          </div>
        </div>
        <CustomButton color="success" type="submit">
          Kaydet
        </CustomButton>
      </form>
    </SideModal>
  );
};

export default AddPaymentInformationModal;
