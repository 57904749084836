export default {
  4001: 'Girdiğiniz bilgileri kontrol edin.',
  4002: 'Şifreniz 6 haneli ve tekrarlanmaran rakamlardan oluşmaktadır.',
  4003: 'Bu işlem için yetkiniz bulunmamaktadır.',
  4004: 'Girilen değer bulunamadı.',
  4005: 'Dosya işlenirken bir hata oluştu.',
  4006: 'HTTP metodu desteklenmiyor.',
  4007: 'Girilen değer geçersiz.',
  4008: 'Girilen değer geçersiz.',
  4009: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.',
  4010: 'E-posta adresi geçersiz.',
  4011: 'Alanlar boş olamaz.',
  4012: 'Geçmiş bir tarih verilemez.',
  4013: 'TC kimlik numarası geçerli değil.',
  4014: 'Vergi kimlik numarası geçerli değil.',
  4015: 'IBAN geçerli değil.',
  4016: 'Telefon numarası geçerli değil.',
  4017: 'Girilen değer geçerli değil.',
  4100: 'Tarih formatı hatalı',
  5000: 'Bir hata oluştu.',
  5001: 'Dosya tipi desteklenmiyor.',
  5002: 'Dosya PDF formatında olmamalı.',
  5003: 'Dosya çok büyük.',
  5004: 'Tarih aralığı geçerli değil.',
  10001: 'Kimlik bilgisi geçerli değil.',
  10002: 'İstemci kimliği doğrulanamadı.',
  10003: 'İstemci ID token ile eşleşmiyor.',
  10004: 'Geçerli istemci kimliği bulunamadı.',
  10005: 'Erişim tipi desteklenmiyor.',
  10006: 'Kullanıcı tipi bu akış için uygun değil.',
  11001: 'Telefon numarası geçerli değil.',
  12001: 'Değer göndermek için servis bulunamadı.',
  12002: 'Bildirim şablonu bulunamadı.',
  12003: 'Bildirim izni bulunamadı.',
  12101: 'SMS gönderilirken bir hata oluştu.',
  12201: 'E-posta gönderilirken bir hata oluştur.',
  12202: 'E-posta gönderme limiti aşıldı.',
  12500: 'Bildirim şablonu bulunamadı.',
  12501: 'Bildirim izni bulunamadı.',
  13001: '{0} numaralı adımdan devam edilmeli. Link: {1}',
  13002: 'Kayıt anahtarı geçerli değil.',
  13003: 'Başka bir kullanıcı bu kayıt işleminde aktif.',
  13004: 'Kullanıcı kaydı tamamlanmış.',
  13005: 'Kullanıcı kaydı tamamlanmamış.',
  13006: 'Kullanıcı yaşı uygun değil.',
  13101: 'E-posta adresi kayıtlı.',
  13102: 'E-posta adresi onaylanmadı.',
  13201: 'Telefon numarası kayıtlı.',
  13202: 'Telefon numarası onaylanmadı.',
  13301: 'Kullanıcının kimlik bilgileri eksik.',
  13302: 'Kullanıcının TC kimlik numarası ile kimlik bilgileri uyuşmamaktadır.',
  13303: 'TC kimlik numarası kayıtlı.',
  13304: 'TC kimlik numarası geçerli değil.',
  13401: 'Şifreler birbiriyle uyuşmamaktadır.',
  13402: 'Şifre eksik.',
  13403: 'Geçersiz karakter girişi',
  13404: 'Geçersiz karakter girişi',
  13405: 'Geçersiz karakter girişi',
  13406: 'Geçersiz karakter girişi',
  13407: 'Geçersiz karakter girişi',
  13408: 'Geçersiz karakter girişi',
  13409: 'Şifre çok uzun',
  13410: 'Şifre çok kısa',
  13500: 'Kimlik numarası geçerli değil.',
  14001: 'Kullanıcı bulunamadı.',
  14100: 'Kullanıcı rolü bulunamadı.',
  14200: 'Bildirim izni bulunamadı.',
  15001: 'MFA anahtarı geçerli değil.',
  15002: 'MFA anahtarı boş olamaz.',
  15003: 'MFA anahtarının süresi dolmuş.',
  15004: 'MFA servisi bulunamadı.',
  15005: 'MFA istenilen e-posta adresi ile gönderilen e-posta adresi uyuşmamaktadır.',
  15101: 'Tek kullanımlık şifre anahtarının süresi doldu.',
  15102: 'Geçersiz tek kullanımlık şifre.',
  15103: 'OTP anahtarı boş olamaz.',
  15104: 'OTP anahtarı kullanılmış.',
  15105: 'Gönderilmiş bir SMS anahtarı bulunamadı.',
  15106: 'SMS anahtarı onaylamadı.',
  15107: 'Tek kullanımlık şifre isteği limiti aşıldı.',
  15108: 'Yeni istek yapabilmek için 60 saniye beklemelisiniz.',
  16001: 'Cüzdan servisine bağlanırken bir hata oluştu.',
  16002: 'Cüzdan silinemedi.',
  16003: 'Cüzdan listelenemedi.',
  16004: 'Cüzdan detayına erişilemedi.',
  16005: 'Kart özelleştirilemedi.',
  16101: 'Cüzdana giriş yapılamadı.',
  16201: 'Cüzdan kullanıcısı yaratılamadı.',
  16202: 'Cüzdan kullanıcısı yaratılırken tek kullanımlık şifre aşamasında sorun yaşandı.',
  16203:
    'Cüzdan kullanıcısı yaratılırken tekrar tek kullanımlık şifre gönderme aşamasında sorun yaşandı.',
  16204: 'Cüzdan kullanıcısı listelenemedi.',
  16301: 'Cüzdana banka hesabı eklenemedi.',
  16302: 'Cüzdanda bulunan banka hesabı gösterilemedi.',
  16303: 'Cüzdanda bulunan banka hesabı silinemedi.',
  16401: 'Cüzdandan cüzdana transfer işlemi gerçekleştirilemedi.',
  16402: 'Cüzdandan cüzdana transfer işlemi gerçekleştirilemedi.',
  16403: 'Cüzdan döviz değerleri gösterilemedi.',
  16404: 'Cüzdan döviz transferi onaylanamadı.',
  16405: 'Cüzdan döviz transferi tamamlanamadı.',
  16406: 'Cüzdan depozitosu geçerli değil.',
  16407: 'Cüzdandan cüzdana transfer işlemi gerçekleştirilemedi.',
  16501: 'Düzenli ödeme oluşturulamadı.',
  16502: 'Düzenli ödeme oluşturulamadı.',
  16503: 'Düzenli ödeme güncellenemedi.',
  16504: 'Düzenli ödeme görüntülenemedi.',
  16505: 'Düzenli ödeme tamamlanamadı.',
  17001: 'Kullanıcının aynı isimde başka bir hesabı bulunuyor.',
  17002: 'Hesap bulunamadı.',
  17003: 'Hesap durumu bu işlem için geçerli değil.',
  17004: 'Hesabınızı artı bakiye ile kapatamazsınız.',
  17005: 'Güncelleme sebebi boş olamaz.',
  17006: 'Ana hesabın döviz kuru TL olmalıdır.',
  17007: 'Hesap farklı bir kura sahip.',
  17008: 'Hesap farklı bir şirkete ait.',
  17009: 'Ana hesap kapatılamaz.',
  17010: 'Değistirilmek istenen hesap zaten ana hesap.',
  17011: 'Kapatılmış bir hesap ana hesap yapılamaz.',
  17012: 'Kimlik doğrulama yapılmamış bir şirket için döviz hesabı açılamaz.',
  17013: 'Bu işlem sadece ana hesap için yapılabilir.',
  17014: 'Hesap ismi 15 karakterden çok olamaz.',
  17015: 'Hesap ismi daha önceden alınmış.',
  17016: 'Hesap oluşturulamadı.',
  17017: 'Transfer hesabı bulunamadı.',
  17018: 'Transfer hesabı bulunamadı.',
  17100: 'Transfer hesabı bulunamadı.',
  18001: 'QR kod oluşturma yetkiniz yok.',
  18002: 'QR kod oluşturulamadı.',
  18003: 'QR kod oluşturulamadı.',
  19001: 'Şirket bulunamadı.',
  19002: 'Aynı isimde şirket mevcut.',
  19003: 'Şirket kurulumu için yanlış adım.',
  19004: 'Şirket aktif değil.',
  19005: 'Şirket onay bekliyor.',
  20001: 'Transfer gerçekleştirilemedi.',
  20002: 'Transfer bulunamadı.',
  20003: 'Alıcı kimliği geçerli değil.',
  20004: 'Transfer durumu güncellenemez.',
  20005: 'Transfer durumu güncellenemedi.',
  20009: 'Döviz transferi yapılamaz.',
  20010: "Transfer edilecek tutar 0'dan büyük olmalıdır.",
  20011: 'Çapraz kur desteklenmemektedir.',
  20012: 'Aynı hesap içinde transfer yapılamaz.',
  20013: 'Gönderen ve alıcı hesapların döviz cinsi aynı olmalıdır.',
  20014: 'Döviz transferi yapılamaz.',
  20015: 'Alıcı adı hatalıdır.',
  20016: 'Gönderen hesap bulunamadı.',
  20017: 'Alıcı hesap bulunamadı.',
  20018: 'Gönderen ve alıcı hesapların döviz cinsi aynı olmalıdır.',
  20019: 'Gönderen hesap bulunamadı.',
  20020: 'Alıcı hesap bulunamadı.',
  20021: 'Gönderen ve alıcı hesapların döviz cinsi aynı olmalıdır.',
  20201: 'Aynı isimde kayıt mevcut.',
  20300: 'Gönderen hesap bulunamadı.',
  20301: 'Alıcı hesap bulunamadı.',
  20302: 'Gönderen ve alıcı hesapların döviz cinsi aynı olmalıdır.',
  21001: 'Yetersiz bakiye.',
  21002: 'Yetersiz bakiye.',
  22001: 'Dosya yüklenemedi.',
  22002: 'Dosya tipi desteklenmiyor.',
  24001: 'Tekrarlayan ödeme bulunamadı.',
  24002: 'Tekrarlayan ödeme TL dışında yabancı bir para birimi ile yaratılamaz',
  24003: 'Tekrarlayan ödeme için belirtilen baslangıç ve bitiş tarihleri uygun değildir',
  25001: 'Daha önceden yapılmış kart isteği var',
  25002: 'Kart numarisinin son kismi 4 haneden oluşmalıdır',
  25003: 'Kart daha önceden bir hesap ile eşleştirilmiş',
  25004: 'Hesap daha önceden baska bir kart ile eşleştirilmiş',
  25005: 'Kart durumu aktive edilmemiş',
  25006: 'Kart ilişkilendirme hatası',
  25007: 'Kart ilişkilendirme hatası',
  25008: 'Yakın zamanda istediğiniz kart işlemi bulunmaktadır',
  26001: 'Ülke bulunamadı',
  27001: 'Şehir bulunamadı',
  28001: 'Cüzdan referans numarası daha önceden kaydedilmiş',
  28002: 'TL dışında bir kur hesaba aktarılamaz',
  28003: 'Toplam harcama limiti yetersiz',
  29001: 'Toplam harcama limiti yetersiz',
  29002: 'Toplam bakiye limiti yetersiz',
  29003: 'Alıcının havale limiti yetersiz',
  29004: 'Alıcının bakiye limiti yetersiz',
  29005: 'KYC daha önceden tanımlanmış',
  29006: 'KYC tanımlanmamış',
  30001: 'Yabancı para birimin ile transfer yapılamaz',
  31001: 'Entegrasyon hatası',
  31002: 'Ürün kodu daha onceden kaydedilmiş',
  31003: 'Fatura entegrasyon hatası',
  31004: 'Fatura ile işlem para birimleri uyuşmuyor',
  31005: 'Toplam fatura değeri tahsil edilen değerden büyük olmalıdır',
  31006: 'Fatura ile ödeme para birimleri uyuşmuyor',
  31007: 'Kur oranı girilmeli',
  31008: 'Tahsilat tarihi 7 günden önceki değer olamaz',
  31009: 'Ödeme tarihi 7 günden önceki değer olamaz',
  31010: 'Kayıt için E-fatura işlemi yapıldığından güncelleme yapılamaz',
  31011: 'Para girişi olan transfer seçilmeli',
  31012: 'Para çıkışı yapılan transfer seçilmeli',
  31013: 'Ödeme yöntemi boş olamaz',
  31014: 'Ödeme tarihi boş olamaz',
  31015: 'Kayıt tipi Genel olmalı',
  31016: 'Kayıt tipi Normal olmalı',
  32001: 'Rapor oluştururken bir hata oluştu',
  33001: 'Müşteri/Tedarikçi adres bilgisi eksik',
  33002: 'Müşteri/Tedarikçi fatura ile eşleşmiş',
  33003: 'Müşteri/Tedarikçi güncellenemez',
  34001: 'Transfer kategorisi bulunamadı',
  34002: 'Transfer kategorisi ismi daha önceden yaratilmiş',
  34005: 'Kategori silinemez',
  34006: 'Kategori en az bir fatura ile ilişkilendirilmiş',
  34007: 'Geçersiz ikon bilgisi',
  34008: 'Geçersiz renk kodu',
  34009: 'Kategori silinemez',
  34010: 'Kategori ismi güncellenemez',
  34011: 'Kategori ikon bilgisi bulunamadı',
  34012: 'Kategori rengi bulunamadı',
  34100: 'Geçersiz ikon bilgisi',
  34101: 'Geçersiz renk kodu',
  35001: 'Vergi dairesi bulunamadı',
  36001: 'Şifre hatalı karakter içeriyor',
  36002: 'Şifre hatalı karakter içeriyor',
  36003: 'Şifre sadece rakamlardan oluşmalıdır',
  36004: 'Şifre sadece rakamlardan oluşmalıdır',
  36005: 'Şifre sadece rakamlardan oluşmalıdır',
  36006: 'Şifre boşluk karakteri içeremez',
  36007: 'Şifre çok uzun',
  36008: 'Şifre çok kısa',
  37001: 'Yetki hatası',
  38001: 'E-imza başvurusu zaten yapılmış',
  39001: 'Satış tipinde olmayan ürün ile işlem yapılamaz',
  40001: 'Doküman silinmiş',
  41001: 'Ödeme tutarı boş olamaz',
  41002: 'KDV oranı boş olamaz',
  41003: 'Gider dokümanı zaten yüklenmiş',
  41004: 'Ödeme tarihi boş olamaz',
  41005: 'Ödeme yöntemi boş olamaz',
  41006: 'Vade tarihi boş olamaz',
  41007: 'Ödeme tutarı boş olamaz',
  41008: 'Satış kaydı yaratılamaz',
  41009: 'Taslak kaydı yaratılamaz',
  41010: 'Gider kaydı yaratılamaz',
  41011: 'Ürün/Hizmet boş olamaz',
  41012: 'Toplam tutar ile ödenen tutar aynı olmalıdır',
  41013: 'Fatura kaydı silinemedi',
  41014: 'E-belge kaydı silinemedi',
  41015: 'Ürün/Hizmet para birimleri aynı olmalıdır',
  42001: 'Döküman durumu güncellenemez',
  42002: 'Doküman zaten silinmiş',
  42003: 'Doküman parametresi geçersiz',
  42004: 'Doküman güncellenemedi',
  42005: 'Doküman silinemedi',
  42006: 'Doküman açıklaması boş olamaz'
};
