import React from 'react';
import { Button, CustomInput, Label } from 'components';
import { useSelector } from 'react-redux';
import SelectAccount from 'views/transactions/components/SelectAccount';
import { useSmartOperationContext } from 'views/transactions/subPages/smartOperations';
import AmountInput from 'components/AmountInput';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import RecurringDateSelection from '../recurringDateSelection';
import {
  AUTOMATIC_TRANSACTION_REPEAT_TYPES,
  COMPANY_TYPES,
  getFormattedDateForPayload
} from 'utils';
import { createAutomaticTransaction } from 'store/actions/automatic';
import { useDispatch } from 'react-redux';
import { CURRENCIES } from 'views/other/items/enums';

export default function ToNormaNumber() {
  const { automaticTransactionFormValues, setAutomaticTransactionFormValues, nextStep } =
    useSmartOperationContext();

  const accounts = useSelector((state) => state.accounts).filter(
    (account) => account.currencyCode === CURRENCIES.TRY
  );
  const dispatch = useDispatch();
  const REPEAT_TYPE_MAP = Object.entries(AUTOMATIC_TRANSACTION_REPEAT_TYPES).map(
    ([value, type]) => ({
      label: type.label,
      value
    })
  );

  const onChangeRecordName = (e) => {
    const { value } = e.target;
    setAutomaticTransactionFormValues((formValues) => ({ ...formValues, recordName: value }));
  };

  const onChangeSenderWallet = (wallet) => {
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      senderWallet: wallet
    }));
  };

  const onChangeRepeatType = (type) => {
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      repeatType: type
    }));

    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      transferDay: null
    }));
  };

  const onChangeRepeatCount = (e) => {
    const { value } = e.target;
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      repeatCount: value
    }));
  };

  const onChangePaymentStartDate = (date) => {
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      paymentStartDate: date
    }));
  };

  const onChangeTransferDay = (day) => {
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      transferDay: day
    }));
  };

  const onChangeAmount = (amount) => {
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      amount
    }));
  };

  const onChangeReceiverFullName = (e) => {
    const { value } = e.target;
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      receiverFullName: value
    }));
  };

  const onChangeReceiverWalletNumber = (e) => {
    const { value } = e.target;
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      receiverWalletNumber: value
    }));
  };

  const onSubmit = () => {
    const {
      amount,
      contact,
      paymentStartDate,
      receiverFullName,
      repeatType,
      transferDay,
      recordName,
      repeatCount,
      receiverWalletNumber,
      receiverType,
      senderWallet
    } = automaticTransactionFormValues;

    const payload = {
      amount,
      receiverType,
      paymentStartDate: getFormattedDateForPayload(paymentStartDate),
      receiverFullName:
        receiverFullName ||
        (contact?.companyType === COMPANY_TYPES.BUSINESS
          ? contact?.companyName
          : `${contact?.firstName} ${contact?.lastName}`),
      receiverWalletNumber,
      recordName,
      repeatCount: repeatCount || 1,
      repeatType: repeatType?.value || null,
      transferDay: transferDay?.value || 0
    };

    console.log('payload', payload);
    dispatch(createAutomaticTransaction(senderWallet?.id, payload)).then(() => nextStep());
  };

  const {
    recordName,
    repeatCount,
    transferDay,
    amount,
    repeatType,
    paymentStartDate,
    receiverWalletNumber,
    senderWallet
  } = automaticTransactionFormValues;

  const isFormValid =
    repeatType &&
    repeatCount > 0 &&
    repeatCount <= 99 &&
    ([
      AUTOMATIC_TRANSACTION_REPEAT_TYPES.MONTHLY.value,
      AUTOMATIC_TRANSACTION_REPEAT_TYPES.WEEKLY.value
    ].includes(repeatType.value)
      ? transferDay
      : true) &&
    recordName?.length > 2 &&
    recordName?.length <= 32 &&
    amount > 0 &&
    receiverWalletNumber &&
    receiverWalletNumber?.length === 10 &&
    senderWallet &&
    paymentStartDate;

  return (
    <div className="flex flex-col gap-y-6 !pb-24">
      <div className="flex gap-x-6 w-full">
        <CustomInput
          label="Kayıt İsmi"
          className="flex-1"
          onChange={onChangeRecordName}
          value={recordName}
          maxLength={32}
        />
        <CustomInput
          label="Alıcı İsim Soyisim / Unvan"
          className="flex-1"
          onChange={onChangeReceiverFullName}
        />
      </div>
      <CustomInput
        label="Norma No"
        className="flex-1"
        only="number"
        maxLength={10}
        onChange={onChangeReceiverWalletNumber}
      />
      <RecurringDateSelection
        repeatTypes={REPEAT_TYPE_MAP}
        onChangeRepeatType={onChangeRepeatType}
        onChangeRepeatCount={onChangeRepeatCount}
        onChangeTransferDay={onChangeTransferDay}
        onChangePaymentStartDate={onChangePaymentStartDate}
      />
      <Label label="Gönderen Hesabı Seç" textClassName="font-semibold text-lg text-gray-900">
        <SelectAccount
          className={'!m-0'}
          accounts={accounts}
          selectedAccount={automaticTransactionFormValues?.senderWallet}
          setSelectedAccount={onChangeSenderWallet}
        />
      </Label>
      <AmountInput
        inputClassName={`!bg-white border border-gray-200 !p-6 text-right text-[28px] text-semibold ${
          (automaticTransactionFormValues.amount === 0 ||
            automaticTransactionFormValues.amount === null) &&
          'text-gray-400'
        }`}
        label="Tutar"
        labelClassName="font-semibold text-lg text-gray-900"
        placeholder="0,00 TL"
        suffix={` ${CURRENCY_NAMES.TRY}`}
        value={automaticTransactionFormValues.amount}
        onValueChange={onChangeAmount}
      />
      <Button className="w-full" onClick={onSubmit} disabled={!isFormValid}>
        Devam
      </Button>
    </div>
  );
}
