import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { BottomModal, Card, CodeInput, CustomButton, CustomInput } from 'components';
import { cardAssociate } from 'store/actions/card';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';

import CardCorrect from 'assets/illustrations/card/card-correct.png';

const defaultValues = {
  cardLastFourDigits: '',
  barcode: '',
  cardPassword: ''
};

const StepHeader = ({ title, subtitle }) => (
  <div className="flex flex-col">
    <span className="text-gray-400 text-sm font-semibold">{title}</span>
    <span className="text-gray-900 text-xl font-semibold">{subtitle}</span>
  </div>
);

const Step0 = ({ nextStep, watch, setValue }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'card_associate' }), []);

  return (
    <div className="w-96 flex flex-col items-start" data-testid="section_modal_add_card">
      <StepHeader
        data-testid="label_step_add_card"
        title="Norma Kart Ekle"
        subtitle="Norma Kart bilgilerinizi girin"
      />
      <Card className="mt-8 w-full">
        <CustomInput
          label="Norma Kart Son 4 Hanesi"
          maxLength={4}
          only="number"
          onChange={(e) => setValue('cardLastFourDigits', e.target.value)}
          data-testid="input_add_card_last_four_digits"
          autoFocus
        />
        <CustomInput
          label="Barkod"
          onChange={(e) => setValue('barcode', e.target.value)}
          only="number"
          maxLength={13}
          data-testid="input_add_card_barcode"
          className="mt-6"
        />
        <CustomButton
          disabled={watch('cardLastFourDigits').length !== 4 || watch('barcode').length !== 13}
          onClick={nextStep}
          className="mt-6 w-full"
          data-testid="button_add_card_next"
          color="success"
        >
          Kartını Ekle
        </CustomButton>
      </Card>
    </div>
  );
};

const Step1 = ({ watch, setValue }) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'card_associate_pin' }), []);

  const isValid =
    !PASSWORD_SEQUENTIAL_REGEX.test(watch('cardPassword')) &&
    !PASSWORD_REPEATING_REGEX.test(watch('cardPassword')) &&
    watch('cardPassword').length === 4;

  return (
    <div className="w-96 flex flex-col items-start">
      <StepHeader
        data-testid="label_step_add_card_pin"
        title="Norma Kart Ekle"
        subtitle="Norma Kart şifrenizi 4 haneli olarak belirleyin"
      />
      <CodeInput
        length={4}
        invisible={true}
        className="mt-10"
        onChange={(e) => setValue('cardPassword', e)}
        only="number"
        data-testid="input_add_card_pin"
        blockPaste
        autofocus
      />
      <CustomButton
        className="mt-12 w-full"
        color="success"
        disabled={!isValid}
        type="submit"
        data-testid="button_add_card_next"
      >
        Onayla
      </CustomButton>
    </div>
  );
};

const Step2 = ({ toggleAddCardModal }) => {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'card_associate_success' });
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'add_card_success'
    });
  }, []);

  return (
    <div className="w-[286px] flex flex-col items-center text-center">
      <img src={CardCorrect} width={200} alt="card-correct" />
      <span className="text-white text-2xl mt-8">Norma Kart başarıyla hesabınıza eklendi</span>
      <CustomButton className="w-full mt-4" color="success" onClick={toggleAddCardModal}>
        Tamam
      </CustomButton>
    </div>
  );
};

const AddCardModal = ({ toggleAddCardModal, primaryAccountId, getCards }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  function nextStep() {
    setStep(step + 1);
  }

  const { watch, setValue, handleSubmit } = useForm({ defaultValues });
  function onSubmit(data) {
    const payload = {
      barcodeCode: data?.barcode,
      lastDigitsOfCard: data?.cardLastFourDigits,
      password: data?.cardPassword
    };
    dispatch(cardAssociate({ accountId: primaryAccountId, ...payload }))
      .then(() => {
        nextStep();
        getCards();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const STEPS = {
    0: <Step0 nextStep={nextStep} watch={watch} setValue={setValue} />,
    1: <Step1 watch={watch} setValue={setValue} />,
    2: <Step2 toggleAddCardModal={toggleAddCardModal} />
  };

  return (
    <BottomModal handleClose={toggleAddCardModal} className={step === 2 && 'bg-green-500'}>
      <div className="w-full h-full flex items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>{STEPS[step]}</form>
      </div>
    </BottomModal>
  );
};

export default AddCardModal;
