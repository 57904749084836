import React from 'react';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';

export default function ToastThemeWithTitle({ icon: Icon, title, message }) {
  return (
    <div className="flex gap-x-1 bg-green-500 text-white text-sm rounded-xl p-5">
      <div className="flex items-center">
        <div>{Icon && <Icon className="w-5 h-5 fill-current mr-2" />}</div>
        <div>
          <div className="font-semibold ">{title}</div>
          <div>{message}</div>
        </div>
      </div>
      <div>
        <IconClose className="w-5 h-5 stroke-current font-semibold text-white cursor-pointer" />
      </div>
    </div>
  );
}
