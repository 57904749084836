import React from 'react';
import { SideModal } from 'components';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { SERVICE_PACKAGE_TYPES } from 'utils/contants';

export default function ServiceDetailModal({ data, onClose, ...props }) {
  const { featureDescription, featureTitle, features, name, note } = data;

  return (
    <SideModal handleClose={onClose} {...props}>
      <h3 className="mb-6">{SERVICE_PACKAGE_TYPES[name].value}</h3>
      <p className="font-semibold text-gray-700 mb-2">{featureTitle}</p>
      <p className="text-sm font-medium text-gray-500 leading-5">{featureDescription}</p>
      <div className="my-8">
        <h4 className="text-base leading-6 text-gray-700 mb-4">Hizmetler</h4>
        <div className="flex flex-col gap-y-3">
          {features?.map((item, i) => (
            <div key={i} className="flex items-center gap-x-2">
              <span className="font-semibold text-[14px] text-gray-700">
                {<IconCheck className="stroke-current text-green-500" width={20} height={20} />}
              </span>
              {item.description}
            </div>
          ))}
        </div>
      </div>
      <div className="border border-gray-200 border-solid rounded-lg p-4 bg-gray-100">
        <p className="text-xs leading-4 text-gray-500 font-medium mb-1">
          {SERVICE_PACKAGE_TYPES[name].value}
        </p>
        <p className="text-[14px] text-gray-900 leading-5 font-semibold">{note}</p>
      </div>
    </SideModal>
  );
}
