import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Card,
  Checkbox,
  CustomInput,
  Divider,
  EmptyView,
  Label,
  Popup,
  SelectedAccountView,
  SideModal
} from 'components';
import { Prompt } from 'components/promptModal';
import StepHeader from 'components/stepHeader';
import Chip from 'components/Chip';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteBillSubscription,
  getBills,
  getBillSubscriptions,
  updateBillSubscription
} from 'store/actions/bill';
import { BILL_STATUSES, currencyFormat, dateFormat } from 'utils';
import useModal from 'utils/hooks/useModal';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { BillContext } from '../..';

import { ReactComponent as IconDelete } from 'assets/icons/trash.svg';
// import { getWallets } from 'store/actions/accounts';
// import { WALLET_TYPES } from 'utils/contants';
import { CURRENCIES } from 'views/other/items/enums';
import useTextShortener from 'utils/hooks/useTextShortener';
import SelectAccount from 'views/transactions/components/SelectAccount';

export default function RecordedBills({ isNotDirectPayment }) {
  const {
    selectedSubscription,
    billPaymentFormValues,
    nextStep,
    setSelectedBill,
    selectedPaymentAccount
  } = useContext(BillContext);
  const [isEditRecordBillActive, , toggleEditRecordBill] = useModal();
  const [isAccountSelectActive, , toggleAccountSelect] = useModal();

  const dispatch = useDispatch();

  const { bills } = useSelector((state) => state.bill);

  useEffect(() => {
    dispatch(getBillSubscriptions());
    dispatch(
      getBills({
        corporationId:
          selectedSubscription?.corpCode || billPaymentFormValues?.corporation?.value,
        subscriberNo: selectedSubscription?.subscriberNo || billPaymentFormValues.subscriberNo
      })
    );
  }, []);

  const onOpenSelectAccountPopup = (bill) => {
    setSelectedBill(bill);
    if (selectedPaymentAccount) {
      nextStep();
    } else {
      toggleAccountSelect();
    }
  };
  return (
    <div className="w-full flex flex-col gap-y-8">
      {isEditRecordBillActive && <EditRecordedBill onClose={toggleEditRecordBill} />}
      {isAccountSelectActive && <SelectAccountPopup onClose={toggleAccountSelect} />}
      <div className="flex items-center justify-between">
        <StepHeader
          title={isNotDirectPayment ? 'Fatura İşlemleri' : 'Fatura Ödeme'}
          subtitle={isNotDirectPayment ? 'Kayıtlı Faturalar' : 'Faturalar'}
        />
        {isNotDirectPayment && (
          <Button color="secondary" onClick={toggleEditRecordBill}>
            Düzenle
          </Button>
        )}
      </div>
      <BillInformationCard isNotDirectPayment={isNotDirectPayment} />

      {bills?.bills?.length > 0 && (
        <div className="flex flex-col gap-y-8 p-2 h-full max-h-80 overflow-y-auto">
          {bills?.bills?.map((billRecord, i) => (
            <UnPaidBillsCard
              key={billRecord?.id || i}
              bill={billRecord}
              onOpenSelectAccountPopup={onOpenSelectAccountPopup}
            />
          ))}
        </div>
      )}
      {!bills?.bills && <EmptyView classname="mt-16">Fatura Bulunamadı</EmptyView>}
    </div>
  );
}

const BillInformationCard = ({ isNotDirectPayment }) => {
  const { selectedSubscription, billPaymentFormValues } = useContext(BillContext);
  const { bills } = useSelector((state) => state.bill);

  const recordName = useTextShortener(selectedSubscription?.recordName, 10);
  const corpName = useTextShortener(
    billPaymentFormValues?.corporation?.label || bills?.corpName,
    15
  );

  return (
    <Card className="bg-gray-50 !shadow-none flex items-center gap-x-14 px-6">
      {isNotDirectPayment && (
        <div>
          <p className="text-sm font-semibold leading-5">{recordName}</p>
          {selectedSubscription?.autoPayment && <Chip colorHexCode="#EEF8D3" />}
        </div>
      )}
      <div className="flex gap-x-11">
        {isNotDirectPayment && (
          <>
            <LineInfo title="Abone Adı" description={bills?.subscriberName} />
            <Divider isRight />
          </>
        )}
        <LineInfo
          title="Abone No"
          description={billPaymentFormValues?.subscriberNo || bills?.subscriberNo}
        />
        <Divider isRight />
        <LineInfo title="Kurum Adı" description={corpName} />
      </div>
    </Card>
  );
};

const UnPaidBillsCard = ({ onOpenSelectAccountPopup, bill, ...props }) => {
  return (
    <Card className="flex items-center justify-between" {...props}>
      <div className="flex gap-x-11">
        <LineInfo
          title="Son Ödeme Tarihi"
          description={dateFormat(new Date(bill?.invoiceDueDate), '/')}
          descriptionClassName={bill?.invoiceStatus === BILL_STATUSES.LATE ? '!text-red-500' : ''}
        />
        <LineInfo title="Fatura Numarası" description={bill?.invoiceNo} />
      </div>
      <div className="flex items-center gap-x-8">
        <span className="text-xl font-semibold leading-7">
          {currencyFormat(bill?.totalAmount)} {CURRENCY_NAMES.TRY}
        </span>
        <Button onClick={() => onOpenSelectAccountPopup(bill)}>Ödeme Yap</Button>
      </div>
    </Card>
  );
};

const LineInfo = ({ title, description, className, titleClassName, descriptionClassName }) => {
  const lineInfoClassNames = classNames('flex flex-col gap-y-2 w-full min-w-max', className);
  const titleClassNames = classNames(
    'font-medium text-xs text-gray-500 leading-4',
    titleClassName
  );
  const descriptionClassNames = classNames(
    'text-xs font-semibold leading-4 text-gray-700',
    descriptionClassName
  );
  return (
    <div className={lineInfoClassNames}>
      <p className={titleClassNames}>{title}</p>
      <p className={descriptionClassNames}>{description}</p>
    </div>
  );
};

const EditRecordedBill = ({ onClose }) => {
  const {
    selectedSubscription,
    setBillPaymentFormValues,
    setSelectedSubscription,
    setStep,
    billPaymentFormValues,
    setSelectedPaymentAccount
  } = useContext(BillContext);

  const [isDeleteSubscriptionRecordActive, , toggleDeleteSubscriptionRecordPrompt] = useModal();
  const [recordName, setRecordName] = useState(selectedSubscription?.recordName);
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts).filter(
    (wallet) => wallet.currencyCode === CURRENCIES.TRY
  );

  useEffect(() => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      autoPayment: selectedSubscription?.autoPayment,
      wallet: accounts.find((account) => account?.id === selectedSubscription?.walletId)
    }));
  }, []);

  const onSave = () => {
    dispatch(
      updateBillSubscription({
        subscriptonId: selectedSubscription?.id,
        recordName: recordName,
        autoPayment: billPaymentFormValues?.autoPayment,
        walletId: billPaymentFormValues?.wallet?.id
      })
    ).then(() => {
      setBillPaymentFormValues((prevValues) => ({
        ...prevValues,
        billRecordName: recordName
      }));
      const updatedSubscription = { ...selectedSubscription, recordName };
      setSelectedSubscription(updatedSubscription);
      dispatch(getBillSubscriptions());
      onClose();
    });
  };

  const onDelete = () => toggleDeleteSubscriptionRecordPrompt();

  const onDeleteApproved = () => {
    dispatch(deleteBillSubscription(selectedSubscription?.id)).then(() => {
      toggleDeleteSubscriptionRecordPrompt();
      setStep(3);
    });
  };

  const onChangeRecordName = (e) => {
    setRecordName(e.target.value);
  };

  const onWalletSelected = (wallet) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      wallet
    }));
    setSelectedPaymentAccount(wallet);
  };

  const onChangeAutoPayment = (e) => {
    const status = e.target.checked;

    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      autoPayment: status,
      wallet: status ? prevValues?.wallet : null
    }));
  };

  return (
    <SideModal handleClose={onClose}>
      <Prompt
        isActive={isDeleteSubscriptionRecordActive}
        className="max-w-xs mx-auto"
        title={
          <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-100 mx-auto">
            <IconDelete className="fill-current w-10 h-10 text-red-600" />
          </div>
        }
        description={
          <p className="!font-medium !text-base !leading-6 !text-gray-900">
            Kayıtlı faturanızı silmek istediğinize emin misiniz?
          </p>
        }
        okText="Evet"
        cancelText="Vazgeç"
        onOK={onDeleteApproved}
        onCancel={toggleDeleteSubscriptionRecordPrompt}
      />
      <div className="flex flex-col justify-between h-full pb-10">
        <div>
          <p className="font-semibold text-[28px] leading-8 mb-8">Kayıt Düzenle</p>
          <div className="flex flex-col gap-y-6">
            <CustomInput
              label="Hesap İsmi"
              defaultValue={recordName}
              maxLength={32}
              onChange={onChangeRecordName}
            />
            <CustomInput
              label="Kurum Adı"
              defaultValue={selectedSubscription?.corpName}
              disabled
            />
            <CustomInput
              label="Abone No"
              defaultValue={selectedSubscription?.subscriberNo}
              disabled
            />
            {/* {!selectedSubscription?.autoPayment && ( */}
            <Checkbox
              disabled={selectedSubscription.autoPayment}
              checked={billPaymentFormValues?.autoPayment}
              onChange={onChangeAutoPayment}
            >
              Otomatik ödeme olarak kaydet
            </Checkbox>
            {/* )} */}
            {!selectedSubscription?.autoPayment && billPaymentFormValues?.autoPayment && (
              <Label label="Ödeme Yapılacak Hesap">
                <SelectAccount
                  className="bg-white"
                  accounts={accounts}
                  selectedAccount={billPaymentFormValues?.wallet}
                  setSelectedAccount={onWalletSelected}
                />
              </Label>
            )}
            {selectedSubscription?.autoPayment && (
              <Label label="Ödeme Yapılacak Hesap">
                <SelectedAccountView
                  name={accounts.find(({ id }) => id === selectedSubscription?.walletId)?.name}
                  amount={
                    accounts.find(({ id }) => id === selectedSubscription?.walletId)?.balance
                      ?.balance
                  }
                  verticalView
                  disabled
                />
              </Label>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <Button onClick={onSave}>Kaydet</Button>
          <Button color="red" onClick={onDelete}>
            Sil
          </Button>
        </div>
      </div>
    </SideModal>
  );
};

const SelectAccountPopup = ({ onClose }) => {
  const accounts = useSelector((state) => state.accounts);
  const { selectedPaymentAccount, setSelectedPaymentAccount, nextStep } = useContext(BillContext);
  const onAccountSelected = () => nextStep();
  return (
    <Popup
      active
      childrenClassName="!w-full max-w-[460px] h-[420px] !p-6 flex flex-col gap-y-6 rounded-xl"
      onClose={onClose}
    >
      <StepHeader
        title="Hesap Seç"
        subtitle="Ödeme Yapılacak Hesabı Seçin"
        className="!m-0"
        titleClassName="mb-2"
      />

      {/* <div className="flex flex-col gap-y-6"> */}
      <div className="flex flex-col gap-y-8 p-2 h-full max-h-72 overflow-y-auto">
        {accounts
          .filter((account) => account.currencyCode === CURRENCIES.TRY)
          .map((account) => (
            <AccountSelectCard
              account={account}
              key={account?.id}
              isSelected={account?.id === selectedPaymentAccount?.id}
              onSelected={setSelectedPaymentAccount}
            />
          ))}
      </div>
      {/* </div> */}

      <Button className="w-full" onClick={onAccountSelected} disabled={!selectedPaymentAccount}>
        Devam
      </Button>
    </Popup>
  );
};

const AccountSelectCard = ({ account, isSelected, onSelected, ...props }) => {
  const cardClasses = classNames(
    'border border-solid border-gray-100 bg-gray-50 !shadow-none cursor-pointer !p-4',
    {
      '!border-green-600 !bg-white': isSelected
    }
  );

  return (
    <Card className={cardClasses} {...props} onClick={() => onSelected(account)}>
      <div className="flex justify-between items-center">
        <span>{account?.name}</span>
        <span className="font-semibold">
          {currencyFormat(account?.balance?.balance)} {CURRENCY_NAMES[account?.currencyCode]}
        </span>
      </div>
    </Card>
  );
};
