import { useState, useEffect } from 'react';

import { BottomModal } from 'components';

import ItemContextProvider from 'contexts/itemContext';
import CreateForm from './CreateForm';
import Summary from './Summary';
import SuccessPage from './SuccessPage';
import EDocumentPreview from '../eDocumentPreview';

import { PAGE_NAMES } from './enums';
import { useRouter } from 'utils/hooks/useRouter';
import { useDispatch } from 'react-redux';
import { getSaleExpenseById } from 'store/actions/invoice';

const CreateSaleRecordModal = ({ handleClose, openDetailModal }) => {
  const [page, setPage] = useState(PAGE_NAMES.CREATE_FORM);
  const [recordId, setRecordId] = useState();
  const { query, push, history } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (query?.contactId) {
        history.replace({
          search: ''
        });
        push(`/contact/${query?.contactId}`);
      }
    };
  }, []);

  useEffect(() => {
    if (recordId)
      dispatch(
        getSaleExpenseById({
          salesExpenseRecordId: recordId
        })
      );
  }, [recordId]);

  const PAGES = {
    [PAGE_NAMES.CREATE_FORM]: (
      <ItemContextProvider>
        <CreateForm setPage={setPage} setRecordId={setRecordId} />
      </ItemContextProvider>
    ),
    [PAGE_NAMES.SUMMARY]: <Summary setPage={setPage} recordId={recordId} />,
    [PAGE_NAMES.E_DOCUMENT]: (
      <EDocumentPreview onComplete={() => setPage(PAGE_NAMES.SUCCESS_PAGE)} recordId={recordId} />
    ),
    [PAGE_NAMES.SUCCESS_PAGE]: (
      <SuccessPage handleClose={handleClose} openDetailModal={() => openDetailModal(recordId)} />
    )
  };

  return <BottomModal handleClose={handleClose}>{PAGES[page]}</BottomModal>;
};

export default CreateSaleRecordModal;
