import React, { useEffect, useState } from 'react';

import { Button, Checkbox, CustomInput, Divider, Popup } from 'components';

import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';

import { ReactComponent as IllustrationCardLoading } from 'assets/illustrations/card-loading.svg';
import SubTitle from '../subTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getCreditCards } from 'store/actions/creditCards';
import PopoverSelect from 'components/popoverSelect';
import useModal from 'utils/hooks/useModal';
import { ReactComponent as IconCard } from 'assets/icons/card.svg';
import AgreementModal from '../agreementModal';
import { resetPaymentStatus } from 'store/actions/payments';
import { payWithExistingCard, resetSubscription } from 'store/actions/marketplace';

export default function SelectCard({ variant, onClickCreateCard }) {
  const dispatch = useDispatch();
  const [cardOptions, setCardOptions] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cvvCode, setCvvCode] = useState('');
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);

  const [isCvvPopupActive, , toggleCvvPopup] = useModal();
  const [isAgreementModalActive, , toggleAgreementModal] = useModal();

  const { cards } = useSelector((state) => state.creditCards);

  useEffect(() => {
    dispatch(getCreditCards());
  }, []);

  useEffect(() => {
    if (cards) {
      const options =
        cards?.map((card) => ({
          label: (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-3">
                <span className="flex items-center justify-center h-11 w-11 bg-green-300 rounded-full">
                  <IconCard className="w-5 h-5" />
                </span>
                <span className="text-base leading-6 font-medium">{card.cardAlias}</span>
              </div>
              <div>
                <span className=" text-[22px] leading-7 font-medium">
                  **** **** **** {card?.cardInformation?.lastFourDigits}
                </span>
              </div>
            </div>
          ),
          value: card.id
        })) || [];
      setCardOptions(options);
    }
  }, [cards]);

  useEffect(() => {
    if (!isCvvPopupActive) setCvvCode('');
  }, [isCvvPopupActive]);

  const onSelected = (card) => {
    if (!card) return;

    const selected = cards.find((c) => c.id === card.value);
    // if (typeof onChange === 'function') onChange(selected);
    setSelectedCard(selected);
  };

  const onMakePayment = async () => {
    dispatch(resetPaymentStatus());
    dispatch(resetSubscription());

    const selected = { ...selectedCard, cvvCode };

    await dispatch(payWithExistingCard(variant?.id, selected?.id, cvvCode));
  };

  const onAgreementAccepted = (isAccepted) => {
    setIsAgreementAccepted(isAccepted);
    toggleAgreementModal();
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <SubTitle>Kayıtlı Kartlar</SubTitle>{' '}
        <Button variant="ghost" leftIcon={IconPlus} onClick={() => onClickCreateCard(true)}>
          Yeni Kart Ekle
        </Button>
      </div>
      {cards?.length === 0 && (
        <div className="flex flex-col items-center justify-center">
          <IllustrationCardLoading />
          <div className="text-base text-gray-400 leading-6 font-semibold">Kayıtlı Kart yok</div>
        </div>
      )}
      {cards?.length > 0 && (
        <div>
          <PopoverSelect
            options={cardOptions}
            defaultValue={cardOptions[0]}
            labelClassName="w-full"
            panelClassName="!w-full"
            className="!shadow-none !bg-gray-100"
            onChange={onSelected}
          />
        </div>
      )}
      {cardOptions.length > 0 && (
        <Checkbox
          name="agreementAccepted"
          className="text-gray-900 font-medium text-sm leading-5 !items-start my-6"
          checked={isAgreementAccepted}
          onChange={(e) => setIsAgreementAccepted(e.target.checked)}
        >
          <Button variant="ghost" className="!p-0" onClick={toggleAgreementModal}>
            Satış sözleşmesini
          </Button>
          <span> ve ödemelerin her ay kartımdan veya hesabımdan çekilmesini onaylıyorum.</span>
        </Checkbox>
      )}
      {isCvvPopupActive && (
        <Popup active={isCvvPopupActive} childrenClassName="h-80">
          <div className="flex flex-col justify-between h-full">
            <h4>Güvenlik kodunu giriniz</h4>
            <div>
              <CustomInput
                label="CVV"
                only="number"
                name="cardNumber"
                maxLength={3}
                className="my-6"
                onChange={(e) => setCvvCode(e.target.value)}
              />

              <div className="w-full flex flex-col gap-y-4">
                <Button
                  className="w-full"
                  onClick={onMakePayment}
                  disabled={cvvCode?.length !== 3}
                >
                  Ödeme Yap
                </Button>
                <Button className="w-full" variant="secondary" onClick={toggleCvvPopup}>
                  Vazgeç
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
      <Divider className="my-10" />
      <Button
        className="w-full"
        onClick={toggleCvvPopup}
        disabled={!selectedCard || !isAgreementAccepted}
      >
        Ödeme Yap
      </Button>
      {isAgreementModalActive && (
        <AgreementModal
          onClose={toggleAgreementModal}
          onAgreementAccepted={onAgreementAccepted}
        />
      )}
    </div>
  );
}
