import axios from 'axios';
import { openBankingTypes } from 'store/types';

const {
  GET_OPEN_BANKING_BANKS,
  GET_OPEN_BANKING_BANKS_FAILURE,
  GET_OPEN_BANKING_BANKS_SUCCESS,
  GET_OPEN_BANKING_BANK_INFORMATIONS,
  GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE,
  GET_OPEN_BANKING_BANK_INFORMATIONS_SUCCESS,
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS,
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_FAILURE,
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_SUCCESS,
  GET_OPEN_BANKING_BANK_ACCOUNTS,
  GET_OPEN_BANKING_BANK_ACCOUNTS_SUCCESS,
  GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE,
  ADD_OPEN_BANKING_BANK,
  ADD_OPEN_BANKING_BANK_FAILURE,
  ADD_OPEN_BANKING_BANK_SUCCESS,
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT,
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE,
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_SUCCESS,
  UPDATE_OPEN_BANKING_BANK_ACCOUNT,
  UPDATE_OPEN_BANKING_BANK_ACCOUNT_FAILURE,
  UPDATE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS,
  DELETE_OPEN_BANKING_BANK_ACCOUNT,
  DELETE_OPEN_BANKING_BANK_ACCOUNT_FAILURE,
  DELETE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS
} = openBankingTypes;

const BASE_URL = '/api/wallets/open-banking';

export const getOpenBankingBanks = () => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_OPEN_BANKING_BANKS_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: GET_OPEN_BANKING_BANKS
      });

      const response = await axios.get(`${BASE_URL}/banks`);

      if (response.status === 200) {
        dispatch({
          type: GET_OPEN_BANKING_BANKS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_OPEN_BANKING_BANKS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const getOpenBankingBankInformations = () => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: GET_OPEN_BANKING_BANK_INFORMATIONS
      });

      const response = await axios.get(`${BASE_URL}/bank-info`);

      if (response.status === 200) {
        dispatch({
          type: GET_OPEN_BANKING_BANK_INFORMATIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const getOpenBankingBankAccounts = (openBankingId) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: GET_OPEN_BANKING_BANK_ACCOUNTS
      });

      const response = await axios.get(`${BASE_URL}/banks/${openBankingId}/accounts`);

      if (response.status === 200) {
        dispatch({
          type: GET_OPEN_BANKING_BANK_ACCOUNTS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const getOpenBankingBankRequiredFields = (bankInformationId) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: GET_OPEN_BANKING_BANK_REQUIRED_FIELDS
      });

      const response = await axios.get(`${BASE_URL}/banks/${bankInformationId}/required-fields`);

      if (response.status === 200) {
        dispatch({
          type: GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const addOpenBankingBank = (bankInformationId, payload) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: ADD_OPEN_BANKING_BANK_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: ADD_OPEN_BANKING_BANK
      });

      const response = await axios.post(`${BASE_URL}/banks/${bankInformationId}`, payload);

      if (response.status === 200) {
        dispatch({
          type: ADD_OPEN_BANKING_BANK_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: ADD_OPEN_BANKING_BANK_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const addOpenBankingManuallyBankAccount = (openBankingId, payload) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT
      });

      const response = await axios.post(`${BASE_URL}/banks/${openBankingId}/accounts`, payload);

      if (response.status === 200) {
        dispatch({
          type: ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const updateOpenBankingBankAccount = (openBankingId, payload) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: UPDATE_OPEN_BANKING_BANK_ACCOUNT_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: UPDATE_OPEN_BANKING_BANK_ACCOUNT
      });

      const response = await axios.put(`${BASE_URL}/banks/${openBankingId}/accounts`, payload);

      if (response.status === 200) {
        dispatch({
          type: UPDATE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_OPEN_BANKING_BANK_ACCOUNT_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
export const deleteOpenBankingBankAccount = (openBankingId, accountOrWalletId) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: DELETE_OPEN_BANKING_BANK_ACCOUNT_FAILURE,
        payload: 'access denied'
      });

    try {
      dispatch({
        type: DELETE_OPEN_BANKING_BANK_ACCOUNT
      });

      const response = await axios.delete(
        `${BASE_URL}/banks/${openBankingId}/accounts/${accountOrWalletId}`
      );

      if (response.status === 200) {
        dispatch({
          type: DELETE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: DELETE_OPEN_BANKING_BANK_ACCOUNT_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
