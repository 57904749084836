import React from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export default function RadioButton({ defaultValue, children, onChange = () => {}, ...props }) {
  return (
    <RadioGroup value={defaultValue} onChange={onChange} className="flex items-center" {...props}>
      {children}
    </RadioGroup>
  );
}
RadioButton.Item = ({ value, label, onClick, ...props }) => {
  return (
    <RadioGroup.Option value={value} {...props}>
      {({ checked }) => (
        <div className="flex items-center gap-x-2 cursor-pointer" onClick={onClick}>
          <div
            className={classNames(
              'w-4 h-4 border border-solid rounded-full flex items-center justify-center',
              checked ? 'border-transparent bg-green-500' : 'border-gray-200'
            )}
          >
            <span className="w-2 h-2 bg-white rounded-full" />
          </div>
          <RadioGroup.Label className="cursor-pointer select-none">{label}</RadioGroup.Label>
        </div>
      )}
    </RadioGroup.Option>
  );
};

RadioButton.Item.displayName = 'RadioButton.Item';
