import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReceiptPdf = ({ pdfUrl, page = 1 }) => (
  <Document
    file={pdfUrl}
    onLoadError={console.log}
    className="rounded-xl"
    loading="Belge Yükleniyor..."
    error="Belge Yüklenirken Bir Hata Oluştu!"
    noData="Belge Bulunamadı!"
  >
    <Page pageNumber={page} className="w-full rounded-xl" scale={1.2} />
  </Document>
);

export default ReceiptPdf;
