import BottomModal from 'components/BottomModal';
import Button from 'components/button';
import DoubleCheck from 'assets/illustrations/card/double-check.png';
// import { useEffect } from 'react';

export default function SuccessModal({
  actionButtonText,
  secondaryActionButtonText,
  successMessage,
  onClose,
  onActionButtonClick,
  onSecondaryActionButtonClick,
  ...props
}) {
  // useEffect(() => {
  //   if (typeof onMount === 'function') onMount();
  // }, []);
  return (
    <BottomModal className="bg-green-400" isActive={true} handleClose={onClose} {...props}>
      <div className="w-full h-full flex flex-col items-center justify-center gap-y-6">
        <img src={DoubleCheck} alt="double-check" width="100" />
        <p className="text-3xl text-white max-w-md text-center">{successMessage}</p>
        {secondaryActionButtonText && (
          <Button onClick={onSecondaryActionButtonClick} color="secondary" className="w-[400px]">
            {secondaryActionButtonText}
          </Button>
        )}
        <Button onClick={onActionButtonClick} className="w-[400px]">
          {actionButtonText}
        </Button>
      </div>
    </BottomModal>
  );
}
