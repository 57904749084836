import { useState } from 'react';

import CompanyDocuments from './tabs/companyDocuments';
import TaxDeclarationsDocuments from './tabs/taxDeclarationsDocuments';

import { NavLink, Route, Switch } from 'react-router-dom';

import NotFound from 'views/misc/NotFound';

import { useRouter } from 'utils/hooks/useRouter';

const Profile = () => {
  const [isModalActive, setIsModalActive] = useState(false);
  function toggleModal() {
    setIsModalActive(!isModalActive);
  }

  return (
    <div
      className="relative bg-gray-200 flex flex-col justify-start items-center py-12 min-h-screen"
      data-testid="section_documents"
    >
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative flex flex-col justify-start items-start w-11/12 xl:w-3/4 h-auto">
        <span className="text-white font-semibold text-xl">Belgeler</span>
        <TabsCard
          className="flex flex-row justify-center items-start space-x-8 w-full mt-6"
          data-testid="section_documents_tabs"
          isModalActive={isModalActive}
          toggleModal={toggleModal}
        />
      </div>
    </div>
  );
};

const TabsCard = ({ defaultTab = 0, className }) => {
  const [, setTab] = useState(defaultTab);

  const { match } = useRouter();

  const handleTabs = (i) => {
    if (tabs[i].disabled) return;

    setTab(i);
  };

  return (
    <div className={`${className} h-auto bg-white shadow-md rounded-xl`}>
      <div className="w-full">
        <div className="w-full px-8 flex items-center justify-between">
          <div className="w-full flex items-center border-b border-solid border-gray-100 text-black text-sm space-x-8 overflow-x-auto pr-6">
            {tabs.map((t, i) => {
              return (
                <NavLink
                  exact={i === 0}
                  to={t.path}
                  key={i}
                  className={`text-gray-500 fill-gray-500 solid-gray-500 w-min min-w-max font-medium pb-3 pt-5 h-auto flex items-center justify-center gap-x-3 cursor-pointer select-none`}
                  activeClassName="border-b border-solid border-green-500 text-green-500"
                  onClick={() => handleTabs(i)}
                >
                  {t.icon}
                  {t.title}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className="w-full mt-4 py-10">
          <Switch>
            <Route exact path={`${match.path}`}>
              <CompanyDocuments />
            </Route>
            <Route exact path={`${match.path}/taxes-declarations`}>
              <TaxDeclarationsDocuments />
            </Route>
            <Route exact path={'*'}>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

const tabs = [
  {
    title: 'Şirket Belgeleri',
    icon: null,
    page: <CompanyDocuments />,
    path: '/documents'
  },
  {
    title: 'Vergi/Beyanname Belgeleri',
    icon: null,
    page: <TaxDeclarationsDocuments />,
    path: '/documents/taxes-declarations'
  }
];

export default Profile;
