import React, { useState, useEffect } from 'react';
import { Button, CustomInput, Select, SideModal, CustomButton, Popup } from 'components';
import { useItemContext } from 'contexts/itemContext';
import AmountInput from 'components/AmountInput';
import { CURRENCIES } from '../../enums';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
import { getItemUnits, getTaxCodes, getWithHolding } from 'store/actions/eTransformation';
import { useDispatch, useSelector } from 'react-redux';
import { filterKeys, PRICE_TYPES, VATS } from 'utils';
import { WithholdingTax, AdditionalTax, Title } from 'views/other/items/components';
import { deleteItem, getItems, updateItem } from 'store/actions/items';
import { useRouter } from 'utils/hooks/useRouter';
import useModal from 'utils/hooks/useModal';
import routes from 'router';

//icons
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

export default function EditItemModal({ onClose }) {
  const { formValues, setFormValues, resetFormValues, toggleEditItemModal, isFreelancer } =
    useItemContext();
  const { itemUnits } = useSelector((state) => state.eTransformation);
  const [isNotValid, setIsNotValid] = useState(true);
  const { query, push } = useRouter();
  const { item } = useSelector((state) => state.items);
  const [isConfirmDeleteModalActive, , toggleConfirmDeletePopup] = useModal();

  const dispatch = useDispatch();

  const currencies = Object.entries(CURRENCIES).map(([key, value]) => ({
    id: value,
    name: CURRENCY_NAMES[key]
  }));

  const {
    additionalTaxes,
    currency,
    name,
    price,
    priceType,
    unitTypeId,
    vatRate,
    code,
    vatWithholdingCode,
    vatWithholdingRate
  } = formValues;

  useEffect(() => {
    dispatch(getWithHolding());
    dispatch(getItemUnits());
    dispatch(getTaxCodes());

    setFormValues({
      additionalTaxes: item?.additionalTaxes || [],
      currency: item?.currency,
      name: item?.name,
      price: item?.price,
      priceType: item?.priceType,
      unitTypeId: item?.unitType?.id,
      vatRate: item?.vatRate || VATS[3].id,
      code: item?.code,
      vatWithholdingCode: item?.vatWithholdingCode,
      vatWithholdingRate: item?.vatWithholdingRate
    });
    return () => {
      resetFormValues();
      onClose();
    };
  }, []);

  useEffect(() => {
    if (isFreelancer) {
      setIsNotValid(!currency || !name || !price || !priceType || !vatRate);
    } else {
      setIsNotValid(!currency || !name || !price || !unitTypeId || !vatRate);
    }
  }, [additionalTaxes, currency, name, price, priceType, unitTypeId, vatRate]);

  const onChangeItemName = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      name: e.target.value
    }));
  };
  const onChangeVatRate = (vatRate) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      vatRate: Number(vatRate)
    }));
  };
  const onChangeCurrency = (currency) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      currency
    }));
  };
  const onChangePriceTypeChange = (priceType) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      priceType
    }));
  };
  const onChangeUnitType = (unitTypeId) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      unitTypeId: Number(unitTypeId)
    }));
  };

  const onChangePrice = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      price: value
    }));
  };

  const onSubmit = () => {
    if (isNotValid) return;
    let payload = {
      additionalTaxes: additionalTaxes.filter((tax) => tax.name && tax.code && tax.percent),
      code,
      currency,
      name,
      price,
      priceType,
      unitTypeId,
      vatRate
    };
    if (vatWithholdingCode && vatWithholdingRate) {
      payload.vatWithholdingCode = vatWithholdingCode;
      payload.vatWithholdingRate = vatWithholdingRate;
    }
    payload = filterKeys(payload, null, { filterEmpty: true, filterNull: true });
    dispatch(updateItem(query?.id, payload))
      .then(() => {
        toggleEditItemModal();
        dispatch(getItems());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onDelete() {
    dispatch(deleteItem({ itemId: item.id }))
      .then(() => {
        push(`${routes.other.path}/items`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onCloseSideModal = () => {
    onClose();
    toggleEditItemModal();
  };

  return (
    <SideModal handleClose={onCloseSideModal}>
      <ConfirmDelete
        active={isConfirmDeleteModalActive}
        handleClose={toggleConfirmDeletePopup}
        handleDelete={onDelete}
        item={item}
      />
      <div className="flex flex-col gap-y-12 pb-4 overflow-y-auto px-4 w-full">
        <p className="text-2xl font-semibold">Ürün Düzenle</p>
        <div className="h-full flex flex-col gap-y-4">
          <div className="flex flex-col space-y-4">
            <Title>Bilgiler</Title>
            <CustomInput label="Ürün İsmi" onChange={onChangeItemName} value={formValues?.name} />
            {isFreelancer ? (
              <Select
                options={PRICE_TYPES}
                value={'id'}
                label="Tutar Türü"
                valueLabel="name"
                onChange={onChangePriceTypeChange}
                defaultValue={formValues?.priceType}
              />
            ) : (
              <Select
                options={itemUnits}
                value="id"
                valueLabel="name"
                label="Birim Tipi"
                onChange={onChangeUnitType}
                defaultValue={formValues.unitTypeId}
              />
            )}
            <div className="flex space-x-4">
              <AmountInput
                className="w-full max-w-[172px]"
                label="Tutar"
                onValueChange={onChangePrice}
                value={formValues.price}
              />
              <Select
                className="w-full"
                options={currencies}
                value={'id'}
                valueLabel="name"
                label="Para Birimi"
                onChange={onChangeCurrency}
                defaultValue={
                  currencies.find((c) => c.name === CURRENCY_NAMES[formValues.currency])?.id
                }
              />
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <Title>Vergiler</Title>
            <div className="flex space-x-4">
              <CustomInput
                className="w-full max-w-[172px]"
                label="Vergi Adı"
                value="KDV"
                disabled
              />
              <Select
                className="w-full max-w-[172px]"
                options={VATS}
                value={'id'}
                valueLabel="name"
                label="Vergi Oranı"
                onChange={onChangeVatRate}
                defaultValue={formValues.vatRate}
              />
            </div>
            <AdditionalTax isEdit />
            <WithholdingTax />
          </div>
        </div>
        <div className="flex flex-col w-full gap-y-4 mb-4">
          <Button type="button" className="w-full" onClick={onSubmit} disabled={isNotValid}>
            Kaydet
          </Button>
          <Button type="button" color="red" className="w-full" onClick={toggleConfirmDeletePopup}>
            Sil
          </Button>
        </div>
      </div>
    </SideModal>
  );
}

const ConfirmDelete = ({ active, item, handleClose, handleDelete }) => (
  <Popup active={active}>
    <div className="flex flex-col items-center w-full h-full text-center justify-around">
      <span className="rounded-full w-20 h-20 bg-red-100 flex items-center justify-center">
        <TrashIcon className="fill-current text-red-500 w-8" />
      </span>
      <span className="text-xl font-semibold text-gray-900 mt-6">Ürün Sil</span>
      <span className="text-base font-medium text-gray-500 mt-3">
        {item?.name || ''} ürününü silmek istiyor musun?
      </span>
      <CustomButton
        color="danger"
        className="w-full mt-auto"
        onClick={handleDelete}
        data-testid="butto_delete_item"
      >
        Sil
      </CustomButton>
      <CustomButton
        color="success"
        className="w-full mt-2"
        onClick={handleClose}
        data-testid="button_cancel_delete_item"
      >
        İptal Et
      </CustomButton>
    </div>
  </Popup>
);
