import axios from 'axios';
import { automaticTransactionTypes } from 'store/types';
import { AUTOMATIC_TRANSACTION_STATUS, filterKeys } from 'utils';
import httpStatus from 'utils/httpStatus';

const {
  GET_AUTOMATIC_TRANSACTION,
  GET_AUTOMATIC_TRANSACTION_SUCCESS,
  GET_AUTOMATIC_TRANSACTION_FAILURE,
  GET_AUTOMATIC_TRANSACTIONS,
  GET_AUTOMATIC_TRANSACTIONS_SUCCESS,
  GET_AUTOMATIC_TRANSACTIONS_FAILURE,
  CREATE_AUTOMATIC_TRANSACTION,
  CREATE_AUTOMATIC_TRANSACTION_FAILURE,
  CREATE_AUTOMATIC_TRANSACTION_SUCCESS,
  UPDATE_AUTOMATIC_TRANSACTION,
  UPDATE_AUTOMATIC_TRANSACTION_FAILURE,
  UPDATE_AUTOMATIC_TRANSACTION_SUCCESS,
  DELETE_AUTOMATIC_TRANSACTION,
  DELETE_AUTOMATIC_TRANSACTION_FAILURE,
  DELETE_AUTOMATIC_TRANSACTION_SUCCESS,
  RESET_CREATED_AUTOMATIC_TRANSACTION,
  RESET_AUTOMATIC_TRANSACTION_DETAIL
} = automaticTransactionTypes;

const BASE_URL = '/api/wallets';

export const getAutomaticTransactions =
  (status = AUTOMATIC_TRANSACTION_STATUS.IN_PROGRESS) =>
  async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user?.companyId)
        return dispatch({
          type: GET_AUTOMATIC_TRANSACTIONS_FAILURE,
          payload: 'company not found'
        });

      dispatch({
        type: GET_AUTOMATIC_TRANSACTIONS
      });

      const response = await axios.get(`${BASE_URL}/automatic/transactions`, {
        params: { status }
      });

      if (response.status === httpStatus.OK) {
        dispatch({
          type: GET_AUTOMATIC_TRANSACTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_AUTOMATIC_TRANSACTIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const getAutomaticTransaction = (automaticTransactionId) => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    if (!user.companyId)
      return dispatch({
        type: GET_AUTOMATIC_TRANSACTION_FAILURE,
        payload: 'company not found'
      });

    dispatch({
      type: GET_AUTOMATIC_TRANSACTION
    });

    const response = await axios.get(
      `${BASE_URL}/automatic/transactions/${automaticTransactionId}`
    );

    if (response.status === httpStatus.OK) {
      dispatch({
        type: GET_AUTOMATIC_TRANSACTION_SUCCESS,
        payload: response.data.data
      });
      return Promise.resolve(response.data.data);
    }
  } catch (error) {
    dispatch({
      type: GET_AUTOMATIC_TRANSACTION_FAILURE,
      payload: error
    });
    return Promise.reject(error);
  }
};

export const createAutomaticTransaction =
  (
    walletId,
    {
      recordName = null,
      receiverType = null,
      contactId = null,
      receiverFullName = null,
      receiverIban = null,
      receiverWalletNumber = null,
      repeatCount = 0,
      repeatType = null,
      transferDay = 0,
      paymentStartDate = null,
      amount = 0
    }
  ) =>
  async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId)
        return dispatch({
          type: CREATE_AUTOMATIC_TRANSACTION_FAILURE,
          payload: 'company not found'
        });

      dispatch({
        type: CREATE_AUTOMATIC_TRANSACTION
      });

      const payload = filterKeys(
        {
          recordName,
          receiverType,
          contactId,
          receiverFullName,
          receiverIban,
          receiverWalletNumber,
          repeatCount,
          repeatType,
          transferDay,
          paymentStartDate,
          amount
        },
        [],
        {
          filterEmpty: true,
          filterNull: true
        }
      );

      const response = await axios.post(
        `${BASE_URL}/${walletId}/automatic/transactions`,
        payload
      );

      if (response.status === httpStatus.OK) {
        dispatch({
          type: CREATE_AUTOMATIC_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CREATE_AUTOMATIC_TRANSACTION_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const updateAutomaticTransaction =
  (
    automaticTransactionId,
    {
      recordName = null,
      receiverType = null,
      contactId = null,
      receiverFullName = null,
      receiverIban = null,
      receiverWalletNumber = null,
      repeatCount = 0,
      repeatType = null,
      transferDay = 0,
      paymentStartDate = null,
      amount = 0
    }
  ) =>
  async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId)
        return dispatch({
          type: UPDATE_AUTOMATIC_TRANSACTION_FAILURE,
          payload: 'company not found'
        });

      dispatch({
        type: UPDATE_AUTOMATIC_TRANSACTION
      });

      const payload = {
        recordName,
        receiverType,
        contactId,
        receiverFullName,
        receiverIban,
        receiverWalletNumber,
        repeatCount,
        repeatType,
        transferDay,
        paymentStartDate,
        amount
      };

      const response = await axios.put(
        `${BASE_URL}/automatic/transactions/${automaticTransactionId}`,
        payload
      );

      if (response.status === httpStatus.OK) {
        dispatch({
          type: UPDATE_AUTOMATIC_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_AUTOMATIC_TRANSACTION_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const deleteAutomaticTransaction =
  (automaticTransactionId) => async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId)
        return dispatch({
          type: DELETE_AUTOMATIC_TRANSACTION_FAILURE,
          payload: 'company not found'
        });

      dispatch({
        type: DELETE_AUTOMATIC_TRANSACTION
      });

      const response = await axios.delete(
        `${BASE_URL}/automatic/transactions/${automaticTransactionId}`
      );

      if (response.status === httpStatus.OK) {
        dispatch({
          type: DELETE_AUTOMATIC_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: DELETE_AUTOMATIC_TRANSACTION_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };

export const resetAutomaticTransaction = () => (dispatch) => {
  dispatch({
    type: RESET_AUTOMATIC_TRANSACTION_DETAIL,
    payload: null
  });
};

export const resetCreatedAutomaticTransaction = () => (dispatch) => {
  dispatch({
    type: RESET_CREATED_AUTOMATIC_TRANSACTION,
    payload: null
  });
};
