import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CustomButton } from 'components';
import { Page, Document } from 'react-pdf';

import { downloadInvoice } from 'store/actions/eTransformation';
import { createEDocument, getEDocument } from 'store/actions/invoice';
import { PAGE_NAMES } from './enums';
import { ReactComponent as IconDownload } from 'assets/icons/file-download.svg';
import { COMPANY_TYPES } from 'utils';

const ReceiptPdf = ({ pdfUrl, scale = 1.2, page = 1 }) => (
  <Document
    file={pdfUrl}
    onLoadError={console.log}
    className="rounded-xl"
    noData="Belge Yüklenemedi"
    loading="Belge yükleniyor..."
  >
    <Page pageNumber={page} className="w-full rounded-xl" scale={scale} />
  </Document>
);

const EDocument = ({ transaction, sended, setPage }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { eDocument, isLoading } = useSelector((state) => state.invoice);

  async function send() {
    if (!user.companyId) return;

    try {
      await createEDocument({ companyId: user.companyId, salesExpenseRecordId: transaction?.id });

      setPage(PAGE_NAMES.SUCCESS);
      sended();
    } catch (err) {
      console.log(err);
    }
  }

  function getInvoices() {
    if (!user.companyId || transaction?.files[0]?.file) return;

    if (transaction?.salesExpenseInvoiceDto?.documentId) {
      dispatch(
        downloadInvoice({
          companyId: user.companyId,
          documentId: transaction?.salesExpenseInvoiceDto?.documentId,
          'content-type': 'PDF'
        })
      );
    } else {
      dispatch(
        getEDocument({ companyId: user.companyId, salesExpenseRecordId: transaction?.id })
      );
    }
  }

  useEffect(() => {
    dispatch({
      type: 'SET_E_DOCUMENT',
      data: null
    });
    getInvoices();
  }, [user]);

  const onDownloadFile = () => {
    if (!eDocument && !transaction?.salesExpenseInvoiceDto?.invoiceNumber) return;
    // const {
    //   contact,
    //   salesExpenseInvoiceDto: { invoiceNumber }
    // } = transaction;

    // const fileName = `${
    //   contact?.companyType === COMPANY_TYPES.BUSINESS
    //     ? contact.companyName
    //     : `${contact?.firstName} ${contact?.lastName}`
    // }-${invoiceNumber}`;

    const a = document.createElement('a');
    a.href = eDocument;
    a.download = eDocument.split('/').pop();
    // a.download = fileName;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (!transaction?.files[0]?.file) {
    return (
      <div className="flex justify-center h-full w-full overflow-y-scroll bg-gray-50">
        <div className="w-full max-w-[760px] flex flex-col pt-28">
          <div className="flex w-full justify-between items-center">
            <span className="font-semibold text-3xl text-gray-900">Belge</span>
            {transaction?.salesExpenseInvoiceDto?.documentId && (
              <Button
                variant={'circle'}
                color="gray"
                icon={<IconDownload className="fill-current" />}
                onClick={onDownloadFile}
              />
            )}
          </div>
          <div className="w-full rounded-lg border border-solid border-gray-300 mt-6 bg-white p-4 flex items-center justify-center">
            {isLoading && 'Belge yükleniyor...'}
            {eDocument && <ReceiptPdf pdfUrl={eDocument} />}
          </div>
          {!transaction?.salesExpenseInvoiceDto?.documentId && (
            <div className="w-full">
              <CustomButton color="success" className="w-full mt-8 mb-16" onClick={send}>
                Gönder
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center h-full w-full overflow-y-scroll bg-gray-50">
      <div className="w-[582px] h-auto flex flex-col py-10 pt-28">
        <span className="font-semibold text-3xl text-gray-900">Belge</span>
        <div className="w-full rounded-lg border border-solid border-gray-300 mt-6 bg-white p-4 flex items-center justify-center">
          <img src={transaction?.files[0]?.file.url} />
        </div>
      </div>
    </div>
  );
};

export default EDocument;
