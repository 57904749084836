import { CustomButton } from 'components';
import useDengage from 'utils/hooks/useDengage';
import DoubleIconCheck from 'assets/illustrations/card/double-check.png';
import { PAGE_NAMES } from './enums';

const SuccessPage = ({ handleClose, setPage }) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_sales_success' });
  }, []);

  return (
    <div className="w-full h-full bg-green-500 flex items-center justify-center">
      <div className="w-[380px] flex flex-col items-center">
        <img src={DoubleIconCheck} alt="Double check" className="w-24" />
        <span className="text-3xl font-medium text-white text-center">
          Satış Kaydı oluşturma işleminiz başarıyla tamamlanmıştır.
        </span>
        <CustomButton
          color="primary"
          className="w-full mt-6"
          onClick={() => setPage(PAGE_NAMES.DETAIL)}
        >
          Satış Kaydını Gör
        </CustomButton>
        <CustomButton color="success" className="w-full mt-4" onClick={() => handleClose()}>
          Kapat
        </CustomButton>
      </div>
    </div>
  );
};

export default SuccessPage;
