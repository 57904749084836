import axios from 'axios';

export const getItems = (data) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.get(`/api/companies/${user.companyId}/items`, { params: data }).then((res) => {
      if (!data?.name) {
        dispatch({
          type: 'SET_ITEMS',
          data: res.data?.data?.content,
          totalElements: res.data?.data?.totalElements,
          totalPages: res.data?.data?.totalPages
        });
      }
      dispatch({
        type: 'SET_FILTERED_ITEMS',
        data: res.data?.data?.content,
        totalElements: res.data?.data?.totalElements,
        totalPages: res.data?.data?.totalPages
      });
    });
  };
};

export const getItemById = ({ itemId }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    const response = await axios.get(`/api/companies/${user.companyId}/items/${itemId}`);
    dispatch({ type: 'SET_ITEM', data: response.data.data });
    return Promise.resolve(response);
  };
};

export const createItem = (data) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.post(`/api/companies/${user.companyId}/items`, data);
  };
};

export const updateItem = (itemId, data) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.put(`/api/companies/${user.companyId}/items/${itemId}`, data);
  };
};

export const deleteItem = ({ itemId }) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.delete(`/api/companies/${user.companyId}/items/${itemId}`);
  };
};

export const calculateItems = (params) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios
      .post(`/api/companies/${user.companyId}/items/calculation`, params)
      .then((res) => {
        dispatch({
          type: 'SET_CALCULATION',
          data: res.data?.data
        });
      })
      .catch((err) => console.log(err));
  };
};
