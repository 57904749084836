import { combineReducers } from 'redux';

import accounts from './accounts';
import accountsLoading from './accountsLoading';
import activityScope from './activityScope';
import auth from './auth';
import categories from './categories';
import contacts from './contacts';
import country from './country';
import eTransformation from './eTransformation';
import exchange from './exchange';
import invoice from './invoice';
import items from './items';
import notifications from './notifications';
import recurringPayments from './recurringPayments';
import taxOffice from './taxOffice';
import virtualOffices from './virtualOffices';
import documents from './documents';
import qrCodes from './qrCodes';
import globalLoading from './globalLoading';
import marketplace from './marketplace';
import bankInformations from './bankInformations';
import payments from './payments';
import binQuery from './binQuery';
import creditCards from './creditCards';
import transactions from './transactions';
import wallet from './wallet';
import archive from './archive';
import card from './card';
import bill from './bill';
import automaticTransaction from './automatic';
import enums from './enums';
import openBanking from './openBanking';
import contentResource from './contentResource';

const appReducer = combineReducers({
  accounts,
  accountsLoading,
  activityScope,
  auth,
  categories,
  contacts,
  country,
  eTransformation,
  exchange,
  invoice,
  items,
  notifications,
  recurringPayments,
  taxOffice,
  virtualOffices,
  documents,
  qrCodes,
  globalLoading,
  marketplace,
  bankInformations,
  payments,
  binQuery,
  creditCards,
  transactions,
  wallet,
  archive,
  card,
  bill,
  automaticTransaction,
  enums,
  openBanking,
  contentResource
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
