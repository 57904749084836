import React from 'react';
import { Button, Popup } from 'components';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { WALLET_TYPES } from 'utils/contants';

export default function CreateWalletPopup({
  walletType,
  isActive,
  onClose,
  onApproved,
  onReject,
  ...props
}) {
  return (
    <Popup active={isActive} onClose={onClose} {...props}>
      <div className="flex flex-col justify-center items-center">
        <div className="w-20 h-20 rounded-full bg-yellow-100 flex justify-center items-center">
          <IconInfoCircle className="fill-current w-10 text-yellow-500" />
        </div>
        <span className="mt-6 text-lg font-semibold text-gray-900 text-center">
          {`${
            WALLET_TYPES[walletType]?.label?.split(' ')[0]
          } hesabınız bulunmamakta, ${WALLET_TYPES[
            walletType
          ].label.toLowerCase()} açmak ister misiniz?`}
        </span>
        <Button onClick={onApproved} className="w-full mt-6">
          Evet
        </Button>
        <Button color="red" onClick={onReject} className="w-full mt-4">
          Vazgeç
        </Button>
      </div>
    </Popup>
  );
}
