import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className="w-full h-screen flex flex-col space-y-4 items-center justify-center">
      <b className="text-4xl">PAGE NOT FOUND!</b>
      <button
        onClick={() => history.push('/')}
        className="bg-green-200 p-2 rounded-lg text-gray-600"
      >
        Go Home
      </button>
    </div>
  );
};

export default NotFound;
