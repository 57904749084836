const initialState = [];

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TAX_OFFICES':
      return action.data;
    default:
      return [...state];
  }
};

export default authReducer;
