import { CategoryBadge, CustomButton, CustomInput, SideModal } from 'components';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteCategory,
  getCategoryColors,
  getCategoryIcons,
  updateCategory
} from 'store/actions/categories';
import { useSelector } from 'react-redux';
import useModal from 'utils/hooks/useModal';
import useDengage from 'utils/hooks/useDengage';
import DeleteConfirmPopup from './DeleteConfirmPopup';
import CanNotDeletePopup from './CanNotDeletePopup';

const EditCategoryModal = ({ category, handleClose }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const { colors, icons } = useSelector((state) => state.categories);

  const [name, setName] = useState(category?.name);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedIcon, setSelectedIcon] = useState(category?.iconUrl);

  const [isDeleteConfirmPopupActive, , toggleDeleteConfirmPopup] = useModal();
  const [isCanNotDeletePopupActive, , toggleCanNotDeletePopup] = useModal();

  function updateCategoryById() {
    dispatch(
      updateCategory({
        categoryId: category?.id,
        colorHexCode: selectedColor?.colorHexCode,
        name,
        iconUrl: selectedIcon,
        textColorHexCode: selectedColor?.textColorHexCode
      })
    )
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleDelete() {
    dispatch(deleteCategory({ categoryId: category?.id }))
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    dispatch(getCategoryIcons());
    dispatch(getCategoryColors());
  }, []);

  useEffect(() => {
    const color = colors.find((color) => color.colorHexCode === category?.colorHexCode);

    if (color) setSelectedColor(color);
  }, [colors]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_category_detail' });
  }, []);

  return (
    <SideModal handleClose={handleClose}>
      <DeleteConfirmPopup
        active={isDeleteConfirmPopupActive}
        toggle={toggleDeleteConfirmPopup}
        handleDelete={handleDelete}
      />
      <CanNotDeletePopup active={isCanNotDeletePopupActive} toggle={toggleCanNotDeletePopup} />
      <div className="flex flex-col w-full overflow-y-scroll pb-6">
        <span className="font-semibold text-2xl text-gray-900">Kategori Detayı</span>
        <div className="flex justify-between mt-11 flex-wrap gap-y-4">
          <CustomInput
            label="Kategori İsmi"
            onChange={(e) => setName(e.target.value)}
            defaultValue={name}
            className="w-24 min-w-max"
            data-testid="input_category_detail_name"
          />
          <div className="flex justify-center items-center w-1/2 flex-col max-w-full">
            <span className="text-gray-400 mb-2">Görünüm</span>
            <CategoryBadge
              colorHexCode={selectedColor?.colorHexCode}
              iconUrl={selectedIcon}
              textColorHexCode={selectedColor?.textColorHexCode}
              name={name}
            />
          </div>
        </div>
        <span className="text-xl text-gray-900 font-semibold mt-10">Icon Seç</span>
        <div className="w-full flex justify-center flex-wrap gap-y-4 mt-9">
          {icons.map((icon, i) => (
            <div
              key={i}
              className="w-1/5 h-auto flex items-center justify-center cursor-pointer"
              onClick={() => setSelectedIcon(icon)}
            >
              <div
                className={`${
                  icon === selectedIcon && 'shadow-lg border-gray-200'
                } border border-solid border-transparent text-gray-800 w-auto h-auto flex items-center justify-center p-4 rounded-xl`}
              >
                <img src={icon} className="w-6" alt="category icon" />
              </div>
            </div>
          ))}
        </div>
        <span className="text-xl text-gray-900 font-semibold mt-10">Renk Seç</span>
        <div className="w-full flex justify-center flex-wrap gap-y-8 mt-9">
          {colors.map((color) => (
            <div
              key={color.id}
              className="w-1/3 h-auto flex items-center justify-center cursor-pointer"
              onClick={() => setSelectedColor(color)}
            >
              <div
                className={`${
                  color.id === selectedColor?.id && 'shadow-lg border-gray-200'
                } border border-solid border-transparent w-auto h-auto flex items-center justify-center p-3 rounded-xl`}
              >
                <span
                  className="w-8 h-8 rounded-full"
                  style={{ background: color?.colorHexCode }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-y-3">
          <CustomButton
            color="success"
            className="mt-14"
            onClick={updateCategoryById}
            data-testid="button_update_category"
          >
            Kaydet
          </CustomButton>
          {/* <CustomButton
            color="danger"
            onClick={() => {
              if (category?.count > 0) return toggleCanNotDeletePopup();
              toggleDeleteConfirmPopup();
            }}
          >
            Sil
          </CustomButton> */}
        </div>
      </div>
    </SideModal>
  );
};

export default EditCategoryModal;
