import React, { useEffect } from 'react';
import { Button, SideModal, Tab } from 'components';
import { COMPANY_TYPES, DEBT_STATUS, filterKeys } from 'utils';

import {
  CurrentAccountInformation,
  PersonalInformation,
  ContactInformation,
  AccountInformation,
  InvoiceInformation,
  QuickContactCheckbox,
  CompanyInformation,
  CompanyContactInformation
} from '../createContactModal/components';
import { useDispatch } from 'react-redux';
import { updateContact, getContactById, deleteContact } from 'store/actions/contacts';
import { getCities } from 'store/actions/country';
import { initialContactFormValues, useContactContext } from 'contexts/contactContext';
import useModal from 'utils/hooks/useModal';
import DeleteConfirmationPopup from '../deleteConfirmationPopup';
import toast from 'react-hot-toast';

export default function UpdateContactModal({
  onClose,
  contactId,
  onUpdated = () => {},
  onlyRequired = false
}) {
  const { formValues, setFormValues, refetchContacts, selectedContact, setSelectedContact } =
    useContactContext();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCities());
    dispatch(getContactById(contactId)).then((data) => {
      setFormValues(data);
      setSelectedContact(data);
    });

    return () => {
      setFormValues(initialContactFormValues);
      setSelectedContact(null);
    };
  }, []);

  const onChangeCompanyType = (typeIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      companyType: typeIndex === 0 ? COMPANY_TYPES.INDIVIDUAL : COMPANY_TYPES.BUSINESS
    }));
  };

  const onSubmit = () => {
    if (formValues?.status === 'LOCKED') {
      toast.error('Bir belgeye bağlı Müşteri/Tedarikçiler düzenlemez');
      return;
    }
    let payload = { ...formValues };
    if (!formValues.hasOpeningBalance) {
      payload = filterKeys(payload, ['debtStatus', 'initialBalance', 'initialBalanceDate']);
    }

    payload = filterKeys(
      payload,
      ['hasOpeningBalance', 'isTaxIdentityNumberValid', 'isIbanValid'],
      {
        filterEmpty: true,
        filterNull: true
      }
    );

    dispatch(updateContact({ contactId, ...payload }))
      .then((res) => {
        if (res.status !== 200) return;
        onClose();
        refetchContacts();
        onUpdated(true);
      })
      .catch((err) => {
        onUpdated(false);
        console.log('err: ', err);
      });
  };

  const onDeleteContact = () => {
    dispatch(deleteContact({ contactId })).then(() => {
      toggleDeleteContactPopup();
      refetchContacts();
      onClose();
    });
  };

  const tabClassNameFunc = (isSelected) => {
    const classes = 'max-w-none border-b-0 relative ';
    const activeClasses = isSelected
      ? "after:content-[''] after:w-full  after:h-0.5 after:absolute after:left-0 after:-bottom-0.5 after:rounded-xl after:bg-green-500 "
      : '';
    return classes + activeClasses;
  };

  const tabs = [
    {
      title: 'Bireysel',
      tabClassNameFunc,
      disabled: selectedContact?.companyType !== COMPANY_TYPES.INDIVIDUAL,
      component: <Individual onSubmit={onSubmit} onlyRequired={onlyRequired} />
    },
    {
      title: 'Şirket',
      tabClassNameFunc,
      disabled: selectedContact?.companyType !== COMPANY_TYPES.BUSINESS,
      component: <Business onSubmit={onSubmit} onlyRequired={onlyRequired} />
    }
  ];

  const [isDeleteContactPopupActive, , toggleDeleteContactPopup] = useModal();

  const defaultIndex = selectedContact?.companyType === COMPANY_TYPES.INDIVIDUAL ? 0 : 1;

  return (
    <>
      <SideModal handleClose={onClose}>
        <div
          className="flex flex-col w-full overflow-y-auto px-4 gap-y-4 pb-10"
          data-testid="section_add_contact_modal"
        >
          <div className="gap-y-10">
            <h5 className="font-semibold text-[28px] text-gray-900 mb-10">
              Müşteri/Tedarikçi Düzenle
            </h5>
            {selectedContact && tabs?.length > 0 && (
              <Tab
                tabs={tabs}
                onChange={onChangeCompanyType}
                defaultIndex={defaultIndex}
                tabsClassName={
                  "border-b-0 px-0 gap-x-[10px] relative before:content-[''] before:w-full  before:h-0.5 before:absolute before:left-0 before:-bottom-0.5 before:rounded-xl before:bg-gray-200"
                }
              />
            )}
          </div>
          {!onlyRequired && (
            <Button color="red" onClick={toggleDeleteContactPopup}>
              Sil
            </Button>
          )}
        </div>
      </SideModal>
      <DeleteConfirmationPopup
        active={isDeleteContactPopupActive}
        message={`${formValues.firstName} ${formValues.lastName} silmek istediğinize emin misiniz?`}
        handleDelete={onDeleteContact}
        onClose={toggleDeleteContactPopup}
      />
    </>
  );
}

const Individual = ({ onSubmit, onlyRequired }) => {
  const { formValues } = useContactContext();

  if (onlyRequired) {
    const isDisabled =
      !formValues?.firstName ||
      !formValues?.lastName ||
      !formValues?.email ||
      !formValues?.taxIdentityNumber ||
      !formValues.cityId ||
      !formValues.districtId;

    return (
      <div className="flex flex-col gap-y-10 mt-10">
        <PersonalInformation />
        <ContactInformation onlyRequired />
        <InvoiceInformation onlyRequired />
        <Button onClick={onSubmit} disabled={isDisabled}>
          Kaydet
        </Button>
      </div>
    );
  } else {
    const isDisabled =
      !formValues?.businessType ||
      !formValues?.firstName ||
      !formValues?.lastName ||
      (formValues.iban && !formValues.isIbanValid) ||
      (formValues?.hasOpeningBalance &&
        ((formValues.initialBalance > 0 && formValues?.debtStatus === DEBT_STATUS.NONE.value) ||
          !formValues?.initialBalanceDate));
    return (
      <div className="flex flex-col gap-y-10 mt-10">
        <CurrentAccountInformation defaultBusinessType={formValues.businessType} />
        <PersonalInformation />
        <ContactInformation />
        <AccountInformation />
        <InvoiceInformation />
        <QuickContactCheckbox />

        <Button onClick={onSubmit} disabled={isDisabled}>
          Kaydet
        </Button>
      </div>
    );
  }
};

const Business = ({ onSubmit, onlyRequired }) => {
  const { formValues } = useContactContext();
  if (onlyRequired) {
    const isDisabled =
      !formValues?.companyName ||
      (formValues.taxIdentityNumber && !formValues.isTaxIdentityNumberValid) ||
      !formValues.taxOfficeId ||
      !formValues.cityId ||
      !formValues.address ||
      !formValues.email ||
      !formValues.districtId;

    return (
      <div className="flex flex-col gap-y-10 mt-10">
        <CompanyInformation onlyRequired />
        <CompanyContactInformation onlyRequired />
        <Button onClick={onSubmit} disabled={isDisabled}>
          Kaydet
        </Button>
      </div>
    );
  } else {
    const isDisabled =
      !formValues?.businessType ||
      !formValues?.companyName ||
      (formValues.iban && !formValues.isIbanValid) ||
      (formValues.taxIdentityNumber && !formValues.isTaxIdentityNumberValid) ||
      (formValues?.hasOpeningBalance &&
        ((formValues.initialBalance > 0 && formValues?.debtStatus === DEBT_STATUS.NONE.value) ||
          !formValues?.initialBalanceDate)) ||
      (formValues.initialBalance > 0 && !formValues.debtStatus);
    return (
      <div className="flex flex-col gap-y-10 mt-10">
        <CurrentAccountInformation />
        <CompanyInformation />
        <AccountInformation />
        <CompanyContactInformation />
        <QuickContactCheckbox />
        <Button onClick={onSubmit} disabled={isDisabled}>
          Kaydet
        </Button>
      </div>
    );
  }
};
