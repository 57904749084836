import classNames from 'classnames';
import { useEffect, useState } from 'react';

const CodeInput = ({
  value,
  length = 4,
  onChange,
  completed,
  // invisible,
  className,
  error = false,
  autofocus,
  only,
  blockPaste = false,
  ...props
}) => {
  only = ['text', 'number'].includes(only) ? only : null;

  // invisible = invisible || false;
  value = value || '';

  const [codeChars, setCodeChars] = useState(value.split(''));
  const [codeInput] = useState([]);

  useEffect(() => {
    // autofocus
    if (!autofocus) return;
    handleFocus(0);
  }, [autofocus]);

  function handleFocus(index) {
    if (codeChars.length === codeInput.length) {
      codeInput[codeInput.length - 1].disabled = false;
      codeInput[codeInput.length - 1].focus();
      return;
    }
    if (!codeInput[index]) index = codeInput.length - 1;
    codeInput[index].disabled = false;
    codeInput[index].focus();
  }

  const textRegex = /[^a-zA-ZçÇğĞıİöÖşŞüÜ\s]+/gi;
  const numberRegex = /[^0-9]+/gi;

  function handleInput(e) {
    if (only === 'text') {
      if (textRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(textRegex, '');
        return;
      }
    }
    if (only === 'number') {
      if (numberRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(numberRegex, '');
        return;
      }
    }

    const payload = [...codeChars, ...e.target.value.split('')].slice(0, length);
    setCodeChars(payload);

    codeInput.forEach((ci, index) => {
      if (!ci) return;
      ci.value = payload[index] || '';
    });
    handleFocus(payload.length);
    if (payload.length === codeChars.length && completed) completed();
    if (onChange) onChange(payload.join(''));
  }

  function handleKeyPress(e) {
    if (e.key !== 'Backspace') return;

    const input = codeInput[codeChars.length - 1];
    if (!input) return;
    input.value = '';
    setCodeChars(codeChars.slice(0, -1));
    handleFocus(codeChars.length - 1);
    if (onChange) onChange(codeChars.slice(0, -1).join(''));
  }

  function onPaste(e) {
    if (blockPaste) e.preventDefault();
  }

  const codeInputContainerClassnames = classNames('flex my-4 mx-2 cursor-pointer space-x-2');
  return (
    <div className={codeInputContainerClassnames} onClick={() => handleFocus(codeChars.length)}>
      {new Array(length).fill(0).map((_, index) => {
        const borderColor = error
          ? 'border-red-500'
          : codeChars[index]
          ? 'border-black'
          : index === codeChars.length
          ? 'border-green-500'
          : 'border-gray-200';
        const classNames = `${className} ${borderColor} text-4xl w-8 text-center border-b-2 border-solid bg-transparent focus:outline-none !appearance-none rounded-none`;

        return (
          <input
            key={index}
            ref={(ref) => (codeInput[index] = ref)}
            id={`input-${index}`}
            pattern="[0-9]*"
            className={classNames}
            style={{
              WebkitAppearance: 'none',
              appearance: 'none',
              MozAppearance: 'none',
              WebkitTextSecurity: 'disc'
            }}
            autoComplete="off"
            disabled={index !== codeChars.length || index === codeInput.length - 1}
            onChange={handleInput}
            onKeyDown={handleKeyPress}
            onPaste={onPaste}
            type={'password'}
            inputMode="numeric"
            {...props}
          />
        );
      })}
    </div>
  );
};

export default CodeInput;
