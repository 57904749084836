import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { useDispatch } from 'react-redux';
import { change_password, logout } from 'store/actions/auth';
import { useRouter } from 'utils/hooks/useRouter';

import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';

import {
  CurrentPassword,
  NewPassword,
  ConfirmNewPassword,
  SuccessfullyChanged
} from './subComponents/steps';
import ToastThemeWithTitle from 'components/toastThemeWithTitle';

const ChangePassword = ({ setResetPasswordStep }) => {
  const [step, setStep] = useState(0);

  const [actualPassword, setActualPassword] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const { push } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    let timeout;
    setResetPasswordStep(step);
    if (step === 3) {
      timeout = setTimeout(() => {
        dispatch(logout());
        toast.custom(
          <ToastThemeWithTitle
            title="Şifre güncellendi!"
            message="Lütfen yeni şifreniz ile giriş yapınız."
            icon={IconInfoCircle}
          />
        );
        push('/login');
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [step]);

  function nextStep() {
    setStep(step + 1);
  }

  function handleChangePassword() {
    change_password({ actualPassword, password, rePassword }).then(() => {
      nextStep();
    });
  }

  const STEPS = {
    0: (
      <CurrentPassword
        nextStep={nextStep}
        setActualPassword={setActualPassword}
        actualPassword={actualPassword}
      />
    ),
    1: <NewPassword nextStep={nextStep} setPassword={setPassword} password={password} />,
    2: (
      <ConfirmNewPassword
        handleChangePassword={handleChangePassword}
        setRePassword={setRePassword}
        rePassword={rePassword}
      />
    ),
    3: <SuccessfullyChanged />
  };

  return (
    <div className="flex w-full h-full justify-center items-center">
      <div className="flex w-[400px] h-auto justify-center items-center">{STEPS[step]}</div>
    </div>
  );
};

export default ChangePassword;
