import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, CustomInput } from 'components';

import { getCities, getDistrict } from 'store/actions/country';

const InvoiceInformation = ({ watch, setValue, errors, clearErrors, register }) => {
  const dispatch = useDispatch();

  const districtRef = useRef();

  const { cities, districts } = useSelector((state) => state.country);
  const filteredDistricts = districts[watch('cityId')] || [];

  useEffect(() => {
    dispatch(getCities());
  }, []);

  useEffect(() => {
    const cityId = watch('cityId');
    if (!cityId) return;

    dispatch(getDistrict({ cityId }));
  }, [watch('cityId')]);

  return (
    <div className="flex flex-col gap-y-4">
      <span
        className="text-[18px] text-gray-600 font-semibold"
        data-testid="section_invoice_information"
      >
        Fatura Bilgileri
      </span>
      <CustomInput
        key="individual_taxIdentityNumber"
        label="T.C. Kimlik Numarası"
        isOptional
        name="taxIdentityNumber"
        onChange={(e) => {
          setValue('taxIdentityNumber', e.target.value);
          clearErrors('taxIdentityNumber');
        }}
        maxLength={11}
        value={watch('taxIdentityNumber')}
        error={errors?.taxIdentityNumber?.message}
        only="number"
        register={register}
        data-testid="input_tax_identity_number"
      />
      <Select
        key="individual_cityId"
        className="w-full"
        options={cities}
        value="id"
        valueLabel="name"
        label="İl"
        isOptional
        defaultValue={watch('cityId')}
        onChange={(cId) => {
          setValue('cityId', Number(cId));
          setValue('districtId', null);
          districtRef.current.value = '';
          clearErrors('cityId');
        }}
        error={errors?.cityId?.message}
        data-testid="select_city"
      />
      <Select
        key="individual_districtId"
        selectRef={districtRef}
        className="w-full"
        options={filteredDistricts}
        value="id"
        valueLabel="name"
        label="İlçe"
        isOptional
        defaultValue={watch('districtId')}
        onChange={(districtId) => {
          setValue('districtId', Number(districtId));
          clearErrors('districtId');
        }}
        error={errors?.districtId?.message}
        data-testid="select_district"
      />
      <div className="flex flex-col w-full">
        <span className="text-sm text-gray-500 font-medium">
          Adres{' '}
          <span className="text-sm leading-5 font-medium text-gray-400 italic">
            {'(Opsiyonel)'}
          </span>
        </span>
        <textarea
          onChange={(e) => setValue('address', e.target.value)}
          value={watch('address')}
          className="bg-gray-100 resize-none rounded-lg focus:outline-none p-2 h-20 w-full"
          maxLength={140}
          data-testid="input_address"
        />
      </div>
    </div>
  );
};

export default InvoiceInformation;
