import axios from 'axios';

// LOGIN: Step 1
export const login = (params) => {
  const payload = {
    username: params.phone,
    password: params.password,
    grant_type: 'password',
    phone_number_code: 'TR'
  };

  const headers = {
    Authorization: `Basic ${
      process.env?.REACT_APP_BASIC_AUTH_STAGING ||
      process.env?.REACT_APP_BASIC_AUTH_DEV ||
      process.env?.REACT_APP_BASIC_AUTH_PROD
    }`
  };

  return () => {
    return axios.post('/oauth/token', null, { params: payload, headers });
  };
};

// LOGIN: Step 2
export const loginWMfaToken = (params) => {
  const payload = {
    username: params.phone,
    password: params.password,
    grant_type: 'password',
    phone_number_code: 'TR',
    otp_token: params.otpToken,
    mfa_token: params.mfaToken,
    user_info: true,
    remember_me: params.rememberMe
  };
  const headers = {
    Authorization: `Basic ${
      process.env?.REACT_APP_BASIC_AUTH_STAGING ||
      process.env?.REACT_APP_BASIC_AUTH_DEV ||
      process.env?.REACT_APP_BASIC_AUTH_PROD
    }`
  };
  return (dispatch) => {
    return axios.post('/oauth/token', null, { params: payload, headers }).then((res) => {
      if (!res.data.access_token) return res;
      dispatch({
        type: 'SET_TOKEN',
        data: res.data.access_token
      });
      if (res.data.remember_me_token) {
        localStorage.setItem('rememberMeToken', res.data.remember_me_token);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        dispatch({
          type: 'SET_REMEMBER_ME_TOKEN',
          data: res.data.remember_me_token
        });
      }
      dispatch({
        type: 'SET_USER_DATA',
        data: {
          birthDate: null,
          companyId: res.data?.user?.company?.id,
          companyName: res.data?.user?.company?.name,
          identityNumber: null,
          userId: res.data?.user?.id,
          ...res.data?.user
        }
      });
      localStorage.setItem('token', res.data.access_token);
      if (params.rememberMe) localStorage.setItem('phoneNumber', JSON.stringify(params.phone));
      return res;
    });
  };
};

export const toggleSessionEndModal = (value = true) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_SESSION_END_MODAL',
      data: value
    });
  };
};

export const toggleIdleTimePopup = (value = true) => {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_IDLE_TIME_POPUP',
      data: value
    });
  };
};

export const loginWRememberMeToken = (params) => {
  return (_, getState) => {
    const { rememberMeToken, user } = getState().auth;

    const payload = {
      username: user.fullPhoneNumber.slice(3),
      password: params.password,
      grant_type: 'password',
      phone_number_code: 'TR',
      user_info: true,
      remember_me_token: rememberMeToken
    };
    const headers = {
      Authorization: `Basic ${
        process.env?.REACT_APP_BASIC_AUTH_STAGING ||
        process.env?.REACT_APP_BASIC_AUTH_DEV ||
        process.env?.REACT_APP_BASIC_AUTH_PROD
      }`
    };
    return axios.post('/oauth/token', null, { params: payload, headers });
  };
};

// FORGOT PASSWORD: Step 1
export const forgotPassword = (params) => {
  return () => {
    const payload = {
      phoneNumberCode: 'TR',
      phoneNumber: params.phoneNumber
    };
    return axios.post('/api/passwords/forgot-password', payload);
  };
};

// FORGOT PASSWORD: Step 2
export const forgotPasswordWMfaToken = (params) => {
  return () => {
    const payload = {
      phoneNumberCode: 'TR',
      phoneNumber: params.phoneNumber,
      mfaToken: params.mfaToken,
      otpToken: params.otpToken
    };
    return axios.post('/api/passwords/forgot-password', payload);
  };
};

// FORGOT PASSWORD: Step 3
export const forgotPasswordUpdate = (params) => {
  return () => {
    const payload = {
      mfaToken: params.mfaToken,
      password: params.password,
      rePassword: params.rePassword
    };
    return axios.put('/api/passwords/forgot-password', payload);
  };
};

export const getUserData = () => {
  return async (dispatch) => {
    return axios
      .get('/api/users/profile')
      .then((res) => {
        dispatch({
          type: 'SET_USER_DATA',
          data: res.data.data
        });
        return res;
      })
      .catch(() => {
        dispatch(logout());
      });
  };
};

export const getUser = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/users/profile');
      return response.data.data;
    } catch (error) {
      dispatch(logout());
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT_USER'
    });

    localStorage.removeItem('token');
  };
};

export const revokeToken = () => {
  return axios.get('/revoke');
};

export const forgotMe = () => {
  return (dispatch) => {
    dispatch({
      type: 'SET_TOKEN',
      data: null
    });
    dispatch({
      type: 'SET_REMEMBER_ME_TOKEN',
      data: null
    });
    dispatch({
      type: 'SET_SIGN_UP_TOKEN',
      data: null
    });
    dispatch({
      type: 'SET_USER_DATA',
      data: {
        birthDate: null,
        companyId: null,
        email: null,
        firstName: null,
        fullPhoneNumber: null,
        identityNumber: null,
        lastName: null,
        userId: null
      }
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('rememberMeToken');
    localStorage.removeItem('phoneNumber');
  };
};

export const getClientCredentialsToken = () => {
  return (dispatch) => {
    const payload = { grant_type: 'client_credentials' };
    const headers = {
      Authorization: `Basic ${
        process.env?.REACT_APP_BASIC_AUTH_STAGING ||
        process.env?.REACT_APP_BASIC_AUTH_DEV ||
        process.env?.REACT_APP_BASIC_AUTH_PROD
      }`
    };
    return axios.post('/oauth/token', null, { params: payload, headers }).then((res) => {
      dispatch({
        type: 'SET_CLIENT_CREDENTIALS_TOKEN',
        data: res.data.access_token
      });
      return res;
    });
  };
};

export const findWhereIStayFromSignUp = () => {
  return () => {
    const signUpToken = localStorage.getItem('signUpToken');
    return axios.get(`/signup/token/${signUpToken}`);
  };
};

// SIGNUP: step 1
export const signup = ({ email, grantMarketing }) => {
  return (dispatch) => {
    const payload = { email, grantMarketing };
    return axios.post('/signup', payload).then((res) => {
      // res: { data: { signupToken: ''} }
      localStorage.setItem('signUpToken', res.data.data.signUpToken);
      dispatch({
        type: 'SET_SIGN_UP_TOKEN',
        data: res.data.data.signUpToken
      });
      return res;
    });
  };
};

// SIGNUP: step 2, 3
// This endpoint has two type behaviors first one is send otp token to user’s email, second is to approve email with otp token that is sent to user’s email
// First request should include just signUpToken.
// Second request should include signUpToken and otpToken
export const signup_email = ({ otpToken }) => {
  return (_, getState) => {
    const { signUpToken } = getState().auth;
    const payload = otpToken ? { signUpToken, otpToken } : { signUpToken };

    return axios.post('/signup/email', payload);
  };
};

// SIGNUP: step 4
export const signup_identity = ({ birthDate, firstName, identityNumber, lastName }) => {
  return (_, getState) => {
    const { signUpToken } = getState().auth;
    const payload = { birthDate, firstName, identityNumber, lastName, signUpToken };

    return axios.post('/signup/identity', payload);
  };
};

// SIGNUP: step 5, 6
// This endpoint has two type behaviors first one is send otp token to user’s phone number, second is to approve phone number with otp token that is sent to user’s phone number
// First request should include signUpToken, phoneNumber and phoneNumberCode.
// Second request should include signUpToken and otpToken
export const signup_phone = ({ otpToken, phoneNumber, phoneNumberCode }) => {
  return (_, getState) => {
    const { signUpToken } = getState().auth;
    const payload = { signUpToken, phoneNumber, phoneNumberCode, otpToken };
    return axios.post('/signup/phone', payload);
  };
};

// SIGNUP: step 7
export const signup_password = ({ password, rePassword }) => {
  return (_, getState) => {
    const { signUpToken } = getState().auth;
    const payload = { signUpToken, password, rePassword };

    return axios.post('/signup/password', payload);
  };
};

// SIGNUP: step 8
export const signup_agreement = () => {
  return (_, getState) => {
    const { signUpToken } = getState().auth;
    const payload = { signUpToken };

    return axios.post('/signup/agreement', payload);
  };
};

// CHANGE PHONE NUMBER: step 0
export const change_phone_number = ({ phoneNumber }) => {
  return axios.put(`/api/users/phone`, { phoneNumber, phoneNumberCode: 'TR' });
};

// CHANGE PHONE NUMBER: step 1
export const change_phone_number_otp_token = ({ mfaToken, otpToken }) => {
  return axios.put(`/api/users/phone`, { mfaToken, otpToken });
};

// UPDATE EMAIL: step 0
export const update_email = ({ email }) => {
  return axios.put(`/api/users/email`, { email });
};

// UPDATE EMAIL: step 1
export const update_email_otp_token = ({ otpToken, mfaToken, email }) => {
  return axios.put(`/api/users/email`, { otpToken, mfaToken, email });
};

export const change_password = ({ actualPassword, password, rePassword }) => {
  const clientCredentials = atob('bm9ybWEtbW9iaWxlOjEyMzEyMw==').split(':');

  return axios.put('/api/users/password', {
    clientId: clientCredentials[0],
    clientSecret: clientCredentials[1],
    actualPassword,
    password,
    rePassword
  });
};

export const validate_password = (password) => {
  return axios.get('/api/users/password/check', { params: { password } });
};

// update user profile photo
export const updateUserProfilePhoto = ({ userId, file }) => {
  return axios.post(`/api/users/${userId}/profile-photo`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
