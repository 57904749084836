import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getCompanyDocuments } from 'store/actions/documents';
import { getFormattedDateWithSeperator } from 'utils';
import useModal from 'utils/hooks/useModal';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import TabSubTitle from 'views/profile/tabs/tabSubTitle';
import useDengage from 'utils/hooks/useDengage';
import { ViewCompanyDocumentModal } from './subComponents';
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-right.svg';

const Document = () => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const viewCompanyDocumentModalRef = useRef(null);
  const { publishEvent, eventTypes } = useDengage();

  const documents = useSelector((state) => state.documents);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'profile_and_settings_company_documents' });
  }, []);

  const [
    isViewCompanyDocumentModalOpen,
    setViewCompanyDocumentModal,
    toggleViewCompanyDocumentModal
  ] = useModal({
    shouldBeBlockScroll: true
  });
  useOnClickOutside(viewCompanyDocumentModalRef, () => setViewCompanyDocumentModal(false));

  useEffect(() => {
    if (user?.companyId) dispatch(getCompanyDocuments(user?.companyId));
  }, [user]);

  const onSelectDocument = (document) => {
    setSelectedDocument(document);
    toggleViewCompanyDocumentModal();
  };

  if (!documents) return null;

  return (
    <div className="w-full mb-4 flex flex-col" data-testid="section_documents_company">
      <TabSubTitle className="text-[22px] leading-7 px-8">
        Şirket Belgeleri ({documents?.totalElements})
      </TabSubTitle>

      <ViewCompanyDocumentModal
        ref={viewCompanyDocumentModalRef}
        isActive={isViewCompanyDocumentModalOpen}
        handleClose={toggleViewCompanyDocumentModal}
        selectedDocument={selectedDocument}
      />

      <div className="w-full" data-testid="section_documents_company_list">
        <div className="flex justify-between px-8">
          <div className="py-4 font-semibold text-sm leading-5 text-gray-700">Dosya İsmi</div>
          <div className="py-4 font-semibold text-sm leading-5 text-gray-700">
            Yüklenme Tarihi
          </div>
        </div>
        <div>
          {documents.content?.map((document, i) => (
            <div
              key={i}
              className="flex justify-between border-b border-solid border-gray-100 hover:bg-gray-50 group transition cursor-pointer text-gray-900"
              onClick={() => onSelectDocument(document)}
            >
              <div className="flex items-center gap-x-4 py-6 text-base leading-6 font-semibold text-gray-900 px-8">
                {document?.file?.name.split('.')[0]}
                <IconArrowRight className="w-4 h-4 stroke-current -rotate-45 hidden group-hover:block" />
              </div>
              <div className="py-6 text-sm px-8">
                {getFormattedDateWithSeperator(document.createDate)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Document;
