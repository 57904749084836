import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Button, Checkbox, Divider, CustomInput, DatePicker, Popup } from 'components';

import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getBinQuery } from 'store/actions/binQuery';
import { checkCardAlias, resetPaymentStatus } from 'store/actions/payments';
import useDebounce from 'utils/hooks/useDebounce';
import useGetClientIP from 'utils/hooks/useGetClientIp';
import useModal from 'utils/hooks/useModal';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';

import { payWithCard, resetSubscription } from 'store/actions/marketplace';
import './index.css';
import AgreementModal from '../agreementModal';

export default function AddNewCard({ variant, onGoBack, setCardInformations }) {
  const [binNumber, setBinNumber] = useState('');
  const [cardAlias, setCardAlias] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();
  const { data: binQuery } = useSelector((state) => state.binQuery);
  const { user } = useSelector((state) => state.auth);
  const { payment, isCardAliasExist } = useSelector((state) => state.payments);
  const clientIp = useGetClientIP();

  const [isSaveCardPopupActive, , toggleSaveCardPopup] = useModal();
  const [isAgreementModalActive, , toggleAgreementModal] = useModal();

  const date = new Date();
  const { price } = variant;

  const minMaxDate = useMemo(
    () => ({
      minDate: new Date(),
      maxDate: new Date(date.getFullYear() + 20, date.getMonth(), date.getDate())
    }),
    []
  );
  const { register, watch, setValue, getValues } = useForm();
  const binNumberInputRef = useRef(null);
  const cardAliasInputRef = useRef(null);
  useDebounce(binNumber, 500, binNumberInputRef, (val) => {
    dispatch(getBinQuery({ binNumber: val, amount: price * 100 }));
  });

  useDebounce(cardAlias, 500, cardAliasInputRef, (val) => {
    dispatch(checkCardAlias(val));
  });

  useEffect(() => {
    const { cardCvc, cardNumber, cardOwnerName, expirationDate, saveCard } = getValues();

    if (payment?.transactionId && saveCard) {
      const expDate = new Date(expirationDate).toLocaleString('tr-tr', {
        month: '2-digit',
        year: 'numeric'
      });

      const cardExpireMonth = expDate.split('.')[0];
      const cardExpireYear = expDate.split('.')[1].substring(2, 4);

      const addCardPayload = {
        cardAlias,
        cardCvc,
        cardExpireMonth,
        cardExpireYear,
        cardNumber,
        cardOwnerName,
        clientIp,
        transactionId: payment?.transactionId
      };

      setCardInformations(addCardPayload);
    }
  }, [payment]);

  useEffect(
    () => checkValidation(),
    [
      watch('cardOwnerName'),
      watch('cardNumber'),
      watch('expirationDate'),
      watch('cardCvc'),
      watch('saveCard'),
      watch('cardAlias'),
      watch('agreementAccepted'),
      binQuery,
      isCardAliasExist
    ]
  );

  const checkValidation = () => {
    let isValid =
      watch('cardCvc')?.length === 3 &&
      watch('cardNumber')?.length === 16 &&
      watch('cardOwnerName')?.length >= 4 &&
      watch('cardOwnerName')?.length <= 100 &&
      !!watch('expirationDate') &&
      !!watch('agreementAccepted') &&
      !!binQuery?.bankId;

    if (watch('saveCard')) {
      isValid =
        isValid &&
        watch('cardAlias')?.length > 0 &&
        watch('cardAlias')?.length <= 20 &&
        !isCardAliasExist;
    }

    setIsFormValid(isValid);
  };

  const onCardChange = (e) => {
    const { value } = e.target;
    if (value.length > 5) {
      setBinNumber(value.slice(0, 6));
    }
    if (value.length === 16) {
      //TODO: auto focus on expiry date input
    }
    setValue('cardNumber', value);
    return e;
  };

  const onCardAliasChange = (e) => {
    const { value } = e.target;
    setValue('cardAlias', value);
    if (value.length > 0 && value.length <= 20) setCardAlias(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!user || !binQuery) return;

    dispatch(resetPaymentStatus());
    dispatch(resetSubscription());
    const { cardCvc, cardNumber, cardOwnerName, expirationDate, saveCard } = getValues();

    if (variant?.renewable && !saveCard) return toggleSaveCardPopup();

    const expDate = new Date(expirationDate).toLocaleString('tr-tr', {
      month: '2-digit',
      year: 'numeric'
    });
    const cardExpireMonth = expDate.split('.')[0];
    const cardExpireYear = expDate.split('.')[1].substring(2, 4);

    dispatch(
      payWithCard(variant.id, {
        alias: watch('cardAlias'),
        store: watch('saveCard'),
        card: {
          cvv: cardCvc,
          expiryMonth: cardExpireMonth,
          expiryYear: cardExpireYear,
          holder: cardOwnerName,
          pan: cardNumber
        }
      })
    );
  };

  const onShowAgreement = (e) => {
    e.preventDefault();
    toggleAgreementModal();
  };

  const onAgreementAccepted = (isAccepted) => {
    setValue('agreementAccepted', isAccepted);
    toggleAgreementModal();
  };

  return (
    <div className="w-full h-full">
      <Button
        variant="circle"
        color="gray"
        icon={IconArrowLeft}
        onClick={() => onGoBack(false)}
      />
      <form className="flex flex-col space-y-4 my-4" onSubmit={onSubmit}>
        <fieldset>
          <FormLabel>Kart sahibinin adı soyadı</FormLabel>
          <CustomInput
            only="text"
            name="cardOwnerName"
            inputClassName="uppercase"
            maxLength={100}
            value={watch('cardOwnerName')}
            onChange={(e) => setValue('cardOwnerName', e.target.value)}
          />
        </fieldset>
        <fieldset>
          <FormLabel>Kart numarası</FormLabel>
          <CustomInput
            only="number"
            name="cardNumber"
            maxLength={16}
            onChange={onCardChange}
            refProp={binNumberInputRef}
          />
        </fieldset>
        <div className="flex space-x-4">
          <fieldset className="flex-1">
            <FormLabel>Son kullanma tarihi</FormLabel>
            <DatePicker
              selected={watch('expirationDate')}
              onChange={(date) => setValue('expirationDate', date)}
              className="w-full bg-gray-100 py-3 px-2 rounded-lg mt-1 focus:outline-none"
              dateFormat="MM/yy"
              customInput={<InputMask mask="99/99" />}
              minDate={minMaxDate.minDate}
              maxDate={minMaxDate.maxDate}
              showYearDropdown
              showMonthYearPicker
              dropdownMode="select"
            />
          </fieldset>
          <fieldset className="flex-initial">
            <FormLabel>CVC</FormLabel>
            <CustomInput
              only="number"
              name="cardCvc"
              className="max-w-[140px]"
              value={watch('cardCvc')}
              maxLength={3}
              onChange={(e) => setValue('cardCvc', e.target.value)}
            />
          </fieldset>
        </div>
        <fieldset>
          <Checkbox
            name="saveCard"
            className="text-gray-900 font-medium text-sm leading-5"
            register={register}
          >
            Kartımın kaydedilmesine izin veriyorum.
          </Checkbox>
        </fieldset>
        <fieldset>
          <FormLabel>Kart İsmi</FormLabel>
          <CustomInput
            name="cardAlias"
            maxLength={20}
            value={watch('cardAlias')}
            onChange={onCardAliasChange}
            refProp={cardAliasInputRef}
          />
        </fieldset>
        <Divider className="my-10" />
        <Checkbox
          name="agreementAccepted"
          className="text-gray-900 font-medium text-sm leading-5 !items-start"
          register={register}
          onChange={(e) => setValue('agreementAccepted', e.target.checked)}
        >
          <Button variant="ghost" className="!p-0" onClick={onShowAgreement}>
            Satış sözleşmesini
          </Button>
          <span> ve ödemelerin her ay kartımdan veya hesabımdan çekilmesini onaylıyorum.</span>
        </Checkbox>
        <Button className="w-full" type="submit" disabled={!isFormValid}>
          Ödeme Yap
        </Button>
      </form>
      {isSaveCardPopupActive && (
        <SaveCardPopup
          onApprove={() => {
            setValue('saveCard', true);
            toggleSaveCardPopup();
          }}
          onCancel={() => {
            toggleSaveCardPopup();
            setValue('saveCard', false);
          }}
        />
      )}
      {isAgreementModalActive && (
        <AgreementModal
          onClose={toggleAgreementModal}
          onAgreementAccepted={onAgreementAccepted}
        />
      )}
    </div>
  );
}

const FormLabel = ({ children }) => (
  <label className="text-gray-500 font-medium text-sm leading-5">{children}</label>
);

const SaveCardPopup = ({ onApprove, onCancel }) => {
  return (
    <Popup
      active={true}
      childrenClassName="w-[327px] h-[428px] flex flex-col items-center justify-between text-center"
    >
      <div className="flex flex-col items-center gap-y-6">
        <div className="flex items-center justify-center w-20 h-20 rounded-full bg-yellow-100">
          <InfoCircleIcon className="fill-current w-10 text-yellow-600" />
        </div>
        <div className="flex flex-col gap-y-3">
          <span className="text-lg font-semibold text-gray-900">Kart Kaydetme</span>
          <span className="text-base font-medium text-gray-500">
            Satın almak istediğiniz hizmet aylık ödeme gerektirdiği için kartınızı isim vererek
            kaydetmeniz gerekmektedir.
          </span>
        </div>
        <Button variant="primary" className="w-full" onClick={onApprove}>
          Kartı Kaydet
        </Button>
        <Button variant="red" className="w-full" onClick={onCancel}>
          Vazgeç
        </Button>
      </div>
    </Popup>
  );
};
