import React, { useState } from 'react';
import { SideModal, DatePicker, Button } from 'components';
import { getFormattedDateWithSeperator } from 'utils';

import { ReactComponent as IconEndDate } from 'assets/illustrations/filters/end-route.svg';
import { ReactComponent as IconStartDate } from 'assets/illustrations/filters/start-route.svg';
import { useInvoiceListContext } from '../invoiceList';

export default function EInvoiceFilterModal({ onClose = () => {} }) {
  const { filters, setFilters } = useInvoiceListContext();
  const [startDate, setStartDate] = useState(filters?.startDate);
  const [endDate, setEndDate] = useState(filters?.endDate);

  const onStartDateChanged = (date) => {
    setStartDate(date);
  };

  const onEndDateChanged = (date) => {
    setEndDate(date);
  };

  const applyFilters = () => {
    setFilters((prevState) => ({
      ...prevState,
      startDate,
      endDate
    }));
    onClose();
  };

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilters((prevState) => ({ ...prevState, startDate: null, endDate: null }));
    onClose();
  };

  return (
    <SideModal handleClose={onClose} className="min-h-screen">
      <div className="flex flex-col gap-y-12 pb-4 overflow-y-auto px-4 w-full">
        <span className="text-3xl font-semibold text-gray-900">Filtrele</span>
        <FilterItem title="Tarih Aralığı">
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <IconStartDate className="w-8" />
                <span className="ml-4" data-testid="label_filters_modal_start_date">
                  Başlangıç
                </span>
              </div>
              <div className="w-[50px]]">
                <DatePicker
                  className="font-semibold"
                  selected={startDate}
                  onChange={onStartDateChanged}
                  placeholder={getFormattedDateWithSeperator(new Date())}
                />
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <IconEndDate className="w-8" />
                <span className="ml-4" data-testid="label_filters_modal_end_date">
                  Bitiş
                </span>
              </div>
              <div className="w-[50px]]">
                <DatePicker
                  className="font-semibold"
                  selected={endDate}
                  onChange={onEndDateChanged}
                  placeholder={getFormattedDateWithSeperator(new Date())}
                />
              </div>
            </div>
          </div>
        </FilterItem>
        <div className="flex flex-col gap-y-4">
          <Button onClick={applyFilters}>Uygula</Button>
          <Button color="secondary" onClick={clearFilters}>
            Temizle
          </Button>
        </div>
      </div>
    </SideModal>
  );
}

const FilterItem = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-y-4 w-full">
      <span className="font-normal text-purple-700">{title}</span>
      {children}
    </div>
  );
};
