import { InfoMessage, Card } from 'components';
import { useEffect } from 'react';

import StepHeader from 'views/company-options/StepHeader';
import Car from 'assets/illustrations/car.png';
import LocationSlash from 'assets/illustrations/location-slash.png';
import Map from 'assets/illustrations/map.png';

import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { useRouter } from 'utils/hooks/useRouter';
import routes from 'router';

import classNames from 'classnames';

export default function CompanyBusinessAddress({
  setValue,
  nextStep,
  hasVirtualOfficeSubscription
}) {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();
  const { push } = useRouter();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'start_company_address_type' });
  }, []);

  useEffect(() => {
    if (hasVirtualOfficeSubscription) {
      nextStep(4);
    }
  }, [hasVirtualOfficeSubscription]);

  function handleCompanyAddressType(type) {
    setValue('companyAddressType', type);
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'start_company_activity_address'
    });
    switch (type) {
      case 'REAL':
        nextStep(3);
        break;
      case 'VIRTUAL':
        if (!hasVirtualOfficeSubscription)
          return push(
            `${routes.onboardingMarketplace.path}?shouldBeSubscribeVirtualOffice=true&redirectUrl=${routes.startCompany.path}?step=4`,
            {
              redirectUrl: `${routes.startCompany.path}?step=4`
            }
          );
        nextStep(2);
        break;
      case 'VEHICLE':
        nextStep(3);
        break;
      default:
        break;
    }
  }

  const containerClassnames = classNames('flex flex-col space-y-4 max-w-md');

  return (
    <div className="w-full">
      <StepHeader title="Şirket Kur" subtitle="Faaliyet adresinizi belirleyin" />
      <div className={containerClassnames}>
        <CompanyAddressCard
          title="Faaliyet adresim var"
          description="İşinizi yönettiğiniz bir adresiniz varsa adres bilgilerini ekleyin."
          imageUrl={Map}
          onClick={() => handleCompanyAddressType('REAL')}
          data-testid="section_start_company_address_real"
        />
        <CompanyAddressCard
          title="Faaliyet adresim yok"
          description="Faaliyet adresiniz yoksa hemen İstanbul’da sanal ofis açın."
          imageUrl={LocationSlash}
          onClick={() => handleCompanyAddressType('VIRTUAL')}
          data-testid="section_start_company_address_virtual"
        />
        <CompanyAddressCard
          title=" Araçla faaliyet yapacağım"
          description="Faaliyetinizi aracınız ile yapıyorsanız adres eklemeden devam edin."
          imageUrl={Car}
          onClick={() => handleCompanyAddressType('VEHICLE')}
          data-testid="section_start_company_address_vehicle"
        />
        <InfoMessage>
          Şahıs şirketi kurulumunda, kendi ev adresinizi resmi iş adresiniz olarak
          gösterebilirsiniz.
        </InfoMessage>
      </div>
    </div>
  );
}

const CompanyAddressCard = ({ title, description, imageUrl, onClick, ...props }) => {
  const companyAddressCardClassnames = classNames(
    'flex cursor-pointer hover:translate-y-1 space-x-4'
  );
  return (
    <Card className={companyAddressCardClassnames} onClick={onClick} {...props}>
      <img src={imageUrl} className="w-8 h-8" alt="map" />
      <div className="flex flex-col">
        <span className="text-gray-900 font-semibold leading-6">{title}</span>
        <span className="text-gray-700 text-sm font-medium">{description}</span>
      </div>
    </Card>
  );
};
