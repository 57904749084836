import { useEffect } from 'react';
import { CustomButton, Popup } from 'components';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';

const SaleCanNotDeletePopup = ({ active, onClose }) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_record_non_erasable' });
  }, []);
  return (
    <Popup style={{ width: '311px', height: 'auto' }} active={active} onClose={onClose}>
      <div className="flex flex-col items-center text-center">
        <span className="rounded-full w-20 h-20 bg-yellow-100 flex items-center justify-center">
          <InfoCircleIcon className="fill-current text-yellow-600 w-8" />
        </span>
        <span className="text-lg font-semibold text-gray-900 mt-6">Bu Kayıt Silinemez</span>
        <span className="text-gray-500 mt-3">
          Fatura ile iliskilendirilmiş kayıtlar silinemez
        </span>
        <CustomButton color="success" className="mt-6 w-full" onClick={onClose}>
          Tamam
        </CustomButton>
      </div>
    </Popup>
  );
};

export default SaleCanNotDeletePopup;
