import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import {
  createCredential,
  getCredential,
  getManagementCredentialCheck,
  updateCredential
} from 'store/actions/eTransformation';
import { CustomButton, CustomInput } from 'components';
import { getCompany } from 'store/actions/company';
import useDengage from 'utils/hooks/useDengage';
import TabSubTitle from '../tabSubTitle';
import { filterKeys, E_TRANSFORMATION_TYPES, COMPANY_SUB_TYPES } from 'utils';

const EFinanceInfo = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [buttonContent, setButtonContent] = useState('Kaydet');
  const [processType, setProcessType] = useState('Kaydet');
  const [isDisable, setIsDisable] = useState(false);
  const [transformationType, setTransformationType] = useState('E_INVOICE');
  const [companyInfo, setCompanyInfo] = useState({});
  const { publishEvent, eventTypes } = useDengage();

  const credentials = useSelector((state) => state.eTransformation.credentials);

  useEffect(() => {
    dispatch(getManagementCredentialCheck()).then((res) => {
      if (res.data.data.exists) {
        dispatch(getCredential());
        setProcessType('Güncelle');
        setIsDisable(true);
      }
    });
    dispatch(getCompany()).then((res) => {
      setCompanyInfo(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    setUserName(credentials?.username);
    setPassword(credentials?.password);
  }, [credentials]);

  useEffect(() => {
    if (processType == 'Kaydet' || processType == 'Kaydet2') {
      setButtonContent('Kaydet');
    } else {
      setButtonContent('Güncelle');
    }
  }, [processType]);

  useEffect(() => {
    if (companyInfo?.subType === COMPANY_SUB_TYPES.SOLE_TRADER) {
      setTransformationType(E_TRANSFORMATION_TYPES.E_INVOICE);
    } else if (companyInfo?.subType === COMPANY_SUB_TYPES.FREE_LANCER) {
      setTransformationType(E_TRANSFORMATION_TYPES.E_RECEIPT);
    }
  }, [companyInfo]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_and_settings_integrator_info'
    });
  }, []);

  const onUserNameChange = (e) => setUserName(e.target.value);
  const onPasswordChange = (e) => setPassword(e.target.value);

  const handleProcess = () => {
    if (processType == 'Güncelle' || processType == 'Kaydet2') {
      setIsDisable(false);
      setPassword('');
      if (processType == 'Kaydet2') {
        onSubmitUpdate();
      }
      setProcessType('Kaydet2');
    } else {
      onSubmitRegister();
    }
  };

  const onSubmitRegister = () => {
    const filteredCredentials = filterKeys(credentials, [
      'providerName',
      'updateDate',
      'createDate',
      'id',
      'active'
    ]);
    dispatch(
      createCredential({
        ...filteredCredentials,
        transformationType,
        username: userName,
        password: password,
        active: true
      })
    )
      .then(() => {
        toast.success('Başarıyla kaydedildi');
        setIsDisable(true);
        setProcessType('Güncelle');
      })
      .catch(() => {
        toast.error('Kullanıcı adınız ya da şifreniz hatalı.');
      });
  };

  const onSubmitUpdate = () => {
    const filteredCredentials = filterKeys(credentials, [
      'providerName',
      'updateDate',
      'createDate',
      'id',
      'active'
    ]);
    dispatch(
      updateCredential({
        ...filteredCredentials,
        transformationType,
        username: userName,
        password: password
      })
    )
      .then(() => {
        toast.success('Başarıyla güncellendi');
        setProcessType('Güncelle');
      })
      .catch(() => {
        toast.error('Kullanıcı adınız ya da şifreniz hatalı.');
      });
  };
  // const onSubmit = () => {
  //   const filteredCredentials = filterKeys(credentials, [
  //     'providerName',
  //     'updateDate',
  //     'createDate',
  //     'id',
  //     'active'
  //   ]);

  //   if (Object.keys(filteredCredentials).length > 0) {
  //     dispatch(
  //       updateCredential({
  //         ...filteredCredentials,
  //         transformationType,
  //         username: userName,
  //         password: password
  //       })
  //     )
  //       .then(() => {
  //         toast.success('Başarıyla güncellendi');
  //       })
  //       .catch(() => {
  //         toast.error('Kullanıcı adınız ya da şifreniz hatalı.');
  //       });
  //   } else {
  //     dispatch(
  //       createCredential({
  //         ...filteredCredentials,
  //         transformationType,
  //         username: userName,
  //         password: password,
  //         active: true
  //       })
  //     )
  //       .then(() => {
  //         toast.success('Başarıyla kaydedildi');
  //       })
  //       .catch(() => {
  //         toast.error('Kullanıcı adınız ya da şifreniz hatalı.');
  //       });
  //   }
  // };

  return (
    <div className="w-full max-w-md mb-4 flex flex-col">
      <TabSubTitle>Entegratör Bilgileri</TabSubTitle>
      <CustomInput
        type="text"
        defaultValue={userName}
        value={userName}
        className="w-full mb-6"
        label="Entegratör Kullanıcı Adı"
        onChange={onUserNameChange}
        disabled={isDisable}
      />
      <CustomInput
        type="password"
        defaultValue={password}
        value={password}
        className="w-full mb-10"
        label="Entegratör Şifresi"
        onChange={onPasswordChange}
        disabled={isDisable}
      />
      <CustomButton
        color="success"
        onClick={handleProcess}
        disabled={
          // (userName === credentials?.username && password === credentials?.password) ||
          !password || !userName
        }
      >
        {buttonContent}
      </CustomButton>
    </div>
  );
};

export default EFinanceInfo;
