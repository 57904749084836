import { BottomModal } from 'components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { downloadInvoice } from 'store/actions/eTransformation';
import ReceiptPdf from 'views/invoicing/components/eDocumentPreview/ReceiptPdf';

const PreviewDocument = ({ handleClose, documentId }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { eDocument } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (!user.companyId || !documentId) return;

    dispatch(downloadInvoice({ companyId: user.companyId, documentId, 'content-type': 'PDF' }));
  }, []);

  return (
    <BottomModal handleClose={handleClose}>
      <div
        className="flex justify-center h-full bg-gray-50"
        data-testid="section_preview_document_container"
      >
        <div className="w-[582px] h-full flex flex-col overflow-y-scroll py-10 pt-28">
          <span className="font-semibold text-3xl text-gray-900">Belge</span>
          <div className="w-full rounded-lg border border-solid border-gray-300 mt-6 bg-white p-4 flex items-center justify-center">
            <ReceiptPdf pdfUrl={eDocument} />
          </div>
        </div>
      </div>
    </BottomModal>
  );
};

export default PreviewDocument;
