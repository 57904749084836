import axios from 'axios';
import { marketPlaceTypes } from 'store/types';
// import { getFormattedDateForPayload } from 'utils';

const {
  GET_MARKETPLACE_ITEMS,
  GET_MARKETPLACE_VARIANTS,
  CANCEL_MARKETPLACE_SUBSCRIPTION,
  CREATE_MARKETPLACE_SUBSCRIPTION,
  CREATE_MARKETPLACE_SUBSCRIPTION_FAILURE,
  CREATE_MARKETPLACE_SUBSCRIPTION_SUCCESS,
  PAY_WITH_CARD,
  PAY_WITH_CARD_FAILURE,
  PAY_WITH_CARD_SUCCESS,
  RESET_MARKETPLACE_SUBSCRIPTION,
  GET_MARKETPLACE_SUBSCRIPTIONS,
  GET_MARKETPLACE_SUBSCRIPTIONS_FAILURE,
  GET_MARKETPLACE_SUBSCRIPTIONS_SUCCESS,
  PAY_WITH_EXISTING_CARD,
  PAY_WITH_EXISTING_CARD_FAILURE,
  PAY_WITH_EXISTING_CARD_SUCCESS
} = marketPlaceTypes;

const baseApiUrl = 'api/marketplaces';

export function getMarketplaceItems() {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();

    return axios.get(`${baseApiUrl}/items`).then(({ data }) => {
      dispatch({
        type: GET_MARKETPLACE_ITEMS,
        data: data.data
      });
      return data;
    });
  };
}

export function getMarketplaceVariants(itemId) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();

    return axios.get(`${baseApiUrl}/items/${itemId}/variants`).then(({ data }) => {
      dispatch({
        type: GET_MARKETPLACE_VARIANTS,
        data
      });
      return data;
    });
  };
}

export function cancelMarketplaceSubscription(subscriptionRefereneceId) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios
      .post(`${baseApiUrl}/subscriptions/${subscriptionRefereneceId}/cancel`)
      .then(({ data }) => {
        dispatch({
          type: CANCEL_MARKETPLACE_SUBSCRIPTION,
          data
        });
        return data;
      });
  };
}

export const createVariantSubscription = (variantId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_MARKETPLACE_SUBSCRIPTION
      });
      const { user } = getState().auth;
      if (!user.companyId) return Promise.reject();
      const response = await axios.post(`${baseApiUrl}/variants/${variantId}/subscriptions`, {
        quantity: 1
      });
      if (response.status === 200)
        dispatch({
          type: CREATE_MARKETPLACE_SUBSCRIPTION_SUCCESS,
          data: response.data?.data
        });
      return Promise.resolve(response.data?.data);
    } catch (error) {
      dispatch({
        type: CREATE_MARKETPLACE_SUBSCRIPTION_FAILURE,
        data: error
      });
      return Promise.reject(error);
    }
  };
};

export const payWithExistingCard = (variantId, cardId, cvv) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: PAY_WITH_EXISTING_CARD
      });
      const { user } = getState().auth;
      if (!user.companyId) return Promise.reject();
      const response = await axios.post(
        `${baseApiUrl}/variants/${variantId}/pay/card/${cardId}`,
        {
          cvv
        }
      );
      if (response.status === 200)
        dispatch({
          type: PAY_WITH_EXISTING_CARD_SUCCESS,
          data: response.data?.data
        });
      return response.data?.data;
    } catch (error) {
      dispatch({
        type: PAY_WITH_EXISTING_CARD_FAILURE,
        data: error
      });
      return Promise.reject(error);
    }
  };
};

export const payWithCard = (
  variantId,
  {
    alias = '',
    store = false,
    card: { cvv = '', expiryMonth = '', expiryYear = '', holder = '', pan = '' }
  }
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: PAY_WITH_CARD
      });
      const { user } = getState().auth;
      if (!user.companyId) return Promise.reject();

      const payload = {
        alias,
        store,
        card: { cvv, expiryMonth, expiryYear, holder, pan }
      };

      const response = await axios.post(`${baseApiUrl}/variants/${variantId}/pay/card`, payload);

      if (response.status === 200)
        dispatch({
          type: PAY_WITH_CARD_SUCCESS,
          data: response.data?.data
        });
      return response.data?.data;
    } catch (error) {
      dispatch({
        type: PAY_WITH_CARD_FAILURE,
        data: error
      });
    }
  };
};

export const getSubscriptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_MARKETPLACE_SUBSCRIPTIONS
      });
      const { user } = getState().auth;
      if (!user.companyId) return Promise.reject();
      const response = await axios.get(`${baseApiUrl}/subscriptions/`);
      if (response.status === 200)
        dispatch({
          type: GET_MARKETPLACE_SUBSCRIPTIONS_SUCCESS,
          data: response.data?.data
        });
      return response.data?.data;
    } catch (error) {
      dispatch({
        type: GET_MARKETPLACE_SUBSCRIPTIONS_FAILURE,
        data: error
      });
    }
  };
};

export const resetSubscription = () => (dispatch) =>
  dispatch({ type: RESET_MARKETPLACE_SUBSCRIPTION });
