import axios from 'axios';
import { DOCUMENT_TYPES } from 'utils';

export const getAllDocuments = (options = { type: '', status: '', page: 0, size: 10 }) => {
  return (dispatch) => {
    return axios.get(`/api/documents`, { params: options }).then((res) => {
      dispatch({
        type: 'SET_DOCUMENTS',
        data: res.data.data
      });
    });
  };
};

export const getCompanyDocuments = (
  companyId,
  options = { status: 'ACTIVE', page: 0, size: 10 }
) => {
  return (dispatch) => {
    if (!companyId) return Promise.reject();
    return axios
      .get(`/api/companies/${companyId}/documents`, {
        params: { type: DOCUMENT_TYPES.COMPANY_DOCUMENT, ...options }
      })
      .then((res) => {
        dispatch({
          type: 'SET_COMPANY_DOCUMENTS',
          data: {
            ...res.data.data,
            content: res.data.data.content.filter(
              (item) => item.type == DOCUMENT_TYPES.COMPANY_DOCUMENT
            )
          }
        });
      });
  };
};
export const getCompanyTaxDeclarationDocuments = (companyId, options = { page: 0, size: 10 }) => {
  return (dispatch) => {
    if (!companyId) return Promise.reject();
    return axios
      .get(`/api/companies/${companyId}/documents`, {
        params: { type: DOCUMENT_TYPES.COMPANY_TAX_DECLARATION_DOCUMENT, ...options }
      })
      .then((res) => {
        dispatch({
          type: 'SET_COMPANY_TAX_DECLARATION_DOCUMENTS',
          data: {
            ...res.data.data,
            content: res.data.data.content.filter(
              (item) => item.type == DOCUMENT_TYPES.COMPANY_TAX_DECLARATION_DOCUMENT
            )
          }
        });
      });
  };
};

export const approveCompanyTaxDeclarationDocument = ({ documentId, companyId }) => {
  return () => {
    if (!companyId) return Promise.reject();
    return axios.put(`/api/companies/${companyId}/tax-declarations/${documentId}/approve`);
  };
};

export const sendBackCompanyTaxDeclarationDocument = ({ documentId, description, companyId }) => {
  return () => {
    if (!companyId) return Promise.reject();
    return axios.put(`/api/companies/${companyId}/tax-declarations/${documentId}/send-back`, {
      description
    });
  };
};

export const completePaymentCompanyTaxDeclarationDocument = (
  payload = { paidDate: '', receiptPaymentFileId: '' },
  params = { documentId: '', companyId: '' }
) => {
  return () => {
    if (!params?.companyId) return Promise.reject();
    return axios.put(
      `/api/companies/${params.companyId}/tax-declarations/${params.documentId}/complete-payment`,
      payload
    );
  };
};
