import React, { useState } from 'react';
import { SideModal, Button, CustomInput, Label, SelectedAccountView } from 'components';
import { Prompt } from 'components/promptModal';
import useModal from 'utils/hooks/useModal';
import { ReactComponent as IconDelete } from 'assets/icons/trash.svg';
import { useDispatch } from 'react-redux';
import { useSmartOperationContext } from '../..';
import {
  deleteBillSubscription,
  getBillSubscriptions,
  updateBillSubscription
} from 'store/actions/bill';
import { useSelector } from 'react-redux';

export default function AutoBillEditSideModal({ onClose }) {
  const {
    selectedBill,
    setBillPaymentFormValues,
    setSelectedBill,
    setStep,
    toggleAutoBillEditSideModal
  } = useSmartOperationContext();
  const { bills } = useSelector((state) => state.bill);
  const [recordName, setRecordName] = useState(selectedBill?.recordName);
  const [isDeleteAutoBillRecordPromptActive, , toggleDeleteAutoBillRecordPrompt] = useModal();

  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(
      updateBillSubscription({
        subscriptonId: selectedBill?.id,
        recordName: recordName,
        autoPayment: selectedBill?.autoPayment,
        walletId: selectedBill?.walletId
      })
    ).then(() => {
      setBillPaymentFormValues((prevValues) => ({
        ...prevValues,
        billRecordName: recordName
      }));
      const updatedSubscription = { ...selectedBill, recordName };
      setSelectedBill(updatedSubscription);
      dispatch(getBillSubscriptions());
      onClose();
    });
  };

  const onDelete = () => toggleDeleteAutoBillRecordPrompt();

  const onDeleteApproved = () => {
    dispatch(deleteBillSubscription(selectedBill?.id)).then(() => {
      toggleDeleteAutoBillRecordPrompt();
      setStep(1);
      toggleAutoBillEditSideModal();
    });
  };

  const onChangeRecordName = (e) => {
    setRecordName(e.target.value);
  };
  return (
    <SideModal handleClose={onClose}>
      <Prompt
        isActive={isDeleteAutoBillRecordPromptActive}
        className="max-w-xs mx-auto"
        title={
          <div className="w-20 h-20 flex items-center justify-center rounded-full bg-red-100 mx-auto">
            <IconDelete className="fill-current w-10 h-10 text-red-600" />
          </div>
        }
        description={
          <p className="!font-medium !text-base !leading-6 !text-gray-900">
            Kayıtlı faturanızı silmek istediğinize emin misiniz?
          </p>
        }
        okText="Evet"
        cancelText="Vazgeç"
        okButtonColor="red"
        cancelButtonColor="primary"
        onOK={onDeleteApproved}
        onCancel={toggleDeleteAutoBillRecordPrompt}
      />
      <div className="flex flex-col justify-between h-full pb-10">
        <div>
          <p className="font-semibold text-[28px] leading-8 mb-8">Otomatik Fatura Düzenle</p>
          <div className="flex flex-col gap-y-4">
            <CustomInput
              label="Hesap İsmi"
              defaultValue={recordName}
              maxLength={32}
              onChange={onChangeRecordName}
            />
            <CustomInput label="Kurum Adı" defaultValue={selectedBill?.corpName} disabled />
            <CustomInput label="Abone No" defaultValue={selectedBill?.subscriberNo} disabled />
            <Label label="Ödeme Yapılacak Hesap">
              <SelectedAccountView
                name={bills?.wallet?.name}
                amount={bills?.wallet?.balance || 0}
                verticalView
                disabled
              />
            </Label>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <Button onClick={onSave}>Kaydet</Button>
          <Button color="red" onClick={onDelete}>
            Sil
          </Button>
        </div>
      </div>
    </SideModal>
  );
}
