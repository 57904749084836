import React from 'react';
import classNames from 'classnames';
import { Button, Card } from 'components';

export default function StepInformationCard({
  data = [{ icon: React.Component, title: '' }],
  actionButtonText,
  className,
  actionButtonClassname,
  onClickActionButton
}) {
  const containerClassnames = classNames('!p-8', className);
  const actionButtonClassnames = classNames('w-full', actionButtonClassname);

  return (
    <Card className={containerClassnames}>
      <div className="flex flex-col gap-y-4 mb-10">
        {data?.map((step, index) => (
          <div key={index}>
            <div className="flex gap-x-3 items-center">
              <div>{step?.icon}</div>
              <div>
                <p className="text-xs text-purple-500 font-medium">{index + 1}. Adım</p>
                <p className="text-sm font-semibold">{step?.title}</p>
              </div>
            </div>
            {index !== data?.length - 1 && (
              <div className="w-1 h-6 rounded-3xl bg-gray-100 mt-6 ml-[60px]"></div>
            )}
          </div>
        ))}
      </div>
      <Button onClick={onClickActionButton} className={actionButtonClassnames}>
        {actionButtonText}
      </Button>
    </Card>
  );
}
