import React, { useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Checkbox,
  CustomInput,
  Divider,
  InfoMessage,
  Label,
  Popup
} from 'components';
import PopoverSelect from 'components/popoverSelect';
import StepHeader from 'components/stepHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  checkHasBill,
  getBillCategories,
  getBillCorporations,
  getBillSubscriptions,
  saveBillSubscription
} from 'store/actions/bill';
import { BillContext } from '../..';

import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import useModal from 'utils/hooks/useModal';

import toast from 'react-hot-toast';
import SelectAccount from 'views/transactions/components/SelectAccount';
import { WALLET_TYPES } from 'utils/contants';
import { getWallets } from 'store/actions/accounts';
import { CURRENCIES } from 'views/other/items/enums';

export default function BillPayment({ isNewBillCreate }) {
  const { categories, corporations } = useSelector((state) => state.bill);

  const accounts = useSelector((state) => state.accounts).filter(
    (wallet) => wallet.currencyCode === CURRENCIES.TRY
  );

  const { billPaymentFormValues, setBillPaymentFormValues, nextStep, setSelectedPaymentAccount } =
    useContext(BillContext);

  const [isBillNotFoundPopupActive, , toggleBillNotFoundPopup] = useModal();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillCategories());
    dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
  }, []);

  useEffect(() => {
    if (corporations) {
      setBillPaymentFormValues((prevValues) => ({
        ...prevValues,
        corporation: {
          label: corporations[0]?.description,
          value: corporations[0]?.code,
          title: corporations[0]?.label
        }
      }));
    }
  }, [corporations]);

  const onChangeCategory = (category) => {
    if (!category) return;

    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      category,
      corporation: null
    }));

    dispatch(getBillCorporations(category?.value));
  };

  const onChangeCorporation = (corporation) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      corporation,
      subscriberNo: ''
    }));
  };

  const onChangeSubscriberNo = (e) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      subscriberNo: e.target.value
    }));
  };

  const onChangeAutoPayment = (e) => {
    const status = e.target.checked;

    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      autoPayment: status,
      shoudbeSaveBill: status,
      billRecordName: status ? prevValues.billRecordName : ''
      // wallet: status ? prevValues?.wallet : null
    }));
  };

  const onChangeSaveBill = (e) => {
    if (billPaymentFormValues?.shoudbeSaveBill && billPaymentFormValues?.autoPayment) return;
    const status = e.target.checked;
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      shoudbeSaveBill: status,
      billRecordName: status ? prevValues.billRecordName : ''
    }));
  };

  const onChangeBillRecordName = (e) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      billRecordName: e.target.value
    }));
  };

  const onSaveSubscription = () => {
    dispatch(
      saveBillSubscription({
        corpCode: billPaymentFormValues.corporation.value,
        recordName: billPaymentFormValues.billRecordName,
        subscriberNo: billPaymentFormValues.subscriberNo,
        autoPayment: billPaymentFormValues.autoPayment,
        walletId: billPaymentFormValues.autoPayment ? billPaymentFormValues.wallet?.id : null
      })
    ).then(() => nextStep());
  };

  const onCheckHasBills = () => {
    dispatch(
      checkHasBill({
        corpCode: billPaymentFormValues.corporation.value,
        subscriberNo: billPaymentFormValues.subscriberNo
      })
    )
      .then((data) => {
        if (data?.billExist) {
          nextStep();
        } else {
          toggleBillNotFoundPopup();
        }
        if (billPaymentFormValues?.shoudbeSaveBill) {
          dispatch(
            saveBillSubscription({
              corpCode: billPaymentFormValues.corporation.value,
              subscriberNo: billPaymentFormValues.subscriberNo,
              recordName: billPaymentFormValues.billRecordName,
              autoPayment: billPaymentFormValues.autoPayment,
              walletId: billPaymentFormValues.autoPayment
                ? billPaymentFormValues.wallet?.id
                : null
            })
          ).then(() => {
            dispatch(getBillSubscriptions());
          });
        }
      })
      .catch(() => {
        toast.error('Abone Numarası Bulunamadı');
      });
  };

  const onWalletSelected = (wallet) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      wallet
    }));
    setSelectedPaymentAccount(wallet);
  };

  let categoryOptions =
    [
      ...categories?.map((category) => {
        return {
          label: category?.description,
          value: category?.id,
          icon: category?.iconUrl
        };
      })
    ] || [];

  let corporationOptions =
    [
      ...corporations?.map((corporation) => {
        return {
          label: corporation?.description,
          value: corporation?.code,
          title: corporation?.label
        };
      })
    ] || [];

  const shouldbeDisabled = billPaymentFormValues.shoudbeSaveBill
    ? !billPaymentFormValues?.category ||
      !billPaymentFormValues?.corporation ||
      !billPaymentFormValues?.subscriberNo ||
      !billPaymentFormValues?.billRecordName ||
      billPaymentFormValues?.billRecordName?.length < 2
    : // !billPaymentFormValues?.wallet
      !billPaymentFormValues?.category ||
      !billPaymentFormValues?.corporation ||
      !billPaymentFormValues?.subscriberNo ||
      // !billPaymentFormValues?.wallet ||
      (isNewBillCreate &&
        (!billPaymentFormValues?.billRecordName ||
          billPaymentFormValues?.billRecordName?.length < 2));

  const subscriberNoMaxLength = corporations?.find(
    (corporation) => corporation.code === billPaymentFormValues.corporation?.value
  )?.maxLength;

  return (
    <div className="w-full h-full" id="BillPayment">
      {isBillNotFoundPopupActive && <BillNotFoundPopup onClose={toggleBillNotFoundPopup} />}
      <StepHeader
        title={isNewBillCreate ? 'Fatura İşlemleri' : 'Fatura Ödeme'}
        subtitle={isNewBillCreate ? 'Kayıtlı Fatura Oluştur' : 'Fatura Bilgileri'}
      />
      <Card className={isNewBillCreate ? 'flex flex-col gap-y-1' : 'flex flex-col gap-y-8'}>
        <div className="flex flex-col gap-y-8 mb-8">
          {isNewBillCreate && (
            <CustomInput label="Kayıt İsmi" maxLength={32} onChange={onChangeBillRecordName} />
          )}
          <div className="flex gap-x-4">
            <Label label="Kurum Tipi" className="w-full max-w-[216px]">
              <PopoverSelect
                hasBaseFormClass
                options={categoryOptions}
                panelClassName="!max-h-[190px]"
                onChange={onChangeCategory}
              />
            </Label>
            <Label label="Kurum Adı" className="w-full">
              <PopoverSelect
                hasBaseFormClass
                options={corporationOptions}
                selectedOption={billPaymentFormValues.corporation}
                panelClassName="!max-h-[190px]"
                isSearchable
                searchPlaceholder="Kurum Ara"
                onChange={onChangeCorporation}
              />
            </Label>
          </div>
          {billPaymentFormValues.corporation?.value && billPaymentFormValues.category?.value && (
            <CustomInput
              label={billPaymentFormValues.corporation?.title}
              onChange={onChangeSubscriberNo}
              value={billPaymentFormValues.subscriberNo}
              only="number"
              maxLength={subscriberNoMaxLength}
            />
          )}
        </div>
        {!isNewBillCreate && (
          <Label label="Ödeme Yapılacak Hesap" className="">
            <SelectAccount
              className={'!m-0'}
              accounts={accounts}
              selectedAccount={billPaymentFormValues?.wallet}
              setSelectedAccount={onWalletSelected}
            />
          </Label>
        )}
        {!isNewBillCreate && (
          <div className="flex flex-col gap-y-4">
            <Checkbox checked={billPaymentFormValues?.autoPayment} onChange={onChangeAutoPayment}>
              Otomatik ödeme olarak kaydet
            </Checkbox>
            <Checkbox
              checked={billPaymentFormValues?.shoudbeSaveBill}
              onChange={onChangeSaveBill}
            >
              Kayıtlı Faturalara ekle
            </Checkbox>
            {billPaymentFormValues?.shoudbeSaveBill && (
              <CustomInput label="Kayıt İsmi" maxLength={32} onChange={onChangeBillRecordName} />
            )}
          </div>
        )}
        <div>
          <Divider className="!border-b-[1px] mb-10" />
          {isNewBillCreate ? (
            <Button
              className="w-full pb-6"
              disabled={shouldbeDisabled}
              onClick={onSaveSubscription}
            >
              Kaydet
            </Button>
          ) : (
            <Button className="w-full pb-6" disabled={shouldbeDisabled} onClick={onCheckHasBills}>
              Sorgula
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
}

const BillNotFoundPopup = ({ onClose }) => {
  const { billPaymentFormValues } = useContext(BillContext);
  return (
    <Popup
      active
      childrenClassName="w-[327px] h-auto flex flex-col gap-y-6 items-center justify-between text-center"
      onClose={onClose}
    >
      <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100">
        <IconInfoCircle className="fill-current w-10 text-yellow-600" />
      </div>
      <div className="flex flex-col gap-y-3">
        <span className="text-lg font-semibold text-gray-900">Fatura Bulunamadı</span>
        <span className="text-base font-medium text-gray-500">
          {billPaymentFormValues.subscriberNo} No’lu aboneye ait ödenmemiş fatura bulunamadı.
        </span>
        {billPaymentFormValues?.autoPayment && billPaymentFormValues?.shoudbeSaveBill && (
          <InfoMessage className="p-0 !bg-white !border-none" textClassName="!text-sm">
            Aboneliğiniz kayıtlı işlemlere kaydedildi ve otomatik ödeme talimatınız alındı.
          </InfoMessage>
        )}
        {!billPaymentFormValues?.autoPayment && billPaymentFormValues?.shoudbeSaveBill && (
          <InfoMessage className="p-0 !bg-white !border-none" textClassName="!text-sm">
            Aboneliğiniz kayıtlı işlemlere kaydedildi.
          </InfoMessage>
        )}
      </div>
      <Button className="w-full" onClick={onClose}>
        Tamam
      </Button>
    </Popup>
  );
};
