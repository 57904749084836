import { marketPlaceTypes } from 'store/types';

const {
  GET_MARKETPLACE_ITEMS,
  GET_MARKETPLACE_VARIANTS,
  CREATE_MARKETPLACE_SUBSCRIPTION,
  CREATE_MARKETPLACE_SUBSCRIPTION_FAILURE,
  CREATE_MARKETPLACE_SUBSCRIPTION_SUCCESS,
  PAY_WITH_CARD,
  PAY_WITH_CARD_FAILURE,
  PAY_WITH_CARD_SUCCESS,
  RESET_MARKETPLACE_SUBSCRIPTION,
  GET_MARKETPLACE_SUBSCRIPTIONS,
  GET_MARKETPLACE_SUBSCRIPTIONS_FAILURE,
  GET_MARKETPLACE_SUBSCRIPTIONS_SUCCESS,
  PAY_WITH_EXISTING_CARD,
  PAY_WITH_EXISTING_CARD_FAILURE,
  PAY_WITH_EXISTING_CARD_SUCCESS
} = marketPlaceTypes;

const initialState = {
  items: [],
  variants: [],
  subscription: null,
  savedSubscription: null,
  subscriptions: []
};

export default function marketplaceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MARKETPLACE_ITEMS:
      return {
        ...state,
        items: action.data
      };
    case GET_MARKETPLACE_VARIANTS:
      return {
        ...state,
        variants: action.data
      };
    case CREATE_MARKETPLACE_SUBSCRIPTION:
    case CREATE_MARKETPLACE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.data
      };
    case CREATE_MARKETPLACE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscription: action.data
      };
    case PAY_WITH_CARD:
    case PAY_WITH_CARD_SUCCESS:
      return {
        ...state,
        payment: action.data
      };
    case PAY_WITH_CARD_FAILURE:
      return {
        ...state,
        payment: action.data
      };
    case PAY_WITH_EXISTING_CARD:
    case PAY_WITH_EXISTING_CARD_SUCCESS:
      return {
        ...state,
        payment: action.data
      };
    case PAY_WITH_EXISTING_CARD_FAILURE:
      return {
        ...state,
        payment: action.data
      };
    case GET_MARKETPLACE_SUBSCRIPTIONS:
    case GET_MARKETPLACE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.data
      };
    case GET_MARKETPLACE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        subscriptions: action.data
      };
    case RESET_MARKETPLACE_SUBSCRIPTION:
      return {
        ...state,
        subscription: null
      };
    default:
      return state;
  }
}
