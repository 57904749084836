import axios from 'axios';
import { resetConfirmedTransaction } from '../transactions';

export const createWithdrawal = (
  walletId,
  {
    amount,
    receiverIban,
    receiverName,
    receiverIdentityNumber //sender identity number (TCKN / SSN)
  }
) => {
  if (walletId)
    return async (dispatch) => {
      await dispatch(resetConfirmedTransaction());
      return axios.post(`/api/wallets/${walletId}/withdrawals`, {
        amount,
        receiverIban: receiverIban.replace(/\s/g, ''),
        receiverName,
        receiverIdentityNumber
      });
    };
};
