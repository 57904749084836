const initialState = null;

const qrCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QR_CODES':
      return {
        ...state,
        ...action.data
      };
    default:
      return {
        ...state
      };
  }
};

export default qrCodesReducer;
