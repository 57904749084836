import { CustomInput } from 'components';

const PersonalInformation = ({ watch, setValue, errors, clearErrors, register }) => (
  <div className="flex flex-col gap-y-4">
    <span className="text-[18px] text-gray-600 font-semibold">Kişisel Bilgiler</span>
    <CustomInput
      label="İsim"
      onChange={(e) => {
        setValue('firstName', e.target.value);
        clearErrors('firstName');
      }}
      name="firstName"
      only="text"
      maxLength={140}
      value={watch('firstName')}
      error={errors?.firstName?.message}
      register={register}
      data-testid="input_first_name"
    />
    <CustomInput
      label="Soyisim"
      onChange={(e) => {
        setValue('lastName', e.target.value);
        clearErrors('lastName');
      }}
      name="lastName"
      value={watch('lastName')}
      only="text"
      maxLength={140}
      error={errors?.lastName?.message}
      register={register}
      data-testid="input_last_name"
    />
  </div>
);

export default PersonalInformation;
