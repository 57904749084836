import { useEffect } from 'react';

import DoubleCheck from 'assets/illustrations/card/double-check.png';

import FullModal from 'views/company-options/FullModal';
import { useRouter } from 'utils/hooks/useRouter';
import useDengage from 'utils/hooks/useDengage';

import { CustomButton } from 'components';

import useGTM from 'utils/hooks/useGTM';

export default function SuccessBottomModal({ handleClose }) {
  const { history, push } = useRouter();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'start_company_activity_completed' });
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'start_a_company',
      start_date: new Date().toJSON().slice(0, 10).replace(/-/g, '-')
    });
  }, []);

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        push(location.pathname + location.search);
      }
    });
  }, []);
  return (
    <FullModal
      className="bg-green-500 flex flex-col items-center justify-center"
      handleClose={handleClose}
      data-testid="modal_start_company_success"
    >
      <div className="w-[385px] flex flex-col text-center items-center gap-y-6">
        <img src={DoubleCheck} alt="double check" width={100} />
        <span className="text-[28px] font-medium text-white break-normal leading-8">
          Şirket Kurma Talebiniz Alındı
        </span>
        <CustomButton
          color="success"
          className="w-full"
          onClick={handleClose}
          data-testid="button_start_company_success_close"
        >
          Devam
        </CustomButton>
      </div>
    </FullModal>
  );
}
