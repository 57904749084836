const initialState = [];

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return [...action.data];
    default:
      return [...state];
  }
};

export default accountReducer;
