import { CustomButton } from 'components';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';

const Tabs = ({ tabs, defaultTab }) => {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  defaultTab = defaultTab || 0;

  const [tab, setTab] = useState(defaultTab);

  useEffect(() => {
    if (tab === 0) {
      publishEvent(eventTypes.pageView, { page_type: 'company_kyc_sms' });
      sendGTMEvent({
        event: 'catEvent',
        eventName: 'kyc_tab_click',
        tab: 'sms'
      });
    } else if (tab === 1) {
      publishEvent(eventTypes.pageView, { page_type: 'company_kyc_qr' });
      sendGTMEvent({
        event: 'catEvent',
        eventName: 'kyc_tab_click',
        tab: 'qr'
      });
    }
  }, [tab]);

  return (
    <div className="h-auto w-11/12 mx-auto" data-testid="section-kyc">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full flex items-center border-b border-solid border-gray-200 text-black text-sm">
          {tabs.map((t, i) => {
            const classNames =
              tab === i
                ? 'border-b-2 border-solid border-green-500 text-green-500'
                : 'text-gray-500';
            return (
              <div
                key={i}
                className={`${classNames} w-min min-w-max font-semibold pb-3 pt-5 mr-6 h-auto flex items-center justify-center cursor-pointer select-none`}
                onClick={() => !t.disabled && setTab(i)}
              >
                {t.title}
              </div>
            );
          })}
        </div>
        <div className="w-full mt-4 p-6">{tabs[tab].page}</div>
      </div>
    </div>
  );
};

const KYCSMS = () => {
  const { sendGTMEvent } = useGTM();

  const sendKYCLink = () => {
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'kyc_send_link'
    });
  };

  return (
    <div className="flex flex-col mt-4">
      <span className="text-3xl font-semibold text-gray-900">Kimliğinizi doğrulayın</span>
      <span className="text-xs mt-6 text-gray-400">Kimlik Doğrulama</span>
      <span className="mt-2 text-gray-700">SMS ile Link Al</span>
      <span className="mt-6 text-sm text-gray-700">SMS ile kimliğinizi doğrulamak için;</span>
      <div className="flex mt-4 gap-x-2 items-center">
        <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
          1
        </span>
        <span className="text-sm">Aşağıdaki Link Gönder seçeneğine tıklayın</span>
      </div>
      <div className="flex mt-4 gap-x-2 items-center">
        <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
          2
        </span>
        <span className="text-sm">Linke tıklayarak Norma uygulamasını indirin</span>
      </div>
      <div className="flex mt-4 gap-x-2 items-center">
        <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">
          3
        </span>
        <span className="text-sm">Kimlik fotoğrafınızı arkalı ve önlü olarak çekin</span>
      </div>
      <CustomButton
        color="success"
        className="mt-6"
        onClick={sendKYCLink}
        data-testid="button_send_kyc_sms"
      >
        Link Gönder
      </CustomButton>
    </div>
  );
};

const KYCQR = () => (
  <div className="flex flex-col mt-4 space-y-4" data-testid="section_kyc_qr">
    <div className="w-44 h-44 bg-gray-500">
      <img src="https://app-qr.norma.co/norma_qr.png" alt="qr-code" />
    </div>
    <span className="text-xs mt-6 text-gray-400">Kimlik Doğrulama</span>
    <span className="mt-2 text-gray-900 font-semibold text-xl">Karekod ile Link Al</span>
    <span className="mt-6 text-sm text-gray-700">Karekod ile kimliğinizi doğrulamak için;</span>
    <div className="flex space-x-2 items-center">
      <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">1</span>
      <span className="text-sm">Telefonunuzun kamerasını açın</span>
    </div>
    <div className="flex space-x-2 items-center">
      <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">2</span>
      <span className="text-sm">Ekranda gördüğünüz karekodu almak için bu ekrana doğrultun</span>
    </div>
    <div className="flex space-x-2 items-center">
      <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">3</span>
      <span className="text-sm">Linke tıklayarak Norma uygulamasını indirin</span>
    </div>
    <div className="flex space-x-2 items-center">
      <span className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center">4</span>
      <span className="text-sm">Kimlik fotoğrafınızı arkalı ve önlü olarak çekin</span>
    </div>
  </div>
);

const KYC = () => {
  const history = useHistory();

  const { kycCompleted } = useSelector((state) => state.auth);

  useEffect(() => {
    if (kycCompleted) history.push('/');
  }, [kycCompleted]);

  const TABS = [
    {
      title: 'SMS',
      page: <KYCSMS />,
      disabled: true
    },
    {
      title: 'Karekod',
      page: <KYCQR />,
      disabled: false
    }
  ];

  return (
    <div className="w-full h-screen flex justify-between" data-testid="kyc-page">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-[400px]">
          <Tabs tabs={TABS} defaultTab={1} />
        </div>
      </div>
    </div>
  );
};

export default KYC;
