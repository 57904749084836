import { useState, useEffect } from 'react';
import SideModal from 'components/SideModal';
import { useSelector } from 'react-redux';

import { Checkbox, CustomButton, DatePicker, CategoryLabel } from 'components';
import useDengage from 'utils/hooks/useDengage';

// Illustrations
import { ReactComponent as StartRouteIcon } from 'assets/illustrations/filters/start-route.svg';
import { ReactComponent as EndRouteIcon } from 'assets/illustrations/filters/end-route.svg';
import { WALLET_TYPES } from 'utils/contants';
import { getFormattedDateForPayload } from 'utils';

const defaultFilters = {
  'expense-type': null,
  'category-id': '',
  direction: null,
  type: null,
  date: null
};

const FiltersModal = ({ toggleFiltersModal, handleFilters, form, selectedWalletType }) => {
  const [startDate, setStartDate] = useState(form.getValues()?.date?.startDate || new Date());
  const [endDate, setEndDate] = useState(form.getValues()?.date?.endDate || new Date());
  const [selectedCategory, setSelectedCategory] = useState(form.getValues()['category-id'] || '');
  const { publishEvent, eventTypes } = useDengage();

  const [filters, setFilters] = useState(form.getValues());

  const filterCount = Object.values(filters).filter((el) => el && el?.toString()).length;

  const { categories } = useSelector((state) => state.categories);

  function handleFilter(filterName, val) {
    setFilters({
      ...filters,
      [filterName]: val
    });
  }

  function onCategorySelected(categoryId) {
    if (categoryId === filters['category-id']) {
      setSelectedCategory(null);
      setFilters((prevState) => ({
        ...prevState,
        'category-id': ''
      }));
      return;
    }

    setSelectedCategory(categoryId);
    setFilters((prevState) => ({
      ...prevState,
      'category-id': categoryId
    }));
  }

  function handleDirectionFilter(val, _direction) {
    if (!val) {
      handleFilter('direction', null);
    } else {
      handleFilter('direction', _direction);
    }
  }

  const onStartDateChanged = (date) => {
    if (date) date.setUTCHours(0, 0, 0, 0);
    setStartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      date: {
        endDate: prevState.date?.endDate || new Date(),
        startDate: date
      }
    }));
  };

  const onEndDateChanged = (date) => {
    if (date) date.setUTCHours(0, 0, 0, 0);

    setEndDate(date);
    setFilters((prevState) => ({
      ...prevState,
      date: { startDate: prevState.date?.startDate || new Date(), endDate: date }
    }));
  };

  function resetFilter() {
    setFilters(defaultFilters);
    setStartDate(new Date());
    setEndDate(new Date());
    setSelectedCategory('');
    handleFilters({ ...defaultFilters });
    toggleFiltersModal();
  }

  function onSubmit() {
    if (filterCount === 0) return;

    let payload = { ...filters };

    if (filters?.date) {
      payload = {
        ...payload,
        date: {
          startDate: getFormattedDateForPayload(filters.date?.startDate),
          endDate: getFormattedDateForPayload(filters.date?.endDate)
        }
      };
    }

    handleFilters(payload);

    toggleFiltersModal();
  }

  // function handleExpenseTypeFilter(val, _expenseType) {
  //   if (!val) {
  //     handleFilter('expense-type', null);
  //   } else {
  //     handleFilter('expense-type', _expenseType);
  //   }
  // }

  // function handleTypeFilter(val, _type) {
  //   if (!val) {
  //     handleFilter('type', null);
  //   } else {
  //     handleFilter('type', _type);
  //   }
  // }

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_filter' });
  }, []);

  return (
    <SideModal title="Filtrele" handleClose={toggleFiltersModal}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="h-full"
        data-testid="section_filters_modal"
      >
        <div className="h-full flex flex-col justify-between pb-7">
          <div>
            <div>
              <span
                className="text-purple-500 text-sm font-semibold"
                data-testid="label_filters_modal_title"
              >
                Tarih Aralığı
              </span>
              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <StartRouteIcon className="w-8" />
                  <span className="ml-4" data-testid="label_filters_modal_start_date">
                    Başlangıç
                  </span>
                </div>
                {/* <span className="font-semibold">13 / 11 / 2021</span> */}
                <div className="w-[50px]]">
                  <DatePicker
                    className="font-semibold"
                    selected={startDate}
                    onChange={onStartDateChanged}
                    data-testid="input_filters_modal_start_date"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <EndRouteIcon className="w-8" />
                  <span className="ml-4" data-testid="label_filters_modal_end_date">
                    Bitiş
                  </span>
                </div>
                <div className="w-[50px]]">
                  <DatePicker
                    className="font-semibold"
                    selected={endDate}
                    onChange={onEndDateChanged}
                    data-testid="input_filters_modal_end_date"
                    // onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <span
                className="text-purple-500 text-sm font-semibold"
                data-testid="label_filters_modal_title"
              >
                İşlem Tipi
              </span>
              <div className="flex space-x-10 mt-4">
                <Checkbox
                  checked={filters?.direction === 'IN'}
                  onChange={(e) => handleDirectionFilter(e.target.checked, 'IN')}
                  data-testid="input_filters_modal_direction_in"
                >
                  {selectedWalletType === WALLET_TYPES.EMONEY.value
                    ? 'Gelen Ödeme'
                    : 'Para Girişi'}
                </Checkbox>
                <Checkbox
                  checked={filters?.direction === 'OUT'}
                  onChange={(e) => handleDirectionFilter(e.target.checked, 'OUT')}
                  data-testid="input_filters_modal_direction_out"
                >
                  {selectedWalletType === WALLET_TYPES.EMONEY.value
                    ? 'Giden Ödeme'
                    : 'Para Çıkışı'}
                </Checkbox>
              </div>
            </div>
            {selectedWalletType === WALLET_TYPES.EMONEY.value && (
              <div className="mt-6">
                <span className="text-purple-500 text-sm font-semibold">Kategoriler</span>
                <div className="flex flex-wrap mt-4">
                  {categories.map((category, index) => (
                    <span key={index} className="m-1">
                      <CategoryLabel
                        data={category}
                        selected={selectedCategory}
                        onChange={onCategorySelected}
                        data-testid="label_filters_modal_category"
                      />
                    </span>
                  ))}
                </div>
              </div>
            )}
            {/**
             * Transfer Type will not be available for now, but it could be in the future
             */}
            {/* <div className="mt-6">
              <span className="text-purple-500 text-sm font-semibold">Transfer Tipi</span>
              <div className="flex flex-col mt-4 gap-y-2">
                <Checkbox
                  checked={filters?.type === 'TRANSFER_FEE'}
                  onChange={(e) => handleTypeFilter(e.target.checked, 'TRANSFER_FEE')}
                >
                  Kendi Hesabına
                </Checkbox>
                <Checkbox
                  checked={filters?.type === 'PEER_TRANSFER'}
                  onChange={(e) => handleTypeFilter(e.target.checked, 'PEER_TRANSFER')}
                >
                  Norma Numarası
                </Checkbox>
                <Checkbox
                  checked={filters?.type === 'ACCOUNT_TRANSFER'}
                  onChange={(e) => handleTypeFilter(e.target.checked, 'ACCOUNT_TRANSFER')}
                >
                  EFT
                </Checkbox>
                <Checkbox
                  checked={filters?.type === 'DEPOSIT'}
                  onChange={(e) => handleTypeFilter(e.target.checked, 'DEPOSIT')}
                >
                  Deposit
                </Checkbox>
              </div>
            </div> */}
            {/**
             * Harcama Tipi will not be available for now, but it could be in the future
             *
             */}
            {/* <div className="mt-6">
              <span className="text-purple-500 text-sm font-semibold">Harcama Tipi</span>
              <div className="flex space-x-10 mt-4">
                <Checkbox
                  checked={filters['expense-type'] === 'PERSONAL'}
                  onChange={(e) => handleExpenseTypeFilter(e.target.checked, 'PERSONAL')}
                >
                  Bireysel
                </Checkbox>
                <Checkbox
                  checked={filters['expense-type'] === 'CORPORATE'}
                  onChange={(e) => handleExpenseTypeFilter(e.target.checked, 'CORPORATE')}
                >
                  Şirket
                </Checkbox>
              </div>
            </div> */}
          </div>
          <div className="flex flex-col gap-y-2 mt-4">
            <CustomButton
              className="disabled:hover:cursor-not-allowed"
              color="success"
              type="submit"
              disabled={filterCount === 0}
              data-testid="button_filters_modal_apply"
            >
              Uygula ({filterCount})
            </CustomButton>
            <CustomButton
              onClick={resetFilter}
              disabled={filterCount === 0}
              data-testid="button_filters_modal_reset"
            >
              Temizle
            </CustomButton>
          </div>
        </div>
      </form>
    </SideModal>
  );
};

export default FiltersModal;
