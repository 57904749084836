import React, { useState } from 'react';

import PopoverSelect from 'components/popoverSelect';
import { Checkbox, DatePicker, Label } from 'components';
import { BUSINESS_TYPES, DEBT_STATUS } from 'utils';
import AmountInput from 'components/AmountInput';
import { useEffect } from 'react';
import BusinessTypeItem from 'components/popoverSelect/businessTypeItem';

export default function CurrentAccountInformation({
  setValue,
  watch,
  hasOpeningBalance,
  setHasOpeningBalance
  // errors,
  // clearErrors
}) {
  const [initialBalanceDate, setInitialBalanceDate] = useState(new Date());

  const businessTypeOptions = Object.values(BUSINESS_TYPES).map((businessType) => ({
    label: businessType.label,
    value: businessType.value
  }));

  const debtStatusOptions = Object.values(DEBT_STATUS).map((debtStatus) => ({
    label: debtStatus.label,
    value: debtStatus.value
  }));

  useEffect(() => {
    setValue('initialBalanceDate', initialBalanceDate);
  }, [initialBalanceDate]);

  const onChangeInitialBalance = (balance) => {
    setValue('initialBalance', balance);
    if (balance === 0) setValue('debtStatus', DEBT_STATUS.NONE);
  };
  const onChangeDebtStatus = (status) => {
    setValue('debtStatus', status);
    if (status.value === DEBT_STATUS.NONE.value) setValue('initialBalance', 0);
  };

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-[18px] text-gray-600 font-semibold">Cari Detaylar</p>
      <Label label="Cari Tipi">
        <PopoverSelect
          className="w-full"
          labelClassName="w-full"
          panelClassName="!w-full !p-3 !max-h-56 shadow-norma"
          customItemElement={BusinessTypeItem}
          hasBaseFormClass
          options={businessTypeOptions.filter((bt) => bt.value)}
          selectedOption={watch('businessType')}
          defaultValue={businessTypeOptions[0]}
          onChange={(c) => setValue('businessType', c.value)}
        />
      </Label>
      <Checkbox
        checked={hasOpeningBalance}
        onChange={(e) => setHasOpeningBalance(e.target.checked)}
      >
        Müşteri / Tedarikçi açılış bakiyesi var.
      </Checkbox>
      {hasOpeningBalance && (
        <>
          <Label label="Borç/Alacak Durumu">
            <PopoverSelect
              className="w-full"
              labelClassName="w-full"
              panelClassName="!w-full"
              hasBaseFormClass
              options={debtStatusOptions}
              defaultValue={debtStatusOptions[0]}
              selectedOption={watch('debtStatus')}
              onChange={onChangeDebtStatus}
              disabled={watch('initialBalance') <= 0}
            />
          </Label>
          <AmountInput
            label="Açılış Bakiyesi"
            value={watch('initialBalance')}
            onValueChange={onChangeInitialBalance}
          />
          <div>
            <DatePicker
              label="Bakiye Tarihi"
              className="bg-transparent py-3 w-full focus:outline-none text-black"
              placeholder="Tarih seçin"
              isFormInput
              selected={initialBalanceDate}
              maxDate={new Date()}
              onChange={setInitialBalanceDate}
            />
            <span className="text-xs font-medium text-gray-400">
              Vade tarihi bu tarihten öncesine girilen satış ve gider kayıtları bakiyeyi
              etkileyemeyecektir.
            </span>
          </div>
        </>
      )}
    </div>
  );
}
