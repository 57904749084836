import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { CustomButton, CodeInput, PhoneInput } from 'components';
import { forgotPassword, forgotPasswordWMfaToken } from 'store/actions/auth';
import errorCodes from 'utils/errorCodes';
import useKeyPress from 'utils/hooks/useKeyPress';
import useGTM from 'utils/hooks/useGTM';
import useCountdown from 'utils/hooks/useCountdown';
import { OTP_TIMEOUT, timeFormat } from 'utils';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';

import Logo from 'assets/icons/logo.svg';

const StepHeader = ({ title, subtitle }) => (
  <div className="flex flex-col my-4" data-testid="section_forgot_header">
    <img src={Logo} alt="logo" width="35" />
    <span className="text-gray-400 font-semibold mt-6">{title}</span>
    <span className="text-xl text-gray-900 font-semibold mt-3">{subtitle}</span>
  </div>
);

const Step0 = ({ watch, nextStep, setValue, setError, errors, clearErrors }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleForgotPassword() {
    dispatch(forgotPassword({ phoneNumber: watch('phone') }))
      .then((res) => {
        if (!res) return;
        setValue('mfaToken', res.data.data.mfaToken);
        nextStep();
      })
      .catch((errors) => {
        errors.forEach((err) => {
          if (err.code === errorCodes.invalidPhoneNumber) {
            setError('phone', {
              type: 'warning',
              message: err.message
            });
          }
          if (err.code === errorCodes.otpNotFound) {
            setError('phone', {
              type: 'not-found',
              message: err.message
            });
          }
        });
      });
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    handleForgotPassword();
  });

  useEffect(() => {
    clearErrors('phone');
  }, [watch('phone')]);

  const isCorrect = watch('phone').length === 10;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Şifremi Unuttum"
        subtitle="Telefon numaranızı girin"
        data-testid="label_forgot_header"
      />
      <PhoneInput
        onChange={(_, phone) => setValue('phone', phone)}
        error={errors?.phone?.message}
        data-testid="input_forgot_phone"
        autofocus
      />
      <span className="text-xs text-gray-400 font-medium mt-2">
        Yeni şifre belirlemeniz için telefon numaranıza sıfırlama kodu göndereceğiz
      </span>
      <CustomButton
        color="success"
        className="mt-6"
        disabled={!isCorrect}
        onClick={handleForgotPassword}
        data-testid="button_forgot_password_next"
      >
        Devam
      </CustomButton>
      {errors?.phone?.type === 'not-found' && (
        <CustomButton
          color="primary"
          className="mt-6"
          onClick={() => history.push('/signup')}
          data-testid="button_forgot_link_signup"
        >
          Kayıt Ol
        </CustomButton>
      )}
    </div>
  );
};

const Step1 = ({ setValue, setError, errors, nextStep, watch, clearErrors }) => {
  const dispatch = useDispatch();
  const { sendGTMEvent } = useGTM();
  const [timeLeft, restartCounting, isCounting] = useCountdown(OTP_TIMEOUT);

  function handleForgotPasswordWMfaToken() {
    const payload = {
      phoneNumber: watch('phone'),
      mfaToken: watch('mfaToken'),
      otpToken: watch('SMSCode')
    };
    dispatch(forgotPasswordWMfaToken(payload))
      .then((res) => {
        setValue('mfaToken', res.data.data.mfaToken);
        nextStep();
      })
      .catch((errors) => {
        errors.forEach((err) => {
          if (err.code === errorCodes.invalidOtpToken) {
            setError('otpToken', {
              type: 'invalid',
              message: err.message
            });
          }
        });
      });

    sendGTMEvent({
      event: 'catEvent',
      eventName: 'phone_verification'
    });
  }

  function sendCodeAgain() {
    dispatch(forgotPassword({ phoneNumber: watch('phone') })).then(() => {
      restartCounting();
    });
  }

  useEffect(() => {
    clearErrors('otpToken');
  }, [watch('SMSCode')]);

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    handleForgotPasswordWMfaToken();
  });

  const phoneNumber = `+90 ${watch('phone').slice(0, 3)} ${watch('phone').slice(3, 6)} ${watch(
    'phone'
  ).slice(6, 8)} ${watch('phone').slice(8, 10)}`;

  const isCorrect = watch('SMSCode').length === 4;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Şifremi Unuttum"
        subtitle={`${phoneNumber} numaranıza gelen 4 haneli kodu girin`}
        data-testid="label_forgot_header"
      />
      <CodeInput
        length={4}
        onChange={(val) => setValue('SMSCode', val)}
        error={errors?.otpToken?.message}
        data-testid="input_forgot_otp"
        autofocus
      />
      <span className="text-sm mt-4">SMS ulaşmadı mı?</span>
      {isCounting ? (
        <span className="text-sm text-green-400 select-none cursor-default">
          {timeFormat(timeLeft)}
        </span>
      ) : (
        <span
          className="text-sm text-green-400 select-none cursor-pointer"
          onClick={sendCodeAgain}
          data-testid="button_forgot_send_again"
        >
          Tekrar Gönder.
        </span>
      )}
      <CustomButton
        color="success"
        className="mt-8"
        onClick={handleForgotPasswordWMfaToken}
        disabled={!isCorrect}
        data-testid="button_forgot_password_handle_token"
      >
        Devam
      </CustomButton>
    </div>
  );
};

const Step2 = ({ length, nextStep, watch, setValue, errors, setError, clearErrors }) => {
  function handleContinue() {
    const hasSequantialError = PASSWORD_SEQUENTIAL_REGEX.test(watch('password'));
    const hasRepeatingError = PASSWORD_REPEATING_REGEX.test(watch('password'));

    if (hasSequantialError) {
      toast.error(
        <div className="flex flex-col" data-testid="section_error_password">
          <span className="text-sm font-semibold text-red-100">Hatalı Şifre</span>
          <span className="text-sm font-medium text-red-100">
            Şifreniz ardışık rakamlardan oluşamaz
          </span>
        </div>
      );
      setError('password', {
        type: 'warning',
        message: 'Şifreniz ardışık rakamlardan oluşamaz'
      });
      return;
    }
    if (hasRepeatingError) {
      toast.error(
        <div className="flex flex-col">
          <span className="text-sm font-semibold text-red-100">Hatalı Şifre</span>
          <span className="text-sm font-medium text-red-100">
            Şifreniz tekrarlayan rakamlardan oluşamaz
          </span>
        </div>
      );
      setError('password', {
        type: 'warning',
        message: 'Şifreniz tekrarlayan rakamlardan oluşamaz'
      });
      return;
    }

    clearErrors('password');
    nextStep();
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    handleContinue();
  });

  const isCorrect = watch('password').length === length;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Şifremi Unuttum"
        subtitle={`${length} haneli giriş şifrenizi belirleyin`}
        data-testid="label_forgot_header_set_password"
      />
      <CodeInput
        length={length}
        onChange={(password) => {
          clearErrors('password');
          setValue('password', password);
        }}
        invisible={true}
        autofocus
        error={errors?.password}
        data-testid="input_forgot_password"
      />
      <CustomButton
        color="success"
        className="mt-10"
        onClick={handleContinue}
        disabled={!isCorrect}
        data-testid="button_forgot_password_handle_password"
      >
        Devam
      </CustomButton>
    </div>
  );
};

const Step3 = ({ length, setValue, watch }) => {
  const submitRef = useRef();

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    submitRef.current.click();
  });

  const isCorrect = watch('againPassword').length === length;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Şifremi Unuttum"
        subtitle={`${length} haneli giriş şifreinizi tekrar girin`}
        data-testid="label_forgot_header_set_password_again"
      />
      <CodeInput
        length={length}
        invisible={true}
        onChange={(againPassword) => setValue('againPassword', againPassword)}
        data-testid="input_forgot_password_again"
        autofocus
      />
      <CustomButton color="success" className="mt-10" type="submit" disabled={!isCorrect}>
        Devam
      </CustomButton>
      <button ref={submitRef} className="hidden" />
    </div>
  );
};

export { Step0, Step1, Step2, Step3 };
