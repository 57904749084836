import React, { useEffect } from 'react';

import { BottomModal, Button } from 'components';
import ReceiptPdf from 'views/invoicing/components/eDocumentPreview/ReceiptPdf';
import { downloadFileByUrl } from 'utils';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as IconShare } from 'assets/icons/share.svg';

export default function ViewDocumentModal({
  selectedDocument,
  ref,
  isActive = false,
  handleClose
}) {
  const { publishEvent, eventTypes } = useDengage();

  const downloadReceiptByFileUrl = () => {
    if (selectedDocument?.file?.url)
      downloadFileByUrl(selectedDocument?.file.url, selectedDocument?.file.name);
  };

  useEffect(() => {
    publishEvent(eventTypes.pageView, {
      page_type: 'profile_and_settings_company_documents_preview'
    });
  }, []);

  return (
    <BottomModal ref={ref} isActive={isActive} handleClose={handleClose}>
      <div
        className="mx-auto w-auto h-full flex flex-col items-center justify-center"
        data-testid="section_documents_company_modal"
      >
        <div className="w-full flex justify-between items-center gap-x-3">
          <h4>{selectedDocument?.file.name}</h4>
          <div className="flex items-center gap-x-3">
            <Button
              className="w-full"
              variant="circle"
              color="gray"
              icon={<IconShare className="fill-current stroke-0" />}
              onClick={downloadReceiptByFileUrl}
            />
          </div>
        </div>
        <div className="h-auto w-auto overflow-y-scroll rounded-xl overflow-hidden my-5">
          {selectedDocument?.file?.url && <ReceiptPdf pdfUrl={selectedDocument?.file?.url} />}
        </div>
        <div className="flex gap-x-3 items-center w-full">
          <Button className="w-full" color="primary" onClick={downloadReceiptByFileUrl}>
            İndir
          </Button>
        </div>
      </div>
    </BottomModal>
  );
}
