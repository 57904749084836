import React, { useEffect } from 'react';
import LoadingOverlay from 'components/LoadingOverlay';
import routes from 'router';
import { useRouter } from 'utils/hooks/useRouter';
import { useSelector } from 'react-redux';
import { USER_COMPANY_STATUS, KYC_STATUS } from 'utils';
/* 
 --------|Access Router Rules|--------------
   status: incomplete ise -> welcome
   status: pending_review ise -> welcome
   status: pending_approval ise -> welcome
   status: approved ise -> welcome
   company status: active -> app home page
   status: created ise -> kyc
   status: pending_upload ise -> kyc
 */

export default function AccessRouter() {
  const { pathname, push } = useRouter();
  const auth = useSelector((state) => state.auth);
  const { companyStatus, user, token } = auth;

  const {
    home,
    companyOptions,
    addCompany,
    startCompany,
    onboardingCompleteWelcome,
    agreementAddress,
    kyc
  } = routes;

  const isIncludes = [addCompany.path, startCompany.path].includes(pathname);

  useEffect(() => {
    const hasToken = localStorage.getItem('token') || token;
    const kycStatus = user?.company?.kycStatus || user?.kycStatus;
    if ((kycStatus || companyStatus) && hasToken) {
      if (companyStatus === USER_COMPANY_STATUS.ACTIVE) return push(home.path);
      if (
        companyStatus === USER_COMPANY_STATUS.PASSIVE &&
        !isIncludes &&
        pathname !== companyOptions.path
      )
        return push(companyOptions.path);

      if (
        companyStatus === USER_COMPANY_STATUS.WAITING_APPROVAL &&
        !user?.hasCourierOrder &&
        pathname !== agreementAddress.path
      ) {
        return push(agreementAddress.path);
      }
      if (
        companyStatus === USER_COMPANY_STATUS.WAITING_APPROVAL &&
        user?.hasCourierOrder &&
        pathname !== kyc.path
      ) {
        switch (kycStatus) {
          case KYC_STATUS.INCOMPLETE:
          case KYC_STATUS.PENDING_REVIEW:
          case KYC_STATUS.PENDING_APPROVAL:
          case KYC_STATUS.APPROVED:
            return push(onboardingCompleteWelcome.path);
          case KYC_STATUS.CREATED:
          case KYC_STATUS.PENDING_UPLOAD:
            return push(onboardingCompleteWelcome.path);
          default:
            return push(onboardingCompleteWelcome.path);
        }
      }
    }
  }, [auth]);

  return <LoadingOverlay />;
}
