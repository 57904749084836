import { useState } from 'react';

const Switch = ({ className, onChange, defaultValue }) => {
  defaultValue = defaultValue || false;
  const [isActive, setIsActive] = useState(defaultValue);
  function toggleActive() {
    setIsActive(!isActive);
    if (onChange) onChange(!isActive);
  }

  return (
    <div
      className={`${className} ${
        isActive ? 'justify-end' : 'justify-start'
      } flex flex-col h-auto justify-center`}
    >
      <span
        className={`${
          isActive ? 'bg-green-500 justify-end' : 'bg-gray-200 shadow justify-start'
        } rounded-full flex items-center cursor-pointer w-14 p-1 transition-all delay-75`}
        onClick={toggleActive}
      >
        <span className="rounded-full border w-6 h-6 shadow-inner bg-white"></span>
      </span>
    </div>
  );
};

export default Switch;
