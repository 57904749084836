import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';

import {
  AddCategoryModal,
  BottomModal,
  Button,
  Card,
  CategoryBadge,
  CustomButton
} from 'components';
import { useEffect, useState } from 'react';
import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { COMPANY_TYPES, currencyFormat, dateFormat, TRANSACTION_TYPES } from 'utils';
import ReceiptModal from './ReceiptModal';
import { getCategories } from 'store/actions/categories';
import useDengage from 'utils/hooks/useDengage';
import { TRANSACTION_DIRECTION } from 'utils/contants';
import {
  getTransaction,
  updateTransactionCategory,
  updateTransactionExpenseType,
  updateTransactionNote
} from 'store/actions/transactions';

const CURRENCY = {
  TRY: 'TL',
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP'
};

const TransactionDetailModal = ({ toggleTransactionDetailModal, transactionId, accountId }) => {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categories);
  const { transactionGroupTypes } = useSelector((state) => state.transactions);
  const [expenseType, setExpenseType] = useState('');
  const [transferDetail, setTransferDetail] = useState(null);
  const [category, setCategory] = useState(null);
  const [note, setNote] = useState(null);

  const [receiptModal, , toggleReceiptModal] = useModal();
  const [isAddCategoryModalActive, , toggleCategoryAddModal] = useModal();

  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_transaction_detail' });
  }, []);

  useEffect(() => {
    dispatch(getTransaction({ walletId: accountId, transactionId }))
      .then((data) => {
        setTransferDetail(data);
        setCategory(data.category);
        setNote(data.note);
        setExpenseType(data.expenseType);
      })
      .catch((err) => {
        console.log(err);
        toggleTransactionDetailModal();
      });
    dispatch(getCategories());
  }, []);

  const onSaveTransaction = () => {
    if (!(category?.id !== transferDetail?.category?.id)) {
      //TODO: update category
      dispatch(
        updateTransactionCategory({
          walletId: accountId,
          transactionId,
          categoryId: category?.id
        })
      );
    }
    if (note !== transferDetail?.note) {
      //TODO: update note
      dispatch(updateTransactionNote({ walletId: accountId, transactionId, note }));
    }
    if (expenseType !== transferDetail?.expenseType) {
      //TODO: update expense type
      dispatch(updateTransactionExpenseType({ walletId: accountId, transactionId, expenseType }));
    }
    toggleTransactionDetailModal();
  };

  const onCategoryChanged = () => {
    //TODO: update category
  };

  return (
    <BottomModal handleClose={onSaveTransaction}>
      {receiptModal && (
        <ReceiptModal onClose={toggleReceiptModal} receiptUrl={transferDetail?.receiptUrl} />
      )}
      {isAddCategoryModalActive && (
        <AddCategoryModal
          handleClose={toggleCategoryAddModal}
          handleChangeCategory={onCategoryChanged}
        />
      )}
      <div
        className="w-full h-full flex justify-center items-center overflow-y-scroll"
        data-testid="section_account_transaction_detail_modal"
      >
        <div className="w-[640px] h-full flex flex-col py-20">
          <Card className="!bg-gray-900 !shadow-none !text-white">
            <div className="flex justify-between">
              <span className="text-2xl leading-8 font-medium">
                {transferDetail?.direction === TRANSACTION_DIRECTION.OUT.key && '-'}
                {currencyFormat(transferDetail?.amount || 0)}{' '}
                {CURRENCY[transferDetail?.currencyCode]}
              </span>
              <span className=" text-base leading-6 font-medium text-gray-400">
                {transactionGroupTypes?.find((type) => type.key === transferDetail?.type)?.detail}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-base leading-6 font-semibold">
                {transactionGroupTypes?.find((type) => type.key === transferDetail?.type)?.title}
              </span>
              {transferDetail?.transactionDate && (
                <span className="text-gray-400 text-base leading-6 font-medium">
                  {dateFormat(new Date(transferDetail?.transactionDate), '/')}
                </span>
              )}
            </div>
            <div>
              <span
                className="text-sm text-gray-500 font-medium leading-5"
                data-testid="section_transfer_detail_description"
              >
                {transferDetail?.description}
              </span>
            </div>
          </Card>
          {transferDetail &&
            ![
              TRANSACTION_TYPES.EXCHANGE_BUY.key,
              TRANSACTION_TYPES.EXCHANGE_SELL.key,
              TRANSACTION_TYPES.EXCHANGE_TRANSFER.key
            ].includes(transferDetail?.type) && (
              <>
                <div className="py-12 border-b border-solid border-gray-200">
                  <span className="text-lg font-semibold text-gray-900">Harcama Tipi</span>
                  <div className="mt-4 flex gap-x-6">
                    <span
                      className={`${
                        expenseType !== COMPANY_TYPES.PERSONAL
                          ? 'bg-green-500 text-green-50'
                          : 'bg-gray-200 text-gray-600'
                      } w-full flex items-center justify-center rounded-3xl py-2 cursor-pointer`}
                      onClick={() => setExpenseType(COMPANY_TYPES.CORPORATE)}
                      data-testid="label_expense_type_company"
                    >
                      Şirket
                    </span>
                    <span
                      className={`${
                        expenseType === COMPANY_TYPES.PERSONAL
                          ? 'bg-green-500 text-green-50'
                          : 'bg-gray-200 text-gray-600'
                      } w-full flex items-center justify-center rounded-3xl py-2 cursor-pointer`}
                      onClick={() => setExpenseType(COMPANY_TYPES.PERSONAL)}
                      data-testid="label_expense_type_individual"
                    >
                      Bireysel
                    </span>
                  </div>
                </div>
                <div className="py-12 border-b border-solid border-gray-200">
                  <div className="flex justify-between items-center">
                    <span className="text-lg font-semibold text-gray-900">Kategori</span>
                    <Button
                      variant="ghost"
                      leftIcon={<IconPlus className="stroke-current w-4" />}
                      onClick={toggleCategoryAddModal}
                    >
                      Yeni Ekle
                    </Button>
                  </div>

                  <div
                    className="mt-5 flex gap-4 flex-wrap"
                    data-testid="section_categories_transaction_detail_modal"
                  >
                    {categories?.map((category) => (
                      <CategoryBadge
                        key={category?.id}
                        colorHexCode={category?.colorHexCode}
                        iconUrl={category?.iconUrl}
                        name={category?.name}
                        textColorHexCode={category?.textColorHexCode}
                        selected={transferDetail?.category?.id === category?.id}
                        onClick={() => {
                          setTransferDetail({
                            ...transferDetail,
                            category: { id: category?.id }
                          });
                          setCategory(category);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="py-12 border-b border-solid border-gray-200 flex flex-col gap-y-1">
                  <label className="text-sm text-gray-500 font-medium">Not Ekle</label>
                  <textarea
                    className="appearance-none bg-gray-100 rounded-lg resize-none focus:outline-none p-3 h-20"
                    onChange={(e) => setNote(e.target.value)}
                    defaultValue={transferDetail?.note}
                  />
                </div>
              </>
            )}
          <div className="py-12 flex justify-between gap-x-6">
            <CustomButton
              color="primary"
              className="w-full"
              onClick={toggleReceiptModal}
              data-testid="button_receipt_transaction_detail_modal"
            >
              Dekont Göster
            </CustomButton>
            {/* <CustomButton
              color="success"
              className="w-full"
              data-testid="button_save_transaction_detail_modal"
              onClick={onSaveTransaction}
            >
              Kaydet
            </CustomButton> */}
          </div>
        </div>
      </div>
    </BottomModal>
  );
};

export default TransactionDetailModal;
