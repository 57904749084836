import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotification, getNotifications } from 'store/actions/notifications';

import { ReactComponent as MegaphoneIcon } from 'assets/icons/megaphone.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import NotificationCard from 'assets/illustrations/notification-card.png';

import SideModal from '../SideModal';
import useModal from 'utils/hooks/useModal';
import useDengage from 'utils/hooks/useDengage';
import CustomButton from 'components/CustomButton';
import Popup from 'components/Popup';
import { dateFormatV2 } from 'utils';

const NotHaveNotifications = () => (
  <div className="w-full h-full flex flex-col items-center justify-center">
    <img src={NotificationCard} width="110" alt="notifications" />
    <span className="text-gray-500 font-semibold mt-5">Henüz bildiriminiz bulunmamaktadır</span>
  </div>
);

const Notifications = ({ notifications, deleteNotificationById }) => (
  <div className="mt-6 mb-28 flex flex-col w-full overflow-y-scroll">
    {notifications.map((n, i) => (
      <div
        key={i}
        className={`${
          !(i % 2) ? 'bg-gray-50' : 'bg-white'
        } w-full flex items-start py-6 pl-12 group`}
      >
        <span className="w-10 h-10 rounded-full shadow-lg border-transparent group-hover:border-green-500 bg-white flex items-center justify-center mr-4 transition border border-solid">
          <MegaphoneIcon className="text-gray-500 group-hover:text-green-500 fill-current w-4" />
        </span>
        <div className="flex flex-col items-start justify-start">
          <span className="text-gray-900 font-semibold">{n.subject}</span>
          <span className="text-gray-400 text-sm">{dateFormatV2(new Date(n.sentDate))}</span>
        </div>
        <div
          className="flex justify-end ml-auto mr-6 cursor-pointer"
          onClick={() => deleteNotificationById(n.id)}
        >
          <CloseIcon className="stroke-current w-6 text-gray-400" />
        </div>
      </div>
    ))}
  </div>
);

const DeleteConfirmationPopup = ({ active, onClose, deleteAllNotifications }) => (
  <Popup active={active} onClose={onClose}>
    <div className="flex flex-col justify-center items-center">
      <div className="w-20 h-20 rounded-full bg-red-100 flex justify-center items-center">
        <TrashIcon className="fill-current w-10 text-red-600" />
      </div>
      <span className="mt-6 text-lg font-semibold text-gray-900">
        Tüm Bildirimlerin Silinecek
      </span>
      <span className="mt-3 text-sm font-medium text-gray-600">
        Silmek istediğine emin misin?
      </span>
      <CustomButton color="danger" onClick={deleteAllNotifications} className="w-full mt-6">
        Sil
      </CustomButton>
      <CustomButton color="success" onClick={onClose} className="w-full mt-4">
        İptal Et
      </CustomButton>
    </div>
  </Popup>
);

const NotificationsModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const { notifications } = useSelector((state) => state.notifications);

  const [isDeleteConfirmationPopupActive, , toggleDeleteConfirmationPopup] = useModal();

  async function deleteNotificationById(id) {
    await deleteNotification(id);

    dispatch(getNotifications());
  }

  function deleteAllNotifications() {
    toggleDeleteConfirmationPopup();
    Promise.all(
      notifications.map(async (notification) => {
        await deleteNotification(notification.id);
      })
    ).then(() => {
      dispatch(getNotifications());
    });
  }

  useEffect(() => {
    dispatch(getNotifications());

    publishEvent(eventTypes.pageView, { page_type: 'notifications' });
  }, []);

  return (
    <SideModal
      handleClose={handleClose}
      fullWidth
      header={
        <div className="flex justify-end items-center gap-x-4 p-8">
          <div
            onClick={toggleDeleteConfirmationPopup}
            className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
          >
            <TrashIcon className="fill-current text-gray-500" />
          </div>
          <div
            onClick={handleClose}
            className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
          >
            <CloseIcon className="stroke-current text-gray-500" />
          </div>
        </div>
      }
    >
      <DeleteConfirmationPopup
        active={isDeleteConfirmationPopupActive}
        onClose={toggleDeleteConfirmationPopup}
        deleteAllNotifications={deleteAllNotifications}
      />
      <div className="flex flex-col h-full">
        <span className="font-semibold text-3xl text-gray-900 ml-12">Bildirimler</span>
        {notifications.length ? (
          <Notifications
            notifications={notifications}
            deleteNotificationById={deleteNotificationById}
          />
        ) : (
          <NotHaveNotifications />
        )}
      </div>
    </SideModal>
  );
};

export default NotificationsModal;
