import classNames from 'classnames';
import { currencyFormat } from 'utils';
import { CURRENCY_SIGNS } from '../enums';

const ItemsTable = ({ selectedItems, handleEditItem }) => {
  return (
    <table className="table-auto w-full">
      <thead>
        <tr className="text-left text-sm font-semibold text-gray-700 border-b border-solid border-gray-100">
          <th className="py-4">Ürün İsmi</th>
          <th className="py-4 w-[128px]">Vergiler</th>
          <th className="py-4 text-right">Tutar</th>
          <th className="py-4 text-right">Genel Toplam</th>
        </tr>
      </thead>
      <tbody>
        {selectedItems.map((item, i) => (
          <tr
            key={i}
            className="border-b border-solid border-gray-100 hover:bg-gray-50 group transition cursor-pointer"
            onClick={() => handleEditItem(item)}
          >
            <TableData>{item.name}</TableData>
            <TableData className="py-6 text-sm font-semibold text-gray-700">
              <div className="flex flex-col gap-x-2">
                {item?.vatRate > 0 && <Tax>KDV</Tax>}
                {(item?.vatWithholdingCode || item?.vatWithholdingRate) && (
                  <Tax>KDV Tevkifat</Tax>
                )}
                {item?.additionalTaxes?.map((tax) => (
                  <Tax key={tax.name}>{tax.name}</Tax>
                ))}
              </div>
            </TableData>
            <TableData className="text-right">
              {currencyFormat(item?.price) + CURRENCY_SIGNS[item.currency]}
            </TableData>
            <TableData className="text-right">
              {currencyFormat(item?.totalPrice) + CURRENCY_SIGNS[item.currency]}
            </TableData>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TableData = ({ children, className, ...props }) => (
  <td className={classNames('py-6 text-sm font-semibold text-gray-700', className)} {...props}>
    {children}
  </td>
);

const Tax = ({ children, ...props }) => (
  <span className="text-xs" {...props}>
    {children}
  </span>
);

export default ItemsTable;
