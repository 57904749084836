import { ReactComponent as UnionIcon } from 'assets/icons/union.svg';
import useDengage from 'utils/hooks/useDengage';

import { SideModal } from 'components';
import { Switch } from 'components';
import { useState, useEffect } from 'react';

const NotificationSettingsModal = ({ handleClose }) => {
  const [allNotificationSetting, setAllNotificationSetting] = useState(false);

  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'notifications_settings' });
  }, []);

  return (
    <SideModal handleClose={handleClose} fullWidth>
      <div className="flex flex-col w-full">
        <span className="text-3xl font-semibold text-gray-900 px-8">Bildirim Ayarları</span>
        <div className="mt-6 w-full flex justify-between h-16 bg-gray-50 px-8">
          <div className="w-full flex items-center">
            <UnionIcon className="fill-current w-7 text-gray-900 mr-4" />
            <span className="text-gray-900">Tüm Bildirimler</span>
          </div>
          <Switch
            onChange={(e) => setAllNotificationSetting(e)}
            defaultValue={allNotificationSetting}
          />
        </div>
      </div>
    </SideModal>
  );
};

export default NotificationSettingsModal;
