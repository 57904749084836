import React from 'react';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconSetting } from 'assets/icons/settings.svg';
import classNames from 'classnames';

const SearchInput = React.forwardRef(
  (
    {
      onChange,
      className,
      placeholder = 'Arama',
      containerClassName,
      hasFilter = false,
      onClickFilterIcon = () => {},
      ...props
    },
    ref
  ) => {
    const searchInputClassNames = classNames(
      'appearance-none bg-transparent outline-none text-black w-full',
      className
    );
    const containerClassNames = classNames(
      'flex items-center space-between w-72 px-3 py-2 bg-gray-100 border-black border-1 border-opacity-10 rounded-lg focus:outline-none focus:bg-white focus:shadow-md',
      containerClassName
    );
    return (
      <div className={containerClassNames}>
        <div className="flex items-center space-x-3 w-full">
          <IconSearch className="fill-current text-gray-400 w-4 h-4" />
          <input
            placeholder={placeholder}
            onChange={onChange}
            className={searchInputClassNames}
            ref={ref}
            {...props}
          />
        </div>
        {hasFilter && (
          <IconSetting
            className="fill-current text-gray-400 w-4 h-4 cursor-pointer hover:text-green-500"
            onClick={onClickFilterIcon}
          />
        )}
      </div>
    );
  }
);
SearchInput.displayName = 'SearchInput';
export default SearchInput;
