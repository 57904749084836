import React, { useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/caret-down.svg';
import { Card } from 'components';
import classNames from 'classnames';

export default function CardButton({
  isDisabled = false,
  dropDowns = null,
  icon: Icon,
  text,
  onClick,
  buttonText = 'Yakında',
  ...props
}) {
  // Dropdown durumunu kontrol etmek için kullanılan state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderIcon = (Icon) => {
    if (React.isValidElement(Icon)) return Icon;
    if (typeof Icon === 'string') return <img src={Icon} alt={text} width="30" />;
    if (isDisabled && !React.isValidElement(Icon))
      return <Icon className="text-gray-300 fill-current w-8 h-8 scale-125" />;
  };

  if (isDisabled) {
    return (
      <Card className="w-1/6 bg-gray-700 flex-1" {...props}>
        {Icon && renderIcon(Icon)}
        <div className="flex space-x-2 items-center justify-between text-white mt-4">
          <span className="font-semibold select-none text-gray-300">{text}</span>
          <span className="w-24 h-10 px-4 rounded-full bg-gray-300 flex items-center justify-center select-none text-gray-900">
            {buttonText}
          </span>
        </div>
      </Card>
    );
  }

  if (dropDowns) {
    const IconArrowDownClassNames = classNames({
      ['rotate-180']: isDropdownOpen,
      'stroke-current': true,
      'w-10': true,
      'text-white': true
    });

    const dropDownsClassNames = classNames(
      'cursor-pointer py-2 px-3 text-sm font-semibold leading-5'
    );

    return (
      <div className="flex flex-1 relative">
        <Card
          className="w-1/6 flex-1 cursor-pointer bg-green-500"
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
          style={{
            borderBottomLeftRadius: isDropdownOpen ? 0 : '0.75rem',
            borderBottomRightRadius: isDropdownOpen ? 0 : '0.75rem'
          }}
        >
          {Icon && renderIcon(Icon)}

          <div className="flex space-x-3 items-center mt-6 cursor-pointer">
            <span className="font-semibold select-none text-white">{text}</span>
            <IconArrowDown className={IconArrowDownClassNames} />
          </div>
        </Card>
        <div
          className={classNames('absolute z-1 w-full text-white', {
            hidden: !isDropdownOpen
          })}
          style={{ marginTop: '150px' }}
        >
          <Card
            className="!p-3 bg-green-500"
            style={{
              borderTopLeftRadius: isDropdownOpen ? 0 : '0.75rem',
              borderTopRightRadius: isDropdownOpen ? 0 : '0.75rem'
            }}
          >
            {dropDowns.map((item, index) => (
              <div
                key={index}
                className={dropDownsClassNames}
                onClick={() => {
                  setIsDropdownOpen(false);
                  item.onClick();
                }}
              >
                {item.label}
              </div>
            ))}
          </Card>
        </div>
      </div>
    );
  }

  return (
    <Card className="w-1/6 flex-1 cursor-pointer" onClick={onClick}>
      {Icon && renderIcon(Icon)}
      <div className="flex space-x-2 items-center mt-6 cursor-pointer">
        <span className="font-semibold select-none">{text}</span>
        <ArrowRight className="stroke-current w-4 text-black" />
      </div>
    </Card>
  );
}

