import React, { useState, useEffect, createContext } from 'react';
import { BottomModal, Button } from 'components';
import { ModalHeader } from './components';

import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';

import {
  ExchangeAccountSelection,
  ExchangeCurrencySelection,
  ExchangeCurrencySummary,
  ExchangeSuccess
} from './components/steps';
import { useCountdownTimer } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { getExchangeRate } from 'store/actions/exchange';
import toast from 'react-hot-toast';
import { EXCHANGE_TYPES } from 'utils';
import TimeoutMessage from '../timeoutMessage';
import currency from 'currency.js';
import classNames from 'classnames';
import { getWallets } from 'store/actions/accounts';
import { WALLET_TYPES } from 'utils/contants';

const STEPS = [
  <ExchangeCurrencySelection key={'SelectAnExchangeType'} />,
  <ExchangeAccountSelection key={'ExchangeAccountSelection'} />,
  <ExchangeCurrencySummary key={'ExchangeCurrencySummary'} />,
  <ExchangeSuccess key={'ExchangeSuccess'} />
];

export const ExchangeModalContext = createContext({
  formValues: {
    exchangeType: null,
    currencyCode: null,
    exchangeAmount: 0,
    sellAmount: 0,
    buyAmount: 0,
    wallet: null,
    targetWallet: null,
    rate: null,
    total: null
  },
  setFormValues: () => {},
  step: 0,
  setStep: () => {},
  prevStep: () => {},
  nextStep: () => {},
  subTitle: '',
  time: 0,
  setSubTitle: () => {},
  onClose: () => {},
  onShowReceipt: () => {},
  calculateSellAmount: () => {},
  calculateBuyAmount: () => {}
});

export default function ExchangeModal({ isActive = true, onClose, ...props }) {
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [subTitle, setSubTitle] = useState('');

  const { time, restart } = useCountdownTimer(30, true, onTimeOver);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExchangeRate());
    dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
  }, []);

  useEffect(() => {
    //if the exchange type/rate is changed, calculate the new sell amount
    if (formValues?.buyAmount) calculateSellAmount(formValues?.buyAmount);
  }, [formValues?.exchangeType]);

  const onShowReceipt = () => {};

  const prevStep = () => setStep((currentStep) => currentStep - 1);

  const nextStep = () => setStep((currentStep) => currentStep + 1);

  function onTimeOver() {
    if (step === 3) return;
    if (step > 0)
      toast.custom((t) => <TimeoutMessage onClose={() => toast.dismiss(t.id)} />, {
        position: 'top-right'
      });

    dispatch(getExchangeRate()).then((rate) => {
      //if the exchange rate is changed, calculate the new sell amount
      if (formValues.exchangeType && formValues.currencyCode) {
        const lastRate = rate?.rateList?.find((r) => r.currency === formValues.currencyCode);
        if (formValues.exchangeType && (formValues?.buyAmount || formValues?.sellAmount)) {
          calculateSellAmount(formValues?.buyAmount);
        }
        //if the exchange rate is changed, update the rate in the form values
        setFormValues((prevValues) => ({
          ...prevValues,
          rate:
            prevValues.exchangeType === EXCHANGE_TYPES.SELL
              ? lastRate?.sellRate
              : lastRate?.buyRate
        }));
      }
      restart();
    });
  }

  const calculateBuyAmount = (amount = 0) => {
    const { rate } = formValues;
    const buyAmount = currency(amount).divide(rate).value || 0;
    setFormValues((prevValues) => ({
      ...prevValues,
      sellAmount: amount,
      buyAmount
    }));
    return buyAmount;
  };

  const calculateSellAmount = (amount = 0) => {
    const { rate } = formValues;
    const sellAmount = currency(amount).multiply(rate).value || 0;
    setFormValues((prevValues) => ({ ...prevValues, sellAmount, buyAmount: amount }));
    return sellAmount;
  };

  const modalClassNames = classNames({ '!bg-green-500': step === 3 });

  return (
    <ExchangeModalContext.Provider
      value={{
        formValues,
        setFormValues,
        step,
        setStep,
        prevStep,
        nextStep,
        subTitle,
        setSubTitle,
        onClose,
        time,
        onShowReceipt,
        calculateSellAmount,
        calculateBuyAmount
      }}
    >
      <BottomModal
        isActive={isActive}
        className={modalClassNames}
        handleClose={onClose}
        {...props}
      >
        <div className="h-12 m-6 pt-">
          {step > 0 && step < 3 && (
            <Button variant="circle" color="gray" icon={IconArrowLeft} onClick={prevStep} />
          )}
        </div>
        <div className="overflow-auto pb-10">
          <div className="w-full h-full flex max-w-[640px] mx-auto">
            <div className="w-full">
              {step < 3 && <ModalHeader />}
              {STEPS[step]}
            </div>
          </div>
        </div>
      </BottomModal>
    </ExchangeModalContext.Provider>
  );
}
