import { transactionsTypes } from 'store/types';

const {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  CONFIRM_TRANSACTION,
  CONFIRM_TRANSACTION_FAILURE,
  CONFIRM_TRANSACTION_SUCCESS,
  RESET_TRANSACTION_STATE,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_CATEGORY,
  UPDATE_TRANSACTION_CATEGORY_SUCCESS,
  UPDATE_TRANSACTION_CATEGORY_FAILURE,
  UPDATE_TRANSACTION_EXPENSE_TYPE,
  UPDATE_TRANSACTION_EXPENSE_TYPE_SUCCESS,
  UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE,
  UPDATE_TRANSACTION_NOTE,
  UPDATE_TRANSACTION_NOTE_SUCCESS,
  UPDATE_TRANSACTION_NOTE_FAILURE,
  RESET_CONFIRMED_TRANSACTION,
  ADD_WALLET_TRANSACTION,
  ADD_WALLET_TRANSACTION_SUCCESS,
  ADD_WALLET_TRANSACTION_FAILURE,
  GET_TRANSACTION_GROUP_TYPES,
  GET_TRANSACTION_GROUP_TYPES_FAILURE,
  GET_TRANSACTION_GROUP_TYPES_SUCCESS
} = transactionsTypes;

const initialState = {
  loading: false,
  transactions: [],
  transaction: null,
  confirmedTransaction: null,
  transactionGroupTypes: null,
  error: null
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transactions: action.payload
      };
    case GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload
      };
    case GET_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: action.payload
      };
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload
      };
    case CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_TRANSACTION_CATEGORY:
      return {
        ...state,
        loading: true,
        error: action.payload
      };
    case UPDATE_TRANSACTION_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload
      };
    case UPDATE_TRANSACTION_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_TRANSACTION_NOTE:
      return {
        ...state,
        loading: true,
        error: action.payload
      };
    case UPDATE_TRANSACTION_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload
      };
    case UPDATE_TRANSACTION_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_TRANSACTION_EXPENSE_TYPE:
      return {
        ...state,
        loading: true,
        error: action.payload
      };
    case UPDATE_TRANSACTION_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload
      };
    case UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CONFIRM_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: action.payload
      };
    case CONFIRM_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        confirmedTransaction: action.payload
      };
    case CONFIRM_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case RESET_TRANSACTION_STATE:
      return {
        ...state,
        loading: false,
        error: null,
        transactions: [],
        confirmedTransaction: null,
        transaction: null
      };
    case RESET_CONFIRMED_TRANSACTION:
      return {
        ...state,
        loading: false,
        error: null,
        confirmedTransaction: null
      };
    case ADD_WALLET_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_WALLET_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        addTransaction: action.payload
      };
    case ADD_WALLET_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_TRANSACTION_GROUP_TYPES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_TRANSACTION_GROUP_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transactionGroupTypes: action.payload
      };
    case GET_TRANSACTION_GROUP_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
