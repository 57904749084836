import React from 'react';
import { theme } from 'utils';
import cn from 'classnames';
import './index.css';

const { sizes } = theme;

export default function Tag({
  children,
  color = '',
  size,
  leftIcon,
  rightIcon,
  className = '',
  textColor,
  ...props
}) {
  const isHexColor = /^#[0-9A-F]{6}$/i.test(color);
  const classNames = cn({
    tag: true,
    [`tag-${color}`]: !isHexColor && color,
    [`tag-${size}`]: size,
    [className]: className
  });
  return (
    <div
      className={classNames}
      style={{ backgroundColor: isHexColor && color, color: isHexColor && textColor }}
      {...props}
    >
      {leftIcon && renderIcon({ icon: leftIcon, size, color })}
      <span className="font-medium" style={{ color: textColor }}>
        {children}
      </span>
      {rightIcon && renderIcon({ icon: rightIcon, size, color, isRightIcon: true })}
    </div>
  );
}

const renderIcon = ({ icon: Icon = null, size = '', isRightIcon = false }) => {
  if (Icon === null) return null;

  const getSpacesByDirection = () => {
    if (isRightIcon && size === sizes.lg) return ` ml-[9.33px]`;
    if (isRightIcon && size !== sizes.lg) return ` ml-[9.33px]`;
    if (!isRightIcon && size == sizes.lg) return ` mr-[9.33px]`;
    if (!isRightIcon && size !== sizes.lg) return ` mr-[9.33px]`;
    return '';
  };

  const iconClasses = `fill ${getSpacesByDirection()}`;

  if (React.isValidElement(Icon)) {
    return React.cloneElement(Icon, { className: `${Icon.props.className} ${iconClasses}` });
  }

  return (
    <span className={`fill-current stroke-current ${iconClasses}`}>
      <Icon />
    </span>
  );
};

Tag.defaultProps = {
  color: 'primary',
  size: 'lg',
  className: '',
  isSelected: false
};
