import React from 'react';
import { Popup, Card, Button, InfoMessage } from 'components';

export default function QRCodeModal({ isOpen, onClose, number, qrCode }) {
  return (
    <Popup
      active={isOpen}
      onClose={onClose}
      childrenClassName="!h-auto !overflow-y-none !bg-gray-50 !w-[538px] !p-6"
      data-testid="modal_qr_code_account_modal"
    >
      <div className="flex flex-col gap-y-6">
        <h4 className="font-semibold leading-5 text-gray-900" data-testid="label_qr_code_title">
          KareKod
        </h4>
        <Card className="flex flex-col items-center justify-center">
          <img
            src={`data:image/png;base64, ${qrCode}`}
            alt="qr-code"
            data-testid="img_qr_code_account_modal"
          />
          <h4 data-testid="label_number_qr_code_account_modal">{number}</h4>
        </Card>
        <InfoMessage className="!bg-gray-100 !p-3">
          Bu karekodu kullanarak Norma kullanıcıları ile ücretsiz para transferi yapabilirsin.
        </InfoMessage>
        <Button
          className="w-full"
          onClick={onClose}
          data-testid="button_close_qr_code_account_modal"
        >
          Kapat
        </Button>
      </div>
    </Popup>
  );
}
