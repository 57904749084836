import React from 'react';
import { Menu } from '@headlessui/react';
import { ReactComponent as IconArrowDown } from 'assets/icons/caret-down.svg';
import Button from 'components/button';
import classNames from 'classnames';

export default function DropdownButton({
  menus = [
    {
      label: 'click me',
      icon: <IconArrowDown className="fill-current w-5 h-5" />,
      onClick: () => alert('test')
    }
  ],
  title = 'Click Me',
  color,
  size = 'md',
  variant,
  disabled
}) {
  return (
    <Menu as="div" className="text-left relative z-30">
      {({ open }) => (
        <>
          <Menu.Button
            as={Button}
            rightIcon={<IconArrowDown className={classNames(open ? '-rotate-180' : '')} />}
            color={color}
            size={size}
            variant={variant}
            disabled={disabled}
          >
            {title}
          </Menu.Button>

          <Menu.Items className="origin-top-right absolute right-0 mt-5 w-max ring-1 ring-black ring-opacity-5 focus:outline-none p-6 text-gray-800 bg-white flex flex-col gap-y-4 rounded-xl shadow z-10 select-none">
            {menus?.map((menu, index) => (
              <MenuItem
                key={index}
                label={menu?.label}
                icon={menu?.icon}
                onClick={menu?.onClick}
                isActive={menu?.isActive}
                isLast={menus.length - 1 === index}
              />
            ))}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}

const MenuItem = ({ icon, label, onClick, isActive = true, isLast = false, ...props }) => {
  if (!isActive) return null;
  const menuClass = classNames('w-full flex items-center cursor-pointer gap-x-4 pb-6 pt-4', {
    'border-b border-solid border-b-gray-100': !isLast
  });
  return (
    <Menu.Item as="div" className={menuClass} onClick={onClick} {...props}>
      {icon && <span> {icon}</span>}
      <span>{label}</span>
    </Menu.Item>
  );
};
