import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, CustomInput } from 'components';
import { getDistrict } from 'store/actions/country';
import { useContactContext } from 'contexts/contactContext';

const InvoiceInformation = ({ errors, onlyRequired = false }) => {
  const dispatch = useDispatch();
  const { formValues, setFormValues } = useContactContext();
  const [tcknErrorMessage, setTcknErrorMessage] = useState('');
  const districtRef = useRef();

  const { cities, districts } = useSelector((state) => state.country);
  const filteredDistricts = districts[formValues?.cityId] || [];

  useEffect(() => {
    checkTaxIdendityNumber(formValues.taxIdentityNumber);
  }, [formValues.taxIdentityNumber]);

  useEffect(() => {
    const cityId = formValues?.cityId;
    if (!cityId) return;

    dispatch(getDistrict({ cityId }));
  }, [formValues?.cityId]);

  const checkTaxIdendityNumber = (value) => {
    if (value?.length > 0 && value?.length < 11) {
      setTcknErrorMessage('T.C. Kimlik Numarası 11 haneli olmalıdır!');
      setFormValues((prevValues) => ({
        ...prevValues,
        isTaxIdentityNumberValid: false
      }));
    } else {
      setTcknErrorMessage('');
      setFormValues((prevValues) => ({
        ...prevValues,
        isTaxIdentityNumberValid: true
      }));
    }
  };

  const onChangeTaxIdentityNumber = (e) => {
    const { value } = e.target;
    checkTaxIdendityNumber(value);

    setFormValues((prevValues) => ({ ...prevValues, taxIdentityNumber: value }));
  };

  const onChangeCity = (cityId) => {
    setFormValues((prevValues) => ({ ...prevValues, cityId: Number(cityId), districtId: null }));
    districtRef.current.value = '';
  };

  const onChangeDistrict = (districtId) => {
    setFormValues((prevValues) => ({ ...prevValues, districtId: Number(districtId) }));
  };

  const onChangeAddress = (e) => {
    const { value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, address: value }));
  };

  return (
    <div className="flex flex-col gap-y-4">
      <span
        className="text-[18px] text-gray-600 font-semibold"
        data-testid="section_invoice_information"
      >
        Fatura Bilgileri
      </span>
      <CustomInput
        key="individual_taxIdentityNumber"
        label="T.C. Kimlik Numarası"
        isOptional={!onlyRequired}
        name="taxIdentityNumber"
        onChange={onChangeTaxIdentityNumber}
        maxLength={11}
        value={formValues?.taxIdentityNumber}
        error={tcknErrorMessage}
        only="number"
        data-testid="input_tax_identity_number"
      />
      <Select
        key="individual_cityId"
        className="w-full"
        options={cities}
        value="id"
        valueLabel="name"
        label="İl"
        isOptional={!onlyRequired}
        defaultValue={formValues?.cityId}
        onChange={onChangeCity}
        error={errors?.cityId?.message}
        data-testid="select_city"
      />
      <Select
        key="individual_districtId"
        selectRef={districtRef}
        className="w-full"
        options={filteredDistricts}
        value="id"
        valueLabel="name"
        label="İlçe"
        isOptional={!onlyRequired}
        defaultValue={formValues?.districtId}
        onChange={onChangeDistrict}
        error={errors?.districtId?.message}
        data-testid="select_district"
      />
      {!onlyRequired && (
        <div className="flex flex-col w-full">
          <span className="text-sm text-gray-500 font-medium">
            Adres{' '}
            <span className="text-sm leading-5 font-medium text-gray-400 italic">
              {'(Opsiyonel)'}
            </span>
          </span>
          <textarea
            onChange={onChangeAddress}
            value={formValues?.address}
            className="bg-gray-100 resize-none rounded-lg focus:outline-none p-2 h-20 w-full"
            maxLength={140}
            data-testid="input_address"
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceInformation;
