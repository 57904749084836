import { CustomButton } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { completeSaleExpense, previewEDocument, createEDocument } from 'store/actions/invoice';
import ReceiptPdf from './ReceiptPdf';
// import { PAGE_NAMES } from './enums';

const EDocumentPreview = ({ recordId, onComplete }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { eDocument } = useSelector((state) => state.invoice);

  async function send() {
    try {
      await createEDocument({ companyId: user.companyId, salesExpenseRecordId: recordId });
      await completeSaleExpense({ companyId: user.companyId, recordId });
      onComplete();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (!user.companyId || !recordId) return;

    dispatch(previewEDocument({ companyId: user.companyId, salesExpenseRecordId: recordId }));
  }, [recordId]);

  return (
    <div className="flex justify-center h-full bg-gray-50">
      <div className="w-full max-w-[720px] h-full flex flex-col overflow-y-scroll py-10 pt-28">
        <span className="font-semibold text-3xl text-gray-900">Belge</span>
        <div className="w-full rounded-lg border border-solid border-gray-300 mt-6 bg-white p-4 flex items-center justify-center">
          <ReceiptPdf pdfUrl={eDocument} />
        </div>
        <CustomButton color="success" className="mt-8" onClick={send}>
          Gönder
        </CustomButton>
      </div>
    </div>
  );
};

export default EDocumentPreview;
