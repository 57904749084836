import { automaticTransactionTypes } from 'store/types';

const {
  GET_AUTOMATIC_TRANSACTION,
  GET_AUTOMATIC_TRANSACTION_SUCCESS,
  GET_AUTOMATIC_TRANSACTION_FAILURE,
  GET_AUTOMATIC_TRANSACTIONS,
  GET_AUTOMATIC_TRANSACTIONS_SUCCESS,
  GET_AUTOMATIC_TRANSACTIONS_FAILURE,
  CREATE_AUTOMATIC_TRANSACTION,
  CREATE_AUTOMATIC_TRANSACTION_FAILURE,
  CREATE_AUTOMATIC_TRANSACTION_SUCCESS,
  UPDATE_AUTOMATIC_TRANSACTION,
  UPDATE_AUTOMATIC_TRANSACTION_FAILURE,
  UPDATE_AUTOMATIC_TRANSACTION_SUCCESS,
  DELETE_AUTOMATIC_TRANSACTION,
  DELETE_AUTOMATIC_TRANSACTION_FAILURE,
  DELETE_AUTOMATIC_TRANSACTION_SUCCESS,
  RESET_AUTOMATIC_TRANSACTION_DETAIL,
  RESET_CREATED_AUTOMATIC_TRANSACTION
} = automaticTransactionTypes;

const initialState = {
  automaticTransactions: [],
  automaticTransaction: null,
  createdAutomaticTransaction: null,
  updatedAutomaticTransaction: null,
  deletedAutomaticTransaction: null,
  isLoading: false,
  error: null
};

export default function WalletReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AUTOMATIC_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case GET_AUTOMATIC_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        automaticTransaction: action.payload,
        error: null
      };
    case GET_AUTOMATIC_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GET_AUTOMATIC_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case GET_AUTOMATIC_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        automaticTransactions: action.payload,
        error: null
      };
    case GET_AUTOMATIC_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CREATE_AUTOMATIC_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case CREATE_AUTOMATIC_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createdAutomaticTransaction: action.payload,
        error: null
      };
    case CREATE_AUTOMATIC_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case UPDATE_AUTOMATIC_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case UPDATE_AUTOMATIC_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updatedAutomaticTransaction: action.payload,
        error: null
      };
    case UPDATE_AUTOMATIC_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case DELETE_AUTOMATIC_TRANSACTION:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case DELETE_AUTOMATIC_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deletedAutomaticTransaction: action.payload,
        error: null
      };
    case DELETE_AUTOMATIC_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case RESET_AUTOMATIC_TRANSACTION_DETAIL:
      return {
        ...state,
        isLoading: false,
        automaticTransaction: action.payload,
        error: null
      };
    case RESET_CREATED_AUTOMATIC_TRANSACTION:
      return {
        ...state,
        isLoading: false,
        createdAutomaticTransaction: action.payload,
        error: null
      };
    default:
      return state;
  }
}
