import { CustomButton } from 'components';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  completeSaleExpense,
  getSaleExpenseById,
  updateSaleExpense
} from 'store/actions/invoice';
import { getTaxOffices } from 'store/actions/taxOffice';
import { COMPANY_TYPES, currencyFormat, E_TRANSFORMATION_TYPES } from 'utils';
import useModal from 'utils/hooks/useModal';
import { PAGE_NAMES } from './enums';
import UploadDocumentPopup from './UploadDocumentPopup';
import useDengage from 'utils/hooks/useDengage';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { getManagementCredential } from 'store/actions/eTransformation';
import { TRANSFORMATION_TYPES } from '../salesRecordDetailModal/enums';
import { useDispatch } from 'react-redux';
import { Prompt } from 'components/promptModal';
import UpdateContactModal from 'views/other/contacts/components/updateContactModal';

const CURRENCY_NAMES = {
  USD: 'USD',
  TRY: 'TL',
  EUR: 'EURO'
};

const ItemsTable = ({ items }) => (
  <table className="table-fixed">
    <thead className="border-b border-solid border-gray-200">
      <tr className="text-left text-xs font-medium text-gray-500">
        <th className="pb-2">İşlem Açıklaması</th>
        <th className="pb-2">Birim</th>
        <th className="pb-2">İndirim Oranı</th>
        <th className="text-right pb-2">Toplam Tutar</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item) => (
        <tr className="py-2 border-b border-solid border-gray-200">
          <td className="py-4 flex flex-col">
            <span className="text-sm font-semibold text-gray-700">{item?.name || '-'}</span>
          </td>
          <td className="py-4 text-xs font-medium text-gray-500">
            {item?.unitType?.name ? `1 ${item?.unitType?.name}` : '-'}
          </td>
          <td className="py-4 text-xs font-medium text-gray-500">%{item?.discountRate || '0'}</td>
          <td className="py-4 text-right flex flex-col">
            <span className="text-sm font-semibold text-gray-900">
              {item?.taxExcludedAmount ? currencyFormat(item?.taxExcludedAmount) : '-'}{' '}
              {CURRENCY_NAMES[items[0].currency] || ''}
            </span>
            <div className="text-xs font-medium text-gray-500">
              <span>{`+%${item?.vatRate} KDV`}</span>
              {item?.additionalTaxes.length
                ? item.additionalTaxes.map((additionalTax, index) => (
                    <Fragment key={index}>
                      <span>{`+%${additionalTax?.percent} ${additionalTax?.name}`}</span>
                      {item?.additionalTaxes.length > index + 1 && <span>·</span>}
                    </Fragment>
                  ))
                : '-'}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const UploadDocumentButtonsFull = ({ file, setFile, uploadDocumentToSaleExpense }) => (
  <Fragment>
    <div className="mt-6 w-full border-2 border-dashed border-gray-300 rounded-lg p-4 flex gap-x-4 items-start justify-start bg-white cursor-default">
      <img src={file.url} className="h-32 rounded-lg" />
      <div className="h-full w-full flex flex-col flex-wrap justify-center items-start gap-y-2 break-words">
        <span className="text-lg font-semibold text-gray-600 text-ellipsis break-all">
          {file.name}
        </span>
        <CustomButton color="danger" onClick={() => setFile(null)}>
          <div className="flex gap-x-2 items-center">
            <TrashIcon className="fill-current w-4 text-red-800" />
            <span>Sil</span>
          </div>
        </CustomButton>
      </div>
    </div>
    <div className="flex gap-x-2 items-center my-6">
      <InfoCircleIcon className="fill-current w-5 text-gray-500" />
      <span className="text-sm font-medium text-gray-500">
        Dosya tipi olarak .pdf, .jpg ve .png desteklenir. Maksimum boyut 15 mb
      </span>
    </div>
    <CustomButton color="success" onClick={uploadDocumentToSaleExpense}>
      Kaydet
    </CustomButton>
  </Fragment>
);

const UploadDocumentButtonsEmpty = ({ toggleUploadDocumentPopup, setPage, summary }) => {
  async function saveWithoutUpload() {
    try {
      await completeSaleExpense({ companyId: summary.companyId, recordId: summary.id });
      setPage(PAGE_NAMES.SUCCESS_PAGE);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Fragment>
      <CustomButton color="success" className="mt-9" onClick={toggleUploadDocumentPopup}>
        Belge Yükle
      </CustomButton>
      <CustomButton color="primary" className="mt-4 mb-10" onClick={saveWithoutUpload}>
        Yüklemeden Kaydet
      </CustomButton>
    </Fragment>
  );
};

const BusinessTypeButtons = ({
  file,
  setFile,
  uploadDocumentToSaleExpense,
  toggleUploadDocumentPopup,
  setPage,
  summary
}) => (
  <Fragment>
    {file ? (
      <UploadDocumentButtonsFull
        file={file}
        setFile={setFile}
        uploadDocumentToSaleExpense={uploadDocumentToSaleExpense}
      />
    ) : (
      <UploadDocumentButtonsEmpty
        toggleUploadDocumentPopup={toggleUploadDocumentPopup}
        setPage={setPage}
        summary={summary}
      />
    )}
  </Fragment>
);

const IndividualTypeButtons = ({ summary, setPage }) => {
  const { etransformationType } = useSelector((state) => state.auth);
  const { salesDetail: transaction } = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const [isMissingContactInformationPopupActive, , toggleMissingContactInformationPopup] =
    useModal();
  const [isUpdateContactSideModalActive, , toggleUpdateContactSideModal] = useModal();

  async function continueWithoutCreate() {
    try {
      await completeSaleExpense({ companyId: summary.companyId, recordId: summary.id });
      setPage(PAGE_NAMES.SUCCESS_PAGE);
    } catch (err) {
      console.log(err);
    }
  }

  const onCreateEDocument = () => {
    const contact = transaction?.contact;

    let contactHasMissingInformations = true;

    if (contact?.companyType === COMPANY_TYPES.INDIVIDUAL) {
      contactHasMissingInformations =
        !contact?.firstName ||
        !contact?.lastName ||
        !contact?.email ||
        !contact?.taxIdentityNumber ||
        !contact?.cityId ||
        !contact?.districtId;
    } else if (contact?.companyType === COMPANY_TYPES.BUSINESS) {
      contactHasMissingInformations =
        !contact?.companyName ||
        !contact?.email ||
        !contact?.taxIdentityNumber ||
        !contact?.cityId ||
        !contact?.districtId;
    }

    if (contactHasMissingInformations) {
      return toggleMissingContactInformationPopup();
    }
    setPage(PAGE_NAMES.E_DOCUMENT);
  };

  const onApproveContactEdit = () => {
    toggleMissingContactInformationPopup();
    toggleUpdateContactSideModal();
  };

  const onUpdateContactModalClose = () => {
    dispatch(getSaleExpenseById({ salesExpenseRecordId: summary?.id }));
    toggleUpdateContactSideModal();
  };

  return (
    <div className="flex flex-col mt-9">
      {isMissingContactInformationPopupActive && (
        <Prompt
          isActive={true}
          title={
            <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100 mx-auto">
              <InfoCircleIcon className="fill-current w-10 h-10 text-yellow-600" />
            </div>
          }
          description="VKN/TCKN, e-Posta, İl ve İlçe bilgisinden eksik olanı giriniz"
          okText="Müşteri/Tedarikçi Düzenle"
          cancelText="İptal Et"
          onOK={onApproveContactEdit}
          onCancel={toggleMissingContactInformationPopup}
        />
      )}
      {isUpdateContactSideModalActive && (
        <UpdateContactModal
          contactId={transaction?.contact?.id}
          onClose={onUpdateContactModalClose}
          onlyRequired={true}
        />
      )}
      <CustomButton color="success" onClick={onCreateEDocument}>
        {[E_TRANSFORMATION_TYPES.E_INVOICE, E_TRANSFORMATION_TYPES.E_RECEIPT].includes(
          etransformationType
        )
          ? 'e-Belge Oluştur'
          : 'Belge Yükle'}
      </CustomButton>
      <CustomButton color="primary" className="mt-4" onClick={continueWithoutCreate}>
        Oluşturmadan Devam Et
      </CustomButton>
    </div>
  );
};

const SummaryForm = ({ setPage, recordId }) => {
  const [summary, setSummary] = useState();
  const [calculatedSummary, setCalculatedSummary] = useState(null);
  const [file, setFile] = useState();
  const [type, setType] = useState();
  const dispatch = useDispatch();

  const { user, etransformationType } = useSelector((state) => state.auth);
  const { salesDetail: transaction } = useSelector((state) => state.invoice);
  const taxOffices = useSelector((state) => state.taxOffice);

  const [uploadDocumentPopup, , toggleUploadDocumentPopup] = useModal();
  const { publishEvent, eventTypes } = useDengage();

  const date = new Date();
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;

  useEffect(() => {
    getTaxOffices();
    getCredential();

    setCalculatedSummary({
      ...transaction,
      totalAmount:
        transaction?.currency === 'TRY'
          ? transaction?.totalAmount
          : transaction?.totalAmount * transaction?.currencyRate,
      taxExcludedAmount:
        transaction?.currency === 'TRY'
          ? transaction?.taxExcludedAmount
          : transaction?.taxExcludedAmount * transaction?.currencyRate,
      totalTaxAmount:
        transaction?.currency === 'TRY'
          ? transaction?.totalTaxAmount
          : transaction?.totalTaxAmount * transaction?.currencyRate,
      taxes: transaction?.taxes.map((tax) => ({
        ...tax,
        amount:
          transaction?.currency === 'TRY' ? tax.amount : tax.amount * transaction?.currencyRate
      })),
      // salesExpenseItems: transaction?.salesExpenseItems
      salesExpenseItems: transaction?.salesExpenseItems.map((item) => {
        return {
          ...item,
          currency: item?.currency,
          totalAmount:
            item?.currency === 'TRY'
              ? item?.totalAmount
              : item?.totalAmount / transaction?.currencyRate,
          taxExcludedAmount:
            item?.currency === 'TRY'
              ? item?.taxExcludedAmount
              : item?.taxExcludedAmount / transaction?.currencyRate,
          totalTaxAmount:
            item?.currency === 'TRY'
              ? item?.totalTaxAmount
              : item?.totalTaxAmount / transaction?.currencyRate,
          unitPrice:
            item?.currency === 'TRY'
              ? item?.unitPrice
              : item?.unitPrice / transaction?.currencyRate,
          withholdingTaxAmount:
            item?.currency === 'TRY'
              ? item?.withholdingTaxAmount
              : item?.withholdingTaxAmount / transaction?.currencyRate
        };
      })
    });
    setSummary(transaction);
  }, [transaction]);

  async function uploadDocumentToSaleExpense() {
    if (!summary?.companyId || !summary?.id || !file.id) return;

    try {
      await updateSaleExpense({
        companyId: summary.companyId,
        salesExpenseRecordId: summary?.id,
        ...summary,
        contactId: summary?.contact?.id,
        items: summary?.salesExpenseItems.map((item) => ({ ...item, id: item.itemId })),
        invoiceFileIds: [file.id]
      });

      await completeSaleExpense({ companyId: summary.companyId, recordId: summary.id });

      setPage(PAGE_NAMES.SUCCESS_PAGE);
    } catch (err) {
      console.log(err);
    }
  }

  function getCredential() {
    if (!summary?.companyId) return;

    getManagementCredential({ companyId: summary?.companyId })
      .then((res) => {
        if (res.status === 200) setType(TRANSFORMATION_TYPES.E_INVOICE);
        else setType(TRANSFORMATION_TYPES.E_RECEIPT);
      })
      .catch(() => {
        setType(TRANSFORMATION_TYPES.E_RECEIPT);
      });
  }

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_detail_sales' });
  }, []);

  if (!calculatedSummary || !summary) return null;
  return (
    <div className="flex justify-center h-full bg-gray-50">
      <UploadDocumentPopup
        active={uploadDocumentPopup}
        toggle={toggleUploadDocumentPopup}
        setFile={setFile}
      />
      <div className="w-[640px] h-full flex flex-col overflow-y-scroll py-10">
        <span className="font-semibold text-lg text-gray-900">Satış Kaydı Özeti</span>
        <div className="flex justify-between mt-8">
          <span className="text-gray-900 font-semibold">{summary?.title}</span>
          <span className="text-sm">{formattedDate}</span>
        </div>
        <div className="w-full rounded-t-lg shadow-lg bg-white p-10 mt-6 border-t border-r border-l border-solid border-gray-200">
          <div className="w-full pb-8 border-b border-solid border-gray-200 flex flex-col">
            <span className="text-sm font-semibold text-gray-900">Alıcı</span>
            {summary?.contact?.companyName ? (
              <span className="text-sm font-semibold text-gray-500 mt-4">
                {`${summary?.contact?.companyName}`}
              </span>
            ) : (
              summary?.contact?.firstName &&
              summary?.contact?.lastName && (
                <span className="text-sm font-semibold text-gray-500 mt-4">
                  {`${summary?.contact?.firstName} ${summary?.contact?.lastName}`}
                </span>
              )
            )}
            {summary?.contact?.address && (
              <span className="text-sm font-medium text-gray-500 mt-3 break-words">
                {summary?.contact?.address}
              </span>
            )}
            {taxOffices.find((t) => t.id === summary?.contact?.taxOfficeId)?.name && (
              <span className="text-sm font-medium text-gray-500 mt-3">
                {taxOffices.find((t) => t.id === summary?.contact?.taxOfficeId)?.name}{' '}
                {summary?.taxIdentityNumber}
              </span>
            )}
            {summary?.contact?.email && (
              <span className="text-sm font-medium text-gray-500 mt-3">
                {summary?.contact?.email}
              </span>
            )}
          </div>

          <div className="w-full pt-8 flex flex-col">
            <ItemsTable items={calculatedSummary?.salesExpenseItems || []} />
          </div>
        </div>
        <div className="w-full rounded-b-lg shadow-md bg-gray-50 px-8 py-7 border-b border-r border-l border-solid border-gray-200">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-900">Toplam Tutar</span>
            <span className="text-sm font-semibold text-gray-900">
              {calculatedSummary?.totalAmount
                ? currencyFormat(calculatedSummary?.taxExcludedAmount)
                : '-'}{' '}
              TL
            </span>
          </div>
          <div className="flex justify-between items-center mt-4">
            <span className="text-sm font-semibold text-gray-900">Vergiler</span>
            <span className="text-sm font-semibold text-gray-900">
              {calculatedSummary?.totalTaxAmount
                ? currencyFormat(calculatedSummary?.totalTaxAmount)
                : '-'}{' '}
              TL
            </span>
          </div>
          {[...calculatedSummary?.taxes, ...calculatedSummary?.withHoldingTaxes].map((tax) => (
            <div className="flex justify-between items-center mt-4 pl-6" key={tax.name}>
              <span className="text-xs font-medium text-gray-500">
                {tax?.name} (%{tax?.percent})
              </span>
              <span className="text-xs font-medium text-gray-500">
                {currencyFormat(tax?.amount || 0)} TL
              </span>
            </div>
          ))}

          <div className="flex justify-between items-center mt-5">
            <span className="text-sm font-semibold text-gray-900">Genel Toplam</span>
            <span className="text-sm font-semibold text-gray-900">
              {calculatedSummary?.totalAmount
                ? currencyFormat(calculatedSummary?.totalAmount)
                : '-'}
              TL
            </span>
          </div>
        </div>
        {[E_TRANSFORMATION_TYPES.E_INVOICE, E_TRANSFORMATION_TYPES.E_RECEIPT].includes(
          etransformationType
        ) ? (
          <IndividualTypeButtons summary={summary} setPage={setPage} />
        ) : (
          <BusinessTypeButtons
            file={file}
            setFile={setFile}
            uploadDocumentToSaleExpense={uploadDocumentToSaleExpense}
            toggleUploadDocumentPopup={toggleUploadDocumentPopup}
            setPage={setPage}
            summary={summary}
          />
        )}
      </div>
    </div>
  );
};

export default SummaryForm;
