import React from 'react';

export default function TabSubTitle({ children, className, ...props }) {
  const classNames = `text-gray-900 leading-5 font-semibold text-base mb-6 ${className}`;
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
}

TabSubTitle.defaultProps = {
  className: ''
};
