const initialState = {
  sectors: [],
  professions: []
};

const activiyScopeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SECTORS':
      return {
        ...state,
        sectors: action.data
      };
    case 'SET_PROFESSIONS':
      return {
        ...state,
        professions: action.data
      };
    default:
      return { ...state };
  }
};

export default activiyScopeReducer;
