import { Card, Button, Tag, Divider, PageCard, DropdownButton } from 'components';
import PopoverSelect from 'components/popoverSelect';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getContactBalance,
  getContactById,
  getContactTransactionDebtStatus
} from 'store/actions/contacts';
import {
  BUSINESS_TYPES,
  COMPANY_TYPES,
  currencyFormat,
  DEBT_STATUS,
  PAYMENT_STATUS
} from 'utils';
import { useRouter } from 'utils/hooks/useRouter';
import { CURRENCY_NAMES, RECORD_TYPES } from 'views/invoicing/enums';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import useModal from 'utils/hooks/useModal';
import UpdateContactModal from '../components/updateContactModal';
import { useContactContext } from 'contexts/contactContext';
import MoneyTransferModal from 'views/transactions/MoneyTransferModal';
import { ReactComponent as IconArrowDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as IconAddBalance } from 'assets/icons/add-balance.svg';
import { ReactComponent as IconShop } from 'assets/icons/shop.svg';
import { ReactComponent as IconWallet } from 'assets/icons/wallet.svg';
import routes from 'router';
import moment from 'moment/moment';

const ContactDetail = () => {
  const { query, push } = useRouter();
  const { setSelectedContact } = useContactContext();
  const [selectedFilter, setSelectedFilter] = useState();
  const { balance, contact, transactionDebtStatus } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  const [isUpdateContactSideModalActive, , toggleUpdateContact] = useModal({
    shouldBeBlockScroll: true
  });
  const [isMoneyTransferModalActive, , toggleMoneyTransferModal] = useModal({
    shouldBeBlockScroll: true
  });

  const filters = [
    { label: 'Bugün', value: 'TODAY' },
    { label: 'Gecikmiş', value: 'LATE' },
    { label: 'Toplam', value: 'TOTAL' }
  ];

  const TITLE_MAP = {
    [BUSINESS_TYPES.CUSTOMER.value]: 'Müşter Bilgileri',
    [BUSINESS_TYPES.SUPPLIER.value]: 'Tedarikçi Bilgileri',
    [BUSINESS_TYPES.CUSTOMER_SUPPLIER.value]: 'Müşteri/Tedarikçi Bilgileri'
  };

  const DEBT_MAP = {
    [DEBT_STATUS.CREDIT.value]: { text: 'Alacağım Var', color: '#EEF8D3', textColor: '#3F6600' },
    [DEBT_STATUS.DEBT.value]: { text: 'Borcum Var', color: 'red', textColor: '#831802' },
    [DEBT_STATUS.NONE.value]: {
      text: 'Borç/Alacak Yok',
      color: 'gray-light',
      textColor: '#6B7280'
    }
  };

  const balanceDebtStatus = DEBT_MAP[balance?.debtStatus];

  useEffect(() => {
    if (query?.id) {
      dispatch(getContactById(query?.id)).then(setSelectedContact);
      dispatch(getContactBalance(query?.id));
      dispatch(getContactTransactionDebtStatus(query?.id));
    }
  }, [query?.id]);

  const onChangeFilter = (filter) => {
    if (filter) {
      setSelectedFilter(filter);
      dispatch(
        getContactTransactionDebtStatus(query?.id, {
          queryType: filter?.value
        })
      );
    }
  };
  const onUpdated = (isUpdated) => {
    if (isUpdated) dispatch(getContactById(query?.id));
  };

  const onRedirectToInvoicing = (status) => {
    let filterPayload = {
      startDueDate: null,
      endDueDate: null,
      recordType: null,
      paymentStatus: PAYMENT_STATUS.NOT_PAID,
      hasInvoice: true,
      selectedContact: {
        value: query?.id,
        label: `${contact.firstName} ${contact?.lastName}`
      }
    };

    if (selectedFilter?.value === filters[0].value) {
      filterPayload = {
        ...filterPayload,
        startDueDate: null,
        endDueDate: new Date()
      };
    } else if (selectedFilter?.value === filters[1].value) {
      filterPayload = {
        ...filterPayload,
        startDueDate: null,
        endDueDate: new Date(moment().subtract(1, 'day'))
      };
    } else {
      filterPayload = {
        ...filterPayload,
        startDueDate: null,
        endDueDate: null
      };
    }

    if (status === DEBT_STATUS.CREDIT) {
      push('/invoicing', {
        filters: {
          ...filterPayload,
          recordType: RECORD_TYPES.SALES
        }
      });
    } else {
      push('/invoicing', { filters: { ...filterPayload, recordType: RECORD_TYPES.EXPENSE } });
    }
  };

  return (
    <PageCard.Container>
      {isUpdateContactSideModalActive && (
        <UpdateContactModal
          onClose={toggleUpdateContact}
          contactId={query?.id}
          onUpdated={onUpdated}
        />
      )}
      {isMoneyTransferModalActive && (
        <MoneyTransferModal handleClose={toggleMoneyTransferModal} defaultContactId={query?.id} />
      )}
      <PageCard
        title={TITLE_MAP[contact?.businessType]}
        menu={
          <>
            <Button color="secondary" onClick={toggleUpdateContact}>
              Bilgiler
            </Button>
            <DropdownButton
              menus={[
                {
                  label: 'Para Gönder',
                  onClick: toggleMoneyTransferModal,
                  icon: <IconCircled icon={IconAddBalance} className="" />
                },
                {
                  label: 'Satış Kaydı Oluştur',
                  onClick: () => push(`${routes.invoicing.path}?contactId=${query?.id}`),
                  icon: <IconCircled icon={IconShop} className="" />
                },
                {
                  label: 'Gider Kaydı Oluştur',
                  onClick: () =>
                    push(`${routes.createExpenseRecord.path}?contactId=${query?.id}`),
                  icon: <IconCircled icon={IconWallet} className="" />
                }
              ]}
              title="Hızlı İşlemler"
            />
          </>
        }
      >
        <div className="flex flex-col gap-y-6">
          <h5>
            {contact?.companyType === COMPANY_TYPES.INDIVIDUAL
              ? `${contact?.firstName} ${contact?.lastName}`
              : contact?.companyName}
          </h5>
          <Card className="!border border-solid !border-gray-200 !shadow-none max-w-[431px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-x-4">
                <span className="text-gray-500">Bakiye</span>
                <span className="leading-6 text-lg font-semibold">
                  {currencyFormat(balance?.amount)} {CURRENCY_NAMES.TRY}
                </span>
              </div>
              <Tag
                color={balanceDebtStatus?.color}
                textColor={balanceDebtStatus?.textColor}
                size="sm"
              >
                {balanceDebtStatus?.text}
              </Tag>
            </div>
          </Card>
        </div>
      </PageCard>
      <Divider className="my-10 border-gray-300" />
      <PageCard
        title="Alacaklar / Borçlar"
        titleClassName="text-gray-900"
        className="py-8"
        menu={
          <>
            <PopoverSelect
              options={filters}
              selectedOption={selectedFilter || filters[0]}
              defaultValue={filters[0]}
              onChange={onChangeFilter}
            />
          </>
        }
      >
        <DebtStatusCard
          text="Alacaklarım"
          balance={transactionDebtStatus?.creditAmount}
          onClick={() => onRedirectToInvoicing(DEBT_STATUS.CREDIT)}
        />
        <Divider className="border-gray-100 my-5" />
        <DebtStatusCard
          text="Borçlarım"
          balance={transactionDebtStatus?.debtAmount}
          onClick={() => onRedirectToInvoicing(DEBT_STATUS.DEBT)}
        />
      </PageCard>
    </PageCard.Container>
  );
};

const DebtStatusCard = ({ text = '', balance = 0, onClick = () => {} }) => {
  const baseClassName = classNames('flex items-center justify-between cursor-pointer');
  const balanceClassName = classNames(
    balance > 0 ? 'font-semibold' : 'font-normal text-gray-500'
  );
  return (
    <div className={baseClassName} onClick={onClick}>
      <div className="font-semibold">{text}</div>
      <div className="flex items-center gap-x-4">
        <span className={balanceClassName}>
          {currencyFormat(balance)} {CURRENCY_NAMES.TRY}
        </span>
        <IconArrowDown className="stroke-current w-5 h-5 text-gray-500 -rotate-90" />
      </div>
    </div>
  );
};

const IconCircled = ({ className, icon: Icon }) => (
  <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full">
    <Icon className={classNames('w-5 fill-current', className)} />
  </div>
);

export default ContactDetail;
