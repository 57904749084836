export default {
  ARCHIVE_WALLET: 'ARCHIVE_WALLET',
  ARCHIVE_WALLET_SUCCESS: 'ARCHIVE_WALLET_SUCCESS',
  ARCHIVE_WALLET_FAILURE: 'ARCHIVE_WALLET_FAILURE',
  UNARCHIVE_WALLET: 'UNARCHIVE_WALLET',
  UNARCHIVE_WALLET_SUCCESS: 'UNARCHIVE_WALLET_SUCCESS',
  UNARCHIVE_WALLET_FAILURE: 'UNARCHIVE_WALLET_FAILURE',
  GET_ARCHIVED_WALLET: ' GET_ARCHIVED_WALLET',
  GET_ARCHIVED_WALLET_SUCCESS: ' GET_ARCHIVED_WALLET_SUCCESS',
  GET_ARCHIVED_WALLET_FAILURE: ' GET_ARCHIVED_WALLET_FAILURE'
};
