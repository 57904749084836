import { billTypes } from 'store/types';

const {
  GET_BILLS,
  GET_BILLS_SUCCESS,
  GET_BILLS_FAILURE,
  GET_BILL,
  GET_BILL_SUCCESS,
  GET_BILL_FAILURE,
  GET_BILL_SUBSCRIPTIONS,
  GET_BILL_SUBSCRIPTIONS_SUCCESS,
  GET_BILL_SUBSCRIPTIONS_FAILURE,
  SAVE_BILL_SUBSCRIPTIONS,
  SAVE_BILL_SUBSCRIPTIONS_SUCCESS,
  SAVE_BILL_SUBSCRIPTIONS_FAILURE,
  UPDATE_BILL_SUBSCRIPTIONS,
  UPDATE_BILL_SUBSCRIPTIONS_SUCCESS,
  UPDATE_BILL_SUBSCRIPTIONS_FAILURE,
  DELETE_BILL_SUBSCRIPTIONS,
  DELETE_BILL_SUBSCRIPTIONS_SUCCESS,
  DELETE_BILL_SUBSCRIPTIONS_FAILURE,
  GET_BILL_CATEGORIES,
  GET_BILL_CATEGORIES_SUCCESS,
  GET_BILL_CATEGORIES_FAILURE,
  GET_BILL_CORPORATIONS,
  GET_BILL_CORPORATIONS_SUCCESS,
  GET_BILL_CORPORATIONS_FAILURE,
  CHECK_HAS_BILL,
  CHECK_HAS_BILL_SUCCESS,
  CHECK_HAS_BILL_FAILURE,
  PAY_BILL,
  PAY_BILL_SUCCESS,
  PAY_BILL_FAILURE,
  RESET_ALL_BILL,
  RESET_GET_BILL
} = billTypes;

const initialState = {
  isLoading: false,
  bill: null,
  bills: [],
  corporations: [],
  categories: [],
  subscriptions: [],
  saveSubscriptions: null,
  updateSubscriptions: null,
  deleteSubscriptions: null,
  isBillExist: false,
  payBill: null,
  error: null
};

export default function WalletReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BILL:
      return {
        ...state,
        isLoading: true,
        bill: null,
        error: null
      };
    case GET_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bill: action.payload,
        error: null
      };
    case GET_BILL_FAILURE:
      return {
        ...state,
        isLoading: false,
        bill: null,
        error: action.payload
      };
    case GET_BILLS:
      return {
        ...state,
        isLoading: true,
        bills: null,
        error: null
      };
    case GET_BILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bills: action.payload,
        error: null
      };
    case GET_BILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        bills: null,
        error: action.payload
      };
    case GET_BILL_CATEGORIES:
      return {
        ...state,
        isLoading: true,
        categories: [],
        error: null
      };
    case GET_BILL_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload,
        error: null
      };
    case GET_BILL_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        categories: [],
        error: action.payload
      };
    case GET_BILL_CORPORATIONS:
      return {
        ...state,
        isLoading: true,
        corporations: [],
        error: null
      };
    case GET_BILL_CORPORATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        corporations: action.payload,
        error: null
      };
    case GET_BILL_CORPORATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        corporations: [],
        error: action.payload
      };
    case GET_BILL_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        subscriptions: [],
        error: null
      };
    case GET_BILL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptions: action.payload,
        error: null
      };
    case GET_BILL_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        subscriptions: [],
        error: action.payload
      };
    case SAVE_BILL_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        saveSubscriptions: null,
        error: null
      };
    case SAVE_BILL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveSubscriptions: action.payload,
        error: null
      };
    case SAVE_BILL_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        saveSubscriptions: null,
        error: action.payload
      };
    case UPDATE_BILL_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        updateSubscriptions: null,
        error: null
      };
    case UPDATE_BILL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateSubscriptions: action.payload,
        error: null
      };
    case UPDATE_BILL_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        updateSubscriptions: null,
        error: action.payload
      };
    case DELETE_BILL_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        deleteSubscriptions: null,
        error: null
      };
    case DELETE_BILL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteSubscriptions: action.payload,
        error: null
      };
    case DELETE_BILL_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        deleteSubscriptions: null,
        error: action.payload
      };
    case CHECK_HAS_BILL:
      return {
        ...state,
        isLoading: true,
        isBillExist: null,
        error: null
      };
    case CHECK_HAS_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isBillExist: action.payload?.billExist,
        error: null
      };
    case CHECK_HAS_BILL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isBillExist: null,
        error: action.payload
      };
    case PAY_BILL:
      return {
        ...state,
        isLoading: true,
        payBill: null,
        error: null
      };
    case PAY_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payBill: action.payload,
        error: null
      };
    case PAY_BILL_FAILURE:
      return {
        ...state,
        isLoading: false,
        payBill: null,
        error: action.payload
      };
    case RESET_GET_BILL:
      return {
        ...state,
        isLoading: false,
        bill: initialState.bill,
        error: null
      };
    case RESET_ALL_BILL:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
