export default {
  GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',
  GET_EXCHANGE_RATE_SUCCESS: 'GET_EXCHANGE_RATE_SUCCESS',
  GET_EXCHANGE_RATE_FAILURE: 'GET_EXCHANGE_RATE_FAILURE',
  BUY_FOREIGN_CURRENCY: 'BUY_FOREIGN_CURRENCY',
  BUY_FOREIGN_CURRENCY_SUCCESS: 'BUY_FOREIGN_CURRENCY_SUCCESS',
  BUY_FOREIGN_CURRENCY_FAILURE: 'BUY_FOREIGN_CURRENCY_FAILURE',
  CALCULATE_BUY_FOREIGN_CURRENCY: 'CALCULATE_BUY_FOREIGN_CURRENCY',
  CALCULATE_BUY_FOREIGN_CURRENCY_SUCCESS: 'CALCULATE_BUY_FOREIGN_CURRENCY_SUCCESS',
  CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE: 'CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE',
  SELL_FOREIGN_CURRENCY: 'SELL_FOREIGN_CURRENCY',
  SELL_FOREIGN_CURRENCY_SUCCESS: 'SELL_FOREIGN_CURRENCY_SUCCESS',
  SELL_FOREIGN_CURRENCY_FAILURE: 'SELL_EXCHANGE_CURRENCY_FAILURE',
  CALCULATE_SELL_FOREIGN_CURRENCY: 'CALCULATE_SELL_FOREIGN_CURRENCY',
  CALCULATE_SELL_FOREIGN_CURRENCY_SUCCESS: 'CALCULATE_SELL_FOREIGN_CURRENCY_SUCCESS',
  CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE: 'CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE'
};
