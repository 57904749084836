import { CustomInput } from 'components';

const AccountInformation = ({ setValue, watch, errors, clearErrors }) => {
  function handleIbanChange(e) {
    e.target.value = e.target.value.replaceAll(' ', '');

    for (let i = 0; i < e.target.value.length; i++) {
      const alphaNumericRegex = /^[a-zA-Z0-9]+$/i;
      if (!alphaNumericRegex.test(e.target.value[i])) {
        e.target.value = e.target.value.slice(0, i) + e.target.value.slice(i + 1);
      }
      if ((i + 1) % 5 === 0) {
        e.target.value = e.target.value.slice(0, i) + ' ' + e.target.value.slice(i);
      }
    }

    setValue('iban', e.target.value);
    clearErrors('iban');
  }

  function handleNormaNoChange(e) {
    const spaces = [3, 7, 10];
    e.target.value = e.target.value.replaceAll(' ', '');

    for (let i = 0; i < e.target.value.length; i++) {
      if (spaces.includes(i)) {
        e.target.value = e.target.value.slice(0, i) + ' ' + e.target.value.slice(i);
      }
    }

    setValue('walletNumber', e.target.value);
    clearErrors('walletNumber');
  }

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-[18px] text-gray-600 font-semibold">Hesap Bilgileri</span>
      <CustomInput
        label="IBAN"
        isOptional
        name="iban"
        onChange={handleIbanChange}
        value={watch('iban')}
        error={errors?.iban?.message}
        maxLength={32}
        data-testid="input_iban"
      />
      <CustomInput
        label="Norma No"
        isOptional
        name="walletNumber"
        onChange={handleNormaNoChange}
        value={watch('walletNumber')}
        error={errors?.walletNumber?.message}
        only="number"
        maxLength={13}
        data-testid="input_account_number"
      />
    </div>
  );
};

export default AccountInformation;
