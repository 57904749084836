const initialState = {
  content: [],
  empty: false,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  size: 10,
  sort: { sorted: true, unsorted: false, empty: false },
  totalElements: 0,
  totalPages: 0
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DOCUMENTS':
      return {
        ...state,
        ...action.data
      };
    case 'SET_COMPANY_DOCUMENTS':
      return {
        ...state,
        ...action.data
      };
    case 'SET_COMPANY_TAX_DECLARATION_DOCUMENTS':
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default documentsReducer;
