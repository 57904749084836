import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useModal from 'utils/hooks/useModal';
// import COLORS from 'utils/colors';

import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';

import { getCategories } from 'store/actions/categories';

import { CustomButton, AddCategoryModal } from 'components';
import EditCategoryModal from './components/EditCategoryModal';
import CategoriesTable from './components/CategoriesTable';
import useDebounce from 'utils/hooks/useDebounce';
import useDengage from 'utils/hooks/useDengage';
import { useRef } from 'react';

const Categories = () => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const { categories, totalElements } = useSelector((state) => state.categories);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [editCategoryModalActive, setEditCategoryModalActive] = useState(false);

  const [size, setSize] = useState(10);

  const [isAddCategoryModalActive, , toggleAddCategoryModal] = useModal({
    shouldBeBlockScroll: true
  });

  const searchInputRef = useRef(null);

  useDebounce(searchTerm, 500, searchInputRef, () => {
    getCategoriesBy();
  });

  function getCategoriesBy() {
    dispatch(getCategories({ name: searchTerm, size, orderBy: 'createDate' }));
  }

  useEffect(() => getCategoriesBy(), [editCategoryModalActive, isAddCategoryModalActive, size]);

  function toggleEditCategoryModal(cId) {
    if (cId && !editCategoryModalActive)
      setSelectedCategory(categories.find((el) => el.id === cId));
    setEditCategoryModalActive(!editCategoryModalActive);
  }

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_category' });
  }, []);

  return (
    <div className="flex flex-col">
      {isAddCategoryModalActive && <AddCategoryModal handleClose={toggleAddCategoryModal} />}
      {editCategoryModalActive && (
        <EditCategoryModal category={selectedCategory} handleClose={toggleEditCategoryModal} />
      )}
      <div className="flex flex-col gap-y-4">
        <span className="font-semibold text-gray-900 text-xl">Kategoriler ({totalElements})</span>
        <div className="flex items-center justify-between gap-x-4">
          <div className="bg-gray-100 w-[280px] rounded-lg p-3 flex items-center">
            <IconSearch
              className="fill-current w-4 text-gray-400"
              data-testid="categories-search-icon"
            />
            <input
              className="ml-2 bg-transparent focus:outline-none w-full"
              placeholder="Arama"
              onChange={(e) => setSearchTerm(e.target.value)}
              data-testid="input_search"
              ref={searchInputRef}
            />
          </div>
          <CustomButton
            color="success"
            onClick={toggleAddCategoryModal}
            data-testid="button_toggle_modal"
          >
            <div className="flex items-center gap-x-2">
              <IconPlus className="stroke-current w-4" />
              <span>Kategori Ekle</span>
            </div>
          </CustomButton>
        </div>
      </div>
      <div className="flex flex-col gap-y-6 mt-6">
        <CategoriesTable
          categories={categories}
          toggleEditCategoryModal={toggleEditCategoryModal}
        />
        {size < totalElements && (
          <div className="flex items-start justify-center">
            <CustomButton
              onClick={() => setSize((prevSize) => prevSize + 10)}
              data-testid="button_add_more_categories"
            >
              Daha Fazla Göster
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
