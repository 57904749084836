import { cardTypes } from 'store/types';

const {
  CHECK_PREPAID_CARD,
  CHECK_PREPAID_CARD_SUCCESS,
  CHECK_PREPAID_CARD_FAILURE,
  CHECK_PREPAID_CARD_PIN,
  CHECK_PREPAID_CARD_PIN_SUCCESS,
  CHECK_PREPAID_CARD_PIN_FAILURE,
  UPDATE_PREPAID_CARD_PIN,
  UPDATE_PREPAID_CARD_PIN_SUCCESS,
  UPDATE_PREPAID_CARD_PIN_FAILURE,
  ACTIVATE_PREPAID_CARD,
  ACTIVATE_PREPAID_CARD_SUCCESS,
  ACTIVATE_PREPAID_CARD_FAILURE
} = cardTypes;

const initialState = {
  loading: false,
  data: null,
  error: null,
  checkCardData: null,
  checkCardPinData: null,
  updateCardData: null
};

export default function WalletReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_PREPAID_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CHECK_PREPAID_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        checkCardData: action.payload,
        error: null
      };
    case CHECK_PREPAID_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CHECK_PREPAID_CARD_PIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CHECK_PREPAID_CARD_PIN_SUCCESS:
      return {
        ...state,
        loading: false,
        checkCardPinData: action.payload,
        error: null
      };
    case CHECK_PREPAID_CARD_PIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_PREPAID_CARD_PIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PREPAID_CARD_PIN_SUCCESS:
      return {
        ...state,
        loading: false,
        updateCardData: action.payload,
        error: null
      };
    case UPDATE_PREPAID_CARD_PIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ACTIVATE_PREPAID_CARD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ACTIVATE_PREPAID_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        activateCard: action.payload,
        error: null
      };
    case ACTIVATE_PREPAID_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
