import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { BottomModal, CustomButton, CustomInput, Select } from 'components';
import { getCities, getCountries, getDistrict } from 'store/actions/country';
import { createCardOrder } from 'store/actions/card';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';

import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import CardCorrect from 'assets/illustrations/card/card-correct.png';

const StepHeader = ({ title, subtitle }) => (
  <div className="flex flex-col">
    <span className="text-gray-400 text-sm font-semibold">{title}</span>
    <span className="text-gray-900 text-xl font-semibold">{subtitle}</span>
  </div>
);

const Step0 = ({ watch, setValue }) => {
  const dispatch = useDispatch();

  const { cities, districts, countries } = useSelector((state) => state.country);
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'card_order' }), []);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCities({ country: 'TR' }));
  }, []);

  function _getDistrict(cId) {
    dispatch(getDistrict({ cityId: cId }));
  }

  function handleCity(cId) {
    setValue('cityId', cId);
    setValue('districtId', null);
    if (!Object.keys(districts).includes(cId)) _getDistrict(cId);
  }
  const filteredDistricts =
    watch('cityId') && districts[watch('cityId')] ? districts[watch('cityId')] : [];

  return (
    <div className="flex flex-col" data-testid="section_get_card_modal">
      <StepHeader
        title="Norma Kart"
        subtitle="Yeni Kart İste"
        data-testid="section_get_card_modal_header"
      />
      <div className="h-[600px] overflow-y-scroll">
        <div className="w-96 flex flex-col gap-y-4 mt-8">
          <Select
            options={countries}
            value="id"
            valueLabel="name"
            label="Ülke"
            defaultValue={1}
            data-testid="input_get_card_modal_country"
            disabled
          />
          <Select
            options={cities}
            value="id"
            valueLabel="name"
            label="İl"
            onChange={handleCity}
            data-testid="input_get_card_modal_city"
          />
          <Select
            options={filteredDistricts}
            value="id"
            valueLabel="name"
            label="İlçe"
            onChange={(dId) => setValue('districtId', dId)}
            data-testid="input_get_card_modal_district"
          />
          <CustomInput
            label="Adres"
            onChange={(e) => setValue('address1', e.target.value)}
            data-testid="input_get_card_modal_address"
          />
          <CustomInput
            label="Adres 2"
            onChange={(e) => setValue('address2', e.target.value)}
            data-testid="input_get_card_modal_address2"
          />
          <CustomButton
            color="success"
            type="submit"
            disabled={!watch('cityId') || !watch('districtId')}
            data-testid="button_get_card_modal_submit"
          >
            Kart İste
          </CustomButton>
        </div>
        <div
          className="w-96 bg-gray-100 border border-solid border-gray-200 rounded-lg mt-6 p-3 flex items-start justify-center gap-x-3"
          data-testid="section_get_card_modal_footer"
        >
          <IconInfoCircle className="fill-current w-6 text-gray-400 mt-4" />
          <span className="text-gray-500 break-words w-4/5">
            Yeni Norma Kart isteğiniz belirteceğiniz adrese ücretsiz olarak gönderilecektir.
            Lütfen adresinizi eksiksiz girdiğinizden emin olun.
          </span>
        </div>
      </div>
    </div>
  );
};

const Step1 = ({ handleClose }) => {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'card_order_success' });
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'card_order_button'
    });
  }, []);

  return (
    <div
      className="w-full h-full flex items-center justify-center"
      data-testid="section_get_card_modal_success"
    >
      <div className="w-96 text-center flex flex-col items-center">
        <img
          src={CardCorrect}
          alt="card-correct"
          width={200}
          data-testid="section_get_card_modal_success_image"
        />
        <span
          className="text-2xl text-white mt-8"
          data-testid="section_get_card_modal_success_title"
        >
          Yeni kart isteğiniz başarıyla alındı
        </span>
        <span className="text-white mt-2" data-testid="section_get_card_modal_success_subtitle">
          Norma Kart elinize ulaştığında kart bilgilerini girerek kartınızı
          aktifleştirebilirsiniz.
        </span>
        <CustomButton
          color="success"
          onClick={handleClose}
          className="w-full mt-6"
          data-testid="button_get_card_modal_success_close"
        >
          Kapat
        </CustomButton>
      </div>
    </div>
  );
};

const GetCardModal = ({ toggleGetCardModal }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  function nextStep() {
    setStep(step + 1);
  }

  const defaultValues = {
    address1: null,
    address2: null,
    cityId: null,
    countryId: 1,
    districtId: null,
    companyId: null
  };

  const { watch, setValue, handleSubmit } = useForm({ defaultValues });
  function onSubmit(data) {
    data = {
      ...data,
      address: `${watch('address1') || ''}${watch('address1') && watch('address2') ? ' ' : ''}${
        watch('address2') || ''
      }`
    };
    dispatch(createCardOrder(data)).then(() => {
      nextStep();
    });
  }

  const TABS = {
    0: <Step0 watch={watch} setValue={setValue} />,
    1: <Step1 handleClose={toggleGetCardModal} />
  };

  return (
    <BottomModal handleClose={toggleGetCardModal} className={step === 1 && 'bg-green-500'}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form_get_card_modal">
        <div className="w-full h-full flex items-center justify-center">{TABS[step]}</div>
      </form>
    </BottomModal>
  );
};

export default GetCardModal;
