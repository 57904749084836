import { openBankingTypes } from 'store/types';

const {
  GET_OPEN_BANKING_BANKS,
  GET_OPEN_BANKING_BANKS_FAILURE,
  GET_OPEN_BANKING_BANKS_SUCCESS,
  GET_OPEN_BANKING_BANK_ACCOUNTS,
  GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE,
  GET_OPEN_BANKING_BANK_ACCOUNTS_SUCCESS,
  GET_OPEN_BANKING_BANK_INFORMATIONS,
  GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE,
  GET_OPEN_BANKING_BANK_INFORMATIONS_SUCCESS,
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS,
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_FAILURE,
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_SUCCESS,
  ADD_OPEN_BANKING_BANK,
  ADD_OPEN_BANKING_BANK_SUCCESS,
  ADD_OPEN_BANKING_BANK_FAILURE,
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT,
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_SUCCESS,
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_FAILURE,
  UPDATE_BANK_ACCOUNT_SUCCESS
} = openBankingTypes;

const initialState = {
  loading: false,
  banks: [],
  addBank: null,
  addManuallyBankAccount: null,
  updatedBankAccount: null,
  bankInformations: [],
  bankAccounts: [],
  bankRequiredFields: {
    bankRequiredFieldMap: null,
    bankAccountRequiredFieldMap: null
  },
  error: null
};

export default function OpenBankingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OPEN_BANKING_BANKS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_OPEN_BANKING_BANKS_SUCCESS:
      return {
        ...state,
        loading: false,
        banks: action.payload,
        error: null
      };
    case GET_OPEN_BANKING_BANKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_OPEN_BANKING_BANK_INFORMATIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_OPEN_BANKING_BANK_INFORMATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        bankInformations: action.payload,
        error: null
      };
    case GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_OPEN_BANKING_BANK_ACCOUNTS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_OPEN_BANKING_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccounts: action.payload,
        error: null
      };
    case GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_OPEN_BANKING_BANK_REQUIRED_FIELDS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        bankRequiredFields: action.payload,
        error: null
      };
    case GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_OPEN_BANKING_BANK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_OPEN_BANKING_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        addBank: action.payload,
        error: null
      };
    case ADD_OPEN_BANKING_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        addManuallyBankAccount: action.payload,
        error: null
      };
    case ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        addManuallyBankAccount: action.payload,
        error: null
      };
    case UPDATE_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
