import moment from 'moment';

export const isLoggedIn = () => !!localStorage.getItem('token');

export const currencyFormat = (amount, minimumFractionDigits = 2) => {
  return new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits
  })
    .format(amount || 0)
    .replace('₺', '');
};

export const MONTHS = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık'
];

export const DAYS = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];

export const dateFormat = (date, symbol = '.') => {
  if (!(date instanceof Date)) return '';
  //  if (!date instanceof Date) return '';

  return (
    date.getDate().toString().padStart(2, '0') +
    symbol +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    symbol +
    date.getFullYear()
  );
};

export const dateFormatV2 = (date) => {
  if (!(date instanceof Date)) return '';
  //if (!date instanceof Date) return '';

  return (
    date.getDate().toString().padStart(2, '0') +
    ' ' +
    MONTHS[date.getMonth()] +
    ', ' +
    date.getHours().toString().padStart(2, '0') +
    ':' +
    date.getMinutes().toString().padStart(2, '0')
  );
};

export const TRANSACTION_TYPES = {
  TRANSFER_ACCOUNT: { key: 'TRANSFER_ACCOUNT', value: 'Kendi Norma hesabına' },
  TRANSFER_PEER: { key: 'TRANSFER_PEER', value: 'Norma walletlar arası' },
  TRANSFER_FEE: { key: 'TRANSFER_FEE', value: 'Transafer işlem ücreti' },
  TRANSFER_WALLET_CLOSE: {
    key: 'TRANSFER_WALLET_CLOSE',
    value: 'Wallet kapatıldığında bakiye aktarılması'
  },
  WITHDRAWAL_TRANSFER: { key: 'WITHDRAWAL_TRANSFER', value: 'Norma dışına para transferi' },
  WITHDRAWAL_CASH: { key: 'WITHDRAWAL_CASH', value: 'ATM para çekme' },
  WITHDRAWAL_FEE: { key: 'WITHDRAWAL_FEE', value: 'Para çekme işlem ücreti' },
  DEPOSIT_EFT: { key: 'DEPOSIT_EFT', value: 'IBAN ile para yatırma (sipay)' },
  DEPOSIT_TRANSFER: {
    key: 'DEPOSIT_TRANSFER',
    value: 'Norma dışından gelen transfer (virtual iban)'
  },
  DEPOSIT_CASH: { key: 'DEPOSIT_CASH', value: 'ATM/Nakit para yatırma' },
  DEPOSIT_FEE: { key: 'DEPOSIT_FEE', value: 'Para yatırma işlem ücreti' },
  EXCHANGE_BUY: { key: 'EXCHANGE_BUY', value: 'Döviz satın alma işlemi' },
  EXCHANGE_SELL: { key: 'EXCHANGE_SELL', value: 'EXCHANGE_SELL' },
  EXCHANGE_TRANSFER: { key: 'EXCHANGE_TRANSFER', value: 'Döviz transferi' },
  EXCHANGE_FEE: { key: 'EXCHANGE_FEE', value: 'Döviz işlem ücreti' },
  PAYMENT_NORMA: { key: 'PAYMENT_NORMA', value: "Norma'ya ödeme" },
  PAYMENT_OTHER: { key: 'PAYMENT_OTHER', value: 'Diğer ödeme' },
  PAYMENT_CARD: { key: 'PAYMENT_CARD', value: 'Kart ile ödeme' },
  PAYMENT_FEE: { key: 'PAYMENT_FEE', value: 'Ödeme işlem ücreti' },
  REFUND: { key: 'REFUND', value: 'İade' },
  GET_TRANSACTION_TYPE: function (type) {
    let transactionType = '';

    switch (type) {
      case this.TRANSFER_ACCOUNT.key:
      case this.TRANSFER_PEER.key:
      case this.TRANSFER_FEE.key:
      case this.TRANSFER_WALLET_CLOSE.key:
      case this.WITHDRAWAL_TRANSFER.key:
        return (transactionType = 'Giden Para Transferi');
      case this.WITHDRAWAL_CASH.key:
      case this.DEPOSIT_CASH.key:
        return (transactionType = 'ATM İşlemleri');
      case this.WITHDRAWAL_FEE.key:
      case this.EXCHANGE_FEE.key:
      case this.PAYMENT_NORMA.key:
      case this.PAYMENT_FEE.key:
      case this.PAYMENT_OTHER.key:
        return (transactionType = 'İşlem Ücretleri');
      case this.DEPOSIT_EFT.key:
      case this.DEPOSIT_TRANSFER.key:
      case this.DEPOSIT_FEE.key:
        return (transactionType = 'Gelen Para Transferi');
      case this.EXCHANGE_BUY.key:
      case this.EXCHANGE_SELL.key:
      case this.EXCHANGE_TRANSFER.key:
        return (transactionType = 'Döviz İşlemleri');
      case this.PAYMENT_CARD.key:
      case this.REFUND.key:
        return (transactionType = 'Kart İşlemleri');
    }

    return transactionType;
  }
};

// const GET_TRANSACTION_TYPE = (type) => {
//   let transactionType = '';
//   switch (type) {
//     case (TRANSFER_ACCOUNT,
//     TRANSFER_PEER,
//     TRANSFER_FEE,
//     TRANSFER_WALLET_CLOSE,
//     WITHDRAWAL_TRANSFER):
//       return (transactionType = 'Giden Para Transferi');
//     case (WITHDRAWAL_CASH, DEPOSIT_CASH):
//       return (transactionType = 'ATM İşlemleri');
//     case (WITHDRAWAL_FEE, EXCHANGE_FEE, PAYMENT_NORMA, PAYMENT_FEE, PAYMENT_OTHER):
//       return (transactionType = 'İşlem Ücretleri');
//     case (DEPOSIT_EFT, DEPOSIT_TRANSFER, DEPOSIT_FEE):
//       return (transactionType = 'Gelen Para Transferi');
//     case (EXCHANGE_BUY, EXCHANGE_SELL, EXCHANGE_TRANSFER):
//       return (transactionType = 'Döviz İşlemleri');
//     case (PAYMENT_CARD, REFUND):
//       return (transactionType = 'Kart İşlemleri');
//   }
//   return transactionType;
// };

export const copyToClipboard = (textToCopy) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
};

export const checkIdentityNumber = (id) => {
  // https://www.yusufsezer.com.tr/javascript-tc-kimlik-no-dogrulama/
  const TCNo = id.split('').map((el) => parseInt(el));

  // id length must be 11
  if (id.length !== 11) return false;

  // first digit can not be 0
  if (TCNo[0] === 0) return false;

  // ( [1] + [3] + [5] + [7] + [9] ) * 7 - ( [2] + [4] + [6] + [8] ) = [10]
  if (
    ((TCNo[0] + TCNo[2] + TCNo[4] + TCNo[6] + TCNo[8]) * 7 -
      (TCNo[1] + TCNo[3] + TCNo[5] + TCNo[7])) %
      10 !==
    TCNo[9]
  )
    return false;

  const firstTenDigitsSum =
    TCNo[0] +
    TCNo[1] +
    TCNo[2] +
    TCNo[3] +
    TCNo[4] +
    TCNo[5] +
    TCNo[6] +
    TCNo[7] +
    TCNo[8] +
    TCNo[9];

  // first ten digits remainder from division by 10 is must be equal last digit
  if (firstTenDigitsSum % 10 !== TCNo[10]) return false;

  return true;
};

export const calculateAge = (birthday) => {
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const ibanRegex = new RegExp(
  /TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?/
);

export const timeFormat = (s) => {
  const minutes = parseInt(s / 60);
  const seconds = parseInt(s % 60);

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const OTP_TIMEOUT = 120;

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID'
};

export const PAYMENT_STATUS_TEXT = {
  PAID: 'Ödendi',
  NOT_PAID: 'Ödenecek'
};

export const COMPANY_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  BUSINESS: 'BUSINESS',
  CORPORATE: 'CORPORATE',
  PERSONAL: 'PERSONAL'
};

export function filterKeys(
  obj,
  keys,
  options = { returnSelectedKeys: false, filterEmpty: null, filterNull: null }
) {
  if (options) {
    let object = obj;
    if (options?.filterNull) {
      object = Object.keys(object).reduce((acc, key) => {
        if (obj[key] !== null) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    }
    if (options?.filterEmpty) {
      object = Object.keys(object).reduce((acc, key) => {
        if (obj[key] !== '') {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    }
    return filterGivenKeys(object, keys, options?.returnSelectedKeys);
  }

  return filterGivenKeys(obj, keys);
}

const filterGivenKeys = (obj, keys, returnSelectedKeys = false) => {
  if (returnSelectedKeys)
    return Object.keys({ ...obj })
      .filter((k) => keys?.includes(k))
      .map((k) => Object.assign({}, { [k]: obj[k] }))
      .reduce((res, o) => Object.assign(res, o), {});

  return Object.keys({ ...obj })
    .filter((k) => !keys?.includes(k))
    .map((k) => Object.assign({}, { [k]: obj[k] }))
    .reduce((res, o) => Object.assign(res, o), {});
};

export const E_TRANSFORMATION_TYPES = {
  E_INVOICE: 'E_INVOICE',
  E_RECEIPT: 'E_RECEIPT',
  SELF_CONTROL: 'SELF_CONTROL'
};

//DOCUMENTS
export const DOCUMENT_TYPES = {
  COMPANY_DOCUMENT: 'COMPANY_DOCUMENT',
  COMPANY_TAX_DECLARATION_DOCUMENT: 'COMPANY_TAX_DECLARATION_DOCUMENT'
};
export const DOCUMENT_STATUS = {
  PAID: { key: 'PAID', text: 'Ödendi', color: '#DEF0A8', textColor: '#3F6600' },
  WAITING_APPROVAL: {
    key: 'WAITING_APPROVAL',
    text: 'Onay Bekliyor',
    color: '#FFE8CC',
    textColor: '#831802'
  },
  WAITING_FOR_RECEIPT_NUMBER: {
    key: 'WAITING_FOR_RECEIPT_NUMBER',
    text: 'Tahakkuk Fişi Bekliyor',
    color: '#E0E0E0',
    textColor: '#333333'
  },
  WAITING_FOR_PAID: {
    key: 'WAITING_FOR_PAID',
    text: 'Ödeme Bilgisi Bekliyor',
    color: '#D9CFFE',
    textColor: '#4C1D95'
  },
  SEND_BACK: {
    key: 'SEND_BACK',
    text: 'Geri Gönderildi',
    color: '#FFCCCC',
    textColor: '#831802'
  },
  PASSIVE: { key: 'PASSIVE', text: 'Pasif', color: '' },
  REJECTED: { key: 'REJECTED', text: 'Reddedildi', color: '' },
  DELETED: { key: 'DELETED', text: 'Silindi', color: '' },
  ACTIVE: { key: 'ACTIVE', text: 'Aktif', color: '' }
};

export const USER_COMPANY_STATUS = {
  ACTIVE: 'ACTIVE',
  PASSIVE: 'PASSIVE',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  APPROVED: 'APPROVED'
};

export const KYC_STATUS = {
  CREATED: 'CREATED',
  INCOMPLETE: 'INCOMPLETE',
  PENDING_REVIEW: 'PENDING_REVIEW',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_UPLOAD: 'PENDING_UPLOAD',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED'
};

/**
 *
 * @param {string} date
 * @param {string} locale
 * @returns 01 Jan 2022
 * @description
 * Formating date to locale format with short month name by locale parameter
 */
export const getFormattedDate = (date, locale = 'tr') => {
  const d = new Date(date);
  return `${d.getDate()} ${d.toLocaleString(locale, {
    month: 'short'
  })} ${d.getFullYear()}`;
};

export const getFormattedDateForPayload = (date, locale = 'tr') => {
  if (isNaN(Date.parse(date))) {
    // return;
    throw 'Date is required';
  }
  return moment(date).locale(locale).format('YYYY-MM-DDTHH:mm:ssZZ');
};

export const getFormattedISODateForPayload = (date, locale = 'tr') => {
  if (isNaN(Date.parse(date))) {
    // return;
    throw 'Date is required';
  }
  return moment(date).locale(locale).format('yyyy-MM-DDTHH:mm:ss.SSSZ');
};

export const getFormattedDateWithSeperator = (date, locale = 'tr', seperator = '/') => {
  if (!date) {
    return '';
  }

  const formatedDate = new Date(date).toLocaleDateString(locale).replace(/\./g, seperator);

  if (formatedDate && formatedDate !== 'Invalid Date') {
    return formatedDate;
  }

  return '';
};

export const theme = {
  sizes: {
    sm: 'sm',
    md: 'md',
    lg: 'lg'
  },
  button: {
    variants: {
      circle: 'circle',
      ghost: 'ghost',
      tab: 'tab',
      default: 'default'
    }
  }
};

export const downloadFileByUrl = async (url, name) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_blank';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export function downloadCsv(file, fileName = 'file') {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
}

export function downloadFile(file, fileType, fileName = 'ana hesap') {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  const now = new Date();
  const createdFileName = `${fileName}-${now.getDate()}.${
    now.getMonth() + 1
  }.${now.getFullYear()}`;
  link.href = url;
  link.setAttribute('download', `${createdFileName}.${fileType?.toLowerCase()}`);
  link.click();
}
export const MAX_DOCUMENT_NAME_LENGTH = 40;

export const COMPANY_SUB_TYPES = {
  FREE_LANCER: 'FREE_LANCER',
  SOLE_TRADER: 'SOLE_TRADER'
};

export const ONBOARDING_COMPANY_FLOW_TYPE = {
  ADD: 'ADD',
  START: 'START'
};

export const CHART_TYPES = {
  LINE: 'LINE',
  BAR: 'BAR',
  PIE: 'PIE',
  DOUGHNUT: 'DOUGHNUT'
};

export function getWeekDays(locale = 'tr', options = { weekday: 'long', until: 7 }) {
  let baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  let weekDays = [];
  for (let i = 0; i < options?.until; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, options));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
}

export function getMonths(locale, options = { month: 'long', until: 12 }) {
  let baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  let monthNames = [];
  for (let i = 0; i < options?.until; i++) {
    monthNames.push(baseDate.toLocaleDateString(locale, options));
    baseDate.setMonth(i + 1);
  }
  return monthNames;
}

export const CHART_FILTER_OPTIONS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

export const PREPAID_CARD_TYPES = {
  VIRTUAL: 'VIRTUAL',
  PHYSICAL: 'PHYSICAL'
};

export const EXCHANGE_TYPES = {
  BUY: 'BUY',
  SELL: 'SELL'
};

export function isValidVKN(kno) {
  let v1 = 0;
  let v2 = 0;
  let v3 = 0;
  let v4 = 0;
  let v5 = 0;
  let v6 = 0;
  let v7 = 0;
  let v8 = 0;
  let v9 = 0;
  let v11 = 0;
  let v22 = 0;
  let v33 = 0;
  let v44 = 0;
  let v55 = 0;
  let v66 = 0;
  let v77 = 0;
  let v88 = 0;
  let v99 = 0;
  let v_last_digit = 0;
  let total = 0;

  // const numberExp = /[0-9]{10}/;

  if (kno.length == 10) {
    v1 = (Number(kno.charAt(0)) + 9) % 10;
    v2 = (Number(kno.charAt(1)) + 8) % 10;
    v3 = (Number(kno.charAt(2)) + 7) % 10;
    v4 = (Number(kno.charAt(3)) + 6) % 10;
    v5 = (Number(kno.charAt(4)) + 5) % 10;
    v6 = (Number(kno.charAt(5)) + 4) % 10;
    v7 = (Number(kno.charAt(6)) + 3) % 10;
    v8 = (Number(kno.charAt(7)) + 2) % 10;
    v9 = (Number(kno.charAt(8)) + 1) % 10;
    v_last_digit = Number(kno.charAt(9));

    v11 = (v1 * 512) % 9;
    v22 = (v2 * 256) % 9;
    v33 = (v3 * 128) % 9;
    v44 = (v4 * 64) % 9;
    v55 = (v5 * 32) % 9;
    v66 = (v6 * 16) % 9;
    v77 = (v7 * 8) % 9;
    v88 = (v8 * 4) % 9;
    v99 = (v9 * 2) % 9;

    if (v1 != 0 && v11 == 0) v11 = 9;
    if (v2 != 0 && v22 == 0) v22 = 9;
    if (v3 != 0 && v33 == 0) v33 = 9;
    if (v4 != 0 && v44 == 0) v44 = 9;
    if (v5 != 0 && v55 == 0) v55 = 9;
    if (v6 != 0 && v66 == 0) v66 = 9;
    if (v7 != 0 && v77 == 0) v77 = 9;
    if (v8 != 0 && v88 == 0) v88 = 9;
    if (v9 != 0 && v99 == 0) v99 = 9;
    total = v11 + v22 + v33 + v44 + v55 + v66 + v77 + v88 + v99;

    if (total % 10 == 0) total = 0;
    else total = 10 - (total % 10);

    if (total == v_last_digit) {
      return true;
    } else return false;
  } else return false;
}

export const BILL_STATUSES = {
  LATE: 'LATE',
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID'
};

export const PIN_PASSWORD_LENGTH = 4;

export const CARD_ACTIVATE_STATUS = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE'
};

export const BUSINESS_TYPES = {
  NONE: { label: '', value: null },
  CUSTOMER: { label: 'Müşteri', value: 'CUSTOMER' },
  SUPPLIER: { label: 'Tedarikçi', value: 'SUPPLIER' },
  CUSTOMER_SUPPLIER: { label: 'Müşteri/Tedarikçi', value: 'CUSTOMER_SUPPLIER' }
};

export const DEBT_STATUS = {
  // EMPTY: { label: '', value: 'EMPTY' },
  NONE: { label: 'Borç / Alacak Yok', value: 'NONE' },
  DEBT: { label: 'Borcum Var', value: 'DEBT' },
  CREDIT: { label: 'Alacağım Var', value: 'CREDIT' }
};

export const AUTOMATIC_TRANSACTION_RECEIVER_TYPES = {
  CONTACT: 'CONTACT',
  NORMA_NO: 'NORMA_NO',
  IBAN: 'IBAN',
  OWN_ACCOUNT: 'OWN_ACCOUNT'
};

export const AUTOMATIC_TRANSACTION_REPEAT_TYPES = {
  ONCE: { label: 'Bir Seferlik', value: 'ONCE' },
  DAILY: { label: 'Günlük', value: 'DAILY' },
  WEEKLY: { label: 'Haftalık', value: 'WEEKLY' },
  MONTHLY: { label: 'Aylık', value: 'MONTHLY' }
};
export const AUTOMATIC_TRANSACTION_STATUS = {
  CREATED: 'CREATED',
  DELETED: 'DELETED',
  DONE: 'DONE',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  PASSIVE: 'PASSIVE'
};

export const getMaskedIban = (val) => {
  let value = val.replaceAll(' ', '');
  return `${value.substring(0, 4)} ${value.substring(4, 8)} ${value.substring(
    8,
    12
  )} ${value.substring(12, 16)} ${value.substring(16, 20)} ${value.substring(
    20,
    24
  )} ${value.substring(24, 28)} ${value.substring(28, 32)}`;
};

export const getMaskedNormaNo = (val) => {
  if (!val) return val;

  let value = val?.toString().replaceAll(' ', '');
  return `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(
    6,
    8
  )} ${value.substring(8, 10)}`;
};

export const LOCAL_STORAGE_PARAMS = {
  NORMA_LANG: 'NORMA_LANG'
};

export const PRICE_TYPES = [
  {
    id: 'NET',
    name: 'Net'
  },
  {
    id: 'BRUT',
    name: 'Brüt'
  },
  {
    id: 'TOTAL',
    name: 'KDV Dahil Tahsil Edilecek Tutar'
  }
];
export const VATS = [
  {
    id: 1,
    name: '%1'
  },
  {
    id: 8,
    name: '%8'
  },
  {
    id: 10,
    name: '%10'
  },
  {
    id: 18,
    name: '%18'
  },
  {
    id: 20,
    name: '%20'
  }
];
