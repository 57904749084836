import { CustomInput, PhoneInput } from 'components';
import { useContactContext } from 'contexts/contactContext';
import { useState } from 'react';
import { emailRegex } from 'utils';

const CompanyContactInformation = ({ errors, onlyRequired = false }) => {
  const { formValues, setFormValues } = useContactContext();
  const [hasMailError, setHasMailError] = useState(null);

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const onChangePhone = (formattedInput, phoneInput) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      fullPhoneNumber: formattedInput || phoneInput
    }));
  };

  const onChangeMail = (e) => {
    const { value } = e.target;
    setHasMailError(() => !emailRegex.test(value));
    onChange(e);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-[18px] text-gray-600 font-semibold">Yetkili Kişi Bilgileri</span>
      {!onlyRequired && (
        <>
          <CustomInput
            label="İsim"
            name="firstName"
            onChange={onChange}
            isOptional
            only="text"
            maxLength={140}
            value={formValues?.firstName}
            error={errors?.firstName?.message}
            data-testid="input_first_name"
          />
          <CustomInput
            label="Soyisim"
            name="lastName"
            onChange={onChange}
            isOptional
            value={formValues?.lastName}
            only="text"
            maxLength={140}
            error={errors?.lastName?.message}
            data-testid="input_last_name"
          />
        </>
      )}
      <CustomInput
        label="E-Posta"
        name="email"
        onChange={onChangeMail}
        isOptional={!onlyRequired}
        maxLength={140}
        value={formValues?.email}
        error={hasMailError && 'Hatalı E-Posta Adresi'}
        data-testid="input_email"
      />

      {!onlyRequired && (
        <PhoneInput
          onChange={onChangePhone}
          name={'fullPhoneNumber'}
          isOptional
          showCountryCode={false}
          defaultValue={formValues?.fullPhoneNumber}
          error={errors?.fullPhoneNumber?.message}
          justCellPhone={false}
          data-testid="input_phone"
        />
      )}
    </div>
  );
};

export default CompanyContactInformation;
