import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CategoryBadge, CustomButton, Tag } from 'components';
import UploadDocumentPopup from './UploadDocumentPopup';

import { COMPANY_TYPES, currencyFormat } from 'utils';
import { PAGE_NAMES, CURRENCY_NAMES, TRANSFORMATION_TYPES } from './enums';
import useModal from 'utils/hooks/useModal';

import { getSaleExpenseById, updateSaleExpense } from 'store/actions/invoice';

import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { Prompt } from 'components/promptModal';
import UpdateContactModal from 'views/other/contacts/components/updateContactModal';
import { useDispatch } from 'react-redux';

const DetailSale = ({ setPage, salesExpenseRecordId, handleClose }) => {
  const [file, setFile] = useState();
  const taxOffices = useSelector((state) => state.taxOffice);
  const { etransformationType } = useSelector((state) => state.auth);
  const { salesDetail: transaction } = useSelector((state) => state.invoice);
  const [calculatedTransaction, setCalculatedTransaction] = useState(transaction);
  const dispatch = useDispatch();

  const [uploadDocumentPopup, , toggleUploadDocumentPopup] = useModal();
  const [isMissingContactInformationPopupActive, , toggleMissingContactInformationPopup] =
    useModal();
  const [isUpdateContactSideModalActive, , toggleUpdateContactSideModal] = useModal();

  useEffect(() => {
    if (transaction) {
      const currency = transaction?.currency;
      setCalculatedTransaction({
        ...transaction,
        totalAmount:
          currency === 'TRY'
            ? transaction?.totalAmount
            : transaction?.totalAmount * transaction?.currencyRate,
        taxExcludedAmount:
          currency === 'TRY'
            ? transaction?.taxExcludedAmount
            : transaction?.taxExcludedAmount * transaction?.currencyRate,
        totalTaxAmount:
          currency === 'TRY'
            ? transaction?.totalTaxAmount
            : transaction?.totalTaxAmount * transaction?.currencyRate,
        salesExpenseItems: transaction?.salesExpenseItems.map((item) => {
          return {
            ...item,
            currency: item.currency,
            totalAmount:
              item?.currency === 'TRY'
                ? item?.totalAmount
                : item?.totalAmount / transaction?.currencyRate,
            taxExcludedAmount:
              item?.currency === 'TRY'
                ? item?.taxExcludedAmount
                : item?.taxExcludedAmount / transaction?.currencyRate,
            totalTaxAmount:
              item?.currency === 'TRY'
                ? item?.totalTaxAmount
                : item?.totalTaxAmount / transaction?.currencyRate,
            unitPrice:
              item?.currency === 'TRY'
                ? item?.unitPrice
                : item?.unitPrice / transaction?.currencyRate,
            withholdingTaxAmount:
              item?.currency === 'TRY'
                ? item?.withholdingTaxAmount
                : item?.withholdingTaxAmount / transaction?.currencyRate
          };
        }),

        withHoldingTaxes: transaction?.withHoldingTaxes?.map((withHoldingTax) => ({
          ...withHoldingTax,
          amount:
            currency === 'TRY'
              ? withHoldingTax.amount
              : withHoldingTax.amount * transaction.currencyRate
        })),
        taxes: transaction?.taxes?.map((tax) => ({
          ...tax,
          amount: currency === 'TRY' ? tax?.amount : tax.amount * transaction.currencyRate
        }))
      });
    }
  }, [transaction]);

  async function uploadDocumentToSaleExpense() {
    if (!transaction?.companyId || !transaction?.id || !file.id) return;

    try {
      await updateSaleExpense({
        companyId: transaction?.companyId,
        salesExpenseRecordId: transaction?.id,
        invoiceFileIds: [file.id],

        contactId: transaction?.contact?.id,
        currency: transaction?.currency,
        currencyRate: transaction?.currencyRate,
        dueDate: transaction?.dueDate,
        items: transaction?.salesExpenseItems.map((item) => ({ ...item, id: item.itemId })),
        note: transaction?.note,
        paymentDate: transaction?.paymentDate,
        paymentMethod: 'NORMA',
        paymentStatus: transaction?.paymentStatus,
        recordType: transaction?.recordType,
        title: transaction?.title,
        totalAmount: transaction?.totalAmount,
        totalTaxAmount: transaction?.totalTaxAmount
      });

      handleClose();
    } catch (err) {
      console.log(err);
    }
  }

  const createDate = transaction?.createDate ? new Date(transaction?.createDate) : null;
  const formattedDate = createDate
    ? `${createDate.getDate().toString().padStart(2, '0')}/${(createDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${createDate.getFullYear()}`
    : '';

  if (!calculatedTransaction || !transaction) return <></>;

  const onCreateEDocument = () => {
    const contact = transaction?.contact;

    let contactHasMissingInformations = true;

    if (contact?.companyType === COMPANY_TYPES.INDIVIDUAL) {
      contactHasMissingInformations =
        !contact?.firstName ||
        !contact?.lastName ||
        !contact?.email ||
        !contact?.taxIdentityNumber ||
        !contact?.cityId ||
        !contact?.districtId;
    } else if (contact?.companyType === COMPANY_TYPES.BUSINESS) {
      contactHasMissingInformations =
        !contact?.companyName ||
        !contact?.email ||
        !contact?.taxIdentityNumber ||
        !contact?.cityId ||
        !contact?.districtId;
    }

    if (contactHasMissingInformations) {
      return toggleMissingContactInformationPopup();
    }
    setPage(PAGE_NAMES.EDOCUMENT);
  };

  const onApproveContactEdit = () => {
    toggleMissingContactInformationPopup();
    toggleUpdateContactSideModal();
  };

  const onUpdateContactModalClose = () => {
    dispatch(getSaleExpenseById({ salesExpenseRecordId }));
    toggleUpdateContactSideModal();
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-center h-full bg-gray-50 overflow-y-scroll">
        {isMissingContactInformationPopupActive && (
          <Prompt
            isActive={true}
            title={
              <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100 mx-auto">
                <InfoCircleIcon className="fill-current w-10 h-10 text-yellow-600" />
              </div>
            }
            description="VKN/TCKN, e-Posta, İl ve İlçe bilgisinden eksik olanı giriniz"
            okText="Müşteri/Tedarikçi Düzenle"
            cancelText="İptal Et"
            onOK={onApproveContactEdit}
            onCancel={toggleMissingContactInformationPopup}
          />
        )}
        {isUpdateContactSideModalActive && (
          <UpdateContactModal
            contactId={transaction?.contact?.id}
            onClose={onUpdateContactModalClose}
            onlyRequired={true}
          />
        )}
        <UploadDocumentPopup
          active={uploadDocumentPopup}
          toggle={toggleUploadDocumentPopup}
          setFile={setFile}
        />
        <div className="w-[640px] h-full flex flex-col py-10">
          <div className="flex flex-col">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-lg text-gray-900">Satış Kaydı Detayı</span>
              <div
                className="p-3 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
                onClick={() => setPage(PAGE_NAMES.EDIT)}
              >
                <PencilIcon className="fill-current w-6 text-gray-500" />
              </div>
            </div>
            <div className="flex justify-between items-start break-all gap-x-4">
              {transaction?.category && (
                <Tag
                  style={{ backgroundColor: transaction?.category?.colorHexCode }}
                  textColor={transaction?.category?.textColorHexCode}
                  size="sm"
                  leftIcon={<img className="w-4" src={transaction?.category?.iconUrl} />}
                >
                  {transaction?.category?.name}
                </Tag>
              )}
              <span className="text-sm font-medium text-gray-900 mt-1 break-normal">
                {formattedDate}
              </span>
            </div>
          </div>
          <div className="w-full rounded-t-lg shadow-lg bg-white p-10 mt-6 border-t border-r border-l border-solid border-gray-200">
            <div className="w-full pb-8 border-b border-solid border-gray-200 flex flex-col">
              <span className="text-sm font-semibold text-gray-900">Alıcı</span>
              {transaction?.contact?.companyName ? (
                <span className="text-sm font-semibold text-gray-500 mt-4">
                  {`${transaction?.contact?.companyName}`}
                </span>
              ) : (
                transaction?.contact?.firstName &&
                transaction?.contact?.lastName && (
                  <span className="text-sm font-semibold text-gray-500 mt-4">
                    {`${transaction?.contact?.firstName} ${transaction?.contact?.lastName}`}
                  </span>
                )
              )}
              {transaction?.contact?.address && (
                <span className="text-sm font-medium text-gray-500 mt-3 break-words">
                  {transaction?.contact?.address}
                </span>
              )}
              {taxOffices.find((t) => t.id === transaction?.contact?.taxOfficeId)?.name && (
                <span className="text-sm font-medium text-gray-500 mt-3">
                  {taxOffices.find((t) => t.id === transaction?.contact?.taxOfficeId)?.name}
                </span>
              )}
              {transaction?.contact?.email && (
                <span className="text-sm font-medium text-gray-500 mt-3">
                  {transaction?.contact?.email}
                </span>
              )}
            </div>
            <ItemsTable items={calculatedTransaction?.salesExpenseItems || []} />
          </div>
          <div className="w-full rounded-b-lg shadow-md bg-gray-50 px-8 py-7 border-b border-r border-l border-solid border-gray-200">
            <div className="flex justify-between items-center">
              <span className="text-sm font-semibold text-gray-900">Toplam Tutar</span>
              <span className="text-sm font-semibold text-gray-900">
                {`
                ${
                  !isNaN(calculatedTransaction?.totalAmount)
                    ? currencyFormat(calculatedTransaction?.taxExcludedAmount)
                    : '-'
                } TL
              `}
              </span>
            </div>
            <div className="flex justify-between items-center mt-4">
              <span className="text-sm font-semibold text-gray-900">Vergiler (TL)</span>
              {/* <span className="text-sm font-semibold text-gray-900">
                {!isNaN(calculatedTransaction?.totalTaxAmount)
                  ? currencyFormat(calculatedTransaction?.totalTaxAmount)
                  : '-'}{' '}
                TL
              </span> */}
            </div>
            {[...calculatedTransaction?.taxes, ...calculatedTransaction?.withHoldingTaxes]?.map(
              (tax) => (
                <div className="flex justify-between items-center mt-4 pl-6">
                  <span className="text-xs font-medium text-gray-500">
                    {tax?.name} (%{tax?.percent})
                  </span>
                  <span className="text-xs font-medium text-gray-500">
                    {currencyFormat(tax?.amount || 0)} TL
                  </span>
                </div>
              )
            )}
            <div className="flex justify-between items-center mt-5">
              <span className="text-sm font-semibold text-gray-900">Genel Toplam</span>
              <span className="text-sm font-semibold text-gray-900">
                {!isNaN(calculatedTransaction?.totalAmount)
                  ? currencyFormat(calculatedTransaction?.totalAmount)
                  : '-'}{' '}
                TL
              </span>
            </div>
          </div>
          {file ? (
            <FilePreview
              file={file}
              setFile={setFile}
              uploadDocumentToSaleExpense={uploadDocumentToSaleExpense}
            />
          ) : (
            <div className="py-10 w-full">
              {!!transaction?.files.length || transaction?.salesExpenseInvoiceDto?.documentId ? (
                <CustomButton
                  color="success"
                  className="w-full"
                  onClick={() => setPage(PAGE_NAMES.EDOCUMENT)}
                >
                  Belge Görüntüle
                </CustomButton>
              ) : [TRANSFORMATION_TYPES.E_INVOICE, TRANSFORMATION_TYPES.E_RECEIPT].includes(
                  etransformationType
                ) ? (
                <CustomButton color="success" className="w-full" onClick={onCreateEDocument}>
                  e-Belge Oluştur
                </CustomButton>
              ) : (
                <CustomButton
                  color="success"
                  className="w-full"
                  onClick={toggleUploadDocumentPopup}
                >
                  Belge Yükle
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ItemsTable = ({ items }) => {
  return (
    <div className="w-full py-8 flex flex-col">
      <table className="table-fixed">
        <thead className="border-b border-solid border-gray-200">
          <tr className="text-left text-xs font-medium text-gray-500">
            <th className="pb-2">İşlem Açıklaması</th>
            <th className="pb-2">Birim</th>
            <th className="pb-2">İndirim Oranı</th>
            <th className="text-right pb-2">Toplam Tutar</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, itemIndex) => (
            <tr key={itemIndex} className="py-2 border-b border-solid border-gray-200">
              <td className="py-4 flex flex-col">
                <span className="text-sm font-semibold text-gray-700">{item?.name || '-'}</span>
                {item?.category && (
                  <div className="flex mt-1">
                    <CategoryBadge
                      colorHexCode={item?.category?.colorHexCode}
                      textColorHexCode={item?.category?.textColorHexCode}
                      iconUrl={item?.category?.iconUrl}
                      name={item?.category?.name}
                      className="w-auto"
                    />
                  </div>
                )}
              </td>
              <td className="py-4 text-xs font-medium text-gray-500">
                {item?.unitType?.name && item?.quantity
                  ? `${item?.quantity} ${item?.unitType?.name}`
                  : '-'}
              </td>
              <td className="py-4 text-xs font-medium text-gray-500">
                %{item?.discountRate || '0'}
              </td>
              <td className="py-4 text-right flex flex-col items-end">
                <span className="text-sm font-semibold text-gray-900">
                  {!isNaN(item?.taxExcludedAmount)
                    ? currencyFormat(item?.taxExcludedAmount)
                    : '-'}{' '}
                  {CURRENCY_NAMES[item.currency]}
                </span>
                <div className="text-xs font-medium text-gray-500 flex flex-wrap justify-end w-48">
                  <span>{`+%${item?.vatRate} KDV`}</span>
                  {!!item?.additionalTaxes.length &&
                    item.additionalTaxes.map((additionalTax, index) => (
                      <Fragment key={index}>
                        {item?.additionalTaxes.length > index && <span className="px-1">·</span>}
                        <span>{`+%${additionalTax?.percent} ${additionalTax?.name}`}</span>
                      </Fragment>
                    ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FilePreview = ({ file, setFile, uploadDocumentToSaleExpense }) => (
  <Fragment>
    <div className="mt-6 w-full border-2 border-dashed border-gray-300 rounded-lg p-4 flex gap-x-4 items-start justify-start bg-white cursor-default">
      <img src={file.url} className="h-32 rounded-lg" />
      <div className="h-full w-full flex flex-col flex-wrap justify-center items-start gap-y-2 break-words">
        <span className="text-lg font-semibold text-gray-600 text-ellipsis break-all">
          {file.name}
        </span>
        <CustomButton color="danger" onClick={() => setFile(null)}>
          <div className="flex gap-x-2 items-center">
            <TrashIcon className="fill-current w-4 text-red-800" />
            <span>Sil</span>
          </div>
        </CustomButton>
      </div>
    </div>
    <div className="flex gap-x-2 items-center my-6">
      <InfoCircleIcon className="fill-current w-5 text-gray-500" />
      <span className="text-sm font-medium text-gray-500">
        Dosya tipi olarak .pdf, .jpg ve .png desteklenir. Maksimum boyut 15 mb
      </span>
    </div>
    <div className="pb-10 w-full">
      <CustomButton color="success" className="w-full" onClick={uploadDocumentToSaleExpense}>
        Kaydet
      </CustomButton>
    </div>
  </Fragment>
);

export default DetailSale;
