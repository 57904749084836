import React, { useEffect } from 'react';
import { Button, Card, Checkbox, CustomInput, DropdownSelect, Label, Popup } from 'components';
import StepHeader from 'components/stepHeader';
import { useSelector, useDispatch } from 'react-redux';
import {
  checkHasBill,
  getBillCategories,
  getBillCorporations,
  saveBillSubscription
} from 'store/actions/bill';
import { useSmartOperationContext } from '../..';

import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import useModal from 'utils/hooks/useModal';
import { getSubscriptions } from 'store/actions/marketplace';
import toast from 'react-hot-toast';
import { getWallets } from 'store/actions/accounts';
import { WALLET_TYPES } from 'utils/contants';
import { CURRENCIES } from 'views/other/items/enums';
import SelectAccount from 'views/transactions/components/SelectAccount';
import { components } from 'react-select';

export default function AutoBillPaymentCreate({ isNewBillCreate }) {
  const { categories, corporations } = useSelector((state) => state.bill);
  const accounts = useSelector((state) => state.accounts).filter(
    (wallet) => wallet.currencyCode === CURRENCIES.TRY
  );

  const { billPaymentFormValues, setBillPaymentFormValues, nextStep } =
    useSmartOperationContext();

  const [isBillNotFoundPopupActive, , toggleBillNotFoundPopup] = useModal();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillCategories());
    dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
  }, []);

  useEffect(() => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      corporation: null
    }));
  }, [billPaymentFormValues?.category]);

  const onChangeCategory = (category) => {
    if (!category) return;
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      category,
      corporation: null
    }));

    dispatch(getBillCorporations(category?.value));
  };

  const onChangeCorporation = (corporation) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      corporation,
      subscriberNo: ''
    }));
  };

  const onChangeSubscriberNo = (e) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      subscriberNo: e.target.value
    }));
  };

  const onChangeSaveBill = (e) => {
    const status = e.target.checked;

    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      shoudbeSaveBill: status,
      billRecordName: status ? prevValues.billRecordName : ''
    }));
  };

  const onChangeBillRecordName = (e) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      billRecordName: e.target.value
    }));
  };

  const onSaveSubscription = () => {
    dispatch(
      saveBillSubscription({
        corpCode: billPaymentFormValues.corporation.value,
        recordName: billPaymentFormValues.billRecordName,
        subscriberNo: billPaymentFormValues.subscriberNo,
        autoPayment: billPaymentFormValues?.autoPayment,
        walletId: billPaymentFormValues?.wallet?.id
      })
    ).then(() => nextStep());
  };

  const onCheckHasBills = () => {
    dispatch(
      checkHasBill({
        corpCode: billPaymentFormValues.corporation.value,
        subscriberNo: billPaymentFormValues.subscriberNo
      })
    )
      .then((data) => {
        if (data?.billExist) {
          nextStep();
        } else {
          toggleBillNotFoundPopup();
        }
        if (billPaymentFormValues?.shoudbeSaveBill) {
          dispatch(
            saveBillSubscription({
              corpCode: billPaymentFormValues.corporation.value,
              subscriberNo: billPaymentFormValues.subscriberNo,
              recordName: billPaymentFormValues.billRecordName
            })
          );
          dispatch(getSubscriptions());
        }
      })
      .catch(() => {
        toast.error('Abone Numarası Bulunamadı');
      });
  };

  const onWalletSelected = (wallet) => {
    setBillPaymentFormValues((prevValues) => ({
      ...prevValues,
      wallet
    }));
  };

  let categoryOptions =
    [
      ...categories?.map((category) => {
        return {
          label: category?.description,
          value: category?.id,
          icon: category?.iconUrl
        };
      })
    ] || [];

  let corporationOptions =
    [
      ...corporations?.map((corporation) => {
        return {
          label: corporation?.description,
          value: corporation?.code,
          title: corporation?.label
        };
      })
    ] || [];

  const shouldbeDisabled = billPaymentFormValues.shoudbeSaveBill
    ? !billPaymentFormValues?.category ||
      !billPaymentFormValues?.corporation ||
      !billPaymentFormValues?.subscriberNo ||
      !billPaymentFormValues?.billRecordName ||
      !billPaymentFormValues?.wallet ||
      !billPaymentFormValues?.autoPayment ||
      billPaymentFormValues?.billRecordName?.length < 2
    : !billPaymentFormValues?.category ||
      !billPaymentFormValues?.corporation ||
      !billPaymentFormValues?.subscriberNo ||
      (isNewBillCreate &&
        (!billPaymentFormValues?.billRecordName ||
          billPaymentFormValues?.billRecordName?.length < 2));

  const subscriberNoMaxLength = corporations?.find(
    (corporation) => corporation.code === billPaymentFormValues.corporation?.value
  )?.maxLength;

  const CustomCategoryOption = ({ value, label, setValue, selectProps, innerProps }) => {
    const option = selectProps.options.find((option) => option.value === value);
    return (
      <div
        className="!hover:bg-none border-b border-solid border-gray-100 px-2 text-base text-gray-800 leading-6 flex gap-x-4 items-center"
        onClick={() => setValue(value)}
        {...innerProps}
      >
        <span className="w-[20px] h-[20px]">
          {option?.icon && <img src={option?.icon} alt={label} width={20} height={20} />}
        </span>
        <span>{label}</span>
      </div>
    );
  };
  const CustomCorporationOption = ({ value, label, setValue, innerProps }) => {
    return (
      <div
        className="!hover:bg-none border-b border-solid border-gray-100 px-2 text-base text-gray-800 leading-6 flex gap-x-4 items-center"
        onClick={() => setValue(value)}
        {...innerProps}
      >
        <span>{label}</span>
      </div>
    );
  };
  const CustomSelectedItem = ({ children, selectProps, ...props }) => {
    const { value } = selectProps;
    return (
      <components.Control {...props}>
        <span className="w-[20px] h-[20px] ml-2">
          {value?.icon && <img src={value?.icon} alt={value?.label} width={20} height={20} />}
        </span>
        {children}
      </components.Control>
    );
  };

  return (
    <div className="w-full h-full" id="BillPayment">
      {isBillNotFoundPopupActive && <BillNotFoundPopup onClose={toggleBillNotFoundPopup} />}
      <StepHeader title="Akıllı İşlemler" subtitle="Otomatik Fatura Oluştur" />

      <Card>
        <div className="flex flex-col gap-y-8">
          {isNewBillCreate && (
            <CustomInput
              label="Kayıtlı Fatura İsmi"
              maxLength={32}
              onChange={onChangeBillRecordName}
            />
          )}
          <div className="flex gap-x-4">
            <Label label="Kurum Tipi" className="w-full max-w-[216px]">
              <DropdownSelect
                options={categoryOptions}
                onChange={onChangeCategory}
                placeholder=""
                value={billPaymentFormValues?.category}
                isSearchable
                customOptionComponent={{
                  Option: CustomCategoryOption,
                  Control: CustomSelectedItem
                }}
              />
            </Label>
            <Label label="Kurum Adı" className="w-full">
              <DropdownSelect
                options={corporationOptions}
                onChange={onChangeCorporation}
                placeholder=""
                value={billPaymentFormValues?.corporation}
                customOptionComponent={{ Option: CustomCorporationOption }}
                isSearchable
              />
            </Label>
          </div>
          {billPaymentFormValues.corporation?.value && billPaymentFormValues.category?.value && (
            <CustomInput
              label={billPaymentFormValues.corporation?.title}
              onChange={onChangeSubscriberNo}
              value={billPaymentFormValues.subscriberNo}
              only="number"
              maxLength={subscriberNoMaxLength}
            />
          )}
        </div>

        <div className="mt-4">
          <Label label="Ödeme Yapılacak Hesap">
            <SelectAccount
              accounts={accounts}
              selectedAccount={billPaymentFormValues?.wallet}
              setSelectedAccount={onWalletSelected}
            />
          </Label>

          {!isNewBillCreate && (
            <div className="flex flex-col gap-y-4 mb-10">
              <Checkbox
                checked={billPaymentFormValues?.shoudbeSaveBill}
                onChange={onChangeSaveBill}
              >
                Kayıtlı Faturalara ekle
              </Checkbox>
              {billPaymentFormValues?.shoudbeSaveBill && (
                <CustomInput
                  label="Kayıt İsmi"
                  maxLength={32}
                  onChange={onChangeBillRecordName}
                />
              )}
            </div>
          )}
        </div>
        {isNewBillCreate ? (
          <Button
            className="w-full pb-6"
            disabled={shouldbeDisabled}
            onClick={onSaveSubscription}
          >
            Kaydet
          </Button>
        ) : (
          <Button className="w-full pb-6" disabled={shouldbeDisabled} onClick={onCheckHasBills}>
            Sorgula
          </Button>
        )}
      </Card>
    </div>
  );
}

const BillNotFoundPopup = ({ onClose }) => {
  const { billPaymentFormValues } = useSmartOperationContext();
  return (
    <Popup
      active
      childrenClassName="w-[327px] h-[320px] flex flex-col items-center justify-between text-center"
      onClose={onClose}
    >
      <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100">
        <IconInfoCircle className="fill-current w-10 text-yellow-600" />
      </div>
      <div className="flex flex-col gap-y-3">
        <span className="text-lg font-semibold text-gray-900">Fatura Bulunamadı</span>
        <span className="text-base font-medium text-gray-500">
          {billPaymentFormValues.subscriberNo} No’lu aboneye ait ödenmemiş fatura bulunamadı.
        </span>
      </div>
      <Button className="w-full" onClick={onClose}>
        Tamam
      </Button>
    </Popup>
  );
};
