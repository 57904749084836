import classNames from 'classnames';
import React from 'react';
import { ReactComponent as IconEmptyState } from 'assets/icons/empty-state.svg';
import InfoMessage from 'components/InfoMessage';

export default function EmptyView({
  children,
  classname,
  InfoMessageContainerClassname,
  ...props
}) {
  const baseClassNames = classNames('flex flex-col items-center gap-y-8', classname);
  const InfoMessageContainerClassnames = classNames(
    'px-4 py-2 rounded-full',
    InfoMessageContainerClassname
  );
  return (
    <div className={baseClassNames} {...props}>
      <IconEmptyState />
      <InfoMessage className={InfoMessageContainerClassnames} iconClassName="w-5 h-5">
        {children}
      </InfoMessage>
    </div>
  );
}
