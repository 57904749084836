import React, { useState } from 'react';
import cn from 'classnames';
import { Button } from 'components';

import { ReactComponent as IconFileUpload } from 'assets/icons/file-upload.svg';
import { ReactComponent as IconDelete } from 'assets/icons/trash.svg';

const FileSelectButton = React.forwardRef(
  (
    {
      onSelected,
      className,
      isMultiple = false,
      acceptFileTypes = 'image/png, image/jpeg',
      description = 'Fiş eklemek için tıklayın'
    },
    ref
  ) => {
    const [files, setFiles] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const inputRef = React.useRef(null);

    const classNames = cn(
      [
        'flex flex-col items-center justify-center w-full h-full p-8 border border-dashed border-gray-300 rounded-xl bg-gray-100 cursor-pointer'
      ],
      { className }
    );

    const onClickDropArea = () => {
      if (ref) return ref?.current?.click();

      inputRef?.current?.click();
    };

    const onSelectedFilesByInput = () => {
      const selectedFiles = ref?.current?.files || inputRef?.current?.files;
      onSelected(selectedFiles);
      setFiles(selectedFiles);
      setSelectedImage(readSelectedFile(selectedFiles));
    };

    const onDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const onDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      readSelectedFile(e.dataTransfer.files);
    };

    const readSelectedFile = (selectedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(selectedFiles[0]);
      setFiles(selectedFiles);
    };

    const onCleanFileSelection = () => {
      setFiles(null);
      setSelectedImage('');
      onSelected(null);
    };

    return (
      <div
        className={classNames}
        onClick={onClickDropArea}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        {selectedImage && (
          <div className="flex gap-x-5">
            <img src={selectedImage} alt="company-receipt" className="w-32 rounded-lg" />
            <div className="flex flex-col gap-y-2 items-start">
              <p className="font-semibold text-lg leading-6 text-gray-600">{files[0]?.name}</p>
              <Button
                color="red"
                leftIcon={<IconDelete className="fill-current" />}
                onClick={onCleanFileSelection}
              >
                Sil
              </Button>
            </div>
          </div>
        )}
        {!selectedImage && (
          <div className="flex flex-col items-center">
            <IconFileUpload className="w-8 h-8 fill-current text-gray-400" />
            <p className="font-semibold text-lg text-gray-500">{description}</p>
            <p className="font-semibold text-sm text-gray-400">veya sürükleyip buraya bırakın</p>
            <input
              className="hidden"
              ref={ref || inputRef}
              type="file"
              accept={acceptFileTypes}
              multiple={isMultiple}
              onChange={onSelectedFilesByInput}
            />
          </div>
        )}
      </div>
    );
  }
);

FileSelectButton.displayName = 'FileSelectButton';

export default FileSelectButton;
