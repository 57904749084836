export const PAGE_NAMES = {
  DETAIL: 'DETAIL',
  EDIT: 'EDIT',
  EDOCUMENT: 'EDOCUMENT',
  SUCCESS: 'SUCCESS'
};

export const CURRENCY_NAMES = {
  USD: 'USD',
  TRY: 'TL',
  EUR: 'EURO',
  GBP: 'GBP'
};

export const TRANSFORMATION_TYPES = {
  E_INVOICE: 'E_INVOICE',
  E_RECEIPT: 'E_RECEIPT'
};
