import { useEffect } from 'react';
import { CustomButton } from 'components';
import DoubleIconCheck from 'assets/illustrations/card/double-check.png';
import { useHistory } from 'react-router-dom';
import useDengage from 'utils/hooks/useDengage';

const Success = ({ handleClose, createdExpenseId }) => {
  const history = useHistory();
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_create_expense_success' });
  }, []);

  return (
    <div className="w-full h-full bg-green-500 flex flex-col items-center justify-center">
      <div className="w-[380px] flex flex-col items-center">
        <img src={DoubleIconCheck} alt="Double check" className="w-24 mb-8" />
        <span className="text-3xl font-medium text-white text-center">
          Gider Kaydı oluşturma işleminiz başarıyla tamamlanmıştır.
        </span>
        <CustomButton
          className="w-full mb-4 mt-6"
          onClick={() => {
            history.push(`/invoicing/expense-detail/${createdExpenseId}`);
          }}
        >
          Gider Kaydını Gör
        </CustomButton>
        <CustomButton className="w-full" color="success" onClick={handleClose}>
          Kapat
        </CustomButton>
      </div>
    </div>
  );
};

export default Success;
