import React from 'react';
import cn from 'classnames';

export const avatarVariants = {
  CIRCLED: 'circled',
  SQUARED: 'squared'
};

const Avatar = ({
  alt,
  size,
  variant,
  className = '',
  fallback = '',
  fallbackClass = 'bg-gray-200 text-gray-600',
  src = '',
  ...props
}) => {
  const classNames = cn({
    avatar: true,
    [`avatar-${variant}`]: variant,
    [`avatar-${size}`]: size,
    [fallbackClass]: true,
    ['fallback-text']: fallback,
    [className]: className,
    'img-border': variant === avatarVariants.CIRCLED
  });

  const getFallBackName = () => {
    if (!fallback) return '';
    const name = fallback
      .trim()
      .split(' ')
      .filter((n) => n);

    if (name.length >= 3) {
      return name[0][0] + name[1][0] + name[name.length - 1][0];
    }
    if (name.length === 2) {
      return name[0][0] + name[1][0];
    }
    return name[0][0];
  };

  if (src) return <img src={src} alt={alt} className={classNames} {...props} />;
  if (!fallback) return <div className={classNames} />;

  return (
    <div className={classNames}>
      <span>{getFallBackName()}</span>
    </div>
  );
};

Avatar.defaultProps = {
  variant: avatarVariants.SQUARED,
  size: 'sm',
  alt: 'avatar'
};

export default Avatar;
