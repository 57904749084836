import { invoicingTypes } from 'store/types';

const {
  GET_SALES_DETAIL,
  GET_SALES_DETAIL_SUCCESS,
  GET_SALES_DETAIL_FAIL,
  GET_E_DOCUMENT,
  GET_E_DOCUMENT_FAIL,
  GET_E_DOCUMENT_SUCCESS
} = invoicingTypes;

const initialState = {
  expensesTotalAmount: 0,
  salesTotalAmount: 0,
  series: [],
  intervalType: 'WEEK',
  salesDetail: null,
  salesExpenses: {
    content: [],
    empty: false,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 15,
    sort: { sorted: true, unsorted: false, empty: false },
    totalElements: 0,
    totalPages: 0
  },
  eDocument: null,
  isLoading: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUMMARY_SERIES':
      return {
        ...state,
        ...action.data
      };
    case 'SET_INTERVAL_TYPE':
      return {
        ...state,
        intervalType: action.data
      };
    case 'SET_SALES_EXPENSES':
      return {
        ...state,
        salesExpenses: action.data
      };
    case 'SET_SALES_EXPENSE_ERROR':
      return {
        ...state,
        salesExpenses: {
          ...initialState.salesExpenses
        }
      };
    case 'SET_E_DOCUMENT':
      return {
        ...state,
        eDocument: action.data
      };
    case GET_E_DOCUMENT:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case GET_E_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        eDocument: action.data
      };
    case GET_E_DOCUMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.data
      };

    case GET_SALES_DETAIL:
      return {
        ...state,
        loading: true,
        salesDetail: null,
        error: null
      };
    case GET_SALES_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        salesDetail: action.payload
      };
    case GET_SALES_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
