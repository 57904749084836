import React, { useEffect } from 'react';
import StepHeader from 'components/stepHeader';
import { Button, Card, Checkbox } from 'components';
import { useOpenBakingContext } from '../..';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getOpenBankingBankAccounts,
  getOpenBankingBankRequiredFields,
  updateOpenBankingBankAccount
} from 'store/actions/openBanking';

import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';

export default function SelectBankAccount() {
  const { setStep, steps, setFormValues, formValues } = useOpenBakingContext();
  const { bankAccounts, addBank } = useSelector((state) => state.openBanking);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOpenBankingBankAccounts(formValues?.bank?.openBankingId));
  }, []);

  useEffect(() => {
    setFormValues((previousValues) => ({
      ...previousValues,
      selectedBankAccounts: [...bankAccounts]
    }));
  }, [bankAccounts]);

  const onUpdateAccounts = () => {
    dispatch(
      updateOpenBankingBankAccount(
        formValues?.bank?.openBankingId || addBank?.openBankingId,
        formValues.selectedBankAccounts
      )
    ).then(() => {
      setStep(steps.succeded);
    });
  };

  const onChangeBankAccountSelection = (_, bankAccount) => {
    setFormValues((prevValues) => {
      let accounts = [...prevValues?.selectedBankAccounts] || [];

      const hasSelectedAccount = accounts?.find(
        (account) => account?.accountId === bankAccount?.accountId
      );

      if (hasSelectedAccount) {
        accounts = accounts?.map((account) => {
          if (account?.accountId === hasSelectedAccount?.accountId) {
            account.assignedBankAccount = !hasSelectedAccount?.assignedBankAccount;
          }

          return account;
        });
        return {
          ...prevValues,
          selectedBankAccounts: [...accounts]
        };
      }

      return {
        ...prevValues,
        selectedBankAccounts: accounts
      };
    });
  };

  return (
    <>
      <StepHeader title="Entegre Banka Hesabı" subtitle="Hesap Ekle" />
      <Card>
        <BankAccounts onChange={onChangeBankAccountSelection} />
        <Button className="w-full mt-10" onClick={onUpdateAccounts}>
          Devam
        </Button>
      </Card>
    </>
  );
}

const BankAccounts = ({ onChange }) => {
  const { formValues, setStep, steps } = useOpenBakingContext();
  const { addBank: bank } = useSelector((state) => state.openBanking);
  const dispatch = useDispatch();
  const onClickCreateNewAccount = () => {
    dispatch(getOpenBankingBankRequiredFields(formValues?.bank?.bankInfoId)).then(() => {
      setStep(steps.addManuallyBankAccountForm);
    });
  };
  return (
    <div className="flex flex-col gap-y-[18px]">
      <img
        src={formValues?.bank?.logoUrl || bank?.bankLogoUrl}
        alt={formValues?.bank?.logoUrl || bank?.bankName}
        className="w-[120px]"
      />
      <div className="flex items-center justify-between">
        <p className="text-sm font-medium text-gray-400">Hesap Seç</p>
        {formValues?.bank?.manualAccountAllowed && (
          <Button variant="ghost" leftIcon={IconPlus} onClick={onClickCreateNewAccount}>
            Yeni Hesap Ekle
          </Button>
        )}
      </div>
      <div className="flex flex-col divide-y divide-gray-100">
        {formValues.selectedBankAccounts?.map((bankAccount, index) => (
          <Checkbox
            key={index}
            checked={bankAccount?.assignedBankAccount}
            onChange={(e) => onChange(e.target.checked, bankAccount)}
            className="border-solid py-4"
          >
            {bankAccount?.accountName}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};
