import React, { useState } from 'react';
import { Button, SideModal, Label, DatePicker, CustomInput, LabelButton } from 'components';

// import { getFormattedDateWithSeperator } from 'utils';

// import IbanInput from 'views/transactions/components/IbanInput';
// import { WALLET_TYPES } from 'utils/contants';
import AmountInput from 'components/AmountInput';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { TRANSACTION_DIRECTION } from 'utils/contants';
import { useDispatch } from 'react-redux';
import { getFormattedDateForPayload } from 'utils';
import { addManualTransaction, getTransactions } from 'store/actions/transactions';

export default function AddTransactionSideModal({ onClose, selectedAccount, onSuccess }) {
  const [direction, setDirection] = useState(TRANSACTION_DIRECTION.IN.key);
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState('');

  const dispatch = useDispatch();

  const isValid = amount > 0 && direction && date;

  const onSave = () => {
    dispatch(
      addManualTransaction(selectedAccount.id, {
        direction,
        amount,
        date: getFormattedDateForPayload(date),
        description
      })
    ).then(() => {
      onClose();
      onSuccess();
      dispatch(getTransactions());
    });
  };

  return (
    <SideModal handleClose={onClose}>
      <div className="flex flex-col w-full h-full justify-between overflow-y-scroll pb-10">
        <div>
          <span className="font-semibold text-2xl text-gray-900">İşlem Ekle</span>
          <div className="flex flex-col gap-y-4 justify-between mt-5">
            <Label label="İşlem Tipi">
              <div className="flex gap-x-2">
                <LabelButton
                  onClick={() => setDirection(TRANSACTION_DIRECTION.IN.key)}
                  className={
                    direction === TRANSACTION_DIRECTION.IN.key
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-200 text-gray-500'
                  }
                >
                  Para Girişi
                </LabelButton>
                <LabelButton
                  onClick={() => setDirection(TRANSACTION_DIRECTION.OUT.key)}
                  className={
                    direction === TRANSACTION_DIRECTION.OUT.key
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-200 text-gray-500'
                  }
                >
                  Para Çıkışı
                </LabelButton>
              </div>
            </Label>
            <AmountInput
              label="Tutar"
              suffix={` ${CURRENCY_NAMES[selectedAccount?.currencyCode]}`}
              value={amount}
              onValueChange={setAmount}
            />
            <Label>
              <DatePicker
                label="Tarih"
                className="bg-transparent py-3 w-full focus:outline-none text-black"
                placeholder="Tarih seçin"
                selected={date}
                isFormInput
                onChange={setDate}
              />
            </Label>
            <Label label="Açıklama" isOptional={true}>
              <CustomInput
                inputClassName="h-20"
                maxLength={140}
                count={description?.length}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Label>
          </div>
        </div>
        <Button className="self-end w-full" onClick={onSave} disabled={!isValid}>
          {direction === TRANSACTION_DIRECTION.IN.key ? 'Para Girişi Ekle' : 'Para Çıkışı Ekle'}
        </Button>
      </div>
    </SideModal>
  );
}
