import axios from 'axios';
import { billTypes } from 'store/types';

const {
  GET_BILLS,
  GET_BILLS_SUCCESS,
  GET_BILLS_FAILURE,
  GET_BILL_SUBSCRIPTIONS,
  GET_BILL_SUBSCRIPTIONS_SUCCESS,
  GET_BILL_SUBSCRIPTIONS_FAILURE,
  SAVE_BILL_SUBSCRIPTIONS,
  SAVE_BILL_SUBSCRIPTIONS_SUCCESS,
  SAVE_BILL_SUBSCRIPTIONS_FAILURE,
  UPDATE_BILL_SUBSCRIPTIONS,
  UPDATE_BILL_SUBSCRIPTIONS_SUCCESS,
  UPDATE_BILL_SUBSCRIPTIONS_FAILURE,
  DELETE_BILL_SUBSCRIPTIONS,
  DELETE_BILL_SUBSCRIPTIONS_SUCCESS,
  DELETE_BILL_SUBSCRIPTIONS_FAILURE,
  GET_BILL_CATEGORIES,
  GET_BILL_CATEGORIES_SUCCESS,
  GET_BILL_CATEGORIES_FAILURE,
  GET_BILL_CORPORATIONS,
  GET_BILL_CORPORATIONS_SUCCESS,
  GET_BILL_CORPORATIONS_FAILURE,
  CHECK_HAS_BILL,
  CHECK_HAS_BILL_SUCCESS,
  CHECK_HAS_BILL_FAILURE,
  PAY_BILL,
  PAY_BILL_SUCCESS,
  PAY_BILL_FAILURE,
  RESET_ALL_BILL,
  RESET_GET_BILL
} = billTypes;

export const getBillCategories = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BILL_CATEGORIES
      });

      const response = await axios.get(`/api/bills/categories`);

      if (response.status === 200) {
        dispatch({
          type: GET_BILL_CATEGORIES_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_BILL_CATEGORIES_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const getBillCorporations = (categoryId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BILL_CORPORATIONS
      });

      const response = await axios.get(`/api/bills/categories/${categoryId}/corporations`);

      if (response.status === 200) {
        dispatch({
          type: GET_BILL_CORPORATIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_BILL_CORPORATIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const getBillSubscriptions = (companyId = null) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      if (!user?.companyId) {
        return dispatch({
          type: GET_BILL_SUBSCRIPTIONS_FAILURE,
          payload: 'company not found'
        });
      }
      dispatch({
        type: GET_BILL_SUBSCRIPTIONS
      });

      const response = await axios.get(
        `/api/companies/${companyId || user?.companyId}/bills/subscriptions`
      );

      if (response.status === 200) {
        dispatch({
          type: GET_BILL_SUBSCRIPTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_BILL_SUBSCRIPTIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const getBills = ({ corporationId, subscriberNo }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_BILLS
      });

      const params = {
        'corp-code': corporationId,
        'subscriber-no': subscriberNo
      };

      const response = await axios.get(`/api/bills`, {
        params
      });

      if (response.status === 200) {
        dispatch({
          type: GET_BILLS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_BILLS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const saveBillSubscription = ({
  corpCode = '',
  recordName = '',
  subscriberNo = '',
  autoPayment = false,
  walletId = null
}) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      if (!user?.companyId) {
        return dispatch({
          type: SAVE_BILL_SUBSCRIPTIONS_FAILURE,
          payload: 'company not found'
        });
      }

      const payload = { corpCode, recordName, subscriberNo, autoPayment, walletId };

      dispatch({
        type: SAVE_BILL_SUBSCRIPTIONS
      });

      const response = await axios.post(
        `/api/companies/${user?.companyId}/bills/subscriptions`,
        payload
      );

      if (response.status === 200) {
        dispatch({
          type: SAVE_BILL_SUBSCRIPTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: SAVE_BILL_SUBSCRIPTIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const updateBillSubscription = ({ subscriptonId, recordName, autoPayment, walletId }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      if (!user?.companyId) {
        return dispatch({
          type: UPDATE_BILL_SUBSCRIPTIONS_FAILURE,
          payload: 'company not found'
        });
      }

      const payload = { recordName, autoPayment, walletId };

      dispatch({
        type: UPDATE_BILL_SUBSCRIPTIONS
      });

      const response = await axios.put(
        `/api/companies/${user?.companyId}/bills/subscriptions/${subscriptonId}`,
        payload
      );

      if (response.status === 200) {
        dispatch({
          type: UPDATE_BILL_SUBSCRIPTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_BILL_SUBSCRIPTIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const deleteBillSubscription = (subscriptonId) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      if (!user?.companyId) {
        return dispatch({
          type: DELETE_BILL_SUBSCRIPTIONS_FAILURE,
          payload: 'company not found'
        });
      }

      dispatch({
        type: DELETE_BILL_SUBSCRIPTIONS
      });

      const response = await axios.delete(
        `/api/companies/${user?.companyId}/bills/subscriptions/${subscriptonId}`
      );

      if (response.status === 200) {
        dispatch({
          type: DELETE_BILL_SUBSCRIPTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: DELETE_BILL_SUBSCRIPTIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const checkHasBill = ({ corpCode, subscriberNo }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CHECK_HAS_BILL
      });

      const response = await axios.get(`/api/bills/subscriptions`, {
        params: {
          'corp-code': corpCode,
          'subscriber-no': subscriberNo
        }
      });

      if (response.status === 200) {
        dispatch({
          type: CHECK_HAS_BILL_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CHECK_HAS_BILL_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const payBill = ({ corpCode = '', subscriberNo = '', walletId = '', invoiceNo = '' }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    try {
      if (!user?.companyId) {
        return dispatch({
          type: PAY_BILL_FAILURE,
          payload: 'company not found'
        });
      }

      const payload = {
        corpCode,
        subscriberNo,
        walletId
      };

      dispatch({
        type: PAY_BILL
      });

      const response = await axios.post(
        `/api/companies/${user?.companyId}/bills/${invoiceNo}/pay`,
        payload
      );

      if (response.status === 200) {
        dispatch({
          type: PAY_BILL_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: PAY_BILL_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const resetAllBillState = () => (dispatch) =>
  dispatch({
    type: RESET_ALL_BILL
  });

export const resetGetBillState = () => (dispatch) =>
  dispatch({
    type: RESET_GET_BILL
  });
