import { Checkbox } from 'components';

const MakeQuickContact = ({ register }) => (
  <div className="w-full py-5 px-6 border border-solid border-gray-200 rounded bg-gray-50">
    <Checkbox register={register} name="express" data-testid="checkbox_make_quick_contact">
      Hızlı Müşteri/Tedarikçi olarak tanımla
    </Checkbox>
  </div>
);

export default MakeQuickContact;
