import React from 'react';
import { Button, Popup } from 'components';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import classNames from 'classnames';

export default function ArchiveAccountPopup({
  selectedAccount,
  onClose,
  onApproved,
  onReject,
  ...props
}) {
  return (
    <Popup active={true} onClose={onClose} childrenClassName="!h-auto" {...props}>
      <div className="flex flex-col justify-center items-center">
        <div className="w-20 h-20 rounded-full bg-yellow-100 flex justify-center items-center">
          <IconInfoCircle className="fill-current w-10 text-yellow-500" />
        </div>
        <span className="mt-6 text-lg font-semibold text-gray-900 text-center mb-3">
          Bu hesap kaydını arşivlemek istediğinize emin misiniz?
        </span>
        {selectedAccount?.balance?.balance === 0 ? (
          <Description>
            Arşivleme işleminin sonucunda hesabınız hesaplar listelerinde görünmeyecek.
          </Description>
        ) : (
          <Description>
            Hesabınızı arşivlemek için bakiyenin{' '}
            <span className="text-gray-900">&apos;0&lsquo;</span> olması gerekiyor. Yine de
            arşivlemek ister misiniz?
          </Description>
        )}

        <Button onClick={onApproved} className="w-full mt-6">
          Arşivle
        </Button>
        <Button color="red" onClick={onReject} className="w-full mt-4">
          İptal Et
        </Button>
      </div>
    </Popup>
  );
}

const Description = ({ children, className, ...props }) => {
  const classes = classNames('text-gray-500 text-center leading-6 font-medium', className);
  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
};
