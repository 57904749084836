import { useState, useEffect } from 'react';
import { CustomInput } from 'components';
import classNames from 'classnames';

const IbanInput = ({
  iban,
  setIban,
  ibanLocale = 'TR',
  label = 'IBAN',
  className,
  isOptional,
  ...props
}) => {
  const [maskedIban, setMaskedIban] = useState(iban);

  const inputClassName = classNames('mt-4', className);
  function handleIbanChange(e) {
    const { value } = e.target;
    const numberRegex = /[^0-9]+/g;
    const numbers = value?.replace(numberRegex, '');

    let ibanNumber = `${ibanLocale}${numbers}`;

    setIban(ibanNumber?.substring(0, 26));

    const maskedIban = `${ibanNumber.substring(0, 4)} ${ibanNumber.substring(
      4,
      8
    )} ${ibanNumber.substring(8, 12)} ${ibanNumber.substring(12, 16)} ${ibanNumber.substring(
      16,
      20
    )} ${ibanNumber.substring(20, 24)} ${ibanNumber.substring(24, 28)} ${ibanNumber.substring(
      28,
      32
    )}`
      .substring(0, 32)
      .trim();

    setMaskedIban(maskedIban);
  }

  useEffect(() => {
    if (iban !== undefined) handleIbanChange({ target: { value: iban } });
  }, [iban]);

  return (
    <CustomInput
      label={label}
      className={inputClassName}
      onChange={handleIbanChange}
      value={maskedIban}
      isOptional={isOptional}
      {...props}
    />
  );
};

export default IbanInput;
