import { CustomInput } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompany } from 'store/actions/company';
import TabSubTitle from '../tabSubTitle';

const COMPANY_TYPES = {
  FREE_LANCER: 'Serbest Meslek Erbabı',
  SOLE_TRADER: 'Şahıs Şirketi'
};
const CompanyInfo = () => {
  const dispatch = useDispatch();

  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    dispatch(getCompany()).then((res) => {
      setCompanyInfo(res.data.data);
    });
  }, []);

  return (
    <div>
      <TabSubTitle>Şirket Bilgileri</TabSubTitle>
      <div className="w-full mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <CustomInput
          type="text"
          disabled={true}
          defaultValue={companyInfo?.name}
          label="Şirket Adı"
          className="w-full"
          value={companyInfo?.name}
        />
        <CustomInput
          disabled={true}
          defaultValue={COMPANY_TYPES[companyInfo?.subType]}
          label="Şirket Türü"
          className="w-full"
          value={COMPANY_TYPES[companyInfo?.subType]}
        />
        <CustomInput
          disabled={true}
          defaultValue={companyInfo?.address?.countryTitle}
          label="Ülke"
          className="w-full"
          value={companyInfo?.address?.countryTitle}
        />
        <CustomInput
          disabled={true}
          defaultValue={companyInfo?.address?.cityTitle}
          label="Şehir"
          className="w-full"
          value={companyInfo?.address?.cityTitle}
        />
        <CustomInput
          disabled={true}
          defaultValue={companyInfo?.address?.address}
          label="Adres"
          className="w-full"
          value={companyInfo?.address?.address}
        />
        <CustomInput
          disabled={true}
          defaultValue={companyInfo?.taxOffice?.name}
          label="Vergi Dairesi"
          className="w-full"
          value={companyInfo?.taxOffice?.name}
        />
        <CustomInput
          disabled={true}
          defaultValue={companyInfo?.taxIdentityNumber}
          label="Vergi No"
          className="w-full"
          value={companyInfo?.taxIdentityNumber}
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
