import React, { useState, useEffect } from 'react';
import TabSubTitle from 'views/profile/tabs/tabSubTitle';
import {
  ServiceCard,
  ServiceDetailModal,
  PaymentModal,
  EtransformationSelectionPopup,
  PaymentSuccessModal
} from './subComponents';
import useModal from 'utils/hooks/useModal';
import { useRouter } from 'utils/hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import {
  createVariantSubscription,
  getMarketplaceItems,
  getSubscriptions
} from 'store/actions/marketplace';
import {
  ETRANSFORMATION_SELECTION_TYPES,
  PAYMENT_TRANSACTION_STATUS,
  SERVICE_PACKAGE_TYPES
} from 'utils/contants';
import { BottomModal } from 'components';
import { getPaymentStatus } from 'store/actions/payments';
import { toast } from 'react-hot-toast';
import { getCreditCards } from 'store/actions/creditCards';
import useDengage from 'utils/hooks/useDengage';

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [cardInformations, setCardInformations] = useState();
  const { push, query, history } = useRouter();
  const dispatch = useDispatch();

  const { publishEvent, eventTypes } = useDengage();

  const { payment: paymentWithExistingCard } = useSelector((state) => state.payments);
  const { payment } = useSelector((state) => state.marketplace);

  const [isServiceDetailDrawerActive, , toggleServiceDetailDrawer] = useModal({
    shouldBeBlockScroll: true
  });
  const [isPaymentModalActive, setIsPaymentModalActive, togglePaymentModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [
    isEtransformationSelectionPopupActive,
    setIsEtransformationSelectionPopupActive,
    toggleEtransformationSelectionPopup
  ] = useModal({
    shouldBeBlockScroll: true
  });

  const [isPaymentSuccessModalActive, , togglePaymentSuccessModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [
    isThreeDPaymentModalActive,
    setIsThreeDPaymentModalActive,
    toggleThreeDPaymentModalActive
  ] = useModal();

  const { items } = useSelector((state) => state.marketplace);

  useEffect(() => {
    dispatch(getMarketplaceItems());
    dispatch(getCreditCards());
    if (query?.shouldBeSubscribeVirtualOffice)
      publishEvent(eventTypes.pageView, { page_type: 'start_company_virtual_address_info' });

    return () => {
      dispatch({
        type: 'RESET_PAYMENT_STATUS'
      });
      document.body.style = '';
    };
  }, []);

  useEffect(() => {
    if (query?.shouldBeSubscribeEInvoice) {
      onBuyOrApplyService(
        items.find((item) => item.name === SERVICE_PACKAGE_TYPES.E_TRANSFORMATION.key)
      );
    }
    if (query?.shouldBeSubscribeVirtualOffice) {
      onBuyOrApplyService(
        items.find((item) => item.name === SERVICE_PACKAGE_TYPES.VIRTUAL_OFFICE.key)
      );
    }
  }, [items]);

  useEffect(() => {
    if (payment?.html || paymentWithExistingCard?.html) setIsThreeDPaymentModalActive(true);
  }, [payment, paymentWithExistingCard]);

  const onSeeMore = (service) => {
    setSelectedService(service);
    toggleServiceDetailDrawer();
  };

  const onBuyOrApplyService = (service) => {
    if (!service) return;

    const { name, purchased } = service;

    if (!purchased) {
      setSelectedService(service);
      if (name === SERVICE_PACKAGE_TYPES.E_TRANSFORMATION.key) {
        return setIsEtransformationSelectionPopupActive(true);
      } else if (service?.variants?.length <= 1 && service?.variants[0]?.price === 0) {
        return dispatch(createVariantSubscription(service?.variants[0]?.id)).then(() => {
          dispatch(getMarketplaceItems());
          setSuccessMessage('Başvurunuz alınmıştır.');
          togglePaymentSuccessModal();
        });
      }
      togglePaymentModal();
    }
  };

  const clearQueryStringHistory = () => {
    if (query?.shouldBeSubscribeEInvoice || query?.shouldBeSubscribeVirtualOffice) {
      history.replace({
        search: ''
      });
    }
  };

  const onSelectEtransformation = (selectedOption, variant) => {
    toggleEtransformationSelectionPopup();

    clearQueryStringHistory();
    switch (selectedOption) {
      case ETRANSFORMATION_SELECTION_TYPES.APPLY_ETRANSFORMATION:
        setSuccessMessage('Başvurunuz alınmıştır.');
        dispatch(createVariantSubscription(variant?.variants[0]?.id));
        togglePaymentSuccessModal();

        break;
      case ETRANSFORMATION_SELECTION_TYPES.WITH_YOUR_PROVIDER:
        setSelectedService(
          items.find((item) => item.name === SERVICE_PACKAGE_TYPES.E_SIGNATURE.key)
        );
        toggleEtransformationSelectionPopup();
        togglePaymentModal();
        break;
      case ETRANSFORMATION_SELECTION_TYPES.WITH_ANOTHER_PROVIDER:
        setSuccessMessage('Başvurunuz alınmıştır.');
        dispatch(createVariantSubscription(variant?.variants[0]?.id));
        togglePaymentSuccessModal();
        break;
    }
  };

  const onEtransformationSelectionPopupClose = () => {
    clearQueryStringHistory();
    toggleEtransformationSelectionPopup();
  };

  const onPaymentSuccessModalClose = () => {
    if (query?.shouldBeSubscribeVirtualOffice && query?.redirectUrl) {
      clearQueryStringHistory();
      dispatch(getSubscriptions());
      return push(query?.redirectUrl);
    }
    togglePaymentSuccessModal();
  };

  return (
    <div className="w-full mb-4 flex flex-col">
      <TabSubTitle>
        <span className="text-gray-900 text-lg leading-6 font-semibold">Paketler</span>
        <div className="flex flex-wrap justify-center">
          {items?.length > 0 &&
            items?.map((item, i) => (
              <div className="p-5" key={i}>
                <ServiceCard
                  data={item}
                  onBuyOrApplyService={onBuyOrApplyService}
                  onSeeMore={onSeeMore}
                />
              </div>
            ))}
        </div>
      </TabSubTitle>

      {isServiceDetailDrawerActive && (
        <ServiceDetailModal data={selectedService} onClose={toggleServiceDetailDrawer} />
      )}

      {isEtransformationSelectionPopupActive && (
        <EtransformationSelectionPopup
          active={true}
          data={selectedService}
          onClose={onEtransformationSelectionPopupClose}
          onSelect={onSelectEtransformation}
        />
      )}

      {isPaymentModalActive && (
        <PaymentModal
          data={selectedService}
          onClose={togglePaymentModal}
          setCardInformations={setCardInformations}
        />
      )}

      {isThreeDPaymentModalActive && (
        <ThreeDPaymentModal
          htmlContent={payment?.html || paymentWithExistingCard?.html}
          onClose={toggleThreeDPaymentModalActive}
          onSuccess={() => {
            setSuccessMessage('Ödeme işleminiz başarıyla tamamlanmıştır.');
            togglePaymentSuccessModal();
            setIsPaymentModalActive(false);
            dispatch(getMarketplaceItems());
          }}
        />
      )}

      {isPaymentSuccessModalActive && (
        <PaymentSuccessModal
          selectedService={{ ...selectedService }}
          message={successMessage}
          onClose={onPaymentSuccessModalClose}
          cardInformations={cardInformations}
        />
      )}
    </div>
  );
};

const ThreeDPaymentModal = ({ htmlContent, onClose, onSuccess, ...props }) => {
  const dispatch = useDispatch();
  const { paymentStatus, payment: paymentWithExistingCard } = useSelector(
    (state) => state.payments
  );
  const { payment } = useSelector((state) => state.marketplace);
  useEffect(() => {
    if (payment?.transactionId || paymentWithExistingCard?.transactionId) {
      //TODO: the code should be removed after web socket implemented.
      const interval = setInterval(() => {
        dispatch(
          getPaymentStatus(payment?.transactionId || paymentWithExistingCard?.transactionId)
        );
      }, 3000);
      return () => {
        clearInterval(interval);
        dispatch({
          type: 'RESET_PAYMENT_STATUS'
        });
      };
    }
  }, []);

  useEffect(() => {
    switch (paymentStatus?.transactionStatus) {
      case PAYMENT_TRANSACTION_STATUS.INPROGRESS:
        //PAYMENT IN PROGRESS
        break;
      case PAYMENT_TRANSACTION_STATUS.SUCCESS:
        onSuccess();
        onClose();
        break;
      case PAYMENT_TRANSACTION_STATUS.FAILED:
        toast.error(<PaymentErrorMessage />, {
          duration: 4000
        });
        onClose();
        break;
      case PAYMENT_TRANSACTION_STATUS.REFUND:
        //PAYMENT REFUND
        break;
      default:
        break;
    }
  }, [paymentStatus]);

  return (
    <BottomModal handleClose={onClose} {...props}>
      <iframe title="d-scr" srcDoc={htmlContent} className="h-full w-full"></iframe>
    </BottomModal>
  );
};

const PaymentErrorMessage = () => (
  <div>
    <p className="font-medium text-sm text-red-100 leading-5">Ödeme Alınamadı</p>
    <p className="font-medium text-sm text-red-100 leading-5">
      Ödeme işlemi başarısız oldu. Tekrar denemeniz için yönlendiriyoruz.
    </p>
  </div>
);

export default Services;
