const FILE_TYPES = {
  CONTACT_PROFILE_PHOTO: 'CONTACT_PROFILE_PHOTO',
  INVOICE_ITEM_PHOTO: 'INVOICE_ITEM_PHOTO',
  EXPENSE_INVOICE_VOUCHER: 'EXPENSE_INVOICE_VOUCHER',
  TRANSFER_VOUCHER: 'TRANSFER_VOUCHER',
  EXPENSE_INVOICE: 'EXPENSE_INVOICE',
  SALES_INVOICE: 'SALES_INVOICE',
  SALES_INVOICE_VOUCHER: 'SALES_INVOICE_VOUCHER',
  NORMA_COMPANY_TAX_DECLARATION_RECEIPT_OF_PAYMENT:
    'NORMA_COMPANY_TAX_DECLARATION_RECEIPT_OF_PAYMENT'
};

export default FILE_TYPES;
