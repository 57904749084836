import { useEffect, useRef, useState } from 'react';

import { CustomButton, Popup } from 'components';

import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';

import { uploadFile } from 'store/actions/fileUpload';
import { updateExpenseSale } from 'store/actions/invoice';
import useDengage from 'utils/hooks/useDengage';

import toast from 'react-hot-toast';

import FILE_TYPES from 'utils/fileTypes';
import { RECORD_TYPES } from '../enums';
import { useSelector } from 'react-redux';

const UploadBox = ({ onClick }) => (
  <div
    className="w-full py-8 bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg flex flex-col justify-center items-center mt-6 cursor-pointer"
    onClick={onClick}
  >
    <FileUploadIcon className="fill-current w-8 text-gray-400" />
    <span className="text-lg font-semibold text-gray-500 mt-3">
      Fiş / Fatura eklemek için tıklayın
    </span>
    <span className="text-sm font-semibold text-gray-400 mt-2">
      veya sürükleyip buraya bırakın
    </span>
  </div>
);

const PreviewBox = ({ img, setFile, inputRef }) => {
  function deleteImg() {
    inputRef.current.value = '';
    setFile(null);
  }

  return (
    <div className="w-full p-2 bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg flex justify-start mt-6 cursor-pointer">
      <img src={URL.createObjectURL(img)} alt="preview" className="h-32 rounded-lg" />
      <div className="ml-6 flex flex-col justify-center items-start">
        <span className="font-semibold text-gray-600">{img.name}</span>
        <CustomButton color="danger" onClick={deleteImg}>
          Sil
        </CustomButton>
      </div>
    </div>
  );
};

const UploadInvoicePopup = ({ active, sale, toggleUploadInvoicePopup }) => {
  const { user } = useSelector((state) => state.auth);
  const inputRef = useRef();

  useEffect(() => {
    setFile(null);
    inputRef.current.value = '';
  }, [active]);

  const [file, setFile] = useState(null);

  const { publishEvent, eventTypes } = useDengage();

  function handleInputChange(e) {
    const _file = e.target.files[0];
    if (!_file) return;

    if (_file.size > 15 * 1000 * 1000) return toast.error('Maksimum boyut 15mb');

    setFile(_file);
  }

  async function uploadInvoice() {
    try {
      const response = await uploadFile({ file, fileType: FILE_TYPES.EXPENSE_INVOICE_VOUCHER });

      const imageId = response.data?.data?.id;

      if (!imageId) return toast.error('Bir hata oluştu');

      if (sale.recordType === RECORD_TYPES.EXPENSE) {
        _uploadExpenseSale(imageId);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function _uploadExpenseSale(imageId) {
    if (!user.companyId) return;

    updateExpenseSale({
      companyId: user.companyId,
      id: sale.id,
      payload: {
        salesExpenseExpressFileUpdateDtoList: [
          {
            fileId: imageId
          }
        ]
      }
    });
  }

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_upload_document' });
  }, []);

  return (
    <Popup active={active} style={{ width: '500px', height: 'auto' }}>
      <input
        ref={inputRef}
        type="file"
        accept=".pdf,.jpg,.png"
        className="hidden"
        onChange={handleInputChange}
      />
      <span className="text-xl font-semibold text-gray-900">Belge Yükleme</span>
      {file ? (
        <PreviewBox img={file} setFile={setFile} inputRef={inputRef} />
      ) : (
        <UploadBox onClick={() => inputRef.current.click()} />
      )}
      <div className="flex gap-x-2 items-center mt-6">
        <InfoCircleIcon className="fill-current w-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-500">
          Dosya tipi olarak .pdf, .jpg ve .png desteklenir. Maksimum boyut 15 mb
        </span>
      </div>
      <CustomButton
        color="success"
        className="mt-6 w-full"
        disabled={!file}
        onClick={uploadInvoice}
      >
        Yükle
      </CustomButton>
      <CustomButton color="danger" className="mt-4 w-full" onClick={toggleUploadInvoicePopup}>
        Vazgeç
      </CustomButton>
    </Popup>
  );
};

export default UploadInvoicePopup;
