import React, { useState } from 'react';
import { Button, Card, Chart, ContentContainer } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CHART_TYPES, CHART_FILTER_OPTIONS, getFormattedISODateForPayload } from 'utils';
import IncomeExpenseItem from '../incomeExpenseItem';
import { getSummarySeriesByCompanyId } from 'store/actions/invoice';
import PopoverSelect from 'components/popoverSelect';
import moment from 'moment';
import { ReactComponent as IconArrow } from 'assets/icons/arrow-right.svg';
import routes from 'router';
import { useRouter } from 'utils/hooks/useRouter';

export default function SalesExpenseRecords({ currency = 'TL' }) {
  const { user } = useSelector((state) => state.auth);
  const { push } = useRouter();
  const [filterBy, setFilterBy] = useState({ label: '', value: CHART_FILTER_OPTIONS.WEEK });
  const [summarySeries, setSummarySeries] = useState([]);

  const filterOptions = [
    {
      label: 'Bu hafta',
      value: 'WEEK'
    },
    {
      label: 'Bu ay',
      value: 'MONTH'
    },
    {
      label: 'Son 30 gün',
      value: 'last30Days'
    }
  ];
  useEffect(() => {
    let params = createQueryParams();

    getSummarySeriesByCompanyId(user.companyId, params)
      .then((res) => {
        setSummarySeries(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, filterBy]);

  const createQueryParams = () => {
    let params = {};
    if (
      filterBy?.value === CHART_FILTER_OPTIONS.WEEK ||
      filterBy?.value === CHART_FILTER_OPTIONS.MONTH
    ) {
      params = {
        'interval-type': filterBy?.value || filterOptions[0].value
      };
    } else {
      let startDate = getFormattedISODateForPayload(
        moment().subtract(30, 'days', true).calendar()
      );
      let endDate = getFormattedISODateForPayload(moment().format());
      params = {
        'start-date': startDate,
        'end-date': endDate
      };
    }
    return params;
  };

  return (
    <ContentContainer
      title="Satış ve Gider Kayıtları"
      className="mt-10"
      actions={
        <React.Fragment>
          <Button variant="ghost" className="!p-0" onClick={() => push(routes.invoicing.path)}>
            Tümünü Görüntüle
          </Button>
        </React.Fragment>
      }
    >
      <Card>
        <div className="flex justify-between">
          <div className="flex gap-x-4">
            <IncomeExpenseItem
              title="Satışlar"
              value={summarySeries?.salesTotalAmount || 0}
              currency={currency}
              isIncome={true}
              icon={<IconArrow className="stroke-current w-5 -rotate-90" />}
            />
            <div className="border border-r border-gray-100 border-solid h-14 self-center"></div>
            <IncomeExpenseItem
              title="Giderler"
              value={summarySeries?.expensesTotalAmount || 0}
              currency={currency}
              icon={<IconArrow className="stroke-current w-5 rotate-90" />}
            />
          </div>
          <div>
            <PopoverSelect
              options={filterOptions}
              defaultValue={filterOptions[0]}
              onChange={(item) => setFilterBy(item)}
            />
          </div>
        </div>
        <div className="border border-solid border-gray-100 my-10"></div>
        <div>
          <Chart
            type={CHART_TYPES.BAR}
            filterBy={filterBy}
            data={{
              labels: summarySeries?.series?.map((series) => series.createDate),
              datasets: [
                {
                  label: 'Satışlar',
                  backgroundColor: '#6B7280',
                  borderColor: '#6B7280',
                  borderWidth: 1,
                  hoverBackgroundColor: '#10B981',
                  hoverBorderColor: '#10B981',
                  data: summarySeries?.series?.map((series) => series.saleAmount)
                },
                {
                  label: 'Giderler',
                  backgroundColor: '#D1D5DB',
                  borderColor: '#D1D5DB',
                  borderWidth: 1,
                  hoverBackgroundColor: '#374151',
                  hoverBorderColor: '#374151',
                  data: summarySeries?.series?.map((series) => series.expenseAmount)
                }
              ]
            }}
          />
        </div>
      </Card>
    </ContentContainer>
  );
}
