import { Button, Checkbox, Popup } from 'components';
import React, { useState } from 'react';
import { COLLECTION_WARNING_POPUP_ID } from 'utils/contants';

export default function CollectionWarningPopup({ isActive, onClose }) {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const onClosePopup = () => {
    if (dontShowAgain) {
      localStorage.setItem(COLLECTION_WARNING_POPUP_ID, false);
    }
    onClose();
  };

  return (
    <Popup active={isActive} onClose={onClose} childrenClassName="!max-w-[480px] !w-full !h-auto">
      <h5 className="text-lg mb-3">Diğer Bankalardan Havale ile Tahsilat Yapın.</h5>
      <p className="mb-12 text-base leading-6 text-gray-500 font-medium">
        Norma hesabınıza, size özel işlem kodunu açıklama kısmına girerek, anlaşmalı bankalardan
        7/24 para transferi yapabilir, 15 dakika içerisinde tahsilatlarınızı alabilirsiniz.{' '}
      </p>
      <div>
        <label>
          <Checkbox className="mb-6" onChange={(e) => setDontShowAgain(e.target.checked)}>
            Tekrar Gösterme
          </Checkbox>
        </label>
        <Button variant="primary" className="w-full" onClick={onClosePopup}>
          Devam Et
        </Button>
      </div>
    </Popup>
  );
}
