import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ExchangeModalContext } from '../../..';
import { Button } from 'components';
import useModal from 'utils/hooks/useModal';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import DoubleCheck from 'assets/illustrations/card/double-check.png';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
// import { SuccessModal } from 'components';

export default function ExchangeSuccess() {
  const { onClose } = useContext(ExchangeModalContext);
  const { buyorSellForeignCurrency } = useSelector((state) => state.exchange);
  const [isReceiptActive, , toggleReceipt] = useModal();

  if (isReceiptActive) {
    return (
      <ExchangeReceipt receiptUrl={buyorSellForeignCurrency?.receiptUrl} onClose={onClose} />
    );
  }
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-6 mt-16">
      <img src={DoubleCheck} alt="double-check" width="100" />
      <p className="text-3xl text-white max-w-md text-center">
        Döviz Alış işleminiz başarıyla tamamlanmıştır
      </p>

      <Button onClick={toggleReceipt} color="secondary" className="w-[400px]">
        Dekont Göster
      </Button>
      <Button onClick={onClose} className="w-[400px]">
        Kapat
      </Button>
    </div>
  );
}

const ExchangeReceipt = ({ receiptUrl, onClose }) => {
  const onDownload = () => {
    const a = document.createElement('a');
    a.href = receiptUrl;
    a.download = receiptUrl.split('/').pop();
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className="mx-auto w-96 h-full flex flex-col items-center justify-center">
      <div className="w-full flex justify-between items-center">
        <span className="text-2xl font-semibold text-white">Dekont</span>
        <div
          className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
          onClick={onDownload}
        >
          <ShareIcon className="fill-current text-gray-500 w-4" />
        </div>
      </div>
      <div className="h-auto w-auto overflow-y-scroll rounded-xl overflow-hidden mt-5">
        <ReceiptPdf pdfUrl={receiptUrl} />
      </div>
      <Button className="mt-6 w-full" onClick={onClose}>
        Kapat
      </Button>
    </div>
  );
};

const ReceiptPdf = ({ pdfUrl, page = 1 }) => (
  <Document
    file={pdfUrl}
    onLoadError={console.log}
    className="rounded-xl"
    data-testid="pdf_receipt_account"
  >
    <Page
      pageNumber={page}
      className="w-96 rounded-xl"
      width={384}
      data-testid="page_receipt_account"
    />
  </Document>
);
