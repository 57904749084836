import React from 'react';
import TabSubTitle from 'views/profile/tabs/tabSubTitle';

const ServiceDetail = () => {
  return (
    <div className="w-full mb-4 flex flex-col">
      <TabSubTitle>
        <span className="text-gray-600">Paketler</span>
        <div className="flex flex-wrap justify-center gap-10 mt-6">detay</div>
      </TabSubTitle>
    </div>
  );
};

export default ServiceDetail;
