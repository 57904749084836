import { Bar } from 'react-chartjs-2';
import { DAYS, MONTHS } from 'utils';

const LABELS = {
  WEEK: 'day',
  MONTH: 'date',
  YEAR: 'month'
};

const SaleChart = ({ chartInterval, summarySeries }) => {
  const chartData = {
    labels: summarySeries?.series
      ? summarySeries.series
          .map((s, i) => ({
            ...s,
            date: new Date(s.createDate).getDate(),
            month: MONTHS[i],
            day: DAYS[i]
          }))
          .map((s) => s[LABELS[chartInterval]])
      : [],
    datasets: [
      {
        borderRadius: 5,
        borderColor: '#10B981',
        barPercentage: 0.5,
        label: 'Satış',
        pointStyle: 'dash',
        data: summarySeries?.series
          ? summarySeries.series
              .map((s, i) => ({
                ...s,
                date: new Date(s.createDate).getDate(),
                month: MONTHS[i],
                day: DAYS[i]
              }))
              .map((s) => s.saleAmount)
          : [],
        backgroundColor: 'rgba(16, 185, 129, 1)',
        fill: { value: 0.1 }
      },
      {
        borderRadius: 5,
        borderColor: '#8B5CF6',
        barPercentage: 0.5,
        label: 'Gider',
        pointStyle: 'dash',
        data: summarySeries?.series
          ? summarySeries.series
              .map((s, i) => ({
                ...s,
                date: new Date(s.createDate).getDate(),
                month: MONTHS[i],
                day: DAYS[i]
              }))
              .map((s) => s.expenseAmount)
          : [],
        backgroundColor: 'rgba(139, 92, 246, 1)',
        fill: { value: 0.1 }
      }
    ]
  };

  const chartOptions = {
    plugins: {
      title: { display: false },
      legend: { display: false },
      decimation: { enabled: false }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    }
  };

  return (
    <div className="flex flex-col">
      <Bar data={chartData} options={chartOptions} height={50} />
    </div>
  );
};

export default SaleChart;
