export default {
  GET_CHECK_CARD_ALIAS: 'GET_CHECK_CARD_ALIAS',
  GET_CHECK_CARD_ALIAS_SUCCESS: 'GET_CHECK_CARD_ALIAS_SUCCESS',
  GET_CHECK_CARD_ALIAS_FAILURE: 'GET_CHECK_CARD_ALIAS_FAILURE',
  MAKE_PAYMENT: 'MAKE_PAYMENT',
  MAKE_PAYMENT_FAILURE: 'MAKE_PAYMENT_FAILURE',
  MAKE_PAYMENT_SUCCESS: 'MAKE_PAYMENT_SUCCESS',
  GET_PAYMENT_STATUS: 'GET_PAYMENT_STATUS',
  GET_PAYMENT_STATUS_FAILURE: 'GET_PAYMENT_STATUS_FAILURE',
  GET_PAYMENT_STATUS_SUCCESS: 'GET_PAYMENT_STATUS_SUCCESS',
  RESET_PAYMENT_STATUS: 'RESET_PAYMENT_STATUS'
};
