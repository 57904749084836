import React, { useState, useEffect } from 'react';

import PopoverSelect from 'components/popoverSelect';
import { Checkbox, DatePicker, Label } from 'components';
import { BUSINESS_TYPES, DEBT_STATUS } from 'utils';
import AmountInput from 'components/AmountInput';
import BusinessTypeItem from 'components/popoverSelect/businessTypeItem';

import moment from 'moment';
import { useContactContext } from 'contexts/contactContext';

export default function CurrentAccountInformation() {
  const [initialBalanceDate, setInitialBalanceDate] = useState(
    new Date(moment().subtract(1, 'day'))
  );

  const { setFormValues, formValues, selectedContact } = useContactContext();

  const businessTypeOptions = Object.values(BUSINESS_TYPES).map((businessType) => ({
    label: businessType.label,
    value: businessType.value
  }));

  const debtStatusOptions = Object.values(DEBT_STATUS).map((debtStatus) => ({
    label: debtStatus.label,
    value: debtStatus.value
  }));

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      initialBalanceDate
    }));
  }, [initialBalanceDate]);

  useEffect(() => {
    if (formValues?.hasOpeningBalance) {
      setFormValues((prevValues) => ({
        ...prevValues,
        initialBalanceDate: new Date(moment().subtract(1, 'day'))
      }));
    }
  }, [formValues?.hasOpeningBalance]);

  const onChangeInitialBalance = (balance) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      initialBalance: balance
    }));

    if (
      [DEBT_STATUS.NONE, DEBT_STATUS.NONE.value].includes(formValues.debtStatus) &&
      balance > 0
    ) {
      return setFormValues((prevValues) => ({
        ...prevValues,
        debtStatus: DEBT_STATUS.NONE.value
      }));
    }

    if (balance === 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        debtStatus: DEBT_STATUS.NONE.value
      }));
    }
  };
  const onChangeDebtStatus = (status) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      debtStatus: status.value
    }));

    if (status.value === DEBT_STATUS.NONE.value) {
      return setFormValues((prevValues) => ({
        ...prevValues,
        initialBalance: 0
      }));
    }
  };
  const onChangeBusinessType = (type) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      businessType: type.value
    }));
  };

  const onChangeOpeningBalanceVisibility = (e) => {
    const { checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      hasOpeningBalance: checked
    }));
  };

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-[18px] text-gray-600 font-semibold">Cari Detayları</p>
      <Label label="Cari Tipi">
        <PopoverSelect
          className="w-full"
          labelClassName="w-full"
          panelClassName="!w-full !p-3 !max-h-56 shadow-norma"
          customItemElement={BusinessTypeItem}
          hasBaseFormClass
          options={businessTypeOptions.filter((bt) => bt.value)}
          selectedOption={businessTypeOptions.find((bt) => bt.value === formValues?.businessType)}
          defaultValue={
            formValues?.businessType
              ? businessTypeOptions.find((bt) => bt.value === formValues?.businessType)
              : businessTypeOptions[0]
          }
          onChange={onChangeBusinessType}
        />
      </Label>
      <InitialBalanceCheckBox
        shouldBeShow={selectedContact?.id && !selectedContact?.initialBalanceDate}
        onChange={onChangeOpeningBalanceVisibility}
        checked={formValues?.hasOpeningBalance}
      />
      {formValues?.hasOpeningBalance && (
        <>
          <AmountInput
            label="Açılış Bakiyesi"
            value={formValues?.initialBalance}
            onValueChange={onChangeInitialBalance}
          />
          <Label label="Borç/Alacak Durumu">
            <PopoverSelect
              className="w-full"
              labelClassName="w-full"
              panelClassName="!w-full"
              hasBaseFormClass
              options={debtStatusOptions}
              selectedOption={debtStatusOptions.find((ds) => ds.value === formValues?.debtStatus)}
              defaultValue={
                formValues?.debtStatus
                  ? debtStatusOptions.find((ds) => ds.value === formValues?.debtStatus)
                  : debtStatusOptions[0]
              }
              onChange={onChangeDebtStatus}
              disabled={formValues?.initialBalance <= 0}
            />
          </Label>

          <div>
            <DatePicker
              label="Bakiye Tarihi"
              className="bg-transparent py-3 w-full focus:outline-none text-black"
              placeholder="Tarih seçin"
              isFormInput
              selected={initialBalanceDate}
              maxDate={new Date(moment().subtract(1, 'day'))}
              onChange={setInitialBalanceDate}
            />
            <span className="text-xs font-medium text-gray-400">
              Vade tarihi bu tarihten öncesine girilen satış ve gider kayıtları bakiyeyi
              etkileyemeyecektir.
            </span>
          </div>
        </>
      )}
    </div>
  );
}

const InitialBalanceCheckBox = ({ shouldBeShow = true, onChange, checked }) => {
  if (shouldBeShow)
    return (
      <Checkbox checked={checked} onChange={onChange}>
        Müşteri / tedarikçinin açılış bakiyesi var.
      </Checkbox>
    );

  return null;
};
