import React, { useContext, useEffect, useState } from 'react';
import { Button, Popup } from 'components';
import { currencyFormat, EXCHANGE_TYPES } from 'utils';
import { CURRENCIES } from 'views/other/items/enums';
import { ExchangeModalContext } from '../../..';
import { useSelector } from 'react-redux';
import { useRouter } from 'utils/hooks/useRouter';
import routes from 'router';

import { ReactComponent as IconUSFlag } from 'assets/icons/us-flag.svg';
import { ReactComponent as IconEUFlag } from 'assets/icons/eu-flag.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';

import cn from 'classnames';
import useModal from 'utils/hooks/useModal';

export default function ExchangeCurrencySelection() {
  const { setSubTitle, nextStep, formValues, setFormValues } = useContext(ExchangeModalContext);
  const { rate } = useSelector((state) => state.exchange);

  useEffect(() => {
    setSubTitle('İşlem tipi seçin');
    setFormValues((prevValues) => ({
      ...prevValues,
      buyAmount: 0,
      rate: 0,
      sellAmount: 0
    }));
  }, []);

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex justify-end pb-4 gap-x-6 border-solid border-b border-gray-100 text-center">
        <SellBuyTitle>Alış</SellBuyTitle>
        <SellBuyTitle>Satış</SellBuyTitle>
      </div>
      <CurrencyCard
        text="Dolar ($)"
        icon={<IconUSFlag />}
        currencyCode={CURRENCIES.USD}
        sellingValue={rate?.rateList?.find((rt) => rt.currency === CURRENCIES.USD)?.sellRate}
        buyingValue={rate?.rateList?.find((rt) => rt.currency === CURRENCIES.USD)?.buyRate}
      />
      <CurrencyCard
        text="EURO (€)"
        icon={<IconEUFlag />}
        currencyCode={CURRENCIES.EUR}
        sellingValue={rate?.rateList?.find((rt) => rt.currency === CURRENCIES.EUR)?.sellRate}
        buyingValue={rate?.rateList?.find((rt) => rt.currency === CURRENCIES.EUR)?.buyRate}
      />
      <Button
        className="w-full"
        onClick={nextStep}
        disabled={!formValues.exchangeType && !formValues.currencyCode}
      >
        Devam
      </Button>
    </div>
  );
}

const SellBuyTitle = ({ children, ...props }) => {
  return (
    <span
      className="flex-initial w-[168px] text-sm text-gray-500 leading-5 font-medium"
      {...props}
    >
      {children}
    </span>
  );
};

const BuyingSellingLabel = ({
  value = 0,
  currency = CURRENCIES.TRY,
  isSelected = false,
  className,
  ...props
}) => {
  const classNames = cn(
    'text-center w-[168px] text-gray-900 text-lg font-semibold py-4 px-11 border border-solid border-gray-200 rounded-lg bg-gray-100 cursor-pointer select-none',
    className,
    { 'bg-white border-green-600': isSelected }
  );

  return (
    <div className={classNames} {...props}>
      {currencyFormat(value, 3)} {currency}
    </div>
  );
};

const CurrencyCard = ({
  icon,
  text,
  sellingValue = 0,
  buyingValue = 0,
  currencyCode = 'TL',
  buyingSellingCurrencyCode = 'TL'
}) => {
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null);
  const { formValues, setFormValues } = useContext(ExchangeModalContext);
  const accounts = useSelector((state) => state.accounts);
  const { push, pathname } = useRouter();

  const [isExchangeTypeSelectionWarningPopupActive, , toggleExchangeTypeSelectionPopup] =
    useModal(false);

  const onExchangeTypeSelected = (exchangeType, currencyCode) => {
    setSelectedCurrencyCode(currencyCode);
    //check if currency type accout is exist. If the account is not exist, redirect to create it.
    if (!accounts.some((account) => account.currencyCode === currencyCode)) {
      return toggleExchangeTypeSelectionPopup();
    }
    setFormValues({
      ...formValues,
      exchangeType,
      currencyCode,
      rate: exchangeType === EXCHANGE_TYPES.SELL ? sellingValue : buyingValue
    });
  };

  const redirectToOpenAnAccount = () =>
    push(`${routes.accounts.path}?redirectUrl=${pathname}&currencyCode=${selectedCurrencyCode}`);

  return (
    <div>
      {isExchangeTypeSelectionWarningPopupActive && (
        <ExchangeAccountWarningPopup
          onCancel={toggleExchangeTypeSelectionPopup}
          onClose={toggleExchangeTypeSelectionPopup}
          onApprove={redirectToOpenAnAccount}
        />
      )}
      <div className="flex justify-between">
        <div className="flex items-center gap-x-4">
          {icon} <span className="text-gray-900 font-medium leading-6">{text}</span>
        </div>
        <div className="flex gap-x-6">
          <BuyingSellingLabel
            value={buyingValue}
            currency={buyingSellingCurrencyCode}
            isSelected={
              formValues?.exchangeType === EXCHANGE_TYPES.BUY &&
              formValues.currencyCode === currencyCode
            }
            onClick={() => onExchangeTypeSelected(EXCHANGE_TYPES.BUY, currencyCode)}
          />
          <BuyingSellingLabel
            value={sellingValue}
            currency={buyingSellingCurrencyCode}
            isSelected={
              formValues?.exchangeType === EXCHANGE_TYPES.SELL &&
              formValues.currencyCode === currencyCode
            }
            onClick={() => onExchangeTypeSelected(EXCHANGE_TYPES.SELL, currencyCode)}
          />
        </div>
      </div>
    </div>
  );
};

const ExchangeAccountWarningPopup = ({ onClose, onCancel, onApprove }) => {
  const { formValues } = useContext(ExchangeModalContext);
  return (
    <Popup active onClose={onClose} childrenClassName="!h-auto">
      <div className="flex flex-col justify-between items-center w-full h-full">
        <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100">
          <InfoCircleIcon className="fill-current w-10 h-10 text-yellow-600" />
        </div>
        <span className="mt-9 text-gray-900 text-center font-semibold">
          Döviz hesabınız bulunmamakta,{' '}
          {formValues.currencyCode === CURRENCIES.USD ? 'Dolar' : 'Euro'} hesabı açmak ister
          misiniz?
        </span>
        <div className="w-full">
          <Button className="mt-4 w-full" onClick={onApprove}>
            Evet
          </Button>
          <Button color="red" className="mt-4 w-full" onClick={onCancel}>
            Vazgeç
          </Button>
        </div>
      </div>
    </Popup>
  );
};
