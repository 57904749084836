import React, { useEffect, useContext } from 'react';
import DoubleCheck from 'assets/illustrations/card/double-check.png';
import { Button } from 'components';
import { BillContext } from '../..';
import { getBillSubscriptions } from 'store/actions/bill';
import { useDispatch } from 'react-redux';

export default function BillCreateSuccess({ onClose }) {
  const { setShowBackButton } = useContext(BillContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowBackButton(false);
    dispatch(getBillSubscriptions());
    return () => {
      setShowBackButton(true);
    };
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-6">
      <img src={DoubleCheck} alt="double-check" width="100" />
      <p className="text-3xl text-white max-w-md text-center">Fatura başarıyla kaydedildi</p>
      <Button onClick={onClose} className="w-[400px]">
        Kapat
      </Button>
    </div>
  );
}
