import { Popup, CustomButton } from 'components';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const ConfirmDeletePopup = ({ active, onClose, handleDelete }) => (
  <Popup active={active} onClose={onClose} style={{ width: '311px', height: 'auto' }}>
    <div className="flex flex-col justify-center items-center">
      <div className="w-20 h-20 rounded-full bg-red-100 flex justify-center items-center">
        <TrashIcon className="fill-current w-10 text-red-600" />
      </div>
      <span className="mt-6 text-lg font-semibold text-gray-900">Kaydı Sil</span>
      <span className="mt-3 text-sm font-medium text-gray-600">
        Kaydı silmek istediğine emin misin?
      </span>
      <CustomButton color="danger" onClick={handleDelete} className="w-full mt-6">
        Sil
      </CustomButton>
      <CustomButton color="success" onClick={onClose} className="w-full mt-4">
        İptal Et
      </CustomButton>
    </div>
  </Popup>
);

export default ConfirmDeletePopup;
