import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { loginWMfaToken, forgotMe } from 'store/actions/auth';
import { useHistory } from 'react-router-dom';

import { Step0, Step1, Step2, RMStep0 } from './Steps';
import errorCodes from 'utils/errorCodes';
import { getCompany } from 'store/actions/company';
import { useRouteStep } from 'utils/hooks/useRouteStep';
import { useRouter } from 'utils/hooks/useRouter';
import routes from 'router';
import useGTM from 'utils/hooks/useGTM';

const defaultValues = {
  phone: '',
  password: '',
  otpToken: '',
  rememberMe: false
};

const Login = () => {
  const router = useRouter();
  const { accessRouter } = routes;
  const { sendGTMEvent } = useGTM();
  const [step, nextStep] = useRouteStep(
    { initialStep: 0, max: 3, fallbackPath: '/welcome', router },
    (step) => {
      const notAllowedCallbackSteps = [];
      var allowedStep = step;

      while (notAllowedCallbackSteps.includes(allowedStep)) {
        allowedStep -= 1;
      }

      nextStep(allowedStep);
    }
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    dispatch(loginWMfaToken(data))
      .then(() => {
        clearErrors('otpToken');
        dispatch(getCompany()).then(() => {
          history.push(accessRouter.path);
        });
        sendGTMEvent({ event: 'catEvent', eventName: 'login' });
      })
      .catch((err) => {
        if (err[errorCodes.otpNotFound]) {
          setError('otpToken', {
            type: 'warning',
            message: err[errorCodes.otpNotFound]
          });
        }
      });
  };

  const { rememberMeToken, user } = useSelector((state) => state.auth);

  function _forgotMe() {
    dispatch(forgotMe());
  }

  const _STEPS = {
    0: <Step0 register={register} nextStep={nextStep} watch={watch} setValue={setValue} />,
    1: (
      <Step1
        nextStep={nextStep}
        watch={watch}
        setValue={setValue}
        setError={setError}
        errors={errors}
      />
    ),
    2: (
      <Step2
        setValue={setValue}
        isCorrect={!!watch('otpToken')}
        watch={watch}
        errors={errors}
        length={4}
      />
    )
  };

  const _REMEMBER_ME_STEPS = {
    0: <RMStep0 user={user} forgotMe={_forgotMe} />
  };

  const STEPS = rememberMeToken ? _REMEMBER_ME_STEPS : _STEPS;

  return (
    <form
      className="w-full h-full flex items-center justify-between md:justify-center max-w-[400px] md:flex-1"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="form_login"
    >
      {STEPS[step]}
    </form>
  );
};

export default Login;
