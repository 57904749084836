import React, { useEffect } from 'react';
import LoginFigure from 'assets/images/login-figure.png';
import { useDeviceDetection } from 'utils/hooks';
import classNames from 'classnames';

export default function OnboardingContainer({ children, ...props }) {
  const { isMobile, isAndroid, isIOS } = useDeviceDetection();

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        document?.body?.classList?.add('h-[calc(90vh)]', 'pb-[50px]');
      }
      if (isIOS) {
        document?.body?.classList?.add('h-[calc(84vh)]', 'pb-[50px]');
      }
    }
  }, [isMobile, isAndroid, isIOS]);
  if (isMobile) {
    if (isIOS) return <IOSSafeAreaContainer>{children}</IOSSafeAreaContainer>;
    if (isAndroid) return <AndroiSafeAreaContainer>{children}</AndroiSafeAreaContainer>;
  }

  return (
    <div className="flex justify-between h-screen" {...props}>
      <div className="flex justify-center items-center w-full">{children}</div>
      <img src={LoginFigure} alt="login-figure" className="hidden xl:block" />
    </div>
  );
}

const AndroiSafeAreaContainer = ({ children }) => {
  return <div className="px-6 pt-6 h-[calc(90vh)]">{children}</div>;
};

const IOSSafeAreaContainer = ({ children }) => {
  useEffect(() => {}, []);
  const safeAreaContainerclassName = classNames('px-6 pt-6 h-[calc(84vh)]', {});

  return <div className={safeAreaContainerclassName}>{children}</div>;
};
