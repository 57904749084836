import { useEffect, useState } from 'react';
import useDengage from 'utils/hooks/useDengage';
import { CodeInput, CustomButton } from 'components';

import { useContext } from 'react';
import StepHeader from 'components/stepHeader';
import { ChangeCardPasswordContext } from '../..';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';
import toast from 'react-hot-toast';
import { PIN_PASSWORD_LENGTH } from 'utils';

export default function EnterPassword() {
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const { publishEvent, eventTypes } = useDengage();

  const { setCardPin, cardPin, nextStep } = useContext(ChangeCardPasswordContext);

  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'accounts_card_password' }), []);

  useEffect(() => {
    if (cardPin?.length === PIN_PASSWORD_LENGTH) {
      if (PASSWORD_SEQUENTIAL_REGEX.test(cardPin)) {
        return toast.error('Şifreniz ardışık rakamlardan oluşamaz.');
      }
      if (PASSWORD_REPEATING_REGEX.test(cardPin)) {
        return toast.error('Şifreniz tekrarlayan rakamlardan oluşamaz.');
      } else {
        return setIsPasswordValid(true);
      }
    }
    setIsPasswordValid(false);
  }, [cardPin]);
  return (
    <div className="flex flex-col" data-testid="label_modal_change_password">
      <StepHeader
        title="Şifresi Değiştir"
        subtitle="4 haneli kart şifrenizi belirleyin"
        data-testid="label_modal_change_password"
      />
      <CodeInput
        length={4}
        invisible
        onChange={setCardPin}
        className="mt-4"
        only="number"
        blockPaste
        value={cardPin}
        data-testid="input_modal_change_password"
        autofocus
      />
      <CustomButton
        className="mt-6 w-96"
        color="success"
        onClick={nextStep}
        disabled={!isPasswordValid}
        data-testid="button_modal_change_password"
      >
        Onayla
      </CustomButton>
    </div>
  );
}
