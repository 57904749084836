import { ReactComponent as IconClose } from '../assets/icons/close.svg';
import cn from 'classnames';
import Button from './button';

const BottomModal = ({ title, children, handleClose, className, isActive = true, ref }) => {
  const classnames = cn('fixed bottom-0 left-0 w-screen h-screen z-50 flex flex-col', {
    ['hidden']: !isActive
  });
  return (
    <div className={classnames} ref={ref}>
      <div
        className="cursor-pointer w-full h-24 bg-gray-900 bg-opacity-60 flex items-center justify-center z-40"
        onClick={handleClose}
      />
      <div className={`${className} w-full flex-grow bg-white flex flex-col z-50 h-5/6 relative`}>
        <div className="flex justify-between items-center mb-10 absolute right-6 top-6">
          <span className="font-semibold text-2xl">{title}</span>
          <Button variant="circle" color="gray" icon={IconClose} onClick={handleClose} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default BottomModal;
