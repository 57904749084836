const Select = ({
  options,
  label,
  onChange,
  value,
  valueLabel,
  className,
  defaultValue,
  disabled,
  error,
  selectRef,
  isOptional = false,
  prefix,
  hasEmptyItem = true,
  ...props
}) => (
  <label className={`${className} block text-left`} data-testid={`input_label_${label}`}>
    <span className="text-gray-500 text-sm">
      {label}{' '}
      {isOptional && (
        <span className="text-sm leading-5 font-medium text-gray-400 italic">
          {'(Opsiyonel)'}
        </span>
      )}
    </span>
    <div
      className={`px-2 bg-gray-100 rounded-lg w-full border border-solid ${
        error ? 'border-red-500' : 'border-transparent'
      }`}
    >
      <select
        ref={selectRef}
        className="w-full mt-1 bg-transparent py-[10px] focus:outline-none disabled:text-gray-400"
        onChange={(e) => {
          if (typeof onChange === 'function') onChange(e.target.value);
        }}
        value={defaultValue}
        defaultValue={defaultValue}
        disabled={disabled}
        {...props}
      >
        {hasEmptyItem && <option value={null}></option>}
        {options?.map((opt, i) => (
          <option key={i} value={value ? opt[value] : opt}>
            {prefix} {valueLabel ? opt[valueLabel] : opt}
          </option>
        ))}
      </select>
    </div>
    {error && <label className="text-xs text-red-500 flex items-center mt-2">{error}</label>}
  </label>
);

export default Select;
