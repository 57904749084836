import { CustomButton } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getItems } from 'store/actions/items';

import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter-2.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconCloseSquare } from 'assets/icons/close-square.svg';

// import AddItemModal from './components/AddItemModal';
import ItemsTable from './components/ItemsTable';
import useModal from 'utils/hooks/useModal';
import FilterModal from './components/FilterModal';
import { ITEM_TYPE_NAMES } from './enums';
import useDebounce from 'utils/hooks/useDebounce';
import useDengage from 'utils/hooks/useDengage';
import { useRef } from 'react';
import { useItemContext } from 'contexts/itemContext';
// import AddItemModal from './components/AddItemModal';
import { AddNewItemModal } from './components';

const Items = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { publishEvent, eventTypes } = useDengage();
  const { isAddNewItemModalActive, toggleAddNewItemModal } = useItemContext();

  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({ type: null });
  const [paginatedItems, setPaginatedItems] = useState([]);

  const { filteredItems, totalElements, totalPages } = useSelector((state) => state.items);
  // const [isAddItemModalActive, , toggleAddItemModal] = useModal({
  //   handleClose: _getItems,
  //   shouldBeBlockScroll: true
  // });
  const [isFilterModalActive, , toggleFilterModal] = useModal();

  const searchInputRef = useRef(null);
  useDebounce(searchTerm, 500, searchInputRef, () => {
    setPage(0);
    setPaginatedItems([]);
    _getItems({
      currentPage: page,
      currentFilters: filters,
      currentSearchTerm: searchTerm
    });
  });

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_item' });
  }, []);

  useEffect(() => {
    setPage(0);
    setPaginatedItems([]);
  }, [filters]);

  useEffect(() => {
    _getItems({
      currentPage: page,
      currentFilters: filters,
      currentSearchTerm: searchTerm
    });
  }, [page, filters]);

  useEffect(() => {
    setPaginatedItems((prevItems) =>
      [
        ...new Map(
          [...(page === 0 ? [] : prevItems), ...filteredItems].map((item) => [item['id'], item])
        ).values()
      ].sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
    );
  }, [filteredItems]);

  function handleFilters(type) {
    setFilters({ type });
  }

  function _getItems(params = { currentPage: 0, currentSearchTerm: '', currentFilters: null }) {
    dispatch(
      getItems({
        size: 10,
        page: params?.currentPage,
        orderBy: 'createDate',
        name: params?.currentSearchTerm,
        orderDirection: 'DESC',
        ...params?.currentFilters
      })
    );
  }

  function handleEditItem(item) {
    history.push(`/items/${item.id}`);
  }

  return (
    <div className="flex flex-col">
      {isAddNewItemModalActive && <AddNewItemModal onClose={toggleAddNewItemModal} />}
      {isFilterModalActive && (
        <FilterModal
          handleClose={toggleFilterModal}
          handleFilters={handleFilters}
          filters={filters}
        />
      )}
      <div className="flex flex-col items-start gap-y-8">
        <span className="text-xl text-gray-900 font-semibold">Ürünler ({totalElements})</span>
        <div className="w-full flex flex-wrap gap-y-2">
          <div className="bg-gray-100 text-gray-400 p-3 flex items-center rounded-lg">
            <IconSearch className="fill-current w-5 mr-3" />
            <input
              className="appearance-none focus:outline-none bg-transparent text-gray-900"
              placeholder="Arama"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              ref={searchInputRef}
            />
          </div>
          <CustomButton className="ml-4" onClick={toggleFilterModal} data-testid="button_filter">
            <div className="flex items-center gap-x-2">
              <IconFilter className="fill-current w-5" />
              <span>Filtrele</span>
            </div>
          </CustomButton>
          <CustomButton
            color="success"
            className="ml-auto"
            onClick={toggleAddNewItemModal}
            data-testid="button_add_product"
          >
            <div className="flex items-center gap-x-2">
              <IconPlus className="stroke-current w-5" />
              <span>Ürün Ekle</span>
            </div>
          </CustomButton>
        </div>
        {!!Object.values(filters).filter((f) => !!f).length && (
          <div>
            <span className="px-3 py-2 rounded-full bg-gray-100 flex items-center gap-x-2">
              <span>{ITEM_TYPE_NAMES[filters.type]}</span>
              <IconCloseSquare
                className="fill-current w-4 text-gray-500 cursor-pointer"
                onClick={() => setFilters({ ...filters, type: null })}
              />
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-6 gap-y-6">
        <ItemsTable selectedItems={paginatedItems} handleEditItem={handleEditItem} />
        {page + 1 < totalPages && (
          <div
            className="flex justify-center"
            onClick={() => setPage((prevPage) => prevPage + 1)}
            data-testid="button_load_more"
          >
            <CustomButton>Daha Fazla Göster</CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default Items;
