import React, { useState, useContext, createContext } from 'react';
import { Divider } from 'components';
import {
  OperationTabs,
  SmartOperationCardButton,
  //Automatic Bill Payment
  AutoBillPaymenModal,
  AutoBillPaymentCreate,
  AutoBillPaymentSuccess,
  AutoBillPaymentDetail,
  AutoBillEditSideModal,
  //Automatic Transaction
  AutomaticTransactionCreate,
  AutomaticTransactionDetail,
  AutomaticTransactionSuccess,
  AutomaticTransactionModal,
  AutomaticTransactionEditSideModal
} from './components';
import useModal from 'utils/hooks/useModal';

import { ReactComponent as IconInvoice } from 'assets/icons/invoice.svg';
import { AUTOMATIC_TRANSACTION_RECEIVER_TYPES, AUTOMATIC_TRANSACTION_REPEAT_TYPES } from 'utils';
import moment from 'moment';
import { useEffect } from 'react';
import { getDaysOfMoth, getDaysOfWeek } from 'store/actions/enums';
import { useDispatch } from 'react-redux';

const REPEAT_TYPE_MAP = Object.entries(AUTOMATIC_TRANSACTION_REPEAT_TYPES).map(
  ([value, type]) => ({
    label: type.label,
    value
  })
);

const initialValues = {
  billPaymentFormValues: {
    category: null,
    corporation: null,
    subscriberNo: null,
    shoudbeSaveBill: true,
    billRecordName: '',
    autoPayment: true,
    wallet: null
  },
  automaticTransactionFormValues: {
    recordName: '',
    receiverType: AUTOMATIC_TRANSACTION_RECEIVER_TYPES.CONTACT,
    contact: null,
    wallet: null,
    senderWallet: null,
    receiverWallet: null,
    receiverFullName: null,
    receiverIban: null,
    receiverWalletNumber: null,
    repeatCount: 1,
    repeatType: REPEAT_TYPE_MAP[0],
    transferDay: null,
    paymentStartDate: new Date(moment().add(1, 'days')),
    amount: null
  }
};

export const SmartOperationContext = createContext({
  step: 0,
  setStep: () => {},
  nextStep: () => {},
  previousStep: () => {},
  //Automatic Bill Payment
  billPaymentFormValues: initialValues.formValues,
  setBillPaymentFormValues: () => {},
  selectedBill: null,
  setSelectedBill: () => {},
  isCreateAutoBillPaymentModalActive: false,
  toggleCreateAutoBillPayment: () => {},
  showBackButton: false,
  setShowBackButton: () => {},
  resetBillPaymentFormValues: () => {},
  isAutoBillPaymentDetailModalActive: false,
  toggleAutoBillDetailPayment: () => {},
  isAutoBillEditSideModalActive: false,
  toggleAutoBillEditSideModal: () => {},
  //Automatic Transaction
  automaticTransactionFormValues: initialValues.automaticTransactionFormValues,
  setAutomaticTransactionFormValues: () => {},
  selectedAutomaticTransaction: null,
  setSelectedAutomaticTransaction: () => {},
  isCreateAutomaticTransactionModalActive: false,
  toggleCreateAutomaticTransactionModal: () => {},
  isAutomaticTransactionDetailModalActive: false,
  toggleAutomaticTransactionDetailModal: () => {},
  isAutomaticTransactionEditModalActive: false,
  toggleAutomaticTransactionEditModal: () => {},
  resetAutomaticTransactionFormValues: () => {}
});

export const useSmartOperationContext = () => useContext(SmartOperationContext);

export default function SmartOperations() {
  const [step, setStep] = useState(0);
  const [showBackButton, setShowBackButton] = useState(false);
  //Automatic Bill Payment
  const [selectedBill, setSelectedBill] = useState(null);
  const [billPaymentFormValues, setBillPaymentFormValues] = useState(
    initialValues.billPaymentFormValues
  );
  //Automatic Transaction
  const [selectedAutomaticTransaction, setSelectedAutomaticTransaction] = useState(null);
  const [automaticTransactionFormValues, setAutomaticTransactionFormValues] = useState(
    initialValues.automaticTransactionFormValues
  );

  //Automatic Bill Payment
  const [isCreateAutoBillPaymentModalActive, , toggleCreateAutoBillPayment] = useModal({
    shouldBeBlockScroll: true
  });

  const [isAutoBillPaymentDetailModalActive, , toggleAutoBillDetailPayment] = useModal({
    shouldBeBlockScroll: true
  });
  const [isAutoBillEditSideModalActive, , toggleAutoBillEditSideModal] = useModal({
    shouldBeBlockScroll: true
  });

  //Automatic Transaction
  const [isCreateAutomaticTransactionModalActive, , toggleCreateAutomaticTransactionModal] =
    useModal({
      shouldBeBlockScroll: true
    });

  const [isAutomaticTransactionDetailModalActive, , toggleAutomaticTransactionDetailModal] =
    useModal({
      shouldBeBlockScroll: true
    });
  const [isAutomaticTransactionEditModalActive, , toggleAutomaticTransactionEditModal] = useModal(
    {
      shouldBeBlockScroll: true
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDaysOfMoth());
    dispatch(getDaysOfWeek());
  }, []);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const previousStep = () => setStep((prevStep) => prevStep - 1);

  //Automatic Bill Payment
  const resetBillPaymentFormValues = () => {
    setBillPaymentFormValues(initialValues.billPaymentFormValues);
    setStep(0);
  };

  //Automatic Bill Payment
  const autoBillPaymentModalSteps = [
    <AutoBillPaymentCreate key="AutoBillPaymentCreate" isNewBillCreate />,
    <AutoBillPaymentSuccess
      message="Otomatik fatura başarıyla kaydedildi"
      key="AutoBillPaymentCreateSuccess"
      onClose={toggleCreateAutoBillPayment}
    />
  ];
  //Automatic Bill Payment
  const autoBillPaymenDetailSteps = [
    <AutoBillPaymentDetail key="AutoBillPaymentDetail" />,
    <AutoBillPaymentSuccess
      message="Otomatik fatura başarıyla silindi"
      key="AutoBillPaymentDeleteSuccess"
      onClose={toggleAutoBillDetailPayment}
    />
  ];

  //Automatic Transaction
  const resetAutomaticTransactionFormValues = () => {
    setAutomaticTransactionFormValues(initialValues.automaticTransactionFormValues);
    setStep(0);
  };

  //Automatic Transaction
  const automaticTransactionCreateModalSteps = [
    <AutomaticTransactionCreate key="AutomaticTransactionCreate" />,
    <AutomaticTransactionSuccess
      message="Otomatik Para Transferi başarıyla oluşturuldu"
      key="AutomaticTransactionCreateSuccess"
    />
  ];
  //Automatic Transaction
  const automaticTransactionDetailSteps = [
    <AutomaticTransactionDetail key="AutomaticTransactionDetail" />,
    <AutomaticTransactionSuccess
      message="Otomatik Para Transferi başarıyla silindi"
      key="AutomaticTransactionDeleteSuccess"
      isDeleteSuccess={true}
    />
  ];

  return (
    <SmartOperationContext.Provider
      value={{
        step,
        setStep,
        nextStep,
        previousStep,
        selectedBill,
        setSelectedBill,
        isCreateAutoBillPaymentModalActive,
        toggleCreateAutoBillPayment,
        showBackButton,
        setShowBackButton,
        billPaymentFormValues,
        setBillPaymentFormValues,
        resetBillPaymentFormValues,
        isAutoBillPaymentDetailModalActive,
        toggleAutoBillDetailPayment,
        isAutoBillEditSideModalActive,
        toggleAutoBillEditSideModal,
        //Automatic Transactions
        automaticTransactionFormValues,
        setAutomaticTransactionFormValues,
        selectedAutomaticTransaction,
        setSelectedAutomaticTransaction,
        isCreateAutomaticTransactionModalActive,
        toggleCreateAutomaticTransactionModal,
        isAutomaticTransactionDetailModalActive,
        toggleAutomaticTransactionDetailModal,
        isAutomaticTransactionEditModalActive,
        toggleAutomaticTransactionEditModal,
        resetAutomaticTransactionFormValues
      }}
    >
      <div className="flex flex-col gap-y-12">
        {/* Auto Bill Payment */}
        {isCreateAutoBillPaymentModalActive && (
          <AutoBillPaymenModal
            steps={autoBillPaymentModalSteps}
            onClose={toggleCreateAutoBillPayment}
            className={step === 1 && '!bg-green-400'}
          />
        )}
        {isAutoBillPaymentDetailModalActive && (
          <AutoBillPaymenModal
            steps={autoBillPaymenDetailSteps}
            onClose={toggleAutoBillDetailPayment}
            className={step === 1 && '!bg-green-400'}
          />
        )}
        {isAutoBillEditSideModalActive && (
          <AutoBillEditSideModal onClose={toggleAutoBillEditSideModal} />
        )}
        {/* Automatic Transactions */}
        {isCreateAutomaticTransactionModalActive && (
          <AutomaticTransactionModal
            steps={automaticTransactionCreateModalSteps}
            onClose={toggleCreateAutomaticTransactionModal}
            className={step === 1 && '!bg-green-400'}
          />
        )}
        {isAutomaticTransactionDetailModalActive && (
          <AutomaticTransactionModal
            steps={automaticTransactionDetailSteps}
            onClose={toggleAutomaticTransactionDetailModal}
            className={step === 1 && '!bg-green-400'}
          />
        )}
        {isAutomaticTransactionEditModalActive && (
          <AutomaticTransactionEditSideModal onClose={toggleAutomaticTransactionEditModal} />
        )}
        <h1 className="text-[22px] font-semibold text-gray-900 leading-7">Akıllı İşlemler</h1>
        <div className="flex gap-x-4 w-full">
          <SmartOperationCardButton
            icon={<IconInvoice className="fill-current stroke-[1px] text-gray-700 w-5 h-5" />}
            title="Para Transferi"
            description="Düzenli transfer işlemlerini yönet"
            onClick={toggleCreateAutomaticTransactionModal}
          />
          <SmartOperationCardButton
            icon={<IconInvoice className="fill-current stroke-[1px] text-gray-700 w-5 h-5" />}
            title="Fatura İşlemleri"
            description="Otomatik kurum ödemelerini yönet"
            onClick={toggleCreateAutoBillPayment}
          />
        </div>
        <Divider className="border-gray-200 border-b-[1px]" />
        <OperationTabs />
      </div>
    </SmartOperationContext.Provider>
  );
}
