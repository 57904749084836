import React, { useContext, useEffect } from 'react';
import { Button, Card, Popup } from 'components';
import { ExchangeModalContext } from 'views/transactions/components/exchangeModal';

// import { ReactComponent as IconSafe } from 'assets/icons/safe.svg';
import { currencyFormat, EXCHANGE_TYPES } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
// import currency from 'currency.js';

import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import useModal from 'utils/hooks/useModal';
import {
  buyForeignCurrency,
  calculateBuyForeignCurrency,
  calculateSellForeignCurrency,
  sellForeignCurrency
} from 'store/actions/exchange';
import { useSelector, useDispatch } from 'react-redux';

export default function ExchangeCurrencySummary() {
  const { setSubTitle, nextStep } = useContext(ExchangeModalContext);
  const { formValues } = useContext(ExchangeModalContext);
  const dispatch = useDispatch();

  const { rate } = useSelector((state) => state.exchange);
  const { calculatedForeignCurrency } = useSelector((state) => state.exchange);

  useEffect(() => {
    setSubTitle('İşlem  Özeti');
  }, []);

  useEffect(() => {
    calculateExchangeSummary();
  }, [formValues?.rate]);

  function calculateExchangeSummary() {
    const payload = {
      exchangeAmount: formValues.buyAmount,
      sourceCurrency:
        formValues.exchangeType === EXCHANGE_TYPES.BUY
          ? formValues.wallet.currencyCode
          : formValues.targetWallet.currencyCode,
      targetCurrency:
        formValues.exchangeType === EXCHANGE_TYPES.BUY
          ? formValues.targetWallet.currencyCode
          : formValues.wallet.currencyCode,
      exchangeRateToken: rate?.exchangeRateToken,
      targetWalletId:
        formValues.exchangeType === EXCHANGE_TYPES.BUY
          ? formValues.targetWallet?.id
          : formValues.wallet?.id
    };
    if (formValues.exchangeType === EXCHANGE_TYPES.BUY) {
      dispatch(calculateBuyForeignCurrency(payload, formValues.wallet?.id));
    } else if (formValues.exchangeType === EXCHANGE_TYPES.SELL) {
      dispatch(calculateSellForeignCurrency(payload, formValues.targetWallet?.id));
    }
  }

  const onSubmitBuyorSellExchange = () => {
    if (formValues.exchangeType === EXCHANGE_TYPES.BUY) {
      dispatch(
        buyForeignCurrency({
          walletId: formValues?.wallet?.id,
          targetWalletId: formValues?.targetWallet?.id,
          exchangeToken: calculatedForeignCurrency?.exchangeToken
        })
      ).then(() => {
        nextStep();
      });
    } else if (formValues.exchangeType === EXCHANGE_TYPES.SELL) {
      dispatch(
        //Target and source wallet should be reverse as below while selling foreign currency
        sellForeignCurrency({
          walletId: formValues?.targetWallet?.id,
          targetWalletId: formValues?.wallet?.id,
          exchangeToken: calculatedForeignCurrency?.exchangeToken
        })
      ).then(() => {
        nextStep();
      });
    }
  };

  return (
    <>
      <Card>
        <div className="flex flex-col gap-y-9">
          {formValues.exchangeType === EXCHANGE_TYPES.BUY && (
            <>
              <AccountCard
                title="Satıcı Hesap"
                wallet={formValues.wallet}
                currencyCode={calculatedForeignCurrency?.sourceCurrency}
                amount={calculatedForeignCurrency?.sourceAmount}
                isSelling
              />
              <AccountCard
                title="Alıcı Hesap"
                wallet={formValues.targetWallet}
                amount={calculatedForeignCurrency?.targetAmount}
                currencyCode={calculatedForeignCurrency?.targetCurrency}
              />
              <CurrencyInformationCard />
              <TotalCard
                amount={calculatedForeignCurrency?.totalAmount}
                kgvRate={calculatedForeignCurrency?.kgvRate}
              />
            </>
          )}
          {formValues.exchangeType === EXCHANGE_TYPES.SELL && (
            <>
              <AccountCard
                title="Satıcı Hesap"
                wallet={formValues.targetWallet}
                amount={calculatedForeignCurrency?.targetAmount}
                currencyCode={calculatedForeignCurrency?.targetCurrency}
              />
              <AccountCard
                title="Alıcı Hesap"
                wallet={formValues.wallet}
                currencyCode={calculatedForeignCurrency?.sourceCurrency}
                amount={calculatedForeignCurrency?.sourceAmount}
                isSelling
              />
              <CurrencyInformationCard />
              <TotalCard
                amount={calculatedForeignCurrency?.totalAmount}
                kgvRate={calculatedForeignCurrency?.kgvRate}
              />
            </>
          )}
        </div>
      </Card>
      <Button className="w-full my-6" onClick={onSubmitBuyorSellExchange}>
        İşlemi Onayla
      </Button>
    </>
  );
}

const CurrencyInformationCard = () => {
  const { formValues } = useContext(ExchangeModalContext);

  return (
    <Card className=" flex flex-col gap-y-6 !shadow-none !bg-gray-100">
      <div className="flex justify-between border-b border-solid border-gray-200 pb-6">
        <p className="text-sm text-gray-500 font-medium leading-5">Kur</p>
        <p className="text-base font-semibold leading-6 text-gray-900">{`1 ${formValues.currencyCode} = ${formValues.rate} ${CURRENCY_NAMES.TRY}`}</p>
      </div>
      <div className="flex justify-between">
        {formValues.exchangeType === EXCHANGE_TYPES.SELL && (
          <>
            <div className="">
              <p className="text-sm text-gray-500 font-medium leading-5">
                {formValues.currencyCode} Miktar
              </p>
              <p className="text-base text-gray-900 font-semibold leading-6">
                {`${currencyFormat(formValues.buyAmount)} ${
                  CURRENCY_NAMES[formValues.targetWallet.currencyCode]
                }`}
              </p>
            </div>

            <IconArrowRight className="stroke-current text-gray-400 w-4 h-4 mt-7" />

            <div className="text-right">
              <p className="text-sm text-gray-500 font-medium leading-5">
                {CURRENCY_NAMES.TRY} Miktar
              </p>
              <p className="text-base text-green-500 font-semibold leading-6">
                {`${currencyFormat(formValues.sellAmount)} ${
                  CURRENCY_NAMES[formValues.wallet.currencyCode]
                }`}
              </p>
            </div>
          </>
        )}
        {formValues.exchangeType === EXCHANGE_TYPES.BUY && (
          <>
            <div className="">
              <p className="text-sm text-gray-500 font-medium leading-5">
                {CURRENCY_NAMES.TRY} Miktar
              </p>
              <p className="text-base text-gray-900 font-semibold leading-6">
                {`${currencyFormat(formValues.sellAmount)} ${
                  CURRENCY_NAMES[formValues.wallet.currencyCode]
                }`}
              </p>
            </div>

            <IconArrowRight className="stroke-current text-gray-400 w-4 h-4 mt-7" />

            <div className="text-right">
              <p className="text-sm text-gray-500 font-medium leading-5">
                {formValues.currencyCode} Miktar
              </p>
              <p className="text-base text-green-500 font-semibold leading-6">
                {`${currencyFormat(formValues.buyAmount)} ${
                  CURRENCY_NAMES[formValues.targetWallet.currencyCode]
                }`}
              </p>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

const TotalCard = ({ amount, kgvRate = 0 }) => {
  const [isExchangeTaxInfoPopupActive, , toggleExchangeTaxInfoPopup] = useModal();
  const { formValues } = useContext(ExchangeModalContext);
  return (
    <>
      {isExchangeTaxInfoPopupActive && (
        <ExchangeTaxInfoPopup onClose={toggleExchangeTaxInfoPopup} />
      )}
      <Card className="!shadow-none border border-solid border-gray-100">
        {formValues.exchangeType === EXCHANGE_TYPES.BUY && (
          <div className="flex justify-between items-center mb-3">
            <div className="flex items-center gap-x-1">
              <p className="text-sm font-medium text-gray-500 leading-5">Kambiyo Vergisi</p>
              <IconInfoCircle
                className={'fill-current stroke-0 text-gray-400 w-4 h-4 cursor-pointer'}
                onClick={toggleExchangeTaxInfoPopup}
              />
            </div>
            <span className="text-sm font-medium leading-5">{`${currencyFormat(kgvRate)} ${
              CURRENCY_NAMES.TRY
            }`}</span>
          </div>
        )}
        <div className="flex justify-between items-center">
          <p className="text-sm font-semibold leading-5">Toplam Tutar:</p>
          <span className="text-sm font-semibold leading-5">{`${currencyFormat(amount)} ${
            CURRENCY_NAMES.TRY
          }`}</span>
        </div>
      </Card>
    </>
  );
};

const AccountCard = ({ wallet, title }) => {
  return (
    <div>
      <h5 className="mb-4">{title}</h5>
      <Card className="flex items-center gap-x-4 !bg-gray-100 !shadow-none">
        {/* <div className="bg-green-300 rounded-full w-11 h-11 flex items-center justify-center flex-initial">
          <IconSafe className="fill-current stroke-1 text-green-700" />
        </div> */}
        <div className="flex-1">
          <p className="fotext-base text-gray-900 font-semibold leading-6">{wallet?.name}</p>
        </div>
        {/* {isSelling && (
          <div className="text-right">
            <p className="text-xs font-medium text-gray-500 leading-4">İşlem Sonrası Bakiye</p>
            <p className="text-base text-gray-900 font-semibold leading-6">
              {currencyFormat(currency(wallet?.balance).subtract(amount))}{' '}
              {CURRENCY_NAMES[currencyCode]}{' '}
            </p>
          </div>
        )} */}
      </Card>
    </div>
  );
};

const ExchangeTaxInfoPopup = ({ onClose }) => {
  return (
    <Popup active childrenClassName="h-[327px]">
      <div className="flex flex-col justify-between items-center h-full">
        <p className="w-20 h-20 rounded-full bg-yellow-100 flex items-center justify-center">
          <IconInfoCircle className="fill-current text-yellow-500 w-10 h-10" />
        </p>
        <p className="text-base text-gray-900 font-medium leading-6 text-center">
          Kambiyo vergisi alış tutarı üzerinden %0,2 BSMV (Banka ve Sigorta Muamemeleri Vergisi)
          tahsil edilmektedir.
        </p>
        <Button className="w-full" onClick={onClose}>
          Tamam
        </Button>
      </div>
    </Popup>
  );
};
