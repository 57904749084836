import { Tag } from 'components';
import StepHeader from 'components/stepHeader';
import React, { useContext } from 'react';
import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { ExchangeModalContext } from 'views/transactions/components/exchangeModal';

const DEFAULT_TITLE = 'Döviz Al / Sat';

export default function ModalHeader() {
  const { subTitle, formValues, step, time } = useContext(ExchangeModalContext);

  return (
    <div className="flex justify-between border-solid border-b border-gray-200 mb-10">
      <StepHeader
        title={DEFAULT_TITLE}
        subtitle={subTitle}
        className="text-left"
        titleClassName="mb-4"
        subtitleClassName="text-[28px] leading-8"
      />
      <div className="flex items-center gap-x-4">
        <span className="text-purple-500 text-lg leading-6 font-semibold">{time}sn </span>
        {step == 1 && (
          <Tag size="sm" color="gray">
            {`1 ${formValues.currencyCode} = ${currencyFormat(formValues.rate, 3)} ${
              CURRENCY_NAMES.TRY
            }`}
          </Tag>
        )}
      </div>
    </div>
  );
}
