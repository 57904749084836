const CustomInput = ({
  label,
  onChange,
  style,
  className,
  error,
  register,
  name,
  invisible,
  disabled,
  defaultValue,
  value,
  type,
  only,
  maxLength = -1,
  onKeyPress,
  refProp,
  autoFocus,
  count,
  inputClassName,
  isOptional = false,
  min = null,
  ...props
}) => {
  only = ['text', 'number'].includes(only) ? only : null;

  const inputClassNames = error
    ? `${inputClassName} px-3 py-2 bg-gray-100 border border-red-500 border-solid rounded-lg focus:outline-none focus:bg-white focus:shadow-md text-red-500`
    : `${inputClassName} px-3 py-2 bg-gray-100 h-12 rounded-lg focus:outline-none focus:shadow-md focus:bg-white border border-solid border-transparent focus:border focus:border-solid focus:border-purple-700 disabled:text-gray-400`;

  const inputName = name || label;

  const textRegex = /[^a-zA-ZçÇğĞıİöÖşŞüÜ\s]+/gi;
  const numberRegex = /[^0-9]+/gi;

  function handleChange(e) {
    if (only === 'text') {
      if (textRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(textRegex, '');
      }
    }
    if (only === 'number') {
      if (numberRegex.test(e.target.value)) {
        e.target.value = e.target.value.replace(numberRegex, '');
      }
    }
    if (typeof onChange === 'function') onChange(e);
  }

  return (
    <div className={`custom-input ${className} flex flex-col`} style={style}>
      {(label || isOptional) && (
        <span className="text-gray-500 text-sm mb-1 font-medium leading-5">
          {label}{' '}
          {isOptional && (
            <span className="text-sm leading-5 font-medium text-gray-400 italic">
              {'(Opsiyonel)'}
            </span>
          )}
        </span>
      )}
      {register ? (
        <input
          type={invisible ? 'password' : type || 'text'}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={handleChange}
          className={inputClassNames}
          maxLength={maxLength}
          min={min}
          onKeyPress={onKeyPress}
          ref={refProp}
          autoFocus={autoFocus}
          name={name}
          {...register(inputName)}
          {...props}
        />
      ) : (
        <input
          defaultValue={defaultValue}
          type={invisible ? 'password' : type || 'text'}
          disabled={disabled}
          onChange={handleChange}
          className={inputClassNames}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          ref={refProp}
          autoFocus={autoFocus}
          value={value}
          name={name}
          min={min}
          {...props}
        />
      )}
      {error && (
        <div className="flex mt-2">
          <label className="text-xs text-red-500 flex items-center">{error}</label>
          {count >= 0 && maxLength && (
            <span className="ml-auto text-xs font-medium text-gray-400">{`${count} / ${maxLength}`}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomInput;
