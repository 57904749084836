import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
// Icons
import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';
import { ReactComponent as IconClipboard } from 'assets/icons/clipboard.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as IconClock } from 'assets/icons/clock.svg';
import { ReactComponent as Building3Icon } from 'assets/icons/building-3.svg';
import { ReactComponent as IdentificationIcon } from 'assets/icons/identification.svg';
import { ReactComponent as LocationDocumentIcon } from 'assets/icons/location-document.svg';

import Logo from 'assets/icons/logo.svg';

import {
  CustomInput,
  Checkbox,
  CodeInput,
  SideModal,
  PhoneInput,
  DatePicker,
  Loading,
  InfoMessage,
  Button
} from 'components';
import InputMask from 'react-input-mask';

import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  signup,
  signup_email,
  signup_identity,
  signup_phone,
  signup_password,
  signup_agreement,
  getUser
} from 'store/actions/auth';
import { useHistory } from 'react-router-dom';

import errorCodes from 'utils/errorCodes';
import { checkIdentityNumber, emailRegex, getFormattedDateForPayload, timeFormat } from 'utils';

import { getCompany } from 'store/actions/company';

import useCountdown from 'utils/hooks/useCountdown';
import useKeyPress from 'utils/hooks/useKeyPress';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';
import toast from 'react-hot-toast';
import cn from 'classnames';
import classNames from 'classnames';
import { useDeviceDetection } from 'utils/hooks';

const OTP_TIMEOUT = 120;

const SideBarLoading = ({ className, isLoading }) => {
  const classNames = cn('h-full w-full', { ['hidden']: !isLoading, [className]: className });
  return (
    <div className={classNames}>
      <Loading />
    </div>
  );
};

const StepHeader = ({ title, subtitle, children }) => (
  <div className="flex flex-col mb-6">
    <img src={Logo} alt="logo" width="35" />
    <span className="text-gray-400 leading-5 mt-6 text-sm font-semibold">{title}</span>
    <span className="text-gray-900 text-xl font-semibold mt-3">{subtitle}</span>
    {children}
  </div>
);

const Step0UserAggrementSideModal = ({ handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_clarification_text' });
  }, []);

  return (
    <SideModal handleClose={() => handleClose(false)} className="w-1/2">
      <div className="w-full h-full flex flex-col gap-y-2">
        <SideBarLoading isLoading={isLoading} />
        <iframe
          src="https://agreements.norma.co/uye-sozlesmesi.html"
          title="Üyelik Sözleşmesi"
          className={isLoading ? 'hidden' : 'h-full w-full'}
          onLoad={() => setIsLoading(false)}
          data-testid="user_agreement_iframe"
        />

        <Button
          className="w-full my-4"
          onClick={() => handleClose(true)}
          data-testid="user_agreement_button"
        >
          Sözleşmeyi Onayla
        </Button>
      </div>
    </SideModal>
  );
};

const Step0KvkkAggrementSideModal = ({ handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_member_agreement' });
  }, []);

  return (
    <SideModal handleClose={() => handleClose(false)} className="w-1/2">
      <div className="w-full h-full flex flex-col gap-y-2">
        <SideBarLoading isLoading={isLoading} />
        <iframe
          src="https://agreements.norma.co/aydinlatma-metni.html"
          title="Aydınlatma Metni"
          className={isLoading ? 'hidden' : 'h-full w-full'}
          onLoad={() => setIsLoading(false)}
          data-testid="kvkk_agreement_iframe"
        />
        <Button
          className="w-full my-4"
          onClick={() => handleClose(true)}
          data-testid="kvkk_agreement_button"
        >
          Sözleşmeyi Onayla
        </Button>
      </div>
    </SideModal>
  );
};

const SignupOutline = ({ nextStep }) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_outline' });
  }, []);

  return (
    <div className="w-[400px] flex flex-col relative">
      <StepHeader
        title="Hesap Oluştur"
        subtitle="Şirket Hesabınızın Açılması için Gerekli Süreçler"
        data-testid="label_signup_outline"
      >
        <div className="mt-2">
          <IconClock className="fill-current text-gray-500 w-4 h-4 inline mr-2 " />
          <span className="text-xs font-medium text-gray-500">Tahmini Süre 4 dakika</span>
        </div>
      </StepHeader>
      <ul className="mt-4">
        {[
          {
            label: '1. Aşama',
            title: 'Kişisel bilgilerinizi ekleyin',
            text: 'Telefon ve e-posta bilgilerinizi girin',
            icon: <IdentificationIcon className="mr-3 w-12 h-12" />
          },
          {
            label: '2. Aşama',
            title: 'Şirket bilgilerinizi ekleyin',
            text: 'Şirketiniz yoksa kuruluş işlemlerinizi başlatın',
            icon: <Building3Icon className="mr-3 w-12 h-12" />
          },
          {
            label: '3. Aşama',
            title: 'Sözleşme Adres Bilgileri',
            text: 'Ticari şirket hesabınızın açılabilmesi için şirket sahibi olarak imzanız gereklidir',
            icon: <LocationDocumentIcon className="mr-3 w-12 h-12" />
          }
        ].map(({ label, title, text, icon }, index) => (
          <>
            <li key={label} className="list-none flex">
              {icon}
              <div className="max-w-[340px]">
                <p className="text-purple-500 text-xs font-medium mt-2">{label}</p>
                <p className="text-gray-900 text-sm font-semibold my-1">{title}</p>
                <p className="text-gray-500 text-xs font-medium">{text}</p>
              </div>
            </li>
            {index !== 2 && (
              <div className=" bg-gray-200 w-1 h-6 my-3 ml-[60px] rounded-3xl"></div>
            )}
          </>
        ))}
      </ul>

      <Button
        className="mt-10 w-full"
        onClick={() => {
          nextStep();
        }}
        data-testid="button_signup_outline"
      >
        Devam
      </Button>
    </div>
  );
};

const SignupEmail = ({ register, nextStep, watch, setValue, setError, clearErrors, errors }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { sendGTMEvent } = useGTM();

  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_email' });
  }, []);

  function signupFn() {
    dispatch(signup({ email: watch('email'), grantMarketing: watch('grantMarketing') }))
      .then(() => {
        clearErrors();
        sendGTMEvent({
          event: 'catEvent',
          eventName: 'enter_email'
        });
        nextStep();
      })
      .catch((errors) => {
        if (Array.isArray(errors))
          errors?.forEach((err) => {
            if (err.code === errorCodes.emailAlreadyInUse) {
              setError('email', {
                type: 'warning',
                message: err.message
              });
            }
            if (err.code === errorCodes.invalidEmail) {
              setError('email', {
                type: 'invalid',
                message: err[errorCodes.invalidEmail]
              });
            }
          });
      });
  }

  useEffect(() => {
    clearErrors('email');
  }, [watch('email')]);

  const [isTermActive, setIsTermActive] = useState(false);
  function handleUserAggrement(opt = false) {
    if (opt) setValue('terms', opt);
    setIsTermActive(false);
  }

  const [isKvkkActive, setIsKvkkActive] = useState(false);
  function handleKvkkAggrement(opt = false) {
    if (opt) setValue('privacy', opt);
    setIsKvkkActive(false);
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    signupFn();
  });

  const isCorrect = emailRegex.test(watch('email')) && watch('terms') && watch('privacy');

  return (
    <div className="w-[400px] flex flex-col relative">
      {isTermActive && <Step0UserAggrementSideModal handleClose={handleUserAggrement} />}
      {isKvkkActive && <Step0KvkkAggrementSideModal handleClose={handleKvkkAggrement} />}
      <StepHeader
        title="Hesap Oluştur"
        subtitle="E-posta adresinizi girin"
        data-testid="label_signup_email"
      />
      <CustomInput
        label="E-posta"
        value={watch('email')}
        onChange={(e) => {
          clearErrors('email');
          setValue('email', e.target.value);
        }}
        error={errors?.email?.message}
        data-testid="input_signup_email"
        autoFocus
      />
      <Checkbox
        className="mt-6 text-gray-700 font-medium text-sm leading-5"
        register={register}
        name="terms"
        data-testid="checkbox_signup_email"
      >
        <span className="text-green-500 cursor-pointer" onClick={() => setIsTermActive(true)}>
          Üye sözleşmesini
        </span>{' '}
        okudum, kabul ediyorum
      </Checkbox>
      <Checkbox
        className="mt-4 text-gray-700 font-medium text-sm leading-5"
        register={register}
        name="privacy"
        data-testid="checkbox_signup_email_kvkk"
      >
        <span className="text-green-500 cursor-pointer" onClick={() => setIsKvkkActive(true)}>
          Aydınlatma metnini{' '}
        </span>
        okudum, anladım
      </Checkbox>
      <Checkbox
        className="mt-4 text-gray-700 font-medium text-sm leading-5"
        register={register}
        name="grantMarketing"
        data-testid="checkbox_signup_email_marketing"
      >
        {"Norma'nın tarafıma ticari elektronik ileti göndermesine izin veriyorum"}
      </Checkbox>
      <Button
        className="mt-8 w-full"
        disabled={!isCorrect || errors?.email}
        onClick={signupFn}
        data-testid="button_signup_email"
      >
        Devam
      </Button>
      {errors?.email && (
        <Button
          color="primary"
          className="mt-4 w-full"
          onClick={() => history.push('/login')}
          data-testid="button_login_link"
        >
          Giriş Yap
        </Button>
      )}
    </div>
  );
};

const SignupEmailOtp = ({ length, nextStep, setError, errors, clearErrors }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const [code, setCode] = useState('');
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_email_otp' });
  }, []);

  function sendCode() {
    restart();
    dispatch(signup_email({ otpToken: null }));
  }

  useEffect(() => {
    sendCode();
  }, []);

  function signupFn() {
    dispatch(signup_email({ otpToken: code }))
      .then(() => {
        nextStep();
        sendGTMEvent({
          event: 'catEvent',
          eventName: 'email_verification'
        });
      })
      .catch((err) => {
        if (err[errorCodes.invalidOtpToken]) {
          setError('mailOtpToken', {
            type: 'warning',
            message: err[errorCodes.invalidOtpToken]
          });
        }
      });
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    signupFn();
  });

  useEffect(() => {
    clearErrors('mailOtpToken');
  }, [code]);

  const [timeLeft, restart, isCounting] = useCountdown(OTP_TIMEOUT);

  const isCorrect = code.length === length;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Hesap Oluştur"
        subtitle={`Email adresinize gelen ${length} haneli kodu girin`}
        data-testid="label_signup_email_otp"
      />
      <CodeInput
        length={length}
        onChange={(code) => setCode(code)}
        error={errors.mailOtpToken}
        autofocus
        only="number"
        data-testid="input_signup_email_otp"
      />
      <span className="flex flex-col mt-2">
        <span>E-posta size ulaşmadı mı?</span>
        {isCounting ? (
          <span className="text-green-400 cursor-default select-none">
            {timeFormat(timeLeft)}
          </span>
        ) : (
          <span className="text-green-400 cursor-pointer select-none" onClick={sendCode}>
            Tekrar gönder.
          </span>
        )}
      </span>
      <Button
        className="mt-8"
        disabled={!isCorrect}
        onClick={signupFn}
        data-testid="button_signup_email_otp"
      >
        Devam
      </Button>
    </div>
  );
};

const SignupPhone = ({ watch, nextStep, setValue, errors, setError, clearErrors }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { publishEvent, eventTypes } = useDengage();

  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_phone_number' });
  }, []);

  function signupFn() {
    const payload = {
      phoneNumber: watch('phone'),
      phoneNumberCode: 'TR',
      otpToken: null
    };

    dispatch(signup_phone(payload))
      .then(() => {
        sendGTMEvent({ event: 'catEvent', eventName: 'enter_phone' });
        nextStep();
      })
      .catch((errors) => {
        errors.forEach((err) => {
          if (err.code === errorCodes.phoneAlreadySaved) {
            setError('phone', {
              type: 'alreadySaved',
              message: err.message
            });
          }
          if (err.code === errorCodes.invalidPhoneNumber) {
            setError('phone', {
              type: 'invalid',
              message: err.message
            });
          }
        });
      });
  }

  function handlePhone(_, phone) {
    clearErrors('phone');
    setValue('phone', phone);
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    signupFn();
  });

  const isCorrect = watch('phone').length === 10;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Hesap Oluştur"
        subtitle="Telefon numaranız"
        data-testid="label_signup_phone"
      />
      <PhoneInput onChange={handlePhone} error={errors?.phone?.message} autofocus />
      <div className="w-full p-3 rounded-lg bg-gray-100 border border-solid border-gray-200 flex gap-x-3 justify-start items-center mt-6">
        <div>
          <InfoCircleIcon className="fill-current w-6 text-gray-400" />
        </div>
        <span className="text-sm font-medium text-gray-500">
          Doğrulama kodunuz SMS olarak iletilecektir.
        </span>
      </div>
      <Button
        type="button"
        className="mt-6"
        onClick={signupFn}
        disabled={!isCorrect || errors?.phone}
        data-testid="button_signup_phone"
      >
        Devam
      </Button>
      {errors?.phone?.type === 'alreadySaved' && (
        <Button
          type="button"
          color="primary"
          onClick={() => history.push('/login')}
          className="mt-6"
          data-testid="button_login_link"
        >
          Giriş Yap
        </Button>
      )}
    </div>
  );
};

const SignupCredentials = ({ watch, nextStep, setValue, setError, errors, clearErrors }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_personal_info' });
  }, []);

  const hasTcError =
    watch('identityNumber')?.length === 11 && !checkIdentityNumber(watch('identityNumber'))
      ? 'Hatalı TC Kimlik No'
      : false;

  const isCorrect =
    watch('firstName')?.length >= 2 &&
    watch('lastName')?.length >= 2 &&
    watch('birthDate') &&
    watch('identityNumber')?.length === 11 &&
    !hasTcError;

  const lastNameInput = useRef();
  const identityNumberInput = useRef();
  const birthDateInput = useRef();

  function signupFn() {
    if (!isCorrect) return;

    const payload = {
      firstName: watch('firstName'),
      lastName: watch('lastName'),
      identityNumber: watch('identityNumber'),
      birthDate: getFormattedDateForPayload(watch('birthDate'))
    };

    dispatch(signup_identity(payload))
      .then(() => {
        nextStep();
      })
      .catch((err) => {
        if (err[errorCodes.invalidIdentityNumber]) {
          setError('identityNumber', {
            type: 'warning',
            message: err[errorCodes.invalidIdentityNumber]
          });
        }
        if (err[errorCodes.userIDAlreadyRegistered]) {
          setError('identityNumber', {
            type: 'alreadyRegistered',
            message: err[errorCodes.userIDAlreadyRegistered]
          });
        }
      });

    sendGTMEvent({
      event: 'catEvent',
      eventName: 'personal_info'
    });
  }

  function handleIdentityNumber(e) {
    clearErrors('identityNumber');
    setValue('identityNumber', e.target.value);
  }

  function handleKeyPress(e, ref) {
    if (e.code === 'Enter') {
      ref.current.focus();
    }
  }

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Hesap Oluştur"
        subtitle="Kişisel Bilgilerinizi Girin"
        data-testid="label_signup_credentials"
      />
      <CustomInput
        label="İsim"
        onChange={(e) => setValue('firstName', e.target.value)}
        only="text"
        value={watch('firstName')}
        onKeyPress={(e) => handleKeyPress(e, lastNameInput)}
        data-testid="input_signup_first_name"
        autoFocus
      />
      <CustomInput
        refProp={lastNameInput}
        className="mt-4"
        label="Soyisim"
        onChange={(e) => setValue('lastName', e.target.value)}
        value={watch('lastName')}
        onKeyPress={(e) => handleKeyPress(e, identityNumberInput)}
        only="text"
        data-testid="input_signup_last_name"
      />
      <CustomInput
        refProp={identityNumberInput}
        className="mt-4"
        label="TC Kimlik Numarası"
        onChange={handleIdentityNumber}
        value={watch('identityNumber')}
        onKeyPress={(e) => handleKeyPress(e, birthDateInput)}
        error={errors?.identityNumber?.message || hasTcError}
        only="number"
        maxLength={11}
        data-testid="input_signup_identity_number"
      />
      <div className="flex flex-col mt-4">
        <span className="text-sm font-medium text-gray-500" data-testid="label_signup_birth_date">
          Doğum Tarihi (GG AA YYYY)
        </span>
        <DatePicker
          selected={watch('birthDate')}
          onChange={(date) => setValue('birthDate', date)}
          className="w-full bg-gray-100 py-3 px-2 rounded-lg mt-1 focus:outline-none"
          dateFormat="dd/MM/yyyy"
          customInput={<InputMask mask="99/99/9999" />}
          showYearDropdown
          showMonthDropdown
          peekNextMonth
          dropdownMode="select"
          data-testid="input_signup_birth_date"
        />
        {errors?.birthDate && (
          <span className="text-xs text-red-500" data-testid="error_signup_birth_date">
            {errors?.birthDate?.message}
          </span>
        )}
      </div>
      <Button
        className="mt-8"
        disabled={!isCorrect}
        onClick={signupFn}
        data-testid="button_signup_credentials"
      >
        Devam
      </Button>
    </div>
  );
};

const SignupPassword = ({ setValue, length, nextStep, errors, watch, clearErrors }) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_password' });
  }, []);

  const isCorrect = watch('password').length === 6;

  function handlePassword(val) {
    clearErrors('password');
    setValue('password', val);
  }

  function handleContinue() {
    const hasSequantialError = PASSWORD_SEQUENTIAL_REGEX.test(watch('password'));
    const hasRepeatingError = PASSWORD_REPEATING_REGEX.test(watch('password'));

    if (hasSequantialError) {
      toast.error(
        <div className="flex flex-col" data-testid="section_signup_error">
          <span
            className="text-sm font-semibold text-red-100"
            data-testid="label_signup_error_title"
          >
            Hatalı Şifre
          </span>
          <span
            className="text-sm font-medium text-red-100"
            data-testid="label_signup_error_description"
          >
            Şifreniz ardışık rakamlardan oluşamaz
          </span>
        </div>
      );
      return;
    }
    if (hasRepeatingError) {
      toast.error(
        <div className="flex flex-col">
          <span
            className="text-sm font-semibold text-red-100"
            data-testid="label_signup_error_title"
          >
            Hatalı Şifre
          </span>
          <span
            className="text-sm font-medium text-red-100"
            data-testid="label_signup_error_description"
          >
            Şifreniz tekrarlayan rakamlardan oluşamaz
          </span>
        </div>
      );
      return;
    }

    nextStep();
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    handleContinue();
  });
  const { isMobile, isIOS } = useDeviceDetection();
  const signupPasswordClassnames = classNames('w-[400px] flex flex-col', {
    'gap-y-6': !isMobile,
    'space-y-6': isIOS
  });
  return (
    <div className={signupPasswordClassnames}>
      <StepHeader
        title="Hesap Oluştur"
        subtitle={`Giriş şifrenizi ${length} haneli olarak belirleyin`}
        data-testid="label_signup_password"
      />
      <CodeInput
        className="text-4xl"
        length={length}
        onChange={handlePassword}
        invisible={true}
        error={errors?.password}
        autofocus
        only="number"
        blockPaste={true}
        data-testid="input_signup_password"
      />
      <Button disabled={!isCorrect} onClick={handleContinue} data-testid="button_signup_password">
        Devam
      </Button>
      <InfoMessage>
        Şifreniz, tekrarlayan ya da ardışık rakamlardan oluşamaz. (111,123 vb)
      </InfoMessage>
    </div>
  );
};

const SignupRePassword = ({ watch, setValue, setError, nextStep, length }) => {
  const dispatch = useDispatch();

  const isCorrect = watch('againPassword').length === 6;

  function signupFn() {
    const payload = { password: watch('password'), rePassword: watch('againPassword') };

    dispatch(signup_password(payload))
      .then((res) => {
        if (!res) return;
        nextStep();
      })
      .catch((err) => {
        if (err[errorCodes.passwordNotSame]) {
          setError('password', {
            type: 'warning',
            message: err[errorCodes.passwordNotSame]
          });
          nextStep(2);
        }
      });
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    signupFn();
  });
  const { isMobile, isIOS } = useDeviceDetection();
  const signupRePasswordClassnames = classNames('w-[400px] flex flex-col', {
    'gap-y-6': !isMobile,
    'space-y-6': isIOS
  });

  return (
    <div className={signupRePasswordClassnames}>
      <StepHeader
        title="Hesap Oluştur"
        subtitle={`Giriş şifrenizi ${length} haneli olarak tekrar girin`}
        data-testid="label_signup_repassword"
      />
      <CodeInput
        className="text-4xl"
        length={length}
        onChange={(val) => setValue('againPassword', val)}
        autofocus
        invisible={true}
        only="number"
        blockPaste={true}
        data-testid="input_signup_repassword"
      />
      <Button
        className="mt-8"
        disabled={!isCorrect}
        onClick={signupFn}
        data-testid="button_signup_repassword"
      >
        Devam
      </Button>
      <InfoMessage>
        Şifreniz, tekrarlayan ya da ardışık rakamlardan oluşamaz. (111,123 vb)
      </InfoMessage>
    </div>
  );
};

const SignupSmsCode = ({ length, errors, watch, clearErrors }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();
  const [SMSCode, setSMSCode] = useState('');
  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'register_phone_otp' });
  }, []);

  async function signupFn() {
    const payload = {
      phoneNumber: watch('phone'),
      phoneNumberCode: 'TR',
      otpToken: SMSCode
    };
    try {
      const {
        data: {
          data: { access_token }
        }
      } = await dispatch(signup_phone(payload));

      await dispatch({
        type: 'SET_TOKEN',
        data: access_token
      });

      sendGTMEvent({ event: 'catEvent', eventName: 'phone_verification' });

      localStorage.setItem('token', access_token);

      dispatch(getUser()).then((user) => {
        dispatch(getCompany());
        sendGTMEvent({ event: 'catEvent', eventName: 'sign_up', userId: user.userId });
      });

      history.push('/login');
    } catch (err) {
      console.log(err);
    }
  }

  function sendSmsAgain() {
    const payload = {
      phoneNumber: watch('phone'),
      phoneNumberCode: 'TR',
      otpToken: null
    };

    dispatch(signup_phone(payload)).then(() => {
      restartCount();
    });
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    signupFn();
  });

  useEffect(() => {
    clearErrors('smsOtpToken');
  }, [SMSCode]);

  const [minutesLeft, restartCount, isCounting] = useCountdown(OTP_TIMEOUT);

  const isCorrect = !!SMSCode;

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader title="Hesap Oluştur" subtitle="SMS Kodunuz" />
      <CodeInput
        length={length}
        onChange={(code) => setSMSCode(code)}
        error={errors?.smsOtpToken}
        only="number"
        data-testid="input_signup_sms_code"
        autofocus
      />
      <span className="text-sm mt-4">SMS ulaşmadı mı?</span>
      {isCounting ? (
        <span
          className="text-sm text-green-400 select-none"
          data-testid="label_signup_sms_code_info"
        >
          {timeFormat(minutesLeft)}
        </span>
      ) : (
        <span
          className="text-sm text-green-400 select-none cursor-pointer"
          onClick={sendSmsAgain}
          data-testid="label_signup_sms_code_resend"
        >
          Tekrar Gönder
        </span>
      )}
      <InfoMessage className="!p-3 mt-6">Doğrulama kodunuz SMS olarak iletilecektir.</InfoMessage>
      <Button
        type="button"
        className="mt-6"
        onClick={signupFn}
        data-testid="button_signup_sms_code"
      >
        Devam
      </Button>
    </div>
  );
};

const SignupCompanyOption = ({ nextStep, setCompanyOption, COMPANY_OPTIONS }) => {
  const Card = ({ title, subtitle, className, onClick, icon }) => (
    <div
      className={`${className} flex flex-row items-start bg-white rounded-xl shadow-md hover:shadow-lg p-4 w-[400px] cursor-pointer`}
      onClick={onClick}
      data-testid="section_signup_company_option"
    >
      <img src={icon} alt="icon" />
      <div className="ml-4">
        <h6 data-testid="section_signup_company_option_title">{title}</h6>
        <p data-testid="section_signup_company_option_subtitle" className="mt-2">
          {subtitle}
        </p>
      </div>
    </div>
  );

  function selectOption(option) {
    switch (option) {
      case COMPANY_OPTIONS.HAVE_COMPANY:
        setCompanyOption(COMPANY_OPTIONS.HAVE_COMPANY);
        nextStep(8);
        break;
      case COMPANY_OPTIONS.HAVE_NOT_COMPANY:
        setCompanyOption(COMPANY_OPTIONS.HAVE_NOT_COMPANY);
        nextStep(9);
        break;
      case COMPANY_OPTIONS.NONE:
        setCompanyOption(COMPANY_OPTIONS.NONE);
        nextStep(9);
        break;
      default:
        setCompanyOption(COMPANY_OPTIONS.NONE);
        nextStep(9);
        break;
    }
  }

  return (
    <div className="w-[400px] flex flex-col">
      <StepHeader
        title="Hesap Oluştur"
        subtitle="Şirket Bilgileri"
        data-testid="label_signup_company_credentials"
      />
      <Card
        title="Şirketin Sahibiyim"
        subtitle="Şirket bilgilerini girerek tüm işlemleri Norma ile yapabilirsiniz."
        className="mb-4"
        icon={BuildingIcon}
        onClick={() => selectOption(COMPANY_OPTIONS.HAVE_COMPANY)}
        data-testid="option_signup_company_have_company"
      />
      <Card
        title="Şirketim Yok"
        subtitle="Sizin için bir şirket kuralım. Böylece tüm Norma imkanlarından faydalanın."
        icon={IconClipboard}
        onClick={() => selectOption(COMPANY_OPTIONS.HAVE_NOT_COMPANY)}
        data-testid="option_signup_company_have_not_company"
      />
      <Button
        color="danger"
        className="mt-8"
        onClick={() => selectOption(COMPANY_OPTIONS.NONE)}
        data-testid="button_signup_company_cancel"
      >
        Seçmeden Devam Et
      </Button>
    </div>
  );
};

const SignupCompany = ({ register, isCorrect, nextStep }) => (
  <div className="w-[400px] flex flex-col">
    <StepHeader
      title="Hesap Oluştur"
      subtitle="Şirket Bilgileri"
      data-testid="label_signup_company_credentials"
    />
    <CustomInput
      label="Şirket Adı"
      register={register}
      name="companyName"
      data-testid="input_signup_company_name"
    />
    <CustomInput
      className="mt-2"
      label="Ülke"
      register={register}
      name="country"
      data-testid="input_signup_company_country"
    />
    <CustomInput
      className="mt-2"
      label="Şehir"
      register={register}
      name="city"
      data-testid="input_signup_company_city"
    />
    <CustomInput
      className="mt-2"
      label="Adres"
      register={register}
      name="address"
      data-testid="input_signup_company_address"
    />
    <CustomInput
      className="mt-2"
      label="Vergi dairesi"
      register={register}
      name="taxOffice"
      data-testid="input_signup_company_tax_office"
    />
    <CustomInput
      className="mt-2"
      label="Vergi no"
      register={register}
      name="taxCode"
      data-testid="input_signup_company_tax_code"
    />
    <Button
      disabled={!isCorrect}
      className="mt-8"
      onClick={() => nextStep()}
      data-testid="button_signup_company_next"
    >
      Devam
    </Button>
  </div>
);

const SignupAggrement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  function _signup() {
    dispatch(signup_agreement()).then(() => {
      history.push('/welcome');
    });
  }

  return (
    <div className="w-[400px] flex flex-col" data-testid="section_signup_agreement">
      <h4>Gizlilik Sözleşmesi</h4>
      <p className="mt-4">You have to agree with our privacy policy</p>
      <p className="mt-8">
        {`Your privacy is important to us. It is norma' policy to respect your privacy regarding any
        information we may collect from you across our website,`}{' '}
        <a href="https://norma.co" target="_blank" rel="noreferrer">
          https://norma.co
        </a>
        , and other sites we own and operate.
      </p>
      <p className="mt-8">
        We only ask for personal information when we truly need it to provide a service to you. We
        collect it by fair and lawful means, with your knowledge and consent. We also let you know
        why we’re collecting it and how it will be used.
      </p>
      <p className="mt-8">
        By tapping accept, I also accept Norma{' '}
        <a href="https://norma.co" target="_blank" rel="noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://norma.co" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </p>
      <Button className="mt-8" onClick={_signup}>
        Onayla
      </Button>
    </div>
  );
};

export {
  StepHeader,
  SignupOutline,
  SignupEmail,
  SignupEmailOtp,
  SignupPhone,
  SignupCredentials,
  SignupPassword,
  SignupSmsCode,
  SignupRePassword,
  SignupCompanyOption,
  SignupCompany,
  SignupAggrement
};
