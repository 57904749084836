import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as LightningFilledIcon } from 'assets/icons/lightning-filled.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as ProfilePhoto } from 'assets/icons/default-profile-ıcon-40px.svg';
import { ReactComponent as CompanyPhoto } from 'assets/icons/default-company-Icon.svg';

import { Fragment, useState } from 'react';
import { Popover } from '@headlessui/react';
import { useEffect } from 'react';
import useModal from 'utils/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts } from 'store/actions/contacts';
import CreateContactModal from 'views/other/contacts/components/createContactModal';
import { COMPANY_TYPES } from 'utils';

const SelectedContactPreview = ({ selectedContact }) => (
  <Popover.Button as="div" className="flex items-center cursor-pointer py-6 px-4">
    {selectedContact?.profilePhoto ? (
      <img
        src={selectedContact.profilePhoto}
        alt="profile"
        className="h-10 w-10 rounded-full mr-4"
      />
    ) : (
      <>
        {selectedContact.companyType === COMPANY_TYPES.INDIVIDUAL && (
          <ProfilePhoto className="h-11 w-11 mr-4 rounded-full bg-purple-700 flex items-center justify-center"></ProfilePhoto>
        )}
        {selectedContact.companyType === COMPANY_TYPES.BUSINESS && (
          <CompanyPhoto className="h-11 w-11 mr-4 rounded-full bg-purple-700 flex items-center justify-center"></CompanyPhoto>
        )}
      </>
    )}
    <div className="flex flex-col">
      <span className="text-gray-900 font-semibold">
        {selectedContact?.companyType === COMPANY_TYPES.BUSINESS
          ? selectedContact?.companyName
          : `${selectedContact?.firstName} ${selectedContact?.lastName}`}
      </span>
      {selectedContact?.companyType !== COMPANY_TYPES.BUSINESS && (
        <span className="text-gray-500 text-sm">{selectedContact?.companyName}</span>
      )}
    </div>
    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
  </Popover.Button>
);

const SelectContactButton = () => (
  <Popover.Button as="div" className="flex items-center cursor-pointer py-6 px-4">
    <span className="font-semibold text-gray-900">Müşteri/Tedarikçi Seç</span>
    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
  </Popover.Button>
);

const SelectContactPanel = ({
  search,
  setSearch,
  handleSelectedContact,
  filteredContacts,
  close
}) => (
  <Popover.Panel className="absolute top-20 left-0 w-full bg-white rounded-xl border border-solid border-gray-200 p-4 mt-4 h-96 z-50">
    <div className="w-full rounded-lg bg-gray-100 flex gap-x-2 items-center px-3 py-4 mb-4">
      <IconSearch className="fill-current text-gray-400 w-5" />
      <input
        placeholder="Arama"
        className="border-0 focus:outline-none bg-transparent w-full"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
    <div className="flex flex-col overflow-y-scroll h-72">
      {filteredContacts.map((contact, i) => (
        <div
          className="flex items-center cursor-pointer border-b border-solid border-gray-100 py-4"
          onClick={() => {
            handleSelectedContact(contact);
            close();
          }}
          key={i}
        >
          {contact?.profilePhoto ? (
            <img src={contact?.profilePhoto} alt="profile" className="h-11 w-11 rounded-full" />
          ) : (
            <>
              {contact.companyType === COMPANY_TYPES.INDIVIDUAL && (
                <ProfilePhoto className="h-11 w-11 rounded-full bg-purple-700 flex items-center justify-center"></ProfilePhoto>
              )}
              {contact.companyType === COMPANY_TYPES.BUSINESS && (
                <CompanyPhoto className="h-11 w-11 rounded-full bg-purple-700 flex items-center justify-center"></CompanyPhoto>
              )}
            </>
          )}
          <div className="flex flex-col justify-center">
            <span className="text-gray-700 font-semibold ml-4">
              {contact?.companyType === COMPANY_TYPES.BUSINESS
                ? contact?.companyName
                : `${contact?.firstName} ${contact?.lastName}`}
            </span>
            {contact?.companyType !== COMPANY_TYPES.BUSINESS && contact?.companyName && (
              <span className="text-gray-500 text-xs ml-4">{`${contact?.companyName}`}</span>
            )}
          </div>
          {contact?.express && (
            <LightningFilledIcon className="fill-current w-4 text-yellow-500 ml-auto" />
          )}
        </div>
      ))}
    </div>
  </Popover.Panel>
);

const SelectContact = ({
  selectedContact,
  showTitle = true,
  showAddNewContact = true,
  setSelectedContactId = () => {},
  onChange = () => {},
  editContactModal
}) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);

  const [addContactModal, , toggleAddContactModal] = useModal();

  const { contacts } = useSelector((state) => state.contacts);

  function handleSelectedContact(contact) {
    onChange(contact);
    setSelectedContactId(contact.id);
    setSearch('');
  }

  useEffect(() => {
    const payload = contacts.filter(
      (contact) =>
        contact?.firstName?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) ||
        contact?.lastName?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) ||
        contact?.companyName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );

    setFilteredContacts(payload);
  }, [search, contacts]);

  useEffect(() => {
    dispatch(getContacts());
  }, [addContactModal, editContactModal]);

  return (
    <Fragment>
      {addContactModal && (
        <CreateContactModal onClose={toggleAddContactModal} onSucceded={handleSelectedContact} />
      )}
      {(showTitle || showAddNewContact) && (
        <div className="flex justify-between items-center">
          {showTitle && (
            <span className="font-semibold text-lg text-gray-900">Müşteri/Tedarikçi Seç</span>
          )}
          {showAddNewContact && (
            <span
              className="font-semibold text-sm text-green-500 flex items-center gap-x-2 cursor-pointer"
              onClick={toggleAddContactModal}
            >
              <IconPlus className="stroke-current w-4" />
              Yeni Ekle
            </span>
          )}
        </div>
      )}
      <div className="rounded-xl border border-solid border-gray-200 relative inline-block w-full bg-gray-100">
        <Popover>
          {({ close }) => (
            <>
              {selectedContact ? (
                <SelectedContactPreview selectedContact={selectedContact} />
              ) : (
                <SelectContactButton
                  search={search}
                  setSearch={setSearch}
                  filteredContacts={filteredContacts}
                  handleSelectedContact={handleSelectedContact}
                />
              )}
              <SelectContactPanel
                search={search}
                setSearch={setSearch}
                handleSelectedContact={handleSelectedContact}
                filteredContacts={filteredContacts}
                close={close}
              />
            </>
          )}
        </Popover>
      </div>
    </Fragment>
  );
};

export default SelectContact;
