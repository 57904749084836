import React from 'react';
import StepHeader from 'components/stepHeader';
import { StepInformationCard } from 'components';
import { ReactComponent as IconBankColored } from 'assets/icons/icon-bank-colored-step.svg';
import { ReactComponent as IconDownloadColored } from 'assets/icons/icon-download-colored-step.svg';
import { ReactComponent as IconKeyColored } from 'assets/icons/icon-key-colored.svg';
import { ReactComponent as IconNormaColored } from 'assets/icons/icon-norma-colored.svg';
import { useOpenBakingContext } from '../..';
import { downloadFileByUrl } from 'utils';

export default function BankList() {
  const {
    formValues: { bank },
    onClose
  } = useOpenBakingContext();

  const onDownloadBankForm = () => {
    downloadFileByUrl(bank.pdfUrl);
    onClose();
  };

  return (
    <>
      <StepHeader
        title="Entegre Banka Hesabı"
        subtitle="Banka Entegrasyonu için Gerekli Adımlar"
      />
      <StepInformationCard
        data={[
          {
            title: 'Formu indir ve imzala',
            icon: <IconBankColored className="w-12" />
          },
          {
            title: 'Formu bankaya ilet',
            icon: <IconDownloadColored className="w-12" />
          },
          {
            title: 'Banka entegrasyon bilgilerini gir',
            icon: <IconKeyColored className="w-12" />
          },
          {
            title: 'Norma’dan ilgili bankaya erişim sağla',
            icon: <IconNormaColored className="w-12" />
          }
        ]}
        actionButtonText="İndir"
        onClickActionButton={onDownloadBankForm}
      />
    </>
  );
}
