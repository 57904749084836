import React, { useEffect } from 'react';
import StepHeader from 'components/stepHeader';
import { Button, Card, CustomInput, Divider } from 'components';
import { useOpenBakingContext } from '../..';
import { useSelector } from 'react-redux';
import { addOpenBankingBank, getOpenBankingBankAccounts } from 'store/actions/openBanking';
import { useDispatch } from 'react-redux';

export default function AddBankAccountForm() {
  const { setStep, steps, formValues, setFormValues } = useOpenBakingContext();
  const {
    bankRequiredFields: { bankRequiredFieldMap, bankAccountRequiredFieldMap }
  } = useSelector((state) => state.openBanking);

  const dispatch = useDispatch();

  const { bank, corporate } = formValues;
  const requiredFields = { ...bankRequiredFieldMap, ...bankAccountRequiredFieldMap };

  useEffect(() => {
    return () => {
      setFormValues((previousValues) => ({
        ...previousValues,
        corporate: {}
      }));
    };
  }, []);
  useEffect(() => {
    if (bankRequiredFieldMap || bankAccountRequiredFieldMap) {
      const fields = Object.entries(requiredFields).map(([, value]) =>
        convertAsFieldName(value.label)
      );
      fields.map((field) => {
        setFormValues((previousValues) => ({
          ...previousValues,
          corporate: {
            ...previousValues.corporate,
            [field]: corporate[field] || ''
          }
        }));
      });
    }
  }, [bankRequiredFieldMap, bankAccountRequiredFieldMap]);

  const convertAsFieldName = (value) =>
    value
      .split(' ')
      .map((item, index) => {
        if (index === 0) return item.toLocaleLowerCase();
        return item;
      })
      .join('');

  const onChangeFormValue = (e) => {
    const { name, value } = e.target;
    setFormValues((previousValues) => ({
      ...previousValues,
      corporate: {
        ...formValues?.corporate,
        [name]: value
      }
    }));
  };

  const onAddBank = () => {
    dispatch(addOpenBankingBank(bank?.bankId, corporate)).then(({ openBankingId }) => {
      setStep(steps.selectBankAccount);
      dispatch(getOpenBankingBankAccounts(openBankingId));
    });
  };

  const isFormValid = Object.values(formValues.corporate).filter((value) => !value).length === 0;

  return (
    <>
      <StepHeader title="Entegre Banka Hesabı" subtitle="Banka Ekle" />
      <Card>
        <div className="flex flex-col gap-y-4">
          <img src={formValues.bank?.logoUrl} alt={bank?.bankName} className="w-[120px]" />
          {Object.entries(requiredFields).map(([, value], index) => {
            return (
              <CustomInput
                name={convertAsFieldName(value.label)}
                label={value.title}
                key={index}
                value={corporate[convertAsFieldName(value.label)]}
                onChange={onChangeFormValue}
              />
            );
          })}
        </div>
        <Divider className="my-10 border-gray-100" />
        <Button className="w-full" onClick={onAddBank} disabled={!isFormValid}>
          Kaydet
        </Button>
      </Card>
    </>
  );
}
