import cn from 'classnames';

const Popup = ({
  children,
  active,
  style,
  className,
  childrenClassName,
  overlayClassName,
  onClose
}) => {
  const openClasses = active ? 'fixed' : 'hidden';

  const baseClasses = `${openClasses} top-0 left-0 w-full h-screen flex items-center justify-center z-50`;
  const classNames = cn({
    [baseClasses]: baseClasses,
    [className]: className
  });
  const childrenClassNames = cn(
    ['bg-white w-80 h-96 px-4 py-8 rounded-lg w-min-max overflow-y-auto z-50'],
    { [childrenClassName]: childrenClassName }
  );
  const overlayClassNames = cn(
    'absolute top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-80 z-40',
    overlayClassName
  );
  return (
    <div className={classNames}>
      <div className={overlayClassNames} onClick={onClose}></div>
      <div className={childrenClassNames} style={style}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
