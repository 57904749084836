import axios from 'axios';

export const getVirtualOffices = () => {
  return (dispatch) => {
    return axios.get('/api/virtual-offices').then((res) => {
      dispatch({
        type: 'SET_VIRTUAL_OFFICES',
        data: res.data?.data
      });
    });
  };
};
