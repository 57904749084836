export const RECORD_TYPES = {
  SALES: 'SALES',
  EXPENSE: 'EXPENSE'
};

export const PAYMENT_METHODS = {
  NORMA: 'NORMA',
  BANK: 'BANK',
  CARD: 'CARD',
  CASH: 'CASH',
  CHECK: 'CHECK'
};

export const INVOICE_PROFILE_IDS = {
  TEMELFATURA: 'TEMELFATURA',
  TICARIFATURA: 'TICARIFATURA',
  KAMU: 'KAMU',
  IHRACAT: 'IHRACAT',
  YOLCUBERABERFATURA: 'YOLCUBERABERFATURA',
  HKS: 'HKS',
  EARSIVFATURA: 'EARSIVFATURA'
};

export const PAYMENT_METHOD_TITLES = {
  [PAYMENT_METHODS.NORMA]: 'Norma Hesabı',
  [PAYMENT_METHODS.BANK]: 'Banka Hesabı',
  [PAYMENT_METHODS.CARD]: 'Kredi / Banka Kartı',
  [PAYMENT_METHODS.CASH]: 'Nakit',
  [PAYMENT_METHODS.CHECK]: 'Çek'
};

export const CURRENCY_NAMES = {
  TRY: 'TL',
  USD: 'USD',
  EUR: 'EURO'
};
