import React, { useContext } from 'react';

import { Card, Label, Button } from 'components';
import StepHeader from 'components/stepHeader';
import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { BillContext } from '../..';

import { ReactComponent as IconBank } from 'assets/icons/bank.svg';
import { ReactComponent as IconInvoice } from 'assets/icons/invoice.svg';

import { useDispatch } from 'react-redux';
import { payBill } from 'store/actions/bill';
import { useSelector } from 'react-redux';

export default function ApplyPaymentProcess({ isNotDirectPayment }) {
  const { bills } = useSelector((state) => state.bill);
  const {
    nextStep,
    selectedPaymentAccount,
    selectedSubscription,
    billPaymentFormValues,
    selectedBill,
    toggleBillPaymentModal,
    toggleBillCreateAndPayModal
  } = useContext(BillContext);

  const dispatch = useDispatch();

  const onApprove = () => {
    dispatch(
      payBill({
        corpCode: billPaymentFormValues?.corporation?.value || selectedSubscription?.corpCode,
        subscriberNo: billPaymentFormValues?.subscriberNo || selectedSubscription?.subscriberNo,
        walletId: selectedPaymentAccount?.id || bills?.wallet?.id,
        invoiceNo: selectedBill?.invoiceNo
      })
    ).then(() => {
      nextStep();
    });
  };

  const onCancel = () => {
    isNotDirectPayment ? toggleBillPaymentModal() : toggleBillCreateAndPayModal();
  };
  return (
    <div className="w-full">
      <StepHeader title="İşlem Özeti" subtitle="Ödemeyi onaylıyor musunuz?" />
      <div>
        <Card className="flex flex-col gap-y-8">
          <SummaryCardItem
            label="Fatura"
            text={bills?.corpName}
            subscriberNo={bills.subscriberNo}
            icon={
              isNotDirectPayment ? (
                <IconInvoice className="fill-current w-6 h-6 text-green-700" />
              ) : (
                billPaymentFormValues.category?.icon
              )
            }
            descripton={billPaymentFormValues?.subscriberNo}
          />
          <SummaryCardItem
            label="Ödeme Yapılacak Hesap"
            icon={<IconBank className="fill-current w-6 h-6 text-green-700" />}
            text={selectedPaymentAccount?.name || bills?.wallet?.name}
            price={selectedPaymentAccount?.balance?.balance}
          />
          <TotalCard
            paymentAmount={selectedBill?.paymentAmount || 0}
            commissionAmount={selectedBill?.commissionAmount || 0}
            totalAmount={selectedBill?.totalAmount || 0}
          />
        </Card>
        <div className="flex gap-x-4 my-6 pb-6">
          <Button color="red" className="w-[168px]" onClick={onCancel}>
            Vazgeç
          </Button>
          <Button className="w-full" onClick={onApprove}>
            İşlemi Onayla
          </Button>
        </div>
      </div>
    </div>
  );
}

const SummaryCardItem = ({ label, text = '', subscriberNo, descripton = '', price = 0 }) => (
  <Label label={label} textClassName="!font-semibold text-lg text-gray-900">
    <Card className="flex items-center justify-between !bg-gray-100 !shadow-none">
      <div className="flex gap-x-3 items-center">
        {/* <span className="w-10 h-10 rounded-full bg-green-300 flex items-center justify-center">
          {typeof icon === 'string' ? <img src={icon} width={24} height={24} alt={text} /> : icon}
        </span> */}
        <div>
          <p className="font-semibold leading-6">{text}</p>
          {subscriberNo && <p className="text-xs font-medium text-gray-500">{subscriberNo}</p>}
          {descripton && <p className="text-gray-500 text-xs leading-4">{descripton}</p>}
        </div>
      </div>
      {price > 0 && (
        <div className="text-[28px] font-medium leading-7">
          {currencyFormat(price)} {CURRENCY_NAMES.TRY}
        </div>
      )}
    </Card>
  </Label>
);

const TotalCard = ({ totalAmount = 0, commissionAmount = 0, paymentAmount = 0 }) => {
  const TotalCardItem = ({ title = '', amount = 0 }) => (
    <div className="flex items-center justify-between w-full">
      <span className="text-sm font-semibold">{title}</span>
      <span className="font-semibold">
        {currencyFormat(amount)} {CURRENCY_NAMES.TRY}
      </span>
    </div>
  );
  return (
    <Card className="flex flex-col gap-y-5 items-center !shadow-none border border-solid border-gray-200">
      <TotalCardItem title="Fatura Tutarı" amount={paymentAmount} />
      <TotalCardItem title="İşlem Ücreti" amount={commissionAmount} />
      <TotalCardItem title="Toplam Tutarı" amount={totalAmount} />
    </Card>
  );
};
