import classNames from 'classnames';

import { useDeviceDetection } from 'utils/hooks';

const Card = ({ children, className, ...props }) => {
  const { isMobile } = useDeviceDetection();
  const cardClassNames = classNames(
    'h-auto bg-white shadow-norma rounded-xl',
    { 'p-6': !isMobile, 'p-4': isMobile },
    className
  );

  return (
    <div className={cardClassNames} {...props}>
      {children}
    </div>
  );
};

export default Card;
