import React, { useState, useEffect, useContext } from 'react';
import { Button, CodeInput, InfoMessage } from 'components';
import StepHeader from 'components/stepHeader';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';
import { ActivatePhysicalCardContext } from '../..';
import toast from 'react-hot-toast';
import { PIN_PASSWORD_LENGTH } from 'utils';

export default function EnterPassword() {
  const { setFormValues, formValues, nextStep } = useContext(ActivatePhysicalCardContext);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    if (formValues?.password?.length === PIN_PASSWORD_LENGTH) {
      if (PASSWORD_SEQUENTIAL_REGEX.test(formValues?.password)) {
        return toast.error('Şifreniz ardışık rakamlardan oluşamaz.');
      }
      if (PASSWORD_REPEATING_REGEX.test(formValues?.password)) {
        return toast.error('Şifreniz tekrarlayan rakamlardan oluşamaz.');
      } else {
        return setIsPasswordValid(true);
      }
    }
    setIsPasswordValid(false);
  }, [formValues?.password]);

  const onChangePassword = (password) => {
    setFormValues((values) => ({
      ...values,
      password
    }));
  };

  const onApprovePassword = () => {
    nextStep();
  };

  return (
    <div className="flex flex-col gap-y-10">
      <div>
        <StepHeader
          title="Şifre Belirle"
          subtitle="4 haneli kart şifrenizi belirleyin"
          className="!mb-10"
        />
        <div>
          <CodeInput
            length={4}
            invisible
            onChange={onChangePassword}
            only="number"
            value={formValues?.password}
            blockPaste
            autofocus
          />
        </div>
      </div>
      <InfoMessage>
        Şifreniz, tekrarlayan ya da ardışık rakamlardan oluşamaz. (111,123 vb)
      </InfoMessage>
      <Button className="w-full" disabled={!isPasswordValid} onClick={onApprovePassword}>
        Onayla
      </Button>
    </div>
  );
}
