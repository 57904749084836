import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { getClientCredentialsToken, forgotPasswordUpdate } from 'store/actions/auth';

import { Step0, Step1, Step2, Step3 } from './Steps';
import { useRouter } from 'utils/hooks/useRouter';
import { useRouteStep } from 'utils/hooks/useRouteStep';
import toast from 'react-hot-toast';

const defaultValues = {
  phone: '',
  password: '',
  againPassword: '',
  mfaToken: '',
  SMSCode: ''
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const router = useRouter();
  const [step, nextStep] = useRouteStep(
    { initialStep: 0, max: 4, router, fallbackPath: '/login' },
    (step) => {
      const notAllowedCallbackSteps = [1];
      var allowedStep = step;

      while (notAllowedCallbackSteps.includes(allowedStep)) {
        allowedStep -= 1;
      }

      nextStep(allowedStep);
    }
  );

  useEffect(() => {
    dispatch(getClientCredentialsToken());
  }, []);

  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues });
  const onSubmit = () => {
    if (watch('password') === watch('againPassword')) {
      const payload = {
        mfaToken: watch('mfaToken'),
        password: watch('password'),
        rePassword: watch('againPassword')
      };
      dispatch(forgotPasswordUpdate(payload)).then((res) => {
        if (!res) return;
        history.push('/login');
      });
    } else {
      setValue('password', '');
      setValue('againPassword', '');
      setError('password', {
        type: 'passwordHasNotMatched',
        message: 'Girdiğiniz şifreler uyuşmamaktadır'
      });
      toast.error(
        <div className="flex flex-col">
          <span className="text-sm font-semibold text-red-100">Hatalı Şifre</span>
          <span className="text-sm font-medium text-red-100">
            Girdiğiniz şifreler uyuşmamaktadır
          </span>
        </div>
      );
      nextStep(2);
    }
  };

  const STEPS = [
    <Step0
      key={'Step0'}
      nextStep={nextStep}
      watch={watch}
      setValue={setValue}
      setError={setError}
      errors={errors}
      clearErrors={clearErrors}
    />,
    <Step1
      key={'Step1'}
      setValue={setValue}
      watch={watch}
      nextStep={nextStep}
      setError={setError}
      errors={errors}
      clearErrors={clearErrors}
    />,
    <Step2
      key={'Step2'}
      length={6}
      setValue={setValue}
      watch={watch}
      nextStep={nextStep}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
    />,
    <Step3 key={'Step3'} length={6} setValue={setValue} watch={watch} />
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="form_forgot">
      {STEPS[step]}
    </form>
  );
};

export default ForgotPassword;
