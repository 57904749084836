import { useRef, useState } from 'react';
import ReactIdleTimer from 'react-idle-timer';
import Popup from 'components/Popup';
import ReactDom from 'react-dom';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import CustomButton from 'components/CustomButton';
import { logout, toggleIdleTimePopup } from 'store/actions/auth';
import { useDispatch, useSelector } from 'react-redux';

const IdleTimer = () => {
  const dispatch = useDispatch();

  const idleTimerRef = useRef();

  const [isTimedOut, setIsTimedOut] = useState(false);

  const { isIdleTimePopupActive } = useSelector((state) => state.auth);

  const timeOut =
    1000 *
    60 *
    (process.env?.REACT_APP_SESSION_TIMEOUT_DEV ||
      process.env?.REACT_APP_SESSION_TIMEOUT_STAGING ||
      process.env?.REACT_APP_SESSION_TIMEOUT_PROD);

  function onIdle() {
    if (isTimedOut) return;

    localStorage.removeItem('token');
    dispatch(toggleIdleTimePopup(true));
  }

  function handleLogout() {
    dispatch(toggleIdleTimePopup(false));
    dispatch(logout());
  }

  const IdleTimeOutPopup = ({ active, handleLogout }) =>
    ReactDom.createPortal(
      <Popup
        active={active}
        overlayClassName="backdrop-blur"
        childrenClassName="w-[327px] h-[320px] flex flex-col items-center justify-between text-center"
      >
        <div className="w-20 h-20 flex items-center justify-center rounded-full bg-yellow-100">
          <InfoCircleIcon className="fill-current w-10 text-yellow-600" />
        </div>
        <div className="flex flex-col gap-y-3">
          <span className="text-lg font-semibold text-gray-900">Tekrar Giriş Yapın</span>
          <span className="text-base font-medium text-gray-500">
            Uzun süredir işlem yapmadınız.
          </span>
        </div>
        <CustomButton color="success" className="w-full" onClick={handleLogout}>
          Giriş Yap
        </CustomButton>
      </Popup>,
      document.body
    );

  return (
    <>
      <IdleTimeOutPopup active={isIdleTimePopupActive} handleLogout={handleLogout} />
      <ReactIdleTimer
        ref={idleTimerRef}
        element={document}
        onActive={() => setIsTimedOut(false)}
        onIdle={onIdle}
        onAction={() => setIsTimedOut(false)}
        debounce={250}
        timeout={timeOut}
      />
    </>
  );
};

export default IdleTimer;
