import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg';
import classNames from 'classnames';

registerLocale('tr', tr);

const DatePicker = ({
  locale = 'tr',
  dateFormat = 'dd.MM.yyyy',
  ref,
  selected,
  isFormInput,
  className,
  label,
  placeholder = '',
  disabled,
  ...props
}) => {
  if (isFormInput)
    return (
      <div>
        {label && (
          <span className="text-gray-500 text-sm mb-1 font-medium leading-5">{label}</span>
        )}
        <div className="w-full bg-gray-100 px-3 rounded-lg flex items-center">
          <div className="flex flex-col w-full">
            <ReactDatePicker
              locale={locale}
              dateFormat={dateFormat}
              selected={selected}
              className={classNames(
                isFormInput
                  ? 'bg-transparent py-3 w-full focus:outline-none text-black disabled:text-gray-400 disabled:font-normal'
                  : '',
                className
              )}
              placeholderText={placeholder}
              {...props}
              disabled={disabled}
              customInputRef={ref}
              ref={ref}
            />
          </div>
          <IconCalendar className="fill-current w-5 text-gray-400" />
        </div>
      </div>
    );
  return (
    <ReactDatePicker
      className={classNames('!placeholder-gray-200 !font-semibold !text-base', className)}
      locale={locale}
      dateFormat={dateFormat}
      selected={selected}
      placeholderText={placeholder}
      {...props}
      disabled={disabled}
      customInputRef={ref}
      ref={ref}
    />
  );
};

export default DatePicker;
