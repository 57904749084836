import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideModal, CustomInput, CustomButton, CategoryBadge } from 'components';
import useDengage from 'utils/hooks/useDengage';

import {
  createCategory,
  getCategories,
  getCategoryColors,
  getCategoryIcons
} from 'store/actions/categories';

const AddCategoryModal = ({ handleClose, handleChangeCategory }) => {
  const dispatch = useDispatch();

  const { publishEvent, eventTypes } = useDengage();

  const [name, setName] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const { colors, icons } = useSelector((state) => state.categories);

  function _createCategory() {
    dispatch(
      createCategory({
        colorHexCode: selectedColor?.colorHexCode,
        iconUrl: selectedIcon,
        name
      })
    )
      .then((res) => {
        dispatch(getCategories()).then(() => {
          if (handleChangeCategory) handleChangeCategory(res.data.data);
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    dispatch(getCategoryIcons());
    dispatch(getCategoryColors());
  }, []);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'other_create_category' });
  }, []);

  return (
    <SideModal handleClose={handleClose}>
      <div className="flex flex-col w-full overflow-y-scroll">
        <span className="font-semibold text-2xl text-gray-900">Kategori Oluştur</span>
        <div className="flex justify-between mt-11">
          <CustomInput
            label="Kategori İsmi"
            onChange={(e) => setName(e.target.value)}
            className="w-24 min-w-max"
          />

          <div className="flex justify-center items-center w-1/2 flex-col max-w-full">
            <span className="text-gray-400 mb-1">Görünüm</span>
            <CategoryBadge
              colorHexCode={selectedColor?.colorHexCode || colors[0]?.colorHexCode}
              iconUrl={selectedIcon}
              name={name}
              textColorHexCode={selectedColor?.textColorHexCode}
              iconClassName="w-6"
            />
          </div>
        </div>
        <span className="text-xl text-gray-900 font-semibold mt-10">Icon Seç</span>
        <div className="w-full flex justify-center flex-wrap gap-y-4 mt-9">
          {icons.map((icon, i) => (
            <div
              key={i}
              className="w-1/5 h-auto flex items-center justify-center cursor-pointer"
              onClick={() => setSelectedIcon(icon)}
            >
              <div
                className={`${
                  icon === selectedIcon && 'shadow-lg border-gray-200'
                } border border-solid border-transparent text-gray-800 w-auto h-auto flex items-center justify-center p-4 rounded-xl`}
              >
                <img src={icon} className="w-6" alt="category icon" />
              </div>
            </div>
          ))}
        </div>
        <span className="text-xl text-gray-900 font-semibold mt-10">Renk Seç</span>
        <div className="w-full flex justify-center flex-wrap gap-y-8 mt-9">
          {colors.map((color) => (
            <div
              key={color.id}
              className="w-1/3 h-auto flex items-center justify-center cursor-pointer"
              onClick={() => setSelectedColor(color)}
            >
              <div
                className={`${
                  color.id === selectedColor?.id && 'shadow-lg border-gray-200'
                } border border-solid border-transparent w-auto h-auto flex items-center justify-center p-3 rounded-xl`}
              >
                <span
                  className="w-8 h-8 rounded-full"
                  style={{ background: color?.colorHexCode }}
                />
              </div>
            </div>
          ))}
        </div>
        <CustomButton
          color="success"
          disabled={!name || !selectedColor || !selectedIcon}
          className="my-14"
          onClick={_createCategory}
        >
          Ekle
        </CustomButton>
      </div>
    </SideModal>
  );
};

export default AddCategoryModal;
