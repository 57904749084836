import { ReactComponent as IconCheck } from 'assets/icons/check.svg';

const SelectedBadge = () => (
  <div className="p-1 rounded-full bg-green-500 absolute -right-1 -top-2 border-2 border-solid border-white flex items-center justify-center">
    <IconCheck className="stroke-current w-3 text-white" />
  </div>
);

const CategoryBadge = ({
  iconUrl,
  name,
  colorHexCode,
  textColorHexCode,
  selected,
  onClick,
  className,
  iconClassName
}) => {
  return (
    <div
      className={`${className} px-4 py-2 rounded-full text-black flex items-center gap-x-2 cursor-pointer relative`}
      style={{ background: colorHexCode, color: textColorHexCode }}
      onClick={onClick}
    >
      {iconUrl && <img src={iconUrl} alt="icon" className={`w-4 ${iconClassName}`} />}
      <span>{name}</span>
      {selected && <SelectedBadge />}
    </div>
  );
};

export default CategoryBadge;
