import { walletTypes } from 'store/types';

const {
  CLOSE_WALLET,
  CLOSE_WALLET_SUCCESS,
  CLOSE_WALLET_FAILURE,
  UPDATE_WALLET,
  UPDATE_WALLET_FAILURE,
  UPDATE_WALLET_SUCCESS,
  EXPORT_WALLET_TRANSACTIONS,
  EXPORT_WALLET_TRANSACTIONS_FAILURE,
  EXPORT_WALLET_TRANSACTIONS_SUCCESS,
  GET_WALLET_TYPES,
  GET_WALLET_TYPES_FAILURE,
  GET_WALLET_TYPES_SUCCESS
} = walletTypes;

const initialState = {
  loading: false,
  data: null,
  exportedTransactions: null,
  walletTypes: {
    bankAccountList: [],
    impersonalAccountList: []
  },
  error: null
};

export default function WalletReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_WALLET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CLOSE_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case CLOSE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_WALLET:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case UPDATE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EXPORT_WALLET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EXPORT_WALLET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        exportedTransactions: action.payload,
        error: null
      };
    case EXPORT_WALLET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_WALLET_TYPES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_WALLET_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        walletTypes: action.payload,
        error: null
      };
    case GET_WALLET_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
