import { useEffect, useRef, useState } from 'react';

import { Popup, CustomButton } from 'components';

import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import { uploadFile } from 'store/actions/fileUpload';
import FILE_TYPES from 'utils/fileTypes';
import toast from 'react-hot-toast';

const UploadDocumentPopup = ({ active, toggle, setFile: setFileSummary }) => {
  const fileInput = useRef();

  const [file, setFile] = useState();

  function handleFile(e) {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 15 * 1000 * 1000) return toast.error('Maksimum boyut 15mb');

    setFile(file);
  }

  async function uploadDocument() {
    try {
      const res = await uploadFile({ file, fileType: FILE_TYPES.SALES_INVOICE });
      if (!res.data?.data?.id) return;

      setFileSummary(res.data?.data);
      toggle();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (!file) {
      fileInput.current.value = '';
    }
  }, [file]);

  useEffect(() => {
    setFile(null);
  }, [active]);

  return (
    <Popup active={active} onClose={toggle} style={{ width: '500px', height: 'auto' }}>
      <div className="flex flex-col w-full">
        <span className="text-xl font-semibold text-gray-900">Fatura Yükleme</span>
        {file ? (
          <div className="mt-6 w-full border-2 border-dashed border-gray-300 rounded-lg p-4 flex gap-x-4 items-start justify-start bg-white cursor-default">
            <img src={URL.createObjectURL(file)} className="h-32 rounded-lg" />
            <div className="h-full w-full flex flex-col flex-wrap justify-start items-start gap-y-2 break-words">
              <span className="text-sm font-medium text-gray-900 text-ellipsis break-all">
                {file.name}
              </span>
              <CustomButton color="danger" onClick={() => setFile(null)}>
                <div className="flex gap-x-2 items-center">
                  <TrashIcon className="fill-current w-4 text-red-800" />
                  <span>Sil</span>
                </div>
              </CustomButton>
            </div>
          </div>
        ) : (
          <div
            className="mt-6 w-full border-2 border-dashed border-gray-300 rounded-lg py-8 flex flex-col items-center justify-center bg-gray-100 cursor-pointer"
            onClick={() => fileInput.current.click()}
          >
            <FileUploadIcon className="fill-current w-8 text-gray-400" />
            <span className="text-lg font-semibold text-gray-500 mt-3">
              Fatura eklemek için tıklayın
            </span>
            <span className="text-sm font-semibold text-gray-400">
              veya sürükleyip buraya bırakın
            </span>
          </div>
        )}
        <div className="flex gap-x-2 items-center my-6">
          <InfoCircleIcon className="fill-current w-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-500">
            Dosya tipi olarak .pdf, .jpg ve .png desteklenir. Maksimum boyut 15 mb
          </span>
        </div>
        <CustomButton color="success" onClick={uploadDocument} disabled={!file}>
          Yükle
        </CustomButton>
        <CustomButton color="danger" className="mt-4" onClick={toggle}>
          Vazgeç
        </CustomButton>
      </div>
      <input
        ref={fileInput}
        type="file"
        accept=".pdf,.jpg,.png"
        className="hidden"
        onChange={handleFile}
      />
    </Popup>
  );
};

export default UploadDocumentPopup;
