import axios from 'axios';
import { WALLET_TYPES } from 'utils/contants';

export const getAccounts = ({ status }) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios
      .get(`/api/companies/${user.companyId}/accounts`, { params: { status } })
      .then((res) => {
        dispatch({
          type: 'SET_ACCOUNTS',
          data: res.data?.data?.content
        });
      });
  };
};

export const getTransfers = ({ accountId, size, search, referenceNo, ...filters }) => {
  return () => {
    let params = {
      orderBy: 'createDate',
      orderDirection: 'DESC',
      status: 'COMPLETED',
      size,
      description: search,
      ...filters
    };
    if (referenceNo) {
      params['wallet-transfer-reference-id'] = referenceNo;
    }
    if (search) {
      params.description = search;
    }
    if (filters['start-date'] || filters['end-date']) {
      if (new Date(filters['start-date']).getTime() === new Date(filters['end-date']).getTime()) {
        params['end-date'] = new Date(new Date(filters['end-date']).setUTCHours(23, 59, 59, 999));
      }
    }
    return axios.get(`/api/accounts/${accountId}/transfers`, {
      params
    });
  };
};

export const openAccount = ({ currencyType, name }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.post(`/api/companies/${user.companyId}/accounts`, { currencyType, name });
  };
};

export const updateAccountStatus = ({ accountId, transferAccountId }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.put(`/api/companies/${user.companyId}/accounts/${accountId}/status`, {
      transferAccountId: transferAccountId || null
    });
  };
};

export const exportOrders = ({ fileType = 'CSV' } = { fileType: 'CSV' }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.get('/api/cards/orders/export', {
      'company-id': user.companyId,
      'file-type': fileType
    });
  };
};

/*
// Wallets
*/
const defaultParams = {
  type: WALLET_TYPES.EMONEY.value,
  status: 'ACTIVE'
};
export const getWallets = ({ openBankingId, ...params }) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();

    const queryParams = {
      ...defaultParams,
      ...params
    };

    if (openBankingId) {
      queryParams['open-banking-id'] = openBankingId;
    }

    dispatch({
      type: 'SET_ACCOUNT_LOAD'
    });

    return axios
      .get(`/api/companies/${user.companyId}/wallets`, {
        params: { ...defaultParams, ...queryParams }
      })
      .then((res) => {
        dispatch({
          type: 'SET_ACCOUNTS',
          data: res.data?.data
        });
        dispatch({
          type: 'SET_ACCOUNT_LOADED'
        });

        return Promise.resolve(res.data?.data);
      });
  };
};

export const createWallet = (payload) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios.post(`/api/companies/${user.companyId}/wallets`, {
      ...payload
    });
  };
};
