import { creditCardsTypes } from 'store/types';

const {
  GET_CREDIT_CARDS,
  GET_CREDIT_CARDS_FAILURE,
  GET_CREDIT_CARDS_SUCCESS,
  ADD_CARD,
  ADD_CARD_FAILURE,
  ADD_CARD_SUCCESS
} = creditCardsTypes;

const initialState = {
  data: null,
  cards: []
};

export default function creditCardsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREDIT_CARDS:
    case GET_CREDIT_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.data
      };
    case GET_CREDIT_CARDS_FAILURE:
      return {
        ...state,
        cards: action.data
      };
    case ADD_CARD:
    case ADD_CARD_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case ADD_CARD_FAILURE:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}
