import { ReactComponent as Close } from 'assets/icons/close.svg';

const FullModal = ({ title, children, handleClose, className }) => (
  <div
    className="fixed bottom-0 left-0 w-screen h-screen z-50 flex flex-col"
    data-testid="section_company_option_modal_full"
  >
    <div className={`${className} w-full flex-grow bg-white flex flex-col z-50 h-full relative`}>
      <div className="flex justify-between items-center mb-10 absolute right-6 top-6">
        <span
          className="font-semibold text-2xl"
          data-testid="section_company_option_modal_full_title"
        >
          {title}
        </span>
        <div
          onClick={handleClose}
          className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
          data-testid="button_company_option_modal_full_close"
        >
          <Close className="stroke-current text-black" />
        </div>
      </div>
      {children}
    </div>
  </div>
);

export default FullModal;
