import { CustomButton, Popup } from 'components';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const DeleteConfirmationPopup = ({ active, message, handleDelete, onClose }) => (
  <Popup active={active}>
    <div className="w-full h-full flex flex-col items-center justify-between">
      <span className="w-20 h-20 rounded-full bg-red-100 text-red-500 flex items-center justify-center">
        <TrashIcon className="fill-current w-8" />
      </span>
      <div className="w-full flex flex-col gap-y-4 items-center">
        <span
          className="font-semibold text-xl text-gray-900"
          data-testid="label_delete_confirmation_popup_title"
        >
          Müşteri/Tedarikçi Sil
        </span>
        <span className="text-gray-500" data-testid="label_delete_confirmation_popup_message">
          {message}
        </span>
      </div>
      <div className="w-full flex flex-col gap-y-4">
        <CustomButton
          onClick={handleDelete}
          color="danger"
          className="w-full"
          data-testid="button_delete_contact"
        >
          Sil
        </CustomButton>
        <CustomButton
          onClick={onClose}
          color="success"
          className="w-full"
          data-testid="button_cancel_delete_contact"
        >
          İptal Et
        </CustomButton>
      </div>
    </div>
  </Popup>
);

export default DeleteConfirmationPopup;
