import { contentResourceTypes } from 'store/types';
import axios from 'axios';

const {
  GET_ALL_CONTENT_RESOURCES,
  GET_ALL_CONTENT_RESOURCES_FAILED,
  GET_ALL_CONTENT_RESOURCES_SUCCEDED,
  GET_ERROR_CONTENT_RESOURCES,
  GET_ERROR_CONTENT_RESOURCES_FAILED,
  GET_ERROR_CONTENT_RESOURCES_SUCCEDED
} = contentResourceTypes;

const mapFromArrayToObject = (arr = []) => {
  if (arr?.length === 0) return;

  return arr?.reduce((acc, { key, value }) => ({ ...acc, [key]: value }));
};

const convertToLanguageObject = (source, language = 'tr') => {
  return {
    [language.toLocaleLowerCase()]: source
  };
};

export const getErrorContentResources = (lang = 'tr') => {
return async (dispatch, getState) => {
  try {
    const { errorContent } = getState().contentResource;
    dispatch({
      type: GET_ERROR_CONTENT_RESOURCES
    });

    const response = await axios.get(`/content-resources`, {
      params: { lang: 'TR', type: 'ERROR' }
    });

    if (response.status === 200) {
      const { resources, version } = response.data.data;
      if (errorContent?.version !== version) {
        dispatch({
          type: GET_ERROR_CONTENT_RESOURCES_SUCCEDED,
          payload: {
            resources: convertToLanguageObject(mapFromArrayToObject(resources), lang),
            version
          }
        });
        return Promise.resolve(response.data.data);
      }
    }
  } catch (error) {
    dispatch({
      type: GET_ERROR_CONTENT_RESOURCES_FAILED,
      payload: error
    });
    return Promise.reject(error);
  }
};
};

export const getContentResources = (lang = 'TR') => {
  return async (dispatch, getState) => {
    try {
      const { errorContent } = getState().contentResource;
      dispatch({
        type: GET_ALL_CONTENT_RESOURCES
      });

      const response = await axios.get(`/content-resources`, {
        params: { lang }
      });
      if (response.status === 200) {
        const { resources, version } = response.data.data;
        if (errorContent?.version !== version) {
          dispatch({
            type: GET_ALL_CONTENT_RESOURCES_SUCCEDED,
            payload: {
              resources: convertToLanguageObject(mapFromArrayToObject(resources), lang),
              version
            }
          });
          return Promise.resolve(response.data.data);
        }
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_CONTENT_RESOURCES_FAILED,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
