import React, { useState, useContext } from 'react';
import { BottomModal, Button, Card, CustomInput, DatePicker, Select } from 'components';

import { WALLET_TYPES } from 'utils/contants';
import classNames from 'classnames';
import { createContext } from 'react';
import StepHeader from 'components/stepHeader';

import { CURRENCY_NAMES } from 'views/invoicing/enums';
import AmountInput from 'components/AmountInput';
import IbanInput from 'views/transactions/components/IbanInput';

import DoubleCheck from 'assets/illustrations/card/double-check.png';
import { useDispatch } from 'react-redux';
import { createWallet, getWallets } from 'store/actions/accounts';
import { getFormattedDateForPayload } from 'utils';
import { useEffect } from 'react';

const Context = createContext({
  formValues: {
    type: '',
    balanceStartDate: new Date(),
    currencyCode: 'TRY',
    iban: '',
    initialBalance: 0,
    name: ''
  },
  setFormValues: () => {},
  step: 0,
  setStep: () => {},
  onClose: () => {},
  onSubmitForm: () => {}
});

export default function CreateWalletModal({ onClose, walletType }) {
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const dispatch = useDispatch();

  const modalClasses = classNames({ ['bg-green-400']: step === 1 });

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      type: walletType,
      balanceStartDate: new Date(),
      currencyCode: 'TRY',
      initialBalance: 0
    }));
  }, []);

  const onSubmitForm = () => {
    dispatch(
      createWallet({
        ...formValues,
        iban: formValues.iban?.replace('TR', '')?.length === 24 ? formValues?.iban : null,
        balanceStartDate: getFormattedDateForPayload(formValues?.balanceStartDate)
      })
    )
      .then(() => {
        setStep(1);
        dispatch(getWallets({ status: 'ACTIVE', type: formValues.type }));
      })
      .catch(() => {
        //TODO: handle error
      });
  };

  return (
    <Context.Provider value={{ step, setStep, formValues, setFormValues, onClose, onSubmitForm }}>
      <BottomModal isActive handleClose={onClose} className={modalClasses}>
        <div className="w-full h-full flex items-center justify-center max-w-[416px] mx-auto">
          {Steps[step]}
        </div>
      </BottomModal>
    </Context.Provider>
  );
}

const Steps = { 0: <CreateWalletForm />, 1: <Succeded /> };

const CreateCashWalletForm = () => {
  const { formValues, setFormValues, onSubmitForm } = useContext(Context);

  const currencies = Object.entries(CURRENCY_NAMES).map(([key]) => ({
    label: key,
    value: key
  }));

  const onSubmit = () => onSubmitForm();

  return (
    <>
      <CustomInput
        label="Hesap İsmi"
        onChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))}
      />
      <Select
        options={currencies}
        value={'value'}
        valueLabel="value"
        label="Para Birimi"
        defaultValue={formValues.currencyCode}
        onChange={(currency) => setFormValues((prev) => ({ ...prev, currencyCode: currency }))}
      />
      <AmountInput
        label="Bakiye"
        defaultValue={0}
        onValueChange={(e) => setFormValues((prev) => ({ ...prev, initialBalance: e }))}
      />
      <DatePicker
        label="Bakiye Başlangıç Tarihi"
        className="bg-transparent py-3 w-full focus:outline-none text-black"
        placeholder="Tarih seçin"
        selected={formValues.balanceStartDate}
        isFormInput
        onChange={(e) => setFormValues((prev) => ({ ...prev, balanceStartDate: e }))}
      />
      <Button
        className="w-full"
        disabled={!formValues.name || !formValues.currencyCode || !formValues.balanceStartDate}
        type="button"
        onClick={onSubmit}
      >
        Hesap Oluştur
      </Button>
    </>
  );
};

const CreateBankWalletForm = () => {
  const { formValues, setFormValues, onSubmitForm } = useContext(Context);

  const currencies = Object.entries(CURRENCY_NAMES).map(([key]) => ({
    label: key,
    value: key
  }));

  const isNotValid =
    !formValues?.name?.replace(/\s+/g, '') ||
    !formValues?.currencyCode ||
    !formValues?.balanceStartDate ||
    (formValues?.iban?.replace('TR', '').length > 0 &&
      formValues?.iban?.replace('TR', '')?.length < 24);

  const onSubmit = () => onSubmitForm();

  return (
    <>
      <CustomInput
        label="Hesap İsmi"
        onChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))}
        maxLength={14}
      />
      <Select
        options={currencies}
        value={'value'}
        valueLabel="value"
        label="Para Birimi"
        defaultValue={formValues.currencyCode}
        onChange={(currency) => setFormValues((prev) => ({ ...prev, currencyCode: currency }))}
      />
      <AmountInput
        label="Bakiye"
        defaultValue={0}
        onValueChange={(e) => setFormValues((prev) => ({ ...prev, initialBalance: e }))}
      />
      <DatePicker
        label="Bakiye Başlangıç Tarihi"
        className="bg-transparent py-3 w-full focus:outline-none text-black"
        placeholder="Tarih seçin"
        selected={formValues.balanceStartDate}
        isFormInput
        onChange={(e) => setFormValues((prev) => ({ ...prev, balanceStartDate: e }))}
      />
      <IbanInput
        label="IBAN"
        className="!m-0"
        setIban={(iban) => setFormValues((prev) => ({ ...prev, iban }))}
      />
      <Button className="w-full" disabled={isNotValid} type="button" onClick={onSubmit}>
        Hesap Oluştur
      </Button>
    </>
  );
};

const forms = {
  // [WALLET_TYPES.EMONEY.value]: <CreateEmoneyWalletForm />,
  [WALLET_TYPES.CASH.value]: <CreateCashWalletForm />,
  [WALLET_TYPES.BANK.value]: <CreateBankWalletForm />,
  [WALLET_TYPES.OPEN_BANKING.value]: ''
};

function CreateWalletForm() {
  const { formValues } = useContext(Context);
  return (
    <div className="w-full">
      <StepHeader
        title="Yeni Hesap Ekle"
        subtitle={`${WALLET_TYPES[formValues.type]?.label} Oluştur`}
      />
      <Card>
        <p className="text-base leading-6 font-semibold mb-3">Hesap Bilgileri</p>
        <form className="flex flex-col gap-y-4">{forms[formValues.type]}</form>
      </Card>
    </div>
  );
}

function Succeded() {
  const { onClose, formValues } = useContext(Context);
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-6">
      <img src={DoubleCheck} alt="double-check" width="100" />
      <p className=" text-3xl text-white text-center font-medium leading-8">
        &quot;{formValues.name}&ldquo; isimli hesap başarıyla oluşturuldu
      </p>
      <Button className="w-[400px]" onClick={onClose}>
        Kapat
      </Button>
    </div>
  );
}
