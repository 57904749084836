import React, { useState, useContext } from 'react';
import { Button, Card, Divider, EmptyView } from 'components';

import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import { ReactComponent as IconInvoice } from 'assets/icons/invoice.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconRefresh } from 'assets/icons/refresh-2.svg';
import {
  ApplyPaymentProcess,
  BillCreateSuccess,
  BillDeleteSuccess,
  BillModal,
  BillPayment,
  BillPaymentSuccess,
  RecordedBills
} from './components';
import { createContext } from 'react';
import useModal from 'utils/hooks/useModal';
import { useEffect } from 'react';
import { getBillSubscriptions, resetGetBillState } from 'store/actions/bill';
import { useSelector, useDispatch } from 'react-redux';
import { BILL_STATUSES } from 'utils';
import useTextShortener from 'utils/hooks/useTextShortener';
import IconContainer from 'components/iconContainer';
import { WALLET_TYPES } from 'utils/contants';
import { getWallets } from 'store/actions/accounts';

const initialValues = {
  billPaymentFormValues: {
    category: null,
    corporation: null,
    subscriberNo: null,
    shoudbeSaveBill: false,
    billRecordName: '',
    autoPayment: false,
    wallet: null
  }
};
export const BillContext = createContext({
  billPaymentFormValues: initialValues.billPaymentFormValues,
  setBillPaymentFormValues: () => {},
  step: 0,
  setStep: () => {},
  showBackButton: true,
  setShowBackButton: () => {},
  nextStep: () => {},
  previousStep: () => {},
  toggleBillCreateAndPayModal: () => {},
  toggleBillCreateModal: () => {},
  toggleBillPaymentModal: () => {},
  selectedSubscription: null,
  setSelectedSubscription: () => {},
  selectedBill: null,
  setSelectedBill: () => {},
  selectedPaymentAccount: null,
  setSelectedPaymentAccount: () => {},
  isBillCreateAndPayModalActive: false,
  isBillCreateModalActive: false,
  isBillPaymentModalActive: false,
  resetBillPaymentFormValues: () => {}
});

export default function Bills() {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const [billPaymentFormValues, setBillPaymentFormValues] = useState(
    initialValues.billPaymentFormValues
  );
  const [showBackButton, setShowBackButton] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedBill, setSelectedBill] = useState(null);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState(null);

  const [isBillCreateAndPayModalActive, , toggleBillCreateAndPayModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isBillCreateModalActive, , toggleBillCreateModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isBillPaymentModalActive, , toggleBillPaymentModal] = useModal({
    shouldBeBlockScroll: true
  });

  useEffect(() => {
    if (!isBillCreateAndPayModalActive || !isBillCreateModalActive || !isBillPaymentModalActive) {
      setStep(0);
      setBillPaymentFormValues(initialValues.billPaymentFormValues);
      setSelectedPaymentAccount(null);
      dispatch(resetGetBillState());
    }
  }, [isBillCreateAndPayModalActive, isBillCreateModalActive, isBillPaymentModalActive]);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const previousStep = () => setStep((prevStep) => prevStep - 1);

  const resetBillPaymentFormValues = () =>
    setBillPaymentFormValues(initialValues.billPaymentFormValues);

  const BillCreateAndPaySteps = [
    <BillPayment key="BillPayment" />,
    <RecordedBills key="RecordedBills" />,
    <ApplyPaymentProcess key="ApplyPaymentProcess" />,
    <BillPaymentSuccess key="BillPaymentSuccess" onClose={toggleBillCreateAndPayModal} />
  ];

  const BillCreateSteps = [
    <BillPayment key="BillPayment" isNewBillCreate />,
    <BillCreateSuccess key="BillCreateSuccess" onClose={toggleBillCreateModal} />
  ];

  const BillPaymentSteps = [
    <RecordedBills key="RecordedBills" isNotDirectPayment />,
    <ApplyPaymentProcess key="ApplyPaymentProcess" isNotDirectPayment />,
    <BillPaymentSuccess key="BillPaymentSuccess" onClose={toggleBillPaymentModal} />,
    <BillDeleteSuccess key="BillDeleteSuccess" onClose={toggleBillPaymentModal} />
  ];

  return (
    <BillContext.Provider
      value={{
        step,
        setStep,
        nextStep,
        previousStep,
        billPaymentFormValues,
        setBillPaymentFormValues,
        showBackButton,
        setShowBackButton,
        selectedSubscription,
        setSelectedSubscription,
        selectedBill,
        setSelectedBill,
        toggleBillCreateAndPayModal,
        toggleBillCreateModal,
        toggleBillPaymentModal,
        selectedPaymentAccount,
        setSelectedPaymentAccount,
        isBillCreateAndPayModalActive,
        isBillCreateModalActive,
        isBillPaymentModalActive,
        resetBillPaymentFormValues
      }}
    >
      {isBillCreateAndPayModalActive && (
        <BillModal
          steps={BillCreateAndPaySteps}
          onClose={toggleBillCreateAndPayModal}
          className={step === 3 && '!bg-green-400'}
        />
      )}
      {isBillCreateModalActive && (
        <BillModal
          steps={BillCreateSteps}
          className={step === 1 && '!bg-green-400'}
          onClose={toggleBillCreateModal}
        />
      )}
      {isBillPaymentModalActive && (
        <BillModal
          steps={BillPaymentSteps}
          className={step > 1 && '!bg-green-400'}
          onClose={toggleBillPaymentModal}
        />
      )}
      <div className="flex flex-col gap-y-12">
        <h1 className="text-[22px] font-semibold text-gray-900 leading-7">Fatura İşlemleri</h1>
        <MakeBillPaymentCardButton />
        <Divider className="border-gray-200 border-b-[1px]" />
        <SubscribedBills />
      </div>
    </BillContext.Provider>
  );
}

const MakeBillPaymentCardButton = ({ ...props }) => {
  const { toggleBillCreateAndPayModal } = useContext(BillContext);
  return (
    <Card
      className="flex justify-between items-center max-w-[492px] cursor-pointer !shadow-none border border-solid border-gray-200"
      onClick={toggleBillCreateAndPayModal}
      {...props}
    >
      <div className="flex items-center gap-x-4">
        <IconInvoice className="fill-current stroke-[1px] text-gray-700 w-5 h-5" />
        <span>Fatura Ödeme</span>
      </div>
      <IconArrowRight className="stroke-current text-gray-700 w-5 h-5" />
    </Card>
  );
};

const SubscribedBills = () => {
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state) => state.bill);
  const { user } = useSelector((state) => state.auth);
  const { toggleBillCreateModal, toggleBillPaymentModal, setSelectedSubscription } =
    useContext(BillContext);

  useEffect(() => {
    dispatch(getBillSubscriptions(user?.companyId));
    dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
  }, []);

  const onSelected = (item) => {
    setSelectedSubscription(item);
    toggleBillPaymentModal();
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-[34px]">
        <h2 className="text-lg font-semibold text-gray-900 leading-6">Kayıtlı Faturalar</h2>
        <Button
          variant="ghost"
          leftIcon={<IconPlus className="stroke-current" />}
          onClick={toggleBillCreateModal}
        >
          Yeni Ekle
        </Button>
      </div>
      <div className="flex flex-col gap-y-6">
        {subscriptions?.map((data, i) => (
          <SubscribedBillsItem item={data} key={i} onClick={onSelected} />
        ))}
        {subscriptions?.length === 0 && <EmptyView>Kayıtlı Faturanız Bulunmamaktadır</EmptyView>}
      </div>
    </div>
  );
};

const SubscribedBillsItem = ({ onClick, item, ...props }) => {
  const corporationName = useTextShortener(item?.corpName, 15);
  return (
    <Card
      className="flex items-center justify-between bg-gray-50 border border-solid border-gray-100 !shadow-none cursor-pointer hover:bg-gray-100 h-[98px]"
      onClick={() => onClick(item)}
      {...props}
    >
      <div className="flex items-center gap-x-16">
        <span className="font-medium leading-6 min-w-[300px]">{item?.recordName}</span>
        <span className="text-sm text-gray-500 font-medium min-w-[145px]">{corporationName}</span>
        <span className="text-sm text-gray-500 font-medium">Abone No: {item?.subscriberNo}</span>
      </div>
      <div className="flex items-center gap-x-14 ml-1 ">
        <div className="flex items-center gap-x-4">
          {item?.autoPayment && (
            <IconContainer className="bg-[#EEF8D3] w-[49px] h-9 relative">
              <IconRefresh className="fill-current text-green-900 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </IconContainer>
          )}
          {[BILL_STATUSES.LATE, BILL_STATUSES.NOT_PAID].includes(item?.status) && (
            <IconContainer className="!bg-yellow-100 w-[49px] h-9 relative">
              <IconInvoice className="fill-current text-yellow-500 w-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </IconContainer>
          )}
        </div>
        <IconArrowRight className="stroke-current text-gray-700 w-5 h-5" />
      </div>
    </Card>
  );
};
