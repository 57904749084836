import React from 'react';
import { Card, ContentContainer } from 'components';
import { ReactComponent as IconBalanceIn } from 'assets/icons/balance-in.svg';
import { ReactComponent as IconBalanceOut } from 'assets/icons/add-balance.svg';
import IncomeExpenseItem from '../incomeExpenseItem';

export default function CashFlow({ balance, currency }) {
  return (
    <ContentContainer
      title={
        <React.Fragment>
          Nakit Akışı{' '}
          <span className="text-base leading-6 font-semibold text-gray-500">Son 30 gün</span>
        </React.Fragment>
      }
      className="mt-10"
    >
      <Card>
        <div className="flex gap-x-4">
          <IncomeExpenseItem
            icon={<IconBalanceIn className="w-5 fill-current text-gray-700" />}
            title="Gelen Para"
            value={balance?.expenseIncome?.income || 0}
            currency={currency}
            isIncome={true}
          />
          <div className="border border-r border-gray-100 border-solid h-14 self-center"></div>
          <IncomeExpenseItem
            icon={<IconBalanceOut className="w-5 fill-current text-gray-700" />}
            title="Giden Para"
            value={balance?.expenseIncome?.expense || 0}
            currency={currency}
          />
        </div>
      </Card>
    </ContentContainer>
  );
}
