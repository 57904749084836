// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categoryLabel_base__1-8tD {\n    position: relative;\n    display: flex;\n    cursor: pointer;\n    align-items: center\n}\n.categoryLabel_base__1-8tD > :not([hidden]) ~ :not([hidden]) {\n    --tw-space-x-reverse: 0;\n    margin-right: calc(0.5rem * var(--tw-space-x-reverse));\n    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))\n}\n.categoryLabel_base__1-8tD {\n    border-radius: 9999px\n}\n.categoryLabel_md__3oODu {\n    padding-left: 1rem;\n    padding-right: 1rem;\n    padding-top: 0.5rem;\n    padding-bottom: 0.5rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 500\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,mBAAA;IAAA,cAAA;IAAA,gBAAA;IAAA;CAAA;AAAA;IAAA,wBAAA;IAAA,uDAAA;IAAA;CAAA;AAAA;IAAA;CAAA;AAAA;IAAA,mBAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA,uBAAA;IAAA,oBAAA;IAAA,qBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "categoryLabel_base__1-8tD",
	"md": "categoryLabel_md__3oODu"
};
export default ___CSS_LOADER_EXPORT___;
