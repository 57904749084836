import { CustomButton, InfoMessage, Select } from 'components';
import { useEffect, useRef } from 'react';
import StepHeader from 'views/company-options/StepHeader';

import { useDispatch, useSelector } from 'react-redux';

import { getProfessions, getSectors } from 'store/actions/activityScope';
import useKeyPress from 'utils/hooks/useKeyPress';
import useDengage from 'utils/hooks/useDengage';

export default function CompanyActivityInformation({ setValue, watch }) {
  const submitRef = useRef();
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'start_company_activity_info' });
  }, []);

  const { sectors, professions } = useSelector((state) => state.activityScope);

  useEffect(() => {
    dispatch(getSectors());
    dispatch(getProfessions());
  }, []);

  useKeyPress((e) => {
    if (e.key !== 'Enter') return;
    submitRef.current.click();
  });

  return (
    <div className="flex flex-col">
      <StepHeader
        title="Şirket Kur"
        subtitle="İşinizden ve faaliyet gösterdiğiniz sektörden kısaca bahsedin"
      />
      <div className="flex flex-col space-y-4" data-testid="section_start_company_info">
        <div className="flex flex-col gap-y-1 mt-6">
          <label className="text-sm text-gray-500 font-medium">Faaliyet Bilgileri</label>
          <textarea
            className="appearance-none bg-gray-100 rounded-lg resize-none focus:outline-none p-3 h-20"
            autoFocus
            onChange={(e) => setValue('businessInfo', e.target.value)}
            value={watch('businessInfo')}
            data-testid="input_start_company_info_business_info"
            maxLength={255}
          />
        </div>
        <Select
          options={sectors}
          value="refId"
          valueLabel="value"
          label="Sektör Bilgisi"
          onChange={(e) => setValue('sector', e)}
          defaultValue={watch('sector')}
          data-testid="select_start_company_info_sector"
        />
        <Select
          options={professions}
          value="refId"
          valueLabel="value"
          label="Meslek Bilgisi"
          onChange={(e) => setValue('profession', e)}
          defaultValue={watch('profession')}
          data-testid="select_start_company_info_profession"
        />
        <CustomButton
          color="success"
          className="mt-6 w-full"
          type="submit"
          disabled={!watch('sector') || !watch('profession') || !watch('businessInfo')}
          data-testid="button_start_company_info_next"
        >
          Devam
        </CustomButton>
        <button
          ref={submitRef}
          className="hidden"
          type="submit"
          data-testid="button_start_company_info_submit_hidden"
        />
        <InfoMessage>
          İşiniz ve sektörünüz ile ilgili girdiğiniz bilgileri faaliyet alanınızı belirlemede
          kullanacağız.
        </InfoMessage>
      </div>
    </div>
  );
}
