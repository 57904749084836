import React from 'react';
import StepHeader from 'components/stepHeader';

import { ReactComponent as IconNorma } from 'assets/icons/norma-icon.svg';
import { ReactComponent as IconBankColored } from 'assets/icons/bank-colored.svg';
import { ReactComponent as IconWalletColored } from 'assets/icons/wallet-colored.svg';
import { ReactComponent as IconIntegratedBankColored } from 'assets/icons/integrated-bank-colored.svg';

import { WALLET_TYPES } from 'utils/contants';
import { BottomModal, Card, Divider } from 'components';
import classNames from 'classnames';
import { useWalletContext } from 'contexts/walletContext';
export default function WalletTypeSelectionModal({ onTypeSelected, onClose }) {
  const { setSelectedWalletType } = useWalletContext();

  const onSelected = (type) => {
    setSelectedWalletType(type);
    onTypeSelected(type);
  };

  return (
    <BottomModal isActive={true} handleClose={onClose}>
      <div className="w-full h-full flex flex-col items-center justify-center px-4 mx-auto overflow-y-auto">
        <div>
          <StepHeader
            title="Yeni Hesap Ekle"
            subtitle="Oluşturmak istediğiniz hesap tipini seçin"
          />
          <div className="flex flex-col gap-y-4 h-full">
            {WALLETS.map((wallet, i) => {
              if (wallet?.title) {
                return <div className="text-sm text-gray-400 font-medium">{wallet?.title}</div>;
              }
              if (wallet?.isDivider) {
                return <Divider className="border-gray-100" />;
              }
              return (
                <WalletTypeCard
                  key={i}
                  icon={wallet.icon}
                  text={wallet.label}
                  description={wallet.description}
                  disabled={wallet.isDisabled}
                  type={wallet.type}
                  onSelected={onSelected}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BottomModal>
  );
}

const WALLETS = [
  {
    title: 'Banka Hesapları'
  },
  {
    label: WALLET_TYPES.EMONEY.label,
    icon: <IconNorma className=" w-8" />,
    type: WALLET_TYPES.EMONEY.value,
    description: 'Tüm ticari ödeme ve tahsilatlarınız için ücretsiz kurumsal hesap.',
    isDisabled: false
  },
  {
    label: WALLET_TYPES.OPEN_BANKING.label,
    icon: <IconIntegratedBankColored className=" w-8" />,
    type: WALLET_TYPES.OPEN_BANKING.value,
    description: 'Anlaşmalı banka hesaplarınızı Norma üzerinden yönetebileceğiniz hesap.',
    isDisabled: false
  },
  { isDivider: true },
  {
    title: 'Kasa Hesapları'
  },
  {
    label: WALLET_TYPES.CASH.label,
    icon: <IconWalletColored className=" w-8" />,
    type: WALLET_TYPES.CASH.value,
    description: 'Şirketinizin nakit giriş-çıkışlarını takip edebileceğiniz kasa hesabı.',
    isDisabled: false
  },
  {
    label: WALLET_TYPES.BANK.label,
    icon: <IconBankColored className=" w-8" />,
    type: WALLET_TYPES.BANK.value,
    description: 'Bankalardaki hesap hareketlerinizi takip edebileceğiniz hesap.',
    isDisabled: false
  }
];

function WalletTypeCard({ icon, text, description, onSelected, disabled, type, ...props }) {
  const classes = classNames('w-full flex gap-x-4 cursor-pointer relative select-none bg-white', {
    ['!bg-gray-100 cursor-not-allowed border']: disabled
  });

  return (
    <Card className={classes} onClick={() => onSelected(type)} {...props}>
      <div>{icon}</div>
      <div className="flex flex-col gap-y-2 cur">
        <p className="text-base font-semibold text-gray-900">{text}</p>

        <p className="text-sm font-medium text-gray-600">{description}</p>
      </div>
      {disabled && (
        <span className="absolute top-5 right-5 bg-gray-200 rounded-full px-3 py-1 text-xs text-gray-600">
          Yakında
        </span>
      )}
    </Card>
  );
}
