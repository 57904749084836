import axios from 'axios';

export const initializeMarketing = ({ userId }) => {
  if (window && window?.dengage) {
    window?.dengage('setContactKey', userId);
    window?.dengage('getToken', function (token) {
      if (token) {
        axios.post(`/users/${userId}/push-notifications`, {
          alias: 'normaWeb',
          token,
          marketingToken: window?.Adjust.getWebUUID()
        });
      }
    });
  }
};

export const initAdjust = () => {
  const options = {
    appToken: process.env.REACT_APP_ADJUST_TOKEN,
    environment: process.env.NODE_ENV !== 'production' ? 'sandbox' : 'production'
  };
  window?.Adjust?.initSdk(options);
};
