import React from 'react';
import { Button, Popup } from 'components';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';

export default function ClosePhysicalCardPopup({ onClose, onApproved, onReject, ...props }) {
  return (
    <Popup active onClose={onClose} {...props}>
      <div className="flex flex-col justify-center items-center">
        <div className="w-20 h-20 rounded-full bg-red-100 flex justify-center items-center">
          <IconInfoCircle className="fill-current w-10 text-red-500" />
        </div>
        <span className="mt-6 text-lg font-semibold text-gray-900 text-center">
          Kartınızı kapatmak istediğinizden emin misiniz?
        </span>
        <Button color="red" onClick={onApproved} className="w-full mt-6">
          Evet
        </Button>
        <Button onClick={onReject} className="w-full mt-4">
          Geri Dön
        </Button>
      </div>
    </Popup>
  );
}
