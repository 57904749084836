import { bankInformationsTypes } from '../../types';
const { GET_BANK_INFORMATIONS } = bankInformationsTypes;

const bankInformationsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_BANK_INFORMATIONS:
      return action.data;
    default:
      return state;
  }
};

export default bankInformationsReducer;
