import classNames from 'classnames';
import { Card } from 'components';
import React from 'react';

function PageCard({ title, menu, children, titleClassName, className, ...props }) {
  const titleClassname = classNames('text-white', titleClassName);
  const cardClassname = classNames('px-12 py-16', className);
  return (
    <div className="flex flex-col gap-y-6" {...props}>
      <div className="flex justify-between items-center">
        <h5 className={titleClassname}>{title}</h5>
        {menu && <div className="flex gap-x-6">{menu}</div>}
      </div>
      <Card className={cardClassname}>{children}</Card>
    </div>
  );
}

PageCard.Container = ({ children }) => {
  return (
    <div className="relative bg-gray-200 flex flex-col justify-start items-center py-12 min-h-screen">
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative flex flex-col justify-start items-start w-11/12 xl:w-3/4">
        <div className="flex flex-col gap-y-10 w-full">{children}</div>
      </div>
    </div>
  );
};

PageCard.Container.displayName = 'PageCard.Container';

export default PageCard;
