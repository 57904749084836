import React, { useState, useEffect } from 'react';
import { Card, ContentContainer, Divider } from 'components';
// import { ReactComponent as IconBalanceIn } from 'assets/icons/balance-in.svg';
// import { ReactComponent as IconBalanceOut } from 'assets/icons/add-balance.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import PopoverSelect from 'components/popoverSelect';
import { currencyFormat, PAYMENT_STATUS } from 'utils';
import { CURRENCY_NAMES, RECORD_TYPES } from 'views/invoicing/enums';
import { useDispatch } from 'react-redux';
import { getAllContactTransactionDebtStatus } from 'store/actions/contacts';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';

const filterOptions = [
  {
    label: 'Bugün',
    value: 'TODAY'
  },
  {
    label: 'Gecikmiş',
    value: 'LATE'
  },
  {
    label: 'Toplam',
    value: 'TOTAL'
  }
];

const FILTER_OPTION_INDEX = {
  TODAY: 0,
  LATE: 1,
  TOTAL: 2
};
export default function DebtCredit() {
  const dispatch = useDispatch();

  const [filterBy, setFilterBy] = useState(filterOptions[0]);
  const { allContactTransactionDebtStatus } = useSelector((state) => state.contacts);

  useEffect(() => {
    dispatch(getAllContactTransactionDebtStatus({ queryType: filterBy.value }));
  }, []);

  const [filters, setFilters] = useState(null);

  useEffect(() => {
    let filterPayload = {
      paymentStatus: PAYMENT_STATUS.NOT_PAID,
      recordType: RECORD_TYPES.SALES,
      startDueDate: '',
      endDueDate: ''
    };

    if (filterBy?.value === filterOptions[FILTER_OPTION_INDEX.TODAY].value) {
      filterPayload = {
        ...filterPayload,
        startDueDate: null,
        endDueDate: new Date()
      };
    } else if (filterBy?.value === filterOptions[FILTER_OPTION_INDEX.LATE].value) {
      filterPayload = {
        ...filterPayload,
        startDueDate: null,
        endDueDate: new Date(moment().subtract(1, 'day'))
      };
    } else {
      filterPayload = {
        ...filterPayload,
        startDueDate: null,
        endDueDate: null
      };
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...filterPayload
    }));
  }, [filterBy]);

  useEffect(() => {
    dispatch(getAllContactTransactionDebtStatus({ queryType: filterBy.value }));
  }, [filters]);

  return (
    <ContentContainer
      title={<React.Fragment>Alacaklar /Borçlar</React.Fragment>}
      actions={
        <>
          <PopoverSelect
            options={filterOptions}
            defaultValue={filterOptions[0]}
            onChange={(item) => setFilterBy(item)}
          />
        </>
      }
      className="mt-10"
    >
      <Card>
        <div className="flex flex-col w-full">
          <CardItem
            title="Alacaklarım"
            filters={{
              ...filters,
              recordType: RECORD_TYPES.SALES
            }}
            amount={allContactTransactionDebtStatus?.creditAmount}
          />
          <Divider className="!border-b" />
          <CardItem
            title="Borçlarım"
            filters={{
              ...filters,
              recordType: RECORD_TYPES.EXPENSE
            }}
            amount={allContactTransactionDebtStatus?.debtAmount}
          />
        </div>
      </Card>
    </ContentContainer>
  );
}

const CardItem = ({ title, amount = 0, linkTo = '/invoicing', filters }) => {
  return (
    <Link
      to={{
        pathname: linkTo,
        state: {
          filters: {
            ...filters
          }
        }
      }}
    >
      <div className="flex justify-between py-4">
        <div className="text-base font-semibold text-gray-900">{title}</div>
        <div className="text-lg font-semibold text-gray-500 flex gap-x-4 items-center">
          <span>
            {currencyFormat(amount)} {CURRENCY_NAMES.TRY}
          </span>
          <IconArrowRight className="stroke-current text-gray-500 w-5 h-5" />
        </div>
      </div>
    </Link>
  );
};
