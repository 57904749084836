import { CustomInput, PhoneInput } from 'components';

const ContactInformation = ({ watch, setValue, clearErrors, errors, register }) => (
  <div className="flex flex-col gap-y-4">
    <span className="text-[18px] text-gray-600 font-semibold">İletişim Bilgileri</span>
    <CustomInput
      label="E-Posta"
      isOptional
      name="email"
      onChange={(e) => {
        setValue('email', e.target.value);
        clearErrors('email');
      }}
      maxLength={140}
      value={watch('email')}
      error={errors?.email?.message}
      register={register}
      data-testid="input_email"
    />
    <PhoneInput
      onChange={(phone) => {
        setValue('fullPhoneNumber', phone);
        clearErrors('fullPhoneNumber');
      }}
      isOptional={true}
      showCountryCode={false}
      defaultValue={watch('fullPhoneNumber')}
      error={errors?.fullPhoneNumber?.message}
      justCellPhone={false}
      data-testid="input_phone"
    />
  </div>
);

export default ContactInformation;
