import { paymentTypes } from 'store/types';

const {
  GET_CHECK_CARD_ALIAS,
  GET_CHECK_CARD_ALIAS_SUCCESS,
  GET_CHECK_CARD_ALIAS_FAILURE,
  MAKE_PAYMENT,
  MAKE_PAYMENT_FAILURE,
  MAKE_PAYMENT_SUCCESS,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_FAILURE,
  GET_PAYMENT_STATUS_SUCCESS,
  RESET_PAYMENT_STATUS
} = paymentTypes;

const initialState = {
  isCardAliasExist: false,
  cardStore: null,
  paymentStatus: null,
  data: null
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHECK_CARD_ALIAS:
    case GET_CHECK_CARD_ALIAS_SUCCESS:
      return {
        ...state,
        isCardAliasExist: action.data
      };
    case GET_CHECK_CARD_ALIAS_FAILURE:
      return {
        ...state,
        isCardAliasExist: action.data
      };
    case MAKE_PAYMENT:
    case MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.data
      };
    case MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        payment: action.data
      };
    case GET_PAYMENT_STATUS:
    case GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatus: action.data
      };
    case GET_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        paymentStatus: action.data
      };
    case RESET_PAYMENT_STATUS:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
