import { COMPANY_TYPES } from 'utils';

const CompanyTypeSelection = ({ watch, setValue }) => (
  <div className="flex items-center justify-between">
    <span
      className={`w-full py-2 text-center transition border-b-2 border-solid ${
        watch('companyType') === COMPANY_TYPES.INDIVIDUAL &&
        'border-green-500 text-green-500 cursor-default'
      }
      ${
        watch('companyType') === COMPANY_TYPES.BUSINESS &&
        'border-gray-200 text-gray-500 cursor-pointer'
      }`}
      onClick={() => setValue('companyType', COMPANY_TYPES.INDIVIDUAL)}
      data-testid="button_company_type_selection_individual"
    >
      Bireysel
    </span>
    <span
      className={`w-full py-2 text-center transition border-b-2 border-solid ${
        watch('companyType') === COMPANY_TYPES.BUSINESS
          ? 'border-green-500 text-green-500 cursor-default'
          : 'border-gray-200 text-gray-500 cursor-pointer'
      }`}
      onClick={() => setValue('companyType', COMPANY_TYPES.BUSINESS)}
      data-testid="button_company_type_selection_business"
    >
      Şirket
    </span>
  </div>
);

export default CompanyTypeSelection;
