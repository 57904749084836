import { useEffect, useContext } from 'react';
import useDengage from 'utils/hooks/useDengage';
import { CustomButton } from 'components';
import { ChangeCardPasswordContext } from '../..';

import CardCorrect from 'assets/illustrations/card/card-correct.png';

export default function UpdatePinSuccess() {
  const { publishEvent, eventTypes } = useDengage();
  const { toggleChangeCardPasswordModal } = useContext(ChangeCardPasswordContext);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_card_password_change_success' });
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center space-y-8"
      data-testid="label_modal_change_password_success"
    >
      <img src={CardCorrect} width="180" alt="card correct" />
      <span className="text-white text-xl">Kart şifreniz başarıyla değiştirildi</span>
      <CustomButton color="success" className="w-48" onClick={toggleChangeCardPasswordModal}>
        Tamam
      </CustomButton>
    </div>
  );
}
