import { CustomInput } from 'components';
import { useContactContext } from 'contexts/contactContext';
import { useEffect, useState } from 'react';
import IbanInput from 'views/transactions/components/IbanInput';

const AccountInformation = ({ errors }) => {
  const { formValues, setFormValues } = useContactContext();
  const [ibanValidationErrorMessage, setIbanValidationErrorMessage] = useState('');
  const ibanRegex = new RegExp(
    /TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?/
  );

  useEffect(() => {
    if (formValues.iban?.length > 2)
      setFormValues((prevValues) => ({
        ...prevValues,
        isIbanValid: ibanRegex.test(formValues.iban)
      }));
  }, [formValues.iban]);

  function handleIbanChange(value) {
    let isValidIban = true;
    if (value.length > 2) {
      isValidIban = ibanRegex.test(value);
      if (!isValidIban) {
        setIbanValidationErrorMessage('IBAN numarası uygun olmalıdır!');
      }
    } else {
      isValidIban = true;
    }

    if (isValidIban) setIbanValidationErrorMessage('');

    setFormValues((prevValues) => ({
      ...prevValues,
      iban: value,
      isIbanValid: isValidIban
    }));
  }

  function handleNormaNoChange(e) {
    const spaces = [3, 7, 10];
    e.target.value = e.target.value.replaceAll(' ', '');

    for (let i = 0; i < e.target.value.length; i++) {
      if (spaces.includes(i)) {
        e.target.value = e.target.value.slice(0, i) + ' ' + e.target.value.slice(i);
      }
    }

    setFormValues((prevValues) => ({ ...prevValues, walletNumber: e.target.value }));
  }

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-[18px] text-gray-600 font-semibold">Hesap Bilgileri</span>

      <IbanInput
        label="IBAN"
        name="iban"
        iban={formValues?.iban}
        isOptional
        setIban={handleIbanChange}
        data-testid="input_iban"
        error={ibanValidationErrorMessage}
      />
      <CustomInput
        label="Norma No"
        isOptional
        name="walletNumber"
        onChange={handleNormaNoChange}
        value={formValues?.walletNumber}
        error={errors?.walletNumber?.message}
        only="number"
        maxLength={13}
        data-testid="input_account_number"
      />
    </div>
  );
};

export default AccountInformation;
