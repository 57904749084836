import { useState, createContext } from 'react';
import { BottomModal } from 'components';

import { ConfirmPassword, ConfirmSmsToken, EnterPassword, UpdatePinSuccess } from './components';

export const ChangeCardPasswordContext = createContext({
  cardId: '',
  walletId: '',
  cardPin: '',
  confirmCardPin: '',
  setCardId: () => {},
  setWalletId: () => {},
  setCardPin: () => {},
  setConfirmCardPin: () => {},
  nextStep: () => {},
  toggleChangeCardPasswordModal: () => {}
});

const ChangeCardPasswordModal = ({
  toggleChangeCardPasswordModal,
  cardId: currentCardId,
  walletId: currentWalletId
}) => {
  const [cardId, setCardId] = useState(currentCardId);
  const [walletId, setWalletId] = useState(currentWalletId);
  const [cardPin, setCardPin] = useState('');
  const [confirmCardPin, setConfirmCardPin] = useState('');

  const [step, setStep] = useState(0);
  function nextStep() {
    setStep(step + 1);
  }

  const STEPS = [
    <EnterPassword key={'EnterPassword'} />,
    <ConfirmPassword key={'ConfirmPassword'} />,
    <ConfirmSmsToken key={'ConfirmSmsToken'} />,
    <UpdatePinSuccess key={'UpdatePinSuccess'} />
  ];

  return (
    <ChangeCardPasswordContext.Provider
      value={{
        cardId,
        setCardId,
        walletId,
        setWalletId,
        cardPin,
        setCardPin,
        confirmCardPin,
        setConfirmCardPin,
        nextStep,
        toggleChangeCardPasswordModal
      }}
    >
      <BottomModal
        handleClose={toggleChangeCardPasswordModal}
        className={step === 3 && 'bg-green-500'}
      >
        <div className="w-full h-full flex items-center justify-center">
          <div data-testid="form_modal_change_password">{STEPS[step]}</div>
        </div>
      </BottomModal>
    </ChangeCardPasswordContext.Provider>
  );
};

export default ChangeCardPasswordModal;
