import React from 'react';
import { Menu } from '@headlessui/react';

import { ReactComponent as IconCog } from 'assets/icons/cog.svg';

export default function AccountSettingsMenu({
  //   tab,
  menus = []
  //   handleCloseAccountConfirmation,
  //   selectedWalletType
}) {
  return (
    <Menu as="div" className="text-left relative z-30">
      <Menu.Button
        as="div"
        className="flex items-center justify-center rounded-full bg-white w-10 h-10 cursor-pointer"
      >
        <IconCog className="fill-current text-gray-500 w-5" />
      </Menu.Button>
      <Menu.Items className="origin-top-right absolute right-0 mt-5 w-56 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none p-6 text-gray-800 bg-white flex flex-col gap-y-4 rounded-xl shadow z-10 select-none">
        {menus?.map((menu, index) => (
          <MenuItem
            key={index}
            label={menu?.label}
            icon={menu?.icon}
            onClick={menu?.onClick}
            isActive={menu?.isActive}
          />
        ))}
      </Menu.Items>
    </Menu>
  );
}

const MenuItem = ({ icon, label, onClick, isActive = true, ...props }) => {
  if (!isActive) return null;
  return (
    <Menu.Item
      as="div"
      className="w-full flex items-center cursor-pointer p-2"
      onClick={onClick}
      {...props}
    >
      {icon}
      <span>{label}</span>
    </Menu.Item>
  );
};
