import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createContact } from 'store/actions/contacts';

import { CustomButton, SideModal } from 'components';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { BUSINESS_TYPES, COMPANY_TYPES, DEBT_STATUS } from 'utils';
// import { uploadFile } from 'store/actions/fileUpload';
// import FILE_TYPES from 'utils/fileTypes';

import CompanyTypeSelection from '../companyTypeSelection';
import CompanyInformation from '../companyInformation';
// import ProfileImageBadge from '../profileImageBadge';
import PersonalInformation from '../personalInformation';
import ContactInformation from '../contactInformation';
import CompanyContactInformation from '../companyContactInformation';
import AccountInformationCompany from '../accountInformationCompany';
import AccountInformationIndividual from '../accountInformationIndividual';
import InvoiceInformation from '../invoiceInformation';
import MakeQuickContact from '../makeQuickContact';
import CurrentAccountInformation from '../currentAccountInformation';
import httpStatus from 'utils/httpStatus';
// import moment from 'moment/moment';

const AddContactModal = ({ toggleAddContactModal, handleCreatedContact, onCreated }) => {
  const dispatch = useDispatch();

  const [companyType, setCompanyType] = useState(COMPANY_TYPES.INDIVIDUAL);
  const [hasOpeningBalance, setHasOpeningBalance] = useState(false);

  const ibanRegex = new RegExp(
    /(^$|(TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?))/
  );

  const defaultValues = {
    companyType: COMPANY_TYPES.INDIVIDUAL,
    profilePhoto: null,
    firstName: '',
    lastName: '',
    email: '',
    fullPhoneNumber: '',
    walletNumber: '',
    iban: '',
    companyName: '',
    taxIdentityNumber: '',
    taxOfficeId: '',
    cityId: '',
    districtId: '',
    address: '',
    express: false,
    initialBalance: 0,
    initialBalanceDate: new Date(),
    debtStatus: DEBT_STATUS.NONE,
    businessType: BUSINESS_TYPES.NONE
  };

  const schema = z.object({
    companyType: z.string(),
    profilePhoto: z.optional(z.string().nullable()),
    firstName: z.string({ required_error: 'İsim girilmesi zorunludur' }),
    // .min(2, { message: 'İsim en az 2 harften oluşmalıdır' }),
    lastName: z.string({ required_error: 'Soyisim girilmesi zorunludur' }),
    // .min(2, { message: 'Soyisim en az 2 harften oluşmalıdır' }),
    email: z.string(),
    fullPhoneNumber: z.string(),
    walletNumber: z.optional(z.string()),
    iban: z
      .string({ required_error: 'IBAN formatı hatalı' })
      .regex(ibanRegex, 'IBAN formatı hatalı'),
    companyName:
      companyType === COMPANY_TYPES.BUSINESS
        ? z
            .string({ required_error: 'Şirket adı girilmesi zorunludur' })
            .nonempty('Şirket adı girilmesi zorunludur')
        : z.optional(z.string()),
    taxIdentityNumber: z.string(),
    taxOfficeId:
      companyType === COMPANY_TYPES.BUSINESS
        ? z.number({
            required_error: 'Vergi dairesi seçilmesi zorunludur',
            invalid_type_error: 'Vergi dairesi seçilmesi zorunludur'
          })
        : z.optional(z.any()),
    cityId: z.optional(z.any()),
    districtId: z.optional(z.any()),
    address: z.optional(z.string()),
    express: z.optional(z.boolean()),
    initialBalance: hasOpeningBalance ? z.number() : z.optional().nullable(),
    initialBalanceDate: hasOpeningBalance ? z.date() : z.optional().nullable(),
    debtStatus: hasOpeningBalance ? z.string() : z.optional().nullable(),
    businessType: hasOpeningBalance ? z.string() : z.optional().nullable()
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues
  });

  useEffect(() => {
    setValue('companyType', COMPANY_TYPES.INDIVIDUAL);
    setValue('initialBalanceDate', new Date().toISOString());
  }, []);

  useEffect(() => {
    setCompanyType(watch('companyType'));
  }, [watch('companyType')]);

  async function onSubmit(data) {
    if (companyType !== COMPANY_TYPES.BUSINESS) {
      delete data.companyName;
      delete data.taxOfficeId;
    }

    dispatch(createContact(data))
      .then((res) => {
        if (res.status !== httpStatus.CREATED) return;
        toggleAddContactModal();
        if (handleCreatedContact) handleCreatedContact(res.data?.data);
        if (onCreated) onCreated();
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }

  return (
    <SideModal handleClose={toggleAddContactModal}>
      <div
        className="flex flex-col gap-y-10 w-full overflow-y-auto px-4"
        data-testid="section_add_contact_modal"
      >
        <span
          className="font-semibold text-[28px] text-gray-900"
          data-testid="label_add_contact_modal"
        >
          Müşteri/Tedarikçi Oluştur
        </span>
        <CompanyTypeSelection watch={watch} setValue={setValue} />
        {watch('companyType') === COMPANY_TYPES.INDIVIDUAL ? (
          <>
            <CurrentAccountInformation
              watch={watch}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
              register={register}
              hasOpeningBalance={hasOpeningBalance}
              setHasOpeningBalance={setHasOpeningBalance}
            />
            <PersonalInformation
              watch={watch}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
              register={register}
            />
            <ContactInformation
              watch={watch}
              clearErrors={clearErrors}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <AccountInformationIndividual
              setValue={setValue}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
            />
            <InvoiceInformation
              clearErrors={clearErrors}
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
            />
          </>
        ) : (
          <>
            <CurrentAccountInformation
              isCorporate={true}
              watch={watch}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
              register={register}
              hasOpeningBalance={hasOpeningBalance}
              setHasOpeningBalance={setHasOpeningBalance}
            />
            <CompanyInformation
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
            />
            <AccountInformationCompany
              setValue={setValue}
              watch={watch}
              errors={errors}
              clearErrors={clearErrors}
            />
            <CompanyContactInformation
              watch={watch}
              setValue={setValue}
              clearErrors={clearErrors}
              errors={errors}
            />
          </>
        )}
        <MakeQuickContact register={register} />
        <CustomButton
          color="success"
          onClick={handleSubmit(onSubmit)}
          className="mb-16"
          disabled={
            watch('companyType') === COMPANY_TYPES.INDIVIDUAL
              ? !watch('businessType') ||
                !watch('debtStatus') ||
                !watch('firstName') ||
                !watch('lastName')
              : !watch('businessType') ||
                !watch('debtStatus') ||
                !watch('initialBalance') ||
                !watch('lastName')
          }
          data-testid="button_add_contact_modal"
        >
          Oluştur
        </CustomButton>
      </div>
    </SideModal>
  );
};

export default AddContactModal;
