import { paymentTypes } from 'store/types';
import axios from 'axios';

const {
  GET_CHECK_CARD_ALIAS,
  GET_CHECK_CARD_ALIAS_FAILURE,
  GET_CHECK_CARD_ALIAS_SUCCESS,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_FAILURE,
  GET_PAYMENT_STATUS_SUCCESS,
  RESET_PAYMENT_STATUS
} = paymentTypes;

export const checkCardAlias = (cardAlias) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId) return;

      dispatch({ type: GET_CHECK_CARD_ALIAS });

      const response = await axios.get(`/api/payments/card/stored/check-alias`, {
        params: {
          cardAlias
        }
      });

      if (response.status === 200) {
        dispatch({
          type: GET_CHECK_CARD_ALIAS_SUCCESS,
          data: response.data.data
        });
      }
      return response.data.data;
    } catch (error) {
      dispatch({
        type: GET_CHECK_CARD_ALIAS_FAILURE,
        payload: error
      });
    }
  };
};

export const getPaymentStatus = (transactionId) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId) return;

      dispatch({ type: GET_PAYMENT_STATUS });

      const response = await axios.get(`/api/payments/transaction/${transactionId}`);

      if (response.status === 200) {
        dispatch({
          type: GET_PAYMENT_STATUS_SUCCESS,
          data: response.data.data
        });
      }

      return response.data.data;
    } catch (error) {
      dispatch({
        type: GET_PAYMENT_STATUS_FAILURE,
        payload: error
      });
    }
  };
};
export const resetPaymentStatus = () => (dispatch) => dispatch({ type: RESET_PAYMENT_STATUS });
