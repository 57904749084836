import { useContext } from 'react';
import { BottomModal, Button } from 'components';
import { BillContext } from '../..';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';

export default function BillModal({ steps = [], className, onClose }) {
  const { step, previousStep, showBackButton } = useContext(BillContext);

  return (
    <BottomModal isActive handleClose={onClose} className={className}>
      <div className="h-12 m-6">
        {showBackButton && step > 0 && (
          <Button variant="circle" color="gray" icon={IconArrowLeft} onClick={previousStep} />
        )}
      </div>
      <div className="flex flex-col items-center justify-center h-full overflow-y-auto">
        <div className="w-full h-full flex max-w-[640px] mx-auto">{steps[step]}</div>
      </div>
    </BottomModal>
  );
}
