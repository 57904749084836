import React, { useEffect } from 'react';
import StepHeader from 'components/stepHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useSmartOperationContext } from '../..';
import { Button, Card, Divider, Label, SelectedAccountView } from 'components';

import useTextShortener from 'utils/hooks/useTextShortener';

import { getBills } from 'store/actions/bill';
import { LineInfo } from 'views/transactions/components';

export default function AutoBillPaymentDetail() {
  const { bills } = useSelector((state) => state.bill);

  const { selectedBill, toggleAutoBillEditSideModal } = useSmartOperationContext();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getBills({
        corporationId: selectedBill?.corpCode,
        subscriberNo: selectedBill?.subscriberNo
      })
    );
  }, []);

  return (
    <div className="flex flex-col gap-y-6 w-full">
      <div className="flex items-center justify-between">
        <StepHeader title="Akıllı İşlemler" subtitle="Otomatik Ödeme" />
        <Button color="secondary" onClick={toggleAutoBillEditSideModal}>
          Düzenle
        </Button>
      </div>
      <BillInformationCard bill={selectedBill} />

      <Label label="Ödeme Yapılacak Hesap">
        <SelectedAccountView name={bills?.wallet?.name} amount={bills?.wallet?.balance || 0} />
      </Label>
    </div>
  );
}

const BillInformationCard = ({ bill }) => {
  const { bills } = useSelector((state) => state.bill);
  const recordName = useTextShortener(bill?.recordName, 10);
  const corpName = useTextShortener(bills?.corpName, 15);

  return (
    <Card className="bg-gray-50 !shadow-none flex items-center gap-x-14 px-6">
      <p className="text-sm font-semibold leading-5">{recordName}</p>
      <div className="flex gap-x-11">
        <LineInfo title="Abone Adı" description={bills?.subscriberName} />
        <Divider isRight />
        <LineInfo title="Abone No" description={bills?.subscriberNo} />
        <Divider isRight />
        <LineInfo title="Kurum Adı" description={corpName} />
      </div>
    </Card>
  );
};
