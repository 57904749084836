import { useEffect, useState } from 'react';
import useDengage from 'utils/hooks/useDengage';
import { CodeInput, CustomButton } from 'components';

import { useContext } from 'react';
import StepHeader from 'components/stepHeader';
import { ChangeCardPasswordContext } from '../..';
import { PASSWORD_REPEATING_REGEX, PASSWORD_SEQUENTIAL_REGEX } from 'utils/regex';
import toast from 'react-hot-toast';
import { checkPrepaidCardPin } from 'store/actions/card';
import { useDispatch } from 'react-redux';
import { PIN_PASSWORD_LENGTH } from 'utils';

export default function ConfirmPassword() {
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);

  const { publishEvent, eventTypes } = useDengage();
  const { setConfirmCardPin, confirmCardPin, cardPin, walletId, cardId, nextStep } = useContext(
    ChangeCardPasswordContext
  );
  const dispatch = useDispatch();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_card_password_2' });
  }, []);

  useEffect(() => {
    if (confirmCardPin?.length === PIN_PASSWORD_LENGTH) {
      if (PASSWORD_SEQUENTIAL_REGEX.test(confirmCardPin)) {
        return toast.error('Şifreniz ardışık rakamlardan oluşamaz.');
      }
      if (PASSWORD_REPEATING_REGEX.test(confirmCardPin)) {
        return toast.error('Şifreniz tekrarlayan rakamlardan oluşamaz.');
      }
      if (cardPin !== confirmCardPin) {
        return toast.error('Girdiğiniz pinler uyuşmuyor');
      } else {
        return setIsConfirmPasswordValid(true);
      }
    }
    setIsConfirmPasswordValid(false);
  }, [confirmCardPin]);

  const onCheckPin = () => {
    dispatch(checkPrepaidCardPin({ walletId, cardId, pin: cardPin })).then(() => {
      nextStep();
    });
  };

  return (
    <div className="flex flex-col">
      <StepHeader
        title="Şifresi Değiştir"
        subtitle="4 haneli kart şifrenizi tekrar girin"
        data-testid="label_modal_change_password_again"
      />
      <CodeInput
        length={4}
        invisible
        onChange={setConfirmCardPin}
        className="mt-4"
        only="number"
        value={confirmCardPin}
        data-testid="input_modal_change_password_again"
        blockPaste
        autofocus
      />
      <CustomButton
        className="mt-6 w-96"
        color="success"
        disabled={!isConfirmPasswordValid}
        data-testid="button_modal_change_password_again"
        onClick={onCheckPin}
      >
        Onayla
      </CustomButton>
    </div>
  );
}
