import { walletTypes } from 'store/types';
import axios from 'axios';
import { filterKeys, getFormattedDateForPayload } from 'utils';

const {
  CLOSE_WALLET,
  CLOSE_WALLET_SUCCESS,
  CLOSE_WALLET_FAILURE,
  UPDATE_WALLET,
  UPDATE_WALLET_FAILURE,
  UPDATE_WALLET_SUCCESS,
  EXPORT_WALLET_TRANSACTIONS,
  EXPORT_WALLET_TRANSACTIONS_FAILURE,
  EXPORT_WALLET_TRANSACTIONS_SUCCESS,
  GET_WALLET_TYPES,
  GET_WALLET_TYPES_FAILURE,
  GET_WALLET_TYPES_SUCCESS
} = walletTypes;

export const closeWallet = ({ walletId, transferWalletId }) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: CLOSE_WALLET_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: CLOSE_WALLET
      });

      const response = await axios.post(
        `/api/companies/${user?.companyId}/wallets/${walletId}/close`,
        {
          transferWalletId
        }
      );

      if (response.status === 200) {
        dispatch({
          type: CLOSE_WALLET_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CLOSE_WALLET_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const updateWallet = (payload) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: UPDATE_WALLET_FAILURE,
        payload: 'user companyId is not defined'
      });

    try {
      dispatch({
        type: UPDATE_WALLET
      });

      const response = await axios.put(`/api/companies/${user.companyId}/wallets/${payload.id}`, {
        ...payload
      });

      if (response.status === 200) {
        dispatch({
          type: UPDATE_WALLET_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_WALLET_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const exportWalletTransactions = (walletId = null, payload, fileType = 'CSV') => {
  const filters = {
    'start-date': payload?.date?.startDate
      ? getFormattedDateForPayload(payload?.date?.startDate)
      : null,
    'end-date': payload?.date?.endDate
      ? getFormattedDateForPayload(payload?.date?.endDate)
      : null,
    direction: payload?.direction,
    'category-id': payload?.['category-id'],
    title: payload?.search,
    'file-type': fileType
  };

  const payloadParams = filterKeys(filters, null, {
    filterEmpty: true,
    filterNull: true
  });

  return async (dispatch) => {
    if (!walletId)
      return dispatch({
        type: EXPORT_WALLET_TRANSACTIONS_FAILURE,
        payload: 'walletId is not defined'
      });

    try {
      dispatch({
        type: EXPORT_WALLET_TRANSACTIONS
      });

      const response = await axios.get(`/api/wallets/${walletId}/transactions/export`, {
        params: payloadParams
      });

      if (response.status === 200) {
        dispatch({
          type: EXPORT_WALLET_TRANSACTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: EXPORT_WALLET_TRANSACTIONS_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};

export const getWalletTypes = () => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId)
      return dispatch({
        type: GET_WALLET_TYPES_FAILURE,
        payload: 'companyId is not found'
      });

    try {
      dispatch({
        type: GET_WALLET_TYPES
      });

      const response = await axios.get(`/api/companies/${user?.companyId}/wallets/types`);

      if (response.status === 200) {
        dispatch({
          type: GET_WALLET_TYPES_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_WALLET_TYPES_FAILURE,
        payload: error
      });
      return Promise.reject(error);
    }
  };
};
