import React, { useEffect } from 'react';
import StepHeader from 'views/company-options/StepHeader';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { COMPANY_SUB_TYPES } from 'utils';
import { Card } from 'components';

import { ReactComponent as IconBuilding } from 'assets/icons/building.svg';
import { ReactComponent as IconClipboard } from 'assets/icons/clipboard.svg';

export default function CompanyType({ setValue, nextStep }) {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'company_type' });
  }, []);

  function handleCompanySubType(type) {
    setValue('companySubType', type);
    nextStep();
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'add_company_type',
      company_type: type
    });
  }

  return (
    <div>
      <StepHeader title="Şirket Ekle" subtitle="Eklemek istediğiniz şirket türünü seçin" />
      <div className="flex flex-col space-y-4">
        <Card
          className="flex hover:translate-y-1 cursor-pointer"
          onClick={() => handleCompanySubType(COMPANY_SUB_TYPES.SOLE_TRADER)}
          data-testid="section_add_company_sole_trader"
        >
          <IconBuilding className="fill-current text-green-500 w-12" />
          <div className="flex flex-col space-y-2">
            <span
              className="text-gray-900 font-semibold"
              data-testid="label_add_company_sole_trader"
            >
              Şahıs/A.Ş/LTD Şirketi
            </span>
            <span
              className="text-gray-700 text-sm"
              data-testid="label_add_company_sole_trader_subtitle"
            >
              Kolay ve hızlı bir şekilde şirketinizi ekleyin.
            </span>
          </div>
        </Card>
        <Card
          className="flex hover:translate-y-1 cursor-pointer"
          onClick={() => handleCompanySubType(COMPANY_SUB_TYPES.FREE_LANCER)}
        >
          <IconClipboard className="w-12" />
          <div className="flex flex-col space-y-2">
            <span
              className="text-gray-900 font-semibold"
              data-testid="label_add_company_free_lancer"
            >
              Serbest Meslek Erbabı
            </span>
            <span
              className="text-gray-700 text-sm"
              data-testid="label_add_company_free_lancer_subtitle"
            >
              Kolay ve hızlı bir şekilde şirketinizi ekleyin
            </span>
          </div>
        </Card>
  
      </div>
    </div>
  );
}
