import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getSummarySeries } from 'store/actions/invoice';
import externalTooltipHandler from './externalTooltipHandler';
import { DAYS, MONTHS } from 'utils';

const LABEL_INTERVAL = {
  WEEK: 'day',
  MONTH: 'date',
  YEAR: 'month'
};

const Chart = () => {
  const dispatch = useDispatch();

  const { series, intervalType } = useSelector((state) => state.invoice);
  const { user } = useSelector((state) => state.auth);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Satış',
        data: []
      },
      {
        label: 'Gider',
        data: []
      }
    ]
  });

  const chartOptions = {
    plugins: {
      title: { display: false },
      legend: { display: false },
      decimation: { enabled: false },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    scales: {
      x: { grid: { display: false, drawBorder: false } },
      y: { display: false }
    }
  };

  useEffect(() => {
    dispatch(getSummarySeries());
  }, [intervalType, user]);

  useEffect(() => {
    const payload = {
      labels: series
        .map((s, i) => ({
          ...s,
          date: new Date(s.createDate).getDate(),
          month: MONTHS[i],
          day: DAYS[i]
        }))
        .map((s) => s[LABEL_INTERVAL[intervalType]]),
      datasets: [
        {
          borderSkipped: false,
          borderRadius: 5,
          label: 'Satış',
          data: series.map((s) => s.saleAmount),
          backgroundColor: '#6B7280',
          hoverBackgroundColor: '#10B981'
        },
        {
          borderSkipped: false,
          borderRadius: 5,
          label: 'Gider',
          data: series.map((s) => s.expenseAmount),
          backgroundColor: '#D1D5DB',
          hoverBackgroundColor: '#E73152'
        }
      ]
    };
    setChartData(payload);
  }, [series]);

  return <Bar data={chartData} options={chartOptions} height={50} />;
};

export default Chart;
