import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useRouter } from 'utils/hooks/useRouter';
import { useRouteStep } from 'utils/hooks/useRouteStep';

import { getClientCredentialsToken } from 'store/actions/auth';

import {
  SignupOutline,
  SignupEmail,
  SignupEmailOtp,
  SignupPhone,
  SignupCredentials,
  SignupPassword,
  SignupSmsCode,
  SignupRePassword
} from './Steps';

const COMPANY_OPTIONS = {
  HAVE_COMPANY: 'HAVE_COMPANY',
  HAVE_NOT_COMPANY: 'HAVE_NOT_COMPANY',
  NONE: 'NONE'
};

const defaultValues = {
  address: '',
  birthDate: '',
  city: '',
  companyName: '',
  country: '',
  email: '',
  identityNumber: '',
  name: '',
  phone: '',
  privacy: false,
  terms: false,
  informationPermission: false,
  surname: '',
  taxCode: '',
  taxOffice: '',
  companyOption: COMPANY_OPTIONS.NONE,
  password: '',
  againPassword: ''
};

const Signup = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const [step, nextStep] = useRouteStep(
    { initialStep: 0, max: 8, fallbackPath: '/welcome', router },
    (stepParam) => {
      const notAllowedCallbackSteps = [2, 4, 7];
      var allowedStep = stepParam;

      while (notAllowedCallbackSteps.includes(allowedStep)) {
        allowedStep -= 1;
      }

      nextStep(allowedStep);
    }
  );

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ defaultValues });
  const onSubmit = () => {};

  const STEPS = [
    <SignupOutline key={'SignupOutline'} nextStep={nextStep} />,
    <SignupEmail
      key={'SignupEmail'}
      nextStep={nextStep}
      register={register}
      watch={watch}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      errors={errors}
    />,
    <SignupEmailOtp
      key={'SignupEmailOtp'}
      length={4}
      nextStep={nextStep}
      setError={setError}
      errors={errors}
      clearErrors={clearErrors}
    />,
    <SignupPassword
      key={'SignupPassword'}
      setValue={setValue}
      length={6}
      nextStep={nextStep}
      errors={errors}
      watch={watch}
      clearErrors={clearErrors}
    />,
    <SignupRePassword
      key={'SignupRePassword'}
      watch={watch}
      setValue={setValue}
      length={6}
      nextStep={nextStep}
      setError={setError}
    />,
    <SignupCredentials
      key={'SignupCredentials'}
      register={register}
      watch={watch}
      nextStep={nextStep}
      setValue={setValue}
      setError={setError}
      clearErrors={clearErrors}
      errors={errors}
    />,
    <SignupPhone
      key={'SignupPhone'}
      watch={watch}
      nextStep={nextStep}
      setValue={setValue}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
    />,
    <SignupSmsCode
      key={'SignupSmsCode'}
      length={4}
      errors={errors}
      setError={setError}
      watch={watch}
      clearErrors={clearErrors}
    />
  ];

  useEffect(() => {
    dispatch(getClientCredentialsToken());
  }, []);

  return (
    <form
      className="w-full flex items-center justify-center max-w-[400px] md:flex-1"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="form_signup"
    >
      {STEPS[step]}
    </form>
  );
};

export default Signup;
