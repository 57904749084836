import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as IconArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as IconBalanceIn } from 'assets/icons/balance-in.svg';
import { ReactComponent as IconAddBalance } from 'assets/icons/add-balance.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter-2.svg';
import { ReactComponent as IconCloseSquare } from 'assets/icons/close-square-filled.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/invoice.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as TransactionList } from 'assets/illustrations/accounts/transaction-list.svg';
import { ReactComponent as IconAccounting } from 'assets/icons/accounting.svg';
import DraftIcon from 'assets/icons/draft.png';

//filter icons
import { ReactComponent as IconBankNoteColored } from 'assets/icons/bank-note-colored.svg';
import { ReactComponent as IconCoinsColored } from 'assets/icons/coins-colored.svg';
import { ReactComponent as IconPieColored } from 'assets/icons/pie-colored.svg';
import { ReactComponent as IconUsersColored } from 'assets/icons/users-colored.svg';
import { ReactComponent as IconInvoiceColored } from 'assets/icons/invoice-colored.svg';
import { ReactComponent as IconCalendarColored } from 'assets/icons/calendar-colored.svg';
import { ReactComponent as IconRouteColored } from 'assets/icons/route-colored.svg';
import { ReactComponent as IconGridColored } from 'assets/icons/grid-colored.svg';

import { exportSaleExpense, getSalesExpense } from 'store/actions/invoice';
import { getCategories } from 'store/actions/categories';
import { getInvoices } from 'store/actions/eTransformation';

import { Button, Card, CategoryBadge, CustomButton, ExportButton, Tooltip } from 'components';
import SalesRecordDetailModal from './components/salesRecordDetailModal';
import CreateSaleRecordModal from './components/createSaleRecordModal';
import AddQuickExpenseModal from './components/AddQuickExpenseModal';
import FiltersModal from './components/FiltersModal';
import UploadInvoicePopup from './components/UploadInvoicePopup';
import AcceptRejectInvoiceModal from './components/acceptRejectInvoiceModal';
import Chart from './components/summarySeriesChart';
import { Listbox } from '@headlessui/react';
import useDengage from 'utils/hooks/useDengage';

import { INVOICE_PROFILE_IDS, PAYMENT_METHOD_TITLES, RECORD_TYPES } from './enums';
import useModal from 'utils/hooks/useModal';

import {
  currencyFormat,
  downloadCsv,
  downloadFileByUrl,
  E_TRANSFORMATION_TYPES,
  getFormattedDateWithSeperator,
  PAYMENT_STATUS,
  PAYMENT_STATUS_TEXT
} from 'utils';
import { PAGE_NAMES, TRANSFORMATION_TYPES } from './components/salesRecordDetailModal/enums';
import AddPaymentInformationModal from './components/addPaymentInformationModal';
import { currencyAmountCalculator } from 'utils/contants';
import CreateExpenseModal from './components/createExpenseModal';
import useDebounce from 'utils/hooks/useDebounce';
import { useRouter } from 'utils/hooks/useRouter';
import { useRef } from 'react';
import toast from 'react-hot-toast';

const monthNames = [
  'Oca',
  'Şub',
  'Mar',
  'Nis',
  'May',
  'Haz',
  'Tem',
  'Ağu',
  'Eyl',
  'Eki',
  'Kas',
  'Ara'
];

const chartIntervalTypes = {
  WEEK: 'Bu Hafta',
  MONTH: 'Bu Ay',
  YEAR: 'Bu Yıl'
};

const recordTypesNames = {
  SALES: 'Satışlar',
  EXPENSE: 'Giderler'
};

const Invoicing = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, etransformationType } = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state.categories);
  const { responseNeededInvoices: invoices } = useSelector((state) => state.eTransformation);
  const { expensesTotalAmount, salesTotalAmount, salesExpenses } = useSelector(
    (state) => state.invoice
  );

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({ recordType: null, categoryId: null });
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [isBottomModalActive, setIsBottomModalActive] = useModal({ shouldBeBlockScroll: true });
  const [selectedTransactionId, setSelectedTransactionId] = useState();
  const [selectedSale, setSelectedSale] = useState(null);

  const [isCreateSaleModalActive, , toggleCreateSaveModal] = useModal({
    shouldBeBlockScroll: true
  });
  const [isAddQuickExpenseModalActive, , toggleAddQuickExpenseModal] = useModal({
    handleClose: fetchSalesExpense
  });
  const { publishEvent, eventTypes } = useDengage();
  const [isFiltersModalActive, , toggleFiltersModal] = useModal({ shouldBeBlockScroll: true });
  const [isUploadInvoicePopupActive, , toggleUploadInvoicePopup] = useModal();
  const [isAcceptRejectInvoicePopupActive, , toggleAcceptRejectInvoicePopup] = useModal({
    shouldBeBlockScroll: true
  });
  const [isCreateExpenseModalActive, , toggleCreateExpenseModal] = useModal({
    handleClose: () => setSelectedInvoice(null),
    shouldBeBlockScroll: true
  });

  const { query, location } = useRouter();

  const searchInputRef = useRef(null);

  useDebounce(search, 500, searchInputRef, () => {
    fetchSalesExpense();
  });

  useEffect(() => {
    const remoteFilters = location?.state?.filters;
    if (remoteFilters) {
      setFilters({ ...remoteFilters });
    }
  }, []);

  useEffect(() => {
    if (query?.contactId) toggleCreateSaveModal();
  }, [query?.contactId]);

  useEffect(() => {
    dispatch(getCategories());
    publishEvent(eventTypes.pageView, {
      page_type: 'pre_acc'
    });
  }, []);

  useEffect(() => {
    fetchSalesExpense();
  }, [user, filters, page, size, isBottomModalActive]);

  useEffect(() => {
    if (isAcceptRejectInvoicePopupActive) return;
    let params = {
      direction: 'INCOMING',
      'app-response-needed': true,
      'profile-id': INVOICE_PROFILE_IDS.TICARIFATURA,
      archived: false
    };

    if (etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT) {
      params = {
        direction: 'INCOMING',
        archived: false
      };
    }
    dispatch(getInvoices(params));
  }, [isAcceptRejectInvoicePopupActive]);

  function routeToExpenseRecords() {
    history.push('/invoicing/create-expense-record');
  }

  function handleItemDetail(i) {
    if (!isNaN(i) && salesExpenses.content[i]?.recordType === RECORD_TYPES.EXPENSE) {
      history.push('/invoicing/expense-detail/' + salesExpenses.content[i].id);
    } else {
      setIsBottomModalActive(!isBottomModalActive);
      if (!!isBottomModalActive) {
        setSelectedTransactionId(null);
        return;
      }
      setSelectedTransactionId(salesExpenses.content[i].id);
    }
  }

  function handleExportCSV(fileType) {
    if (!user?.companyId) return;
    const remoteFilters = location.state?.filters;
    let payload = null;

    if (remoteFilters) {
      payload = { ...payload, ...remoteFilters };
    } else {
      payload = { ...payload, ...filters };
    }

    exportSaleExpense({
      companyId: user.companyId,
      payload,
      fileType
    })
      .then((res) => {
        downloadFileByUrl(res.data?.data?.exportUrl);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Dosya indirilirken bir hata oluştu.');
      });
  }

  function fetchSalesExpense() {
    const remoteFilters = location.state?.filters;
    if (remoteFilters) {
      dispatch(getSalesExpense(user.companyId, { ...remoteFilters, page, size, search }));
      delete location.state.filters;
    } else {
      dispatch(getSalesExpense(user.companyId, { ...filters, page, size, search }));
    }
  }

  function openDetailModal(recordId) {
    toggleCreateSaveModal();
    setSelectedTransactionId(recordId);
    setIsBottomModalActive(true);
  }

  function handleCreateWithInvoice(invoice) {
    setSelectedInvoice(invoice);
    toggleCreateExpenseModal();
  }

  return (
    <div
      className="relative bg-gray-200 flex justify-center py-12 w-full h-auto min-h-screen"
      data-testid="page_invoicing"
    >
      {isCreateExpenseModalActive && (
        <CreateExpenseModal
          handleClose={toggleCreateExpenseModal}
          defaultInvoice={selectedInvoice}
        />
      )}
      {isBottomModalActive && (
        <SalesRecordDetailModal
          handleClose={handleItemDetail}
          salesRecordId={selectedTransactionId}
        />
      )}
      {isCreateSaleModalActive && (
        <CreateSaleRecordModal
          handleClose={toggleCreateSaveModal}
          openDetailModal={openDetailModal}
        />
      )}
      {isAddQuickExpenseModalActive && (
        <AddQuickExpenseModal handleClose={toggleAddQuickExpenseModal} />
      )}
      {isFiltersModalActive && (
        <FiltersModal
          handleClose={toggleFiltersModal}
          handleFilters={setFilters}
          filters={filters}
          categories={categories}
          onFetch={fetchSalesExpense}
        />
      )}
      {isAcceptRejectInvoicePopupActive && (
        <AcceptRejectInvoiceModal
          handleClose={toggleAcceptRejectInvoicePopup}
          defaultInvoice={selectedInvoice}
          handleCreateWithInvoice={handleCreateWithInvoice}
        />
      )}
      <UploadInvoicePopup
        active={isUploadInvoicePopupActive}
        sale={selectedSale}
        toggleUploadInvoicePopup={toggleUploadInvoicePopup}
      />
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative w-11/12 xl:w-3/4">
        <div className="w-full flex flex-col items-start">
          <div className="flex items-center  w-full relative gap-x-2">
            <IconAccounting className="fill-current w-8 ml-4 h-auto text-green-500" />
            <span className="font-semibold text-[22px] text-white">Ön Muhasebe</span>
          </div>
          <div className="flex mt-6 gap-x-4 w-full">
            <Card className="w-1/3 cursor-pointer" onClick={routeToExpenseRecords}>
              <IconAddBalance className="fill-current w-8 h-auto text-green-500" />
              <div
                className="flex space-x-2 items-center mt-6 cursor-pointer"
                data-testid="add_expense_record"
              >
                <span className="font-semibold">Gider Kaydı Oluştur</span>
                <IconArrowRight className="stroke-current w-4 text-black" />
              </div>
            </Card>
            <Card className="w-1/3 cursor-pointer" onClick={toggleCreateSaveModal}>
              <IconBalanceIn className="fill-current w-8 h-auto text-green-500" />
              <div
                className="flex space-x-2 items-center mt-6 cursor-pointer"
                data-testid="add_sale_record"
              >
                <span className="font-semibold">Satış Kaydı Oluştur</span>
                <IconArrowRight className="stroke-current w-4 text-black" />
              </div>
            </Card>
            <WaitingInvoicesCard
              toggleAcceptRejectInvoicePopup={toggleAcceptRejectInvoicePopup}
              length={invoices.totalElements}
            />
          </div>
        </div>
        <Card className="mt-8">
          <div className="flex justify-between">
            <span className="font-semibold text-xl">Satış ve Gider Kayıtları</span>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="p-4 flex flex-col space-y-1">
                <span className="flex space-x-1 text-sm text-green-600 font-semibold">
                  <IconArrowUp className="stroke-current" />
                  <span>Satışlar</span>
                </span>
                <span className="font-semibold text-2xl">
                  + {currencyFormat(salesTotalAmount || 0)} TL
                </span>
              </div>
              <div className="w-1 h-12 border-l border-solid border-gray-200" />
              <div className="p-4">
                <span className="flex space-x-1 text-sm text-purple-600 font-semibold">
                  <IconArrowDown className="stroke-current" />
                  <span>Harcamalar</span>
                </span>
                <span className="font-semibold text-2xl">
                  - {currencyFormat(expensesTotalAmount || 0)} TL
                </span>
              </div>
            </div>
            <IntervalTypeSelect />
          </div>
          <div className="border-t border-solid border-gray-200 pt-14">
            <Chart />
          </div>
        </Card>
        <Card className="mt-8">
          <div className="flex items-center flex-wrap gap-y-2 gap-x-6 mb-4">
            <span className="font-semibold text-xl">Son Kayıtlar</span>
            <div className="bg-gray-200 rounded-lg p-3 flex items-center text-gray-400 focus-within:bg-white focus-within:shadow-md focus-within:text-black transition ml-auto">
              <IconSearch className="fill-current mr-3 w-5" />
              <input
                placeholder="Arama"
                className="bg-transparent focus:outline-none"
                onChange={(e) => setSearch(e.target.value)}
                data-testid="input_invoicing_search_input"
                ref={searchInputRef}
              />
            </div>
            {/* <CustomButton
              color="primary"
              className="flex items-center gap-x-2"
              onClick={toggleFiltersModal}
              data-testid="button_invoicing_filter"
            >
              <IconFilter className="fill-current w-4" />
              <span>Filtrele</span>
            </CustomButton> */}
            <Button
              variant="secondary"
              leftIcon={<IconFilter className="fill-current w-4" />}
              onClick={toggleFiltersModal}
            >
              Filtrele
            </Button>
            {/* <CustomButton
              color="success"
              className="ml-4"
              // disabled={!salesExpenses.content.length}
              onClick={handleExportCSV}
            >
              Dışa Aktar
            </CustomButton> */}

            <ExportButton onChange={handleExportCSV} data-testid="button_invoicing_export">
              Dışa Aktar
            </ExportButton>
          </div>
          {filters && <FilterTags filters={{ ...filters }} setFilters={setFilters} />}
          <div className="flex flex-col items-center gap-y-8 mt-4">
            {!!salesExpenses.content.length ? (
              <>
                {salesExpenses.content.map((sale, index) => (
                  <ListItem
                    key={index}
                    sale={sale}
                    onClick={() => handleItemDetail(index)}
                    fetchSalesExpense={fetchSalesExpense}
                  />
                ))}
                {salesExpenses.totalElements > salesExpenses.numberOfElements && (
                  <CustomButton
                    color="primary"
                    className="mx-auto"
                    onClick={() => setSize(size + 15)}
                    data-testid="button_invoicing_load_more"
                  >
                    Daha Fazla Göster
                  </CustomButton>
                )}
              </>
            ) : (
              <div
                className="flex flex-col gap-y-8 items-center justify-center mt-20"
                data-testid="section_invoicing_no_data"
              >
                <TransactionList />
                <div className="text-gray-500 flex items-center justify-center gap-x-2 px-4 py-2 rounded-2xl bg-gray-100 border border-solid border-gray-200">
                  <InfoCircleIcon className="fill-current w-3" />
                  <span className="text-sm">Henüz işlem yapılmamış</span>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const FilterTags = ({ filters, setFilters, ...props }) => {
  const { categories } = useSelector((state) => state.categories);
  const { contacts } = useSelector((state) => state.contacts);

  const FilterTag = ({ children, filterKeys = [], label = '', icon, ...filterTagProps }) => {
    const removeFilter = () => {
      Object.entries(filterKeys).forEach(([_, key]) => {
        if (['startDueDate', 'endDueDate'].includes(key)) {
          return setFilters((currentFilters) => ({
            ...currentFilters,
            ['startDueDate']: null,
            ['endDueDate']: null
          }));
        }
        if (['startInvoiceDate', 'endInvoiceDate'].includes(key)) {
          return setFilters((currentFilters) => ({
            ...currentFilters,
            ['startInvoiceDate']: null,
            ['endInvoiceDate']: null
          }));
        }
        if (['startPaymentDate', 'endPaymentDate'].includes(key)) {
          return setFilters((currentFilters) => ({
            ...currentFilters,
            ['startPaymentDate']: null,
            ['endPaymentDate']: null
          }));
        }
        setFilters((currentFilters) => ({ ...currentFilters, [key]: null }));
      });
    };
    return (
      <span
        className="px-3 py-2 rounded-full bg-gray-100 flex items-center gap-x-2"
        data-testid="section_invoicing_filter_category"
        {...filterTagProps}
      >
        {icon}
        <div className="text-sm">
          {label && <span className="text-gray-500 font-medium">{label}</span>} {children}
        </div>
        <IconCloseSquare
          className="fill-current w-4 text-gray-400 cursor-pointer"
          onClick={removeFilter}
        />
      </span>
    );
  };
  return (
    <div className="flex flex-wrap gap-2" {...props}>
      {Object.values(filters)?.map((filter, index) => {
        switch (filter) {
          case filters?.recordType:
            return (
              filter && (
                <FilterTag key={index} filterKeys={['recordType']}>
                  {recordTypesNames[filter]}
                </FilterTag>
              )
            );
          case filters?.selectedCategory:
            return (
              filter && (
                <FilterTag
                  key={index}
                  filterKeys={['selectedCategory']}
                  icon={<IconGridColored />}
                >
                  {categories.find((c) => c.id === filters.selectedCategory?.value).name}
                </FilterTag>
              )
            );
          case filters?.startDueDate || filters?.endDueDate:
            return (
              (filters?.startDueDate || filters?.endDueDate) && (
                <FilterTag
                  label="Vade Tarihi:"
                  key={index}
                  filterKeys={['startDueDate', 'endDueDate']}
                  icon={<IconRouteColored />}
                >
                  {`${getFormattedDateWithSeperator(
                    filters?.startDueDate
                  )} - ${getFormattedDateWithSeperator(filters?.endDueDate)}`}
                </FilterTag>
              )
            );
          case filters?.startInvoiceDate || filters?.endInvoiceDate:
            return (
              (filters?.startInvoiceDate || filters?.endInvoiceDate) && (
                <FilterTag
                  label="Fatura Tarihi:"
                  filterKeys={['startInvoiceDate', 'endInvoiceDate']}
                  icon={<IconInvoiceColored />}
                >
                  {`${getFormattedDateWithSeperator(
                    filters?.startInvoiceDate
                  )} - ${getFormattedDateWithSeperator(filters?.endInvoiceDate)}`}
                </FilterTag>
              )
            );
          case filters?.startPaymentDate || filters?.endPaymentDate:
            return (
              (filters?.startPaymentDate || filters?.endPaymentDate) && (
                <FilterTag
                  label="Ödeme Tarihi:"
                  filterKeys={['startPaymentDate', 'endPaymentDate']}
                  icon={<IconCalendarColored />}
                >
                  {`${getFormattedDateWithSeperator(
                    filters?.startPaymentDate
                  )} - ${getFormattedDateWithSeperator(filters?.endPaymentDate)}`}
                </FilterTag>
              )
            );
          case filters?.hasInvoice:
            return (
              <FilterTag filterKeys={['hasInvoice']} icon={<IconPieColored />}>
                {filter ? 'Fatura Var' : 'Fatura Yok'}
              </FilterTag>
            );
          case filters?.paymentMethod:
            return (
              filters?.paymentMethod && (
                <FilterTag filterKeys={['paymentMethod']} icon={<IconCoinsColored />}>
                  {PAYMENT_METHOD_TITLES[filter?.value]}
                </FilterTag>
              )
            );
          case filters?.paymentStatus:
            return (
              filters?.paymentStatus && (
                <FilterTag filterKeys={['paymentStatus']} icon={<IconBankNoteColored />}>
                  {PAYMENT_STATUS_TEXT[filter]}
                </FilterTag>
              )
            );
          case filters?.selectedContact:
            const contact = contacts?.find((contact) => contact?.id === filter?.value);
            if (contact) {
              return (
                filters?.selectedContact && (
                  <FilterTag
                    filterKeys={['selectedContact']}
                    icon={<IconUsersColored />}
                  >{`${contact?.firstName} ${contact?.lastName}`}</FilterTag>
                )
              );
            }
        }
      })}
    </div>
  );
};

const ListItem = ({ sale, onClick, fetchSalesExpense }) => {
  const { etransformationType } = useSelector((state) => state.auth);

  const [uploadInvoicePopup, , toggleUploadInvoicePopup] = useModal({
    shouldBeBlockScroll: true
  });
  const [detailModalActive, , toggleDetailModal] = useModal({ shouldBeBlockScroll: true });
  const [addPaymentInformationModalActive, , toggleAddPaymentInformationModal] = useModal({
    handleClose: fetchSalesExpense
  });

  const subtitle =
    sale.contact?.firstName && sale.contact?.lastName
      ? `${sale.contact.firstName} ${sale.contact.lastName}`
      : '-';

  return (
    <>
      <UploadInvoicePopup
        active={uploadInvoicePopup}
        toggleUploadInvoicePopup={toggleUploadInvoicePopup}
        sale={sale}
      />
      {!!detailModalActive && (
        <SalesRecordDetailModal
          defaultPage={PAGE_NAMES.EDOCUMENT}
          handleClose={toggleDetailModal}
          salesRecordId={sale?.id}
        />
      )}
      {addPaymentInformationModalActive && (
        <AddPaymentInformationModal
          handleClose={toggleAddPaymentInformationModal}
          record={sale}
        />
      )}
      <div
        className="w-full h-[88px] py-3 -my-px flex justify-between border border-gray-200 bg-gray-50 hover:bg-white rounded-xl border-solid cursor-pointer group overflow-x-auto"
        onClick={onClick}
        data-testid="section_invoicing_sale_record"
      >
        <div className="flex">
          {sale?.createDate ? (
            <div className="flex flex-col items-center justify-center ml-4 mr-6">
              <span className="font-semibold text-[22px] leading-7">
                {new Date(sale?.createDate).getDate()}
              </span>
              <span
                className="font-semibold text-xs text-gray-500"
                data-testid="section_invoicing_sale_record_date"
              >
                {monthNames[new Date(sale?.createDate).getMonth()]}{' '}
                {new Date(sale?.createDate).getFullYear()}
              </span>
              <span
                className="font-semibold text-xs text-gray-400"
                data-testid="section_invoicing_sale_record_date"
              >
                {new Date(sale?.createDate).getHours().toString().padStart(2, '0')}
                {':'}
                {new Date(sale?.createDate).getMinutes().toString().padStart(2, '0')}
              </span>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center mx-10">
              <span className="font-normal text-xl text-center">-</span>
            </div>
          )}
          <div className="flex flex-col gap-[5px] w-72 px-6 justify-center relative before:content-[''] before:absolute before:left-0 before:h-14 before:border-l before:border-solid before:border-gray-200">
            <span className="font-semibold truncate" data-testid="section_invoicing_sale_title">
              {sale?.title}
            </span>
            {sale?.status === 'COMPLETED' ? (
              sale?.category && (
                <CategoryBadge
                  {...sale?.category}
                  className="py-[5px] px-3 text-xs w-max font-medium"
                />
              )
            ) : (
              <CategoryBadge
                iconUrl={DraftIcon}
                name="Taslak"
                colorHexCode="#FFFFFF"
                textColorHexCode="#6B7280"
                className="py-[5px] px-3 text-xs w-max font-medium border border-solid border-gray-200"
              />
            )}
          </div>
        </div>

        <div className="flex items-center justify-between flex-grow">
          <div className="w-28 flex items-center justify-start ml-[3px]">
            <span
              className={`px-3 py-[5px] -m-px border border-solid rounded-full text-xs font-medium ${
                sale?.paymentStatus === PAYMENT_STATUS.PAID
                  ? 'border-green-100 bg-[#E8FCF2] text-green-500 '
                  : ' border-yellow-100 bg-[#FDF6DD] text-yellow-600 '
              }`}
            >
              {sale?.paymentStatus === PAYMENT_STATUS.PAID ? 'Ödendi' : 'Ödenecek'}
            </span>
          </div>
          <span
            className={`${
              sale?.recordType === RECORD_TYPES.SALES
                ? 'group-hover:text-green-500'
                : 'group-hover:text-red-600'
            } font-semibold text-gray-900 w-36 mr-12 text-right `}
            data-testid="section_invoicing_sale_record_amount"
          >
            {`${sale?.recordType === RECORD_TYPES.SALES ? '+ ' : '- '}
            ${currencyFormat(
              currencyAmountCalculator(sale?.totalAmount, {
                currencyRate: sale?.currency === 'TRY' ? 1 : sale?.currencyRate,
                currency: sale?.currency,
                revertCurrencyRate: false
              })
            )} TL`}
          </span>
        </div>
      </div>
    </>
  );
};

const IntervalTypeSelect = () => {
  const dispatch = useDispatch();
  const { intervalType } = useSelector((state) => state.invoice);

  function setIntervalType(interval) {
    dispatch({
      type: 'SET_INTERVAL_TYPE',
      data: interval
    });
  }

  return (
    <Listbox as="div" className="relative" value={intervalType} onChange={setIntervalType}>
      <Listbox.Button className="rounded-lg shadow-md bg-white py-2 px-4 gap-x-2 cursor-pointer flex items-center">
        {chartIntervalTypes[intervalType]}
        <IconCaretDown className="stroke-current w-5" />
      </Listbox.Button>
      <Listbox.Options
        as="div"
        className="absolute top-0 left-0 mt-12 bg-white rounded-lg py-2 px-4 shadow-md w-full cursor-pointer"
      >
        {Object.keys(chartIntervalTypes).map((intervalType, index) => (
          <Listbox.Option key={index} as="div" value={intervalType}>
            {chartIntervalTypes[intervalType]}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

const WaitingInvoicesCard = ({ toggleAcceptRejectInvoicePopup, length }) => (
  <Card
    className="w-1/3 relative cursor-pointer"
    onClick={toggleAcceptRejectInvoicePopup}
    data-testid="waiting_invoices"
  >
    {!!length && (
      <span
        className="absolute -top-4 -right-4 inline-flex items-center justify-center px-3 py-2 text-lg font-semibold leading-none text-red-100 bg-red-500 rounded-full"
        data-testid="waiting_invoices_count"
      >
        {length}
      </span>
    )}
    <InvoiceIcon className="fill-current w-8 h-auto text-green-500" />
    <div
      className="flex space-x-2 items-center mt-6 cursor-pointer"
      data-testid="button_waiting_invoices_card"
    >
      <span className="font-semibold">Bekleyen Faturalar</span>
      <IconArrowRight className="stroke-current w-4 text-black" />
    </div>
  </Card>
);

export default Invoicing;
