import { Checkbox, CustomButton, CustomInput, BottomModal, DatePicker } from 'components';

import { ReactComponent as FileUploadIcon } from 'assets/icons/file-upload.svg';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { getCategories } from 'store/actions/categories';

import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';

import DoubleCheck from 'assets/illustrations/card/double-check.png';

import { getRates } from 'store/actions/exchange';
import { uploadFile } from 'store/actions/fileUpload';
import { createSaleExpenseExpress } from 'store/actions/invoice';

import FILE_TYPES from 'utils/fileTypes';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { currencyFormat } from 'utils';
import toast from 'react-hot-toast';

const CURRENCIES = {
  TRY: {
    show: 'TL',
    symbol: '₺'
  },
  EUR: {
    show: 'Euro',
    symbol: '€'
  },
  USD: {
    show: 'USD',
    symbol: '$'
  }
};

const VAT_RATES = {
  0: '0%',
  1: '1%',
  8: '8%',
  18: '18%'
};

const QuickExpenseDescription = ({ setValue, watch, errors, clearErrors }) => (
  <div
    className="py-12 border-b border-solid border-gray-200"
    data-testid="section_quick_expense_modal"
  >
    <span className="text-lg font-semibold text-gray-900">Açıklama</span>
    <CustomInput
      label="Kayıt İsmi"
      className="mt-5"
      onChange={(e) => {
        setValue('title', e.target.value);
        clearErrors('title');
      }}
      maxLength={140}
      count={watch('title') ? watch('title').length : 0}
      error={errors?.title?.message}
      data-testid="input_quick_expense_record_name"
    />
  </div>
);

const QuickExpenseInvoice = ({ file, setFile }) => {
  const fileInput = useRef();

  function handleFile(e) {
    const _file = e.target.files[0];
    if (!_file) return;

    if (_file.size > 15 * 1000 * 1000) return toast.error('Maksimum boyut 15mb');

    setFile(_file);
  }

  function handleResetInput() {
    setFile(null);
    fileInput.current.value = '';
  }

  return (
    <div
      className="py-12 border-b border-solid border-gray-200"
      data-testid="section_quick_expense_modal"
    >
      <span className="text-lg font-semibold text-gray-900">Fiş / Pos</span>
      <div className="mt-4">
        {file ? (
          <div
            className="mb-4 w-full border-2 border-dashed border-gray-300 rounded-lg py-4 px-5 flex items-center gap-x-6 bg-white"
            data-testid="section_quick_expense_modal_file_uploaded"
          >
            <img src={URL.createObjectURL(file)} alt="" className="h-32 rounded-lg" />
            <div className="flex flex-col gap-y-2 justify-center items-start">
              <span
                className="text-lg font-semibold text-gray-600"
                data-testid="label_quick_expense_modal_file_name"
              >
                {file.name}
              </span>
              <CustomButton
                color="danger"
                onClick={handleResetInput}
                data-testid="button_quick_expense_modal_file_delete"
              >
                Sil
              </CustomButton>
            </div>
          </div>
        ) : (
          <div
            className="mb-4 w-full border-2 border-dashed border-gray-300 rounded-lg py-8 flex flex-col items-center justify-center bg-gray-100 cursor-pointer"
            onClick={() => fileInput.current.click()}
            data-testid="section_quick_expense_modal_file_upload"
          >
            <FileUploadIcon className="fill-current w-8 text-gray-400" />
            <span className="text-lg font-semibold text-gray-500 mt-3">
              Fiş / Pos Slibi eklemek için tıklayın
            </span>
            <span className="text-sm font-semibold text-gray-400">
              veya sürükleyip buraya bırakın
            </span>
          </div>
        )}
        <div>
          <span className="text-sm font-medium text-gray-500 flex items-center gap-x-2">
            <InfoCircleIcon className="fill-current w-5" />
            <span>
              Dosya tipi olarak .jpg, .jpeg, .png ve .pdf desteklenir. Maksimum boyut 15 mb
            </span>
          </span>
        </div>
      </div>
      <input
        ref={fileInput}
        type="file"
        accept=".pdf,.jpg,.png"
        className="hidden"
        onChange={handleFile}
        data-testid="input_quick_expense_modal_file"
      />
    </div>
  );
};

const QuickExpenseCategory = ({ setValue, watch }) => {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.categories);
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const SelectedBadge = () => (
    <div className="p-1 rounded-full bg-green-500 absolute -right-3 -top-3 border-2 border-solid border-white flex items-center justify-center">
      <IconCheck className="stroke-current w-4 text-white" />
    </div>
  );

  return (
    <div
      className="py-12 border-b border-solid border-gray-200"
      data-testid="section_quick_expense_category_modal"
    >
      <div className="flex justify-between">
        <span className="text-lg font-semibold text-gray-900">Kategori</span>
        <span className="text-sm font-medium text-green-500">Yeni Ekle</span>
      </div>
      <div className="flex flex-wrap gap-4 mt-5">
        {categories.map((c, i) => (
          <div
            key={i}
            className="px-3 py-1 rounded-full text-black flex items-center gap-x-2 cursor-pointer relative"
            style={{ backgroundColor: c.colorHexCode }}
            onClick={() => setValue('categoryId', c.id)}
            data-testid="button_quick_expense_category_set_category"
          >
            {c.iconUrl && <img src={c.iconUrl} alt="icon" className="w-4" />}
            <span>{c.name}</span>
            {watch('categoryId') === c.id && <SelectedBadge />}
          </div>
        ))}
      </div>
    </div>
  );
};

const QuickExpenseCurrency = ({ setValue, watch, errors, clearErrors }) => {
  const dispatch = useDispatch();

  const rateInput = useRef();

  const [autoCurrencyRate, setAutoCurrencyRate] = useState(true);

  const { TRY } = useSelector((state) => state.exchange);
  const currencyRate = TRY.find((e) => e.name === watch('currency'));

  useEffect(() => {
    dispatch(getRates({ currency: 'TRY' }));
  }, [dispatch]);

  function handleAutoCurrency(e) {
    setAutoCurrencyRate(e.target.checked);
    if (e.target.checked) {
      handleCurrencyRate();
    }
  }

  function handleCurrency(currency) {
    clearErrors('currency');
    clearErrors('currencyRate');
    setValue('currency', currency);
  }

  function handleCurrencyRate() {
    if (currencyRate) {
      setValue('currencyRate', currencyRate.averageRate);
      rateInput.current.value = currencyRate.averageRate;
    } else {
      setValue('currencyRate', null);
    }
  }

  useEffect(() => {
    handleCurrencyRate();
  }, [currencyRate, setValue]);

  return (
    <div className="py-12 border-b border-solid border-gray-200">
      <span
        className={`${
          errors?.currency || errors?.currencyRate ? 'text-red-500' : 'text-gray-700'
        } text-lg font-semibold`}
        data-testid="label_expense_currency"
      >
        Para Birimi
      </span>
      <div className="flex gap-x-2 w-full mt-4">
        {Object.keys(CURRENCIES).map((currency, i) => (
          <span
            key={i}
            className={`${
              watch('currency') === currency
                ? 'bg-green-500 text-green-50'
                : 'bg-gray-200 text-gray-500'
            } w-1/3 flex items-center justify-center py-2 rounded-3xl cursor-pointer`}
            onClick={() => handleCurrency(currency)}
            data-testid="button_expense_currency_set_currency"
          >
            {`${CURRENCIES[currency].symbol} ${CURRENCIES[currency].show}`}
          </span>
        ))}
      </div>
      {currencyRate && (
        <div>
          <Checkbox
            className="mt-6"
            onChange={handleAutoCurrency}
            defaultChecked={autoCurrencyRate}
            data-testid="checkbox_expense_currency_auto_currency_rate"
          >
            <span className="text-sm text-gray-700">
              1 {CURRENCIES[currencyRate.name].symbol} fiyatı Merkez Bankası Efektif satış
              tarafından hesaplanmıştır.
              <span className="text-green-500">
                1 {CURRENCIES[currencyRate.name].symbol} = {currencyRate.averageRate} ₺
              </span>
            </span>
          </Checkbox>
          <CustomInput
            refProp={rateInput}
            className="mt-6"
            label="Kur"
            disabled={autoCurrencyRate}
            onChange={(e) => setValue('currencyRate', Number(e.target.value))}
            only="number"
            data-testid="input_expense_currency_currency_rate"
          />
        </div>
      )}
    </div>
  );
};

const QuickExpenseVat = ({ setValue, watch, errors, clearErrors }) => {
  const [isForeign, setIsForeign] = useState(false);
  useEffect(() => {
    if (watch('currency') !== 'TRY') {
      setIsForeign(true);
      setValue('vatRate', 0);
    } else {
      setIsForeign(false);
    }
  }, [watch('currency')]);

  return (
    <div
      className="py-12 border-b border-solid border-gray-200"
      data-testid="section_quick_expense_vat_modal"
    >
      <span
        className={`${errors?.vatRate ? 'text-red-500' : 'text-gray-900'} text-lg font-semibold`}
      >
        KDV Oranı
      </span>
      <div className="mt-4 flex gap-2">
        {Object.keys(VAT_RATES).map((vatRate, i) => (
          <span
            key={i}
            className={`${
              watch('vatRate') === Number(vatRate)
                ? 'bg-green-500 text-green-50'
                : 'bg-gray-200 text-gray-500'
            } w-1/4 flex items-center justify-center py-2 rounded-3xl cursor-pointer`}
            onClick={() => {
              if (isForeign) return;
              setValue('vatRate', Number(vatRate));
              clearErrors('vatRate');
            }}
            data-testid="button_expense_vat_set_vat_rate"
          >
            {VAT_RATES[vatRate]}
          </span>
        ))}
      </div>
    </div>
  );
};

const QuickExpensePayment = ({ setValue, watch, errors, clearErrors }) => {
  return (
    <div
      className="py-12 border-b border-solid border-gray-200"
      data-testid="section_quick_expense_payment_modal"
    >
      <span className="text-lg font-semibold text-gray-900">Ödeme</span>
      <div className="mt-4 flex gap-2">
        <div className="flex flex-col w-1/2">
          <CustomInput
            className="w-full"
            label="Toplam Tutar"
            onChange={(e) => {
              setValue('totalPaidAmount', Number(e.target.value));
              clearErrors('totalPaidAmount');
            }}
            only="number"
            error={errors?.totalPaidAmount?.message}
            data-testid="input_expense_payment_total_paid_amount"
          />
          {['EUR', 'USD'].includes(watch('currency')) && (
            <span className="text-xs">
              <span className="font-medium text-gray-500">Toplam TL Tutar:</span>
              <span
                className="font-semibold text-gray-900"
                data-testid="label_expense_payment_total_paid_amount_in_try"
              >
                {' '}
                {currencyFormat(
                  (watch('currencyRate') || 0) * (watch('totalPaidAmount') || 0)
                )}{' '}
                TL
              </span>
            </span>
          )}
        </div>
        <div className="flex flex-col w-1/2">
          <span className="text-sm font-medium text-gray-500">Ödeme Tarihi</span>
          <DatePicker
            selected={watch('paymentDate')}
            onChange={(date) => {
              setValue('paymentDate', date);
              clearErrors('paymentDate');
            }}
            className={`${
              errors?.paymentDate && 'border border-solid border-red-500'
            } bg-gray-100 rounded-lg p-2 w-full`}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            data-testid="input_expense_payment_payment_date"
          />
          {errors?.paymentDate && (
            <span
              className="text-xs text-red-500 flex items-center mt-2"
              data-testid="section_expense_payment_payment_date_error"
            >
              {errors?.paymentDate?.message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const QuickExpenseNote = ({ setValue, watch }) => {
  const maxLength = 140;
  return (
    <div
      className="py-12 border-b border-solid border-gray-200 flex flex-col"
      data-testid="section_quick_expense_note_modal"
    >
      <span className="text-sm font-medium text-gray-500">Not Ekle</span>
      <div className="w-full flex flex-col">
        <textarea
          className="appearance-none bg-gray-100 rounded-lg h-20 resize-none focus:outline-none mt-1 p-2"
          maxLength={maxLength}
          onChange={(e) => setValue('note', e.target.value)}
          data-testid="input_expense_note_note"
        />
        <span className="mt-2 ml-auto text-xs font-medium text-gray-400">{`${
          watch('note') ? watch('note').length : 0
        } / ${maxLength}`}</span>
      </div>
    </div>
  );
};

const SuccessPage = ({ handleClose }) => (
  <div
    className="w-full h-full bg-green-500 flex items-center justify-center"
    data-testid="section_quick_expense_success_modal"
  >
    <div className="w-[380px] flex flex-col flex-wrap items-center">
      <img src={DoubleCheck} alt="check" className="w-24 mb-8" />
      <span className="text-3xl font-medium text-white text-center">
        Hızlı Masraf oluşturma işleminiz başarıyla tamamlanmıştır.
      </span>
      <CustomButton color="primary" className="mt-6 w-full">
        Hızlı Masrafı Gör
      </CustomButton>
      <CustomButton color="success" onClick={handleClose} className="mt-4 w-full">
        Kapat
      </CustomButton>
    </div>
  </div>
);

const AddQuickExpenseModal = ({ handleClose }) => {
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState('');

  const schema = z.object({
    categoryId: z.optional(z.number()),
    currency: z.string(),
    currencyRate:
      currency === 'TRY' ? z.optional(z.any()) : z.number().min(1, { message: 'Required' }),
    note: z.optional(
      z.string({ required_error: 'Required' }).min(1, { message: 'Required' }).default('')
    ),
    paymentDate: z.date(),
    title: z.string().min(1, { message: 'Required' }).default(''),
    totalPaidAmount: z.number().min(1, { message: 'Required' }),
    vatRate: z.number()
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm({ resolver: zodResolver(schema) });

  useEffect(() => {
    setCurrency(watch('currency'));
  }, watch('currency'));

  const [file, setFile] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  async function onSubmit(data) {
    let fileResponse;
    if (file) {
      try {
        fileResponse = await uploadFile({ file, fileType: FILE_TYPES.EXPENSE_INVOICE_VOUCHER });
      } catch (err) {
        console.log(err);
      }
    }

    const fileIds = file ? [{ fileId: fileResponse.data?.data.id }] : [];

    dispatch(createSaleExpenseExpress(data, fileIds))
      .then(() => {
        setIsSuccess(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <BottomModal handleClose={handleClose}>
      {isSuccess ? (
        <SuccessPage handleClose={handleClose} />
      ) : (
        <form className="w-full h-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-center h-full">
            <div className="w-[640px] h-full flex flex-col overflow-y-scroll py-10">
              <div className="flex flex-col py-12 border-b border-solid border-gray-200">
                <span className="text-sm font-semibold text-gray-400">Gider Kaydı Oluştur</span>
                <span className="text-xl font-semibold text-gray-900">Hızlı Masraf</span>
              </div>
              <QuickExpenseDescription
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
              />
              <QuickExpenseInvoice file={file} setFile={setFile} errors={errors} />
              <QuickExpenseCategory setValue={setValue} watch={watch} errors={errors} />
              <QuickExpenseCurrency
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
              />
              <QuickExpenseVat
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
              />
              <QuickExpensePayment
                setValue={setValue}
                watch={watch}
                errors={errors}
                clearErrors={clearErrors}
              />
              <QuickExpenseNote setValue={setValue} watch={watch} errors={errors} />
              <div className="py-12 flex gap-x-6">
                <CustomButton
                  color="danger"
                  className="w-1/2"
                  onClick={handleClose}
                  data-testid="button_cancel_quick_expense"
                >
                  İptal Et
                </CustomButton>
                <CustomButton
                  color="success"
                  className="w-1/2"
                  type="submit"
                  data-testid="button_submit_quick_expense"
                >
                  Kaydet
                </CustomButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </BottomModal>
  );
};

// TODO:RM this component
export default AddQuickExpenseModal;
