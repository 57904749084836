import { useState, useEffect } from 'react';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';

import DoubleCheck from 'assets/illustrations/card/double-check.png';

import { BottomModal, CustomButton, CustomInput, Card } from 'components';
import { useDispatch } from 'react-redux';
import { createWallet, getWallets } from 'store/actions/accounts';

import cn from 'classnames';
import { useRouter } from 'utils/hooks/useRouter';

const CURRENCIES = {
  TRY: 'Türk Lirası',
  USD: 'Dolar',
  EUR: 'Euro'
};

const StepHeader = ({ title, subtitle }) => (
  <div className="flex flex-col">
    <span className="text-gray-400 text-sm font-semibold">{title}</span>
    <span className="text-gray-900 text-xl font-semibold">{subtitle}</span>
  </div>
);

const Step0 = ({
  setAccountName,
  completed,
  isCorrect,
  selectedCurrency,
  setSelectedCurrecy
}) => {
  const { publishEvent, eventTypes } = useDengage();
  useEffect(() => publishEvent(eventTypes.pageView, { page_type: 'accounts_new_account' }), []);

  return (
    <div className="flex flex-col justify-center w-96">
      <StepHeader
        title="Yeni Hesap Ekle"
        data-testid="label_new_account"
        subtitle={
          <span>
            Yeni hesap eklemek için
            <br /> para birimi ve hesap ismini belirleyin
          </span>
        }
      />
      <Card className="mt-8 w-80 border border-solid border-gray-100">
        <div
          className="w-full flex flex-col gap-y-4"
          data-testid="section_currencies_new_account"
        >
          {Object.keys(CURRENCIES).map((currency, i) => (
            <CurrencyLabel
              isSelectable
              data={currency}
              onClick={() => setSelectedCurrecy(currency)}
              selected={selectedCurrency}
              key={i}
              data-testid="section_currencies_new_account"
            />
          ))}
        </div>
        <CustomInput
          label="Hesap İsmi"
          className="mt-10"
          onChange={(e) => setAccountName(e.target.value)}
          maxLength={14}
          data-testid="input_new_account_account_name"
        />
        <CustomButton
          color="success"
          className="w-full mt-4"
          onClick={completed}
          disabled={!isCorrect}
          data-testid="button_new_account_next"
        >
          Hesap Oluştur
        </CustomButton>
      </Card>
    </div>
  );
};

const CurrencyLabel = ({ onClick, data, selected, isSelectable = true, ...props }) => {
  const classNames = cn(
    'w-full cursor-pointer p-4 rounded-lg border border-solid border-gray-200 text-gray-900 flex items-center',
    {
      'bg-green-200': selected === data,
      'bg-white': selected !== data,
      'pointer-events-none': !isSelectable,
      'border-gray-300 border-1': !isSelectable,
      '!bg-gray-100': !isSelectable,
      '!p-3': !isSelectable
    }
  );
  const soonClassNames = cn(
    'text-gray-900 bg-gray text-xs font-medium bg-gray-200 px-3 py-[5px] rounded-full'
  );

  return (
    <div className={classNames} onClick={onClick} {...props}>
      <div className="w-full flex justify-between items-center">
        <span>{CURRENCIES[data]}</span>
        {!isSelectable && <span className={soonClassNames}>Yakında</span>}
      </div>
    </div>
  );
};

const Step1 = ({ completed, accountName }) => {
  const { publishEvent, eventTypes } = useDengage();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'accounts_new_account_success' });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center space-y-8">
      <img src={DoubleCheck} width="100" alt="check" />
      <span className="text-white text-xl">“{accountName}” başarıyla oluşturuldu.</span>
      <CustomButton
        color="success"
        className="w-48"
        onClick={completed}
        data-testid="button_new_account_success"
      >
        Tamam
      </CustomButton>
    </div>
  );
};

const OpenAnAccountModal = ({ toggleOpenAnAccountModal }) => {
  const dispatch = useDispatch();
  const { sendGTMEvent } = useGTM();
  const [step, setStep] = useState(0);
  const { query, push } = useRouter();

  const [accountName, setAccountName] = useState('');
  const [selectedCurrency, setSelectedCurrecy] = useState(null);

  useEffect(() => {
    if (query?.currencyCode) {
      setSelectedCurrecy(query.currencyCode);
    }
  }, []);

  function handleOpenAccount() {
    dispatch(createWallet({ name: accountName, currencyCode: selectedCurrency })).then((res) => {
      if (![200, 201].includes(res.status)) return;
      dispatch(getWallets({ status: 'ACTIVE' }));
      setStep(1);
    });
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'create_account'
    });
  }

  const onClose = () => {
    if (query?.redirectUrl) {
      push(`${query.redirectUrl}?currencyCode=${selectedCurrency}`);
    }
    toggleOpenAnAccountModal();
  };

  const STEPS = {
    0: (
      <Step0
        setAccountName={setAccountName}
        completed={handleOpenAccount}
        isCorrect={!!accountName && !!selectedCurrency}
        setSelectedCurrecy={setSelectedCurrecy}
        selectedCurrency={selectedCurrency}
      />
    ),
    1: <Step1 completed={onClose} accountName={accountName} />
  };

  return (
    <BottomModal handleClose={onClose} className={step === 1 && 'bg-green-500'}>
      <div
        className="w-full h-full flex items-center justify-center"
        data-testid="section_new_account_modal"
      >
        {STEPS[step]}
      </div>
    </BottomModal>
  );
};

export default OpenAnAccountModal;
