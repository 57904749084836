import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReceiptPdf = ({ pdfUrl, page = 1 }) => (
  <Document
    file={pdfUrl}
    onLoadError={console.log}
    className="rounded-xl"
    data-testid="pdf_receipt_account"
  >
    <Page
      pageNumber={page}
      className="w-96 rounded-xl"
      width={384}
      data-testid="page_receipt_account"
    />
  </Document>
);

export default ReceiptPdf;
