import React from 'react';
import { Link } from 'react-router-dom';
import { navbarRoutes } from 'components/header/contants';
import { useHistory } from 'react-router-dom';

export default function Navbar() {
  const {
    location: { pathname }
  } = useHistory();

  return (
    <div className="flex justify-center items-center w-full md:w-auto order-last md:order-none">
      <ul className="flex space-x-6 text-gray-500">
        {navbarRoutes.map((route, i) => {
          const Icon = route.icon;
          const classNames = pathname === route.to ? 'text-green-500' : 'text-gray-500';
          return (
            <Link to={route.to} key={i}>
              <li className="flex items-center justify-center">
                <Icon className={`${classNames} fill-current w-8 my-auto`} />
                <span className={`${classNames} lg:text-base text-xs`}>{route.name}</span>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
