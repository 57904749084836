import React from 'react';
import { theme } from 'utils';
import cn from 'classnames';
import './index.css';

const {
  sizes,
  button: { variants }
} = theme;

/**
 * size?: 'sm' | 'md' | 'lg';
 * color?: 'primary' | 'secondary' | 'red' | 'gray' | 'yellow' | 'light';
 * variant?: 'default' | 'ghost' | 'tab' | 'circle';
 * leftIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
 * rightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
 * icon?: React.FC<React.SVGProps<SVGSVGElement>>;
 * isLoading?: boolean;
 */
const Button = ({
  children,
  color,
  size = 'md',
  icon,
  variant,
  disabled,
  className = '',
  leftIcon,
  rightIcon,
  onClick,
  ...props
}) => {
  const classes = cn({
    btn: true,
    [`btn-${color}`]: color,
    [`btn-${size}`]: size,
    [`btn-${variant}`]: variant,
    'btn-disabled': disabled,

    [className]: className
  });

  if (variant !== variants.circle) {
    return (
      <button className={classes} disabled={disabled} onClick={onClick} {...props}>
        {renderIcon({ icon: leftIcon, color, size })}
        {children}
        {renderIcon({ icon: rightIcon, color, size, isRightIcon: true })}
      </button>
    );
  }

  return (
    <button className={classes} disabled={disabled} onClick={onClick} {...props}>
      {icon && renderIcon({ icon, color, size, variant })}
      {!icon && children}
    </button>
  );
};

const renderIcon = ({
  icon: Icon = null,
  color = '',
  size = '',
  variant,
  isLoading,
  isRightIcon = false
}) => {
  if (Icon === null) return null;

  const getSpacesByDirection = () => {
    if (variant === variants.circle) return '';
    if (isRightIcon && size === sizes.lg) return ` ml-3`;
    if (isRightIcon && size !== sizes.lg) return ` ml-2`;
    if (!isRightIcon && size == sizes.lg) return ` mr-3`;
    if (!isRightIcon && size !== sizes.lg) return ` mr-2`;
    return '';
  };

  const iconClasses = cn({
    [`stroke-${color}-900] stroke-0 ${getSpacesByDirection()}`]: color,
    'animate-spin': isLoading
  });

  if (React.isValidElement(Icon)) {
    return <span className={iconClasses}>{Icon}</span>;
  }

  if (variant !== variants.circle)
    return (
      <span className={iconClasses}>
        <Icon />
      </span>
    );

  return (
    <span className={iconClasses}>
      <Icon />
    </span>
  );
};

Button.defaultProps = {
  size: sizes.md,
  color: 'primary',
  variant: 'default',
  disabled: false,
  leftIcon: null,
  rightIcon: null,
  className: ''
};

export default Button;
