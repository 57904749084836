import React, { useEffect } from 'react';

export default function useGetClientIp() {
  const [ip, setIp] = React.useState('');

  useEffect(() => {
    fetch('https://ip-api.norma.co')
      .then((response) => response.json())
      .then(({ data }) => setIp(data.ip));
  }, []);

  return ip;
}
