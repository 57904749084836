import { useEffect, useState } from 'react';

export function useRouteStep({ initialStep = 0, max = 0, router, fallbackPath = '/' }, callback) {
  const [step, setStep] = useState(initialStep);

  function nextStep(_step) {
    const val = _step !== null && !isNaN(_step) ? _step : step + 1;
    setStep(val);
    router.history.push('?step=' + val);
  }

  useEffect(() => {
    if (
      router.query.step &&
      !isNaN(router.query.step) &&
      max > Number(router.query.step) &&
      Number(router.query.step) >= 0
    ) {
      nextStep(Number(router.query.step));
    } else {
      nextStep(0);
    }
  }, [router.query.step]);

  useEffect(() => {
    if (router.history.action === 'POP') {
      if (router.query.step == 0) return router.push(fallbackPath);

      callback(Number(router.query.step) - 1);
    }
  }, [router.history.action]);

  useEffect(() => {
    nextStep(initialStep);
  }, []);

  return [step, nextStep];
}
