// Category Icons
import { ReactComponent as ToiletPaper } from '../assets/icons/toilet-paper.svg';
import { ReactComponent as Laptop } from '../assets/icons/laptop.svg';
import { ReactComponent as ForkAndKnife } from '../assets/icons/fork-and-knife.svg';

const FoodBadge = () => (
  <div className="bg-yellow-200 text-yellow-800 px-3 h-6 flex items-center space-x-2 w-min rounded-md">
    <ForkAndKnife className="fill-current w-4 h-auto" />
    <span>Yemek</span>
  </div>
);

const ElectronicBadge = () => (
  <div className="bg-green-200 text-green-800 px-3 h-6 flex items-center space-x-2 w-min rounded-md">
    <Laptop className="fill-current w-4 h-auto" />
    <span>Elektronik</span>
  </div>
);

const MaterialBadge = () => (
  <div className="bg-red-200 text-red-800 px-3 h-6 flex items-center space-x-2 w-min rounded-md">
    <ToiletPaper className="fill-current w-4 h-auto" />
    <span>Malzeme</span>
  </div>
);

export { FoodBadge, ElectronicBadge, MaterialBadge };
