import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useRouter } from 'utils/hooks/useRouter';
import { useSelector } from 'react-redux';
import routes from 'router';
import { USER_COMPANY_STATUS } from 'utils';

import LoadingOverlay from 'components/LoadingOverlay';

function LayoutRouter({
  component: Component,
  layout: Layout,
  isPrivate,
  provider: Provider,
  ...rest
}) {
  const { pathname } = useRouter();

  const { token, companyStatus } = useSelector((state) => state.auth);
  const hasToken = localStorage.getItem('token') || token;
  const { welcome, accessRouter, NotFound } = routes;

  const renderLayout = (props) => {
    if (hasToken && Provider) {
      return (
        <Layout>
          <Provider>
            <Component {...props} />
          </Provider>
        </Layout>
      );
    } else if (hasToken) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    } else {
      return <Redirect to={{ pathname: welcome.path, state: { from: pathname } }} />;
    }
  };

  if (hasToken && !companyStatus) return <LoadingOverlay />;

  if (!isPrivate && (hasToken || companyStatus === USER_COMPANY_STATUS.ACTIVE)) {
    return <Redirect to={accessRouter.path} />;
  }

  let isOnboardingPath = Object.keys(routes).some(
    (route) =>
      routes[route].isPrivate &&
      routes[route].isOnboarding &&
      routes[route].path === pathname &&
      routes[route].path !== NotFound.path
  );

  if (isPrivate) {
    if (
      !isOnboardingPath &&
      companyStatus !== USER_COMPANY_STATUS.ACTIVE &&
      pathname !== accessRouter.path
    ) {
      return <Redirect to={accessRouter.path} />;
    }
    return <Route {...rest} render={renderLayout} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
export default LayoutRouter;
