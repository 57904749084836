import { exchangeTypes } from 'store/types';

const {
  GET_EXCHANGE_RATE,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_FAILURE,
  BUY_FOREIGN_CURRENCY,
  BUY_FOREIGN_CURRENCY_SUCCESS,
  BUY_FOREIGN_CURRENCY_FAILURE,
  CALCULATE_BUY_FOREIGN_CURRENCY,
  CALCULATE_BUY_FOREIGN_CURRENCY_SUCCESS,
  CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE,
  SELL_FOREIGN_CURRENCY,
  SELL_FOREIGN_CURRENCY_SUCCESS,
  SELL_FOREIGN_CURRENCY_FAILURE,
  CALCULATE_SELL_FOREIGN_CURRENCY,
  CALCULATE_SELL_FOREIGN_CURRENCY_SUCCESS,
  CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE
} = exchangeTypes;

const initialState = {
  TRY: [],
  USD: [],
  EUR: [],
  GBP: [],
  isLoading: false,
  rate: null,
  buyForeignCurrency: null,
  calculatedBuyForeignCurrency: null,
  sellForeignCurrency: null,
  calculatedSellForeignCurrency: null,
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RATES':
      return {
        ...state,
        [action.data.currency]: action.data.data
      };
    case GET_EXCHANGE_RATE:
      return {
        ...state,
        isLoading: true,
        rate: null
      };
    case GET_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rate: action.payload
      };
    case GET_EXCHANGE_RATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        rate: null,
        error: action.payload
      };
    case BUY_FOREIGN_CURRENCY:
      return {
        ...state,
        isLoading: true,
        buyorSellForeignCurrency: null
      };
    case BUY_FOREIGN_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        buyorSellForeignCurrency: action.payload
      };
    case BUY_FOREIGN_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        buyorSellForeignCurrency: null,
        error: action.payload
      };
    case CALCULATE_BUY_FOREIGN_CURRENCY:
      return {
        ...state,
        isLoading: true,
        calculatedForeignCurrency: null
      };
    case CALCULATE_BUY_FOREIGN_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        calculatedForeignCurrency: action.payload
      };
    case CALCULATE_BUY_FOREIGN_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        calculatedForeignCurrency: null,
        error: action.payload
      };
    case SELL_FOREIGN_CURRENCY:
      return {
        ...state,
        isLoading: true,
        buyorSellForeignCurrency: null
      };
    case SELL_FOREIGN_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        buyorSellForeignCurrency: action.payload
      };
    case SELL_FOREIGN_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        buyorSellForeignCurrency: null,
        error: action.payload
      };
    case CALCULATE_SELL_FOREIGN_CURRENCY:
      return {
        ...state,
        isLoading: true,
        calculatedForeignCurrency: null
      };
    case CALCULATE_SELL_FOREIGN_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        calculatedForeignCurrency: action.payload
      };
    case CALCULATE_SELL_FOREIGN_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        calculatedForeignCurrency: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
