export default {
  GET_MARKETPLACE_ITEMS: 'GET_MARKETPLACE_ITEMS',
  GET_MARKETPLACE_VARIANTS: 'GET_MARKETPLACE_VARIANTS',
  CANCEL_MARKETPLACE_SUBSCRIPTION: 'CANCEL_MARKETPLACE_SUBSCRIPTION',
  CREATE_MARKETPLACE_SUBSCRIPTION: 'CREATE_MARKETPLACE_SUBSCRIPTION',
  CREATE_MARKETPLACE_SUBSCRIPTION_FAILURE: 'CREATE_MARKETPLACE_SUBSCRIPTION_FAILURE',
  CREATE_MARKETPLACE_SUBSCRIPTION_SUCCESS: 'CREATE_MARKETPLACE_SUBSCRIPTION_SUCCESS',
  PAY_WITH_CARD: 'PAY_WITH_CARD',
  PAY_WITH_CARD_FAILURE: 'PAY_WITH_CARD_FAILURE',
  PAY_WITH_CARD_SUCCESS: 'PAY_WITH_CARD_SUCCESS',
  GET_MARKETPLACE_SUBSCRIPTIONS: 'GET_MARKETPLACE_SUBSCRIPTIONS',
  GET_MARKETPLACE_SUBSCRIPTIONS_FAILURE: 'GET_MARKETPLACE_SUBSCRIPTIONS_FAILURE',
  GET_MARKETPLACE_SUBSCRIPTIONS_SUCCESS: 'GET_MARKETPLACE_SUBSCRIPTIONS_SUCCESS',
  RESET_MARKETPLACE_SUBSCRIPTION: 'RESET_MARKETPLACE_SUBSCRIPTION',
  PAY_WITH_EXISTING_CARD: 'PAY_WITH_EXISTING_CARD',
  PAY_WITH_EXISTING_CARD_FAILURE: 'PAY_WITH_EXISTING_CARD_FAILURE',
  PAY_WITH_EXISTING_CARD_SUCCESS: 'PAY_WITH_EXISTING_CARD_SUCCESS'
};
