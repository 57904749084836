import { CustomButton, CustomInput, Select } from 'components';
import { useEffect } from 'react';

import StepHeader from 'views/company-options/StepHeader';

import { useDispatch, useSelector } from 'react-redux';

import { getCities, getDistrict } from 'store/actions/country';
import useKeyPress from 'utils/hooks/useKeyPress';
import useDengage from 'utils/hooks/useDengage';

export default function AddCurrentBusinessAddress({ nextStep, setValue, watch }) {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const { cities, districts } = useSelector((state) => state.country);

  const filteredDistricts =
    watch('cityId') && districts[watch('cityId')] ? districts[watch('cityId')] : [];

  const isCorrect = watch('cityId') && watch('districtId') && watch('address');

  useEffect(() => {
    dispatch(getCities({ country: 'TR' }));
    publishEvent(eventTypes.pageView, { page_type: 'start_company_address_info' });
  }, []);

  function handleCity(cId) {
    setValue('cityId', cId);
    dispatch(getDistrict({ cityId: cId }));
  }

  function handleDistrict(dId) {
    setValue('districtId', dId);
  }

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    nextStep(4);
  });

  return (
    <div className="w-full">
      <StepHeader title="Şirket Kur" subtitle="Faaliyet adresinizi girin" />
      <Select
        options={cities}
        label="İl"
        value="id"
        valueLabel="name"
        onChange={handleCity}
        className="mt-6"
        defaultValue={watch('cityId')}
        data-testid="select_start_company_address_city"
      />
      <Select
        options={filteredDistricts}
        value="id"
        valueLabel="name"
        label="İlçe"
        className="mt-4"
        onChange={handleDistrict}
        defaultValue={watch('districtId')}
        data-testid="select_start_company_address_district"
      />
      <CustomInput
        label="Adres"
        className="mt-4"
        onChange={(e) => setValue('address', e.target.value)}
        value={watch('address')}
        data-testid="input_start_company_address_address"
      />
      <CustomButton
        color="success"
        className="mt-6 w-full"
        onClick={() => {
          nextStep(4);
        }}
        disabled={!isCorrect}
        data-testid="button_start_company_address_next"
      >
        Devam
      </CustomButton>
    </div>
  );
}
