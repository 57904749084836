import React, { useState, Fragment } from 'react';
import { Button } from 'components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconClockFilled } from 'assets/icons/clock-filled.svg';

const ActionsCard = ({ actionCards, hasAllItemsPurchased = false }) => {
  const [index, setIndex] = useState(0);

  const EmtpyCard = () => (
    <div className="w-[360px] h-[228px] bg-gray-100 rounded-xl p-8">
      <div className="flex justify-between items-center mb-5">
        <IconClockFilled className=" w-12" />
        <span className="text-gray-900 text-sm leading-5 bg-gray-200 px-4 py-2 rounded-full">
          Yakında
        </span>
      </div>
      <div>
        <p className="font-semibold text-lg leading-6 mb-2">Henüz Aksiyon Yok</p>
        <p className="font-medium text-sm leading-5 text-gray-500">
          Şirketiniz için almanız gereken aksiyonlar burada listelenecektir
        </p>
      </div>
    </div>
  );

  function handleIndex() {
    setIndex((index + 1) % actionCards.length);
  }

  const _actionCards = [...actionCards.slice(index), ...actionCards.slice(0, index)].slice(0, 3);

  return (
    <Fragment>
      <div className="w-1/12 lg:block hidden" />
      <div className="lg:w-10/12 w-full">
        <span className="font-semibold text-white text-xl">Aksiyonlar</span>
        <div className="flex gap-x-4 mt-4 w-full overflow-x-auto">
          {hasAllItemsPurchased && <EmtpyCard />}
          {!hasAllItemsPurchased &&
            _actionCards.map((card, i) => (
              <ActionCard
                key={i}
                icon={card.icon}
                doneIcon={card.doneIcon}
                title={card.title}
                subtitle={card.subtitle}
                actionTitle={card.actionTitle}
                doneActionTitle={card.doneActionTitle}
                done={card.done}
                onClick={card.onClick}
              />
            ))}
        </div>
      </div>
      {_actionCards.length > 3 && (
        <div className="w-1/12 flex items-center justify-center mt-10" onClick={handleIndex}>
          <MoreCircle counter={5} />
        </div>
      )}
    </Fragment>
  );
};

const ActionCard = ({
  icon,
  doneIcon,
  title,
  subtitle,
  actionTitle,
  doneActionTitle,
  onClick,
  done
}) => {
  const classNames = done ? 'text-gray-900' : 'text-black';

  return (
    <div
      className={`${classNames} w-[360px] h-64 flex flex-col justify-between items-start rounded-xl p-8 shadow-norma mb-2 bg-white`}
    >
      {done ? doneIcon : icon}
      <div className="flex flex-col space-y-1">
        <span className="font-semibold">{title}</span>
        <span className="text-gray-600 font-light text-sm">{subtitle}</span>
      </div>
      {done ? (
        <div className="flex space-x-2 items-center text-green-700 select-none">
          <span>{doneActionTitle}</span>
          <IconCheck className="w-4 stroke-current" />
        </div>
      ) : (
        <Button
          variant="link"
          onClick={onClick}
          // className="flex space-x-2 items-center text-green-700 hover:text-green-900"
          rightIcon={<ArrowRightIcon className="w-4 stroke-current" />}
        >
          <span>{actionTitle}</span>
          {/* <ArrowRightIcon className="w-4 stroke-current" /> */}
        </Button>
      )}
    </div>
  );
};

const MoreCircle = ({ counter }) => (
  <div className="relative w-12 h-12 flex items-center justify-center cursor-pointer">
    <div className="w-10 h-10 rounded-full text-gray-500 bg-white flex items-center justify-center">
      <ArrowRightIcon className="w-5 stroke-current text-gray-500" />
      <div className="absolute top-0 right-0 bg-red-500 w-5 h-5 text-xs flex items-center justify-center rounded-full text-white select-none">
        {counter < 100 ? counter : '99'}
      </div>
    </div>
  </div>
);

export default ActionsCard;
