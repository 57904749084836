import { useEffect, useState } from 'react';
import { Button } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getArchivedWallets, unarchiveWallet } from 'store/actions/archive';

import { currencyFormat } from 'utils';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
import { WALLET_TYPES } from 'utils/contants';

import { ReactComponent as IconNorma } from 'assets/icons/norma-icon.svg';
import { ReactComponent as IconBankColored } from 'assets/icons/bank-colored.svg';
import { ReactComponent as IconWalletColored } from 'assets/icons/wallet-colored.svg';
import useModal from 'utils/hooks/useModal';
import { TransactionsModal } from './subComponents';
import { getTransactions } from 'store/actions/transactions';
import SuccessModal from 'components/successModal';

const ArchivedWallets = () => {
  const dispatch = useDispatch();
  const { data: archivedWallets } = useSelector((state) => state.archive);
  const [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    dispatch(getArchivedWallets());
  }, []);

  const renderIcon = (type) => {
    switch (type) {
      case WALLET_TYPES.EMONEY.value:
        return <IconNorma className="w-6" />;
      case WALLET_TYPES.BANK.value:
        return <IconBankColored className="w-6" />;
      case WALLET_TYPES.CASH.value:
        return <IconWalletColored className="w-6" />;
      default:
        return null;
    }
  };

  const [isTransactionsModalActive, setIsTransactionsModalActive, toggleTransactionsModal] =
    useModal({
      shouldBeBlockScroll: true
    });

  const [isUnarchiveSuccessModalActive, setIsUnarchiveSuccessModalActive] = useModal({
    shouldBeBlockScroll: true
  });

  const onWalletSelected = (wallet) => {
    dispatch(getTransactions({ walletId: wallet.id }));
    setSelectedWallet(wallet);
    toggleTransactionsModal();
  };

  const onUnarchive = (wallet, e) => {
    setSelectedWallet(wallet);
    dispatch(unarchiveWallet(wallet.id)).then((data) => {
      if (data) {
        dispatch(getArchivedWallets());
        setIsTransactionsModalActive(false);
        setIsUnarchiveSuccessModalActive(true);
      }
    });
    e?.stopPropagation();
  };

  return (
    <div>
      {isUnarchiveSuccessModalActive && (
        <SuccessModal
          actionButtonText="Kapat"
          successMessage={`"${selectedWallet?.name}" isimli hesap başarıyla arşivden çıkarıldı`}
          onClose={() => setIsUnarchiveSuccessModalActive(false)}
          onActionButtonClick={() => setIsUnarchiveSuccessModalActive(false)}
        />
      )}
      {isTransactionsModalActive && (
        <TransactionsModal
          onClose={toggleTransactionsModal}
          onUnarchive={onUnarchive}
          selectedWallet={selectedWallet}
          icon={renderIcon(selectedWallet.type)}
        />
      )}
      <div>
        <div className="flex items-center h-16 text-sm leading-5 font-semibold text-gray-700">
          <span className="flex-1">Hesap İsmi</span>
          <span className="flex-none w-52">Bakiye</span>
          <span className="flex-none w-52">Aksiyonlar</span>
        </div>
        <div>
          {archivedWallets?.map((wallet, i) => (
            <div
              className="flex items-center h-16 hover:bg-gray-100 cursor-pointer text-gray-900 font-semibold text-base leading-6"
              key={i}
              onClick={() => onWalletSelected(wallet)}
            >
              <span className="flex-1 flex gap-x-2 items-center">
                {renderIcon(wallet.type)} {wallet?.name}
              </span>
              <span className="flex-none w-52 text-sm leading-5">
                {currencyFormat(wallet?.balance?.balance, 2)}{' '}
                {CURRENCY_NAMES[wallet?.currencyCode]}
              </span>
              <span className="flex-none w-52">
                <Button size="sm" onClick={(e) => onUnarchive(wallet, e)}>
                  Arşivden Çıkar
                </Button>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArchivedWallets;
