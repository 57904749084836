import axios from 'axios';

export const getCategories = (options = { size: 45, page: 0, name: '' }) => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return Promise.reject();
    return axios
      .get(`/api/companies/${user.companyId}/categories`, { params: options })
      .then((res) => {
        dispatch({
          type: 'SET_CATEGORIES',
          data: res.data.data
        });
      });
  };
};

export const getCategoryIcons = () => {
  return (dispatch) => {
    return axios
      .get('/api/categories/icons')
      .then((res) => {
        dispatch({
          type: 'SET_CATEGORY_ICONS',
          data: res.data.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCategoryColors = () => {
  return (dispatch) => {
    return axios
      .get('/api/categories/colors')
      .then((res) => {
        dispatch({
          type: 'SET_CATEGORY_COLORS',
          data: res.data.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createCategory = ({ colorHexCode, iconUrl, name }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.post(`/api/companies/${user.companyId}/categories`, {
      colorHexCode,
      iconUrl,
      name
    });
  };
};

export const updateCategory = ({ categoryId, colorHexCode, textColorHexCode, iconUrl, name }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.put(`/api/companies/${user.companyId}/categories/${categoryId}`, {
      colorHexCode,
      iconUrl,
      name,
      textColorHexCode
    });
  };
};

export const deleteCategory = ({ categoryId }) => {
  return (_, getState) => {
    const { user } = getState().auth;
    if (!user.companyId) return;
    return axios.delete(`/api/companies/${user.companyId}/categories/${categoryId}`);
  };
};
