import { CustomInput, PhoneInput } from 'components';
import { useContactContext } from 'contexts/contactContext';
import { useState } from 'react';
import { emailRegex } from 'utils';

const ContactInformation = ({ errors, onlyRequired = false }) => {
  const { formValues, setFormValues } = useContactContext();
  const [hasMailError, setHasMailError] = useState(null);

  const onChange = (e) => {
    const { value, name } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  const onChangePhone = (formattedInput, phoneInput) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      fullPhoneNumber: formattedInput || phoneInput
    }));
  };

  const onChangeMail = (e) => {
    const { value } = e.target;
    setHasMailError(() => !emailRegex.test(value));
    onChange(e);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <span className="text-[18px] text-gray-600 font-semibold">İletişim Bilgileri</span>
      <CustomInput
        label="E-Posta"
        isOptional={!onlyRequired}
        name="email"
        onChange={onChangeMail}
        maxLength={140}
        value={formValues?.email}
        error={hasMailError && 'Hatalı E-Posta Adresi'}
        data-testid="input_email"
      />

      {!onlyRequired && (
        <PhoneInput
          onChange={onChangePhone}
          name="fullPhoneNumber"
          isOptional={true}
          showCountryCode={false}
          defaultValue={formValues?.fullPhoneNumber}
          error={errors?.fullPhoneNumber?.message}
          justCellPhone={false}
          data-testid="input_phone"
        />
      )}
    </div>
  );
};

export default ContactInformation;
