import { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import cn from 'classnames';

const AmountInput = ({
  label,
  inputRef,
  suffix,

  prefix,
  onValueChange = () => {},
  error,
  className,
  labelClassName,
  inputClassName,
  disabled,
  value = '',
  max,
  decimalsLimit = 2,
  decimalScale = 2,
  decimalSeparator = ',',
  groupSeparator = '.',
  placeholder = '0,00',
  isOptional = false,
  ...props
}) => {
  const [previewAmount, setPreviewAmount] = useState(value);

  function handleChange(value) {
    if (!value) value = 0;
    if (max && value > max) value = max;

    setPreviewAmount(value.toString());
    if (typeof onValueChange === 'function')
      onValueChange(Number(value.toString().replace(',', '.')));
  }

  useEffect(() => {
    setPreviewAmount(value || 0);
  }, [value]);

  const classNames = cn('flex flex-col', className);
  const labelClassNames = cn(
    'text-sm mb-1',
    { 'text-red-500': error, 'text-gray-500': !error },
    labelClassName
  );
  const inputClassNames = cn(
    'disabled:text-gray-400 w-full focus:outline-none bg-gray-100 rounded-lg p-3',
    { 'border border-solid border-red-500': error },
    inputClassName
  );
  return (
    <div className={classNames} {...props}>
      {label && (
        <span className={labelClassNames}>
          {label}{' '}
          {isOptional && (
            <span className="text-sm leading-5 font-medium text-gray-400 italic">
              {'(Opsiyonel)'}
            </span>
          )}
        </span>
      )}
      <CurrencyInput
        name="input-tax"
        ref={inputRef}
        placeholder={placeholder}
        decimalScale={decimalScale}
        decimalsLimit={decimalsLimit}
        decimalSeparator={decimalSeparator}
        groupSeparator={groupSeparator}
        suffix={suffix}
        prefix={prefix}
        onValueChange={handleChange}
        value={previewAmount}
        disabled={disabled}
        className={inputClassNames}
        max={max}
        {...props}
      />
      {error && <label className="text-xs text-red-500 flex items-center">{error}</label>}
    </div>
  );
};

export default AmountInput;
