import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { menuItems } from 'components/header/contants';
import useModal from 'utils/hooks/useModal';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { DropdownMenu } from 'components';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions/auth';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ProfilePhoto } from 'assets/icons/default-profile-ıcon.svg';

const ProfileMenu = () => {
  const { user } = useSelector((state) => state.auth);
  const profileMenuRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();

  const [isProfileMenuActive, setIsProfileActive, toggleProfileMenu] = useModal();
  useOnClickOutside(profileMenuRef, () => setIsProfileActive(false));

  useEffect(() => {
    menuItems[menuItems.length - 1].onClick = handleLogout;
    menuItems.map((item, i) => {
      if (i !== menuItems.length - 1) {
        item.onClick = () => setIsProfileActive(false);
      }
      return item;
    });
  }, []);

  function handleLogout() {
    dispatch(logout());
    history.push('/');
  }

  return (
    <div className="flex items-center gap-x-2 ">
      <DropdownMenu isActive={isProfileMenuActive} items={menuItems} ref={profileMenuRef}>
        {user?.profilePhotoUrl ? (
          <img
            src={user?.profilePhotoUrl}
            style={{
              width: '40px',
              minWidth: '40px',
              maxWidth: '40px',
              minHeight: '40px',
              maxHeight: '40px',
              height: '40px'
            }}
            className="rounded-full bg-gray-500 border border-white border-solid cursor-pointer object-cover"
            alt="profile"
            onClick={toggleProfileMenu}
          />
        ) : (
          <>
            <ProfilePhoto
              style={{
                width: '32px',
                minWidth: '32px',
                maxWidth: '32px',
                minHeight: '32px',
                maxHeight: '32px',
                height: '32px'
              }}
              className="rounded-full bg-purple-100 border border-purple-200 border-solid cursor-pointer"
              onClick={toggleProfileMenu}
            ></ProfilePhoto>
          </>
          // <div
          //   style={{
          // width: '40px',
          // minWidth: '40px',
          // maxWidth: '40px',
          // minHeight: '40px',
          // maxHeight: '40px',
          // height: '40px'
          //   }}
          // className="rounded-full bg-gray-500 border border-white border-solid cursor-pointer"
          //   onClick={toggleProfileMenu}
          // />
        )}
      </DropdownMenu>
      <div className="flex flex-col justify-center">
        <span className="text-sm font-bold min-w-max">
          {user.firstName + ' ' + user.lastName}
        </span>
        <span className="text-xs text-gray-500">{user.companyName || 'Şirket'}</span>
      </div>
    </div>
  );
};

export default ProfileMenu;
