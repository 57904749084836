export default {
  CHECK_PREPAID_CARD: 'CHECK_PREPAID_CARD',
  CHECK_PREPAID_CARD_SUCCESS: 'CHECK_PREPAID_CARD_SUCCESS',
  CHECK_PREPAID_CARD_FAILURE: 'CHECK_PREPAID_CARD_FAILURE',
  CHECK_PREPAID_CARD_PIN: 'CHECK_PREPAID_CARD_PIN',
  CHECK_PREPAID_CARD_PIN_SUCCESS: 'CHECK_PREPAID_CARD_PIN_SUCCESS',
  CHECK_PREPAID_CARD_PIN_FAILURE: 'CHECK_PREPAID_CARD_PIN_FAILURE',
  UPDATE_PREPAID_CARD_PIN: 'UPDATE_PREPAID_CARD_PIN',
  UPDATE_PREPAID_CARD_PIN_SUCCESS: 'UPDATE_PREPAID_CARD_PIN_SUCCESS',
  UPDATE_PREPAID_CARD_PIN_FAILURE: 'UPDATE_PREPAID_CARD_PIN_FAILURE',
  ACTIVATE_PREPAID_CARD: 'ACTIVATE_PREPAID_CARD',
  ACTIVATE_PREPAID_CARD_SUCCESS: 'ACTIVATE_PREPAID_CARD_SUCCESS',
  ACTIVATE_PREPAID_CARD_FAILURE: 'ACTIVATE_PREPAID_CARD_FAILURE'
};
