import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoices } from 'store/actions/eTransformation';
import useDengage from 'utils/hooks/useDengage';

import {
  currencyFormat,
  E_TRANSFORMATION_TYPES,
  filterKeys,
  getFormattedDateForPayload,
  MONTHS
} from 'utils';
import { INVOICE_PROFILE_IDS } from 'views/invoicing/enums';
import useModal from 'utils/hooks/useModal';
import { EInvoiceFilterModal } from 'views/invoicing/components/acceptRejectInvoiceModal/components';
import { CURRENCY_NAMES } from 'views/invoicing/components/salesRecordDetailModal/enums';
import { SearchInput } from 'components';
import useDebounce from 'utils/hooks/useDebounce';
import { createContext } from 'react';
import { useContext } from 'react';
import { useCallback } from 'react';

const InvoiceListContext = createContext({
  filters: {
    direction: 'INCOMING',
    'app-response-needed': true,
    'profile-id': INVOICE_PROFILE_IDS.TICARIFATURA,
    archived: false,
    startDate: null,
    endDate: null,
    title: ''
  },
  setFilters: () => {}
});

export const useInvoiceListContext = () => useContext(InvoiceListContext);

const InvoiceList = ({ setSelectedInvoice }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const { responseNeededInvoices: invoices } = useSelector((state) => state.eTransformation);
  const { etransformationType } = useSelector((state) => state.auth);

  const [isEInvoiceFilterModalActive, , toggleEInvoiceFilterModal] = useModal();

  const [search, setSearch] = useState('');

  const [filters, setFilters] = useState({
    direction: 'INCOMING',
    'app-response-needed': true,
    'profile-id': INVOICE_PROFILE_IDS.TICARIFATURA,
    archived: false,
    startDate: null,
    endDate: null,
    title: ''
  });

  const searchInputRef = useRef(null);

  const getFilters = useCallback(() => {
    let params = {
      ...filterKeys(filters, ['startDate', 'endDate'])
    };

    if (filters?.startDate) {
      params = {
        ...params,
        'start-date': getFormattedDateForPayload(filters?.startDate)
      };
    }

    if (filters?.endDate) {
      params = {
        ...params,
        'end-date': getFormattedDateForPayload(filters?.endDate)
      };
    }

    if (etransformationType === E_TRANSFORMATION_TYPES.E_RECEIPT) {
      params = {
        direction: 'INCOMING',
        archived: false
      };
    }
    return params;
  }, [filters]);

  useDebounce(search, 500, searchInputRef, () => {
    setFilters((prevState) => ({ ...prevState, title: search }));
  });

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'pre_acc_waiting_e_invoice' });
  }, []);

  useEffect(() => {
    dispatch(getInvoices({ ...getFilters() }));
  }, [filters]);

  const onChangeSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <InvoiceListContext.Provider value={{ filters, setFilters }}>
      <div className="flex flex-col w-[376px] h-auto">
        {isEInvoiceFilterModalActive && (
          <EInvoiceFilterModal onClose={toggleEInvoiceFilterModal} />
        )}
        <span className="text-sm font-semibold text-gray-400">e-Fatura Onayla / Reddet</span>
        <span className="text-3xl font-semibold text-gray-900 mt-2">
          {invoices.totalElements} Bekleyen
        </span>
        <div className="mt-6">
          <SearchInput
            ref={searchInputRef}
            hasFilter={true}
            value={search}
            onClickFilterIcon={toggleEInvoiceFilterModal}
            onChange={onChangeSearch}
          />
        </div>
        <div className="flex flex-col gap-y-4 mt-6">
          {invoices.content.map((invoice, index) => {
            const date = new Date(invoice?.issueDate) || null;
            const formattedDate =
              date instanceof Date // is date
                ? `${date.getDate().toString().padStart(2, '0')} ${
                    MONTHS[date.getMonth()]
                  } ${date.getFullYear()}`
                : '';
            return (
              <div
                key={index}
                className="w-full flex justify-between items-center px-8 py-6 bg-white shadow-lg rounded-xl cursor-pointer"
                onClick={() => setSelectedInvoice(invoice)}
              >
                <div className="flex flex-col gap-y-1">
                  <span className="font-medium text-gray-900">{invoice?.senderTitle || ''}</span>
                  <span className="font-medium text-gray-500">{formattedDate}</span>
                </div>
                <span className="font-semibold text-gray-900">
                  {`${currencyFormat(invoice?.payableAmount) || ''} ${
                    CURRENCY_NAMES[invoice?.currencyCode] || ''
                  }`}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </InvoiceListContext.Provider>
  );
};

export default InvoiceList;
