import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import StepHeader from 'components/stepHeader';
import { Tab } from 'components';

import { useSmartOperationContext } from '../..';

import { getWallets } from 'store/actions/accounts';
import { WALLET_TYPES } from 'utils/contants';
import { ToCustomerSupplier, ToIbanNumber, ToMyAccount, ToNormaNumber } from './components';
import { AUTOMATIC_TRANSACTION_RECEIVER_TYPES } from 'utils';

export default function AutomaticTransactionCreate() {
  const dispatch = useDispatch();

  const { setAutomaticTransactionFormValues, resetAutomaticTransactionFormValues } =
    useSmartOperationContext();

  useEffect(() => {
    dispatch(getWallets({ status: 'ACTIVE', type: WALLET_TYPES.EMONEY.value }));
  }, []);

  const tabs = [
    {
      title: 'Müşteri/Tedarikçi',
      type: AUTOMATIC_TRANSACTION_RECEIVER_TYPES.CONTACT,
      component: <ToCustomerSupplier />
    },
    {
      title: 'Norma No',
      type: AUTOMATIC_TRANSACTION_RECEIVER_TYPES.NORMA_NO,
      component: <ToNormaNumber />
    },
    {
      title: 'IBAN',
      type: AUTOMATIC_TRANSACTION_RECEIVER_TYPES.IBAN,
      component: <ToIbanNumber />
    },
    {
      title: 'Hesaplararası',
      type: AUTOMATIC_TRANSACTION_RECEIVER_TYPES.OWN_ACCOUNT,
      component: <ToMyAccount />
    }
  ];

  const onChangeTab = (_, tab) => {
    resetAutomaticTransactionFormValues();
    setAutomaticTransactionFormValues((formValues) => ({
      ...formValues,
      receiverType: tab.type
    }));
  };

  return (
    <div className="w-full h-full" id="BillPayment">
      <StepHeader title="" subtitle="Otomatik Para Transferi" />
      <div>
        <Tab tabs={tabs} onChange={onChangeTab} defaultTabIndex={0} tabsClassName="mb-10 px-0" />
      </div>
    </div>
  );
}
