import { CustomButton, Popup } from 'components';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendInvoiceResponse } from 'store/actions/eTransformation';

const RejectConfirmationPopup = ({ active, onClose, invoice, handlePopupClose }) => {
  const dispatch = useDispatch();

  const [description, setDescription] = useState('');

  const { user } = useSelector((state) => state.auth);

  function rejectInvoice() {
    if (!user.companyId || !invoice.documentId) return;

    const payload = {
      description,
      accepted: false
    };

    dispatch(
      sendInvoiceResponse({ companyId: user.companyId, documentId: invoice.documentId }, payload)
    )
      .then(() => {
        handlePopupClose();
        onClose();
      })
      .catch((err) => {
        console.log(err);
        handlePopupClose();
      });
  }

  return (
    <Popup active={active} onClose={onClose} style={{ width: '375px', height: 'auto' }}>
      <div className="flex flex-col">
        <span className="text-xl font-semibold text-gray-900">
          Lütfen e-Faturayı reddetme sebebinizi girin
        </span>
        <div className="flex flex-col mt-6">
          <span className="text-sm font-medium text-gray-500">Reddedilme Sebebi</span>
          <textarea
            className="mt-1 appearance-none resize-none focus:outline-none w-full h-20 rounded-lg bg-gray-100 p-3"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <CustomButton color="success" className="mt-6" onClick={rejectInvoice}>
          Geri Gönder
        </CustomButton>
        <CustomButton color="danger" className="mt-4" onClick={onClose}>
          Vazgeç
        </CustomButton>
      </div>
    </Popup>
  );
};

export default RejectConfirmationPopup;
