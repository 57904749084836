import { RadioGroup } from '@headlessui/react';
import { DatePicker } from 'components';
import { useEffect } from 'react';

import { PAYMENT_STATUS } from 'utils';
import { PAYMENT_METHOD_TITLES, CURRENCY_NAMES } from 'views/invoicing/enums';

const SaleRecordStatus = ({ setValue, watch, errors, transaction }) => {
  useEffect(() => {
    if (transaction?.paymentMethod) {
      setValue('paymentMethod', transaction?.paymentMethod);
    }
  }, [transaction]);

  return (
    <div className="py-12 flex flex-col border-b border-solid border-gray-200">
      <span className="font-semibold text-lg text-gray-900">Durum</span>
      <PaymentStatusRadioButtons watch={watch} setValue={setValue} />
      <div className="w-full mt-4">
        {watch('paymentStatus') === PAYMENT_STATUS.PAID && (
          <>
            <div className="flex flex-wrap gap-2 my-4">
              {Object.keys(PAYMENT_METHOD_TITLES).map((paymentMethod) => (
                <span
                  className={`${
                    watch('paymentMethod') === paymentMethod
                      ? 'bg-green-500 text-green-50'
                      : 'bg-gray-200 text-gray-500'
                  } px-4 py-2 rounded-full min-w-max cursor-pointer`}
                  onClick={() => setValue('paymentMethod', paymentMethod)}
                >
                  {PAYMENT_METHOD_TITLES[paymentMethod]}
                </span>
              ))}
            </div>
            <PaymentStatusPaidDatePicker errors={errors} setValue={setValue} watch={watch} />
            {/* {watch('paymentMethod') === PAYMENT_METHODS.NORMA && (
              <PaymentStatusPaidTransaction errors={errors} watch={watch} setValue={setValue} />
            )} */}
          </>
        )}
        {watch('paymentStatus') === PAYMENT_STATUS.NOT_PAID && (
          <PaymentStatusNotPaidDatePicker errors={errors} watch={watch} setValue={setValue} />
        )}
      </div>
    </div>
  );
};

const PaymentStatusRadioButtons = ({ watch, setValue, transaction }) => (
  <RadioGroup
    value={watch('paymentStatus')}
    onChange={(e) => setValue('paymentStatus', e)}
    className="flex gap-x-8 mt-6"
    disabled={
      watch('paymentStatus') === PAYMENT_STATUS.PAID &&
      transaction?.salesExpenseInvoiceDto?.documentId
    }
  >
    <RadioGroup.Option value={PAYMENT_STATUS.PAID}>
      {({ checked }) => (
        <div
          className={`${
            !checked &&
            watch('paymentStatus') !== PAYMENT_STATUS.PAID &&
            !transaction?.salesExpenseInvoiceDto?.documentId &&
            'cursor-pointer'
          } flex gap-x-2 items-center`}
        >
          {checked ? (
            <span className="w-5 h-5 rounded-full bg-white border-4 border-solid border-green-500" />
          ) : (
            <span className="w-5 h-5 rounded-full bg-white border border-solid border-gray-200" />
          )}
          <span className="text-sm font-medium text-gray-700">Ödendi</span>
        </div>
      )}
    </RadioGroup.Option>
    <RadioGroup.Option value={PAYMENT_STATUS.NOT_PAID}>
      {({ checked }) => (
        <div
          className={`${
            !checked &&
            watch('paymentStatus') !== PAYMENT_STATUS.NOT_PAID &&
            !transaction?.salesExpenseInvoiceDto?.documentId &&
            'cursor-pointer'
          } flex gap-x-2 items-center`}
        >
          {checked ? (
            <span className="w-5 h-5 rounded-full bg-white border-4 border-solid border-green-500" />
          ) : (
            <span className="w-5 h-5 rounded-full bg-white border border-solid border-gray-200" />
          )}
          <span className="text-sm font-medium text-gray-700">Ödenecek</span>
        </div>
      )}
    </RadioGroup.Option>
  </RadioGroup>
);

const PaymentStatusPaidDatePicker = ({ watch, setValue, errors }) => (
  <div className="flex flex-col mt-10">
    <span className="text-gray-500 text-sm font-medium">Ödeme Tarihi</span>
    <DatePicker
      className="bg-gray-100 w-full rounded-lg p-2 focus:outline-none"
      selected={watch('paymentDate')}
      onChange={(date) => setValue('paymentDate', date)}
      maxDate={new Date()}
    />
    {errors?.paymentDate && (
      <span className="text-red-500 text-xs mt-2">{errors?.paymentDate.message}</span>
    )}
  </div>
);

const PaymentStatusNotPaidDatePicker = ({ errors, watch, setValue }) => (
  <div className="flex flex-col">
    <span className="text-gray-500 text-sm font-medium">Vade Tarihi</span>
    <DatePicker
      className="bg-gray-100 w-full rounded-lg p-2 focus:outline-none"
      selected={watch('dueDate')}
      onChange={(date) => setValue('dueDate', date)}
      minDate={new Date()}
    />
    {errors?.dueDate && (
      <span className="text-red-500 text-xs mt-2">{errors?.dueDate?.message}</span>
    )}
  </div>
);

// const PaymentStatusPaidTransaction = ({ errors, watch, setValue }) => {
//   const dispatch = useDispatch();

//   const [search, setSearch] = useState(null);
//   const [transfers, setTransfers] = useState([]);
//   const [filteredTransfers, setFilteredTransfers] = useState([]);

//   useEffect(() => {
//     dispatch(getTransfersOfCompany({ search }))
//       .then((res) => {
//         if (search === null) {
//           setTransfers(res.data?.data?.content);
//         }

//         setFilteredTransfers(res.data?.data?.content);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, [search]);

//   return (
//     <div className="flex flex-col w-full">
//       <span className="text-gray-900 font-semibold mt-7">İşlemler</span>
//       <SelectTransferDropdown
//         transfers={filteredTransfers}
//         onSelect={(selectedItems) => setValue('transferIds', selectedItems)}
//         selectedItems={watch('transferIds')}
//         setSearch={setSearch}
//         search={search}
//       />
//       <div className="flex flex-col mt-10">
//         {watch('transferIds').map((transferId) => {
//           const transfer = transfers.find((t) => t.id === transferId);
//           return (
//             <div key={transfer?.id} className="w-full flex justify-between py-4">
//               <div className="flex gap-x-2 items-center justify-center">
//                 <span className="w-10 h-10 rounded-full bg-purple-700 text-purple-300 font-semibold flex items-center justify-center">
//                   {transfer?.receiverAccount?.companyName
//                     ? transfer?.receiverAccount?.companyName
//                         .split(' ')
//                         .map((s) => s[0])
//                         .slice(0, 2)
//                         .join('')
//                     : `${transfer?.receiverAccount?.firstName[0]} ${transfer?.receiverAccount?.lastName[0]}`}
//                 </span>
//                 <div className="flex flex-col">
//                   <span className="font-semibold text-gray-900">
//                     {transfer?.receiverAccount?.companyName ||
//                       `${transfer?.receiverAccount?.firstName} ${transfer?.receiverAccount?.lastName}`}
//                   </span>
//                   <span className="text-xs font-medium text-gray-500">
//                     {dateFormat(new Date(transfer?.transferDate))}
//                   </span>
//                 </div>
//               </div>
//               <div className="flex items-center gap-x-4">
//                 <span className="font-semibold text-gray-900">
//                   {`${currencyFormat(transfer?.amount)} ${CURRENCY_NAMES[transfer?.currency]}`}
//                 </span>
//                 <span
//                   className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
//                   onClick={() => {
//                     setValue(
//                       'transferIds',
//                       watch('transferIds').filter((id) => id !== transferId)
//                     );
//                   }}
//                 >
//                   <TrashIcon className="fill-current w-5 text-gray-500" />
//                 </span>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

export default SaleRecordStatus;
