import { useEffect, useState } from 'react';

export default function useDeviceDetection() {
  const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const regexAndroid = /Android/i;
  const regexIOS = /iP(ad|od|hone)/i;
  const regexTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
  const media = window.matchMedia('(max-width: 1024px)');
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      regex.test(userAgent) || regexTablet.test(userAgent.toLowerCase()) || media.matches
    );
    setIsAndroid(regexAndroid.test(userAgent));
    setIsIOS(regexIOS.test(userAgent));
    setIsTablet(regexTablet.test(userAgent.toLowerCase()));
  }, [navigator.userAgent]);
  return {
    isMobile,
    isAndroid,
    isIOS,
    isTablet
  };
}
