import axios from 'axios';

export const getCountries = () => {
  return (dispatch) => {
    return axios.get('/api/countries').then((res) => {
      dispatch({
        type: 'SET_COUNTRIES',
        data: res.data?.data
      });
    });
  };
};

export const getCities = ({ country = 'TR' } = { country: 'TR' }) => {
  return (dispatch) => {
    if (!country) return new Promise.reject();
    return axios.get(`/api/countries/${country}/cities`).then((res) => {
      dispatch({
        type: 'SET_CITIES',
        data: res.data?.data
      });
    });
  };
};

export const getDistrict = ({ cityId }) => {
  return (dispatch) => {
    return axios.get(`/api/countries/TR/cities/${cityId}`).then((res) => {
      dispatch({
        type: 'SET_DISTRICT',
        data: {
          cityId,
          data: res.data?.data
        }
      });
    });
  };
};

export const getRegions = (cityId, districtId) => {
  return (dispatch) => {
    return axios.get(`/api/countries/TR/cities/${cityId}/districts/${districtId}`).then((res) => {
      console.log(res.data.data);
      dispatch({
        type: 'SET_REGIONS',
        data: res.data?.data
      });
    });
  };
};
export const getStreets = (cityId, districtId, regionId) => {
  return (dispatch) => {
    return axios
      .get(`/api/countries/TR/cities/${cityId}/districts/${districtId}/regions/${regionId}`)
      .then((res) => {
        dispatch({
          type: 'SET_STREETS',
          data: res.data?.data
        });
      });
  };
};
