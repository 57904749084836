import { Button, InfoMessage, Select } from 'components';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepHeader from 'views/company-options/StepHeader';
import { getProfessions, getSectors } from 'store/actions/activityScope';
import useKeyPress from 'utils/hooks/useKeyPress';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';

export default function CompanySector({ setValue, watch }) {
  const submitRef = useRef();
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();

  const { professions, sectors } = useSelector((state) => state.activityScope);

  useEffect(() => {
    dispatch(getProfessions());
    dispatch(getSectors());
    publishEvent(eventTypes.pageView, { page_type: 'add_company_activity_info' });
  }, []);

  const isCorrect = watch('sector') && watch('profession');

  useKeyPress((e) => {
    if (e.key !== 'Enter' || !isCorrect) return;

    submitRef.current.click();
  });

  const sendEvent = () => {
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'add_a_company_sector_success'
    });
  };

  return (
    <div>
      <StepHeader
        title="Şirket Ekle"
        subtitle="İşinizden ve faaliyet gösterdiğiniz sektörden kısaca bahsedin"
      />
      <div className="flex flex-col space-y-4">
        <Select
          options={sectors}
          value="refId"
          valueLabel="value"
          label="Sektör Bilgisi"
          defaultValue={watch('sector')}
          onChange={(e) => setValue('sector', e)}
          className="mt-6"
          data-testid="select_company_sector"
        />
        <Select
          options={professions}
          value="refId"
          valueLabel="value"
          label="Meslek Bilgisi"
          defaultValue={watch('profession')}
          onChange={(e) => setValue('profession', e)}
          className="mt-6"
          data-testid="select_company_profession"
        />
        <Button
          className="mt-6 w-full"
          disabled={!isCorrect}
          type="submit"
          onClick={sendEvent}
          data-testid="button_company_options_next"
        >
          Kaydet
        </Button>
        <button ref={submitRef} className="hidden" type="submit" />
        <InfoMessage>
          İşiniz ve sektörünüz ile ilgili girdiğiniz bilgileri faaliyet alanınızı belirlemede
          kullanacağız.
        </InfoMessage>
      </div>
    </div>
  );
}
