import React, { useState } from 'react';
import StepHeader from 'components/stepHeader';
import { useWalletContext } from 'contexts/walletContext';
import { WALLET_TYPES } from 'utils/contants';
import { SearchInput, Card, Button, EmptyView } from 'components';
import { useSelector } from 'react-redux';
import { useOpenBakingContext } from '../..';
import useModal from 'utils/hooks/useModal';
import { Prompt } from 'components/promptModal';

//icons
import { ReactComponent as IconArrowRight } from 'assets/icons/caret-right.svg';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { useDispatch } from 'react-redux';
import { getOpenBankingBankRequiredFields } from 'store/actions/openBanking';
import classNames from 'classnames';

export default function SelectBank() {
  const { selectedWalletType } = useWalletContext();
  const {
    setStep,
    steps,
    formValues: { bank }
  } = useOpenBakingContext();
  const [isOpenBankingAccessInformationPopupActive, , toggleOpenBankingAccessInformationPopup] =
    useModal();

  const dispatch = useDispatch();

  const onOk = () => {
    dispatch(getOpenBankingBankRequiredFields(bank.bankId)).then(() => {
      setStep(steps.addBankAccountForm);
      toggleOpenBankingAccessInformationPopup();
    });
  };
  const onCancel = () => {
    setStep(steps.downloadForm);
    toggleOpenBankingAccessInformationPopup();
  };

  return (
    <div className="flex flex-col gap-y-6 max-w-[420px] mx-auto">
      <StepHeader
        title={`${WALLET_TYPES[selectedWalletType]?.label}`}
        subtitle="Banka Ekle"
        className="!m-0"
      />
      {isOpenBankingAccessInformationPopupActive && (
        <OpenBankingAccessInformationPopup onCancel={onCancel} onOk={onOk} />
      )}
      <Banks toggleOpenBankingAccessInformationPopup={toggleOpenBankingAccessInformationPopup} />
    </div>
  );
}

function OpenBankingAccessInformationPopup({ onOk, onCancel }) {
  return (
    <Prompt
      isActive={true}
      className="max-w-xs mx-auto"
      title={
        <div className="flex flex-col items-center justify-between gap-y-6">
          <Button
            variant="circle"
            color="yellow"
            className="pointer-events-none scale-150"
            icon={<IconInfoCircle className="fill-current text-yellow-600" />}
          />
          <div className="text-lg font-semibold">
            Erişim Bilgilerinizi Bankanızdan Aldınız mı?
          </div>
        </div>
      }
      okText="Evet"
      okButtonColor="secondary"
      cancelButtonColor="primary"
      cancelText="Hayır"
      onOK={onOk}
      onCancel={onCancel}
    />
  );
}

const Banks = ({ toggleOpenBankingAccessInformationPopup }) => {
  const { bankInformations } = useSelector((state) => state.openBanking);
  const [searchText, setSearchText] = useState('');
  const { setFormValues } = useOpenBakingContext();

  const onChangeSearchText = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const onBankSelected = (bank) => {
    toggleOpenBankingAccessInformationPopup();
    setFormValues((prevValues) => ({
      ...prevValues,
      bank
    }));
  };

  const banks = bankInformations?.filter((bank) =>
    bank.bankName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  return (
    <>
      <SearchInput containerClassName="!w-full" onChange={onChangeSearchText} />
      {banks.length > 0 ? (
        <Card className="flex flex-col shadow-norma divide-y divide-gray-100 max-h-[420px] overflow-y-auto h-full w-full">
          {banks.map((bank, index) => (
            <div
              key={bank?.id}
              className={classNames(
                'flex justify-between items-center cursor-pointer border-solid py-6',
                { 'pt-0': index === 0 }
              )}
              onClick={() => onBankSelected(bank)}
            >
              <img src={bank?.logoUrl} alt={bank?.name} className="w-[120px]" />
              <IconArrowRight className="stroke-current text-gray-700 w-5 h-5" />
            </div>
          ))}
        </Card>
      ) : (
        <EmptyView>Banka Bulunamadı</EmptyView>
      )}
    </>
  );
};
