import React, { useState, useEffect } from 'react';
import { useItemContext } from 'contexts/itemContext';
import { useSelector } from 'react-redux';
import AddTaxButton from '../addTaxButton';
import { Button, CustomInput, Select } from 'components';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';

export default function WithholdingTax() {
  const { withHoldingTaxes } = useSelector((state) => state.eTransformation);
  const { formValues, setFormValues, isFreelancer } = useItemContext();
  const [isShow, setIsShow] = useState(!!formValues.vatWithholdingCode);

  useEffect(() => {
    if (!!formValues.vatWithholdingCode || !!formValues.vatWithholdingRate) {
      setIsShow(true);
      if (isFreelancer)
        setFormValues((prevValues) => ({
          ...prevValues,
          vatWithholdingCode: withHoldingTaxes[0]?.code
        }));
    }
  }, [formValues.vatWithholdingCode, formValues.vatWithholdingRate, withHoldingTaxes]);

  const onChangeVatWithholdingCode = (code) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      vatWithholdingCode: code,
      vatWithholdingRate: withHoldingTaxes.find((tax) => tax.code === code)?.ratios[0].value
    }));
  };

  const onChangeVatWithholdingRate = (rate) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      vatWithholdingRate: rate ? Number(rate) : null
    }));
  };

  const resetWithholdingTax = () => {
    setIsShow((show) => !show);
    setFormValues((prevValues) => ({
      ...prevValues,
      vatWithholdingCode: null,
      vatWithholdingRate: null
    }));
  };

  const onAddTax = () => {
    if (isFreelancer) {
      setFormValues((prevValues) => ({
        ...prevValues,
        vatWithholdingCode: withHoldingTaxes[0].code
      }));
    }
    setIsShow((show) => !show);
  };

  return (
    <div>
      {!isShow && <AddTaxButton onClick={onAddTax}>Tevfikat Ekle</AddTaxButton>}
      {isShow && (
        <div className="flex items-center space-x-4">
          {isFreelancer ? (
            <CustomInput
              label="Tevkifat Adı"
              className="w-full max-w-[172px]"
              placeholder="KDV Tevkifatı"
              disabled
            />
          ) : (
            <Select
              className="w-full max-w-[172px]"
              options={withHoldingTaxes}
              value="code"
              valueLabel="code"
              label="Tevkifat Kodu"
              onChange={onChangeVatWithholdingCode}
              defaultValue={formValues.vatWithholdingCode}
            />
          )}
          <Select
            className="w-full max-w-[118px]"
            options={
              withHoldingTaxes.find((tax) => tax.code === formValues.vatWithholdingCode)?.ratios
            }
            value="value"
            valueLabel="viewText"
            label="Tevkifat Oranı"
            defaultValue={formValues.vatWithholdingRate}
            onChange={onChangeVatWithholdingRate}
          />
          <div>
            <Button
              className="mt-5"
              size="sm"
              variant="circle"
              color="gray"
              icon={<IconClose className="stroke-current w-5" />}
              onClick={resetWithholdingTax}
            />
          </div>
        </div>
      )}
    </div>
  );
}
