import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  approveCompanyTaxDeclarationDocument,
  completePaymentCompanyTaxDeclarationDocument,
  getCompanyTaxDeclarationDocuments,
  sendBackCompanyTaxDeclarationDocument
} from 'store/actions/documents';
import {
  currencyFormat,
  DOCUMENT_STATUS,
  getFormattedDateForPayload,
  getFormattedDateWithSeperator,
  MAX_DOCUMENT_NAME_LENGTH
} from 'utils';
import TabSubTitle from 'views/profile/tabs/tabSubTitle';
import { Tag, Button, CustomInput, DragAndDropFileUploader, DatePicker } from 'components';

import ViewDocumentModal from './subComponents/viewDocumentModal';
import { Prompt } from 'components/promptModal';

import useModal from 'utils/hooks/useModal';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconInfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as IconClock } from 'assets/icons/clock.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as IconRefresh } from 'assets/icons/refresh.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg';

import FILE_TYPES from 'utils/fileTypes';
import { uploadFile } from 'store/actions/fileUpload';
import { ViewDocumentDetailModal, ViewFileModal } from './subComponents';
import CopyToClipboardButton from 'components/copyToClipboardButton';
import useDengage from 'utils/hooks/useDengage';

const Document = () => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedPaymentReceiptImage, setSelectedPaymentReceiptImage] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { publishEvent, eventTypes } = useDengage();

  const [documentSendBackDescription, setDocumentSendBackDescription] = useState('');
  const documents = useSelector((state) => state.documents);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const viewDocumentModalRef = useRef(null);
  const viewDocumentDetailModalRef = useRef(null);
  const viewSelectedFileModalRef = useRef(null);

  const [isViewDocumentModalOpen, setViewDocumentModal, toggleViewDocumentModal] = useModal({
    shouldBeBlockScroll: true
  });
  const [
    isViewDocumentDetailModalOpen,
    setViewDocumentDetailModal,
    toggleViewDocumentDetailModal
  ] = useModal({
    shouldBeBlockScroll: true
  });
  const [isApprovePromptModalOpen, , toggleApprovePromptModal] = useModal({
    shouldBeBlockScroll: true
  });
  const [isSendBackPromptModalOpen, , toggleSendBackPromptModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isPaymentInfoModalOpen, , toggleIsPaymentInfoModalOpen] = useModal({
    shouldBeBlockScroll: true
  });

  const [isViewSelectedFileModalOpen, setViewSelectedFileModal, toggleViewSelectedFileModal] =
    useModal({
      shouldBeBlockScroll: true
    });

  useOnClickOutside(viewDocumentModalRef, () => setViewDocumentModal(false));
  useOnClickOutside(viewDocumentDetailModalRef, () => setViewDocumentDetailModal(false));
  useOnClickOutside(viewSelectedFileModalRef, () => setViewSelectedFileModal(false));

  useEffect(() => {
    if (user?.companyId) dispatch(getCompanyTaxDeclarationDocuments(user.companyId));
  }, [user]);

  useEffect(() => {
    if (!isViewDocumentModalOpen) setSelectedDocument(null);
  }, [isViewDocumentModalOpen]);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'profile_and_settings_tax_declaration' });
  }, []);

  const renderStatusIcon = (status) => {
    if (status === DOCUMENT_STATUS.WAITING_FOR_RECEIPT_NUMBER.key)
      return <IconCalendar className="stroke-0" />;
    if (status.includes('WAITING')) return IconClock;
    if (status === DOCUMENT_STATUS.PAID.key) return IconCheck;
    if (status === DOCUMENT_STATUS.REJECTED.key) return IconInfoCircle;
    if (status === DOCUMENT_STATUS.SEND_BACK.key) return <IconRefresh className="stroke-0" />;
    return null;
  };

  const onSelectDocument = (document) => {
    setSelectedDocument(document);
    if (document.status === DOCUMENT_STATUS.PAID.key) return toggleViewDocumentDetailModal();
    toggleViewDocumentModal();
  };

  const onApprovedDocument = () => {
    dispatch(
      approveCompanyTaxDeclarationDocument({
        documentId: selectedDocument.id,
        companyId: user.companyId
      })
    ).then(() => {
      toggleApprovePromptModal();
      toggleViewDocumentModal();
      dispatch(getCompanyTaxDeclarationDocuments(user.companyId));
    });
  };
  const onRejectedDocument = () => {
    dispatch(
      sendBackCompanyTaxDeclarationDocument({
        documentId: selectedDocument.id,
        companyId: user.companyId,
        description: documentSendBackDescription
      })
    ).then(() => {
      toggleSendBackPromptModal();
      toggleViewDocumentModal();
      dispatch(getCompanyTaxDeclarationDocuments(user.companyId));
    });
  };

  const onUploadReceipt = async () => {
    let fileUploadResponse = await uploadFile({
      file: selectedPaymentReceiptImage[0],
      fileType: FILE_TYPES.NORMA_COMPANY_TAX_DECLARATION_RECEIPT_OF_PAYMENT
    }).catch(() => {
      //TODO: show file upload error
    });

    const payload = {
      paidDate: getFormattedDateForPayload(new Date(paymentDate)),
      receiptPaymentFileId: fileUploadResponse.data?.data.id
    };

    dispatch(
      completePaymentCompanyTaxDeclarationDocument(payload, {
        documentId: selectedDocument.id,
        companyId: user.companyId
      })
    )
      .then(() => {
        toggleIsPaymentInfoModalOpen();
        toggleViewDocumentModal();
        dispatch(getCompanyTaxDeclarationDocuments(user.companyId));
      })
      .catch(() => {
        //TODO: show complete payment error
      });
  };

  const onSendBackDocumentCancelled = () => {
    toggleSendBackPromptModal();
    setDocumentSendBackDescription('');
  };

  const checkFileNameLength = (fileName) => {
    let fileNameWithoutExtention = fileName.split('.')[0];
    if (fileNameWithoutExtention.length > MAX_DOCUMENT_NAME_LENGTH) {
      return `${fileNameWithoutExtention.substring(0, MAX_DOCUMENT_NAME_LENGTH)}...`;
    }
    return fileNameWithoutExtention;
  };

  if (!documents) return null;

  return (
    <div data-testid="section_documents_tax">
      <TabSubTitle className="text-[22px] leading-7 px-8">
        Vergi/Beyanname Belgeleri ({documents?.totalElements})
      </TabSubTitle>
      <ViewDocumentModal
        ref={viewDocumentModalRef}
        isActive={isViewDocumentModalOpen}
        handleClose={toggleViewDocumentModal}
        selectedDocument={selectedDocument}
        onApprove={toggleApprovePromptModal}
        onSendBack={toggleSendBackPromptModal}
        onAddPaymentInfo={toggleIsPaymentInfoModalOpen}
      />
      <ViewDocumentDetailModal
        ref={viewDocumentDetailModalRef}
        isActive={isViewDocumentDetailModalOpen}
        handleClose={toggleViewDocumentDetailModal}
        selectedDocument={selectedDocument}
        onSelectFile={(file) => {
          toggleViewSelectedFileModal();
          setSelectedFile(file);
        }}
      />
      <ViewFileModal
        ref={viewSelectedFileModalRef}
        isActive={isViewSelectedFileModalOpen}
        handleClose={toggleViewSelectedFileModal}
        selectedDocument={selectedDocument}
        selectedFile={selectedFile}
      />
      <Prompt
        isActive={isApprovePromptModalOpen}
        className="max-w-xs mx-auto"
        title={
          <div className="flex flex-col items-center justify-between gap-y-6">
            <Button variant="circle" className="pointer-events-none scale-150" icon={IconCheck} />
            <div>
              <span className="font-bold">{selectedDocument?.file?.name.split('.')[0]} </span>
              isimli belgeyi onaylamak istediğine emin misin?
            </div>
          </div>
        }
        okText="Onayla"
        cancelText="Vazgeç"
        onOK={onApprovedDocument}
        onCancel={toggleApprovePromptModal}
      />
      <Prompt
        isActive={isSendBackPromptModalOpen}
        title={<h5>Lütfen geri göndermek için açıklama yazınız</h5>}
        description={
          <div className="text-left">
            <CustomInput
              label="Açıklama"
              defaultValue={documentSendBackDescription}
              onChange={(e) => setDocumentSendBackDescription(e.target.value)}
            />
          </div>
        }
        okText="Geri Gönder"
        cancelText="Vazgeç"
        isOkButtonDisabled={documentSendBackDescription?.length <= 3}
        onOK={onRejectedDocument}
        onCancel={onSendBackDocumentCancelled}
      />
      <Prompt
        isActive={isPaymentInfoModalOpen}
        title={<h5>Lütfen ödemeye dair fiş ya da makbuz görüntüsünü yükleyin</h5>}
        description={
          <div className="text-left flex flex-col gap-y-4">
            <DragAndDropFileUploader onSelected={setSelectedPaymentReceiptImage} />
            <div>
              <div className="text-gray-500 text-sm mb-1 font-medium leading-5">Ödeme Tarihi</div>
              <DatePicker
                selected={paymentDate}
                onChange={setPaymentDate}
                className="bg-gray-100 rounded-lg p-2 w-full"
                dateFormat="dd/MM/yyyy"
                placeholderText="gg/aa/yyyy"
              />
            </div>
          </div>
        }
        okText="Yükle"
        cancelText="Vazgeç"
        isOkButtonDisabled={!selectedPaymentReceiptImage || !paymentDate}
        onOK={onUploadReceipt}
        onCancel={toggleIsPaymentInfoModalOpen}
      />
      <table className="table-auto w-full">
        <thead>
          <tr className="text-center text-sm font-semibold text-gray-700 border-b border-solid border-gray-100">
            <td className="py-4 text-left pl-8">Dosya İsmi</td>
            <td className="py-4">Son Ödeme Tarihi</td>
            <td className="py-4">Tahakkuk Fiş No</td>
            <td className="py-4">Tutar</td>
            <td className="py-4 text-right pr-8">Durum</td>
          </tr>
        </thead>
        <tbody>
          {documents?.content
            ?.filter((document) => document.status !== 'PASSIVE')
            ?.map((document, i) => (
              <tr
                className="text-center border-b border-solid border-gray-100 hover:bg-gray-50 group transition cursor-pointer leading-4 font-medium text-xs"
                key={i}
                onClick={() => onSelectDocument(document)}
              >
                <td className="gap-x-4 py-6 text-left pl-8">
                  <span className="flex items-center gap-x-2 font-semibold text-base text-gray-800">
                    <span>{checkFileNameLength(document?.file?.name)}</span>
                    <IconArrowRight className="w-4 h-4 stroke-current -rotate-45 hidden group-hover:block" />
                  </span>
                  <div>{document?.taxDocumentType?.name}</div>
                </td>
                <td className="gap-x-4 py-6 ">
                  {getFormattedDateWithSeperator(document.dueDate)}
                </td>
                <td className="gap-x-4 py-6 ">
                  {document.receiptNumber && (
                    <CopyToClipboardButton
                      textToCopy={document.receiptNumber}
                      beforeCopyMessage="Kopyala"
                      copiedTextMessage="Kopyalandı"
                      activateTimeout={3}
                    />
                  )}
                </td>
                <td className="gap-x-4 py-6 ">{currencyFormat(document?.amount)} TL</td>
                <td className="gap-x-4 py-6 flex justify-end pr-8">
                  {document?.status && (
                    <Tag
                      color={DOCUMENT_STATUS[document?.status].color}
                      textColor={DOCUMENT_STATUS[document?.status].textColor}
                      size="sm"
                      leftIcon={renderStatusIcon(document?.status)}
                    >
                      {DOCUMENT_STATUS[document?.status].text}
                    </Tag>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Document;
