import axios from 'axios';

export const getCompany = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    if (!user?.companyId) return Promise.reject();
    return axios.get(`/api/companies/${user.companyId}`).then((res) => {
      dispatch({
        type: 'SET_COMPANY_STATUS',
        data: {
          companyStatus: res.data?.data?.status,
          kycCompleted: res.data?.data?.kycCompleted,
          etransformationType: res.data?.data?.etransformationType,
          company: res.data.data
        }
      });
      return res;
    });
  };
};

export const setCompanyOptions = (payload) => {
  return (dispatch, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();
    return axios.put(`/api/companies/${companyId}/info`, payload);
  };
};

export const addCompany = (payload) => {
  return (dispatch, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();
    return axios.put(`/api/companies/${companyId}/addCompany`, payload);
  };
};

export const startCompany = (payload) => {
  return (dispatch, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();
    return axios.put(`/api/companies/${companyId}/startCompany`, payload);
  };
};

export const onboardingCompany = (payload) => {
  return (_, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();
    return axios.post(`/api/companies/${companyId}/onboarding`, payload);
  };
};

export const getMonthlyRevenue = () => {
  return (dispatch, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();
    return axios.get(`/api/companies/${companyId}/monthly-revenue`);
  };
};

export const getBalances = (currency = 'TRY') => {
  return (dispatch, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();

    return axios.get(`/api/companies/${companyId}/balances`, { params: { currency } });
  };
};

export const getBalance = (params) => {
  return (dispatch, getState) => {
    const {
      user: { companyId }
    } = getState().auth;
    if (!companyId) return Promise.reject();

    return axios.get(`/api/companies/${companyId}/balance`, { params });
  };
};
