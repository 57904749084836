import axios from 'axios';
import { transactionsTypes } from 'store/types';

const {
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_FAILURE,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  CONFIRM_TRANSACTION,
  CONFIRM_TRANSACTION_FAILURE,
  CONFIRM_TRANSACTION_SUCCESS,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_CATEGORY,
  UPDATE_TRANSACTION_CATEGORY_SUCCESS,
  UPDATE_TRANSACTION_CATEGORY_FAILURE,
  UPDATE_TRANSACTION_EXPENSE_TYPE,
  UPDATE_TRANSACTION_EXPENSE_TYPE_SUCCESS,
  UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE,
  UPDATE_TRANSACTION_NOTE,
  UPDATE_TRANSACTION_NOTE_SUCCESS,
  UPDATE_TRANSACTION_NOTE_FAILURE,
  RESET_CONFIRMED_TRANSACTION,
  ADD_WALLET_TRANSACTION,
  ADD_WALLET_TRANSACTION_SUCCESS,
  ADD_WALLET_TRANSACTION_FAILURE,
  GET_TRANSACTION_GROUP_TYPES,
  GET_TRANSACTION_GROUP_TYPES_FAILURE,
  GET_TRANSACTION_GROUP_TYPES_SUCCESS
} = transactionsTypes;

const initialParams = {
  walletId: '',
  queries: { orderBy: 'transactionDate', orderDirection: 'ASC', status: 'SUCCESS' }
};

export const getTransactions = ({ walletId, searchParams, queries } = initialParams) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId || !walletId) {
        dispatch({
          type: GET_TRANSACTIONS_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: GET_TRANSACTIONS
      });

      const response = await axios.get(`/api/wallets/${walletId}/transactions/search`, {
        params: {
          orderBy: 'transactionDate',
          orderDirection: 'ASC',
          status: 'SUCCESS',
          ...queries,
          ...searchParams
        }
      });
      if (response.status === 200) {
        dispatch({
          type: GET_TRANSACTIONS_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS_FAILURE,
        payload: error
      });
    }
  };
};

export const getTransaction = ({ walletId, transactionId, ...queries }) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId || !walletId) {
        dispatch({
          type: GET_TRANSACTION_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: GET_TRANSACTION
      });

      const response = await axios.get(`/api/wallets/${walletId}/transactions/${transactionId}`, {
        params: {
          ...queries
        }
      });
      if (response.status === 200) {
        dispatch({
          type: GET_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_TRANSACTION_FAILURE,
        payload: error
      });
    }
  };
};

export const createTransactionWithNorma = (
  walletId = null,
  payload = { amount: 0, receiverWalletNumber: null }
) => {
  return async (dispatch) => {
    await dispatch(resetConfirmedTransaction());
    try {
      if (!walletId) {
        let errorMessage = 'No walletId';
        dispatch({
          type: CREATE_TRANSACTION_FAILURE,
          payload: {
            error: errorMessage
          }
        });
        return Promise.reject(errorMessage);
      }

      dispatch({
        type: CREATE_TRANSACTION
      });
      const response = await axios.post(`/api/wallets/${walletId}/transactions`, payload);
      if (response.status === 200) {
        dispatch({
          type: CREATE_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CREATE_TRANSACTION_FAILURE,
        payload: error
      });
    }
  };
};

export const confirmTransaction = (
  walletId = null,
  payload = { description: '', groupId: '' }
) => {
  return async (dispatch) => {
    try {
      // if (!payload?.description) {
      //   let errorMessage = 'No description';
      //   dispatch({
      //     type: CONFIRM_TRANSACTION_FAILURE,
      //     payload: {
      //       error: errorMessage
      //     }
      //   });
      //   return Promise.reject(errorMessage);
      // }
      if (!walletId) {
        let errorMessage = 'No walletId';
        dispatch({
          type: CONFIRM_TRANSACTION_FAILURE,
          payload: {
            error: errorMessage
          }
        });
        return Promise.reject(errorMessage);
      }

      dispatch({
        type: CONFIRM_TRANSACTION
      });

      const response = await axios.post(`/api/wallets/${walletId}/confirmation`, payload);

      if (response.status === 200) {
        dispatch({
          type: CONFIRM_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: CONFIRM_TRANSACTION_FAILURE,
        payload: error
      });
    }
  };
};

export const updateTransactionNote = ({ walletId, transactionId, note }) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId || !walletId) {
        dispatch({
          type: UPDATE_TRANSACTION_NOTE_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: UPDATE_TRANSACTION_NOTE
      });

      const response = await axios.put(
        `/api/wallets/${walletId}/transactions/${transactionId}/notes`,
        {
          note
        }
      );
      if (response.status === 200) {
        dispatch({
          type: UPDATE_TRANSACTION_NOTE_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_TRANSACTION_NOTE_FAILURE,
        payload: error
      });
    }
  };
};
export const updateTransactionExpenseType = ({ walletId, transactionId, expenseType }) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId || !walletId) {
        dispatch({
          type: UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: UPDATE_TRANSACTION_EXPENSE_TYPE
      });

      const response = await axios.put(
        `/api/wallets/${walletId}/transactions/${transactionId}/expense-type`,
        {
          expenseType
        }
      );
      if (response.status === 200) {
        dispatch({
          type: UPDATE_TRANSACTION_EXPENSE_TYPE_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_TRANSACTION_EXPENSE_TYPE_FAILURE,
        payload: error
      });
    }
  };
};
export const updateTransactionCategory = ({ walletId, transactionId, categoryId }) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId || !walletId) {
        dispatch({
          type: UPDATE_TRANSACTION_CATEGORY_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: UPDATE_TRANSACTION_CATEGORY
      });

      const response = await axios.put(
        `/api/wallets/${walletId}/transactions/${transactionId}/categories`,
        {
          categoryId
        }
      );
      if (response.status === 200) {
        dispatch({
          type: UPDATE_TRANSACTION_CATEGORY_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_TRANSACTION_CATEGORY_FAILURE,
        payload: error
      });
    }
  };
};

export const addManualTransaction = (walletId, payload) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId || !walletId) {
        dispatch({
          type: ADD_WALLET_TRANSACTION_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: ADD_WALLET_TRANSACTION
      });

      const response = await axios.post(`/api/wallets/${walletId}/transactions/manual`, {
        ...payload
      });

      if (response.status === 200) {
        dispatch({
          type: ADD_WALLET_TRANSACTION_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: ADD_WALLET_TRANSACTION_FAILURE,
        payload: error
      });
    }
  };
};

export const getTransactionGroupByType = (type = 'TransactionType') => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      if (!user.companyId) {
        dispatch({
          type: GET_TRANSACTION_GROUP_TYPES_FAILURE,
          payload: {
            error: 'No companyId or walletId'
          }
        });
        return Promise.reject('No companyId or walletId');
      }

      dispatch({
        type: GET_TRANSACTION_GROUP_TYPES
      });

      const response = await axios.get(`/enums/${type}`);

      if (response.status === 200) {
        dispatch({
          type: GET_TRANSACTION_GROUP_TYPES_SUCCESS,
          payload: response.data.data
        });
        return Promise.resolve(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: GET_TRANSACTION_GROUP_TYPES_FAILURE,
        payload: error
      });
    }
  };
};

export const resetConfirmedTransaction = () => (dispatch) =>
  dispatch({ type: RESET_CONFIRMED_TRANSACTION });
