import React, { useContext, useEffect } from 'react';
import { Button } from 'components';
import { ReactComponent as IconCardActivated } from 'assets/icons/card-activated.svg';
import { ActivatePhysicalCardContext } from '../..';
import { useDispatch } from 'react-redux';
import { getWallets } from 'store/actions/accounts';

export default function CardActivationSuccess() {
  const { onClose } = useContext(ActivatePhysicalCardContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWallets());
  }, []);
  return (
    <div className="w-full flex flex-col items-center gap-y-12">
      <IconCardActivated />
      <p className="text-white text-2xl leading-8 font-medium text-center">
        Norma Card’ınız aktifleştirilmiştir
      </p>
      <Button className="w-full" onClick={onClose}>
        Kapat
      </Button>
    </div>
  );
}
