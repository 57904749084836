import React, { useEffect } from 'react';
import useDengage from 'utils/hooks/useDengage';
import { ReactComponent as CardOrderSuccessIllustration } from 'assets/illustrations/card_order_success.svg';
import { ReactComponent as GooglePlayStore } from 'assets/icons/google-play-store.svg';
import { ReactComponent as AppStore } from 'assets/icons/app-store.svg';
import { Button, InfoMessage } from 'components';
import { useRouter } from 'utils/hooks/useRouter';
import Services from 'views/profile/tabs/services';
import routes from 'router';
import { useDeviceDetection } from 'utils/hooks';
import useGTM from 'utils/hooks/useGTM';

export default function OnboardingCompleteWelcome() {
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();
  const { push, query } = useRouter();
  const { isMobile } = useDeviceDetection();
  const { onboardingMarketplace } = routes;

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'waiting_kyc' });
    sendGTMEvent({ event: 'catEvent', eventName: 'waiting_kyc' });
  }, []);

  const onRedirectToMarketplace = () => {
    console.log('test');
    push(onboardingMarketplace.path);
  };

  return (
    <div className="flex">
      {query?.marketplace && <Services />}
      {!query?.marketplace && (
        <div className="flex flex-col items-center justify-between text-center max-w-md space-y-4 h-full">
          <div className="flex flex-col items-center justify-center space-y-4">
            <CardOrderSuccessIllustration />
            <h3>Tüm Adımları Başarıyla Tamamladınız!</h3>
            <p className="text-gray-500 font-medium leading-6 text-base">
              Sözleşmeniz elinize ulaşana kadar şirketiniz için ihtiyacınız olan hizmet ve
              ürünleri satın alabilirsiniz.
            </p>
            <Button onClick={onRedirectToMarketplace} className="w-full">
              Hizmet ve Ürünleri İncele
            </Button>
          </div>
          <div className="flex flex-col justify-between space-y-6 h-full">
            <InfoMessage>
              Sorularınız için <a href="tel:0(850)2117373">0 (850) 211 73 73</a> numaralı telefon
              üzerinden bizimle iletişime geçebilirsiniz.
            </InfoMessage>
            {isMobile && (
              <a
                className="flex justify-center space-x-6 w-full"
                href="https://app.adjust.com/zgb7wuy?engagement_type=fallback_click"
              >
                <AppStore className="cursor-pointer" />
                <GooglePlayStore className="cursor-pointer" />
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
