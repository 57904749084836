const initialState = {
  categories: [],
  totalElements: 0,
  icons: [],
  colors: []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.data.content,
        totalElements: action.data.totalElements,
        totalPages: action.data.totalPages
      };
    case 'SET_CATEGORY_ICONS':
      return {
        ...state,
        icons: action.data
      };
    case 'SET_CATEGORY_COLORS':
      return {
        ...state,
        colors: action.data
      };
    default:
      return state;
  }
};

export default authReducer;
