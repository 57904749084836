import axios from 'axios';

export const getTaxOffices = () => {
  return (dispatch) => {
    axios.get('/api/tax-offices').then((res) => {
      if (res.status !== 200) return;
      dispatch({
        type: 'SET_TAX_OFFICES',
        data: res.data?.data
      });
    });
  };
};

export const getTaxOfficesByCityId = ({ cityId }) => {
  return () => {
    return axios.get(`/api/tax-offices/${cityId}`);
  };
};
