import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getWalletTypes } from 'store/actions/wallet';
import { useSelector } from 'react-redux';
import { getOpenBankingBankInformations, getOpenBankingBanks } from 'store/actions/openBanking';
import useModal from 'utils/hooks/useModal';

export const initialFormValues = {};

const WalletContext = createContext({
  formValues: {},
  setFormValues: () => {},
  setSelectedWalletType: () => {},
  selectedWalletType: null,
  isWalletSelectionModalActive: false,
  toggleWalletSelectionModal: () => {},
  isOpenBankingCreateModalActive: false,
  toggleOpenBankingCreateModal: () => {},
  walletTypes: [],
  tab: 0,
  setTab: () => {},
  isCreateWalletModalActive: false,
  toggleCreateWalletModal: () => {},
  setSelectedWallet: () => {},
  selectedWallet: null,
  selectedAccount: null,
  setSelectedAccount: () => {}
});

export const useWalletContext = () => useContext(WalletContext);

export default function WalletContextProvider({ children }) {
  const { walletTypes: remoteWalletType } = useSelector((state) => state.wallet);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [selectedWalletType, setSelectedWalletType] = useState();
  const [walletTypes, setWalletTypes] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [tab, setTab] = useState(0);

  const [isWalletSelectionModalActive, , toggleWalletSelectionModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isOpenBankingCreateModalActive, , toggleOpenBankingCreateModal] = useModal({
    shouldBeBlockScroll: true
  });

  const [isCreateWalletModalActive, , toggleCreateWalletModal] = useModal({
    shouldBeBlockScroll: true
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletTypes());
    dispatch(getOpenBankingBanks());
    dispatch(getOpenBankingBankInformations());
  }, []);

  useEffect(() => {
    if (
      remoteWalletType?.bankAccountList?.length > 0 ||
      remoteWalletType?.impersonalAccountList?.length > 0
    ) {
      const types = [
        ...remoteWalletType.bankAccountList,
        ...remoteWalletType.impersonalAccountList
      ];
      setSelectedWalletType(types[0]?.type);
      setWalletTypes(types);
    }
  }, [remoteWalletType]);

  return (
    <WalletContext.Provider
      value={{
        formValues,
        setFormValues,
        setSelectedWalletType,
        selectedWalletType,
        walletTypes,
        isWalletSelectionModalActive,
        toggleWalletSelectionModal,
        isOpenBankingCreateModalActive,
        toggleOpenBankingCreateModal,
        tab,
        setTab,
        isCreateWalletModalActive,
        toggleCreateWalletModal,
        selectedWallet,
        setSelectedWallet,
        selectedAccount,
        setSelectedAccount
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}
