import { Popover } from '@headlessui/react';
import { ReactComponent as IconCaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as IconBank } from 'assets/icons/bank.svg';

import { currencyFormat } from 'utils';
import classNames from 'classnames';

const SelectAccount = ({ accounts, selectedAccount, setSelectedAccount, className, icon }) => {
  const containerClassName = classNames(
    'rounded-xl border border-solid border-gray-200 relative inline-block w-full bg-gray-100 mt-4 mb-14 cursor-pointer',
    className
  );
  function handleSelectedAccount(account, close) {
    setSelectedAccount(account);
    close();
  }

  return (
    <Popover as="div" className={containerClassName}>
      {({ close }) => (
        <>
          {selectedAccount ? (
            <SelectedAccountPreview selectedAccount={selectedAccount} icon={icon} />
          ) : (
            <SelectAccountButton />
          )}
          <SelectAccountPanel
            icon={icon}
            accounts={accounts}
            handleSelectedAccount={(account) => handleSelectedAccount(account, close)}
          />
        </>
      )}
    </Popover>
  );
};

const SelectedAccountPreview = ({ selectedAccount, icon }) => (
  <Popover.Button as="div" className="flex items-center w-full px-4 py-6 z-30">
    <div className="flex items-center justify-center w-11 h-11 rounded-full bg-green-300 mr-4">
      {icon || <IconBank className="fill-current text-green-700" />}
    </div>
    <span className="text-gray-900">{selectedAccount?.name || ''}</span>
    <div className="flex gap-x-4 ml-auto">
      <span className="text-gray-900 text-xl">
        {currencyFormat(selectedAccount?.balance?.balance) + ' TL' || ''}
      </span>
      <IconCaretDown className="stroke-current w-7 text-gray-500" />
    </div>
  </Popover.Button>
);

const SelectAccountButton = () => (
  <Popover.Button as="div" className="flex items-center px-4 py-6 z-30">
    <span className="font-semibold text-gray-900">Hesap Seç</span>
    <IconCaretDown className="stroke-current w-7 text-gray-500 ml-auto" />
  </Popover.Button>
);

const SelectAccountPanel = ({ accounts, handleSelectedAccount, icon }) => (
  <Popover.Panel className="absolute top-20 left-0 w-full bg-white rounded-xl border border-solid border-gray-200 p-4 mt-4 h-auto z-50">
    <div className="flex flex-col overflow-y-scroll h-auto">
      {accounts.map((a, i) => (
        <div
          className={`${
            accounts.length > i + 1 && 'border-b border-solid border-gray-100'
          } flex items-center cursor-pointer py-4`}
          onClick={() => handleSelectedAccount(a)}
          key={i}
        >
          <div className="flex items-center justify-center h-11 w-11 rounded-full bg-green-300 text-white mr-4">
            {icon || <IconBank className="fill-current text-green-700" />}
          </div>
          <div className="flex flex-col justify-center">
            <span className="text-gray-700 font-semibold">{a.name}</span>
            <span className="text-gray-500 text-sm">{a.number}</span>
          </div>
          <span className="text-xl text-gray-900 ml-auto">
            {currencyFormat(a?.balance?.balance)} TL
          </span>
        </div>
      ))}
    </div>
  </Popover.Panel>
);

export default SelectAccount;
