import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCompany, onboardingCompany } from 'store/actions/company';
import errorCodes from 'utils/errorCodes';
import { getUserData } from 'store/actions/auth';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import useModal from 'utils/hooks/useModal';
import { useRouteStep } from 'utils/hooks/useRouteStep';
import { useRouter } from 'utils/hooks/useRouter';

import { COMPANY_SUB_TYPES, ONBOARDING_COMPANY_FLOW_TYPE } from 'utils';

import { CompanyType, CompanyInformation, CompanySector, AddCompanySuccessModal } from './steps';

const defaultValues = {
  address: '',
  businessInfo: '',
  cityId: null,
  companyAddressType: 'REAL',
  companySubType: COMPANY_SUB_TYPES.SOLE_TRADER,
  countryId: 1,
  districtId: null,
  name: '',
  profession: '',
  sector: '',
  taxIdentityNumber: '',
  taxOfficeId: null,
  virtualOfficeId: null
};

const AddCompany = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useSelector((state) => state.auth);
  const { sendGTMEvent } = useGTM();
  const { publishEvent, eventTypes } = useDengage();

  const [addCompanySuccessModal, , toggleAddCompanySuccessModal] = useModal({
    shouldBeBlockScroll: true,
    handleClose: async () => {
      await dispatch(getCompany());
      history.push('/');
    }
  });

  const [step, nextStep] = useRouteStep(
    {
      router,
      max: 3,
      fallbackPath: '/company-options'
    },
    (step) => nextStep(step)
  );

  const {
    watch,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    setValue('companyName', `${user.firstName} ${user.lastName}`);
  }, [user]);

  function onSubmit(data) {
    data = {
      ...data,
      name: data?.companyName,
      companyAddressType: 'REAL',
      flowType: ONBOARDING_COMPANY_FLOW_TYPE.ADD
    };

    dispatch(onboardingCompany(data))
      .then(() => success())
      .catch((err) => {
        nextStep(0);
        if (err[errorCodes.invalidIdentityNumber]) {
          setError('taxIdentityNumber', {
            type: 'invalid',
            message: err[errorCodes.invalidIdentityNumber]
          });
        }
        if (err[errorCodes.thereIsCompanyWithSameName]) {
          setError('companyName', {
            type: 'invalid',
            message: err[errorCodes.thereIsCompanyWithSameName]
          });
        }
        console.log(err);
      });
  }

  async function success() {
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'add_a_company'
    });
    publishEvent(eventTypes.pageView, { page_type: 'add_company_activity_completed' });
    await dispatch(getUserData());
    await dispatch(getCompany());
    history.push('/');
  }

  const STEPS = [
    <CompanyType
      key={'Step0'}
      setValue={setValue}
      watch={watch}
      nextStep={nextStep}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
    />,
    <CompanyInformation
      key={'Step1'}
      setValue={setValue}
      watch={watch}
      nextStep={nextStep}
      errors={errors}
      setError={setError}
      clearErrors={clearErrors}
    />,
    <CompanySector key={'Step2'} setValue={setValue} watch={watch} />
  ];

  return (
    <>
      {addCompanySuccessModal && (
        <AddCompanySuccessModal handleClose={toggleAddCompanySuccessModal} success={success} />
      )}

      <form
        className="w-full flex items-center md:justify-center max-w-[400px] md:flex-1"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="form_add_company"
      >
        {STEPS[step]}
      </form>
    </>
  );
};

export default AddCompany;
