import { CustomToaster } from 'components';
import { OnboardingContainer } from 'containers';

const FullScreenLayout = ({ children }) => (
  <OnboardingContainer>
    {children}
    <CustomToaster />
  </OnboardingContainer>
);

export default FullScreenLayout;
