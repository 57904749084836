import {
  CustomButton,
  SideModal,
  DatePicker,
  RadioButton,
  DropdownSelect,
  Checkbox
} from 'components';
import { useState, useEffect } from 'react';

import { PAYMENT_METHODS, PAYMENT_METHOD_TITLES, RECORD_TYPES } from '../enums';

import { useSelector } from 'react-redux';
import { getContacts } from 'store/actions/contacts';

import { ReactComponent as IconEndDueDate } from 'assets/illustrations/filters/end-route.svg';
import { ReactComponent as IconStartDueDate } from 'assets/illustrations/filters/start-route.svg';
import { useDispatch } from 'react-redux';
import {
  COMPANY_TYPES,
  getFormattedDateWithSeperator,
  PAYMENT_STATUS,
  PAYMENT_STATUS_TEXT
} from 'utils';

const FiltersModal = ({
  handleClose,
  filters: currentFilters = [],
  handleFilters = () => {},
  categories,
  onFetch = () => {}
}) => {
  const [filters, setFilters] = useState(currentFilters || []);
  const [filterCount, setFilterCount] = useState(0);

  const dispatch = useDispatch();
  const { contacts } = useSelector((state) => state.contacts);

  function clearFilters() {
    setFilters([]);
    // handleFilters([]);
    // onFetch();
    // handleClose();
  }

  const RECORD_TYPES_MAPS = Object.entries(RECORD_TYPES).map(([_, value]) => ({
    label: RECORD_TYPES.SALES === value ? 'Satışlar' : 'Giderler',
    value
  }));

  const INVOICE_EXIST = [
    {
      label: 'Fatura Var',
      value: true
    },
    {
      label: 'Fatura Yok',
      value: false
    }
  ];

  const PAYMENT_METHOD_MAPS = Object.values(PAYMENT_METHODS).map((method) => ({
    label: PAYMENT_METHOD_TITLES[method],
    value: method
  }));

  const PAYMENT_STATUS_MAPS = Object.values(PAYMENT_STATUS).map((status) => ({
    label: PAYMENT_STATUS_TEXT[status],
    value: status
  }));

  useEffect(() => {
    const count = Object.entries(filters).filter(([key, value]) => {
      if (key === 'hasInvoice' && value !== null) return true;
      return value;
    }).length;
    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    dispatch(getContacts());
  }, []);

  const onStartDueDateChanged = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      // endDueDate: prevState.date?.endDueDate || new Date(),
      startDueDate: date
    }));
  };

  const onEndDueDateChanged = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      // startDueDate: prevState.date?.startDueDate || new Date(),
      endDueDate: date
    }));
  };
  const onStartInvoiceDateChanged = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      // endInvoiceDate: prevState.date?.endInvoiceDate || new Date(),
      startInvoiceDate: date
    }));
  };

  const onEndInvoiceDateChanged = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      // startInvoiceDate: prevState.date?.startInvoiceDate || new Date(),
      endInvoiceDate: date
    }));
  };

  const onStartPaymentDateChanged = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      // endPaymentDate: prevState.date?.endPaymentDate || new Date(),
      startPaymentDate: date
    }));
  };

  const onEndPaymentDateChanged = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      // startPaymentDate: prevState.date?.startPaymentDate || new Date(),
      endPaymentDate: date
    }));
  };

  const onChangeRecordType = (recordType) => {
    setFilters((prevState) => ({
      ...prevState,
      recordType
    }));
  };

  const onChangeCategory = (selectedCategory) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedCategory
    }));
  };
  const onChangeContact = (selectedContact) => {
    setFilters((prevState) => ({
      ...prevState,
      selectedContact
    }));
  };

  const onChangePaymentStatus = (paymentStatus) => {
    setFilters((prevState) => ({
      ...prevState,
      paymentStatus
    }));
  };

  const onChangeHasInvoice = (hasInvoice) => {
    setFilters((prevState) => ({
      ...prevState,
      hasInvoice
    }));
  };

  const onChangePaymentMethod = (paymentMethod) => {
    setFilters((prevState) => ({
      ...prevState,
      paymentMethod
    }));
  };

  const onApplyFilters = () => {
    handleFilters({
      ...filters
    });
    handleClose();
  };

  const CustomOption = ({ value, label, setValue, getValue, innerProps }) => {
    const [selectedItem, setSelectedItem] = useState();
    useEffect(() => setSelectedItem(getValue()[0]), []);
    return (
      <div
        className="!hover:bg-none border-b border-solid border-gray-100 mx-5 text-base text-gray-800 leading-6 flex justify-between"
        onClick={() => setValue(value)}
        {...innerProps}
      >
        <span>{label}</span>
        <Checkbox checked={selectedItem?.value === value} />
      </div>
    );
  };
  return (
    <SideModal handleClose={handleClose} className="min-h-screen">
      <div className="flex flex-col gap-y-12 pb-4 overflow-y-auto px-4 w-full">
        <span className="text-3xl font-semibold text-gray-900">Filtrele</span>
        <FilterItem title="Kayıt Tipi">
          <RadioButton
            value={filters?.recordType}
            className="flex gap-x-4"
            onChange={onChangeRecordType}
          >
            {RECORD_TYPES_MAPS.map(({ label, value }, index) => (
              <RadioButton.Item value={value} label={label} key={index} />
            ))}
          </RadioButton>
        </FilterItem>
        <FilterItem title="Vade Tarihi">
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <IconStartDueDate className="w-8" />
                <span className="ml-4" data-testid="label_filters_modal_start_due_date">
                  Başlangıç
                </span>
              </div>
              <div className="w-[50px]]">
                <DatePicker
                  className="font-semibold"
                  selected={filters?.startDueDate}
                  onChange={onStartDueDateChanged}
                  placeholder={getFormattedDateWithSeperator(new Date())}
                />
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <div className="flex items-center">
                <IconEndDueDate className="w-8" />
                <span className="ml-4" data-testid="label_filters_modal_end_due_date">
                  Bitiş
                </span>
              </div>
              <div className="w-[50px]]">
                <DatePicker
                  className="font-semibold"
                  selected={filters?.endDueDate}
                  onChange={onEndDueDateChanged}
                  placeholder={getFormattedDateWithSeperator(new Date())}
                />
              </div>
            </div>
          </div>
        </FilterItem>
        <FilterItem title="Fatura Tarihi">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-4">
              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <IconStartDueDate className="w-8" />
                  <span className="ml-4" data-testid="label_filters_modal_start_invoice_date">
                    Başlangıç
                  </span>
                </div>
                <div className="w-[50px]]">
                  <DatePicker
                    className="font-semibold"
                    selected={filters?.startInvoiceDate}
                    onChange={onStartInvoiceDateChanged}
                    placeholder={getFormattedDateWithSeperator(new Date())}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <IconEndDueDate className="w-8" />
                  <span className="ml-4" data-testid="label_filters_modal_end_invoice_date">
                    Bitiş
                  </span>
                </div>
                <div className="w-[50px]]">
                  <DatePicker
                    className="font-semibold"
                    selected={filters?.endInvoiceDate}
                    onChange={onEndInvoiceDateChanged}
                    placeholder={getFormattedDateWithSeperator(new Date())}
                  />
                </div>
              </div>
            </div>
            {/* <DatePicker selected={invoiceDate} onChange={onChangeInvoiceDate} isFormInput /> */}
          </div>
        </FilterItem>
        <FilterItem title="Fatura Var/Yok">
          <div className="flex flex-col gap-y-4">
            <RadioButton
              value={filters?.hasInvoice}
              className="flex gap-x-4"
              onChange={onChangeHasInvoice}
            >
              {INVOICE_EXIST.map(({ label, value }, index) => (
                <RadioButton.Item value={value} label={label} key={index} />
              ))}
            </RadioButton>
          </div>
        </FilterItem>
        <FilterItem title="Ödeme Tarihi">
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-4">
              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <IconStartDueDate className="w-8" />
                  <span className="ml-4" data-testid="label_filters_modal_star_payment_date">
                    Başlangıç
                  </span>
                </div>
                <div className="w-[50px]]">
                  <DatePicker
                    className="font-semibold"
                    selected={filters?.startPaymentDate}
                    onChange={onStartPaymentDateChanged}
                    placeholder={getFormattedDateWithSeperator(new Date())}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="flex items-center">
                  <IconEndDueDate className="w-8" />
                  <span className="ml-4" data-testid="label_filters_modal_end_payment_date">
                    Bitiş
                  </span>
                </div>
                <div className="w-[50px]]">
                  <DatePicker
                    className="font-semibold"
                    selected={filters?.endPaymentDate}
                    onChange={onEndPaymentDateChanged}
                    placeholder={getFormattedDateWithSeperator(new Date())}
                  />
                </div>
              </div>
            </div>
            {/* <DatePicker selected={startPaymentDate} onChange={onChangestartPaymentDate} isFormInput /> */}
          </div>
        </FilterItem>
        <FilterItem title="Ödeme Durumu">
          <div className="flex flex-col gap-y-4">
            <RadioButton
              value={filters?.paymentStatus}
              className="flex gap-x-4"
              onChange={onChangePaymentStatus}
            >
              {PAYMENT_STATUS_MAPS.map(({ label, value }, index) => (
                <RadioButton.Item value={value} label={label} key={index} />
              ))}
            </RadioButton>
          </div>
        </FilterItem>
        <FilterItem title="Ödeme Metodu">
          <div className="flex flex-col gap-y-4">
            <DropdownSelect
              options={PAYMENT_METHOD_MAPS}
              defaultValue={filters?.paymentMethod}
              value={filters?.paymentMethod}
              placeholder="Ödeme Metodu Seç"
              onChange={onChangePaymentMethod}
              isSearchable
              customOptionComponent={{ Option: CustomOption }}
            />
          </div>
        </FilterItem>
        <FilterItem title="Müşteri/Tedarikçi">
          <div className="flex flex-col gap-y-4">
            <DropdownSelect
              options={contacts.map((contact) => ({
                label:
                  contact?.companyType === COMPANY_TYPES.BUSINESS
                    ? contact?.companyName
                    : `${contact?.firstName} ${contact?.lastName}`,
                value: contact?.id
              }))}
              defaultValue={filters?.selectedContact}
              value={filters?.selectedContact}
              placeholder="Müşteri/Tedarikçi Seç"
              onChange={onChangeContact}
              isSearchable
              customOptionComponent={{ Option: CustomOption }}
            />
          </div>
        </FilterItem>
        <FilterItem title="Kategoriler">
          <div className="flex flex-col gap-y-4">
            <DropdownSelect
              options={categories.map((category) => ({
                label: category?.name,
                value: category?.id
              }))}
              defaultValue={filters?.selectedCategory}
              value={filters?.selectedCategory}
              placeholder="Kategori Seç"
              onChange={onChangeCategory}
              isSearchable
              customOptionComponent={{ Option: CustomOption }}
            />
            {/* {categories.map((c) => (
              <div
                className="px-3 py-1 rounded-full relative cursor-pointer flex items-center gap-x-2"
                style={{ backgroundColor: c?.colorHexCode }}
                onClick={() => onChangeCategory(c.id)}
              >
                {c?.iconUrl && <img src={c.iconUrl} alt="icon" className="w-4" />}
                <span>{c.name}</span>
                {c.id === filters?.categoryId && (
                  <div className="p-1 rounded-full border-2 border-solid border-white bg-green-500 absolute -right-2 -top-2">
                    <IconCheck className="stroke-current w-3 text-white" />
                  </div>
                )}
              </div>
            ))} */}
          </div>
        </FilterItem>
        <div className="flex flex-col gap-y-4">
          <CustomButton color="success" onClick={onApplyFilters}>
            Uygula {`(${filterCount})`}
          </CustomButton>
          <CustomButton color="primary" onClick={clearFilters}>
            Temizle
          </CustomButton>
        </div>
      </div>
    </SideModal>
  );
};

const FilterItem = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-y-4 w-full">
      <span className="font-normal text-purple-700">{title}</span>
      {children}
    </div>
  );
};

export default FiltersModal;
