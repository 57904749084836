import React, { useEffect } from 'react';

import Logo from 'assets/icons/logo.svg';
import { CustomButton, CustomInput, InfoMessage, Select } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  getCities,
  getCountries,
  getDistrict,
  getRegions,
  getStreets
} from 'store/actions/country';
import { onboardingDocuments } from 'store/actions/card';
import { getUserData } from 'store/actions/auth';
import useDengage from 'utils/hooks/useDengage';
import useGTM from 'utils/hooks/useGTM';
import { useRouter } from 'utils/hooks/useRouter';
import routes from 'router';

export default function AgreementAddress() {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();
  const { sendGTMEvent } = useGTM();
  const { push } = useRouter();

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'company_agreement_address' });
  }, []);

  const { countries, cities, districts, regions, streets } = useSelector(
    (state) => state.country
  );

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty }
  } = useForm();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getCities({ country: 'TR' }));
  }, []);

  const onCityChange = (cityId) => {
    setValue('cityId', cityId);
    dispatch(getDistrict({ cityId }));
  };
  const onChangeDistrict = (districtId) => {
    setValue('districtId', districtId);
    dispatch(getRegions(watch('cityId'), districtId));
  };
  const onChangeRegion = (regionId) => {
    setValue('regionId', Number(regionId));
    dispatch(getStreets(watch('cityId'), watch('districtId'), regionId));
  };
  // const onCountryChange = (countryId) => setValue('countryId', countryId);
  const onAddressChange = (e) => setValue('address', e.target.value);

  const onSubmit = (data) => {
    if (data) {
      const payload = {
        ...data,
        address: data.address.trim(),
        countryId: data.countryId || countries[0].id,
        districtId: parseInt(data.districtId),
        cityId: parseInt(data.cityId)
      };
      dispatch(onboardingDocuments(payload)).then(() => {
        dispatch(getUserData()).then(() => {
          push(routes.accessRouter.path);
        });
      });
    }
    sendGTMEvent({
      event: 'catEvent',
      eventName: 'company_agreement_address_info_completed'
    });
  };

  return (
    <div className="flex" data-testid="section_agreement_address">
      <div className="w-full h-screen flex justify-between max-w-md">
        <div className="w-full flex items-center justify-center">
          <div>
            <Header
              title="Adres Belirle"
              subtitle="Sözleşme teslimatı için adresinizi giriniz"
              data-testid="section_header_agreement_address"
            />
            <form
              className="flex flex-col space-y-4"
              onSubmit={handleSubmit(onSubmit)}
              data-testid="form_agreement_address"
            >
              {/* <Select
                options={countries}
                value="id"
                valueLabel="name"
                label="Ülke"
                defaultValue={1}
                disabled
                onChange={onCountryChange}
                data-testid="select_agreement_address_country"
              /> */}
              <Select
                options={cities}
                value="id"
                valueLabel="name"
                label="İl"
                error={isDirty && !watch('cityId') && 'İl bilgisi eksik'}
                onChange={onCityChange}
                data-testid="select_agreement_address_city"
              />
              <Select
                options={districts[watch('cityId')]}
                value="id"
                valueLabel="name"
                label="İlçe"
                error={isDirty && !watch('districtId') && 'İlçe bilgisi eksik'}
                onChange={onChangeDistrict}
                data-testid="select_agreement_address_district"
              />
              <Select
                options={regions}
                value="id"
                valueLabel="name"
                label="Mahalle/Köy"
                error={isDirty && !watch('regionId') && 'Mahalle/Köy bilgisi eksik'}
                onChange={onChangeRegion}
                data-testid="select_agreement_address_region"
              />
              <Select
                options={streets}
                value="id"
                valueLabel="name"
                label="Cadde/Sokak"
                error={isDirty && !watch('streetId') && 'Cadde/Sokak bilgisi eksik'}
                onChange={(value) => setValue('streetId', Number(value))}
                data-testid="select_agreement_address_street"
              />
              <CustomInput
                label="Adres (Bina No, Daire No)"
                onChange={onAddressChange}
                error={isDirty && !watch('address') && 'Adres bilgisi eksik'}
                data-testid="input_agreement_address_address"
              />
              {/* <CustomInput
                label="Adres 2"
                onChange={onSecondAddressChange}
                data-testid="input_agreement_address_address2"
              /> */}
              <CustomButton
                type="submit"
                color="success"
                disabled={
                  !watch('cityId') ||
                  !watch('districtId') ||
                  !watch('regionId') ||
                  !watch('streetId') ||
                  !watch('address') ||
                  watch('address')?.length < 3
                }
                data-testid="button_agreement_address_submit"
              >
                Devam
              </CustomButton>
              <InfoMessage>
                5549 sayılı kanun gereğince taraflar arasında ıslak imzalı sözleşme akdedilmesi
                gerekmektedir.
              </InfoMessage>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const Header = ({ title, subtitle, ...props }) => (
  <div className="flex flex-col my-4" {...props}>
    <a href="/" title="Norma">
      <img src={Logo} alt="logo" width="35" />
    </a>
    <span
      className="text-gray-400 mt-6 font-semibold"
      data-testid="section_header_agreement_address_title"
    >
      {title}
    </span>
    <span
      className="text-xl font-semibold text-gray-900"
      data-testid="section_header_agreement_address_subtitle"
    >
      {subtitle}
    </span>
  </div>
);
