import { useState, useEffect, useRef } from 'react';

import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as IconInvoice } from 'assets/icons/invoice.svg';
import { ReactComponent as IconBuilding } from 'assets/icons/building-2.svg';
// import { ReactComponent as IconWallet } from 'assets/icons/wallet.svg';
import { ReactComponent as IconSubtitle } from 'assets/icons/subtitles.svg';
import { ReactComponent as IconDotsVertical } from 'assets/icons/dots-vertical.svg';
import { ReactComponent as IconLock } from 'assets/icons/lock.svg';
import { ReactComponent as IconSignout } from 'assets/icons/signout.svg';
import { ReactComponent as IconBoxes } from 'assets/icons/boxes.svg';

import PersonalInfo from './tabs/personalnfo';
import CompanyInfo from './tabs/companyInfo';
import EFinance from './tabs/efinanceInfo';
import ChangePassword from './tabs/changePassword';

import { BottomModal, DropdownMenu } from 'components';
import { NavLink, Route, Switch } from 'react-router-dom';

import NotFound from 'views/misc/NotFound';
// import WalletInfo from './tabs/walletInfo';
import Services from './tabs/services';
import ServiceDetail from './serviceDetail';

import useModal from 'utils/hooks/useModal';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { logout } from 'store/actions/auth';
import { useDispatch } from 'react-redux';
import { useRouter } from 'utils/hooks/useRouter';
import ArchivedWallets from './tabs/archivedWallets';

const Profile = () => {
  const [isModalActive, setIsModalActive] = useState(false);
  function toggleModal() {
    setIsModalActive(!isModalActive);
  }

  return (
    <div className="relative bg-gray-200 flex flex-col justify-start items-center py-12 min-h-screen">
      <div className="absolute top-0 left-0 h-72 w-full z-0 bg-gray-900" />
      <div className="relative flex flex-col justify-start items-start w-11/12 xl:w-3/4 h-auto">
        <span className="text-white font-semibold text-xl">Profil ve Ayarlar</span>
        <TabsCard
          className="flex flex-row justify-center items-start space-x-8 w-full mt-6"
          isModalActive={isModalActive}
          toggleModal={toggleModal}
        />
      </div>
    </div>
  );
};

const TabsCard = ({ defaultTab = 0, className, isModalActive, toggleModal }) => {
  const [, setTab] = useState(defaultTab);
  const [modalIndex, setModalIndex] = useState(4);
  const [resetPasswordStep, setResetPasswordStep] = useState(0);

  const moreMenuRef = useRef(null);

  const { push, match } = useRouter();

  const dispatch = useDispatch();

  const [isMoreMenuActive, setIsMoreMenuActive, toggleMoreMenu] = useModal();
  const [isResetPasswordActive, setIsResetPasswordActive, toggleResetPassword] = useModal();

  useOnClickOutside(moreMenuRef, () => setIsMoreMenuActive(false));

  useEffect(() => {
    if (location.pathname === '/profile/reset-password') setIsResetPasswordActive(true);
  }, [location.pathname]);

  useEffect(() => {
    if (!isResetPasswordActive && location.pathname === '/profile/reset-password')
      push('/profile');
  }, [isResetPasswordActive]);

  const moreMenuItems = [
    {
      title: 'Şifre Değiştir',
      icon: IconLock,
      path: '/profile/reset-password',
      onClick: () => {
        toggleResetPassword();
        setIsMoreMenuActive(false);
      }
    },
    {
      title: 'Çıkış Yap',
      icon: IconSignout,
      path: '/welcome',
      customClass: '!text-red-500',
      onClick: () => dispatch(logout())
    }
  ];

  const handleTabs = (i) => {
    if (tabs[i].disabled) return;

    if (tabs[i].modal) {
      setModalIndex(i);
      toggleModal();
    } else {
      setTab(i);
    }
  };

  return (
    <div className={`${className} h-auto bg-white shadow-md rounded-xl`}>
      {isModalActive && (
        <BottomModal handleClose={toggleModal}>{tabs[modalIndex].page}</BottomModal>
      )}
      {isResetPasswordActive && (
        <BottomModal
          handleClose={toggleResetPassword}
          className={resetPasswordStep === 3 && 'bg-green-500'}
        >
          <ChangePassword setResetPasswordStep={setResetPasswordStep} />
        </BottomModal>
      )}
      <div className="w-full">
        <div className="w-full px-8 flex items-center justify-between">
          <div className="w-full flex items-center border-b border-solid border-gray-100 text-black text-sm space-x-8 overflow-x-auto pr-6">
            {tabs.map((t, i) => {
              return (
                <NavLink
                  exact={i === 0}
                  to={t.path}
                  key={i}
                  className={`text-gray-500 fill-gray-500 solid-gray-500 w-min min-w-max font-medium pb-3 pt-5 h-auto flex items-center justify-center gap-x-3 cursor-pointer select-none`}
                  activeClassName="border-b border-solid border-green-500 text-green-500"
                  onClick={() => handleTabs(i)}
                >
                  {t.icon}
                  {t.title}
                </NavLink>
              );
            })}
          </div>

          <DropdownMenu isActive={isMoreMenuActive} items={moreMenuItems} ref={moreMenuRef}>
            <div
              className="rounded-full bg-gray-100 w-8 h-8 flex items-center justify-center cursor-pointer"
              onClick={toggleMoreMenu}
            >
              <IconDotsVertical className="fill-current text-gray-500 w-4" />
            </div>
          </DropdownMenu>
        </div>
        <div className="w-full mt-4 px-12 py-10">
          <Switch>
            <Route exact path={`${match.path}`}>
              <PersonalInfo />
            </Route>
            <Route exact path={`${match.path}/company`}>
              <CompanyInfo />
            </Route>
            <Route exact path={`${match.path}/e-invoice`}>
              <EFinance />
            </Route>
            <Route exact path={`${match.path}/services`}>
              <Services />
            </Route>
            <Route exact path={`${match.path}/services/:id`}>
              <ServiceDetail />
            </Route>
            <Route exact path={`${match.path}/archived-wallets`}>
              <ArchivedWallets />
            </Route>
            {/* <Route exact path={`${match.path}/wallet`}>
              <WalletInfo />
            </Route> */}
            <Route exact path={`${match.path}/reset-password`}>
              <></>
            </Route>
            <Route exact path={'*'}>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

const tabs = [
  {
    title: 'Kişisel Bilgiler',
    icon: <UserIcon className="fill-current w-4" />,
    page: <PersonalInfo />,
    path: '/profile'
  },
  {
    title: 'Şirket Bilgileri',
    icon: <IconBuilding className="fill-current w-4" />,
    page: <CompanyInfo />,
    path: '/profile/company'
  },
  {
    title: 'e-Dönüşüm Bilgileri',
    icon: <IconInvoice className="fill-current w-4" />,
    page: <EFinance />,
    path: '/profile/e-invoice'
  },
  {
    title: 'Hizmet ve Ürünler',
    icon: <IconSubtitle className="fill-current w-4" />,
    page: <EFinance />,
    path: '/profile/services'
  },
  {
    title: 'Arşivlenmiş Hesaplar',
    icon: <IconBoxes className="fill-current w-4" />,
    page: <ArchivedWallets />,
    path: '/profile/archived-wallets'
  }
  // {
  //   title: 'Cüzdan Limiti',
  //   icon: <IconWallet className="fill-current w-4" />,
  //   page: <WalletInfo />,
  //   path: '/profile/wallet'
  // }
];

export default Profile;
