import React from 'react';
import { Helmet } from 'react-helmet';

const DEFAULT_TITLE = 'Norma App';
const DEFAULT_DESCRIPTION = 'Norma Web App';

const Seo = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  isNoIndex,
  canonicalUrl,
  customMetaTags
}) => {
  const dengageAccountId =
    process.env?.REACT_APP_DENGAGE_PUBLIC_ID_DEV || process.env?.REACT_APP_DENGAGE_PUBLIC_ID_PROD;

  return (
    <Helmet>
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="manifest" href="/manifest.json" />
      {isNoIndex ? <meta name="robots" content="noindex" /> : null}
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {customMetaTags && customMetaTags}
      <script>
        {`
          !function(e,a,n,d,g){e.dengage=e.dengage||function(){(e.dengage.q=e.dengage.q||[]).push(arguments)},d=a.getElementsByTagName("head")[0],(g=a.createElement("script")).async=1,g.src="https://pcdn.dengage.com/p/push/288/${dengageAccountId}/dengage_sdk.js",d.appendChild(g)}(window,document);

          dengage('initialize');
        `}
      </script>
      {/* Adjust */}
      <script type="application/javascript">
        {`
          !function(t,e,a,r,n,s,d,l,o,i,u){t.Adjust=t.Adjust||{},t.Adjust_q=t.Adjust_q||[];for(var c=0;c<l.length;c++)o(t.Adjust,t.Adjust_q,l[c]);i=e.createElement(a),u=e.getElementsByTagName(a)[0],i.async=!0,i.src="https://cdn.adjust.com/adjust-latest.min.js",i.onload=function(){for(var e=0;e<t.Adjust_q.length;e++)t.Adjust[t.Adjust_q[e][0]].apply(t.Adjust,t.Adjust_q[e][1]);t.Adjust_q=[]},u.parentNode.insertBefore(i,u)}(window,document,"script",0,0,0,0,["initSdk","getAttribution","getWebUUID","setReferrer","trackEvent","addGlobalCallbackParameters","addGlobalPartnerParameters","removeGlobalCallbackParameter","removeGlobalPartnerParameter","clearGlobalCallbackParameters","clearGlobalPartnerParameters","switchToOfflineMode","switchBackToOnlineMode","stop","restart","gdprForgetMe","disableThirdPartySharing","initSmartBanner","showSmartBanner","hideSmartBanner"],(function(t,e,a){t[a]=function(){e.push([a,arguments])}}));
        `}
      </script>
    </Helmet>
  );
};

Seo.defaultProps = {
  title: null,
  description: null,
  isNoIndex: null,
  canonicalUrl: null,
  customMetaTags: null
};

export default Seo;
