import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const AmountInput = ({ amount, setAmount }) => {
  const [currencyAmount, setCurrencyAmount] = useState(amount);

  function handleInput(val) {
    setCurrencyAmount(val);
    const intAmount = val ? Number(val.replaceAll('.', '').replaceAll(',', '.')) : 0;
    setAmount(intAmount);
  }

  return (
    <div className="flex flex-col">
      <span className="text-gray-900 font-semibold text-lg">Tutar</span>
      <div
        className={`${
          amount > 0 ? 'text-gray-900' : 'text-gray-400'
        } w-full rounded-lg border border-solid border-gray-200 flex justify-between items-center px-14 py-6 mt-4`}
      >
        <CurrencyInput
          id="input-example"
          name="input-name"
          placeholder="0,00"
          decimalsLimit={2}
          decimalSeparator=","
          groupSeparator="."
          disableGroupSeparators={false}
          onValueChange={(value) => handleInput(value)}
          value={currencyAmount}
          className="text-3xl font-semibold w-full focus:outline-none mr-1 text-right"
        />
        <span className="text-2xl font-semibold">TL</span>
      </div>
    </div>
  );
};

export default AmountInput;
