import React, { useState } from 'react';
import cn from 'classnames';

const PaymentMethodSelectionTabs = ({ tabs = [], defaultTab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  if (tabs.length === 0) return <div>Lütfen Tablarınızı ekleyin</div>;

  const Tab = ({ children, isSelected, id, onClick, ...props }) => {
    const classNames = cn('flex-1 border-b-2 border-solid pb-2 border-gray-200 text-gray-500', {
      ['!border-green-500 !text-green-500']: isSelected,
      ['cursor-pointer']: !isSelected,
      ['cursor-not-allowed']: tabs[id]?.disabled
    });

    return (
      <div className={classNames} {...props} onClick={onClick}>
        {children}
      </div>
    );
  };

  const onSelected = (i) => {
    if (tabs[i].disabled) return;
    if (i !== selectedTab) {
      setSelectedTab(i);
    }
  };
  return (
    <div>
      <div className="flex w-full justify-between text-center">
        {tabs.map((tab, index) => {
          const isSelected = index === selectedTab;
          return (
            <Tab key={index} id={index} isSelected={isSelected} onClick={() => onSelected(index)}>
              {tab.title}
            </Tab>
          );
        })}
      </div>
      <div className="pt-7">{tabs[selectedTab].content}</div>
    </div>
  );
};
export default PaymentMethodSelectionTabs;
