import { BottomModal, Button } from 'components';
import { useSmartOperationContext } from '../..';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import { useEffect } from 'react';
import { getContacts } from 'store/actions/contacts';
import { useDispatch } from 'react-redux';

export default function AutoBillPaymentModal({ steps = [], className, onClose }) {
  const dispatch = useDispatch();

  const { step, previousStep, showBackButton, resetAutomaticTransactionFormValues } =
    useSmartOperationContext();

  useEffect(() => {
    dispatch(
      getContacts({
        size: 1000
      })
    );
    return () => {
      resetAutomaticTransactionFormValues();
    };
  }, []);

  return (
    <BottomModal isActive handleClose={onClose} className={className}>
      <div className="h-12 m-6">
        {showBackButton && step > 0 && (
          <Button variant="circle" color="gray" icon={IconArrowLeft} onClick={previousStep} />
        )}
      </div>
      <div className="flex flex-col items-center justify-center h-full overflow-y-auto">
        <div className="w-full h-full flex max-w-[640px] mx-auto">{steps[step]}</div>
      </div>
    </BottomModal>
  );
}
