export default {
  GET_OPEN_BANKING_BANKS: 'GET_OPEN_BANKING_BANKS',
  GET_OPEN_BANKING_BANKS_SUCCESS: 'GET_OPEN_BANKING_BANKS_SUCCESS',
  GET_OPEN_BANKING_BANKS_FAILURE: 'GET_OPEN_BANKING_BANKS_FAILURE',
  ADD_OPEN_BANKING_BANK: 'ADD_OPEN_BANKING_BANK',
  ADD_OPEN_BANKING_BANK_SUCCESS: 'ADD_OPEN_BANKING_BANK_SUCCESS',
  ADD_OPEN_BANKING_BANK_FAILURE: 'ADD_OPEN_BANKING_BANK_FAILURE',
  GET_OPEN_BANKING_BANK_INFORMATIONS: 'GET_OPEN_BANKING_BANK_INFORMATIONS',
  GET_OPEN_BANKING_BANK_INFORMATIONS_SUCCESS: 'GET_OPEN_BANKING_BANK_INFORMATIONS_SUCCESS',
  GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE: 'GET_OPEN_BANKING_BANK_INFORMATIONS_FAILURE',
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS: 'GET_OPEN_BANKING_BANK_REQUIRED_FIELDS',
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_SUCCESS: 'GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_SUCCESS',
  GET_OPEN_BANKING_BANK_REQUIRED_FIELDS_FAILURE:
    'GET_OPEN_BANKING_BANK_INFORMATION_REQUIRED_FIELDS_FAILURE',
  GET_OPEN_BANKING_BANK_ACCOUNTS: 'GET_OPEN_BANKING_BANK_ACCOUNTS',
  GET_OPEN_BANKING_BANK_ACCOUNTS_SUCCESS: 'GET_OPEN_BANKING_BANK_ACCOUNTS_SUCCESS',
  GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE: 'GET_OPEN_BANKING_BANK_ACCOUNTS_FAILURE',
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT: 'ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT',
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_SUCCESS:
    'ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_SUCCESS',
  ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE:
    'ADD_OPEN_BANKING_MANUALLY_BANK_ACCOUNT_FAILURE',
  UPDATE_OPEN_BANKING_BANK_ACCOUNT: 'UPDATE_OPEN_BANKING_BANK_ACCOUNT',
  UPDATE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS: 'UPDATE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS',
  UPDATE_OPEN_BANKING_BANK_ACCOUNT_FAILURE: 'UPDATE_OPEN_BANKING_BANK_ACCOUNT_FAILURE',
  DELETE_OPEN_BANKING_BANK_ACCOUNT: 'DELETE_OPEN_BANKING_BANK_ACCOUNT',
  DELETE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS: 'DELETE_OPEN_BANKING_BANK_ACCOUNT_SUCCESS',
  DELETE_OPEN_BANKING_BANK_ACCOUNT_FAILURE: 'DELETE_OPEN_BANKING_BANK_ACCOUNT_FAILURE'
};
