import React from 'react';
import { Card, Button } from 'components';
import classNames from 'classnames';
import { ReactComponent as CardNormaWatermark } from 'assets/icons/card-norma-watermark.svg';
import { ReactComponent as IconVisa } from 'assets/icons/visa.svg';
import { ReactComponent as IconDotsVertical } from 'assets/icons/dots-vertical.svg';

import NormaText from 'assets/images/norma-text.png';

export default function PhysicalCard({
  card,
  status,
  toggleActivatePhysicalCardModal,
  toggleMultiCardModal,
  isInModal
}) {
  const cardClassNames = classNames(
    'w-[327px] h-[204px] bg-gray-900 text-white rounded-xl p-4 pl-6 flex flex-col justify-between relative bg-gradient-to-r from-gray-900 to-gray-800 relative z-1 '
  );

  return (
    <Card className={cardClassNames}>
      <div className="h-full flex flex-col justify-between relative z-10">
        <div className="flex items-start justify-between">
          <div className="mt-2">
            <img src={NormaText} width={99} height={18} alt="norma-text-logo" />
          </div>
          {!isInModal && (
            <div
              className="w-8 h-8 ml-2 rounded-full bg-gray-500 flex items-center justify-center cursor-pointer"
              onClick={toggleMultiCardModal}
            >
              <IconDotsVertical className="fill-current text-white" />
            </div>
          )}
        </div>
        <div>
          <p className="text-xs font-medium leading-4 text-gray-500">Kart Numarası</p>
          <p className="text-white text-[22px] leading-7">{card?.cardNo}</p>
        </div>
        <div className="flex justify-between items-end">
          {status || isInModal ? (
            <span></span>
          ) : (
            <Button className="w-[165px] h-[44px]" onClick={toggleActivatePhysicalCardModal}>
              <span>Kartı Aktifleştir</span>
            </Button>
          )}
          <IconVisa />
        </div>
      </div>
      <CardNormaWatermark className="fill-current text-gray-800 rounded-xl absolute bottom-0 right-0 rotate" />
    </Card>
  );
}
