import classNames from 'classnames';

const LabelButton = ({ children, className, onClick }) => {
  const classes = classNames(
    'px-4 py-2 rounded-full text-sm font-medium leading-5 cursor-pointer',
    className
  );
  return (
    <span className={classes} onClick={onClick}>
      {children}
    </span>
  );
};

export default LabelButton;
