import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as Comment } from 'assets/icons/comment.svg';
import { ReactComponent as Union } from 'assets/icons/union.svg';
// import { ReactComponent as QuestionCircle } from 'assets/icons/question-circle.svg';
import { ReactComponent as FileDocument } from 'assets/icons/file-document.svg';

import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { ReactComponent as InsertIcon } from 'assets/icons/insert.svg';
import { ReactComponent as FileEraseIcon } from 'assets/icons/file-erase.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as IconLock } from 'assets/icons/lock.svg';
import { ReactComponent as IconSignout } from 'assets/icons/signout.svg';
import { ReactComponent as IconBoxes } from 'assets/icons/boxes.svg';
import { ReactComponent as IconBuilding } from 'assets/icons/building-2.svg';
import { ReactComponent as IconSubtitle } from 'assets/icons/subtitles.svg';
import { ReactComponent as IconInvoice } from 'assets/icons/invoice.svg';
import { ReactComponent as IconShoppingCart } from 'assets/icons/shopping-cart.svg';

const iconClass = 'fill-current w-4 h-4';

const navbarRoutes = [
  {
    name: 'Norma',
    to: '/',
    icon: GridIcon
  },
  {
    name: 'Hesaplar',
    to: '/accounts',
    icon: WalletIcon
  },
  {
    name: 'İşlemler',
    to: '/transactions',
    icon: InsertIcon
  },
  {
    name: 'Ön Muhasebe',
    to: '/invoicing',
    icon: FileEraseIcon
  },
  {
    name: 'Hizmet Ve Ürünler',
    to: '/profile/services',
    icon: IconShoppingCart
  },
  {
    name: 'Diğer',
    to: '/other',
    icon: MenuIcon
  }
];

const menuItems = [
  {
    title: 'Kişisel Bilgiler',
    icon: UserIcon,
    path: '/profile'
  },
  {
    title: 'Şirket Bilgileri',
    icon: IconBuilding,
    path: '/profile/company'
  },
  {
    title: 'e-Dönüşüm Bilgileri',
    icon: IconInvoice,
    path: '/profile/e-invoice'
  },
  {
    title: 'Hizmet ve Ürünler',
    icon: IconSubtitle,
    path: '/profile/services'
  },
  {
    title: 'Arşivlenmiş Hesaplar',
    icon: IconBoxes,
    path: '/profile/archived-wallets'
  },
  {
    title: 'Belgeler',
    icon: FileDocument,
    path: '/documents'
  },
  {
    title: 'Bildirim Ayarları',
    icon: Union,
    path: '?notification-settings'
  },
  {
    title: 'Bize Ulaşın',
    icon: Comment,
    path: '?contact-us',
    hasDivider: true
  },
  {
    title: 'Şifre Değiştir',
    icon: IconLock,
    path: '/profile/reset-password'
  },
  {
    title: 'Çıkış Yap',
    icon: IconSignout,
    path: '/welcome',
    customClass: '!text-red-500'
  }
  // {
  //   title: 'Sözleşmeler',
  //   icon: FileDocument
  // },
  // {
  //   title: 'Sıkça Sorulan Sorular',
  //   icon: QuestionCircle
  // },
];

export { menuItems, navbarRoutes, iconClass };
