import { BottomModal, Card, CustomButton, CustomInput } from 'components';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWallets } from 'store/actions/accounts';
import { currencyFormat } from 'utils';
import useDengage from 'utils/hooks/useDengage';

import DoubleCheckIllustration from 'assets/illustrations/card/double-check.png';

import { createWithdrawal } from 'store/actions/withdrawal';

import SelectAccount from './components/SelectAccount';
import SelectContact from './components/SelectContact';
import EditIbanInfoPopup from './components/EditIbanInfoPopup';
import useModal from 'utils/hooks/useModal';

import AmountInput from './components/AmountInput';
import IbanInput from './components/IbanInput';
import { confirmTransaction, createTransactionWithNorma } from 'store/actions/transactions';
import ReceiptModal from 'views/accounts/ReceiptModal';
import { CURRENCY_NAMES } from 'views/invoicing/enums';
// import { ReactComponent as IconBank } from 'assets/icons/bank-account.svg';
import { CURRENCIES } from 'views/other/items/enums';
import UpdateContactModal from 'views/other/contacts/components/updateContactModal';

const PAGE_NAMES = {
  TRANSFER: 'TRANSFER',
  SUMMARY: 'SUMMARY',
  SUCCESS: 'SUCCESS',
  RECEIPT: 'RECEIPT'
};

const MoneyTransferModal = ({ handleClose, defaultContactId }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const [selectedPage, setSelectedPage] = useState(PAGE_NAMES.TRANSFER);

  const [transactionInfo, setTransactionInfo] = useState(null);

  const [setSelectedAccount, setSetSelectedAccount] = useState(null);

  const { confirmedTransaction } = useSelector((state) => state.transactions);

  function handleSummary(tInfo) {
    setTransactionInfo(tInfo);
    setSelectedPage(PAGE_NAMES.SUMMARY);
  }

  function handleApprove() {
    setSelectedPage(PAGE_NAMES.SUCCESS);
  }

  function getReceiptUrl() {
    setSelectedPage(PAGE_NAMES.RECEIPT);
  }

  const PAGES = {
    [PAGE_NAMES.TRANSFER]: (
      <Transfer
        handleSummary={handleSummary}
        defaultContactId={defaultContactId}
        selectedAccount={setSetSelectedAccount}
      />
    ),
    [PAGE_NAMES.SUMMARY]: (
      <Summary
        transactionInfo={transactionInfo}
        handleApprove={handleApprove}
        handleClose={handleClose}
        walletId={setSelectedAccount?.id}
      />
    ),
    [PAGE_NAMES.SUCCESS]: <Success handleClose={handleClose} getReceiptUrl={getReceiptUrl} />,
    [PAGE_NAMES.RECEIPT]: (
      <ReceiptModal receiptUrl={confirmedTransaction?.receiptUrl} onClose={handleClose} />
    )
  };

  useEffect(() => {
    dispatch(getWallets({ status: 'ACTIVE' }));
    publishEvent(eventTypes.pageView, { page_type: 'transactions_money_transfer' });
  }, []);

  return <BottomModal handleClose={handleClose}>{PAGES[selectedPage]}</BottomModal>;
};

const TabQuickContact = ({ handleSummary, defaultContactId, onContactSelected }) => {
  const dispatch = useDispatch();
  const { publishEvent, eventTypes } = useDengage();

  const [selectedContactId, setSelectedContactId] = useState(defaultContactId);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState();

  const { contacts } = useSelector((state) => state.contacts);
  const accounts = useSelector((state) => state.accounts).filter((a) => a.currencyCode === 'TRY');
  const { user } = useSelector((state) => state.auth);

  const selectedContact = contacts.find((contact) => contact.id === selectedContactId);

  const [editContactWarning, , toggleEditContactWarning] = useModal();
  const [editContactModal, , toggleEditContactModal] = useModal();

  function handleTransfer() {
    if (!selectedContact || !selectedAccount) return;

    if (!selectedContact?.iban && !selectedContact?.walletNumber) {
      return toggleEditContactWarning();
    }
    if (onContactSelected) onContactSelected(selectedAccount);

    if (selectedContact?.walletNumber) {
      const payload = {
        amount,
        receiverWalletNumber: selectedContact?.walletNumber?.replaceAll(' ', '')
      };
      return dispatch(createTransactionWithNorma(selectedAccount.id, payload)).then((data) => {
        if (data) {
          handleSummary(data);
          publishEvent(eventTypes.pageView, {
            page_type: 'transactions_norma_no_transaction_success'
          });
        }
      });
    }

    if (selectedContact?.iban) {
      const { iban, firstName, lastName } = selectedContact;

      const payload = {
        amount,
        receiverIban: iban,
        receiverName: `${firstName} ${lastName}`,
        receiverIdentityNumber: user.identityNumber
      };

      return dispatch(createWithdrawal(selectedAccount.id, payload))
        .then((res) => {
          handleSummary(res.data?.data);
          publishEvent(eventTypes.pageView, {
            page_type: 'transactions_contact_transaction_success'
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <Fragment>
      {editContactModal && (
        <UpdateContactModal contactId={selectedContact.id} onClose={toggleEditContactModal} />
      )}
      <EditIbanInfoPopup
        active={editContactWarning}
        onClose={toggleEditContactWarning}
        toggleEditContactModal={toggleEditContactModal}
      />
      <SelectContact
        editContactModal={editContactModal}
        selectedContact={selectedContact}
        setSelectedContactId={setSelectedContactId}
      />
      <div className="font-semibold text-lg text-gray-900 mt-14">Gönderen Hesap</div>
      <SelectAccount
        accounts={accounts}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <AmountInput amount={amount} setAmount={setAmount} />
      <CustomButton
        color="success"
        className="mt-10 w-full"
        onClick={handleTransfer}
        disabled={!selectedContact || !selectedAccount || isNaN(amount) || amount <= 0}
      >
        Devam
      </CustomButton>
    </Fragment>
  );
};

const TabNormaNo = ({ handleSummary, onContactSelected }) => {
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState();
  const [normaNo, setNormaNo] = useState('');
  const [name, setName] = useState('');
  const { publishEvent, eventTypes } = useDengage();

  const accounts = useSelector((state) => state.accounts).filter(
    (a) => a.currencyCode === CURRENCIES.TRY
  );

  function handleTransfer() {
    if (onContactSelected) onContactSelected(selectedAccount);

    const payload = {
      amount,
      receiverName: name,
      receiverWalletNumber: normaNo
    };
    dispatch(createTransactionWithNorma(selectedAccount.id, payload)).then((data) => {
      if (data) {
        handleSummary(data);
        publishEvent(eventTypes.pageView, {
          page_type: 'transactions_norma_no_transaction_success'
        });
      }
    });
  }

  const isCorrect = !!selectedAccount && normaNo.length === 10 && amount;

  return (
    <Fragment>
      <span className="font-semibold text-lg text-gray-900">Alıcı Hesap</span>
      <div className="mt-4 flex gap-x-6 mb-14">
        <CustomInput
          label="Norma No"
          className="w-1/3"
          only="number"
          maxLength={10}
          onChange={(e) => setNormaNo(e.target.value)}
        />
        <CustomInput
          label="İsim Soyisim / Unvan"
          className="w-2/3"
          only="text"
          maxLength={140}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <span className="font-semibold text-lg text-gray-900">Gönderen Hesap</span>
      <SelectAccount
        accounts={accounts}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <AmountInput amount={amount} setAmount={setAmount} />
      <CustomButton
        color="success"
        className="mt-10 w-full"
        onClick={handleTransfer}
        disabled={!isCorrect}
      >
        Devam
      </CustomButton>
    </Fragment>
  );
};
const TabIBAN = ({ handleSummary, onContactSelected }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [iban, setIban] = useState('TR');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [amount, setAmount] = useState();
  const { publishEvent, eventTypes } = useDengage();

  const accounts = useSelector((state) => state.accounts).filter((a) => a.currencyCode === 'TRY');
  //const { user } = useSelector((state) => state.auth);

  function handleTransfer() {
    if (onContactSelected) onContactSelected(selectedAccount);

    const payload = {
      amount,
      receiverIban: iban,
      receiverName: name,
      receiverIdentityNumber: iban
    };

    return dispatch(createWithdrawal(selectedAccount.id, payload))
      .then((res) => {
        handleSummary(res.data?.data);
        publishEvent(eventTypes.pageView, {
          page_type: 'transactions_contact_transaction_success'
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const isCorrect = iban.length === 26 && amount && name.length >= 2 && selectedAccount;

  return (
    <Fragment>
      <span className="font-semibold text-lg text-gray-900">Alıcı Hesap</span>
      <IbanInput iban={iban} setIban={setIban} />
      <CustomInput
        label="İsim Soyisim / Unvan"
        className="mt-10 mb-12"
        only="text"
        maxLength={140}
        onChange={(e) => setName(e.target.value)}
      />
      <span className="font-semibold text-lg text-gray-900">Gönderen Hesap</span>
      <SelectAccount
        accounts={accounts}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <AmountInput amount={amount} setAmount={setAmount} />
      <CustomButton
        color="success"
        className="mt-10 w-full"
        disabled={!isCorrect}
        onClick={handleTransfer}
      >
        Devam
      </CustomButton>
    </Fragment>
  );
};
const TabMySelf = ({ handleSummary, onContactSelected }) => {
  const dispatch = useDispatch();

  const accounts = useSelector((state) => state.accounts).filter((a) => a.currencyCode === 'TRY');

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [receiverAccount, setReceiverAccount] = useState(null);
  const [amount, setAmount] = useState();
  const { publishEvent, eventTypes } = useDengage();

  function handleTransfer() {
    const receiverNo = receiverAccount.number;

    if (!receiverNo) return;
    if (onContactSelected) onContactSelected(selectedAccount);

    const payload = {
      amount,
      receiverWalletNumber: receiverNo?.replaceAll(' ', '')
    };
    dispatch(createTransactionWithNorma(selectedAccount.id, payload)).then((data) => {
      if (data) {
        handleSummary(data);
        publishEvent(eventTypes.pageView, {
          page_type: 'transactions_norma_no_transaction_success'
        });
      }
    });
  }

  return (
    <Fragment>
      <span className="font-semibold text-lg text-gray-900">Gönderen Hesap</span>
      <SelectAccount
        accounts={accounts}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <span className="font-semibold text-lg text-gray-900">Alıcı Hesap</span>
      <SelectAccount
        accounts={accounts}
        selectedAccount={receiverAccount}
        setSelectedAccount={setReceiverAccount}
      />
      <AmountInput amount={amount} setAmount={setAmount} />
      <CustomButton
        color="success"
        className="mt-10 w-full"
        disabled={!selectedAccount || !receiverAccount || !amount}
        onClick={handleTransfer}
      >
        Devam
      </CustomButton>
    </Fragment>
  );
};

const Summary = ({ transactionInfo, walletId, handleApprove, handleClose }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const { publishEvent, eventTypes } = useDengage();
  const { confirmedTransaction, error } = useSelector((state) => state.transactions);

  useEffect(() => {
    publishEvent(eventTypes.pageView, { page_type: 'transactions_transaction_summary' });
  }, []);

  useEffect(() => {
    if (!error && confirmedTransaction) {
      handleApprove();
    }
  }, [confirmedTransaction]);

  function handleTransaction() {
    const payload = {
      description,
      groupId: transactionInfo.groupId
    };
    dispatch(confirmTransaction(walletId, payload));
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-white overflow-y-scroll">
      <div className="w-[580px] h-full py-12">
        <div className="flex flex-col">
          <span className="text-gray-400">Para Transferi</span>
          <span className="text-gray-900 text-3xl font-semibold">
            Transferi onaylıyor musunuz?
          </span>
        </div>
        <Card className={'!p-0'}>
          <div className="p-6">
            <div className="flex flex-col gap-y-4">
              <span className="text-gray-900 text-lg">Gönderen Hesap</span>
              <div className="flex-col w-full rounded-lg bg-gray-100 px-5 py-6 flex">
                <div className="flex items-center">
                  {/* <span className="w-10 h-10 rounded-full bg-green-300 mr-3">
                    <IconBank className="fill-current text-green-700" />
                  </span> */}
                  <div className="text-gray-900 font-semibold">
                    {transactionInfo?.senderWalletName || ''}
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <span className="text-xs text-gray-500 ">İşlem Sonrası Bakiye</span>
                  <span className="text-gray-500 font-semibold ml-auto">
                    {currencyFormat(transactionInfo?.senderWalletBalance)} TL
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 mt-8">
              <span className="text-gray-900 text-lg">Alıcı Hesap</span>
              <div className="w-full rounded-lg bg-gray-100 px-5 py-6 flex">
                {/* <span className="w-10 h-10 rounded-full bg-green-300 mr-3">
                  <IconBank className="fill-current text-green-700" />
                </span> */}
                <div className="flex flex-col justify-center">
                  <span className="text-gray-900 font-semibold">
                    {transactionInfo?.receiverFullName || transactionInfo?.receiverName}
                  </span>
                  <span className="text-gray-500 text-sm leading-5 font-semibold">
                    {transactionInfo?.receiverIdentifier ||
                      transactionInfo?.receiverIdentityNumber}
                  </span>
                </div>
                {(!!transactionInfo?.receiver?.receiverAmount ||
                  !!transactionInfo?.sender?.senderAmount) && (
                  <span className="text-gray-900 text-xl ml-auto">
                    {`+ ${currencyFormat(
                      transactionInfo?.receiver?.receiverAmount ||
                        transactionInfo?.sender?.senderAmount
                    )} TL`}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-8 border border-solid border-gray-100 rounded-lg p-6 flex flex-col">
              <div className="w-full flex justify-between items-center py-3 border-b border-solid border-gray-100">
                <span className="text-gray-700 font-semibold text-sm">Tutar</span>
                <span className="text-gray-900 font-semibold text-sm">
                  {currencyFormat(transactionInfo?.amount || 0)} {CURRENCY_NAMES.TRY}
                </span>
              </div>
              {/* <div className="w-full flex justify-between items-center py-3 border-b border-solid border-gray-100">
                <span className="text-gray-700 font-semibold text-sm">Transfer tarihi</span>
                <span className="text-gray-900 font-semibold text-sm">
                  {dateFormat(new Date(transactionInfo?.createDate), '/')}
                </span>
              </div>
              <div className="w-full flex justify-between items-center py-3 border-b border-solid border-gray-100">
                <span className="text-gray-700 font-semibold text-sm">Transfer tipi</span>
                <span className="text-gray-900 font-semibold text-sm">
                  {TRANSACTION_TYPES[transactionInfo?.type]}
                </span>
              </div> */}
              <div className="flex flex-col mt-10">
                <span className="text-sm text-gray-500">Açıklama</span>
                <textarea
                  className="w-full bg-gray-100 rounded-lg appearance-none resize-none h-20 focus:outline-none p-4"
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={140}
                />
                <span className="ml-auto text-xs text-gray-400">{description.length}/140</span>
              </div>
            </div>
          </div>
          <div className="bg-green-300 flex justify-between px-14 py-3 !rounded-br-xl !rounded-bl-xl">
            <div className="text-green-700 font-medium text-lg">İşlem Ücret</div>
            <div className="text-green-700 fonts font-medium text-lg">
              {currencyFormat(0)} {CURRENCY_NAMES.TRY}
            </div>
          </div>
        </Card>
        <div className="mt-6 flex gap-x-4">
          <CustomButton color="danger" className="w-1/3" onClick={handleClose}>
            Vazgeç
          </CustomButton>
          <CustomButton
            className="w-2/3 flex justify-center items-center"
            onClick={handleTransaction}
            // disabled={!description.length}
            color="success"
          >
            İşlemi Onayla
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

const Transfer = ({ handleSummary, defaultContactId, selectedAccount }) => {
  const [tab, setTab] = useState(0);

  const TABS = [
    {
      title: 'Müşteri/Tedarikçiler',
      page: (
        <TabQuickContact
          handleSummary={handleSummary}
          defaultContactId={defaultContactId}
          onContactSelected={selectedAccount}
        />
      )
    },
    {
      title: 'Norma No',
      page: <TabNormaNo handleSummary={handleSummary} onContactSelected={selectedAccount} />
    },
    {
      title: 'IBAN',
      page: <TabIBAN handleSummary={handleSummary} onContactSelected={selectedAccount} />
    },
    {
      title: 'Kendime',
      page: <TabMySelf handleSummary={handleSummary} onContactSelected={selectedAccount} />
    }
  ];

  return (
    <div className="flex justify-center h-full w-full bg-white overflow-y-scroll">
      <div className="flex flex-col w-[640px] py-12 h-full">
        <span className="text-gray-900 font-semibold text-2xl">Para Transferi</span>
        <div className="mt-8 bg-white h-full">
          <div className="flex flex-col items-start justify-start w-full">
            <div className="w-full h-auto flex items-center justify-start gap-x-14 border-b border-solid border-gray-200 text-black text-sm">
              {TABS.map((t, i) => {
                const classNames =
                  tab === i
                    ? 'border-b border-solid border-green-500 text-green-500'
                    : 'text-gray-500';
                return (
                  <div
                    key={i}
                    className={`${classNames} w-min min-w-max font-semibold text-lg pb-3 pt-5 h-auto flex items-center justify-center cursor-pointer select-none`}
                    onClick={() => !t.disabled && setTab(i)}
                  >
                    {t.title}
                  </div>
                );
              })}
            </div>
            <div className="w-full h-full my-8">{TABS[tab].page}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Success = ({ handleClose, getReceiptUrl }) => {
  return (
    <div className="flex justify-center w-full h-full bg-green-500 overflow-y-scroll">
      <div className="w-[360px] h-full flex flex-col items-center justify-center">
        <img src={DoubleCheckIllustration} width="100" alt="double-check" />
        <span className="text-2xl text-white text-center mt-6">
          Para Transferi işleminiz başarıyla gerçekleşti
        </span>
        <CustomButton className="w-full mt-8" onClick={getReceiptUrl}>
          Dekont Göster
        </CustomButton>
        <CustomButton color="success" className="w-full mt-4" onClick={handleClose}>
          Kapat
        </CustomButton>
      </div>
    </div>
  );
};

export default MoneyTransferModal;
