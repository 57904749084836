import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomInput, Select } from 'components';

import { getCities, getDistrict } from 'store/actions/country';
import { getTaxOfficesByCityId } from 'store/actions/taxOffice';

const CompanyInformation = ({ watch, setValue, clearErrors, errors }) => {
  const dispatch = useDispatch();
  const districtRef = useRef();
  const taxOfficeRef = useRef();

  const { cities, districts } = useSelector((state) => state.country);
  const filteredDistricts = districts[watch('cityId')] || [];

  useEffect(() => {
    dispatch(getCities());
  }, []);

  useEffect(() => {
    const cityId = watch('cityId');
    if (!cityId) return;

    dispatch(getDistrict({ cityId }));
  }, [watch('cityId')]);

  const [taxOffices, setTaxOffices] = useState([]);

  useEffect(() => {
    const cityId = watch('cityId');
    if (!cityId) return;

    dispatch(getTaxOfficesByCityId({ cityId })).then((res) => {
      setTaxOffices(res.data?.data);
    });
  }, [watch('cityId')]);

  return (
    <div className="flex flex-col gap-y-4" data-testid="section_company_information">
      <span className="text-[18px] text-gray-600 font-semibold">Şirket Bilgileri</span>
      <CustomInput
        key="business_companyName"
        className="w-full"
        label="Şirket Adı"
        name="companyName"
        onChange={(e) => {
          setValue('companyName', e.target.value);
          clearErrors('companyName');
        }}
        maxLength={140}
        value={watch('companyName')}
        error={errors?.companyName?.message}
        data-testid="input_company_name"
      />
      <Select
        className="w-full"
        options={cities}
        value="id"
        valueLabel="name"
        label="İl"
        isOptional
        defaultValue={watch('cityId')}
        onChange={(cId) => {
          setValue('cityId', Number(cId));
          setValue('districtId', null);
          setValue('taxOfficeId', null);
          districtRef.current.value = '';
          taxOfficeRef.current.value = '';
          clearErrors('cityId');
        }}
        error={errors?.cityId?.message}
        data-testid="select_city"
      />
      <Select
        className="w-full"
        selectRef={districtRef}
        options={filteredDistricts}
        value="id"
        valueLabel="name"
        label="İlçe"
        isOptional
        defaultValue={watch('districtId')}
        onChange={(districtId) => {
          setValue('districtId', Number(districtId));
          clearErrors('districtId');
        }}
        error={errors?.districtId?.message}
        data-testid="select_district"
      />
      <div className="flex flex-col w-full">
        <span className="text-sm text-gray-500 font-medium" data-testid="label_tax_office">
          Adres{' '}
          <span className="text-sm leading-5 font-medium text-gray-400 italic">
            {'(Opsiyonel)'}
          </span>
        </span>
        <textarea
          onChange={(e) => setValue('address', e.target.value)}
          value={watch('address')}
          className="bg-gray-100 resize-none rounded-lg focus:outline-none p-2 h-20 w-full"
          maxLength={140}
          data-testid="input_address"
        />
      </div>
      <Select
        className="w-full"
        selectRef={taxOfficeRef}
        key="business_taxOfficeId"
        options={taxOffices}
        isOptional
        value="id"
        valueLabel="name"
        label="Vergi Dairesi"
        defaultValue={watch('taxOfficeId')}
        onChange={(taxOfficeId) => {
          setValue('taxOfficeId', Number(taxOfficeId));
          clearErrors('taxOfficeId');
        }}
        error={errors?.taxOfficeId?.message}
        data-testid="select_tax_office"
      />
      <CustomInput
        key="business_taxIdentityNumber"
        className="w-full"
        label="Vergi / T.C. Kimlik Numarası"
        name="taxIdentityNumber"
        isOptional
        maxLength={11}
        onChange={(e) => {
          setValue('taxIdentityNumber', e.target.value);
          clearErrors('taxIdentityNumber');
        }}
        value={watch('taxIdentityNumber')}
        only="number"
        error={errors?.taxIdentityNumber?.message}
        data-testid="input_tax_identity_number"
      />
    </div>
  );
};

export default CompanyInformation;
